// @flow
import AutoPaySchedule from '../AutoPaySchedule'
import PaymentMethods from '../../PaymentMethods'
import ExpressPay from '../../ExpressPay'
import { MaxAmount, FixedAmount } from '../Amount'

import type { Flow } from '../../PaymentFlow'

export const progress = {
  noAutoPay: 20,
  payment: 20,
  paymentOption: 35,
  expressPay: 50,
  schedule: 65,
  summary: 85
}

export const payment = {
  Component: () => null,
  name: 'amount',
  required: true,
  infoItem: {
    title: 'Payment',
    label: 'Amount',
    value: 'amount.total',
    editable: true
  },
  progress: progress.payment
}

export const getVAPObject = () => {
  return {
    Component: MaxAmount,
    name: 'amount',
    required: false,
    infoItem: {
      title: 'Payment',
      label: 'Amount',
      value: 'amount.total',
      editable: true
    },
    progress: progress.payment
  }
}

export const FAPValidator = ({ totalAmt }: { totalAmt: number }) => totalAmt > 0
export const getFAPObject = (paymentFields: Object[]) => {
  const isEditable = paymentFields.some(({ locked }) => {
    return !locked
  })
  return {
    Component: FixedAmount,
    name: 'amount',
    required: true,
    props: { isEditable },
    validator: FAPValidator,
    infoItem: {
      title: 'Payment',
      label: 'Amount',
      value: 'amount.total',
      editable: isEditable
    },
    progress: progress.payment
  }
}

export const paymentMethodEdit: Flow = {
  Component: PaymentMethods,
  name: 'lastPaymentMethod',
  required: true,
  props: { showMore: true },
  infoItem: {
    title: 'lastPaymentMethod.name',
    label: 'Fee',
    isDynamicTitle: true,
    value: 'lastPaymentMethod.fee',
    defaultValue: 'Free',
    editable: true
  },
  progress: progress.paymentOption
}

export const expressPay: Flow = {
  Component: ExpressPay,
  name: 'useExpressPay',
  required: false,
  props: { paymentProcessedDate: '5/3/19', expressFee: '$9.95' },
  progress: progress.expressPay
}

export const autoPayScheduleValidator = (value: Object = {}) => {
  const { firstPayment, lastPayment, frequency } = value
  return Boolean(firstPayment && lastPayment && frequency)
}

export const autoPaySchedule: Flow = {
  Component: AutoPaySchedule,
  name: 'schedule',
  required: true,
  validator: autoPayScheduleValidator,
  infoItem: {
    title: 'Schedule',
    label: 'Monthly Starting',
    value: 'schedule.firstPayment',
    editable: true
  },
  progress: progress.schedule
}
