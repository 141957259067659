export const SET_CONFIG = 'setPaymentReminder'

export const setPaymentReminderMutation =`
  mutation($input: PaymentReminderInput!) {
    ${SET_CONFIG} (input: $input) {
      email
      altEmail
      mobile
      altMobile
      frequency
      dateToSend
      remindersEnabled
    }
  }
`
