import { GET_RES_BILLING_INFO, RESIDENT_PIN_LOGIN } from '../actions'

const initialState = {}

const deliveryPreference = (state = initialState, action) => {
  switch (action.type) {
    case GET_RES_BILLING_INFO:
      const {
        email,
        billingType,
        paperStatementReason,
        propertyId,
        tenantCode,
        residentName,
        address: {
          address: streetAddress
        } = {}
      } = action.data
      var prefInt = billingType < 3 ? billingType + 3 : billingType
      return {
        ...state,
        email,
        preference: prefInt.toString(),
        reason: paperStatementReason,
        propertyId,
        tenantCode,
        residentName,
        streetAddress
      }
    case RESIDENT_PIN_LOGIN:
      const { accessToken } = action.data;

      return {
        ...state,
        token: accessToken
      }
    default:
      return state
  }
}

export default deliveryPreference
