import styled from '@emotion/styled'
import Card from '@Common/Card'

export const ToolTipWrap = styled.div`
  position: absolute;
  top: -170px;
  right: 0;
`

export const AddCardStyled = styled(Card)`
  position: relative;
  overflow: visible;
`

export const FormStyled = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;

  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    grid-gap: ${({ theme }) => theme.space.s15};
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    .MuiFormControl-root .MuiInputLabel-formControl {
      font-size: ${theme.custom.font.size.md};
    }
    .MuiFormHelperText-root {
      font-size: ${theme.custom.font.size.xs};
      line-height: ${theme.custom.font.size.xs};
    }
    `}
`

export const BankFormStyled = styled(FormStyled)`
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  ${({ theme }) => [theme.breakpoints.up('lg')]} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    .MuiFormControl-root .MuiInputLabel-formControl {
      font-size: ${theme.custom.font.size.md};
    }`}
`

const FormContentStyled = styled.div`
  display: grid;
  grid-gap: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s5 : theme.space.s20};

  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    grid-gap: ${({ theme }) =>
      theme.ux.isSSO ? theme.space.s5 : theme.space.s15};
  }
`

export const CardInfoStyled = styled(FormContentStyled)`
  & > div:first-of-type,
  & > div:last-of-type {
    grid-column-start: 1;
    grid-column-end: 5;
  }

  & > div:nth-of-type(2) {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  & > div:nth-of-type(3) {
    min-width: 75px;
    grid-column-start: 4;
    grid-column-end: 5;
  }
`

export const CardProvidersStyled = styled.div`
  position: absolute;
  top: -30%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    margin-right: 5px;
  }
`

export const ContactInfoStyled = styled(FormContentStyled)`
  & > div:first-of-type {
    grid-column-start: 1;
    grid-column-end: 5;
  }

  & > div:nth-of-type(2) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  & > div:nth-of-type(3) {
    grid-column-start: 3;
    grid-column-end: 5;
  }

  & > div:nth-of-type(4) {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  & > div:nth-of-type(5) {
    min-width: 70px;
    grid-column-start: 2;
    grid-column-end: 4;
  }

  & > div:nth-of-type(6) {
    grid-column-start: 4;
    grid-column-end: 5;
  }
`
