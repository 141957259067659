// @flow
import { createSelector } from 'reselect'
import get from 'lodash/get'
import sum from 'lodash/sum'
import Dinero from 'dinero.js'

import { getProperty } from '../../../userResource/selectors'
import { getUserName } from '../../../authorization/selectors'
import { getUnitCompany } from 'zego-shared/store/select/selectors'
import { getUpdatedPaymentFields, getTotalOwed } from '../../balance/selectors'
import { getPaymentMethodData } from '../../methods/selectors'
import { getPaymentOptionFees, getExpressPayFee } from '../../fees/selectors'
import { amountToNumber } from '../../../../utils/number'
import { getPaymentNotes } from '../../paymentNotes/selectors'
import { getIsSsoPayments } from 'zego-shared/store/authorization/selectors'
import { getIpnUrl, getIpnCustomValue, getSecondaryResidentId } from '../../../overrides/selectors'

export const isSSO = getIsSsoPayments()

const typeToName = {
  BankAccount: 'ACH',
  DebitCard: 'Debit Card',
  CreditCard: 'Credit Card',
  PayPal: 'PayPal'
}

export const typeNameToPaymentType = {
  BankAccount: 'ACH',
  DebitCard: 'DB',
  CreditCard: 'CC',
  PayPal: 'PAYPAL'
}

export const balanceToInteger = (balance: string) => {
  const bal = balance.slice(1)
  return parseInt(bal, 10)
}

export const getOneTimePaymentDetails = (state: Object) =>
  get(state, 'payments.oneTimePayment.details')

export const getDetails = (state: Object) =>
  get(state, 'payments.oneTimePayment.details')

export const getConfirmationId = (state: Object) =>
  get(state, 'payments.oneTimePayment.data.transId', '')

export const getFraudToolkitError = (state: Object) =>
  get(state, 'payments.oneTimePayment.fraudToolkit.error')

export const getPlaidInsufficientFundsError = (state: Object) =>
  get(state, 'payments.oneTimePayment.plaidInsufficientFunds.error')
  
export const isBalanceZero = createSelector(
  [getTotalOwed],
  (totalOwed: Object) => {
    const total = totalOwed.toFormat()
    return balanceToInteger(total) === 0
  }
)

export const getPaymentDetails = createSelector(
  [getDetails, getUpdatedPaymentFields, getTotalOwed, getPaymentMethodData],
  (
    prevDetails: Object,
    paymentFields: Object,
    totalOwed: Object,
    lastPaymentMethod: Object
  ) => {
  
    const { fields, optionalFields } = paymentFields
    const total = totalOwed.toFormat()
    const details = prevDetails || {
      amount: {
        fields,
        optionalFields,
        total,
        fullBalance: total
      },
      useExpressPay: false
    }
    return { ...details, lastPaymentMethod }
  }
)

export const getRecipient = createSelector(
  [getProperty, getUnitCompany],
  (property: Object, companyName: String) => {
    const { name, state = '', address = '' } = property
    return {
      propertyName: name,
      address: state && address ? `${state}, ${address}` : address || state,
      companyName
    }
  }
)

export const getResidentFullName = getUserName

export const getPaymentMethodFee = (
  paymentMethodFee: number,
  currency: string,
  typeName: string
) => {
  return {
    label: `${typeToName[typeName]} Fee` || '',
    value: Dinero({
      amount: paymentMethodFee,
      currency
    }).toFormat()
  }
}

export const getExpressPayDisplayFee = (
  expressPayFee: number,
  currency: string
) => {
  return {
    label: 'Express Fee',
    value: Dinero({
      amount: expressPayFee,
      currency
    }).toFormat()
  }
}

type LinItem = { label: string, value: string }

export const buildLineItems = (paymentFields: Object[]): Object => {
  const currency = paymentFields[0].currency
  let subtotal = Dinero({ amount: 0, currency })
  const lineItems: LinItem[] = paymentFields
    .filter(({ amount }) => amountToNumber(amount) > 0)
    .map(
      ({ label, amount }): LinItem => {
        const dinero = Dinero({ amount: amountToNumber(amount), currency })
        subtotal = subtotal.add(dinero)
        return {
          label,
          value: dinero.toFormat()
        }
      }
    )
  return { lineItems, subtotal, currency }
}

const buildPaymentInfo = ({
  paymentFields,
  typeName,
  useExpressPay,
  paymentOptionFees,
  amount
}: {
  paymentFields: Object[],
  typeName: string,
  useExpressPay: boolean,
  paymentOptionFees: Object[],
  amount: number
}) => {
  const { lineItems, subtotal, currency } = buildLineItems(paymentFields)
  const expressPayAmount = getExpressPayFee(
    paymentOptionFees,
    typeName,
    useExpressPay
  )

  const totalFees = expressPayAmount + amount;
  const total = subtotal.add(Dinero({ amount: totalFees, currency })).toFormat()
  const expressPayFee = getExpressPayDisplayFee(expressPayAmount, currency)
  const methodFee = getPaymentMethodFee(amount, currency, typeName)

  return {
    total,
    methodFee,
    expressPayFee,
    lineItems
  }
}

export const buildSummaryData = (
  paymentDetails: Object,
  fullName: string,
  recipient: Object,
  paymentOptionFees: Object[]
) => {
  if (!paymentDetails) {
    return null
  }
  const {
    lastPaymentMethod,
    useExpressPay,
    amount: { fields: paymentFields }
  } = paymentDetails
  const { name = '', typeName, amount, payPalEmail = '' } = lastPaymentMethod

  return {
    recipient,
    accountInfo: {
      fullName,
      methodName: name,
      payPalEmail
    },
    payment: buildPaymentInfo({
      paymentFields,
      typeName,
      amount,
      useExpressPay,
      paymentOptionFees
    })
  }
}

export const getSummaryData = createSelector(
  [getDetails, getResidentFullName, getRecipient, getPaymentOptionFees],
  buildSummaryData
)

export const buildPaymentFields = (fields: Object[] = []): [Object, Object] => {
  let unitAmount = 0
  let unitCurrency = ''
  const paymentFields = fields
    .filter(({ amount }) => amountToNumber(amount))
    .map(
      (field: Object): Object => {
        const { key, amount, currency } = field
        const amountNum = amountToNumber(amount)
        unitAmount += amountNum
        unitCurrency = currency
        return { key, money: { amount: amountNum, currency } }
      }
    )
  return [paymentFields, { amount: unitAmount, currency: unitCurrency }]
}

const getTotal = (
  unitAmount: number,
  paymentMethodAmount: number,
  currency: string
) => {
  return {
    amount: unitAmount + paymentMethodAmount,
    currency
  }
}

export const buildPayload = (
  details: Object,
  paymentOptionFees: Object[],
  isSSO: Boolean,
  paymentNotes?: string,
  ipnUrl?: string,
  ipnCustom?: string,
  secondaryResidentId?: string
): Object => {
  if (details) {
    const {
      amount: { fields },
      lastPaymentMethod,
      useExpressPay
    } = details
    const [paymentFields, unitAmount] = buildPaymentFields(fields)
    const {
      typeName,
      id,
      currency = unitAmount.currency,
      amount
    } = lastPaymentMethod
    const expressPayAmount = getExpressPayFee(
      paymentOptionFees,
      typeName,
      useExpressPay
    )
    const feeAmount = sum([expressPayAmount, amount])
    const totalAmount = getTotal(unitAmount.amount, feeAmount, currency)

    return {
      paymentFields,
      feeAmount: { currency, amount: feeAmount },
      unitAmount,
      totalAmount,
      paymentType: typeNameToPaymentType[typeName],
      paymentMethodId: Number(id),
      isExpressPay: useExpressPay,
      notes: paymentNotes,
      isSSO: isSSO,
      ipnUrl: ipnUrl,
      ipnCustom: ipnCustom,
      secondaryResidentId: secondaryResidentId
    }
  }
  return {}
}

export const getPayload = createSelector(
  [getOneTimePaymentDetails, getPaymentOptionFees, getIsSsoPayments, getPaymentNotes, getIpnUrl, getIpnCustomValue, getSecondaryResidentId],
  buildPayload
)
