import { createFetchActions } from '../../../helpers/actions/creators'

export const USERPROFILESECTION = 'USER_PROFILE'
export const USER_PETS = 'USER_PETS'
export const USER_VEHICLES = 'USER_VEHICLES'
export const USER_VISITORS = 'USER_VISITORS'

export const UPLOAD_PROFILE_IMAGE = 'UPLOAD_PROFILE_IMAGE'
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'UPLOAD_PROFILE_IMAGE_SUCCESS'
export const UPLOAD_PROFILE_IMAGE_FAILURE = 'UPLOAD_PROFILE_IMAGE_FAILURE'

export const GENERATE_LOCK_CODE = 'GENERATE_LOCK_CODE'
export const GENERATE_LOCK_CODE_SUCCESS = 'GENERATE_LOCK_CODE_SUCCESS'
export const GENERATE_LOCK_CODE_FAILURE = 'GENERATE_LOCK_CODE_FAILURE'

export const DELETE_LOCK_CODE = 'DELETE_LOCK_CODE'
export const DELETE_LOCK_CODE_FAILURE = 'DELETE_LOCK_CODE_FAILURE'
export const DELETE_LOCK_CODE_SUCCESS = 'DELETE_LOCK_CODE_SUCCESS'

export const GET_USER_BALANCE = 'GET_USER_BALANCE'
export const GET_USER_BALANCE_SUCCESS = 'GET_USER_BALANCE_SUCCESS'
export const GET_USER_BALANCE_FAILURE = 'GET_USER_BALANCE_FAILURE'

export const GET_ALL_VISITORS = 'GET_ALL_VISITORS'
export const GET_ALL_VISITORS_SUCCESS = 'GET_ALL_VISITORS_SUCCESS'
export const GET_ALL_VISITORS_FAILURE = 'GET_ALL_VISITORS_FAILURE'

export const GET_DELIVERY_PREF = 'GET_DELIVERY_PREF'
export const GET_DELIVERY_PREF_SUCCESS = 'GET_DELIVERY_PREF_SUCCESS'
export const GET_DELIVERY_PREF_FAILURE = 'GET_DELIVERY_PREF_FAILURE'

export const UPDATE_DELIVERY_PREF = 'UPDATE_DELIVERY_PREF'
export const UPDATE_DELIVERY_PREF_SUCCESS = 'UPDATE_DELIVERY_PREF_SUCCESS'
export const UPDATE_DELIVERY_PREF_FAILURE = 'UPDATE_DELIVERY_PREF_FAILURE'

export const DISMISS_SURVEY = 'DISMISS_SURVEY'
export const DISMISS_SURVEY_SUCCESS = 'DISMISS_SURVEY_SUCCESS'
export const DISMISS_SURVEY_FAILURE = 'DISMISS_SURVEY_FAILURE'

export const SUBMIT_SURVEY = 'SUBMIT_SURVEY'
export const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS'
export const SUBMIT_SURVEY_FAILURE = 'SUBMIT_SURVEY_FAILURE'

export const {
  fetchAction: fetchUserProfileAction,
  fetchStartAction: fetchUserProfileStart,
  fetchSuccessAction: fetchUserProfileSuccess,
  fetchErrorAction: fetchUserProfileError
} = createFetchActions(USERPROFILESECTION)

export const fetchUserProfile = userId => fetchUserProfileAction({ userId })

export const uploadProfileImage = (userId, file) => ({
  type: UPLOAD_PROFILE_IMAGE,
  userId,
  file
})

export const uploadProfileImageSuccess = response => ({
  type: UPLOAD_PROFILE_IMAGE_SUCCESS,
  response
})

export const uploadProfileImageFailure = () => ({
  type: UPLOAD_PROFILE_IMAGE_FAILURE
})

export const generateLockCode = userId => ({
  type: GENERATE_LOCK_CODE,
  userId
})

export const dismissNotification = surveyId => ({
  type: DISMISS_SURVEY,
  surveyId
})

export const dismissNotificationSuccess = (surveyId, response) => ({
  type: DISMISS_SURVEY_SUCCESS,
  surveyId,
  response
})

export const dismissNotificationFail = (surveyId, response) => ({
  type: DISMISS_SURVEY_FAILURE,
  surveyId,
  response
})

export const submitSurveyResponse = (surveyId, rating, feedback) => ({
  type: SUBMIT_SURVEY,
  surveyId,
  rating,
  feedback
})

export const submitSurveyResponseSuccess = (surveyId, rating, feedback, response) => ({
  type: SUBMIT_SURVEY_SUCCESS,
  surveyId,
  rating,
  feedback,
  response
})


export const submitSurveyResponseFail = (surveyId, rating, feedback, response) => ({
  type: SUBMIT_SURVEY_FAILURE,
  surveyId,
  rating,
  feedback,
  response
})


export const generateLockCodeSuccess = response => ({
  type: GENERATE_LOCK_CODE_SUCCESS,
  response
})

export const generateLockCodeFailure = () => ({
  type: GENERATE_LOCK_CODE_FAILURE
})

export const deleteLockCode = userId => ({
  type: DELETE_LOCK_CODE,
  userId
})

export const deleteLockCodeSuccess = response => ({
  type: DELETE_LOCK_CODE_SUCCESS,
  response
})

export const deleteLockCodeFailure = () => ({
  type: DELETE_LOCK_CODE_FAILURE
})

export const getUserBalance = userId => ({
  type: GET_USER_BALANCE,
  userId
})

export const getUserBalanceSuccess = response => ({
  type: GET_USER_BALANCE_SUCCESS,
  response
})

export const getUserBalanceFailure = () => ({
  type: GET_USER_BALANCE_FAILURE
})

export const getDeliveryPreference = () => ({
  type: GET_DELIVERY_PREF
})

export const getDeliveryPreferenceSuccess = data => ({
  type: GET_DELIVERY_PREF_SUCCESS,
  data
})

export const getDeliveryPreferenceFailure = () => ({
  type: GET_DELIVERY_PREF_FAILURE
})

export const updateDeliveryPreference = deliveryPreferences => ({
  type: UPDATE_DELIVERY_PREF,
  deliveryPreferences
})

export const updateDeliveryPreferenceSuccess = data => ({
  type: UPDATE_DELIVERY_PREF_SUCCESS,
  data
})

export const updateDeliveryPreferenceFailure = () => ({
  type: UPDATE_DELIVERY_PREF_FAILURE
})

export const {
  fetchAction: fetchUserPets,
  fetchStartAction: fetchUserPetsStart,
  fetchSuccessAction: fetchUserPetsSuccess,
  fetchErrorAction: fetchUserPetsError
} = createFetchActions(USER_PETS)

export const {
  fetchAction: fetchUserVehiclesAction,
  fetchStartAction: fetchUserVehiclesStart,
  fetchSuccessAction: fetchUserVehiclesSuccess,
  fetchErrorAction: fetchUserVehiclesError
} = createFetchActions(USER_VEHICLES)

export const {
  fetchAction: fetchUserVisitorsAction,
  fetchStartAction: fetchUserVisitorsStart,
  fetchSuccessAction: fetchUserVisitorsSuccess,
  fetchErrorAction: fetchUserVisitorsError
} = createFetchActions(USER_VISITORS)

