// @flow
import React from 'react'
import { Hidden, useTheme } from '@material-ui/core'
import Button from '@Common/Button'
import PaymentNotes from '@views/Payments/PaymentNotes'
import checkImg from '@images/checks.png'
import DebitAd from './DebitAd'
import UseDebit from './UseDebit'
import {
  ExistingBankWrapper,
  ExistingBankInfoWrapper,
  ExistingBankInfo,
  BankChecks,
  LinksContainer,
  UnderlinedButton,
  CurrentMethodText,
  MethodText,
  ExistingBankDebitWrapper,
  BankFee
} from './styles'
import { PaymentNotesBtn } from '../../PaymentNotes'
import Flex from '@Common/Flex'

type Props = {
  hasDebit: Boolean,
  debitEnabled: Boolean,
  currentMethod: Object,
  onContinueClicked: Function,
  onShowMore: Function,
  onCancel: Function,
  addPaymentMethod: Function,
  selectPaymentMethod: Function,
  showNotes: boolean,
  paymentOptions: Array<Object>,
  paymentMethods: Array<Object>
}

const ExistingBankAccount = ({
  currentMethod,
  paymentMethods,
  paymentOptions,
  onContinueClicked,
  onShowMore,
  onCancel,
  addPaymentMethod,
  showNotes,
  debitEnabled,
  hasDebit,
  selectPaymentMethod
}: Props) => {
  const { type, account, fee } = currentMethod
  const theme = useTheme()
  const { isSSO } = theme.ux

  const debitAd = hasDebit ? (
    <UseDebit
      selectPaymentMethod={selectPaymentMethod}
      paymentMethods={paymentMethods}
    />
  ) : (
    <DebitAd addPaymentMethod={addPaymentMethod} />
  )

  const continueBtn = (
    <Button primary outline fullWidth onClick={onContinueClicked}>
      Continue with Current
    </Button>
  )

  return (
    <div>
      <ExistingBankWrapper>
        <ExistingBankInfoWrapper>
          <CurrentMethodText>Current Payment Method</CurrentMethodText>
          <ExistingBankInfo debitEnabled={debitEnabled}>
            <div>
              <BankChecks src={checkImg} />
            </div>

            <div>
              <MethodText>
                {type} {account}
              </MethodText>

              <BankFee>Fee: {fee || 'Free'}</BankFee>
              <Hidden xsDown> {continueBtn}</Hidden>
            </div>
          </ExistingBankInfo>

          <Hidden smUp>{continueBtn}</Hidden>
        </ExistingBankInfoWrapper>

        {debitEnabled && (
          <ExistingBankDebitWrapper>
            <PaymentNotes disabled={isSSO || !showNotes}>
              {debitAd}
            </PaymentNotes>
          </ExistingBankDebitWrapper>
        )}
      </ExistingBankWrapper>

      <LinksContainer direction="row" justifyRight>
        {isSSO && (
          <Flex flex={1} justifyLeft>
            <PaymentNotesBtn />
          </Flex>
        )}
        <UnderlinedButton light onClick={onCancel}>
          {!isSSO && 'Exit &'} Cancel Payment
        </UnderlinedButton>
        <UnderlinedButton onClick={onShowMore}>
          View all Payment Options
        </UnderlinedButton>
      </LinksContainer>
    </div>
  )
}

export default ExistingBankAccount
