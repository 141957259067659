// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import Card from '@Common/Card'
import { RadioButton, Select, TextInput } from '@Common/Form'
import loginBg from '@images/login_bg.png'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import belowFoldBg from '@images/below_fold_BG.png'
import { LinearProgress } from '@material-ui/core'
import Button from '@Common/Button'

export const Title = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.space.s30};
`

export const Desc = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: ${({ theme }) => theme.space.s30};
`

export const LoginStyled = styled('div')`
  min-height: 100vh;

  ${({ theme }) => [theme.breakpoints.up('md')]} {
    background: ${({ theme }) => theme.palette.common.white} url(${loginBg})
      left top no-repeat fixed;
    background-size: 50%;
  }
`

export const CardStyled = styled(Card)`
  border-radius: 75px 0 0 0;
  margin: auto;
  min-height: 100vh;
  padding: ${({ theme }) => theme.space.s140} ${({ theme }) => theme.space.s140}
    ${({ theme }) => theme.space.s140} ${({ theme }) => theme.space.s140};
  height: 100%;
  display: table;
  overflow: visible;
  position: relative;
  box-shadow: -5px 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    box-shadow: none !important;
  }
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    padding: 0;
    width: 100%;
    text-align: center;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 90%;
    left: 99%;
    top: 0;
    background: white;
    z-index: 1;
  }

  & .MuiCardContent-root {
    height: 100%;
    min-height: 455px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ theme }) => [theme.breakpoints.only('xs')]} {
      display: inline-flex;
      padding-top: 0;
    }
  }
`

export const LoginBox = styled('div')(
  ({ theme, isDeactivated }) => `
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: fit-content;
    width: fit-content;
    max-height: 90vh;

    & > * {
      width: 265px;
    }

    ${isDeactivated &&
    `
      width: 700px;
      ${theme.breakpoints.down('lg')} {
        height: 700px;
      }
      ${theme.breakpoints.down('md')} {
        width: 100%;
      }
      ${theme.breakpoints.down('sm')} {
        height: 600px;
      }
    `}
  `
)

export const PayLeaseLogoStyled = styled.img`
  width: 115px;
  height: 144px;
  margin-bottom: ${({ theme }) => theme.space.s47};
  margin-right: auto;
  object-fit: contain;
  align-self: center;
`

export const MobileOnlyImage = styled(PayLeaseLogoStyled)`
  ${({ theme }) => [theme.breakpoints.up('md')]} {
    display: none;
  }
`

export const ButtonsWrapper = styled(Flex)`
  margin-top: ${({ theme, marginTop }) => marginTop || theme.space.s80};
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`

export const FormStyled = styled('form')`
  width: 267px;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space.s35};
  align-items: center;

  & > .input {
    margin-bottom: ${({ theme }) => theme.space.s10};
    & input {
      height: 35px;
    }
    & fieldset {
      border-radius: 31px;
    }
  }

  & button {
    padding: 0;
    margin: 0;
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  }

  & button:last-of-type {
    margin-top: ${({ theme }) => theme.space.s15};
    text-transform: capitalize;
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  }

  & a {
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    color: ${({ theme }) => theme.palette.secondary.main};
    text-decoration: underline;
  }
`
export const StyledTooltip = withStyles(theme => ({
  tooltip: {
    position: 'relative',
    backgroundColor: theme.palette.common.iceberg,
    color: theme.palette.common.midnight,
    fontSize: theme.custom.font.size.sm,
    border: `1px solid ${theme.palette.secondary.light}`,
    padding: `11px ${theme.space.s25} 11px ${theme.space.s30}`,
    top: '-0.4em',
    right: '-10px',
    borderRadius: '0px',
    maxWidth: '525px',
    [theme.breakpoints.only('xs')]: {
      marginRight: '20px',
      top: '-1em'
    },
    '&::before': {
      content: '""',
      backgroundColor: theme.palette.common.iceberg,
      position: 'absolute',
      transform: 'rotate(45deg)',
      width: '10px',
      height: '10px',
      top: '-5px',
      right: props => props['arrowposition'],
      borderLeft: `1px solid ${theme.palette.secondary.light}`,
      borderTop: `1px solid ${theme.palette.secondary.light}`
    }
  }
}))(Tooltip)

export const DeliveryStyled = styled('div')`
  min-height: 100vh;

  ${({ theme }) => [theme.breakpoints.up('md')]} {
    background: ${({ theme }) => theme.palette.common.white} url(${belowFoldBg})
      left bottom no-repeat fixed;
    background-size: 99%;
  }
`

export const DeliveryCardStyled = styled('div')`
  margin: auto;
  min-height: 100vh;
  padding: 15vh ${({ theme }) => theme.space.s140} 0
    ${({ theme }) => theme.space.s140};
  height: 100%;
  display: table;
  overflow: visible;
  position: relative;
  text-align: center;
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    padding: ${({ theme }) => theme.space.s20};
    width: 100%;
  }

  & .MuiCardContent-root {
    height: 100%;
    min-height: 455px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ theme }) => [theme.breakpoints.only('xs')]} {
      display: inline-flex;
      padding-top: 0;
    }
  }
`

export const DeliveryHeader = styled('h1')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size': '300%';
`

export const DeliveryDesc = styled(Desc)`
  color: ${({ theme }) => theme.palette.common.midnight};
`

export const DeliveryDescEmailInput = styled(DeliveryDesc)`
  font-weight: 500;
  font-size: 24px;
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: 16px;
  }
`

export const DeliveryInfo = styled(Desc)`
  color: ${({ theme }) => theme.palette.common.midnight};
`

export const DeliveryPreferences = styled(Flex)`
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
  margin-bottom: ${({ theme }) => theme.space.s100};
`

export const DeliveryPreference = styled(RadioButton)`
  whitespace: 'nowrap';
  padding-right: ${({ theme }) => theme.space.s30};
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    width: 200px;
  }
`

export const DeliveryTopContainer = styled('div')`
  flex-grow: 1;
`

export const DeliveryReasons = styled(Select)`
  max-width: 360px;
  min-width: 200px;
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    max-width: 85vw;
    & > div {
      padding-right: 2em;
    }
  }
`
export const DeliveryTextInput = styled(TextInput)`
  width: 40%;
  padding-bottom: 100px;
  padding-top: 10px;
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    width: 100%;
  }
`

export const DeliveryPinInput = styled(DeliveryTextInput)`
  & .MuiInputBase-input {
    text-align: center;
    letter-spacing: 0.5em;
  }
`

export const DeliveryProgress = styled(LinearProgress)`
  height: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    bottom: 0;
  }
`

export const DeliveryButton = styled(Button)`
  &.disabled {
    color: ${({ theme }) => theme.palette.common.midnight};
    border: 3px solid ${({ theme }) => theme.palette.primary.main};
    background-color: transparent;
  }

  width: 200px;
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    width: 100%;
  }
`

export const FlexForm = styled('form')`
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

export const PinModal = styled('div')`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 80%;
  background: #ffffff;
  padding-top: 5%;
  outline: 0;
  border: none;
  text-align: center;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    height: 60%;
    width: 90%;
  }
  & img,
  source {
    width: 80%;
    ${({ theme }) => [theme.breakpoints.only('xs')]} {
      width: 90%;
    }
  }
`

export const ModalDesc = styled(DeliveryDescEmailInput)`
  padding-top: 2%;
  padding-left: 10px;
  padding-right: 10px;
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding-top: 15%;
  }
`

export const ModalCloseButton = styled('a')`
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    right: 10px;
    top: 10px;
  }
  &::hover {
    background-color: #00000;
  }
  &::before,
  &::after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 15px;
    width: 2px;
    background-color: #8a8d9e;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`

export const PasswordResetWrapper = styled(Flex)(
  ({ theme, fullWidth }) => `
    width: 480px;
    align-items: flex-end;

    ${theme.breakpoints.down('xs')} {
      width: 265px;
    }
  `
)

export const PasswordInfo = styled('div')(
  ({ theme, fullWidth }) => `
    background-color: ${theme.palette.common.lightBlue};
    width: ${fullWidth ? '100%' : '236px'};
    height: fit-content;
    padding: ${theme.space.s10};
    margin-top:  ${fullWidth ? theme.space.s20 : 0};
    margin-left: ${fullWidth ? 0 : theme.space.s45};
    margin-bottom:  ${fullWidth ? '-35px' : theme.space.s10};

    & ul {
      padding-left: ${theme.space.s30};
      margin-bottom: 0;
    }
    & li {
      list-style-type: square;
    }
  `
)

export const PasswordInfoTitle = styled('h4')(
  ({ theme }) => `
    margin: 0;
    font-weight: ${theme.custom.font.weight.regular};
    font-size: ${theme.custom.font.size.md};
  `
)
export const Bold = styled('span')`
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
`
