// @flow
import React, { Fragment, useState } from 'react'
import Dinero from 'dinero.js'
import { amountToNumber } from 'zego-shared/utils/number'
import { Select } from '../../Common/Form'
import Delete from '../../Common/Delete'
import { DividerStyled } from '../Layout/styles'
import CurrencyTextInput from '../CurrencyTextInput'
import { FormGroupStyled } from './styles'
import type { Field } from './types'

type Props = {
  fields: Field[],
  initiallySelected: Field[],
  dropdownLabel: string,
  onFieldChange: (field: Field, allFields: Field[]) => void,
  onFieldRemoved: (field: Field) => void,
}

const OptionalFields = ({
  fields,
  initiallySelected = [],
  dropdownLabel = '',
  onFieldChange = () => {},
  onFieldRemoved = () => {},
}: Props) => {
  const [selectedFields, setSelectedFields] = useState(initiallySelected)

  if (!fields || !fields.length) {
    return null
  }

  const selectedFieldsKeys = selectedFields.map(({ key }) => key)
  const fieldOptions = fields
    .filter(({ key }) => !selectedFieldsKeys.includes(key))
    .map(({ label, key }) => ({ label, value: key }))

  const handleFieldAmountChange = (event: SyntheticInputEvent<>) => {
    const { value, name } = event.target
    const amount = amountToNumber(value)
    const fieldIndex = selectedFields.findIndex(({ key }) => name === key)

    if (Number.isNaN(amount) || -1 === fieldIndex) {
      return
    }

    const oldField = selectedFields[fieldIndex]
    const newAmount = Dinero({ amount, currency: oldField.currency })

    const newField = { ...oldField, amount: newAmount.toFormat('0.00') }
    const newFields = [...selectedFields.slice(0, fieldIndex), newField, ...selectedFields.slice(fieldIndex + 1)]
    setSelectedFields(newFields)
    onFieldChange(newField, newFields)
  }

  const handleSelectField = (event) => {
    const { value } = event.target
    const field = fields.find((field) => field.key === value);
    const newFields = [...selectedFields, field]
    setSelectedFields(newFields)
    onFieldChange(field, newFields)
  }

  const getHandleRemoveField = (field) => () => {
    setSelectedFields(selectedFields.filter(({ key }) => field.key !== key))
    onFieldRemoved(field)
  }

  return (
    <Fragment>
      <DividerStyled/>
      {selectedFields.map(field => {
        const { label, amount, symbol, key, locked } = field
        return (
          <FormGroupStyled label={`${label} (optional)`} key={key}>
            <CurrencyTextInput
              symbol={symbol}
              onChange={handleFieldAmountChange}
              name={key}
              value={amount}
              inputProps={{ readOnly: locked }}
              renderOptional={() => <Delete onClick={getHandleRemoveField(field)}/>}
            />
          </FormGroupStyled>
        )
      })}
      {
        fieldOptions.length
          ? <FormGroupStyled label={dropdownLabel || "Add Another Payment Type"}>
            <Select placeholder="Select one" options={fieldOptions} onChange={handleSelectField} />
          </FormGroupStyled>
          : null
      }
    </Fragment>
  )
}

export default OptionalFields
