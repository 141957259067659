// @flow
import { getDate, isToday, isYesterday } from '../../../../utils/date'

const closed: string = 'Closed'
const open: string = 'Open'

const getEventDate = (date: string) => {
  if (isToday(date)) {
    return 'Today'
  }
  if (isYesterday(date)) {
    return 'Yesterday'
  }
  return getDate(date)
}

const getStatus = (status: string) => {
  return status === 'closed' ? closed : open
}

const getReporter = (reporter: string) => {
  if (reporter === null) {
    return ''
  }

  return ` - by ${reporter}`
}

const getTitle = (data: Object) => {
  if (getStatus(data.calculated_status) === open)
    return `Submitted ${getEventDate(data.reported_date)}${
      getReporter(data.reported_by)
    }`
  if (getStatus(data.calculated_status) === closed)
    return `Closed ${getDate(data.reported_date)}${getReporter(data.reported_by)}`
}

const getMessage = (data: Object) => {
  return {
    messageTitle: getTitle(data),
    messageBody: data.message
  }
}

const groupHistoryData = (historyData: Array<Object>) => {
  const { openWorkOrders, closedWorkOrders } = historyData.reduce(
    (acc, history) => {
      if (history.status === open) {
        acc.openWorkOrders.push(history)
      }

      if (history.status === closed) {
        acc.closedWorkOrders.push(history)
      }
      return acc
    },
    { openWorkOrders: [], closedWorkOrders: [] }
  )

  return {
    data: [...openWorkOrders, ...closedWorkOrders],
    breakPoint: openWorkOrders.length
  }
}

const historyToHistoryData = (history: Array<Object>) => {
  const historyData = history.map(data => {
    return {
      date: getEventDate(data.reported_date),
      message: getMessage(data),
      status: getStatus(data.calculated_status)
    }
  })

  return groupHistoryData(historyData)
}

export const getWorkOrderHistory = (state: Object) => {
  const history = state.workOrders.history.data

  return history ? historyToHistoryData(history) : []
}

export const getOpenWorkOrders = (state: Object) => {
  const history = state.workOrders.history.data

  return history.filter(history => getStatus(history.calculated_status) === open)
}
