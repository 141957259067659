import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import SSOEMailBg from '@images/sso_email_collection_bg.png'
import GoBtnBG from '@images/auto_pay_ad/go_btn_bg.png'
import { TextInput } from '@Common/Form'

export const Base = styled(Flex)`
  background-image: ${({ showBg }) => showBg && `url(${SSOEMailBg})`};
  background-position: right center;
  background-repeat: no-repeat;
  height: 55px;
  padding: ${({ theme }) => theme.space.s16};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    line-height: 14px;
  }
`

export const LeftDesktop = styled(Flex)`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.md};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  flex-direction: column;
  justify-content: center;
  line-height: 16px;
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    display: none;
  }
`

export const SubText = styled.div`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.md};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    text-align: ${({ alignRightOnMobile }) => alignRightOnMobile && 'right'};
    line-height: 14px;
  }
`

export const LeftMobile = styled.div`
  display: none;
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    display: block;
    color: ${({ theme }) => theme.palette.common.grey};
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    width: 92px;
  }
`

export const Right = styled(Flex)`
  align-items: center;
`

export const GoButton = styled.div`
  height: 24px;
  width: 51.5px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
  line-height: 16px;
  font-size: ${({ theme }) => theme.custom.font.size.md};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  padding: 3px 0;
  margin-left: ${({ theme }) => theme.space.s10};
  z-index: 0;
  display: inline-block;
  cursor: pointer;

  &:before{
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    top: 0;
    left: 0;
    height: 24px;
    width: 0;
    transition: 0.25s width;
    z-index: -1;
  }

  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 100%;
    background-image: url('${GoBtnBG}');
    background-size: 100% 100%;
    z-index: 1;
  }
`

export const StyledInput = styled(TextInput)`
  color: ${({ theme }) => theme.palette.neutral.dark};
  margin-left: ${({ theme }) => theme.space.s6};
`

export const HideOnSM = styled.div`
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    display: none;
  }
`

export const EditBtn = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.common.iceberg};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  margin-left: ${({ theme }) => theme.space.s10};
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  width: 42px;
  height: 20px;
  cursor: pointer;
`
