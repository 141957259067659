// @flow

import { createSelector } from 'reselect'
import get from 'lodash/get'
import { formatDateMDYY, getFullDateTimeInPacificTimezone } from '../../../../utils/date'
import { humanizeString } from '../../../../utils/string'
import Dinero from 'dinero.js'
import { getUserName } from '../../../authorization/selectors'
import { getProperty } from '../../../userResource/selectors'
import { getUnitCompany } from '../../../select/selectors'

const maxReasonLength = 100

export const hasNextPage = (state: Object) => {
  const hasNextPage = get(state, 'payments.history.hasNextPage')
  return hasNextPage || false
}

export const getNextCursor = (state: Object) => {
  const history = get(state, 'payments.history.edges')
  if (!history || history.length === 0) return null
  const { cursor } = history[history.length - 1]
  return cursor
}

const getPaymentMethodName = (paymentMethodName: String) => {
  return paymentMethodName.includes('PayPal') ? 'PayPal' : paymentMethodName
}

export const getHistory = (state: Object) => {
  const history = get(state, 'payments.history.edges')
  if (!history) return []

  return history.map(
    ({
      cursor,
      node: {
        transactionDate,
        totalAmount,
        status,
        paymentMethodName,
        isAutopay,
        voidable,
        paymentType,
        transId
      }
    }) => {
      return {
        account: getPaymentMethodName(paymentMethodName),
        amount: Dinero(totalAmount).toFormat(),
        type: isAutopay ? 'Auto pay' : 'One-time',
        date: formatDateMDYY(transactionDate),
        status: status.toLowerCase(),
        transId,
        voidable,
        paymentType,
        cursor
      }
    }
  )
}

export const hasHistory = (state: Object) => {
  const history = get(state, 'payments.history.edges')
  if (!history || history.length === 0) return false
  return true
}
const historySelector = state => get(state, 'payments.history.edges')

export const getTransaction = createSelector(
  [
    historySelector,
    (state: Object, selectedTransId: number) => selectedTransId
  ],
  (history, selectedTransId) => {
    if (!history) return null

    const transaction = history.find(
      ({ node: { transId } }) => transId === selectedTransId
    )
    if (!transaction) return null
    const {
      node: {
        transactionDate,
        totalAmount,
        feeAmount,
        status,
        paymentMethodName,
        isAutopay,
        transId,
        paymentFields,
        propertyName,
        paymentType,
        adjustmentDetails,
        notes,
        isExpressPay,
        expressPayFee,
      }
    } = transaction

    const reasonCode = get(adjustmentDetails, '0.reason', '')
    const reasonSummary = get(adjustmentDetails, '0.reasonSummary', '')
    const returnReason = (reasonCode && `${reasonCode} ${reasonSummary}`).substring(0, maxReasonLength)
    const totalFeeAmount = Dinero(feeAmount)
    const expressPayFeeAmount = Dinero(expressPayFee)
    const platformFee = isExpressPay ? totalFeeAmount.subtract(expressPayFeeAmount) : totalFeeAmount;

    return {
      transId,
      totalAmount: Dinero(totalAmount).toFormat(),
      feeAmount: platformFee.toFormat(),
      isExpressPay,
      expressPayFeeAmount: expressPayFeeAmount.toFormat(),
      status: humanizeString(status),
      transactionDate: formatDateMDYY(transactionDate),
      extendedTransactionDate: getFullDateTimeInPacificTimezone(transactionDate),
      type: isAutopay ? 'AutoPay' : 'OneTime',
      propertyName,
      paymentMethodName,
      paymentFields: paymentFields.map(({ label, money }) => ({
        label,
        value: Dinero(money).toFormat()
      })),
      returnReason,
      paymentType: paymentType === 'ach' ? 'ACH' : humanizeString(paymentType),
      notes
    }
  }
)

export const getRecipient = createSelector(
  [getProperty, getUnitCompany],
  (property: Object, companyName: String) => {
    const { name, state = '', address = '' } = property
    return {
      propertyName: name,
      address: state && address ? `${state}, ${address}` : address || state,
      companyName
    }
  }
)

export const getResidentFullName = getUserName
