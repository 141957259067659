// @flow
import React, { Fragment, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { fetchTermsAndConditionsAction } from 'zego-shared/store/termsAndConditions/actions'
import { getLatestTAndC } from 'zego-shared/store/termsAndConditions/selectors'
import {
  useStyles,
  StyledTextInput,
  StyledCheckBox,
  StyledLink,
  StyledButton
} from './styles'

type Props = {
  dirty: boolean,
  values: Object,
  handleChange: Function,
  handleBlur: Function,
  handleSubmit: Function,
  isSubmitting: boolean,
  isValid: boolean
}

const LoginLinkForm = ({
  dirty,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  isValid
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { url: termsAndConditionsUrl } = useSelector(getLatestTAndC)

  useEffect(() => {
    dispatch(fetchTermsAndConditionsAction())
  }, [dispatch])

  return (
    <form onSubmit={handleSubmit}>
      <Grid item container direction="column" className={classes.formContainer}>
        <Grid item>
          <StyledTextInput
            name="email"
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            helperText="The email you currently use to login to Zego."
          />
        </Grid>
        <Grid item>
          <StyledTextInput
            name="password"
            type="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            helperText="The password you currently use to login to Zego."
          />
        </Grid>
        <Grid item>
          <StyledCheckBox
            label={
              <Fragment>
                I have confirmed the name on the account is correct and I agree
                with the{' '}
                <StyledLink
                  href={termsAndConditionsUrl}
                  target="_blank"
                  rel="noopener noreferrer">
                  terms and conditions
                </StyledLink>{' '}
                of use.<sup>*</sup>
              </Fragment>
            }
            name="agreement"
            onChange={handleChange}
            onBlur={handleBlur}
            checked={values.agreement}
          />
        </Grid>
      </Grid>
      <Grid item>
        <StyledButton
          outline
          disableRipple
          primary
          fullWidth
          type="submit"
          disabled={isSubmitting || !dirty || !isValid}>
          Login to Link Property
        </StyledButton>
      </Grid>
    </form>
  )
}

export default LoginLinkForm
