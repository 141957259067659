import { SET_POS } from './actions'

const reducer = (state = 0, action) => {
  switch (action.type) {
    case SET_POS:
      return action.position
    default:
      return state
  }
}

export default reducer
