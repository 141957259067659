// @flow
import React from 'react'
import { getDate } from 'zego-shared/utils/date'
import { Grid } from '@material-ui/core'
import { withTheme } from 'emotion-theming'
import withWidth from '@material-ui/core/withWidth'
import {
  InfoLane,
  OtherDetails,
  PaymentInfo,
  StyledButton,
  PaymentInfoContainerStyled,
  IconButtonContainerStyled
} from '../styles'
import { CardStyled, ButtonsStyled, HeaderText } from './styles'

import { EditIconButton, ListIconButton } from '../../common/IconButtons'
import ChargeBreakdown from '../../ChargeBreakdown'

import type { Theme } from '../../../../theme'

type Props = {
  userId: string,
  width: string,
  theme: Theme,
  totalOwed: Object,
  nextAutoPay: string,
  onClickPayNow: boolean => void,
  onClickViewAutoPay: () => void,
  totalOwedLastUpdated: Object,
  hasEditableFields: boolean,
  lineItemsDetails: Array<Object>,
  disableMakePaymentMenuTab: boolean
}

const isSmallScreen = (width: string) => {
  return width === 'xs' || width === 'sm'
}

export const WithBalanceAutoPay = (props: Props) => {
  const {
    theme,
    totalOwed,
    totalOwedLastUpdated,
    nextAutoPay,
    onClickPayNow,
    onClickViewAutoPay,
    width,
    hasEditableFields,
    lineItemsDetails,
    disableMakePaymentMenuTab
  } = props
  const [showChargeBreakdown, setShowChargeBreakdown] = React.useState(false)
  const isSSO = theme.ux.isSSO

  const goToEditBalance = () => {
    onClickPayNow(hasEditableFields)
  }

  const handleShowChargeBreakdown = () =>
    setShowChargeBreakdown(!showChargeBreakdown)

  const getViewAutoPayButtonWidth = () => {
    if(!disableMakePaymentMenuTab){
      return isSSO ? 6 : 5
    } else {
      return 12
    }
  }

  return (
    <CardStyled>
      <InfoLane fullWidth justifySpaceBetween marginTop="3px">
        <HeaderText black>My Current Balance</HeaderText>
        {lineItemsDetails.length > 0 ? (
          <IconButtonContainerStyled>
            <ListIconButton
              id="chargeBreakdownIcon"
              onClick={handleShowChargeBreakdown}
              color={theme.palette.neutral.dark}
            />
            <ChargeBreakdown
              lineItemsDetails={lineItemsDetails}
              isOpen={showChargeBreakdown}
              handleClose={handleShowChargeBreakdown}
            />
          </IconButtonContainerStyled>
        ) : null}
      </InfoLane>
      <PaymentInfoContainerStyled>
        <InfoLane
          justifySpaceBetween
          marginTop={width === 'xs' ? theme.space.s10 : theme.space.s20}>
          <PaymentInfo primary H1 lineHeight="50px">
            <span>{totalOwed && totalOwed.toFormat()}</span>
          </PaymentInfo>
        </InfoLane>
        {hasEditableFields ? (
          <IconButtonContainerStyled style={{ marginTop: '15px' }}>
            <EditIconButton id="editIcon" onClick={goToEditBalance} />
          </IconButtonContainerStyled>
        ) : null}
      </PaymentInfoContainerStyled>
      <InfoLane fullWidth marginTop="5px" marginLeft="5px">
        {totalOwedLastUpdated && (
          <OtherDetails>as of {getDate(totalOwedLastUpdated)}</OtherDetails>
        )}
      </InfoLane>
      <InfoLane
        fullWidth
        marginTop={
          isSSO || isSmallScreen(width) ? theme.space.s15 : theme.space.s80
        }>
        <OtherDetails primary H5>
          Next Auto Pay will run on {nextAutoPay}
        </OtherDetails>
      </InfoLane>

      <ButtonsStyled fullWidth marginTop={theme.space.s20}>
        <Grid container justify="space-between" spacing={3}>
          <Grid xs={getViewAutoPayButtonWidth()} item>
            <StyledButton
              fullWidth
              onClick={onClickViewAutoPay}
              primary>
              {width === 'xs' ? 'Detail' : 'View Auto Pay'}
            </StyledButton>
          </Grid>
          {!disableMakePaymentMenuTab && (
            <Grid xs={isSSO ? 6 : 7} item>
              <StyledButton fullWidth primary onClick={onClickPayNow}>
                Pay now
              </StyledButton>
            </Grid>
          )}
        </Grid>
      </ButtonsStyled>
    </CardStyled>
  )
}

export default withWidth()(withTheme(WithBalanceAutoPay))
