// @flow
import React from 'react'
import WithLeftSideNav from '@Common/WithLeftSideNav'
import WorkOrdersNavRoutes from '../../routes/WorkOrders/WorkOrdersNavRoutes'

type Props = {
  unitId: number
}

const WorkOrders = (props: Props) => {
  const { unitId } = props

  const workOrderNavLinks = [
    {
      name: 'Summary',
      url: `/units/${unitId}/work-orders/summary`
    },
    {
      name: 'History',
      url: `/units/${unitId}/work-orders/history`
    },
    {
      name: 'Submit New',
      url: `/units/${unitId}/work-orders/new`
    }
  ]

  return (
    <WithLeftSideNav routes={workOrderNavLinks}>
      <WorkOrdersNavRoutes />
    </WithLeftSideNav>
  )
}

export default WorkOrders
