// @flow

import React from 'react'
import Button from '@Common/Button'
import Loader from '@Common/Loader'
import { Select, FormGroup, TextInput } from '@Common/Form'
import Snackbar from '@Common/Snackbar'
import payLeaseLogo from '@images/paylease_logo.png'
import {
  LoginStyled,
  CardStyled,
  FormStyled,
  PayLeaseLogoStyled,
  Title,
  Desc
} from './styles'

type Props = {
  propertyId: number,
  tenantCode: string,
  error: string,
  email: string,
  updateResidentBillingPref: Function
}

type State = {
  preference: string,
  reason: string,
  validSubmit: boolean,
  working: boolean
}

class ChangeDeliveryType extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      preference: '',
      reason: '',
      validSubmit: false,
      working: true
    }

    this.preferenceOptions = [
      { label: 'Paper Bill', value: '3' },
      { label: 'Electronic', value: '4' },
      { label: 'Both', value: '5' }
    ]
    this.reasonCodes = [
      { label: `I don't check my email`, value: 'DontCheckEmail' },
      { label: 'I receive too many emails', value: 'ReceiveManyEmails' },
      { label: 'I prefer paper statements', value: 'PreferPaper' },
      {
        label: 'I need paper statements for expense documentation',
        value: 'NeedPaper'
      }
    ]
  }

  componentDidMount() {
    const { linkToken: token, validateEmailToken } = this.props
    if (token) {
      validateEmailToken(token)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { email, preference, reason, error } = this.props
    if (prevProps.email !== email || prevProps.error !== error) {
      this.setState({
        working: false,
        preference,
        reason,
        validSubmit: email !== '',
        error
      })
    }
  }

  handleSubmit = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault()
    const {
      updateResidentBillingPref,
      propertyId,
      tenantCode,
      linkToken: token
    } = this.props
    const { email, preference, reason } = this.state

    let residentPrefs = {
      email: email,
      preference: preference,
      reason: reason,
      propertyId: propertyId,
      tenantCode: tenantCode,
      infoMessage: "Billing Preferences Updated"
    }

    updateResidentBillingPref(token, residentPrefs)
  }

  handlePreference = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { preference, reason } = this.state
    this.setState({
      preference: event.target.value,
      validSubmit: preference === '4' || reason !== ''
    })
  }

  handleReason = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { preference, reason } = this.state
    this.setState({
      reason: preference !== '4' ? event.target.value : '',
      validSubmit: preference === '4' || reason !== ''
    })
  }

  getFormView = () => {
    const { email } = this.props
    const { preference, reason, validSubmit } = this.state
    return (
      <>
        <LoginStyled>
          <CardStyled
            CardContentProps={{
              classes: {
                root: 'card-content-root'
              }
            }}>
            <PayLeaseLogoStyled src={payLeaseLogo} alt="paylease logo" />
            <Title>Statement Delivery Preference</Title>
            <Desc>Change how you receive your monthly statement below.</Desc>

            <FormStyled onSubmit={this.handleSubmit}>
              <FormGroup id="email" label="Email" fullWidth>
                <TextInput
                  className="input"
                  placeholder="email"
                  value={email}
                  disabled
                />
              </FormGroup>
              <FormGroup
                id="preferenceDropdown"
                label="Delivery Preference"
                fullWidth>
                <Select
                  options={this.preferenceOptions}
                  initialValue={preference}
                  onChange={this.handlePreference}
                  disabled={!email}></Select>
              </FormGroup>
              {preference !== '4' ? (
                <FormGroup id="reasonDropdown" label="Reason" fullWidth>
                  <Select
                    options={this.reasonCodes}
                    initialValue={reason}
                    onChange={this.handleReason}
                    disabled={!email}></Select>
                </FormGroup>
              ) : null}
              <Button
                id="preferenceChangeSubmit"
                disableRipple
                disabled={!validSubmit || !email}
                classes={{ label: 'login-label' }}
                fullWidth
                primary
                type="submit">
                Submit
              </Button>
              <Title>
                Need additional help?{' '}
                <a
                  className="help-link"
                  href="https://gozego.force.com/residents/s/">
                  Click Here
                </a>
              </Title>
            </FormStyled>
          </CardStyled>
          <Snackbar />
        </LoginStyled>
        <Snackbar />
      </>
    )
  }

  render() {
    const { working } = this.state
    if (working) return <Loader />
    return this.getFormView()
  }
}

export default ChangeDeliveryType
