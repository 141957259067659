import styled from '@emotion/styled'

export const Loading = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  max-height: 100%;
  width: 100%;
`
