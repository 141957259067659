// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import { Grid } from '@material-ui/core'
import { InfoLane } from '../styles'

export const LastPaymentStyled = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.common.white};
  flex-direction: column;
  height: ${({ theme }) => (theme.ux.isSSO ? '315px' : '378px')};
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(14, 20, 39, 0.5);
  padding: ${({ theme }) => theme.space.s40};
  position: relative;

  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    padding: ${({ theme }) =>
      theme.ux.isSSO ? theme.space.s25 : theme.space.s30};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    padding: ${({ theme }) => theme.space.s20};
    margin-bottom: 20px;
  }
`
export const ButtonsStyled = styled(InfoLane)`
  position: relative;
`

export const InfoContainerStyled = styled(Grid)`
  margin-top: ${({ theme }) => theme.space.s30};

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin-top: ${({ theme }) => theme.space.s5};
  }
`

export const BolderText = styled('div')`
  margin-top: ${({ theme }) => theme.space.s10};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  min-height: 24px;
  line-height: 19px;

  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    line-height: 28px;
  }
`

export const BoldText = styled('div')`
  margin-top: ${({ theme }) => theme.space.s10};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`
