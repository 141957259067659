// @flow
import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import Flex from '@Common/Flex'
import FormGroup from '@Common/Form/FormGroup'
import appImg from '@images/download_app.png'

const marginBottom = '33px'

export const ImageArea = styled(Grid)`
  background: url(${appImg});
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 50%;
  height: 0;
`

export const ContentArea = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8.333%;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    display: none;
  }

  & h1 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme }) => theme.custom.font.size.H2};
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    line-height: 58px;
    margin-bottom: ${marginBottom};
    margin-top: 74px;
  }

  & p {
    max-width: 549px;
    min-height: 100px;
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
    line-height: 24px;
    margin: 0;
    margin-bottom: ${marginBottom};
  }
`

export const TextMe = styled(Flex)`
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    display: none;
  }

  & button {
    min-width: 188px;
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    margin-top: 16px;
    margin-left: 19px;
    text-transform: capitalize;
  }
`

export const AppStoreBtns = styled(Flex)`
  min-height: 71px;
  margin-bottom: 46px;
`

export const AppStoreBtn = styled.a`
  border: none;
  outline: 0;
  cursor: pointer;
  padding: 0;
  background-color: transparent;

  &:last-child {
    margin-left: 20px;
  }
`

export const FormGroupStyled = styled(FormGroup)`
  width: 264px;

  & p{
    display: none;
  }
`
