import { call, take, takeLatest, put, select, delay } from 'redux-saga/effects'
import { showSnackbar } from '../../../snackbar/actions'
import { getGraphQlData } from '../../../../utils/graphql'
import { fetchPaymentOptions } from '../../options/sagas'
import { fetchPaymentBalance } from '../../balance/sagas'
import { fetchLastUsedMethod, fetchPaymentMethods } from '../../methods/sagas'
import { fetchPaymentOptionFeesSaga } from '../../fees/sagas'
import { getPaymentFieldsAsPayload } from '../../balance/selectors'
import { fetchUserResource } from '../../../userResource/actions'
import { autoPaySuccess, autoPayFail } from '../../../../utils/messages'
import { FAP, VAP, VAP_AND_FAP } from '../constants'
import { FEE_TYPE } from '../../fees/actions'
import api from '../../../../utils/api'
import { getPaymentToken, getUserId } from '../../../authorization/selectors'
import {
  getAutoPayConfigQuery,
  GET_CONFIG,
  GET_AUTO_PAYS,
  GET_CASH_PAY_CONFIG,
  getAutopaysQuery,
  getCashPayConfigQuery
} from '../graphql/queries'
import {
  createVariableAutoPay,
  createFixedAutoPay,
  cancelAutopayMutation,
  cancelSkipAutopayMutation,
  skipAutopayMutation,
  getAutoPayMutVars,
  CREATE_FAP,
  CREATE_VAP
} from '../graphql/mutations'
import {
  getAutoPayState,
  getPaymentAmountType,
  getVAPPayload,
  getFAPPayload
} from '../selectors'
import {
  genericErrorMsg,
  failedToSkipAutoPay,
  failedToCancelAutoPay,
  failedToCancelAutoPaySkip
} from '../../../../utils/messages'
import {
  fetchAutoPayConfigAction,
  fetchAutoPayConfigError,
  fetchAutoPayConfigStart,
  fetchAutoPayConfigSuccess,
  fetchCashPayConfigAction,
  fetchCashPayConfigStart,
  fetchCashPayConfigSuccess,
  fetchCashPayConfigError,
  fetchAutoPayStateAction,
  fetchAutoPayStateError,
  fetchAutoPayStateStart,
  fetchAutoPayStateSuccess,
  fetchAutoPayDataStart,
  fetchAutoPayDataSuccess,
  fetchAutoPayDataAction,
  fetchAutoPayDataError,
  fetchAutoPaysAction,
  fetchAutoPaysStart,
  fetchAutoPaysSuccess,
  fetchAutoPaysError,
  AUTO_PAY_AMOUNT_TYPE_CHANGE,
  createAPAction,
  createAPError,
  createAPStart,
  createAPSuccess,
  CANCEL_AUTOPAY,
  SKIP_AUTOPAY,
  CANCEL_SKIP_AUTOPAY,
  cancelFixedAutopaySuccess,
  cancelVariableAutopaySuccess,
  cancelAutopayError,
  cancelSkipAutopayError,
  skipFixedAutopaySuccess,
  skipVariableAutopaySuccess,
  cancelSkipVariableAutopaySuccess,
  cancelSkipFixedAutopaySuccess,
  saveDetails
} from '../actions'

export function* fetchAutoPayConfig() {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = getAutoPayConfigQuery()
    yield put(fetchAutoPayConfigStart())
    const response = yield call(api.graphqlQuery, tokens, query)
    const payload = yield call(getGraphQlData, response, GET_CONFIG)
    yield put(fetchAutoPayConfigSuccess(payload))
  } catch (error) {
    yield put(fetchAutoPayConfigError(error))
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* fetchCashPayConfig() {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = getCashPayConfigQuery()
    yield put(fetchCashPayConfigStart())
    const response = yield call(api.graphqlQuery, tokens, query)
    const payload = yield call(getGraphQlData, response, GET_CASH_PAY_CONFIG)
    yield put(fetchCashPayConfigSuccess(payload))
  } catch (error) {
    yield put(fetchCashPayConfigError(error))
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* fetchAutoPays({ residentId }) {
  try {
    residentId = residentId ? residentId : yield select(getUserId)
    yield put(fetchAutoPaysStart())
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = getAutopaysQuery()
    const variables = { residentId }
    const response = yield call(api.graphqlQuery, tokens, query, variables)
    const payload = yield call(getGraphQlData, response, GET_AUTO_PAYS)

    yield put(fetchAutoPaysSuccess(payload))
  } catch (error) {
    yield put(fetchAutoPaysError(error))
  }
}

export function* cancelAutopay({ id, autopayType, redirectArgs }) {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = cancelAutopayMutation

    const variables = getAutoPayMutVars(id, autopayType)
    const response = yield call(api.graphqlQuery, tokens, query, variables)
    const getResponse = yield call(getGraphQlData, response, 'cancelAutopay')

    autopayType === 'Fixed'
      ? yield put(cancelFixedAutopaySuccess(id))
      : yield put(cancelVariableAutopaySuccess(id))

    const { disableAutopayMenuTab, autopayCount, history } = redirectArgs
    if (disableAutopayMenuTab && 1 === autopayCount) {
      history.push('/payments/summary')
    } else {
      yield put(showSnackbar(getResponse.message, 'success'))
    }
  } catch (error) {
    yield put(showSnackbar(failedToCancelAutoPay, 'error'))
    yield put(cancelAutopayError(error))
  }
}

export function* skipAutopay({ id, autopayType }) {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = skipAutopayMutation

    const variables = getAutoPayMutVars(id, autopayType)
    const response = yield call(api.graphqlQuery, tokens, query, variables)
    const getResponse = yield call(getGraphQlData, response, 'skipAutopay')
    const skipped = true

    autopayType === 'Fixed'
      ? yield put(skipFixedAutopaySuccess(id, skipped))
      : yield put(skipVariableAutopaySuccess(id, skipped))

    yield put(fetchAutoPaysAction())
    yield take(fetchAutoPaysSuccess().type)
    yield put(showSnackbar(getResponse.message, 'success'))
  } catch (error) {
    yield put(showSnackbar(failedToSkipAutoPay, 'error'))
    yield put(cancelAutopayError(error))
  }
}

export function* cancelSkipAutopay({ id, autopayType }) {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = cancelSkipAutopayMutation

    const variables = getAutoPayMutVars(id, autopayType)
    const response = yield call(api.graphqlQuery, tokens, query, variables)
    const getResponse = yield call(
      getGraphQlData,
      response,
      'cancelSkipAutopay'
    )
    const skipped = false

    autopayType === 'Fixed'
      ? yield put(cancelSkipFixedAutopaySuccess(id, skipped))
      : yield put(cancelSkipVariableAutopaySuccess(id, skipped))

    yield put(fetchAutoPaysAction())
    yield take(fetchAutoPaysSuccess().type)
    yield put(showSnackbar(getResponse.message, 'success'))
  } catch (error) {
    yield put(showSnackbar(failedToCancelAutoPaySkip, 'error'))
    yield put(cancelSkipAutopayError(error))
  }
}

export function* fetchAutoPayState() {
  try {
    yield put(fetchAutoPayStateStart())
    const token = yield select(getPaymentToken)
    const response = yield call(api.getAutoPayState, token)
    yield put(fetchAutoPayStateSuccess(response))
  } catch (error) {
    yield put(fetchAutoPayStateError(error))
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

function* fetchFees(autoPayState, paymentFields) {
  if (VAP === autoPayState) {
    yield call(fetchPaymentOptionFeesSaga, {
      feeType: FEE_TYPE.VARIABLE
    })
  } else if (autoPayState === FAP) {
    if (paymentFields.length > 0) {
      yield call(fetchPaymentOptionFeesSaga, {
        paymentFields,
        feeType: FEE_TYPE.FIXED
      })
    }
  }
}

export function* fetchAutoPayFees() {
  const paymentFields = yield select(getPaymentFieldsAsPayload)
  const autoPayState = yield select(getAutoPayState)

  if (autoPayState === VAP_AND_FAP) {
    const paymentAmountType = yield select(getPaymentAmountType)
    yield fetchFees(paymentAmountType, paymentFields)
  } else {
    yield fetchFees(autoPayState, paymentFields)
  }
}

function* create(autoPayState, tokens, onSuccess) {
  const residentId = yield select(getUserId)

  if (autoPayState === VAP) {
    const input = yield select(getVAPPayload)
    const response = yield call(
      api.graphqlQuery,
      tokens,
      createVariableAutoPay,
      { input }
    )
    const payload = yield call(getGraphQlData, response, CREATE_VAP)
    yield put(createAPSuccess(payload))
  } else if (autoPayState === FAP) {
    const input = yield select(getFAPPayload)
    const response = yield call(api.graphqlQuery, tokens, createFixedAutoPay, {
      input
    })
    const payload = yield call(getGraphQlData, response, CREATE_FAP)
    yield put(createAPSuccess(payload))
  }

  yield put(fetchAutoPaysAction())
  yield take(fetchAutoPaysSuccess().type)

  yield put(showSnackbar(autoPaySuccess, 'success'))
  yield delay(500)
  yield call(fetchAutoPays, {residentId})
  yield put(saveDetails(null))
  if (onSuccess) {
    onSuccess()
  }
}

export function* createAutoPay({ onSuccess }) {
  try {
    yield put(createAPStart())
    const autoPayState = yield select(getAutoPayState)
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }

    if (autoPayState === VAP_AND_FAP) {
      const paymentAmountType = yield select(getPaymentAmountType)
      yield create(paymentAmountType, tokens, onSuccess)
    } else {
      yield create(autoPayState, tokens, onSuccess)
    }
  } catch (error) {
    yield put(createAPError(error))
    yield put(showSnackbar(autoPayFail, 'error'))
  }
}

export function* fetchAutoPayData({ residentId }) {
  try {
    yield put(fetchAutoPayDataStart())
    yield call(fetchAutoPayState)
    yield call(fetchAutoPayConfig)
    yield call(fetchCashPayConfig)
    yield call(fetchAutoPays, { residentId })
    yield call(fetchPaymentBalance, { residentId })
    yield call(fetchPaymentOptions, { isOnetimePayment: false })
    yield call(fetchPaymentMethods, { residentId, isOnetimePayment: false, checkAllowed: true })
    yield call(fetchLastUsedMethod)
    yield call(fetchAutoPayFees)
    yield put(fetchAutoPayDataSuccess())

    yield put(fetchUserResource(residentId))
  } catch (error) {
    yield put(fetchAutoPayDataError(error))
  }
}

function* watchFetchAutoPayConfig() {
  yield takeLatest(fetchAutoPayConfigAction().type, fetchAutoPayConfig)
}

function* watchFetchCashPayConfig() {
  yield takeLatest(fetchCashPayConfigAction().type, fetchCashPayConfig)
}

function* watchFetchAutoPays() {
  yield takeLatest(fetchAutoPaysAction().type, fetchAutoPays)
}

function* watchCancelAutopay() {
  yield takeLatest(CANCEL_AUTOPAY, cancelAutopay)
}

function* watchSkipAutopay() {
  yield takeLatest(SKIP_AUTOPAY, skipAutopay)
}

function* watchCancelSkipAutopay() {
  yield takeLatest(CANCEL_SKIP_AUTOPAY, cancelSkipAutopay)
}

function* watchFetchAutoPayState() {
  yield takeLatest(fetchAutoPayStateAction().type, fetchAutoPayState)
}

function* watchFetchAutoPayData() {
  yield takeLatest(fetchAutoPayDataAction().type, fetchAutoPayData)
}

function* watchFetchAutoPayFees() {
  yield takeLatest(AUTO_PAY_AMOUNT_TYPE_CHANGE, fetchAutoPayFees)
}

function* watchCreateAP() {
  yield takeLatest(createAPAction().type, createAutoPay)
}

export default [
  watchCreateAP(),
  watchSkipAutopay(),
  watchFetchAutoPays(),
  watchCancelAutopay(),
  watchFetchAutoPayData(),
  watchFetchAutoPayFees(),
  watchCancelSkipAutopay(),
  watchFetchAutoPayState(),
  watchFetchAutoPayConfig(),
  watchFetchCashPayConfig()
]
