import { all, put, takeLatest, select, call } from 'redux-saga/effects'
import {
  CHANGE_PROPERTY,
  updatePropertyRenderReady,
  selectProperty,
  fetchPropertyInfoAction,
  fetchPropertyInfoStart,
  fetchPropertyInfoSuccess,
  fetchPropertyStaffSuccess,
  fetchPropertyAddressAction,
  fetchPropertyAddressStart,
  fetchPropertyAddressSuccess,
  fetchPropertyAddressError
} from '../actions'
import { getToken, getPaymentToken } from '../../../authorization/selectors'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import { print } from 'graphql'
import { showSnackbar } from '../../../snackbar/actions'
import { getUnitPropertyUuId } from '../../units/selectors'
import { getGraphQlData } from '../../../../utils/graphql'
import { getPropertyQuery, GET_PROPERTY } from '../graphql/queries'

export function* changeProperty({ id }) {
  yield put(updatePropertyRenderReady(false))
  yield put(selectProperty(id))
}

export function* fetchPropertyInfo({ id }) {
  try {
    yield put(fetchPropertyInfoStart())
    const authToken = yield select(getToken)

    const [propertyInfo, propertyStaff] = yield all([
      call(api.getPropertyInfo, authToken, id),
      call(api.getPropertyStaff, authToken, id)
    ])

    yield put(fetchPropertyInfoSuccess(propertyInfo))
    yield put(fetchPropertyStaffSuccess(propertyStaff)) 
  } catch (error) {
    yield put(showSnackbar('Error fetching property', 'error'))
  }
}

export function* fetchPropertyAddress() {
  yield put(fetchPropertyAddressStart())

  try {
    const authToken = yield select(getToken)
    const paymentToken = yield select(getPaymentToken)
    const propertyUuId = yield select(getUnitPropertyUuId)
    const tokens = { authToken, paymentToken }
    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(getPropertyQuery),
      { propertyUuId }
    )
    const payload = yield call(getGraphQlData, response, GET_PROPERTY)
    yield put(fetchPropertyAddressSuccess(payload))
  } catch (error) {
    yield put(fetchPropertyAddressError(error))
    log(`Failed to fetch property Address. Error: ${error}`)
  }
}

export function* watchChangeProperty() {
  yield takeLatest(CHANGE_PROPERTY, changeProperty)
}

export function* watchFetchPropertyInfo() {
  yield takeLatest(fetchPropertyInfoAction().type, fetchPropertyInfo)
}

export function* watchFetchPropertyAddress() {
  yield takeLatest(fetchPropertyAddressAction().type, fetchPropertyAddress)
}

export default [watchChangeProperty(), watchFetchPropertyInfo(), watchFetchPropertyAddress()]
