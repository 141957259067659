import React from 'react'
import { useStyles } from './styles'

type Props = {
  children: React.Component,
  acknowledgementType: string,
  acknowledgement: boolean,
 }

const PlaidAcknowledgement = ({ children, acknowledgementType = "single", acknowledgement }: Props) => {
  const classes = useStyles()
  return "multi" === acknowledgementType ? <div className={acknowledgement ? classes.multiAcknowledgement : classes.multiPreAcknowledgement}>{children}</div> : <div className={classes.acknowledgement}>{children}</div>
}

export default PlaidAcknowledgement
