import { call, select, put, takeLatest } from 'redux-saga/effects'
import { showSnackbar } from '../../snackbar/actions'
import { getToken } from 'zego-shared/store/authorization/selectors'
import {
  DISMISS_SURVEY,
  dismissNotificationSuccess,
  dismissNotificationFail,
  SUBMIT_SURVEY,
  submitSurveyResponseSuccess,
  submitSurveyResponseFail,
  fetchSurveyAction,
  fetchSurveyStart,
  fetchSurveySuccess,
  fetchSurveyError
} from '../actions'
import {
  successSubmitSurveyMsg,
  failDismisSurveyMsg,
  failSubmitSurveyMsg,
  failFetchSurveyMsg
} from '../../../utils/messages'

import api from 'zego-shared/utils/api'

export function* fetchSurvey() {
  try {
    yield put(fetchSurveyStart())
    const authToken = yield select(getToken)
    const response = yield call(api.getResidentSurvey, authToken)
    yield put(fetchSurveySuccess(response))
  } catch(error) {
    yield put(fetchSurveyError(error))
    yield put(showSnackbar(failFetchSurveyMsg, 'error'))
  }
}

export function* dismissSurveyNotification({ surveyId }) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(api.dismissSurveyNotification, authToken, surveyId)
    yield put(dismissNotificationSuccess(surveyId, response))
  } catch (error) {
    yield put(dismissNotificationFail(surveyId, error))
    yield put(showSnackbar(failDismisSurveyMsg, 'error'))
  }
}

export function* submitSurveyResponse({ surveyId, rating, feedback }) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(api.submitSurveyResponse, authToken, surveyId, rating, feedback)
    
    if (response.success && !response.errors) {
      yield put(submitSurveyResponseSuccess())
      yield put(showSnackbar(successSubmitSurveyMsg, 'info'))
    } else {
      throw new Error('could not respond to survey')
    }

  } catch (error) {
    yield put(showSnackbar(failSubmitSurveyMsg, 'error'))
    yield put(submitSurveyResponseFail())
  }
}

function* watchFetchSurveysAction() {
  yield takeLatest(fetchSurveyAction().type, fetchSurvey)
}

function* watchDismissSurveyNotification() {
  yield takeLatest(DISMISS_SURVEY, dismissSurveyNotification)
}

function* watchSubmitSurveyResponse() {
  yield takeLatest(SUBMIT_SURVEY, submitSurveyResponse)
}

export default [
  watchFetchSurveysAction(),
  watchDismissSurveyNotification(),
  watchSubmitSurveyResponse()
]