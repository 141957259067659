// @flow
import React from 'react'
import PayLeaseLogo from '@images/paylease_logo_black.png'

import {
  StyledAppBar,
  Typography,
  StyledLogo,
  StyledToolbar
} from './styles'

function AppBar() {

  return (
    <StyledAppBar position="relative">
      <StyledToolbar disableGutters={true}>
        <Typography component="div">
          <StyledLogo src={PayLeaseLogo} alt="Logo" />
        </Typography>
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default AppBar
