// @flow

import React from 'react'
import Flex from '@Common/Flex'
import payLeaseLogo from '@images/paylease_logo.png'
import { useSelector } from 'react-redux'
import qs from 'query-string'
import { useHistory, withRouter } from 'react-router-dom'
import { getIsAuth } from 'zego-shared/store/authorization/selectors'
import { LoginStyled, LoginBox, PayLeaseLogoStyled } from '../Login/styles'
import {
  Deactivated,
  AccountDeactivatedTitle,
  AccountDeactivatedSubTitle,
  ReRegistrationInfo,
  SupportWrap,
  SupportInfo,
  NeedHelp,
  SSOContainer
} from './styles'

const AccountDeactivated = ({ location: { search } }) => {
  const isAuth = useSelector(getIsAuth)
  const history = useHistory()
  const { sso: isSSO } = qs.parse(search)

  isAuth && history.push('/payments/summary')

  const renderSSO = () => (
    <SSOContainer>
      <AccountDeactivatedTitle>Account Deactivated</AccountDeactivatedTitle>
      <AccountDeactivatedSubTitle>
        Your account has been successfully deactivated
      </AccountDeactivatedSubTitle>
      <ReRegistrationInfo>
        If you would like to register a new account to a different property,
        please visit your management company’s website
      </ReRegistrationInfo>
    </SSOContainer>
  )

  const renderDefault = () => (
    <LoginStyled>
      <LoginBox isDeactivated={true}>
        <Flex justifyCenter>
          <PayLeaseLogoStyled src={payLeaseLogo} alt="paylease logo" />
        </Flex>
        <Deactivated direction="column" justifySpaceBetween>
          <Flex direction="column">
            <Flex direction="column">
              <AccountDeactivatedTitle>
                Account Deactivated
              </AccountDeactivatedTitle>
              <AccountDeactivatedSubTitle>
                Your account has been successfully deactivated
              </AccountDeactivatedSubTitle>
            </Flex>
            <Flex justifyCenter>
              <ReRegistrationInfo>
                If you would like to register a new account to a different
                property, please visit your management company’s website or{' '}
                <a
                  href="https://gozego.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  www.gozego.com
                </a>{' '}
                to register.
              </ReRegistrationInfo>
            </Flex>
          </Flex>
          <Flex justifyCenter>
            <SupportWrap>
              <NeedHelp>Need Assistance Contact Us!</NeedHelp>
              <SupportInfo>
                Call Toll Free: (866) 729-5327
                <br />
                <a
                  href="http://support.gozego.com"
                  target="_blank"
                  rel="noopener noreferrer">
                  http://support.gozego.com
                </a>
              </SupportInfo>
            </SupportWrap>
          </Flex>
        </Deactivated>
      </LoginBox>
    </LoginStyled>
  )

  return isSSO ? renderSSO() : renderDefault()
}

export default withRouter(AccountDeactivated)
