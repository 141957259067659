import styled from '@emotion/styled'
import { GridItem } from '@Common/Grid'

export const StyledGrid = styled(GridItem)`
  margin-bottom: 150px;
`

export const PageTitle = styled('div')`
  margin-bottom: 34px;
  line-height: 32px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H3};
`

export const StyledMsgTtitle = styled('div')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
`

export const StyledMsgBody = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  color: ${({ theme }) => theme.palette.common.grey};
`

export const Message = styled('div')`
  margin-top: 10px;
`

export const DateStyled = styled('div')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
`

export const Status = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  text-align: right;
`

export const Demarcation = styled('div')`
  margin: 40px 0px;
  width: 100%;
  height 1px;
  border: ${({ theme }) => `1px solid ${theme.palette.common.midnight}`};
  opacity: 0.1;
`
