// @flow
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ErrorIcon from '@material-ui/icons/Error'
import { Modal } from '@material-ui/core'
import { getActiveOptions } from 'zego-shared/store/payments/options/selectors'
import { getPaymentMethod, getPaymentsMethodsData } from 'zego-shared/store/payments/methods/selectors'
import { selectPaymentMethod } from 'zego-shared/store/payments/methods/actions'
import { discardSelectedMethod, sortDebitCardsFirst } from '@utils/view'
import Button from '@Common/Button'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import PaymentMethodsList from '../../PaymentMethods/PaymentMethodsList'
import SavedMethodsList from './SavedMethodsList'
import {
  ErrorContainer,
  ErrorMsg,
  ButtonWrapperStyled,
  ModalBackDropStyled,
  ErrorIconWrapperStyled,
  ModalContent,
} from './styles'

type Props = {
  handleExitAndSaveClick: Function,
  totalAmount: number,
  lastFour: string,
}

export const ExitAndCancelButton = withAlertModal(
  ({ showAlertModal }: { showAlertModal: Function }) => (
    <Button id="exitBtn" outline primary onClick={showAlertModal}>
      Exit &amp; Cancel
    </Button>
  )
)

const InsufficientBalanceModal = ({
  handleExitAndSaveClick,
  totalAmount,
  lastFour,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true)
  const [showSavedMethods, setShowSavedMethods] = useState(true)
  const paymentOptions = useSelector(getActiveOptions)
  const paymentMethods = useSelector(getPaymentsMethodsData)
  const paymentMethod = useSelector(getPaymentMethod)
  const history = useHistory()
  const dispatch = useDispatch()

  const closeModal = () => {
    setIsOpen(false)
    handleExitAndSaveClick()
  }

  // Set modal open prop to false and route to payment summmary
  const handleClick = () => {
    closeModal()
    history.push('/payments/summary')
  }

  const onSavedMethodSelected = methodId => {
    closeModal()
    dispatch(selectPaymentMethod(methodId))
  }

  // Sort the paymentMethods by debit cards first and remove the failed method
  const sortedPaymentMethods = sortDebitCardsFirst(
    paymentMethods.filter(discardSelectedMethod(paymentMethod))
  )

  return (
    <Modal
      open={isOpen}
      BackdropComponent={ModalBackDropStyled}
      disableEnforceFocus
      disableAutoFocus>
      <ModalContent>
        <ErrorContainer>
          <ErrorIconWrapperStyled>
            <ErrorIcon />
          </ErrorIconWrapperStyled>
          <ErrorMsg>
            <p>
              A balance check of <span>Bank Account {lastFour}</span> has
              identified that there are not sufficient funds to cover this
              transaction in the amount of <span>{totalAmount}</span>. Please
              choose a different payment method from the options below, or add a
              new account.
            </p>
          </ErrorMsg>
        </ErrorContainer>
        {sortedPaymentMethods.length && showSavedMethods ? (
          <SavedMethodsList
            paymentMethods={sortedPaymentMethods}
            setShowSavedMethods={setShowSavedMethods}
            onSavedMethodSelected={onSavedMethodSelected}
          />
        ) : (
          <PaymentMethodsList
            paymentOptions={paymentOptions}
            onSelectLinkedAccount={onSavedMethodSelected}
            onMethodCreateSuccess={closeModal}
            onPaymentMethodChanged={closeModal}
          />
        )}
        <ButtonWrapperStyled>
          <ExitAndCancelButton
            alertTitle="Are you sure you wish to exit and cancel this payment?"
            onAlertClickYes={handleClick}
          />
        </ButtonWrapperStyled>
      </ModalContent>
    </Modal>
  )
}

export default InsufficientBalanceModal
