// @flow
import React, { useState, useCallback } from 'react'
import { Grid } from '@material-ui/core'
import Snackbar from '@Common/Snackbar'
import MainContainer from '../MainContainer'
import RegisterAccountCard from './RegisterAccountCard'
import LinkPropertyCard from './LinkPropertyCard'
import LoginLinkCard from './LoginLinkCard'
import { CardsGrid, Disclaimer } from './styles'
import { WelcomeText, SubText } from '../styles'

type Props = {
  account: {
    firstName: string,
    lastName: string,
    activationToken: string,
    propertyName: string,
    address1: string,
    email: string,
    zip: string,
    city: string
  },
  continueUrl: string,
  supportUrl: string
}

const Activation = (props: Props) => {
  const { account, continueUrl, supportUrl } = props
  const [showLogin, setShowLogin] = useState(false)

  const handleLinkPropertyClick = useCallback(() => {
    setShowLogin(true)
  }, [setShowLogin])

  return (
    <MainContainer>
      <WelcomeText>Welcome {account.firstName}!</WelcomeText>
      <SubText>We’re so thrilled you’re here.</SubText>
      <CardsGrid container justify="center" spacing={4}>
        <Grid item xs={12}>
          <Disclaimer>
            please make sure these are your current account details before
            choosing an option
          </Disclaimer>
        </Grid>
        <Grid item xs={12} lg={6}>
          <RegisterAccountCard
            account={account}
            supportUrl={supportUrl}
            continueUrl={continueUrl}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {showLogin ? (
            <LoginLinkCard account={account} />
          ) : (
            <LinkPropertyCard onClick={handleLinkPropertyClick} />
          )}
        </Grid>
      </CardsGrid>
      <Snackbar />
    </MainContainer>
  )
}

export default Activation
