import React from 'react'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import HelpIcon from '@material-ui/icons/HelpOutline'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { withStyles } from '@material-ui/core/styles'
import { SnackbarContentStyles, StyledTooltip } from './styles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

const SnackbarMessage = props => {
  const { classes, className, message, onClose, variant, messagesWithTooltip, ...other } = props
  const Icon = variantIcon[variant] || InfoIcon

  const renderMessage = () => {
    let content = message

    if (!isEmpty(messagesWithTooltip)) {
      content = messagesWithTooltip.map(({ message, tooltip }, index) => (
          <React.Fragment key={message}>
            {message}
            <StyledTooltip 
              arrow 
              interactive
              tabIndex={0}
              title={tooltip}
              placement="bottom-start">
              <HelpIcon className={classNames(classes.icon, classes.helpIcon)} />
            </StyledTooltip>
          </React.Fragment>
        )
      )
    }

    return (
      <span id="client-snackbar" className={classes.message}>
        <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {content}
      </span>
    )
  }

  return (
    <SnackbarContent
      classes={{ action: classNames(classes.action, classes[variant]) }}
      className={classNames(classes.root, className)}
      aria-describedby="client-snackbar"
      message={renderMessage()}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}>
          <CloseIcon id="closeSnackbarBtn" className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  )
}

export default withStyles(SnackbarContentStyles)(SnackbarMessage)
