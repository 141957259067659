// @flow
import React from 'react'
import Radio from '@material-ui/core/Radio'
import { FormControlLabel } from './styles'

type Props = {
  value: string,
  label: string,
  selected?: boolean,
  RadioProps?: Object,
  onChange?: (event: Object, checked: boolean) => mixed
}

const RadioButton = ({
  RadioProps = {},
  value,
  label,
  selected,
  onChange,
  ...props
}: Props) => {
  return (
    <FormControlLabel
      {...props}
      value={value}
      selected={selected}
      classes={{
        label: 'form-control-label'
      }}
      control={<Radio {...RadioProps} checked={selected} onChange={onChange} />}
      label={label}
    />
  )
}

export default RadioButton
