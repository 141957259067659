import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import Tabs from '@material-ui/core/Tabs'

export const MenuWrap = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.common.white};
  min-width: fit-content;
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.space.s30}`};
  ${({ theme }) => [theme.breakpoints.up('lg')]} {
    padding: ${({ theme }) => `0 ${theme.space.s75}`};
    max-width: 1400px;
    margin: 0 auto;
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding: 0;
  }
`

export const ActiveBar = styled('div')`
  height: 5px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.tertiary.main};
`

export const SubNavLink = styled(Flex)`
  color: ${({ primaryColor, theme }) =>
    primaryColor ? theme.palette.primary.main : theme.palette.neutral.deep};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  min-height: ${({ active }) => (active ? '35px' : '18px')};
  height: fit-content;
  width: ${({ width }) => width && `${width}px`};
  margin: ${({ theme }) => `${theme.space.s10} 0 0 ${theme.space.s20}`};
  transition: font-size ease-in 0.3s;

  &:first-child {
    margin-left: 0;
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`

export const DownlinkWrap = styled(Flex)`
  transition: flex 0.3s;
  flex: 1;
  ${({ theme }) => [theme.breakpoints.only('md')]} {
    flex: 1;
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    display: none;
  }
`
export const TabNav = styled(Tabs)`
  z-index: 100;
  top: ${({ top }) => `${top}px`};
  position: sticky;
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    position: static;
  }
  min-height: min-content;

  ${({ theme }) =>
    `border-top: 1px solid ${theme.palette.neutral.secondary};
    box-shadow:  ${theme.custom.shadow};
    background-color: ${theme.palette.common.white};`}

  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
