// @flow
import React from 'react'
import { CommonIcon } from '@icons'
import { Link } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import { Grid, Hidden } from '@material-ui/core'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import Flex from '@Common/Flex'
import Loader from '@Common/Loader'
import AutoPayZero from './AutoPayZero'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import CreditReportingAd from '@views/Payments/CreditReportingAd/CreditReportingAd'

import {
  PageTitle,
  AutoPayInfo,
  PaymentItemLane,
  TotalItemLane,
  AutoPayCount,
  PaymentDetailsCardTitle,
  StyledButton,
  AutoPayCircle,
  AddButton,
  Count,
  Item,
  Pagination,
  LeftIcon,
  NextRunDate,
  NextPayment,
  NextAutoPayText,
  RightIcon,
  Bold,
  ShowForMdUp,
  ShowForMdDown,
  StyledCardGrid,
  StyledCard,
  SummaryOverlay,
  StyledBox,
  TotalAmount,
  TotalText,
  WillRunOn
} from './styles'

type Props = {
  theme: Object,
  autopays: {
    autopayType: string,
    paymentFrequency: string,
    skipped: boolean,
    paymentInfo: string,
    maxLimit: string,
    total: string,
    nextPayment: string
  }[],
  isFetching: boolean,
  hasData: boolean,
  allowCancel: boolean,
  allowSkip: boolean,
  levelCreditActive: boolean,
  levelCreditInactive: boolean,
  disableAutopayMenuTab: boolean,
  cancelAutopay: Function,
  skipAutopay: Function,
  cancelSkipAutopay: Function,
  history: string[]
}

type State = {
  currentAutoPay: number
}

class AutoPay extends React.Component<Props, State> {
  state = {
    currentAutoPay: 1
  }
  getAutoPayCount = () => {
    const { autopays } = this.props
    return autopays.length
  }

  onClickAutoPay = (autopayIndex: number) => {
    this.setState({
      currentAutoPay: parseInt(autopayIndex)
    })
  }

  getAutoPayTabs = () => {
    const { autopays } = this.props
    const autoPayCount = this.getAutoPayCount()
    const { currentAutoPay } = this.state

    return autopays.map((a, index) => {
      const num = index + 1
      return (
        <AutoPayCircle
          justifyCenter
          key={num}
          addMargin={num < autoPayCount}
          active={currentAutoPay === num}>
          <Flex direction="column" justifyCenter>
            <Count active={currentAutoPay === num}>{num}</Count>
          </Flex>
        </AutoPayCircle>
      )
    })
  }

  skipOrCancelSkip = () => {
    const { skipAutopay, cancelSkipAutopay, autopays } = this.props
    const { currentAutoPay } = this.state
    const { autopayId, autopayType, skipped } = autopays[currentAutoPay - 1]

    return skipped
      ? cancelSkipAutopay(autopayId, autopayType)
      : skipAutopay(autopayId, autopayType)
  }

  cancelAutopay = () => {
    const { cancelAutopay, autopays, disableAutopayMenuTab, history } =
      this.props
    const { currentAutoPay } = this.state
    const { autopayId, autopayType } = autopays[currentAutoPay - 1]
    this.setState({
      currentAutoPay: 1
    })

    const redirectArgs = {
      autopayCount: autopays.length,
      disableAutopayMenuTab: disableAutopayMenuTab,
      history: history
    }

    return cancelAutopay(autopayId, autopayType, redirectArgs)
  }

  render() {
    const {
      theme,
      autopays,
      isFetching,
      hasData,
      allowCancel,
      allowSkip,
      isLevelCreditEnabled,
      levelCreditActive,
      levelCreditInactive,
      disableAutopayMenuTab
    } = this.props

    if (isFetching) return <Loader />
    if (!hasData) return <AutoPayZero theme={theme} />

    const count = this.getAutoPayCount()
    const { currentAutoPay } = this.state
    const {
      autopayType,
      paymentFrequency,
      skipped,
      paymentInfo,
      maxLimit,
      total,
      nextPayment,
      endDate
    } = autopays[currentAutoPay - 1]

    const isFixed = autopayType === 'Fixed'
    const marginTop = theme.ux.isSSO ? theme.space.s15 : theme.space.s37
    const spacing = theme.ux.isSSO ? 3 : 6
    const padding = theme.ux.isSSO ? `30px` : undefined

    return (
      <StyledBox position="relative" marginBottom={theme.space.s120}>
        <PageTitle>Auto Pay</PageTitle>
        <Grid container spacing={spacing} alignItems="stretch">
          <Grid
            justify="space-between"
            container={true}
            direction="column"
            item
            xs={12}
            lg={5}>
            <AutoPayInfo isLevelCreditEnabled={isLevelCreditEnabled}>
              <NextRunDate>
                <NextAutoPayText>Next Auto Pay</NextAutoPayText>
                <NextPayment>{nextPayment}</NextPayment>
              </NextRunDate>
              <Flex direction="column">
                <AutoPayCount>
                  <Item
                    id="autoPaysSet"
                    fontSize={theme.custom.font.size.H6}
                    color="grey"
                    autoPayCount>
                    You have <Bold>{count}</Bold> Auto Pay Setup
                    {count > 1 && 's'}
                  </Item>
                </AutoPayCount>
                {count > 1 && (
                  <Pagination>
                    <ScrollMenu
                      data={this.getAutoPayTabs()}
                      arrowLeft={<LeftIcon />}
                      arrowRight={<RightIcon />}
                      selected={currentAutoPay}
                      onSelect={this.onClickAutoPay}
                      hideArrows={true}
                      hideSingleArrow={true}
                      alignCenter={false}
                    />
                  </Pagination>
                )}
              </Flex>
            </AutoPayInfo>
            <ShowForMdUp>
              {isLevelCreditEnabled && !levelCreditInactive && (
                <CreditReportingAd isOptedIn={levelCreditActive} />
              )}
            </ShowForMdUp>
          </Grid>
          <SummaryOverlay />
          <StyledCardGrid item xs={12} lg={7}>
            <StyledCard customPadding={padding}>
              <PaymentItemLane direction="column">
                <PaymentDetailsCardTitle>
                  Auto Payment Details
                </PaymentDetailsCardTitle>
                <WillRunOn id="autoPaysSet">
                  will run on {nextPayment}
                </WillRunOn>
              </PaymentItemLane>
              <TotalItemLane marginTop={theme.space.s30}>
                <TotalAmount>
                  {isFixed && <TotalText>Total </TotalText>}
                  {isFixed
                    ? total
                    : maxLimit
                    ? `${maxLimit} Max`
                    : 'My Full Balance'}
                </TotalAmount>
              </TotalItemLane>
              <PaymentItemLane marginTop="44px">
                <Item
                  id="paymentFrequency"
                  fontSize={theme.custom.font.size.H6}
                  lineHeight="19"
                  color="grey"
                  regular>
                  {paymentFrequency}
                </Item>
              </PaymentItemLane>
              <PaymentItemLane>
                <Item
                  id="paymentEndDate"
                  fontSize={theme.custom.font.size.H6}
                  lineHeight="19"
                  color="grey"
                  light>
                  {endDate}
                </Item>
              </PaymentItemLane>
              <PaymentItemLane marginTop="10px">
                <Item
                  id="paymentInfo"
                  fontSize={theme.custom.font.size.H6}
                  lineHeight="19"
                  color="grey"
                  regular>
                  {paymentInfo}
                </Item>
              </PaymentItemLane>
              <Grid
                container
                component={PaymentItemLane}
                spacing={3}
                marginTop={marginTop}>
                <Grid item xs={5}>
                  <StyledButton
                    id="removeBtn"
                    fullWidth
                    outline
                    primary
                    disabled={!allowCancel}
                    onClick={this.cancelAutopay}>
                    Remove
                  </StyledButton>
                </Grid>
                <Grid item xs={7}>
                  <StyledButton
                    id="skipBtn"
                    fullWidth
                    primary
                    disabled={!allowSkip && !skipped}
                    onClick={this.skipOrCancelSkip}>
                    {skipped ? 'Cancel Skip' : 'Skip'}
                  </StyledButton>
                </Grid>
              </Grid>
            </StyledCard>
          </StyledCardGrid>
        </Grid>
        <ShowForMdDown>
          {isLevelCreditEnabled && !levelCreditInactive && (
            <CreditReportingAd isOptedIn={levelCreditActive} />
          )}
        </ShowForMdDown>
        {!disableAutopayMenuTab && (
          <Link to="auto-pay/add" data-testid="addAutoPayLink">
            <Hidden smDown>
              <AddButton size="75px" color="primary" id="addBtn">
                <CommonIcon
                  name="add"
                  width="2.2em"
                  height="2.2em"
                  fill={theme.palette.common.white}
                  strokeWidth={3}
                  stroke={theme.palette.common.white}
                />
              </AddButton>
            </Hidden>
            <MobileBtnsPortal>
              <StyledButton primary>Create Auto Pay</StyledButton>
            </MobileBtnsPortal>
          </Link>
        )}
      </StyledBox>
    )
  }
}

export default withTheme(AutoPay)
