import styled from '@emotion/styled'
import { Chip, Divider } from '@material-ui/core'
import { withStyles, emphasize } from '@material-ui/core/styles'

export const FilterChipSectionLabel = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  color: ${({ theme }) => theme.palette.common.midnight};
`

export const FilterChipWrapper = styled('div')`
  display: flex;
  margin-top: 5px;
`

const ClearFilterChipStyles = (theme) => ({
  root: {
    height: '20px',
    margin: '15px 10px 0px 0px',
    padding: '1px',
    border: `1px solid ${theme.palette.secondary.main}`,
    textTransform: 'capitalize',
    fontSize: theme.custom.font.size.sm,
    fontWeight: theme.custom.font.weight.regular,
    color: theme.palette.common.midnight,
    backgroundColor: theme.palette.secondary.main,
    '&&:hover, &&:active': {
      backgroundColor: emphasize(theme.palette.secondary.main, .12)
    }
  }
})

export const ClearFilterChip = withStyles(ClearFilterChipStyles)(Chip)

export const FilterChipDivider = styled(Divider)`
  height: 40px;
  margin: 5px 10px 0px 0px;
  color: ${({ theme }) => theme.palette.neutral.warm};
`

const FilterChipStyles = (theme) => ({
  root: {
    height: '20px',
    margin: '15px 10px 0px 0px',
    padding: '1px',
    border: `1px solid ${theme.palette.secondary.main}`,
    textTransform: 'capitalize',
    fontSize: theme.custom.font.size.sm,
    fontWeight: theme.custom.font.weight.regular,
    color: theme.palette.common.grey
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
    '&&:hover, &&:active': {
      backgroundColor: emphasize(theme.palette.common.white, .01)
    }
  },
  colorSecondary: {
    backgroundColor: theme.palette.common.iceberg,
    '&&:hover, &&:active': {
      backgroundColor: emphasize(theme.palette.common.iceberg, .01)
    }
  }
})

export const FilterChip = withStyles(FilterChipStyles)(Chip)