import { takeLatest, put, race, delay, take, select } from 'redux-saga/effects'
import {
  LOADER_ON,
  LOADER_OFF,
  loaderMounted,
  hasApiError,
  clearApiError,
  showTimeoutComp
} from './actions'
import { getApiErrors } from './selector'

let httpTimeout

export const getHttpTimeout = () => {
  if (httpTimeout == null) {
    const timeoutFromEnv = parseInt(window._env_.REACT_APP_HTTP_TIMEOUT_SEC, 10)
    httpTimeout = Number.isNaN(timeoutFromEnv) ? 30000 : timeoutFromEnv * 1000
  }
  return httpTimeout
}

export function* handleTimeout(timeoutCount) {
  const apiErrors = yield select(getApiErrors)
  const timeoutRetryDone = timeoutCount >= 2

  if (apiErrors || timeoutRetryDone) {
    yield put(showTimeoutComp())
  }

  if (!timeoutRetryDone) {
    yield put(loaderMounted(timeoutCount + 1))
  }
}

export function* startCountDown({ timeoutCount }) {
  try {
    if (!timeoutCount) yield put(clearApiError())
    const [isCountDownDone] = yield race([
      delay(getHttpTimeout()),
      take(LOADER_OFF)
    ])

    if (isCountDownDone) {
      yield handleTimeout(timeoutCount)
    }
  } catch (error) {}
}

export function* handleAPIErrors() {
  yield put(hasApiError())
}

export const isErrorAction = action => {
  const { type } = action
  const regex = /^FETCH_\w+_ERROR$/g
  return regex.test(type)
}

export function* watchStartCountDown() {
  yield takeLatest(LOADER_ON, startCountDown)
}

export function* watchOnFetchErrors() {
  yield takeLatest(isErrorAction, handleAPIErrors)
}

export default [watchStartCountDown(), watchOnFetchErrors()]
