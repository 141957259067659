// @flow
import { string, object, ref } from 'yup'

export const passwordSchema = object({
  password: string()
    .required()
    .test(
      'pwd-valid',
      'One or more password requirements failed',
      val => val.length >= 8 && val.match(/\d/) && val.match(/[a-z]/i)
    ),
  passwordConfirm: string()
    .label('Confirm password')
    .oneOf([ref('password')], 'Passwords do not match')
    .required()
})
