// @flow

import React from 'react'
import { generate as shortid } from 'shortid'
import type { Node } from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
  StyledTableBody
} from './styles'
import useSortBy from './useSortBy'

type Column = {
  label: string,
  dataKey: string,
  sortable?: boolean,
  active?: boolean,
  align?: string,
  width?: string
}

type ListFullProps = {
  columns: Array<Column>,
  elementId?: string,
  rows: Array<any>,
  useInternalRow: boolean,
  rowAction?: Node,
  tableProps: {}
}

const ListFull = ({
  columns,
  rows,
  rowAction,
  elementId,
  useInternalRow = true,
  tableProps = {}
}: ListFullProps) => {
  const [sortedRows, order, orderBy, handleSort] = useSortBy(rows)
  const RowAction = rowAction || null

  return (
    <Table {...tableProps}>
      <TableHead>
        <TableRow>
          {columns.map(
            ({ label, dataKey, sortable = false, active, align, width }) => (
              <StyledTableHeaderCell
                key={shortid()}
                align={align}
                width={width}>
                {sortable ? (
                  <TableSortLabel
                    active={active || orderBy === dataKey}
                    direction={order}
                    onClick={() => handleSort(dataKey)}
                    IconComponent={ExpandMore}>
                    {label}
                  </TableSortLabel>
                ) : (
                  label
                )}
              </StyledTableHeaderCell>
            )
          )}
          {rowAction && <StyledTableHeaderCell key="rowAction" />}
        </TableRow>
      </TableHead>
      <StyledTableBody id={elementId}>
        {sortedRows &&
          useInternalRow &&
          sortedRows.map(row => {
            return (
              <TableRow key={shortid()}>
                {columns.map(({ dataKey, align }, index) => (
                  <StyledTableBodyCell key={shortid()} align={align}>
                    <Typography variant={index === 0 ? 'h5' : 'body1'}>
                      {row[dataKey]}
                    </Typography>
                  </StyledTableBodyCell>
                ))}
                {rowAction && (
                  <StyledTableBodyCell key="rowAction">
                    <RowAction row={row} />
                  </StyledTableBodyCell>
                )}
              </TableRow>
            )
          })}
        {!useInternalRow && rows}
      </StyledTableBody>
    </Table>
  )
}

export default ListFull
