// @flow
import React from 'react'
import AppBar from '../AppBar'
import { Hidden } from '@material-ui/core'
import { MainContainerBG, ContentWrapper } from './styles'
import '@images/svg/onboarding.svg'

type Props = {
  children: any
}

const MainContainer = (props: Props) => {
  const { children } = props
  return (
    <>
      <Hidden only="xs">
        <MainContainerBG name="onboarding_bg" width="160vh" height="100vh"/>
      </Hidden>
      <AppBar />
      <ContentWrapper>{children}</ContentWrapper>
    </>
  )
}

export default MainContainer
