import React from 'react'
import './arrow.svg'
import './commonIcons.svg'
import './logos.svg'
import './searchIcons.svg'
import './socialIcons.svg'
import './checkboxIcons.svg'
import './lockIcons.svg'
import './loading.svg'
import './radiobuttonIcons.svg'
import './selectorIcons.svg'
import './branchIcons.svg'
import './resync.svg'
import './paymentIcons.svg'
import './paymentIconsA.svg'
import './paymentIconsB.svg'
import './printer.svg'
import './signature.svg'
import './return.svg'
import './envelope.svg'

const Icon = ({ prefix, name, ...props }) => (
  <svg {...props}>
    <use xlinkHref={`#${prefix}_${name}`} {...props} />
  </svg>
)
export const Arrow = props => <Icon prefix="arrow" {...props} />
export const Logo = props => <Icon prefix="logos" {...props} />
export const SocialIcon = props => <Icon prefix="socialIcons" {...props} />
export const CommonIcon = props => <Icon prefix="commonIcons" {...props} />
export const SearchIcon = props => <Icon prefix="searchIcons" {...props} />
export const CheckboxIcon = props => <Icon prefix="checkboxIcons" {...props} />
export const LockIcon = props => <Icon prefix="lockIcons" {...props} />
export const RadiobuttonIcon = props => (
  <Icon prefix="radiobuttonIcons" {...props} />
)
export const Signature = props => <Icon prefix="signature" {...props} />
export const Loading = props => <Icon prefix="loading" {...props} />
export const ReSyncIcon = props => <Icon prefix="resync" {...props} />
export const SelectorIcons = props => <Icon prefix="selectorIcons" {...props} />
export const BranchIcons = props => <Icon prefix="branchIcons" {...props} />
export const PaymentIcons = props => <Icon prefix="paymentIcons" {...props} />
export const PaymentIconsA = props => <Icon prefix="paymentIconsA" {...props} />
export const PaymentIconsB = props => <Icon prefix="paymentIconsB" {...props} />
export const PrinterIcon = props => <Icon prefix="printer" {...props} />
export const ReturnIcon = props => <Icon prefix="return" {...props} />
export const EnvelopeIcon = props => <Icon prefix="envelope" {...props} />

export default Icon
