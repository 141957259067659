// @flow

import React from 'react'
import Loader from '@Common/Loader'
import Snackbar from '@Common/Snackbar'
import payLeaseLogo from '@images/paylease_logo.png'
import statementImage from '@images/bill_statement_pin.png'
import statementImageMobile from '@images/bill_statement_pin_mobile.png'
import { HelpIcon } from '@Common/HelpText/styles'
import successHighFive from '@images/High-Five.png'
import { Link, Modal } from '@material-ui/core'

import {
  PayLeaseLogoStyled,
  DeliveryStyled,
  DeliveryCardStyled,
  DeliveryHeader,
  DeliveryDesc,
  DeliveryInfo,
  DeliveryPreferences,
  DeliveryPreference,
  DeliveryReasons,
  DeliveryProgress,
  StyledTooltip,
  DeliveryTextInput,
  DeliveryPinInput,
  DeliveryDescEmailInput,
  DeliveryTopContainer,
  DeliveryButton,
  MobileOnlyImage,
  FlexForm,
  PinModal,
  ModalCloseButton,
  ModalDesc
} from './styles'

type Props = {
  propertyId: number,
  tenantCode: string,
  error: string,
  email: string,
  residentName: string,
  streetAddress: string,
  updateResidentBillingPref: Function
}

type State = {
  preference: string,
  residentName: string,
  streetAddress: string,
  working: boolean,
  currentStep: number,
  reason: string,
  email: string,
  modal: boolean
}

const deliveryPreferences = {
  '0': 'Paper',
  '1': 'E-Statement',
  '2': 'Both',
  '3': 'Paper',
  '4': 'E-Statement',
  '5': 'Both'
}

const DELIVERY_STEP = {
  INVALID_PIN: -1,
  PIN_LOGIN: 0,
  CHOOSE_PREFERENCE: 1,
  PREFERENCE_CONFIRMATION: 2,
  PAPER_PREFERENCE_CONFIRMATION: 3,
  EMAIL_ENTRY: 4,
  VERIFY_EMAIL: 5
}

class ChangeDeliveryType extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      token: null,
      preference: '',
      residentName: '',
      working: true,
      reason: null,
      email: null,
      pin: null,
      currentStep: DELIVERY_STEP.CHOOSE_PREFERENCE,
      modal: false
    }

    this.helpIconRef = React.createRef();
    this.tooltipRef = React.createRef();
  }

  componentDidMount() {
    const { linkToken, linkPin, validateEmailToken } = this.props
    if (linkToken) {
      this.setState({ token: linkToken })
      validateEmailToken(linkToken, true)
    } else if (linkPin) {
      this.setState({
        pin: linkPin
      }, this.handlePinLogin)
    } else {
      this.setState({
        currentStep: DELIVERY_STEP.PIN_LOGIN,
        working: false
      })
    }
  }

  goToStep = (step: number) => {
    this.setState({ currentStep: step })
  }

  openModal = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ modal: !this.state.modal })
  }

  componentDidUpdate(prevProps: Props) {
    const { token, validateEmailToken, linkVerify, updateResidentBillingPref } = this.props
    var nextStep = this.state.currentStep;
    if (this.props.error && nextStep === DELIVERY_STEP.CHOOSE_PREFERENCE) {
      nextStep = DELIVERY_STEP.INVALID_PIN;
    }

    if (!this.state.token && token) {
      this.setState({ token: token, currentStep: DELIVERY_STEP.CHOOSE_PREFERENCE })
      validateEmailToken(token, true)
    }
    else if (this.state.working && (this.props.preference || this.props.error !== prevProps.error)) {
      this.setState((state, props) => ({
        working: false,
        token: props.token || this.state.token,
        preference: props.preference,
        residentName: props.residentName || state.residentName,
        streetAddress: props.streetAddress || state.streetAddress,
        reason: props.reason,
        error: props.error,
        currentStep: nextStep
      }))

      if (linkVerify) {
        this.setState({ email: linkVerify, currentStep: DELIVERY_STEP.PREFERENCE_CONFIRMATION });

        let residentPrefs = {
          preference: this.props.preference,
          propertyId: this.props.propertyId,
          tenantCode: this.props.tenantCode,
          email: linkVerify,
          reason: this.props.reason,
          excludeSnackbar: true
        }

        updateResidentBillingPref(this.state.token, residentPrefs)
      }
    }
  }

  handlePinLogin = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event && event.preventDefault()
    const { verifyResidentPin } = this.props;

    verifyResidentPin(this.state.pin);
    this.setState({ working: true, error: "" });
  }

  handleSubmit = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault()
    const {
      updateResidentBillingPref,
      propertyId,
      tenantCode,
      email
    } = this.props
    const { preference, token } = this.state

    let residentPrefs = {
      preference: preference,
      propertyId: propertyId,
      tenantCode: tenantCode,
      excludeSnackbar: true,
      reason: null
    }

    //clear out reason value if it was updated before
    this.setState({
      reason: null
    });

    if (!email && (deliveryPreferences[preference] === 'E-Statement' || deliveryPreferences[preference] === 'Both')) {
      this.goToStep(DELIVERY_STEP.EMAIL_ENTRY)
    }
    else {
      updateResidentBillingPref(token, residentPrefs)

      if (deliveryPreferences[preference] === 'Paper' || deliveryPreferences[preference] === 'Both') {
        this.goToStep(DELIVERY_STEP.PAPER_PREFERENCE_CONFIRMATION)
      }
      else {
        this.goToStep(DELIVERY_STEP.PREFERENCE_CONFIRMATION)
      }
    }
  }

  handleSubmitReason = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault()
    const {
      updateResidentBillingPref,
      propertyId,
      tenantCode,
    } = this.props
    const { preference, reason, token } = this.state

    let residentPrefs = {
      preference: preference,
      propertyId: propertyId,
      tenantCode: tenantCode,
      reason: reason
    }

    updateResidentBillingPref(token, residentPrefs)
    const { error } = this.state
    if (error.length === 0) {
      window.location = 'http://gozego.com';
    }
  }

  handleUnconfirmedEmailSubmit = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.handleSendEmail(event)
    this.goToStep(DELIVERY_STEP.VERIFY_EMAIL)
  }

  handlePreference = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      preference: event.target.value,
    });
  }

  handleReason = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({
      reason: event.target.value,
    });
  }

  handleSendEmail = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault()

    const {
      updateResidentBillingPref,
      propertyId,
      tenantCode,
    } = this.props
    const { preference, email, reason, token } = this.state

    let residentPrefs = {
      preference: preference,
      propertyId: propertyId,
      tenantCode: tenantCode,
      email: email,
      reason: reason,
      excludeSnackbar: false,
      infoMessage: `An email has been sent to ${email}.`
    }

    updateResidentBillingPref(token, residentPrefs)
  }

  handleTooltip = () => {
    let toolTipBounds = this.tooltipRef.current.parentElement.getBoundingClientRect();
    let helpIconBounds = this.helpIconRef.current.getBoundingClientRect();

    if (toolTipBounds && helpIconBounds) {
      this.setState({
        arrowPosition: `${toolTipBounds.right - (helpIconBounds.left + helpIconBounds.width / 2) - 6}px`,
      })
    } else {
      this.setState({
        arrowPosition: '10px',
      })
    }
  }

  emailEntry = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value });
  }

  pinEntry = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ pin: event.target.value });
  }

  getFormView = () => {
    const { preference, residentName, streetAddress } = this.state
    return (
      <DeliveryStyled>
        <DeliveryProgress variant="determinate" value={50} color="secondary" />
        <DeliveryCardStyled
          CardContentProps={{
            classes: {
              root: 'card-content-root'
            }
          }}>
          <FlexForm>
            <PayLeaseLogoStyled src={payLeaseLogo} alt="paylease logo" />
            <DeliveryHeader>Hi {residentName}!</DeliveryHeader>
            <DeliveryDesc>How would you like to receive your billing statements{streetAddress ? (<span> for <strong>{streetAddress}</strong></span>) : null}?
            {streetAddress ? (<StyledTooltip
                ref={this.helpIconRef}
                placement="bottom-end"
                interactive
                arrowposition={this.state.arrowPosition}
                TransitionProps={{ onEntered: this.handleTooltip }}
                title={
                  <span ref={this.tooltipRef}>
                    Our records indicate this is the billing address for your account.
                    If this is not you contact our support team at {window._env_.REACT_APP_SUPPORT_PHONE_NUMBER} or
                    <Link color="secondary" href={window._env_.REACT_APP_SUPPORT_URL}> {window._env_.REACT_APP_SUPPORT_URL} </Link>
                    to set up your billing statement preferences.
                  </span>
                }>
                <HelpIcon />
              </StyledTooltip>)
                : null}
            </DeliveryDesc>
            <DeliveryTopContainer>
              <DeliveryPreferences flex={1} style={{ 'justifyContent': 'center' }}>
                <DeliveryPreference
                  id="eBillRadioButton"
                  label="E-Statement"
                  value="4"
                  name="deliveryPreference"
                  onChange={this.handlePreference}
                  selected={
                    deliveryPreferences[preference] === 'E-Statement'
                  }
                />
                <DeliveryPreference
                  id="paperRadioButton"
                  label="Paper"
                  value="3"
                  name="deliveryPreference"
                  onChange={this.handlePreference}
                  selected={
                    deliveryPreferences[preference] === 'Paper'
                  }
                />
                <DeliveryPreference
                  id="bothButton"
                  label="Both"
                  value="5"
                  name="deliveryPreference"
                  onChange={this.handlePreference}
                  selected={
                    deliveryPreferences[preference] === 'Both'
                  }
                />
              </DeliveryPreferences>
            </DeliveryTopContainer>
            <div>
              <DeliveryButton
                id="preferenceChangeSubmit"
                disableRipple
                classes={{ label: 'login-label' }}
                primary
                onClick={this.handleSubmit}
                type="submit">
                Submit
              </DeliveryButton>
            </div>
          </FlexForm>
        </DeliveryCardStyled>
        <Snackbar />
      </DeliveryStyled>
    )
  }

  getPaperConfirmation = () => {
    const reasonCodes = [
      { label: `I don't have an email`, value: 'DontHaveEmail' },
      { label: `I don't check my email`, value: 'DontCheckEmail' },
      { label: 'I receive too many emails', value: 'ReceiveManyEmails' },
      { label: 'I prefer paper statements', value: 'PreferPaper' },
      {
        label: 'I need paper statements for expense documentation',
        value: 'NeedPaper'
      }
    ]

    return (
      <DeliveryStyled>
        <DeliveryProgress variant="determinate" value={100} color="secondary" />
        <DeliveryCardStyled
          CardContentProps={{
            classes: {
              root: 'card-content-root'
            }
          }}>
          <FlexForm>
            <PayLeaseLogoStyled src={payLeaseLogo} alt="paylease logo" />
            <DeliveryHeader>Thank You! <span style={{ 'fontWeight': 'normal' }}> Your options are all set.</span></DeliveryHeader>
            <DeliveryDesc>To better understand and serve you the best experience, could you tell us why you prefer paper over e-statements?</DeliveryDesc>
            <DeliveryTopContainer>
              <DeliveryPreferences flex={1} style={{ 'justifyContent': 'center' }}>
                <DeliveryReasons
                  options={reasonCodes}
                  onChange={this.handleReason}
                  placeholder="select"
                />
              </DeliveryPreferences>
            </DeliveryTopContainer>
            <div>
              <DeliveryButton
                  primary
                  onClick={this.handleSubmitReason}
                  disableRipple
                  disabled={this.state.reason == null}
                  classes={{ label: 'login-label' }}
                  type="submit">
                Done
              </DeliveryButton>
              <DeliveryDesc>
                Changed your mind? Entered the wrong option?
                Just <Link onClick={() => this.goToStep(DELIVERY_STEP.CHOOSE_PREFERENCE)} color="secondary" underline="always">click here to go back</Link>
              </DeliveryDesc>
            </div>
          </FlexForm>
        </DeliveryCardStyled>
        <Snackbar />
      </DeliveryStyled>
    )
  }

  getPrefConfirmation = () => {
    const { preference } = this.state
    return (
      <DeliveryStyled>
        <DeliveryProgress variant="determinate" value={100} color="secondary" />
        <DeliveryCardStyled
          CardContentProps={{
            classes: {
              root: 'card-content-root'
            }
          }}>
          <FlexForm>
            <DeliveryTopContainer>
              <PayLeaseLogoStyled src={payLeaseLogo} alt="paylease logo" />
              <MobileOnlyImage src={successHighFive} alt="success" />
              <DeliveryHeader>Congrats! You're all set to receive {deliveryPreferences[preference]}</DeliveryHeader>
            </DeliveryTopContainer>
            <DeliveryInfo>Changed your mind? Entered the wrong option?
            Just <Link color="secondary" onClick={() => this.goToStep(DELIVERY_STEP.CHOOSE_PREFERENCE)} rel="noopener noreferrer" underline="always">click here to go back</Link>
            </DeliveryInfo>
          </FlexForm>
        </DeliveryCardStyled>
        <Snackbar />
      </DeliveryStyled>
    )
  }

  getVerifyEmailView = () => {
    const verifyMessage1 = `Last Step! Let's verify your email. Check your inbox for a verification url from us!`
    const verifyMessage2 = "Don't see it? You may need to check your spam folder."
    const verifyMessage3 = "Still don't see it? Click below to resend."

    return (
      <DeliveryStyled>
        <DeliveryProgress variant="determinate" value={75} color="secondary" />
        <DeliveryCardStyled
          CardContentProps={{
            classes: {
              root: 'card-content-root'
            }
          }}>
          <FlexForm>
            <PayLeaseLogoStyled src={payLeaseLogo} alt="paylease logo" />
            <DeliveryTopContainer>
              <DeliveryHeader>
                {verifyMessage1}
                <StyledTooltip
                  ref={this.helpIconRef}
                  placement="bottom-end"
                  interactive
                  arrowposition={this.state.arrowPosition}
                  TransitionProps={{ onEntered: this.handleTooltip }}
                  title={
                    <span ref={this.tooltipRef}>
                      If you do not receive an email with a unique URL to complete your billing enrollment,
                        contact our support team at {window._env_.REACT_APP_SUPPORT_PHONE_NUMBER} or
                        <Link color="secondary" href={window._env_.REACT_APP_SUPPORT_URL}> {window._env_.REACT_APP_SUPPORT_URL} </Link>
                      for assistance.
                      </span>
                  }>
                  <HelpIcon />
                </StyledTooltip>
              </DeliveryHeader>
              <DeliveryDesc>{verifyMessage2}</DeliveryDesc>
              <DeliveryDesc>{verifyMessage3}</DeliveryDesc>
            </DeliveryTopContainer>
            <div>
              <DeliveryButton
                  id="resendEmailBtn"
                  primary
                  onClick={this.handleSendEmail}
                  disableRipple
                  classes={{ label: 'login-label' }}
                  type="submit">
                Resend Email
              </DeliveryButton>
            </div>
          </FlexForm>
        </DeliveryCardStyled>
        <Snackbar />
      </DeliveryStyled>
    )
  }

  getResidentEmail = () => {
    return (
      <DeliveryStyled>
        <DeliveryProgress variant="determinate" value={75} color="secondary" />
        <DeliveryCardStyled
          CardContentProps={{
            classes: {
              root: 'card-content-root'
            }
          }}>
          <FlexForm>
            <PayLeaseLogoStyled src={payLeaseLogo} alt="paylease logo" />
            <DeliveryDescEmailInput>Please enter your email address to receive e-statements</DeliveryDescEmailInput>
            <DeliveryTopContainer>
              <DeliveryTextInput
                autoComplete="true"
                placeholder="someone@example.com"
                required
                type="email"
                autoFocus={true}
                onChange={this.emailEntry}>
              </DeliveryTextInput>
            </DeliveryTopContainer>
            <div>
              <DeliveryButton
                  disableRipple
                  primary
                  disabled={!this.state.email || !this.state.email.includes("@")}
                  onClick={this.handleUnconfirmedEmailSubmit}
                  type="submit">
                Submit
              </DeliveryButton>
            </div>
          </FlexForm>
        </DeliveryCardStyled>
        <Snackbar />
      </DeliveryStyled>
    )
  }

  getResidentPinLogin = () => {
    const modalBody = (
      <PinModal>
        <ModalCloseButton href="#" ></ModalCloseButton>
        <ModalDesc>Your pin code can be found on the first page of your billing statement.</ModalDesc>
        <picture>
          <source srcSet={statementImage} media="(min-width: 430px)" />
          <img src={statementImageMobile} alt="billing statement pin location" />
        </picture>
      </PinModal>
    );

    return (
      <DeliveryStyled>
        <Modal
          BackdropProps={{ style: { backgroundColor: "rgba(255, 255, 255, 0.9)" } }}
          disableAutoFocus
          open={this.state.modal}
          onClick={this.openModal}
        >
          {modalBody}
        </Modal>
        <DeliveryProgress variant="determinate" value={25} color="secondary" />
        <DeliveryCardStyled
          CardContentProps={{
            classes: {
              root: 'card-content-root'
            }
          }}>
          <FlexForm>
            <PayLeaseLogoStyled src={payLeaseLogo} alt="paylease logo" />
            <DeliveryDescEmailInput>Please enter your 5 digit PIN code
              <StyledTooltip
                ref={this.helpIconRef}
                placement="bottom-end"
                interactive
                arrowposition={this.state.arrowPosition}
                TransitionProps={{ onEntered: this.handleTooltip }}
                title={
                  <span ref={this.tooltipRef}>
                    You’ll find your pin code on your monthly statement.
                    <span> </span>
                    <Link style={{ textDecoration: 'underline' }} color="secondary" href="#" onClick={this.openModal}>See Placement.</Link>
                    <span> </span>
                    If you need additional help,
                     contact our support team at {window._env_.REACT_APP_SUPPORT_PHONE_NUMBER} or
                     <Link color="secondary" href={window._env_.REACT_APP_SUPPORT_URL}> {window._env_.REACT_APP_SUPPORT_URL} </Link>
                    to set up your billing statement preferences
                    </span>
                }>
                <HelpIcon
                  style={{ marginTop: '.8vw' }}
                />
              </StyledTooltip>
            </DeliveryDescEmailInput>
            <DeliveryTopContainer>
              <DeliveryPinInput
                autoComplete="off"
                type="password"
                placeholder="00000"
                required
                autoFocus={true}
                inputProps={{ maxLength: 5 }}
                onChange={this.pinEntry}
              >
              </DeliveryPinInput>
            </DeliveryTopContainer>
            <div>
              <DeliveryButton
                  disableRipple
                  primary
                  disabled={!this.state.pin || this.state.pin.length < 5}
                  onClick={this.handlePinLogin}
                  type="submit">
                Continue
              </DeliveryButton>
            </div>
          </FlexForm>
        </DeliveryCardStyled>
        <Snackbar />
      </DeliveryStyled>
    )
  }

  getInvalidPinView = () => {
    return (
      <DeliveryStyled>
        <DeliveryProgress variant="determinate" value={0} color="secondary" />
        <DeliveryCardStyled
          CardContentProps={{
            classes: {
              root: 'card-content-root'
            }
          }}>
          <FlexForm>
            <PayLeaseLogoStyled src={payLeaseLogo} alt="paylease logo" />
            <DeliveryHeader>We're Sorry!</DeliveryHeader>
            <DeliveryDesc>It looks like there was an error loading the page. Please contact our resident support team by clicking this link 
            <Link color="secondary" href={window._env_.REACT_APP_SUPPORT_URL}> {window._env_.REACT_APP_SUPPORT_URL} </Link> 
            or call {window._env_.REACT_APP_SUPPORT_PHONE_NUMBER} to update your statement delivery preference. </DeliveryDesc>
          </FlexForm>
        </DeliveryCardStyled>
      </DeliveryStyled>
    )
  }

  render() {
    const { working, currentStep } = this.state
    if (working) {
      return <Loader />
    }
    else
    {
      switch (currentStep)
      {
        case DELIVERY_STEP.INVALID_PIN:
          return this.getInvalidPinView();
        case DELIVERY_STEP.PIN_LOGIN:
          return this.getResidentPinLogin();
        case DELIVERY_STEP.CHOOSE_PREFERENCE:
          return this.getFormView()
        case DELIVERY_STEP.PAPER_PREFERENCE_CONFIRMATION:
          return this.getPaperConfirmation()
        case DELIVERY_STEP.PREFERENCE_CONFIRMATION:
          return this.getPrefConfirmation()
        case DELIVERY_STEP.EMAIL_ENTRY:
          return this.getResidentEmail()
        case DELIVERY_STEP.VERIFY_EMAIL:
          return this.getVerifyEmailView()
        default:
          return this.getPrefConfirmation()
      }
    }
  }
}

export default ChangeDeliveryType
