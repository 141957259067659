import gql from 'graphql-tag'

export const GET_DELIVERY_PREF_Q = `
{
    getResidentBillingPreferences {
        billingType
        paperStatementReason
    }
}`;

export const UPDATE_DELIVERY_PREF_M = gql`
mutation($billingType:Int!, $reasonCode:String, $email:String) {
    updateResidentBillingPreferences(
        input: {
            billingType: $billingType,
            reasonCode: $reasonCode,
            email: $email
        }
    ) {
        eBillResident {
            billingType
            paperStatementReason
        }
    }
}`;