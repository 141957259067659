
// @flow
import get from 'lodash/get'

export const getIpnUrl = (state: Object) => 
  get(state, 'overrides.ipnUrl')

export const getIpnCustomValue = (state: Object) =>
  get(state, 'overrides.ipnCustomValue.ipnCustomValue')  

export const getSecondaryResidentId = (state: Object) =>
  get(state, 'overrides.secondaryResidentId')  
  
export const getCancelOptionBehaviorOverride = (state: Object) =>
  get(state, 'overrides.payments.behavior.cancel_option')

export const getPaymentSuccessRedirect = (state: Object) =>
  get(state, 'overrides.payments.behavior.payment_success_redirect')
