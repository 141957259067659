// @flow
import * as React from 'react'
import type { Node } from 'react'
import { Grid } from '@material-ui/core'
import ShowOnMedia from '@Common/ShowOnMedia'

import LeftSideNav from '../LeftSideNav'

type Route = {
  name: string,
  url: string
}

type Props = {
  children: Node,
  routes: Route[]
}

function WithLeftSideNav(props: Props) {
  const { routes, children } = props

  return (
    <Grid container>
      <Grid item md={3} zeroMinWidth>
        <ShowOnMedia media="screen">
          <LeftSideNav links={routes} />
        </ShowOnMedia>
      </Grid>
      <Grid item xs={12} md={9}>
        {children}
      </Grid>
    </Grid>
  )
}

export default WithLeftSideNav
