export const voidPaymentMutation = `
  mutation voidPayment($input: VoidPaymentRequestInput!) {
    voidPayment(input: $input) {
      transId
      message
    }
  }
`

export const requestPaymentReceipt = `
mutation requestPaymentReceipt ($input: RequestPaymentReceiptInput!) {
  requestPaymentReceipt(input: $input) {
    transactionId
  }
}
`
