// @flow
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import Button from '@Common/Button'
import { PaymentIcons } from '@icons'

import { CardStyled, NoMethodStyled, useStyles } from './styles'
import Plaid from '../../Plaid'

type Props = {
  addBankAccountPath: string,
  addCardPath: string,
  optionsConfig: { hasCard?: boolean, hasBank?: boolean }
}

const AddMethodCard = ({ Icon, label, link, buttonId }) => {
  const history = useHistory()
  return (
    <CardStyled
      CardContentProps={{
        classes: {
          root: 'card-content-root'
        }
      }}>
      <Box display="flex" justifyContent="center" alignItems="center" flex="3">
        {Icon}
      </Box>
      <Box flex="1">
        <Plaid
          switchToManual={() => {history.push(link)}}
          render={({ enabled, open }) => {
            const usePlaid = buttonId === 'addBankBtn' && enabled
            const onClickHandler = event => {
              event.preventDefault()
              open()
            }
            return (
              <Link to={link} onClick={usePlaid ? onClickHandler : undefined}>
                <Button id={buttonId} primary long>
                  {label}
                </Button>
              </Link>
            )
          }}
        />
      </Box>
    </CardStyled>
  )
}

const NoMethod = ({
  addBankAccountPath,
  addCardPath,
  optionsConfig: { hasBank, hasCard }
}: Props) => {
  const classes = useStyles()
  const card = <PaymentIcons name="credit" className={classes.credit} />
  const bank = <PaymentIcons name="bank" className={classes.bank} />

  return (
    <NoMethodStyled>
      {hasCard && (
        <AddMethodCard
          buttonId="addCardBtn"
          Icon={card}
          label="Add a Payment Card"
          link={addCardPath}
        />
      )}
      {hasBank && (
        <AddMethodCard
          buttonId="addBankBtn"
          Icon={bank}
          label="Add a Bank Account"
          link={addBankAccountPath}
        />
      )}
    </NoMethodStyled>
  )
}

export default NoMethod
