// @flow
import styled from '@emotion/styled'
import Divider from '@material-ui/core/Divider'
import Flex from '@Common/Flex'

export const DividerStyled = styled(Divider)`
  margin: ${({ theme }) =>
    theme.ux.isSSO
      ? `${theme.space.s14} 0 ${theme.space.s12}`
      : `${theme.space.s15} 0 ${theme.space.s30}`};
`

export const LayoutStyled = styled(Flex)`
  position: relative;
  padding-bottom: ${({ theme }) => theme.space.s10};
  margin-bottom: ${({ theme }) => theme.space.s120};
  min-height: auto;

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
      margin-bottom: 0;
      min-height: calc(100vh - 70px);
      max-height: calc(100vh - 70px);
    `}
`
