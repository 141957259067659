// @flow
import React from 'react'
import { DetailsItemStyled } from './styles'
import { DetailsItemType } from './types'

const DetailsItem = ({ value, label }: DetailsItemType) => {
  return (
    <DetailsItemStyled>
      <span>{label}</span>
      <span id={label.replace(/\s/g, '')}>{value}</span>
    </DetailsItemStyled>
  )
}

export default DetailsItem
