// @flow

import { get } from "lodash";

export const getCardData = (state: Object) => {
  const card = get(state, ['marketplace', 'selected'], {})
  const data = card.data || {};

  const marketplaceDeal = {
    id: data.id,
    image: data.image,
    description: data.description,
    discount: data.discount_description,
    url: data.redirect_url,
    companyName: data.title,
    category: data.category,
    manufacturer: data.manufacturer,
    type: data.type,
    inserted_at: data.inserted_at
  }

  return marketplaceDeal
}

export const getMarketplaceDeals = (state: Object) => {
  return get(state, ['marketplace', 'list', 'data'], [])
}

export const getMarketplaceCard = (state: Object, dealId: number) => {
  let deals = get(state, ['marketplace', 'list', 'data'], [])
  let result = {};
  if (deals.length > 0) {
    const deal = deals.find(element => element.id === dealId);
    if (deal) {
      const { id, data: {
        image,
        description,
        discount_description,
        redirect_url,
        title,
        category
      } } = deal
      
      result = {
        id,
        image,
        description,
        discount: discount_description,
        url: redirect_url,
        companyName: title,
        category: category
      }
    }
  }

  return result;
}
