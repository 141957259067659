import styled from '@emotion/styled'

import { StyledCard, InfoLane } from '../styles'

export const CardStyled = styled(StyledCard)`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 372px;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    height: 299px;
  }

  & .MuiCardContent-root {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  & h4 {
    height: 24px;
    width: 196px;
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
    line-height: 29px;
    margin: 17px 0 0;
  }
`

export const DetailsStyled = styled.div`
  height: 70%;
  overflow: scroll;
`

export const ButtonsStyled = styled(InfoLane)`
  position: relative;
  &::before {
    position: absolute;
    content: '';
    left: -50px;
    right: -50px;
    bottom: -50px;
    top: -20px;
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    opacity: ${({ showDetails }) => (showDetails ? 0.5 : 0)};
    transition: opacity 0.3s ease-in;
  }
`

export const HeaderText = styled('span')`
  line-height: 29px;
  color: ${({ theme }) =>
    theme.palette.common.midnight };
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};


  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    line-height: 22px;
  }
`