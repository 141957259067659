import { takeLatest, select, call } from 'redux-saga/effects'
import { PERSIST } from 'redux-persist'
import {
  FETCH_RESOURCES_AND_REDIRECT_AFTER_LOGIN,
  DEAUTH
} from '../../authorization/actions'
import Raven from 'zego-shared/utils/sentry'
import { getUserData } from './selectors'

export const getIsProd = () => window._env_.NODE_ENV === 'production'
export const setUserContext = Raven.setUserContext.bind(Raven)

export function* setUserContextSaga() {
  const { isAuth, ...rest } = yield select(getUserData)
  const isProd = yield call(getIsProd)
  if (isAuth && isProd) {
    yield call(setUserContext, rest)
  }
}

export function* deleteUserContextSaga() {
  const isProd = yield call(getIsProd)

  if (isProd) {
    yield call(setUserContext)
  }
}

export function* watchPersistRehydrate() {
  yield takeLatest(
    [PERSIST, FETCH_RESOURCES_AND_REDIRECT_AFTER_LOGIN],
    setUserContextSaga
  )
}

export function* watchDeAuth() {
  yield takeLatest(DEAUTH, deleteUserContextSaga)
}

export default [watchPersistRehydrate(), watchDeAuth()]
