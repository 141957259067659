// @flow
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import OneTimePayment from '../../views/Payments/OneTimePayment'

type Props = {
  match: { path: string }
}
const OneTimePaymentRoutes = ({ match: { path } }: Props) => {
  return (
    <Switch>
      <Route key="one-time-payment" path={path} component={OneTimePayment} />
    </Switch>
  )
}

export default OneTimePaymentRoutes
