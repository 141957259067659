// @flow
import * as React from 'react'
import { withTheme } from 'emotion-theming'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dinero from 'dinero.js'
import { amountToNumber } from 'zego-shared/utils/number'

import CurrencyTextInput from '../../../CurrencyTextInput'
import Button from '../../../../Common/Button'
import { Grid } from '@material-ui/core'

import {
  FormStyled,
  FormWrapper,
  FormGroupStyled,
  FixAmountStyled
} from './styles'
import { CancelBtnStyled } from '../styles'
import { DescriptionStyled } from '../styles'
import type { Theme } from '../../../../../theme'

type Props = {
  match: Object,
  onChange: any => mixed,
  fixedAmountLabel?: string,
  showPayFullBalance?: boolean,
  onPayFullBalanceClick: Function,
  value: { fields: Object[], total: string, fullBalance: string },
  fetchPaymentOptionFees: (Object[]) => mixed,
  onCancel: () => void,
  theme: Theme
}

const FixedAmountEdit = ({
  value: { fields },
  onChange,
  onCancel,
  theme
}: Props) => {
  const changeLayout = useMediaQuery('(max-width:700px)')
  const ssoPaymentLayout = theme.ux.isSSO && changeLayout

  const handleChange = event => {
    const { target } = event
    const amount = amountToNumber(target.value)
    if (!Number.isNaN(amount)) {
      let total = Dinero({ amount: 0 })
      const newFields = fields.map(field => {
        let fieldCpy = field
        if (fieldCpy.key === target.name) {
          const dinero = Dinero({ amount: amount, currency: fieldCpy.currency })
          total = total.add(dinero)
          fieldCpy = {
            ...field,
            amount: dinero.toFormat('0.00')
          }
        } else {
          total = total.add(
            Dinero({
              amount: amountToNumber(fieldCpy.amount),
              currency: fieldCpy.currency
            })
          )
        }

        return fieldCpy
      })
      onChange({
        fields: newFields,
        total: total.toFormat(),
        totalAmt: total.getAmount()
      })
    }
  }

  return (
    <FixAmountStyled>
      <Grid container>
        <Grid item xs={12} sm={ssoPaymentLayout ? 6 : 7}>
          <DescriptionStyled direction="column">
            <h5>payment</h5>
            <p>
              Set a fixed amount you always want to pay. Each auto payment will
              always be for the exact amount you set here, plus fees.
            </p>
          </DescriptionStyled>
        </Grid>
        <Grid item xs={12} sm={ssoPaymentLayout ? 6 : 5}>
          <FormWrapper>
            <CancelBtnStyled justifyRight alignStart>
              <Button
                link
                disableRipple
                color={theme.palette.secondary.dark}
                onClick={onCancel}>
                cancel
              </Button>
            </CancelBtnStyled>
            <FormStyled>
              {fields.map(({ label, amount, symbol, key, locked }) => {
                return (
                  <FormGroupStyled label={label} key={key}>
                    <CurrencyTextInput
                      id={label.replace(/\s/g, '')}
                      symbol={symbol}
                      onChange={handleChange}
                      name={key}
                      value={amount}
                      inputProps={{ readOnly: locked }}
                    />
                  </FormGroupStyled>
                )
              })}
            </FormStyled>
          </FormWrapper>
        </Grid>
      </Grid>
    </FixAmountStyled>
  )
}

export default withTheme(FixedAmountEdit)
