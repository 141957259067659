// @flow
import React from 'react'
import get from 'lodash/get'

import PaymentCard from '../PaymentCard'

export type Flow = {
  Component: Function,
  name: string,
  nextFlow?: number,
  props?: Object,
  required?: boolean,
  validator?: Object => boolean,
  progress: number,
  infoItem?: {
    title: string,
    label: string,
    value: string,
    defaultValue?: string,
    isDynamicTitle?: boolean,
    editable?: boolean
  }
}

type Props = {
  flows: Flow[],
  flow: number,
  details: Object,
  onIconClick: Function,
  handleDetailsChange: Function
}

const PaymentFlow = ({
  flows,
  flow,
  details,
  onIconClick,
  handleDetailsChange,
  ...rest
}: Props) => {
  const { Component, props = {}, name } = flows[flow] || {}
  const [isEditIconClicked, setIsEditIconClicked] = React.useState(false)
  const flowValue = details[name]

  const handleIconClick = (index: number) => () => {
    onIconClick(index)
    setIsEditIconClicked(true)
  }

  return (
    <React.Fragment>
      {Array.from(Array(flow)).map((prevFlow, index) => {
        const { infoItem } = flows[index]
        if (!infoItem) {
          return null
        }
        const {
          title,
          label,
          value,
          isDynamicTitle,
          editable,
          defaultValue = ''
        } = infoItem
        return (
          <PaymentCard
            key={title}
            title={isDynamicTitle ? get(details, title) : title}
            label={label}
            value={get(details, value) || defaultValue}
            onIconClick={handleIconClick(index)}
            editable={editable}
          />
        )
      })}
      <Component
        {...props}
        {...rest}
        name={name}
        isEditIconClicked={isEditIconClicked}
        value={flowValue}
        onChange={handleDetailsChange(name)}
      />
    </React.Fragment>
  )
}

export default PaymentFlow
