// @flow
import * as React from 'react'
import moment, { Moment } from 'moment'
import Popover from '@material-ui/core/Popover'
import InputAdornment from '@material-ui/core/InputAdornment'
import Event from '@material-ui/icons/Event'
import Calendar from '@Common/Calendar'
import { formatDateMDYY } from 'zego-shared/utils/date'

import { DateInputStyled } from './styles'
import TextInput from '../TextInput'

type Props = {
  initialDate?: string,
  onChange?: Function,
  name?: string,
  InputProps?: Object,
  showSelectedDate?: boolean,
  width?: string,
  CalendarProps?: Object,
  formatter?: Moment => string,
  format?: string,
  readOnly?: boolean,
  previousDatesEnabled?: boolean,
  dateInputId?: string,
  disableCalender?: boolean
}

const DateInput = ({
  initialDate = '',
  onChange,
  name,
  InputProps,
  width,
  CalendarProps,
  showSelectedDate,
  formatter = formatDateMDYY,
  format = 'M/D/YY',
  readOnly = true,
  previousDatesEnabled,
  dateInputId = 'dateInputId',
  disableCalender = false,
  ...props
}: Props) => {
  const inputRef = React.useRef()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [date, setDate] = React.useState(initialDate)
  const [dateString, setDateString] = React.useState(
    initialDate ? formatter(initialDate) : ''
  )

  const handleClick = (event: Object) => setAnchorEl(inputRef.current)
  const handleClose = () => setAnchorEl(null)
  const onDateChange = (date: Moment) => {
    setDate(date)
    onChange && onChange(date, name)
  }

  const handleDateChange = (date: Moment) => {
    setDate(date)
    setDateString(formatter(date))
    onChange && onChange(date, name)
  }

  const handleDateStringChange = event => {
    const { value } = event.target
    const date = moment(value, format, true)
    onDateChange(date)
    setDateString(value)
  }

  const open = !disableCalender && Boolean(anchorEl)
  const id = open ? 'calendar' : null

  return (
    <DateInputStyled {...props} ref={inputRef}>
      <TextInput
        aria-describedby={id}
        value={dateString}
        onChange={handleDateStringChange}
        onClick={readOnly && handleClick}
        InputProps={{
          readOnly,
          ...InputProps,
          endAdornment: disableCalender ? null : (
            <InputAdornment position="end" onClick={!readOnly && handleClick}>
              <Event id={dateInputId} />
            </InputAdornment>
          )
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <Calendar
          showSelectedDate={showSelectedDate}
          width={width}
          moment={moment(date).isValid() ? moment(date) : moment()}
          handleChange={handleDateChange}
          previousDatesEnabled={previousDatesEnabled}
          {...CalendarProps}
        />
      </Popover>
    </DateInputStyled>
  )
}

export default DateInput
