// @flow
import styled from '@emotion/styled'
import Card, { CardContent } from '@Common/Card'
import IconButton from '@material-ui/core/IconButton'

export const StyledCard = styled(Card, props => ({
  unwrapContent: props => props.unwrapContent
}))`
  position: relative;
  z-index: 1;
  width: 360px;
  height: 608px;
  justify-content: ${({ customJustifyContent }) =>
    customJustifyContent ? customJustifyContent : 'space-between'};
  display: flex;
  flex-direction: column;
  padding: ${({ customPadding }) =>
    customPadding ? customPadding : `8px 24px`};

  & > .accountHolderNameForm {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  & .MuiCardMedia-img {
    object-fit: none;
  }

  & .lastStep {
    background-color: #111111;

    &:hover {
      background-color: #0a85ea;
      color: #ffffff;
    }
  }

  & .MuiCardActions-root {
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    & button {
      width: 100%;
      margin: 7px 0;
    }

    & a {
      display: block;
    }
  }

  & .MuiTypography-colorTextSecondary {
    color: #06846e;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    align-items: center;
    height: 100%;
    width: 100%;

    > .accountHolderNameForm {
      max-width: 320px;
      width: 50%;
    }
  `}

  & .linkedAccountsTitle {
    font-size: 2em;
    margin: 1em 0;
    font-weight: bold;
  }
`

export const StyledCardContent = styled(CardContent, props => ({
  customPadding: props => props.customPadding
}))`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;

  & > h3 {
    color: #0e1427;
    font-size: 20px;
    font-weight: bolder;
    margin: 0;
  }

  & > p {
    color: #5c6274;
    font-size: 14px;
    margin-top: 0;
    padding: 0 20px 20px 20px;
  }

  & label.MuiInputLabel-formControl {
    font-size: 12px !important;
  }

  &.linkedAccountsContainer {
    display: block;
    overflow-y: auto;
    padding: 1px;
    margin-bottom: 1em;
  }
`
export const IconButtonStyled = styled(IconButton)`
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  & svg {
    height: 20px;
    width: 20px;
  }
`
