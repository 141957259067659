import { call, takeLatest, put, select } from 'redux-saga/effects'
import { print } from 'graphql'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { GET_CONFIG, getPaymentReminderQuery } from '../graphql/queries'
import { SET_CONFIG, setPaymentReminderMutation } from '../graphql/mutations'
import { getPaymentToken } from '../../../authorization/selectors'
import {
  fetchRemindersAction,
  fetchRemindersStart,
  fetchRemindersSuccess,
  fetchRemindersError,
  updateRemindersAction,
  updateRemindersStart,
  updateRemindersSuccess,
  updateRemindersError
} from '../actions'
import api from '../../../../utils/api'
import { getGraphQlData } from '../../../../utils/graphql'
import { genericErrorMsg } from '../../../../utils/messages'
import log from '../../../../utils/logger'

export function* getPaymentReminder() {
  try {
    yield put(fetchRemindersStart())
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = print(getPaymentReminderQuery)

    const response = yield call(api.graphqlQuery, tokens, query)
    const payload = yield call(getGraphQlData, response, GET_CONFIG)
    yield put(fetchRemindersSuccess(payload))
  } catch (error) {
    yield put(fetchRemindersError(error))
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* setPaymentReminder({ payload }) {
  try {
    yield put(updateRemindersStart())
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const input = payload
    const response = yield call(
      api.graphqlQuery,
      tokens,
      setPaymentReminderMutation,
      {
        input
      }
    )

    const rPayload = yield call(getGraphQlData, response, SET_CONFIG)
    yield put(updateRemindersSuccess(rPayload))
    yield put(showSnackbar('update successful', 'success'))
  } catch (error) {
    log(`Failed to set payment reminder. Error: ${error}`)
    yield put(updateRemindersError(error))
    yield put(showSnackbar(error.message, 'error'))
  }
}

function* watchGetPaymentReminders() {
  yield takeLatest(fetchRemindersAction().type, getPaymentReminder)
}

function* watchSetPaymentReminders() {
  yield takeLatest(updateRemindersAction().type, setPaymentReminder)
}

export default [watchGetPaymentReminders(), watchSetPaymentReminders()]
