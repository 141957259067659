import { connect } from 'react-redux'
import Summary from './Summary'
import { getErrorOccured } from 'zego-shared/store/snackbar/selectors'
import { getResidentNsfFee } from 'zego-shared/store/pmSettings/selectors'
import { isDebitCardSelected, isCreditCardSelected, isPlaidAccountSelected } from 'zego-shared/store/payments/methods/selectors'
import { getPlaidBalanceChecks } from 'zego-shared/store/payments/options/selectors'

export const mapStateToProps = state => ({
  isError: getErrorOccured(state),
  isDebitCard: isDebitCardSelected(state),
  isCreditCard :isCreditCardSelected(state),
  residentNsfFee: getResidentNsfFee(state),
  isPlaidBankAccount: isPlaidAccountSelected(state),
  hasBalanceChecksEnabled: getPlaidBalanceChecks(state),
})

export default connect(mapStateToProps, null)(Summary)
