import { call, select, put, fork, takeLatest, delay } from 'redux-saga/effects'
import { print } from 'graphql'
import queryString from 'query-string'
import jwtDecode from 'jwt-decode'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import {
  REAUTHORIZATION_REQUESTED,
  removeSessionStorageItem
} from '../../../utils/sessionStorage'
import merge from 'lodash/merge'
import { isAdminToken } from '../../../utils/auth'
import {
  failedToLogin,
  failedToLogAs,
  failedToResetPass,
  failedToCloseAccount
} from '../../../utils/messages'
import {
  deauth,
  saveAuth,
  updateUserOptimistic,
  updateUserSuccess,
  updateUserError,
  signUpSuccess,
  loginFailure,
  fetchResourcesAndRedirectAfterLogin as fetchAndRedirectAfterLogin,
  UPDATE_USER,
  LOGIN_REFRESH,
  LOGIN_EMAIL,
  LOGIN_TOKEN,
  LOGIN_AS,
  LOGOUT_AND_REDIRECT,
  SIGN_UP,
  signUpError,
  startSignUp,
  REQUEST_RESET_PASS,
  RESET_PASS,
  FETCH_RESOURCES_AND_REDIRECT_AFTER_LOGIN,
  DELETE_RESIDENT,
  deleteResidentSuccessful,
  deleteResidentFailed
} from '../actions'
import { deleteResidentMutation } from '../graphql/mutations'
import { getGraphQlData } from '../../../utils/graphql'
import { showSnackbar, hideSnackbar } from '../../snackbar/actions'
import { ldInitRequest } from 'zego-shared/store/integrations/launchDarkly/actions'
import { fetchPropertyInfo } from 'zego-shared/store/select/actions'
import {
  getToken,
  getProfile,
  getUserId,
  getIsAuth,
  getPaymentToken
} from '../selectors'
import {
  getUnitPropertyId,
  getUnitCompanyUuid
} from 'zego-shared/store/select/selectors'
import {
  selectUnit,
  fetchUnitsSuccess,
  fetchActionUnitInfo,
  updatePropertyRenderReady
} from '../../select/actions'
import { fetchUserSettingsSuccess } from '../../settings/actions'
import { hideTandCfromAdmin } from 'zego-shared/store/termsAndConditions/actions'
import {
  getCompanyMemosQuery,
  GET_COMPANY_MEMOS
} from '../../company/graphql/queries'
import { fetchPmMemosSuccess } from '../../company/actions'
import { fetchResidentUserIdAction } from '../../resident/actions'
import { requestPaymentReceiptAction } from '../../payments/oneTimePayment/actions'
import { getNextLinkedAccountToLogAs } from 'zego-shared/store/payments/linkedAccounts/selectors'
import { getIsSsoPayments } from 'zego-shared/store/authorization/selectors'
import { getPmSettings } from '../../pmSettings/graphql/queries'
import { fetchPmSettingsSuccess } from '../../pmSettings/actions'
import {
  savePaymentBalanceOverride,
  saveHideAutopayType,
  setCancelOptionBehaviorOverride,
  setPaymentSuccessRedirect,
  saveIpnUrl,
  saveIpnCustomValue,
  saveSecondaryResidentId
} from '../../overrides/actions'
import { extractParamsFromQueryString } from '../../overrides/paramExtractor'

export function* saveAuthInfo(info) {
  const { id_token, id } = info

  const profile = yield call(jwtDecode, id_token)

  if (!profile) {
    throw new Error('invalid profile')
  }

  let user
  try {
    user = yield call(api.getUser, id, id_token)
  } catch (err) {
    // If error when getting user, default isIntegratedUser to false
    user = {
      pms_user: {
        data: {
          isIntegrated: false
        }
      }
    }
  }
  const completeProfile = merge(profile, user)

  if (
    completeProfile.role !== 'resident' &&
    completeProfile.role !== 'designated_payer'
  ) {
    yield put(
      showSnackbar(
        'Login failed. Please make sure you are using resident or payer account to login!',
        'error'
      )
    )
  } else {
    // save auth info
    yield put(saveAuth(completeProfile, info))
  }
}

export function* updateUser(action) {
  const profile = yield select(getProfile)
  const newAtts = action.attributes
  const isSSOEmailUpdate = action.isSSOEmailUpdate

  let currentAtts = {}
  let attsToUpdate = {}

  // loop through each attribute
  // if attribute is different from what we have then add it to update list
  // also save it to current attributes so we can revert later if an error occurs
  for (let key in newAtts) {
    if (newAtts[key] && newAtts[key] !== profile[key]) {
      attsToUpdate[key] = newAtts[key]
      currentAtts[key] = profile[key]
    }
  }

  if (Object.keys(attsToUpdate).length > 0) {
    const userId = yield select(getUserId)
    const authToken = yield select(getToken)

    try {
      yield put(updateUserOptimistic(attsToUpdate))

      const response = yield call(
        api.updateUser,
        userId,
        attsToUpdate,
        authToken
      )

      let serverAtts = {}

      for (let key in attsToUpdate) {
        if (response[key]) {
          serverAtts[key] = response[key]
        }
      }
      yield put(updateUserSuccess(serverAtts))
      if (isSSOEmailUpdate) {
        yield put(
          showSnackbar('Email address has been successfully updated', 'success')
        )
        yield call(requestPaymentReceiptAction, { email: newAtts.email })
      }
    } catch (err) {
      yield put(updateUserError(err.message, currentAtts))
      yield put(showSnackbar(err.message, 'error'))
    }
  }
}

export function* login(func, args, options = {}) {
  try {
    yield put(deauth())
    if (args[1] === 'isAdminLogin') {
      yield put(hideTandCfromAdmin())
      localStorage.setItem('hideTandCfromAdmin', '1')
    }
    const info = yield call(func, ...args)
    yield call(saveAuthInfo, info)

    if (options && options.successMessage) {
      const { successMessage, successMessageDelay } = options

      yield delay(successMessageDelay || 0)
      yield put(showSnackbar(successMessage, 'success'))
    }
  } catch (error) {
    yield put(showSnackbar(failedToLogin(error), 'error'))
    yield put(loginFailure())
  }
}

export function* refreshToken({ refreshToken, resolve, reject }) {
  try {
    yield delay(200)
    const info = yield call(api.refreshToken, refreshToken)
    yield call(saveAuthInfo, info)
    resolve()
  } catch (error) {
    yield put(showSnackbar(failedToLogin(error), 'error'))
    reject(error)
  }
}

export function* loginEmail(action) {
  const { username, password, spoofUserId } = action

  const func = api.loginEmail
  const args = [username, password, spoofUserId]

  yield fork(login, func, args)
}

export function* loginToken(action) {
  yield delay(200)

  const { token, messageAfterLogin } = action
  const func = api.loginToken
  let args = [token]
  const isAdminLogin = yield call(isAdminToken, token)

  if (isAdminLogin) {
    const isAdmin = 'isAdminLogin'
    args = [...args, isAdmin]
  }

  let loginOptions = null

  if (messageAfterLogin) {
    loginOptions = {
      successMessage: messageAfterLogin,
      successMessageDelay: 1500
    }
  }

  yield fork(login, func, args, loginOptions)
}

export function* loginAs({ userId, history, millisecondsToReload = 3000 }) {
  try {
    const authToken = yield select(getToken)
    const { token } = yield call(api.loginAs, userId, authToken)

    history.push(`/login?sso=${token}`)
  } catch (error) {
    yield put(showSnackbar(failedToLogAs(error), 'error'))
    log(`Failed to login as: ${error}`)
    yield delay(millisecondsToReload)
    history.go(0)
  }
}

export function* logoutAndRedirect({ history }) {
  yield put(deauth())

  removeSessionStorageItem(REAUTHORIZATION_REQUESTED)

  const webAppUrl = window._env_.REACT_APP_PHP_WEB_APP_LOGIN_URL
  if (webAppUrl) {
    window.location.assign(webAppUrl)
  } else {
    history.push('/login')
  }
}

export function* signUp(action) {
  try {
    yield put(startSignUp())
    const { token, data, provider, history, isMobileDevice } = action

    let info

    if (provider) {
      info = yield call(api.signUpSocial, token, data, provider)
    } else {
      info = yield call(api.signUp, token, data)
    }

    if (isMobileDevice) {
      yield call(saveAuthInfo, info)
      history.push('/new-resident/mobile-app')
      return
    }

    yield call(saveAuthAndShowWelcome, info)

    //Redirect
    const isAuth = yield select(getIsAuth)
    if (isAuth) {
      yield put(fetchAndRedirectAfterLogin(history))
    }
  } catch (error) {
    log(`Failed to sign up. Error: ${error}`)
    yield put(signUpError())
    yield put(showSnackbar(error.message, 'error'))
  }
}

export function* getDelay(time) {
  yield delay(time)
}

export function* saveAuthAndShowWelcome(info) {
  try {
    yield call(saveAuthInfo, info)
    yield call(getDelay, 500)

    yield put(signUpSuccess())
    yield put(showSnackbar('Welcome', 'success'))
  } catch (error) {
    yield put(showSnackbar(failedToLogin(error), 'error'))
  }
}

export function* resetPass({ data, history }) {
  try {
    const result = yield call(api.resetPassword, data)
    log(result)

    yield put(showSnackbar('Password reset successfully!', 'success'))
    yield call(getDelay, 600)
    yield put(hideSnackbar())
    history.push('/login')
  } catch (error) {
    log('failed to reset password', error)
    yield put(showSnackbar(error.message || failedToResetPass, 'error'))
  }
}

export function* requestResetPass({ email }) {
  try {
    const result = yield call(api.resetPassViaEmail, email)
    log(result)

    yield put(showSnackbar(result.details.msg, 'success'))
  } catch (error) {
    log('failed to reset password', error)
    yield put(showSnackbar(failedToResetPass, 'error'))
  }
}

export function* fetchResourcesAndRedirectAfterLogin({ history }) {
  try {
    yield put(ldInitRequest())
    yield put(updatePropertyRenderReady(false))
    const authToken = yield select(getToken)
    const paymentToken = yield select(getPaymentToken)
    const tokens = { authToken, paymentToken }

    // oauth redirect
    const redirectHosts = (
      window._env_.REACT_APP_REDIRECT_HOSTS ||
      'https://api.zego.io,https://api-stage.zego.io'
    ).split(',')
    const {
      redirectURI,
      includeToken,
      formattedPaymentFields,
      hideAutopayType,
      cancelOption,
      paymentSuccessRedirect,
      ipnUrl,
      ipnCustomValue,
      secondaryResidentID
    } = extractParamsFromQueryString(queryString.parseUrl(window.location.href))

    if (ipnUrl) {
      yield put(saveIpnUrl( ipnUrl ))
    }

    if (ipnCustomValue) {
      yield put(saveIpnCustomValue({ ipnCustomValue }))
    }

    if (secondaryResidentID) {
      yield put(saveSecondaryResidentId({ secondaryResidentID }))
    }
        
    if (formattedPaymentFields && formattedPaymentFields.length) {
      yield put(savePaymentBalanceOverride(formattedPaymentFields))
    }

    if (hideAutopayType) {
      yield put(saveHideAutopayType(hideAutopayType))
    }

    let partialPath = '/'

    if (redirectURI) {
      try {
        const url = new URL(redirectURI)
        const baseURL = redirectHosts.includes(url.hostname) ? redirectURI : '/'
        const redirectURL =
          includeToken === 'true' ? `${baseURL}&token=${authToken}` : baseURL
        window.location.assign(redirectURL)
      } catch {
        // Not full path
        partialPath = redirectURI
      }
    }

    const units = yield call(api.getUnits, authToken)
    const firstUnitId = units[0].id
    yield put(fetchUnitsSuccess(units))
    yield put(fetchActionUnitInfo())
    yield put(selectUnit(firstUnitId))
    const propertyId = yield select(getUnitPropertyId)
    yield put(fetchPropertyInfo(propertyId))
    const settings = yield call(api.getSettings, authToken)
    yield put(fetchUserSettingsSuccess(settings))
    yield put(fetchResidentUserIdAction())

    const companyUuid = yield select(getUnitCompanyUuid)
    if (companyUuid) {
      const response = yield call(
        api.graphqlQuery,
        tokens,
        print(getCompanyMemosQuery),
        { companyUuid }
      )
      const payload = yield call(getGraphQlData, response, GET_COMPANY_MEMOS)
      yield put(fetchPmMemosSuccess(payload))
    }

    const pmSettings = yield call(
      api.graphqlQuery,
      tokens,
      print(getPmSettings)
    )

    const pmPayload = yield call(getGraphQlData, pmSettings, 'getPmSettings')

    yield put(fetchPmSettingsSuccess(pmPayload))

    if (cancelOption) {
      yield put(setCancelOptionBehaviorOverride(cancelOption))
    }
    if (paymentSuccessRedirect) {
      yield put(setPaymentSuccessRedirect(paymentSuccessRedirect))
    }
    // forward to homepage, other redirects may occur from there based on state
    history.push(partialPath)
  } catch (error) {
    yield put(deauth())
    yield put(showSnackbar(failedToLogin(error), 'error'))
    console.log(error)
  }
}

export function* deleteResident({ history }) {
  try {
    const authToken = yield select(getToken)
    const paymentToken = yield select(getPaymentToken)
    const logAsUserId = yield select(getNextLinkedAccountToLogAs)
    const isSSO = yield select(getIsSsoPayments)
    const tokens = { authToken, paymentToken }
    const query = deleteResidentMutation
    let ssoToken = null

    if (logAsUserId) {
      ssoToken = yield call(getLogAsToken, logAsUserId)
    }

    const response = yield call(api.graphqlQuery, tokens, query, {})
    const getResponse = yield call(getGraphQlData, response, 'deleteResident')

    if (logAsUserId) {
      history.push(`/login?sso=${ssoToken}`)
      return
    }

    yield put(deauth())
    yield put(deleteResidentSuccessful())
    history.push(`/account-deactivated${isSSO ? '?sso=1' : ''}`)
    yield put(showSnackbar(getResponse.message, 'success'))
  } catch (error) {
    yield put(showSnackbar(failedToCloseAccount, 'error'))
    yield put(deleteResidentFailed(error))
  }
}

export function* getLogAsToken(userId) {
  try {
    const authToken = yield select(getToken)
    const { token } = yield call(api.loginAs, userId, authToken)

    return token
  } catch (error) {
    log(`Failed to fetch linked account token. Error: ${error}`)
  }
}

// spawn a new updateUser task on the latest UPDATE_USER
function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, updateUser)
}

function* watchLoginRefresh() {
  yield takeLatest(LOGIN_REFRESH, refreshToken)
}

function* watchLoginEmail() {
  yield takeLatest(LOGIN_EMAIL, loginEmail)
}

function* watchLoginToken() {
  yield takeLatest(LOGIN_TOKEN, loginToken)
}

function* watchLoginAs() {
  yield takeLatest(LOGIN_AS, loginAs)
}

function* watchSignUp() {
  yield takeLatest(SIGN_UP, signUp)
}

function* watchResetPass() {
  yield takeLatest(RESET_PASS, resetPass)
}

function* watchRequestResetPass() {
  yield takeLatest(REQUEST_RESET_PASS, requestResetPass)
}

function* watchLogoutAndRedirect() {
  yield takeLatest(LOGOUT_AND_REDIRECT, logoutAndRedirect)
}

function* watchRedirectAfterLogin() {
  yield takeLatest(
    FETCH_RESOURCES_AND_REDIRECT_AFTER_LOGIN,
    fetchResourcesAndRedirectAfterLogin
  )
}

function* watchDeleteResident() {
  yield takeLatest(DELETE_RESIDENT, deleteResident)
}

export default [
  watchUpdateUser(),
  watchLoginRefresh(),
  watchLoginEmail(),
  watchLoginToken(),
  watchLoginAs(),
  watchSignUp(),
  watchResetPass(),
  watchRequestResetPass(),
  watchRedirectAfterLogin(),
  watchLogoutAndRedirect(),
  watchDeleteResident()
]
