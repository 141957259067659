import { select, put, call, takeEvery } from 'redux-saga/effects'
import api from '../../../utils/api'
import log from '../../../utils/logger'

import { getToken } from '../../authorization/selectors'
import {
  fetchUserSettingsStart,
  fetchUserSettingsSuccess,
  fetchUserSettingsError,
  fetchUserSettings as fetchUserSettingsAction
} from '../actions'

export function* fetchUserSettings(action) {
  try {
    const authToken = yield select(getToken)
    yield put(fetchUserSettingsStart())
    const response = yield call(api.getSettings, authToken)
    yield put(fetchUserSettingsSuccess(response))
  } catch (error) {
    yield put(fetchUserSettingsError(error))
    log(`Failed to fetch settings. Error: ${error}`)
  }
}

export function* watchFetchUserSetting() {
  yield takeEvery(fetchUserSettingsAction().type, fetchUserSettings)
}

export default [watchFetchUserSetting()]
