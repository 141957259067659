// @flow
import React from 'react'
import Switch from '@material-ui/core/Switch'

type Props = {
  classes: Object,
  checked: boolean,
  handleChange: Function,
  elementId?: string
}

const Switches = (props: Props) => {
  const { classes, checked, handleChange, elementId } = props

  return (
    <div>
      <Switch
        id={elementId}
        checked={checked}
        onChange={handleChange}
        value="checked"
        classes={classes}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  )
}

export default Switches
