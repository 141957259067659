import React from 'react'
import { useSelector } from 'react-redux'
import Flex from '@Common/Flex'
import { useHistory } from 'react-router-dom'
import { PaymentsBlockedText, ReturnButton } from './styles'
import { getCancelOptionBehaviorOverride } from 'zego-shared/store/overrides/selectors'

type Props = {
  isAutoPay?: boolean
}

const PaymentsBlocked = ({ isAutoPay }: Props) => {
  const cancelOption = useSelector(getCancelOptionBehaviorOverride)
  const { push } = useHistory()

  const onReturnButtonClicked = () => {
    if (cancelOption) {
      window.location.replace(cancelOption)
    } else {
      push("/payments/summary")
    }
  }

  const returnButtonText = (cancelOption) ? 'Return' : 'Return to Summary'

  return (
    <React.Fragment>
      <Flex justifyCenter marginTop="66px" marginBottom="109px">
        <PaymentsBlockedText>
          {isAutoPay
            ? 'Unfortunately, an AutoPay cannot be created at this time. Contact your Property Manager for more information on AutoPay policies.'
            : 'Please note you are unable to make payments or create an AutoPay at this time. Contact your management company with questions regarding your status'}
        </PaymentsBlockedText>
      </Flex>
      <Flex justifyCenter marginBottom="166px">
        <ReturnButton onClick={onReturnButtonClicked} width="225px" primary bold>
          {returnButtonText}
        </ReturnButton>
      </Flex>
    </React.Fragment>
  )
}

export default PaymentsBlocked
