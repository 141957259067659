import { connect } from 'react-redux'
import { logoutAndRedirect } from 'zego-shared/store/authorization/actions'
import Logout from './Logout'

const mapDispatchToProps = (dispatch, { history }) => ({
  logout: () => dispatch(logoutAndRedirect(history))
})

export default connect(
  null,
  mapDispatchToProps
)(Logout)
