import Styled from '@emotion/styled'

export const StyledClientNotes = Styled('div')`
  background-color: ${({ theme }) => theme.palette.clientNotes.titleBackGround};
  margin: 0;
  border: 1px solid ${({ theme }) => theme.palette.clientNotes.border};
  padding: ${({ theme }) => theme.space.s7};
  height: auto;
  color: ${({ theme }) => theme.palette.clientNotes.titleText}
`

export const NoteBox = Styled('div')`
  background-color: ${({ theme }) => theme.palette.clientNotes.contentBackGround};
  margin: 0;
  border: 1px solid ${({ theme }) => theme.palette.clientNotes.border};
  padding: ${({ theme }) => `${theme.space.s15} ${theme.space.s7}`};
  height: auto;
  color: ${({ theme }) => theme.palette.clientNotes.contentText};
  width: 100%;
`

export const NoteSpan = Styled('span')`
  text-align: left;
  max-width: 900px;
  display: block;
`
