// @flow

import React from 'react'
import { withTheme } from 'emotion-theming'
import queryString from 'query-string'
import Loader from '@Common/Loader'
import LoginSection from './LoginSection'
import Flex from '@Common/Flex'
import PasswordRecoveryRequest from './PasswordRecoveryRequest'
import PasswordReset from './PasswordReset'
import payLeaseLogo from '@images/paylease_logo.png'
import Snackbar from '@Common/Snackbar'
import type { Match, RouterHistory } from 'react-router-dom'
import type { BrowserLocation } from 'history'
import type { Theme } from '../../../theme'

import { LoginStyled, LoginBox, PayLeaseLogoStyled } from './styles'

type ModeType = 'login' | 'passwordRecoveryRequest' | 'passwordReset'

type State = {
  mode: ModeType,
  email: string,
  password: string,
  passwordConfirm: string,
  working: boolean
}

type Props = {
  isAuth: boolean,
  token: ?string,
  match: Match,
  location: BrowserLocation,
  mode?: ModeType,
  loginEmail: (string, string) => mixed,
  loginSocial: (string, Object) => mixed,
  fetchResourcesAndRedirectAfterLogin: RouterHistory => void,
  requestResetPass: string => mixed,
  resetPass: ({}, RouterHistory) => mixed,
  resetSuccess: Object,
  handleSocialFailure: () => void,
  error: string,
  history: RouterHistory,
  theme: Theme,
  loginToken: string => mixed,
  loginFailed: ?boolean,
  messageAfterLogin: ?string
}

class Login extends React.Component<Props, State> {
  state = {
    mode: this.props.mode || 'login',
    email: '',
    password: '',
    passwordConfirm: '',
    working: false
  }

  componentDidMount() {
    const { token, loginToken, messageAfterLogin } = this.props
    if (token) loginToken(token, messageAfterLogin)
  }

  changeMode = (mode: ModeType) => {
    this.setState(oldState => {
      return {
        ...oldState,
        password: '',
        passwordConfirm: '',
        mode
      }
    })
  }

  handleLogin = event => {
    event.preventDefault()
    this.setWorking(true)
    const { loginEmail } = this.props
    const { email, password } = this.state
    loginEmail(email, password)
  }

  setWorking = (working: boolean) => {
    this.setState({ working })
  }

  handleSocialLogin = (provider: string, userObj: {}) => {
    const { loginSocial } = this.props
    loginSocial(provider, userObj)
  }

  handleChange = ({
    currentTarget: { id, value }
  }: SyntheticEvent<HTMLInputElement>) => {
    this.setState(oldState => {
      return {
        ...oldState,
        [id]: value
      }
    })
  }

  handlePasswordResetRequest = event => {
    event.preventDefault()
    this.setWorking(true)
    const { email } = this.state
    const { requestResetPass } = this.props
    requestResetPass(email)
  }

  handlePasswordReset = event => {
    event.preventDefault()
    this.setWorking(true)
    const { password, passwordConfirm } = this.state
    const { resetPass, location, history } = this.props
    const { reset_token: token } = queryString.parse(location.search)
    resetPass(
      {
        password,
        password_confirmation: passwordConfirm,
        token
      },
      history
    )
  }

  getModeUI = () => {
    const { mode, email, password, passwordConfirm, working } = this.state
    const { handleSocialFailure, error } = this.props

    switch (mode) {
      case 'passwordRecoveryRequest':
        return (
          <PasswordRecoveryRequest
            changeMode={this.changeMode}
            handleChange={this.handleChange}
            handleResetRequest={this.handlePasswordResetRequest}
            email={email}
            error={error}
            working={working}
          />
        )

      case 'passwordReset':
        return (
          <PasswordReset
            changeMode={this.changeMode}
            handleChange={this.handleChange}
            handleReset={this.handlePasswordReset}
            password={password}
            passwordConfirm={passwordConfirm}
            error={error}
            working={working}
          />
        )

      default:
        return (
          <LoginSection
            changeMode={this.changeMode}
            handleChange={this.handleChange}
            handleLogin={this.handleLogin}
            handleSocialLogin={this.handleSocialLogin}
            handleSocialFailure={handleSocialFailure}
            email={email}
            password={password}
            error={error}
            working={working}
          />
        )
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { isAuth, fetchResourcesAndRedirectAfterLogin, history } = this.props
    if (!prevProps.isAuth && isAuth) {
      // sagas will handle redirect so just show login until the sagas handle it
      fetchResourcesAndRedirectAfterLogin(history)
    }
  }

  render() {
    const { error, loginFailed, token } = this.props
    const { working } = this.state

    if (token && !loginFailed) {
      return <Loader />
    }

    if (error && working) {
      this.setWorking(false)
    }
    return (
      <>
        <LoginStyled>
          <LoginBox>
            <Flex justifyCenter>
              <PayLeaseLogoStyled src={payLeaseLogo} alt="paylease logo" />
            </Flex>
            {this.getModeUI()}
          </LoginBox>
          <Snackbar />
        </LoginStyled>
      </>
    )
  }
}

export default withTheme(Login)
