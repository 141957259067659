// @flow
import React from 'react'
import { RadioButton } from '@Common/Form'
import { RadioButtons } from './styles'

type Option = { value: string, label: string }

const LeftSection = ({
  selected,
  options,
  handleChange
}: {
  selected: string,
  options: Option[],
  handleChange: string => mixed
}) => {
  const onChange = event => {
    const { value } = event.target
    handleChange(value)
  }
  return (
    <RadioButtons>
      {options.map(({ label, value }) => {
        return (
          <RadioButton
            id={label.replace(/\s/g, '')}
            classes={{
              label: 'radio-label'
            }}
            RadioProps={{
              classes: {
                root: 'radio'
              }
            }}
            label={label}
            value={value}
            selected={value === selected}
            onChange={onChange}
          />
        )
      })}
    </RadioButtons>
  )
}
export default LeftSection
