// @flow
import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { formatDate } from 'zego-shared/utils/date'
import { Link } from 'react-router-dom'
import { Grid, GridItem } from '@Common/Grid'
import { CardContent } from '@Common/Card'
import { StyledButton, StyledCard } from './styles'
import SupportCard from './SupportCard'

type WorkOrder = {
  reported_date: string,
  reported_by: string,
  message: string
}

type Props = {
  workOrders: WorkOrder[],
  emergencyPhone: string,
  propertyPhone: string,
  unitId: string
}

const defaultProps = { workOrders: [] }

const WorkOrdersSummary = ({
  workOrders,
  emergencyPhone,
  propertyPhone,
  unitId
}: Props) => {
  const hasWorkOrders = workOrders && 0 < workOrders.length
  const openCount = hasWorkOrders
    ? workOrders.length.toString().concat(' OPEN')
    : null
  const latestWorkOrder = hasWorkOrders ? workOrders[0] : null

  return (
    <Grid rows={false}>
      <GridItem columnStart={1}>
        <Typography variant="h2" id="workOrderHeader">Work Orders</Typography>
        <Typography>
          Submit a maintenance request and stay up to date with its progress!
        </Typography>
      </GridItem>
      <Box mt={6} mr={6} clone>
        <GridItem columnStart={1} columnSpan={8} rowStart={2} rowSpan={10}>
          <StyledCard unwrapContent>
            <CardContent>
              {latestWorkOrder && (
                <>
                  <Box pb={1} color="primary.main" id="openWoCount">
                    {openCount}
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    pb={6}
                    clone>
                    <Typography variant="h4">
                      <div id="submittedBy">Submitted by - {latestWorkOrder.reported_by}</div>
                      <div id="reportedDate">{formatDate(latestWorkOrder.reported_date)}</div>
                    </Typography>
                  </Box>
                  <Box color="common.grey" clone>
                    <Typography variant="h6" id="woMessage">
                      {latestWorkOrder.message}
                    </Typography>
                  </Box>
                </>
              )}
              {!hasWorkOrders && (
                <Box>
                  <Box color="common.grey" fontSize="h4.fontSize" clone>
                    <Typography>Currently</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Box
                      color="primary.main"
                      fontSize="100px"
                      fontWeight="fontWeightBold"
                      clone>
                      <Typography id="requestsSubmitted">0</Typography>
                    </Box>
                    <Box pl={3}>
                      <Typography variant="h3">Maintenance Requests</Typography>
                      <Box color="common.grey" clone>
                        <Typography>Have Been Submitted</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </CardContent>
            <Box mt="auto" mb={6} mx={6}>
              <Box display="flex" justifyContent="space-between">
                {hasWorkOrders && (
                  <Link to={`/units/${unitId}/work-orders/history`}>
                    <StyledButton outline primary id="fullHistBtn">
                      Full History
                    </StyledButton>
                  </Link>
                )}
                <Link to={`/units/${unitId}/work-orders/new`}>
                  <StyledButton primary id="submitWoBtn">Submit a Work Order</StyledButton>
                </Link>
              </Box>
            </Box>
          </StyledCard>
        </GridItem>
      </Box>
      <Box mt={6} clone>
        <GridItem columnStart={9} columnSpan={4} rowStart={2} rowSpan={10}>
          <SupportCard
            propertyPhone={propertyPhone}
            emergencyPhone={emergencyPhone}
          />
        </GridItem>
      </Box>
    </Grid>
  )
}

WorkOrdersSummary.defaultProps = defaultProps

export default WorkOrdersSummary
