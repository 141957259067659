// @flow
import React from 'react'

import { InstructionsStyledList } from './styles'
import { getInstructions } from './utils'

type Props = {
  paymentDisclaimerMemo: string,
  isDebitCard: boolean,
  isCreditCard: boolean,
  nsfFee: string | null,
  shouldShowBalanceChecksDisclaimer: boolean
}

const Instructions = ({
  paymentDisclaimerMemo,
  isCreditCard,
  isDebitCard,
  nsfFee,
  shouldShowBalanceChecksDisclaimer
}: Props) => {
  const instructions = getInstructions(
    paymentDisclaimerMemo,
    isDebitCard,
    nsfFee,
    shouldShowBalanceChecksDisclaimer,
    isCreditCard
  )

  return (
    <InstructionsStyledList>
      {instructions.map(({ name, text }) => {
        return (
          <li id={name} key={name}>
            {text}
          </li>
        )
      })}
    </InstructionsStyledList>
  )
}

export default Instructions
