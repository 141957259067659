// @flow

import { connect } from 'react-redux'
import MobileApp from './MobileApp'
import { getMagicLink } from 'zego-shared/store/authorization/selectors'

const defaultMagicLink = 'https://pmz6t.app.link/kqG7Nx5z10'

const mapStateToProps = (state) => ({
  magicLink: getMagicLink(state) || defaultMagicLink
})

export default connect(mapStateToProps)(MobileApp)
