import { PM_SETTINGS } from '../actions'
import withFetch from '../../helpers/reducers/withFetch' 

const pmSettings = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(PM_SETTINGS)(pmSettings)
