import styled from '@emotion/styled'
import Button from '@Common/Button'
import Flex from '@Common/Flex'
import Fab from '@material-ui/core/Fab'
import { IconButton } from '@material-ui/core'

export const StyledFab = styled(Fab)`
  box-shadow: none;
  cursor: default;
  background-color: ${({ theme }) => theme.palette.alternative.purple};
  height: 35px;
  width: 35px;
  margin-top: -0.5em;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  &:click {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    display: none;
  }
`

export const StyledButton = styled(Button)`
  line-height: 19px;
  height: 30px;
  min-width: 130px;
  cursor: pointer;
  margin-left: 0.3em;
  font-size: ${({ theme }) => theme.custom.font.size.md};
  border: 3px solid ${({ theme }) => theme.palette.alternative.purple};
  background-color: transparent;

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.light};
    border: 3px solid ${({ theme }) => theme.palette.alternative.purple};
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding-top: 0.5em;
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) => theme.palette.alternative.purple};
    border: none;
  }
`

export const NotificationRight = styled('div')`
  cursor: pointer;
  height: 50px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.alternative.purple};

  ${({ theme }) => [theme.breakpoints.up('md')]} {
    :before {
      content: "";
      position: absolute;
      top: 25px;
      width: 0;
      height: 0;
      border-bottom: 25px solid ${({ theme }) => theme.palette.primary.main};
      border-right: 25px solid transparent;
    }
  }
`

export const DisplayMsg = styled('span')`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  padding: 0.3em;
  padding-right: 0em;
`

export const CloseBtn = styled(IconButton)`
  margin-left: -${({ theme }) => theme.space.s15};
  margin-right: -${({ theme }) => theme.space.s5};
  color: ${({ theme }) => theme.palette.common.white};
  float: right;
`

export const StyledDismiss = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.palette.common.white};
  margin-left: 30px;
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.common.white};
  }
`

export const NotificationContainer = styled(Flex)`
  height: 50px;
  width: 100%;
  top: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};
  justify-content: space-between;

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    height: 100px;
  }
`

export const NotificationBanner = styled('div')`
  background-color: ${({ theme }) => theme.palette.secondary.light};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  padding: ${({ theme }) => theme.space.s5};
  text-align: center;
  line-height: 19px;
  height: 50px;

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    height: 100px;
    padding: 1em;
    width: 100%;
    padding-top: 1.6em;
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) => theme.palette.primary.light};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    padding: 1em !important;
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }

  ${({ theme }) => [theme.breakpoints.up('md')]} {
    :after {
      width: 0;
      height: 0;
      border-bottom: 50px solid ${({ theme }) => theme.palette.secondary.light};
      border-right: 33px solid transparent;
      position: absolute;
      content: "";
      margin-top: -5px;
      margin-left: 5px;
    }
  }
`
