import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import LookBackRectangleBG from '@images/credit_reporting/optedin_lookback_rectangle.png'
import LookBackBlueBG from '@images/credit_reporting/optedin_blue_lookback_bg.png'
import LookBackBG from '@images/credit_reporting/optedin_lookback_ad_bg.png'
import LookBackSSO from '@images/credit_reporting/lookback_sso2.png'
import GoBtnBG from '@images/credit_reporting/go_btn_bg.png'

export const GoButton = styled.div`
  height: 30px;
  width: 122px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
  line-height: 16px;
  font-size:${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  padding: ${({ theme }) =>
    `${theme.space.s5} ${theme.space.s12} ${theme.space.s6} 0`};
  z-index: 0;
  margin-left: 21px;
  margin-top: ${({ theme }) => (theme.ux.isSSO ? -5 : 18)}px;

  &:before,
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    z-index: -1;
  }
  &:before{
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    width: 0;
    transition: 0.25s width;
    z-index: -1;
  }
  &:after{
    width: 100%;
    background-image: url('${GoBtnBG}');
    background-size: 100% 100%;
  }
`

export const LookbackAd = styled(Link)`
  height: 182px;
  width: 246px;
  position: relative;
  margin-left: 6px;
  margin-right: -4px;
  background: url(${LookBackRectangleBG}) right no-repeat;
  background-size: cover;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    ${({ theme }) => theme.ux.isSSO && 'display: none;'}
  }

  &::before {
    top: 0;
    left: -6px;
    height: 100%;
    z-index: -1;
    background: url(${LookBackBlueBG}) top left no-repeat;
  }

  &::after {
    bottom: 3.4px;
    right: 4px;
    height: 98%;
    background: url(${LookBackBG}) top right no-repeat;
  }

  &:hover ${GoButton}:before, &:focus ${GoButton}:before {
    width: 100%;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    background-image: url(${LookBackSSO});
    width: 178px;
    height: 100px;
    margin: -1px;

    &>*{
      transform: scale(0.77);
      transform-origin: top left;
      white-space: nowrap;
      margin-left: 13px !important;
    }
  `}
`

export const LookbackAdText = styled.div`
  padding-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s20 : theme.space.s25};
  margin-left: ${({ theme }) => theme.space.s20};
`

export const BodyText = styled.div`
  line-height: 20px;
  font-size: 12px;
  padding-top: ${({ theme }) => theme.space.s6};
  padding-right: ${({ theme }) => theme.space.s50};

  ${({ theme }) => theme.ux.isSSO && 'display: none;'}
`

export const SubText = styled.div`
  line-height: 20px;
  font-size: 16px;
  padding-top: ${({ theme }) => theme.space.s4};
`

export const HeadText = styled.div`
  line-height: 20px;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  ${({ theme }) => [theme.breakpoints.only('md')]} {
    font-size: 24px;
  }
`
