import { createFetchActions } from '../../../helpers/actions/creators'

export const PAYMENT_BALANCE_SECTION = 'PAYMENTS_BALANCE'

export const {
  fetchAction: fetchPaymentBalanceAction,
  fetchStartAction: fetchPaymentBalanceStart,
  fetchSuccessAction: fetchPaymentBalanceSuccess,
  fetchErrorAction: fetchPaymentBalanceError
} = createFetchActions(PAYMENT_BALANCE_SECTION)

export const fetchPaymentBalance = residentId =>
  fetchPaymentBalanceAction({ residentId })
