// @flow
import styled from '@emotion/styled'
import IconButton from '@material-ui/core/IconButton'
import { StyledCard } from '../styles'

export const BlockedPaymentStyled = styled(StyledCard)`
  position: relative;
  & .MuiCardContent-root {
    height: 374px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: ${({ theme }) => theme.space.s45};
  }
  & h4 {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
    line-height: 29px;
    margin: 0;
  }
  & p {
    height: 188px;
    color: ${({ theme }) => theme.palette.common.grey};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    line-height: 24px;
    margin: ${({ theme }) => ` ${theme.space.s30} 0 0 `};
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    & p {
      font-size: ${({ theme }) => theme.custom.font.size.H6};
      margin: ${({ theme }) => ` ${theme.space.s10} 0 0 `};
    }
  }
`

export const IconButtonStyled = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: 2px solid #ed3b6a;
  padding: 0;
  margin-right: 19px;
  & svg {
    color: #ed3b6a;
  }
`
