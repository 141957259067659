import MUIFormControlLabel from '@material-ui/core/FormControlLabel'
import styled from '@emotion/styled'

export const FormControlLabel = styled(MUIFormControlLabel)`
  & .form-control-label {
    color: ${({ theme, selected }) =>
      selected ? theme.palette.common.midnight : theme.palette.common.grey};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`
