import {
  createFetchActions,
  createUpdateActions
} from '../../../helpers/actions/creators'
export const PAYMENT_METHODS_SECTION = 'PAYMENTMETHODSSECTION'
export const BANK_ACCOUNT = 'BANKACCOUNT'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD'
export const CARD = 'CARD'
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD'
export const PAYMENT_METHOD_CREATE_SUCCESS_REDIRECT =
  'PAYMENT_METHOD_CREATE_SUCCESS_REDIRECT'
export const SAVE_SELECTED_METHOD_TYPE = 'SAVE_SELECTED_METHOD_TYPE'

export const LAST_USED_PAYMENT_METHOD = 'LAST_USED_PAYMENT_METHOD'

export const {
  fetchAction: fetchPaymentMethodsAction,
  fetchStartAction: fetchPaymentMethodsStart,
  fetchSuccessAction: fetchPaymentMethodsSuccess,
  fetchErrorAction: fetchPaymentMethodsError
} = createFetchActions(PAYMENT_METHODS_SECTION)

export const fetchPaymentMethods = (residentId, isOnetimePayment, checkAllowed = false) =>
  fetchPaymentMethodsAction({
    residentId,
    isOnetimePayment,
    checkAllowed
  })

export const {
  updateAction: updateBankAccountAction,
  updateStartAction: updateBankAccountStart,
  updateSuccessAction: updateBankAccountSuccess,
  updateErrorAction: updateBankAccountError
} = createUpdateActions(BANK_ACCOUNT)

export const {
  updateAction: updateCardAction,
  updateStartAction: updateCardStart,
  updateSuccessAction: updateCardSuccess,
  updateErrorAction: updateCardError
} = createUpdateActions(CARD)

export const updateCard = (payload, isDebitCard = false) =>
  updateCardAction({
    payload,
    isDebitCard
  })

export const updateBankAccount = (residentId, payload) =>
  updateBankAccountAction({
    residentId,
    payload
  })

export const selectPaymentMethod = id => ({
  type: SELECT_PAYMENT_METHOD,
  id
})

export const setPaymentMethod = payload => ({
  type: SET_PAYMENT_METHOD,
  payload
})

export const deletePaymentMethod = (residentId, id, typeName) => ({
  type: DELETE_PAYMENT_METHOD,
  id,
  typeName,
  residentId
})

export const redirectOnMethodCreateSuccess = (
  redirectSuccess,
  redirectFailure
) => ({
  type: PAYMENT_METHOD_CREATE_SUCCESS_REDIRECT,
  redirectSuccess,
  redirectFailure
})

export const saveSelectedMethodType = methodType => ({
  type: SAVE_SELECTED_METHOD_TYPE,
  payload: methodType
})

export const {
  fetchAction: fetchLastUsedPaymentMethod,
  fetchStartAction: fetchLastUsedPaymentMethodStart,
  fetchSuccessAction: fetchLastUsedPaymentMethodSuccess,
  fetchErrorAction: fetchLastUsedPaymentMethodError
} = createFetchActions(LAST_USED_PAYMENT_METHOD)
