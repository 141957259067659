// @flow
import countryRegionData from 'country-region-data'

export const UNITED_STATES = 'US'
export const CANADA = 'CA'

// These states are not supported on platform 1.0
const UNSUPPORTED_REGIONS = {
  US: {
    FM: 'Micronesia',
    MH: 'Marshall Islands',
    PW: 'Palau',
    AA: 'Armed Forces Americas',
    AE: 'Armed Forces Europe, Canada, Africa and Middle East',
    AP: 'Armed Forces Pacific'
  }
}

const buildCountryRegionData = (): { countries: Object[], regions: Object } => {
  const data = countryRegionData.reduce(
    (acc, { countryName, regions, countryShortCode }) => {
      const country = { label: countryName, value: countryShortCode }
      if ([UNITED_STATES, CANADA].includes(countryShortCode)) {
        acc.countries.unshift(country)
      } else {
        acc.countries.push(country)
      }
      acc.regions[countryShortCode] = regions
      return acc
    },
    { countries: [], regions: {} }
  )
  return data
}

const getBuildRegionsOptions = (regions: Object, unsupportedRegions?: Object = {}) => {
  const regionOptions = {}
  return (country: string, shortCodeHasValue?: boolean = false) => {
    if (regionOptions[country]) {
      return regionOptions[country]
    }
    const region = regions[country]
    if (region) {
      const options = region
        .filter(({ shortCode }: { shortCode: string }) => !(unsupportedRegions[country] && unsupportedRegions[country][shortCode]))
        .map(
          ({ name, shortCode }: { name: string, shortCode: string }) => ({
            label: name, // Texas
            value: shortCodeHasValue ? shortCode : name // TX, Texas
          })
      )
      regionOptions[country] = options
      return options
    }
    return []
  }
}

const { countries, regions } = buildCountryRegionData()
const buildRegionsOptions = getBuildRegionsOptions(regions)
const buildSupportedRegionsOptions = getBuildRegionsOptions(regions, UNSUPPORTED_REGIONS)
export { countries, buildRegionsOptions, buildSupportedRegionsOptions }
