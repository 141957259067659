import gpl from 'graphql-tag'
import { CREDIT_REPORTING_CONFIG } from './fragments'

export const GET_CREDIT_REPORTING_CONFIG = 'getCreditReportingConfig'
export const GET_REPORTED_LEVEL_CREDIT_TRANSACTIONS = 'getReportedLevelCreditTrans'
export const GET_REPORTED_LOOKBACK_TRANSACTIONS = 'getLookBackTrans'

export const getCreditReportingConfig = gpl`
query {
    ${GET_CREDIT_REPORTING_CONFIG} {
        ...CreditReportingConfig
    }
}
${CREDIT_REPORTING_CONFIG}
`

export const getReportedLevelCreditTransactions = gpl`
  query {
    ${GET_REPORTED_LEVEL_CREDIT_TRANSACTIONS} {
      updated_at
    }
  }
`

export const getReportedLookbackTransactions = gpl`
  query {
    ${GET_REPORTED_LOOKBACK_TRANSACTIONS} {
      trans_date
    }
  }
`
