//@flow
import React from 'react'
import { Redirect } from 'react-router-dom'
import ResidentWeb from './ResidentWeb'
import MobileWeb from './MobileWeb'
import queryString from 'query-string'
import api from 'zego-shared/utils/api'
import Loader from '@Common/Loader'
import { LeaseRenewalBox, LoginStyledDesktop } from './styles'
import { isMobile } from "react-device-detect";
const setUserInterest = async identifier => {
  if (!identifier) {
    return false
  }
  return (await api.setUserInterest(identifier).catch(err => false))
}
class LeaseRenewal extends React.Component {
  state = {
    tokenIsValid: undefined,
    userId: undefined
  }

  async componentDidMount() {
    const {
      location: { search }
    } = this.props
    const { identifier } = queryString.parse(search)
    const tokenIsValid = Boolean(await setUserInterest(identifier))
    this.setState({ tokenIsValid: tokenIsValid, userId: identifier })
  }

  render() {
    const { state } = this

    return state.tokenIsValid === true ? (
      isMobile === true ?
        (
          <LeaseRenewalBox>
            <ResidentWeb />
            <MobileWeb userId={state.userId} />
          </LeaseRenewalBox>
        ) :
        <LoginStyledDesktop>
          <LeaseRenewalBox>
            <ResidentWeb />
          </LeaseRenewalBox>
        </LoginStyledDesktop>
    ) : state.tokenIsValid === false ? (
      <Redirect to="/login" />
    ) : (
      <Loader />
    )
  }
}

export default LeaseRenewal
