import styled from '@emotion/styled'
import CheckImg from '@images/check2.png'
import DebitCardImg from '@images/debit_card.png'
import Flex from '@Common/Flex'
import Card from '@Common/Card'

export const ExistingCardWrapper = styled(Card)(
  ({ theme, showPaymentNotes }) => `
  &>div{
    display: flex;
    flex-direction: column;
  }

  ${theme.breakpoints.down('md')}{
    ${showPaymentNotes ? '' : 'box-shadow: none !important;'}

    &>div{
      padding: ${theme.space.s15} !important;
    }
  }
  `
)

export const ExistingCardMainContent = styled(Flex)(
  ({ theme }) => `
    flex: 1;
    flex-direction: column;
    justify-content: end;
    padding-left: ${theme.space.s60};

    ${theme.breakpoints.down('sm')}{
      padding-left: ${theme.space.s40};
    }
  `
)

export const ExistingCardContent = styled(Flex)(
  ({ theme }) => `
    ${theme.breakpoints.down('sm')}{
      margin-bottom: ${theme.space.s30};
    }
  `
)

export const ExistingBankWrapper = styled(Flex)(
  ({ theme }) => `
    justify-content: space-between;
    ${theme.ux.isSSO &&
      `height: 272px;
    `}

    ${theme.breakpoints.down('lg')}{
      flex-direction: ${theme.ux.isSSO ? 'row' : 'column'};
    }
  `
)

export const ExistingBankInfoWrapper = styled('div')(
  ({ theme }) => `
    flex: 0.7;
    padding: ${theme.space.s15} ${theme.space.s15} 0;
    margin-bottom: ${theme.space.s30};

    ${theme.ux.isSSO &&
      `
      max-width: 238px;
      padding-left: ${theme.space.s10};
    `}
  `
)

export const ExistingBankInfo = styled(Flex)(
  ({ theme, debitEnabled }) => `
    flex-direction: column;
    max-width: 320px;
    margin-right: ${theme.ux.isSSO ? theme.space.s10 : theme.space.s45};

    ${
      // Always apply this style to this element if debit is enabled
      // Otherwise apply it only under certain screen size condition
      !debitEnabled ? '&' : theme.breakpoints.down(theme.ux.isSSO ? 'md' : 'lg')
    }{
      max-width: unset;
      flex-direction: row;
      align-items: end;
      margin-bottom: ${theme.space.s20};
      margin-right: 0;

      & > div{
        flex: 1;
      }

      & > div:last-child{
        padding-left: ${theme.space.s40};
      }
    }
  `
)

export const CheckMark = styled('div')`
  width: 24px;
  height: 24px;
  background-image: url(${CheckImg});
  background-size: 100% 100%;
  margin-right: ${({ theme }) => theme.space.s10};
`

export const CheckTextContainer = styled(Flex)(
  ({ theme }) => `
  width: 160px;
  justify-content: flex-start;

  ${theme.breakpoints.down('md')} {
    width: 185px;
  }
`
)

export const Title = styled('h3')(
  ({ theme }) => `
    line-height: 40px;
    margin-top: 0;
    margin-bottom: ${theme.space.s15};
    color: ${theme.palette.common.midnight};
    font-weight: ${theme.custom.font.weight.bolder};
    font-size: ${theme.custom.font.size.H3};

    ${theme.ux.isSSO &&
      `
      font-size: ${theme.custom.font.size.H4};
      margin-bottom: ${theme.space.s10};
      line-height: 1.3;
    `}

    ${theme.breakpoints.down('md')} {
      font-size: ${theme.custom.font.size.H3};
      margin-bottom: ${theme.space.s20};
    }

    ${theme.breakpoints.only('xs')} {
      margin-bottom: ${theme.space.s10};
    }
  `
)
export const Thin = styled('span')(
  ({ theme }) => `
    font-weight: ${theme.custom.font.weight.light};
  `
)

export const SubTitle = styled('p')(
  ({ theme, small }) => `
    color: ${theme.palette.common.midnight};
    font-size: ${small ? theme.custom.font.size.H5 : theme.custom.font.size.H4};
    font-weight: ${theme.custom.font.weight.light};
    line-height: 22px;
    margin: 0 0 ${theme.space.s26};

    ${theme.ux.isSSO &&
      `
      font-size: ${theme.custom.font.size.H5};
      margin-bottom: ${theme.space.s20};
    `}
  `
)

export const CheckText = styled('p')(
  ({ theme }) => `
  color: ${theme.palette.common.midnight};
  font-size: ${theme.custom.font.size.md};
  font-weight: ${theme.custom.font.weight.light};
  line-height: 15px;
  margin: 0 0 ${theme.space.s15};

  ${theme.ux.isSSO &&
    `
    margin-bottom: ${theme.space.s12};
  `}
`
)

export const ExistingBankDebitWrapper = styled('div')`
  flex: 1;
`

export const QuickPaymentsCard = styled('div')(
  ({ theme, noImage }) => `
    flex: 1;
    border: 2px solid ${theme.palette.common.midnight};
    border-radius: 5px;
    background: ${theme.palette.neutral.light} url(${DebitCardImg})
      right bottom no-repeat;
    ${noImage ? 'background-image: none;' : ''};
    background-size: 50%;
    padding: ${theme.space.s15} ${theme.space.s30};

  ${theme.ux.isSSO &&
    `padding-bottom: ${theme.space.s5};
    background-size: 70%;
    background-position: 225% bottom
    `}
  `
)

export const NoPayment = styled(Flex)(
  ({ theme, showPaymentNotes }) => `
    flex-direction: row;

    ${theme.breakpoints.down('md')}{
      flex-direction: column;
    }

    ${theme.ux.isSSO &&
      `margin-bottom: ${theme.space.s20};
    `}
  `
)

export const SetupAccCard = styled(Card)(
  ({ theme }) => `
    border: 2px solid ${theme.palette.neutral.main};
    align-items: center;
    margin-left: 18px;

    ${[theme.breakpoints.down('md')]}{
      margin-left: 0;
      margin-top: ${theme.space.s10}
    }
  `
)

export const BankAcctContent = styled('div')(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.space.s20} ${theme.space.s45} ${theme.space.s10};
    height: 100%;

    ${theme.ux.isSSO &&
      `padding-left: ${theme.space.s15};
      padding-right: ${theme.space.s15};
      max-width: 220px;
    `}

    ${theme.breakpoints.down('md')}{
      padding: ${theme.space.s20} ${theme.space.s25} ${theme.space.s10};
      align-items: unset;
    }

    ${[theme.breakpoints.down('xs')]}{
      align-items: center;
      height: 300px;
    }
  `
)

export const SubTitleAndChecksContainer = styled('div')(
  ({ theme }) => `
  ${theme.ux.isSSO &&
    `flex: 1;
  `}
`
)

export const UnderlinedButton = styled('button')`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme, light }) =>
    light ? theme.palette.common.grey : theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6} !important;
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  text-decoration-line: underline;
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }
`

export const LinksContainer = styled(Flex)(
  ({ theme }) => `
    margin-top: ${theme.ux.isSSO ? 0 : theme.space.s60};
    
    ${theme.ux.isSSO && `
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 5px 15px;
    `}

    ${theme.breakpoints.down('md')}{
      margin-top: ${theme.space.s35};
    }

    ${theme.breakpoints.down('sm')}{
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${theme.space.s120};
      padding: ${theme.space.s25};
      flex-direction: column-reverse;
      justify-content: space-between;
      background: white;
    }
  `
)

export const CurrentMethodText = styled('h5')`
  position: relative;
  top: -6px;
  margin: 0 0 ${({ theme }) => theme.space.s10};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  color: ${({ theme }) => theme.palette.common.midnight};

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: 16px;
  }
`

export const BankChecks = styled('img')(
  ({ theme }) => `
  width: 100%;
  ${theme.ux.isSSO &&
    `max-height: 88px;
    object-fit: contain;
    object-position: left;
    `}
`
)

export const MethodText = styled('p')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  margin: 0;
`

export const BankFee = styled('p')(
  ({ theme }) => `
  ${theme.ux.isSSO &&
    `margin-top: 0;
    `}
`
)

export const FeeText = styled('p')`
  font-size: ${({ theme }) => theme.custom.font.size.md};
  margin: 0 0 30px 0;
  font-weight: ${({ theme }) => theme.custom.font.weight.light};

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: 15px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin: 0;
  }
`
