import gql from 'graphql-tag'

export const BANK_ACCOUNT_FIELDS = gql`
  fragment BankAccountFields on BankAccount {
    id
    accountHolderName
    bankName
    type
    routingNumber
    lastFour
    currencyCode
    __typename
  }
`

export const PAYMENT_CARD_FIELDS = gql`
  fragment PaymentCardFields on PaymentCard {
    id
    cardHolderName
    network
    expirationMonth
    expirationYear
    lastFour
    __typename
  }
`
