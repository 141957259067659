// @flow
import get from 'lodash/get'
import { createSelector } from 'reselect'

export const getProperties = (state: Object) =>
  get(state, 'userResource.properties') || {}

export const getProperty = createSelector(
  getProperties,
  (properties: Object) => {
    return Object.values(properties)[0] || {}
  }
)
