// @flow
import styled from '@emotion/styled'
import LinearProgress from '@material-ui/core/LinearProgress'

export const ProgressBarStyled = styled(LinearProgress)`
  height: 6px;
  width: 100%;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.palette.neutral.light4};

  & .bar {
    border-radius: 3px;
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`
