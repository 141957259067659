// @flow

import React from 'react'
import { StyledClientNotes, NoteBox, NoteSpan } from './styles'

type Props = {
  isFetching: boolean,
  clientNotes: {
    notes: string
  }
}

const ClientNotes = (props: Props) => {
  if (props.isFetching || !(props.clientNotes) || !(props.clientNotes.notes)) {
    return null
  }

  const notes = props.clientNotes.notes

  return (
    <React.Fragment>
      <StyledClientNotes>
        <NoteSpan>PM UI/Resident UI Notes</NoteSpan>
      </StyledClientNotes>
      <NoteBox>
        <NoteSpan dangerouslySetInnerHTML={{__html: notes}} />
      </NoteBox>
    </React.Fragment>
  )
}

export default ClientNotes
