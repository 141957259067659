import gql from 'graphql-tag'

export const getPmSettings = gql`
  query {
    getPmSettings {
      residentNsfFee {
        currency
        amount
      }
      paymentNotesEnabled
      showSSOEmailCollectionForm
      disableUIPayments
      disableSummaryMenuTab
      disableMyProfileMenuTab
      disableAutopayMenuTab
      disableMakePaymentMenuTab
      disableHelpMenuTab
      disableLogOutMenuTab
    }
  }
`
