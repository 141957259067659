// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { NameText, InfoText, StyledLink, StyledButton } from './styles'
import { StyledCard } from '../styles'

const renderAddress = ({ address1, city, zip }) => {
  return (
    <>
      {address1 && (
        <>
          {`${address1},`}
          <br />
        </>
      )}
      {city && `${city},`} {zip}
    </>
  )
}

type Props = {
  account: {
    firstName: string,
    lastName: string,
    propertyName: string,
    address1: string,
    email: string,
    zip: string,
    city: string
  },
  continueUrl: string,
  supportUrl: string
}

const RegisterAccountCard = ({ account, supportUrl, continueUrl }: Props) => {
  return (
    <StyledCard>
      <Grid container direction="column" alignItems="stretch">
        <Grid item>
          <NameText>
            {account.firstName} {account.lastName}
          </NameText>
        </Grid>
        <Grid item>
          <InfoText>{renderAddress(account)}</InfoText>
        </Grid>
        <Grid item>
          <InfoText>{account.propertyName}</InfoText>
        </Grid>
        <Grid item container justify="flex-start">
          <StyledLink to={supportUrl} target="_blank" rel="noopener noreferrer">
            this is not me
          </StyledLink>
        </Grid>
        <Grid item>
          <StyledButton
            disableRipple
            primary
            fullWidth
            type="submit"
            component={Link}
            to={continueUrl}>
            Register Account
          </StyledButton>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

export default RegisterAccountCard
