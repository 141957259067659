import { createTheme } from '@material-ui/core/styles'

export const addSsoPaymentsOverrides = theme =>
  createTheme({
    ...theme,
    ux: {
      isSSO: true
    },
    breakpoints: {
      values: { xs: 0, sm: 600, md: 649, lg: 649.5, xl: 900 }
    },
    overrides: {
      ...theme.overrides,
      MuiContainer: {
        root: {
          paddingRight: `${theme.space.s10} !important`,
          paddingLeft: `${theme.space.s10} !important`,
          width: '100%',
          maxWidth: 800
        }
      }
    }
  })
