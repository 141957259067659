// @flow
import some from 'lodash/some'
import get from 'lodash/get'

// returns true if any of the updating flags are set to true
// eslint-disable-next-line
export const createIsUpdatingSelector = (keys: Array<string>) => (
  state: Object
) => some(keys, key => get(state, ['isUpdating', key]) === true)

export const getIsUpdatingOTP = (state: Object) =>
  get(state, ['isUpdating', 'onetimepayment'])

export const getIsUpdatingAutopay = (state: Object) =>
  get(state, ['isUpdating', 'autopay'])

