import { createFetchActions } from '../../helpers/actions/creators'

export const MARKETPLACEDEALLIST = 'MARKETPLACEDEALLIST'

export const {
  fetchAction: fetchMarketplaceDeals,
  fetchStartAction: fetchMarketplaceDealsStart,
  fetchSuccessAction: fetchMarketplaceDealsSuccess,
  fetchErrorAction: fetchMarketplaceDealsError
} = createFetchActions(MARKETPLACEDEALLIST)
