// @flow
import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import ReauthFlowCard from '../ReauthFlowCard'
import ReauthZegoSuccessCard from '../ReauthZegoConfirmationCard'
import PlaidLinkUpdate from '../../PlaidLinkUpdate'
import { accountsMatch } from 'zego-shared/utils/plaid'
import { useSelector } from 'react-redux'
import { getErrorOccured } from 'zego-shared/store/snackbar/selectors'
import { useHistory } from 'react-router-dom'

export type Props = {
  plaidItem: {
    itemId: string,
    institutionId: string,
    name: string,
    bankName: string,
    type: string,
    lastFour: string,
  },
  onClose: () => {}
}

const ReauthFlowModal = ({ plaidItem, onClose }: Props) => {
  const [show, setShow] = useState(false)
  const [itemId, setItemId] = useState('')
  const [updatedPlaidItem, setUpdatedPlaidItem] = useState({
    originalPlaidItem: {},
    id: '',
    bankName: '',
    subtype: '',
    mask: ''
  })
  const error = useSelector(getErrorOccured)
  const history = useHistory()

  useEffect(() => {
    setShow(!!(plaidItem || updatedPlaidItem))
  }, [plaidItem, updatedPlaidItem])

  const resetModalAndClose = (_itemId, metadata) => {
    const account = metadata ? metadata.accounts[0] : null
    if((account) && !accountsMatch({...plaidItem, mask: plaidItem.lastFour}, {...account, institutionId: metadata.institution.institution_id})){
      setUpdatedPlaidItem({
        originalPlaidItem: plaidItem,
        id: account.id,
        bankName: metadata.institution.name,
        subtype: account.subtype,
        mask: account.mask
      })
    } else {
      setShow(false)
      onClose && onClose()
    }
    setItemId('')
  }

  const handleContinue = () => {
    setItemId(plaidItem.itemId)
  }

  useEffect(() => {
    if(error) {
        setShow(false)
        onClose && onClose()
        history.push('/payments/summary')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const handleContinueAndCloseAfterZegoSuccess = () => {
    setUpdatedPlaidItem({
      originalPlaidItem: {},
      id: '',
      bankName: '',
      subtype: '',
      mask: ''
    })
    setShow(false)
    onClose && onClose()
  }

  const getReauthFlowModalCard = () => {
    if (plaidItem && !updatedPlaidItem.id) {
      return (
        <ReauthFlowCard
          plaidItem={plaidItem}
          onClose={resetModalAndClose}
          onContinue={handleContinue}
        />
      )
    } else if (updatedPlaidItem.id) {
      return (
        <ReauthZegoSuccessCard
          updatedPlaidItem={updatedPlaidItem}
          onClose={handleContinueAndCloseAfterZegoSuccess}
          onContinue={handleContinueAndCloseAfterZegoSuccess}
        />
      )
    } else {
      return null
    }
  }

  return (
    <Dialog disableEnforceFocus disableAutoFocus open={show}>
      {getReauthFlowModalCard()}
      {(itemId && !updatedPlaidItem.id) ? (
        <PlaidLinkUpdate
          plaidItem={plaidItem}
          onSuccess={resetModalAndClose}
          onExit={resetModalAndClose}
          onError={resetModalAndClose}
        />
      ) : null}
    </Dialog>
  )
}

export default ReauthFlowModal
