// @flow
import React from 'react'
import type { Node } from 'react'
import { StyledCard, StyledCardContent } from './styles'

type Props = {
  children: Node,
  CardContentProps?: Object,
  unwrapContent?: boolean,
  paddingTop?: string,
  paddingRight?: string,
  paddingBottom?: string,
  paddingLeft?: string,
  marginTop?: string,
  marginRight?: string,
  marginBottom?: string,
  marginLeft?: string,
  noShadow?: boolean,
  customPadding?: string
}

const Card = (props: Props) => {
  const {
    children,
    CardContentProps = {},
    unwrapContent = false,
    customPadding,
    ...rest
  } = props

  const content = unwrapContent ? (
    children
  ) : (
    <StyledCardContent
      customPadding={customPadding}
      {...CardContentProps}>
      {children}
    </StyledCardContent>
  )
  return <StyledCard {...rest}>{content}</StyledCard>
}

export default Card
