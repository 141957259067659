// @flow

import get from 'lodash/get'
import { createSelector } from 'reselect'
import {
  getUserEmail,
  getUserId,
  getUserRole
} from '../../authorization/selectors'
import { getSelectedUnit } from '../../select/selectors'

export const getFeatureFlag = (state: Object) => get(state, ['launchDarkly'])

export const getFeatureFlagValue = (flagName: string) => (state: Object) => {
  // $FlowDisableNextLine
  return getFeatureFlag(state)?.ld?.variation(flagName)
}

export const getLDUserInfo = createSelector(
  getUserEmail,
  getUserId,
  getUserRole,
  getSelectedUnit,
  (email, key, role, unitInfo = {}) => {
    const {
      property: {
        id: property_code,
        name: property_name,
        uuid: property_id,

        company_name,
        company_uuid: company_id,
        company_id: company_code
      } = {}
    } = unitInfo

    const userInfo = {
      key,
      email,
      custom: {
        role,
        company_code,
        company_id,
        company_name,
        property_code,
        property_name,
        property_id
      }
    }

    return key || email ? userInfo : { anonymous: true }
  }
)
