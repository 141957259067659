// @flow

export const HISTORY_QUERY_NAME = 'getTransactions'

export const getHistoryQuery = () => {
  return `query GetTransactions($first: Int, $cursor: String) {
    ${HISTORY_QUERY_NAME}(first: $first, after: $cursor) {
        edges {
          cursor
          node {
            transId
            feeAmount {
              amount
              currency
            }
            totalAmount {
              amount
              currency
            }
            transactionDate
            paymentMethodName
            paymentType
            isAutopay
            status
            paymentFields {
              key
              label
              money {
                amount
                currency
              }
            }
            propertyName
            companyName
            adjustmentDetails {
              reason
              reasonSummary
            }
            voidable
            notes
            isExpressPay
            expressPayFee {
              amount
              currency
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  `
}

export const getHistoryVariables = (cursor: ?string) => ({
  first: 20,
  cursor
})
