export const getPlaidItemsQuery = () => {
  return `query {
    getPlaidItems {
      itemId,
      zegoAccountId,
      institutionId,
      bankName,
      accountMask,
      accountName,
      reauthRequired
    }
  }`
}
