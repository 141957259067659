// @flow
import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { Formik } from 'formik'
import { fetchTermsAndConditionsAction } from 'zego-shared/store/termsAndConditions/actions/'
import Flex from '@Common/Flex'
import Hidden from '@material-ui/core/Hidden'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import { getErrorInfo, getHasError } from '@utils/view'
import { creditReportingOptIn } from 'zego-shared/store/payments/creditReporting/actions'
import {
  buildCreditReportingOptInPayload,
  hasCreditReportingDetails,
  getInitialValues,
  isLevelCreditReportingEnabled,
  getLevelCreditDefaultEmail
} from 'zego-shared/store/payments/creditReporting/selectors'
import { createLatestTnCSelector } from 'zego-shared/store/termsAndConditions/selectors'
import { createIsUpdatingSelector } from 'zego-shared/store/isUpdating/selectors'
import { useDispatch, useSelector } from 'react-redux'
import initialValues from '../initialValues'
import schema from '../schema'
import LevelCreditFields from './LevelCreditFields'
import ConfirmModal from './OptInModal'
import {
  LCButtonWrap,
  LevelCreditHeaderStyled,
  LevelCreditSubHeaderStyled,
  HeaderDiv,
  LCStyledButton,
  HeaderContainer,
  LevelCreditLogoDiv,
  LevelCreditFormContainer,
  LevelCreditFormHeader
} from './styles'

type Props = {
  history: {
    push: Function
  },
  match: {
    url: string
  },
  isNew?: boolean
}
type FormProps = {
  hasDetails: boolean,
  prevDetails: Object,
  latestTnC: Object,
  isLevelCreditReportingEnabled: boolean,
  defaultEmail: string
} & Props
export const CRFData = () => {
  // Fetch latest level credit Terms
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchTermsAndConditionsAction({ tncType: 'level_credit' }))
  }, [dispatch])

  return {
    hasDetails: useSelector(hasCreditReportingDetails),
    prevDetails: useSelector(getInitialValues) || initialValues,
    latestTnC: useSelector(createLatestTnCSelector('level_credit')),
    isLevelCreditReportingEnabled: useSelector(isLevelCreditReportingEnabled),
    defaultEmail: useSelector(getLevelCreditDefaultEmail)
  }
}
const getButtonText = (isProcessing: boolean, isNew?: boolean) => {
  if (isProcessing) return 'Processing'
  if (isNew) return 'Submit'
  return 'save'
}

export const getSubmitForm = ({
  setIsProcessing,
  dispatch,
  redirectToURL
}: {
  setIsProcessing: Function,
  dispatch: Function,
  redirectToURL: Function
}) => (formData: Object) => {
  setIsProcessing(true)
  dispatch(
    creditReportingOptIn(
      buildCreditReportingOptInPayload(formData),
      redirectToURL
    )
  )
}

export const getButtons = ({
  disableSubmit,
  isProcessing,
  isNew,
  onCancelClick,
  submitOnConfirm
}: {
  disableSubmit: Function,
  isProcessing: boolean,
  isNew?: boolean,
  onCancelClick: Function,
  submitOnConfirm: Function
}) => {
  return (
    <LCButtonWrap>
      <LCStyledButton
        id="cancelBtn"
        onClick={onCancelClick}
        primary
        outline
        fullWidth>
        Cancel
      </LCStyledButton>
      <LCStyledButton
        id="submitBtn"
        primary
        onClick={submitOnConfirm}
        disabled={disableSubmit || isProcessing}
        animate={isProcessing}>
        {getButtonText(isProcessing, isNew)}
      </LCStyledButton>
    </LCButtonWrap>
  )
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const LevelCreditForm = ({
  history,
  match,
  isNew,
  hasDetails,
  prevDetails,
  latestTnC,
  isLevelCreditReportingEnabled,
  defaultEmail
}: FormProps) => {
  const [validateLeaseMonth, setValidateLeaseMonth] = React.useState(
    !prevDetails.leaseMonthToMonth
  )
  if (isLevelCreditReportingEnabled) {
    prevDetails.email = defaultEmail
  }
  const isUpdating: boolean = useSelector(
    createIsUpdatingSelector(['creditreportingoptin'])
  )

  const [showConfirmModal, setShowConfirmModal] = React.useState(false)

  const submitOnConfirm = () => {
    setShowConfirmModal(true)
  }

  const backToEdit = () => {
    setShowConfirmModal(false)
  }

  const [isProcessing, setIsProcessing] = React.useState(false)
  const query = useQuery()
  const lookbackInterest = query.get('lookback')
  const landingPage = match.url.replace('/signup', '')
  const lookbackPurchaseUrl = match.url.replace(
    '/signup',
    '/lookback/purchase?newOptIn=true'
  )
  const redirectToURL = () =>
    history.push(lookbackInterest ? lookbackPurchaseUrl : landingPage)
  const onCancelClick = () => history.push(landingPage)

  const dispatch = useDispatch()
  const submitForm = getSubmitForm({
    setIsProcessing,
    dispatch,
    redirectToURL
  })

  const formikProps = {
    initialValues: prevDetails,
    onSubmit: submitForm,
    validationSchema: schema(validateLeaseMonth, isLevelCreditReportingEnabled),
    validateOnMount: true
  }

  React.useEffect(() => {
    setIsProcessing(isUpdating)
  }, [isUpdating])

  if (hasDetails) {
    return <Redirect to={landingPage} />
  }

  return (
    <Flex direction="column">
      <HeaderContainer justifySpaceBetween>
        <HeaderDiv>
          <LevelCreditHeaderStyled id="creditReportingOptIn">
            Sign Up with LevelCredit
          </LevelCreditHeaderStyled>
          <LevelCreditSubHeaderStyled id="creditReportingOptIn">
            To opt into the LevelCredit credit reporting we need a bit of
            information from you!
          </LevelCreditSubHeaderStyled>
        </HeaderDiv>
        <LevelCreditLogoDiv />
      </HeaderContainer>
      <LevelCreditFormContainer direction="column">
        <Formik {...formikProps}>
          {({
            handleChange,
            handleSubmit,
            errors,
            touched,
            values,
            ...formik
          }) => {
            const hasError: string => boolean = getHasError(errors, touched)
            const disableSubmit = Object.keys(errors).length > 0
            const getErrorDetails: string => Object = getErrorInfo(
              errors,
              touched
            )
            const getNamedProps = (name: string) => {
              return {
                error: hasError(name),
                value: values[name],
                initialDate: values[name],
                initialValue: values[name],
                name
              }
            }
            return (
              <React.Fragment>
                <LevelCreditFormHeader>
                Please enter your full legal name
                </LevelCreditFormHeader>
                <LevelCreditFields
                  onChange={handleChange}
                  getErrorDetails={getErrorDetails}
                  getNamedProps={getNamedProps}
                  values={values}
                  errors={errors}
                  handleLeaseMonthToMonthChange={setValidateLeaseMonth}
                  isLevelCredit={isLevelCreditReportingEnabled}
                  defaultEmail={defaultEmail}
                  latestTnC={latestTnC}
                  {...formik}
                />
                <Hidden smDown>
                  {getButtons({
                    disableSubmit,
                    isProcessing,
                    isNew,
                    onCancelClick,
                    submitOnConfirm
                  })}
                </Hidden>
                <MobileBtnsPortal>
                  {getButtons({
                    disableSubmit,
                    isProcessing,
                    isNew,
                    onCancelClick,
                    submitOnConfirm
                  })}
                </MobileBtnsPortal>
                <ConfirmModal
                  show={showConfirmModal}
                  backToEdit={backToEdit}
                  submitLCForm={handleSubmit}
                  values={values}
                />
              </React.Fragment>
            )
          }}
        </Formik>
      </LevelCreditFormContainer>
    </Flex>
  )
}
export default (props: Props) => <LevelCreditForm {...props} {...CRFData()} />
