// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'
import { generate as shortid } from 'shortid'
import { withTheme } from 'emotion-theming'
import {
  MoreVert as MoreVertIcon,
  Close as CloseIcon
} from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { CommonIcon } from '@icons'

import { getPosition } from '../../../store/moreNavPosition/selector'
import { SideNav, Wrapper, StyledLink, StyledFab } from './styles'

import type { BrowserLocation } from 'history'
import type { Theme } from '../../../theme'

type Props = {
  links: [
    {
      url: string,
      name: string
    }
  ],
  location: BrowserLocation,
  theme: Theme
}
const SideNavigation = ({ links, location, theme }: Props) => {
  const [showOnMobile, setShowOnMobile] = React.useState(false)
  const toggle = () => setShowOnMobile(!showOnMobile)
  const bottomPos = useSelector(getPosition)

  return (
    <SideNav bottomPos={`${bottomPos}px`}>
      <Wrapper showOnMobile={showOnMobile}>
        {links.map(link => {
          const isActive = location.pathname.includes(link.url)

          return (
            <StyledLink
              id={link.name.replace(/\s/g, '')}
              key={shortid()}
              exact
              to={link.url}
              activeClassName="active"
              onClick={toggle}
              isActive={(match, location) => {
                return location.pathname.includes(link.url)
              }}>
              {isActive && <CommonIcon name="square" height="10px" width="10px" fill={theme.palette.tertiary.dark} />}
              {link.name}
            </StyledLink>
          )}
        )}
      </Wrapper>
      {links.length > 0 && <StyledFab color="primary" aria-label="Submenu" onClick={toggle}>
        {showOnMobile ? <CloseIcon /> : <MoreVertIcon />}
      </StyledFab>}
    </SideNav>
  )
}

export default withRouter(withTheme(SideNavigation))
