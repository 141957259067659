// @flow
import type { Node } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { savePaymentNotes } from 'zego-shared/store/payments/paymentNotes/actions'
import {
  getPaymentNotesEnabled,
  getPaymentNotes
} from 'zego-shared/store/payments/paymentNotes/selectors'
import PaymentNotes from './PaymentNotes'

export type DataProps = {
  children: Node,
  disabled: boolean,
  variant: 'button' | 'wrapper'
}

const PaymentNotesContainer = ({
  disabled = false,
  children,
  variant = 'wrapper'
}: DataProps) => {
  const dispatch = useDispatch()
  const paymentNote = useSelector(getPaymentNotes)
  const paymentNotesEnabled = useSelector(getPaymentNotesEnabled)
  const onSave = notes => dispatch(savePaymentNotes(notes))
  const notesEnabled = !disabled && paymentNotesEnabled

  return PaymentNotes({ paymentNote, notesEnabled, onSave, children, variant })
}

export default PaymentNotesContainer
