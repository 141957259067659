// @flow
import React from 'react'

import { TextInput } from '@Common/Form'
import { WithSignStyled } from './styles'

type Props = {
  symbol: string,
  className?: string,
  renderOptional?: () => void,
}

const CurrencyTextInput = ({
  symbol,
  className,
  renderOptional = () => {},
  ...props
}: Props) => {
  return (
    <WithSignStyled alignCenter className={className}>
      <span>{symbol}</span>
      <TextInput {...props} />
      {renderOptional()}
    </WithSignStyled>
  )
}

export default CurrencyTextInput
