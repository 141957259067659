import MUIFormControl from '@material-ui/core/FormControl'
import styled from '@emotion/styled'

export const FormControl = styled(MUIFormControl)`
  ${({ disabled }) =>
    disabled &&
    `
    opacity:0.5;
    pointer-events:none;
  `};

  ${({ hidden }) =>
    hidden &&
    `
    visibility:hidden;
    pointer-events:none;
  `};


  & label.MuiInputLabel-formControl {
    position: relative;
    margin-bottom: 5px;
    transform: unset;
    color: ${({ theme }) => theme.palette.common.grey};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    &::first-letter {
      text-transform: uppercase;
    }
  }
`
