// @flow
import React, { useState, useMemo, useRef, useLayoutEffect } from 'react'
import Popover from '@material-ui/core/Popover'
import Avatar from '@Common/Avatar'
import PayleaseLogo from '@images/paylease_logo_black.png'
import PayleaseLogoMobile from '@images/pl_logo_small.png'
import SideNav from '@Common/SideNav'
import { Toolbar, Container, Hidden } from '@material-ui/core'
import { withRouter } from 'react-router'
import { useDispatch } from 'react-redux'
import { setPreviousUrl } from 'zego-shared/store/previousUrl/actions'
import { loginAs } from 'zego-shared/store/authorization/actions'
import DropDown from '../Common/DropDown'
import { getDropDownItems, getSelectedItem, getPropertyName } from './utils'

import {
  StyledAppBar,
  Typography,
  Logo,
  LogoMobile,
  Name,
  MyDetailsNav,
  MyDetailsLink,
  MyAvatar,
  StyledButton,
  AvatarButton,
  useStyles,
  CustomInput
} from './styles'

type ReactRouterProps = {
  location: { pathname: string },
  match: Object,
  history: Object,
  // staticContext is passed all the way down
  // from the context provided, we don't use this prop
  // but we still declare for flow so it does not fail
  // then we can remove it.
  // See the destructuring comment inside the component.
  staticContext?: Object
}

export type Props = {
  propertyName: string,
  unitName: ?string,
  residentName: string,
  profilePicture: string,
  isStaticAppBar: boolean,
  hideNavBar: boolean,
  hidePropertyLink: boolean,
  height: number,
  userId: string,
  linkedAccounts: Object[],
  disableMyProfileMenuTab: boolean,
  disableHelpMenuTab: boolean,
  disableLogOutMenuTab: boolean
} & ReactRouterProps

const AppBar = (props: Props) => {
  const ref: Object = useRef()
  const classes = useStyles()
  const {
    propertyName,
    unitName,
    residentName,
    profilePicture,
    height,
    history,
    match,
    location,
    hidePropertyLink,
    userId,
    hideNavBar,
    linkedAccounts = [],
    disableMyProfileMenuTab,
    disableHelpMenuTab,
    disableLogOutMenuTab,
    // Destructuring staticContext from ...rest
    // to remove warning of "React does nor recognize ... prop on a DOM element"
    // See: https://reactjs.org/warnings/unknown-prop.html
    staticContext,
    ...rest
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [buttonWidth, setButtonWidth] = useState(0)
  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  const dispatch = useDispatch()

  const handleButtonClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useLayoutEffect(() => {
    setButtonWidth(ref.current ? ref.current.clientWidth : 200)
  }, [])

  const setPreviousURL = () => {
    const currentUrl = match.url.split('/')

    const isMyAccount = ['my-account'].includes(currentUrl[1])
    !isMyAccount && dispatch(setPreviousUrl(location.pathname))
  }

  const goToMyPropertyInfo = () => {
    handleClose()
    setPreviousURL()
    history.push('/my-account/property')
  }

  const goToMyAccount = () => {
    handleClose()
    setPreviousURL()
    history.push('/my-account')
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : null

  const dropdownItems = useMemo(() => getDropDownItems(linkedAccounts), [
    linkedAccounts
  ])

  const handleLinkedAccountChange = (_, { id: userId }) => {
    dispatch(loginAs(userId, history))
  }

  return (
    <StyledAppBar {...rest} hide={hideNavBar} height={height}>
      <Container style={{ zIndex: 1 }}>
        <Toolbar disableGutters={true}>
          <Typography component="div">
            <Hidden only="xs">
              <Logo src={PayleaseLogo} alt="Logo" />
            </Hidden>
            <Hidden smUp>
              <LogoMobile src={PayleaseLogoMobile} alt="Logo Mobile" />
            </Hidden>
            {!(dropdownItems.length > 1) ? (
              <Name id="propNameTopNav">
                {getPropertyName(propertyName, unitName)}
              </Name>
            ) : (
              <DropDown
                data={dropdownItems}
                selectedItem={getSelectedItem(dropdownItems, userId)}
                variant="outlined"
                input={<CustomInput />}
                handleChange={handleLinkedAccountChange}
              />
            )}
          </Typography>
          <Hidden only="xs">
            <StyledButton
              isSideNavOpen={isSideNavOpen}
              id="appAccountBtn"
              ref={ref}
              name={residentName}
              primary={true}
              onClick={handleButtonClick}
              aria-describedby={id}
            />
          </Hidden>
          <Hidden smUp>
            <AvatarButton onClick={handleButtonClick}>
              <Avatar size="30px" picture={profilePicture} hideShadow={true} />
            </AvatarButton>
          </Hidden>
          <Popover
            id={id}
            open={open}
            style={{ borderRadius: '12px' }}
            anchorEl={anchorEl}
            onClose={handleClose}
            PaperProps={{
              classes: {
                root: classes.root,
                rounded: classes.rounded
              }
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <MyDetailsNav width={buttonWidth}>
              <Hidden smDown>
                <MyAvatar>
                  <Avatar
                    size="100px"
                    picture={profilePicture}
                    hideShadow={true}
                  />
                </MyAvatar>
              </Hidden>
              {!disableMyProfileMenuTab && (
                <MyDetailsLink id="myAccountBtn" onClick={goToMyAccount}>
                  My Account
                </MyDetailsLink>)}
              {!hidePropertyLink && (
                <MyDetailsLink onClick={goToMyPropertyInfo}>
                  My Property Info
                </MyDetailsLink>
              )}
            </MyDetailsNav>
          </Popover>
          <SideNav
            open={isSideNavOpen}
            name={residentName}
            myDetailsButtonRef={ref}
            width={buttonWidth}
            hideMyDetailsButton={setIsSideNavOpen}
            profilePicture={profilePicture}
            disableHelpMenuTab={disableHelpMenuTab}
            disableLogOutMenuTab={disableLogOutMenuTab}
          />
        </Toolbar>
      </Container>
    </StyledAppBar>
  )
}

export default withRouter(AppBar)
