import 'core-js/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import GraphQlProvider from './graphql/Provider'
import api from 'zego-shared/utils/api'
import { setResidentCookie } from 'zego-shared/utils/marketing'
import { store, persistor } from './store'
import networkUtils from './utils/networkUtils'
import * as serviceWorker from './serviceWorker'
import App from './App'
import ThemeProvider from './theme/Provider'
import './globalStyles'

// set api urls
api.configure({
  url: window._env_.REACT_APP_API_URL,
  appType: window._env_.REACT_APP_APP_TYPE
})

// Set tracking cookies for marketing
setResidentCookie()

// wait for 401 check for expired token and redirect to login
networkUtils.handleRedirectToLogin(store)

ReactDOM.render(
  <Provider store={store}>
    <GraphQlProvider>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </PersistGate>
    </GraphQlProvider>
  </Provider>,
  (document.getElementById('root'): any)
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
