import { combineReducers } from 'redux'
import history from 'zego-shared/store/workOrders/history/reducers'
import workOrdersNew from 'zego-shared/store/workOrders/new/reducers'

const workOrders = combineReducers({
  history,
  new: workOrdersNew
})

export default workOrders
