// @flow
import styled from '@emotion/styled'
import { Dialog } from '@material-ui/core'
import Flex from '@Common/Flex'

export const InstructionsStyledList = styled.ul`
  padding: ${({ theme }) => theme.space.s20};
  padding-left: ${({ theme }) => theme.space.s35};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.secondary.light1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme }) =>
    theme.ux.isSSO ? '10px 0px 0px' : '39px 0px 115px'};
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin-top: 20px;
  }

  & li {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme }) => theme.custom.font.size.xxs};
    line-height: 10px;
    list-style: disc;
    margin-bottom: 10px;
    width: 100%;
    word-break: break-word;
  }
`

export const InstructionsStyled = styled(Flex)`
  justify-content: flex-end;
`

export const InstructionsTextButton = styled.button`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-size: 11px;
  text-decoration: underline;
`

export const StyledDialog = styled(Dialog)`
  & .MuiDialogTitle-root {
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  & .MuiDialogContent-root {
    margin: 0 100px 50px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    ${({ theme }) =>
      theme.ux.isSSO &&
      `
      & .MuiDialogContent-root {
        margin: 0 10px 50px;
      }
      `}
  }
`

export const DialogStyledList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  margin-bottom: 0;

  > li {
    list-style-type: disc;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
