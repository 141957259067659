import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import {
  SuccessTitle,
  SuccessMessageContainer,
  LCLogoSuccess,
  SuccessMessage,
  SuccessLogoContainer,
  Bold,
  AdLink,
  AdText,
  MainText,
  SubText,
  GoButton,
  AdContainer
} from './styles'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const LookbackSuccess = () => {
  const residentId = useSelector(getUserId)
  const creditReportingUrl = `/payments/${residentId}/credit-reporting`
  const query = useQuery()
  const newOptIn = query.get('newOptIn')

  return (
    <div>
      <SuccessLogoContainer justifySpaceBetween>
        <SuccessTitle>
          <span>Success!</span>
        </SuccessTitle>
        <LCLogoSuccess />
      </SuccessLogoContainer>
      <SuccessMessageContainer>
        {newOptIn && (
          <SuccessMessage>
            <Bold>You are reporting your payments with LevelCredit.</Bold>
            As you continue to make payments in Zego, LevelCredit will report
            these payments to Equifax and TransUnion.
          </SuccessMessage>
        )}
        <SuccessMessage>
          <Bold>You are enrolled in Lookback.</Bold>
          We are currently processing your historical payments for reporting to
          Equifax and TransUnion.
        </SuccessMessage>
        <AdContainer justifyCenter>
          <AdLink>
            <AdText>
              <MainText>Check Out </MainText>
              <SubText> Your Credit Reporting Page</SubText>
            </AdText>
            <GoButton to={creditReportingUrl}>Go Now!</GoButton>
          </AdLink>
        </AdContainer>
      </SuccessMessageContainer>
    </div>
  )
}

export default LookbackSuccess
