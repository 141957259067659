// @flow
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DownloadAppBanner from './DownloadAppBanner'
import { getMagicLink } from 'zego-shared/store/authorization/selectors'
import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'

const defaultMagicLink = 'https://pmz6t.app.link/kqG7Nx5z10'

const persistHiddenStatus = () =>
  localStorage.setItem('hideMobileDownloadBanner', '1')

type Props = {}
const DownloadAppBannerContainer = (props: Props) => {
  const [hidden, setHidden] = useState(false)
  const magicLink = useSelector(getMagicLink) || defaultMagicLink

  const theme = useTheme()
  const isSSO = theme.ux.isSSO
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    const status = Boolean(localStorage.getItem('hideMobileDownloadBanner'))
    setHidden(status)
  }, [])

  const dismiss = () => {
    setHidden(true)
    persistHiddenStatus()
  }

  return hidden || !isMobile || isSSO
    ? null
    : DownloadAppBanner({ ...props, magicLink, dismiss })
}

export default DownloadAppBannerContainer
