import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { Tooltip } from '@material-ui/core'

export const HelpIcon = styled(HelpOutlineIcon)`
  margin: 0 ${({ theme }) => theme.space.s5};
  cursor: default;
  color: ${({ theme }) => theme.palette.neutral.main};
  vertical-align: text-top;
  ${({ size }) => size && 'height:' + size};
`

export const StyledTooltip = withStyles(theme => ({
  tooltip: {
    position: 'relative',
    backgroundColor: theme.palette.neutral.light3,
    color: theme.palette.common.midnight,
    fontSize: theme.custom.font.size.sm,
    border: '0px',
    padding: `11px ${theme.space.s25} 11px ${theme.space.s30}`,
    left: '0.5em',
    top: '-0.8em',
    borderRadius: '8px',
    '&::before': {
      content: '""',
      backgroundColor: theme.palette.neutral.light3,
      position: 'absolute',
      transform: 'rotate(45deg)',
      width: '1em',
      height: '1em',
      top: '1em',
      left: '-0.3em',
      zIndex: '-1'
    }
  }
}))(Tooltip)
