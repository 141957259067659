export const FETCH_PREFIX = 'FETCH_'

export const FETCH_NEXT_PREFIX = 'FETCH_NEXT_'

export const REFRESH_PREFIX = 'REFRESH_'

export const UPDATE_PREFIX = 'UPDATE_'

export const SUCCESS = '_SUCCESS'

export const ERROR = '_ERROR'

export const START = '_START'

export const OPTIMISTICALLY = '_OPTIMISTICALLY'

export const REVERT = '_REVERT'
