// @flow

import get from 'lodash/get'
import { formatPhoneNumber } from 'zego-shared/utils/string'
import { getProfileUrlOrDefault } from 'zego-shared/utils/profilePicture'

export const getProfile = (state: Object) =>
  get(state, 'authorization.profile', {})

export const getUserPhoneNumber = (state: Object) => {
  const phoneNum = get(state, ['authorization', 'profile', 'phone_number'], '')
  return formatPhoneNumber(phoneNum)
}

export const getUserProfilePicture = (state: Object) => {
  const picture = get(state, ['authorization', 'profile', 'picture'], '')
  return getProfileUrlOrDefault(picture)
}

export const getToken = (state: Object) => get(state, 'authorization.token')

export const getRefreshToken = (state: Object) =>
  get(state, 'authorization.refreshToken')

export const getUnitId = (state: Object) =>
  get(state, 'authorization.profile.units[0]')

export const getPropertyId = (state: Object) =>
  get(state, 'authorization.profile.properties[0]')

export const getUserId = (state: Object) => get(state, 'authorization.id')

export const getSendbirdUserId = (state: Object) =>
  get(state, 'authorization.sendbirdUserID')

export const getSendbirdToken = (state: Object) =>
  get(state, 'authorization.sendbirdToken')

export const getSendbirdAppID = (state: Object) =>
  get(state, 'authorization.sendbirdAppID')

export const getSendbirdUserID = (state: Object) =>
  get(state, 'authorization.sendbirdUserID')

export const getIsAuth = (state: Object) => get(state, 'authorization.isAuth')

export const hasSendbirdToken = (state: Object) =>
  Boolean(get(state, 'authorization.sendbirdToken'))

export const getSendBirdInfo = (state: Object) => {
  const sendbirdToken = getSendbirdToken(state)
  const sendbirdAppID = getSendbirdAppID(state)
  const sendbirdUserID = getSendbirdUserId(state)

  return sendbirdToken && sendbirdAppID
    ? {
        sendbirdToken,
        sendbirdAppID,
        sendbirdUserID
      }
    : null
}

export const getUserEmail = (state: Object) =>
  get(state, ['authorization', 'profile', 'email'], '')

export const getUserLogin = (state: Object) =>
  get(state, ['authorization', 'profile', 'login'], '')

export const getUserUsername = (state: Object) =>
  get(state, ['authorization', 'profile', 'username'], '')

export const getUserName = (state: Object) =>
  get(state, ['authorization', 'profile', 'name'], '')

export const getUserFirstName = (state: Object) =>
  get(state, ['authorization', 'profile', 'first_name'], '')

export const getUserLastName = (state: Object) =>
  get(state, ['authorization', 'profile', 'last_name'], '')

export const getUserRole = (state: Object) =>
  get(state, ['authorization', 'profile', 'role'], '')

export const getIsValidSignupToken = (state: Object) =>
  get(state, ['authorization', 'isValidSignupToken'])

export const getSignupSuccessful = (state: Object) =>
  get(state, ['authorization', 'signupSuccessful'])

export const getUserBio = (state: Object) =>
  get(state, 'authorization.profile.user_metadata.bio', '')

export const getUserPreferredContactMethod = (state: Object) => {
  const methods = get(
    state,
    'authorization.profile.user_metadata.contact_methods',
    []
  ) || []
  return methods.includes('text') ? 'text' : 'email'
}

export const getIsPaymentOnlyAccount = (state: Object) => {
  return getUserRole(state) === 'designated_payer'
}

export const getIsPayLeasePmsUser = (state: Object) =>
  get(state, ['authorization', 'profile', 'pms_user', 'pms'], '') === 'paylease'

export const hasAutopay = (state: Object) =>
  get(state, ['authorization', 'profile', 'pms_user', 'data', 'hasAutoPay'], '') === true

export const getPaymentToken = (state: Object) =>
  get(state, ['authorization', 'paymentTokenInfo', 'accessToken'])

export const hasLoginFailed = (state: Object) =>
  get(state, ['authorization', 'loginFailed'])

export const getMagicLink = (state: Object) => {
  return get(state, ['authorization', 'magicLink'])
}

export const getIsSigningUp = (state: Object) => {
  return get(state, ['authorization', 'isSigningUp'])
}

export const getIsAdminLogin = (state: Object) =>
  get(state, ['authorization', 'isAdminLogin'])

export const isIntegratedUser = (state: Object) =>
  get(state, ['authorization', 'profile', 'pms_user', 'data', 'isIntegrated'], false)

export const getIsSsoPayments = (state: Object) =>
  get(state, ['authorization', 'profile', 'sso_payment'], false)

export const getLastTransactionDate = (state: Object) =>
  get(state, ['authorization', 'profile', 'pms_user', 'data', 'lastTransactionDate'])

export const getUnitUuId = (state: Object) =>
  get(state, ['authorization', 'profile', 'pms_user', 'data', 'unit_id'])