// @flow
import styled from '@emotion/styled'

export const TermsAndConditionsStyled = styled.div`
  width: 210px;
  min-height: fit-content;
  display: flex;
  align-items: flex-start;

  color: ${({ theme }) => theme.palette.common.midnight};
  & input[type='checkbox'] {
    height: 0px !important;
    font-size: 40px;
  }

  & label {
    margin: 0;

    & > span {
      padding: 0;
    }
  }

  & > span {
    margin-left: ${({ theme }) => theme.space.s10};
  }

  margin: ${({ theme }) => `${theme.space.s20} 0 ${theme.space.s60}`};
`

export const TermsAndConditionsCTAStyled = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.secondary.dark};
  cursor: pointer;
`
