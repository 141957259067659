import styled from '@emotion/styled'

const Link = styled('button')`
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  cursor; pointer;
`

export const MainWrapper = styled('div')(
  ({ theme }) => `
    margin-left: ${theme.space.s14};
    padding-right: ${theme.space.s14};
    position: relative;

    &:before {
        content: '';
        position: absolute;
        right: 0;
        width: 100%;
        height: calc(100% - 41px);
        z-index: -1;

        background: ${theme.palette.common.iceberg};
        border: 1px solid ${theme.palette.secondary.light};
        box-shadow: 0px 2px 4px rgba(14, 20, 39, 0.5);
        border-radius: 4px;
    }
  `
)

export const ModalContent = styled('div')`
  text-align: left;
`

export const ActionLink = styled(Link)(
  ({ theme }) => `
    padding: ${theme.space.s7} 0;
    margin-left: auto;
    width: fit-content;
    display: flex;
    align-items: center;

    & span {
        margin-right: ${theme.space.s7};
    }
  `
)
export const ActionButton = styled('button')(
  ({ theme }) => `
    padding: ${theme.space.s7} ${theme.space.s10}  ${theme.space.s7}  ${theme.space.s15};
    display: flex;
    align-items: center;
    background-color: ${theme.palette.common.iceberg};
    border: 1px solid ${theme.palette.secondary.light};
    border-radius: 4px;

    & span {
        margin-right: ${theme.space.s7};
    }
  `
)

export const RemoveNote = styled(Link)(
  ({ theme }) => `
    display: block;
    color: ${theme.palette.error.dark};
    text-decoration: underline;
    width: fit-content;
    margin-top: ${theme.space.s20};
    margin-left: auto;
    font-weight: ${theme.custom.font.weight.regular};
  `
)

export const Buttons = styled('div')(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.space.s48};

    & > button {
      width: 47.5%;
    }
  `
)

export const ContentWrapper = styled('div')`
  margin-left: -14px;
`
