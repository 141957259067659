import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import Button from '@Common/Button'
import Link from '@material-ui/core/Link'
import Warning from '@images/warning.png'
import { StyledCard, IconButtonStyled } from '../styles'
import {
  Triangle,
  StyledWarningContent,
  UnavailableCardContent
} from './styles'

const Unavailable = ({ onChoosePaymentMethod, onManuallyEnterDetails, onClickCancel, plaidOnly }) => {
  return (
    <StyledCard unwrapContent customPadding="0">
      <IconButtonStyled onClick={onManuallyEnterDetails}>
        <CloseIcon />
      </IconButtonStyled>
      <Triangle />
      <StyledWarningContent>
        <CardMedia
          component="img"
          alt="Warning"
          height="50"
          image={Warning}
          title="Warning"
        />
        <h1>Zego is currently unable to connect to Plaid.</h1>
      </StyledWarningContent>
      {plaidOnly ? (<UnavailableCardContent>
        <p>
          Please try again or choose a different payment method.
        </p>
      </UnavailableCardContent>) :
        (<UnavailableCardContent>
          <p>
            You can enter your bank details manually or choose a different payment method.
          </p>
        </UnavailableCardContent>)
      }
      <CardActions>
        <Button
          outline
          primary
          onClick={onChoosePaymentMethod}>
          Choose payment method
        </Button>
        {!plaidOnly && <Button
          primary
          onClick={onManuallyEnterDetails}>
          Manually enter details
        </Button>}
        <Link underline="always" color="textSecondary" onClick={onClickCancel}>
          exit & cancel setup
        </Link>
      </CardActions>
    </StyledCard>
  )
}

export default Unavailable
