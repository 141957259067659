import ssoPaymentSaga from './ssoPayment/sagas'
import balanceSaga from './balance/sagas'
import historySagas from './history/sagas'
import methodsSagas from './methods/sagas'
import optionsSagas from './options/sagas'
import oneTimePaymentSagas from './oneTimePayment/sagas'
import feesSagas from './fees/sagas'
import autopaysSagas from './autopay/sagas'
import statementsSagas from './statements/sagas'
import paymentsSummary from './summary/sagas'
import creditReporting from './creditReporting/sagas'
import plaidSagas from './plaid/sagas'
import linkedAccountsSagas from './linkedAccounts/sagas'
import clientNotes from './clientNotes/sagas'
import paymentReminders from './paymentReminders/sagas'

// eslint-disable-next-line import/prefer-default-export
export const paymentsSagas = [
  ...ssoPaymentSaga,
  ...balanceSaga,
  ...historySagas,
  ...optionsSagas,
  ...methodsSagas,
  ...oneTimePaymentSagas,
  ...feesSagas,
  ...statementsSagas,
  ...autopaysSagas,
  ...paymentsSummary,
  ...creditReporting,
  ...plaidSagas,
  ...linkedAccountsSagas,
  ...clientNotes,
  ...paymentReminders
]
