// @flow
import get from 'lodash/get'
import { formatNextAutoPayDate } from 'zego-shared/utils/date'
import { paymentStatuses } from '../constants'
import { createSelector } from 'reselect'
import { getHistory } from '../../history/selectors'
import { getUIPaymentsDisabled } from '../../../pmSettings/selectors'
import { getIsSsoPayments } from '../../../authorization/selectors'

const validateStatus = (status: string) => {
  return paymentStatuses[status]
    ? paymentStatuses[status]
    : paymentStatuses.UNLOCKED_ZERO
}

export const getPaymentState = (state: Object) => {
  const summaryStatus = get(
    state,
    ['payments', 'paymentsSummary', 'paymentState', 'state'],
    ''
  )
  const isSSO = getIsSsoPayments(state)
  const uiPaymentsDisabled = getUIPaymentsDisabled(state)

  return !isSSO
    ? uiPaymentsDisabled
      ? validateStatus(paymentStatuses.BLOCKED)
      : validateStatus(summaryStatus)
    : validateStatus(summaryStatus)
}

export const getNextAutoPayDate = (state: Object) => {
  const autopays = get(state, ['payments', 'autoPay', 'autoPays'], {})

  if (!autopays.variableAutopays && !autopays.fixedAutopays) {
    return ''
  }
  const allAutopays = [].concat(
    autopays.variableAutopays,
    autopays.fixedAutopays
  )

  const today = new Date()
  const allAutopaysSorted = allAutopays
    .filter(
      ({ endDate, nextDebitDate }) =>
        nextDebitDate != null &&
        (!endDate ||
          new Date(endDate) > today ||
          new Date(endDate) >= new Date(nextDebitDate))
    )
    .sort(sortByDebitDate)

  const nextAutopay = allAutopaysSorted[0] ?? null

  return nextAutopay ? formatNextAutoPayDate(nextAutopay.nextDebitDate) : ''
}

export const getNextAutoPayDateById = (state: Object, id: string) => {
  const autopays = get(state, ['payments', 'autoPay', 'autoPays'], {})

  if (!autopays.variableAutopays && !autopays.fixedAutopays) {
    return ''
  }
  const allAutopays = [].concat(
    autopays.variableAutopays,
    autopays.fixedAutopays
  )

  const nextAutopay = allAutopays.find(ap => ap.id === id)

  return nextAutopay ? formatNextAutoPayDate(nextAutopay.nextDebitDate) : ''
}

const sortByDebitDate = (
  { nextDebitDate: debitDateA },
  { nextDebitDate: debitDateB }
) => debitDateA - debitDateB

export const getLastPayment = createSelector(
  getHistory,
  (history: Object[]) => {
    return get(history, '[0]', {})
  }
)
