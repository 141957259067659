import { makeStyles } from '@material-ui/core/styles'

const acknowledgementBase = {
  paddingBottom: '0.25em',
  paddingTop: '0.25em'
}

export const useStyles = makeStyles(theme => ({
  acknowledgement: {
    ...acknowledgementBase,
    backgroundColor: theme.palette.neutral.light
  },
  multiPreAcknowledgement: {
    ...acknowledgementBase,
    backgroundColor: theme.palette.neutral.light,
    "border": "red",
    "border-style": "solid",
    "border-width": "thin",
    "border-radius": "5px"
  },
  multiAcknowledgement: {
    ...acknowledgementBase,
    backgroundColor: theme.palette.neutral.light,
    "margin-bottom": "10px"
  }
})
)
