import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Button from '@Common/Button'
import InstitutionAccountsCard from '../InstitutionAccountsCard'
import { IconButtonStyled, StyledCard, StyledCardContent } from '../styles'

const LinkedAccountsModal = ({
  institutionAccounts = [],
  onClickExit,
  onClickLinkNewBank,
  onSelectAccount
}) => {
  return (
    <StyledCard unwrapContent customPadding="3em 1.5em">
      <IconButtonStyled onClick={onClickExit}>
        <CloseIcon />
      </IconButtonStyled>
      <Typography className="linkedAccountsTitle" variant="h1">
        You have linked accounts
      </Typography>
      <StyledCardContent
        customPadding="0em"
        className="linkedAccountsContainer">
        {institutionAccounts.map(({ institutionName, accounts }) => (
          <InstitutionAccountsCard
            key={institutionName}
            institutionName={institutionName}
            accounts={accounts}
            onSelectAccount={onSelectAccount}
          />
        ))}
      </StyledCardContent>
      <Button primary onClick={onClickLinkNewBank}>
        Link New Bank
      </Button>
    </StyledCard>
  )
}

export default LinkedAccountsModal
