import gql from 'graphql-tag'

export const PLAID_BANK_ACCOUNT_FIELDS = gql`
  fragment PlaidBankAccountFields on PlaidBankAccount {
    id
    accountHolderName
    bankName
    type
    routingNumber
    lastFour
    currencyCode
    __typename
  }
`
