// @flow
import React from 'react'
import { getDate } from 'zego-shared/utils/date'
import withWidth from '@material-ui/core/withWidth'
import { withTheme } from 'emotion-theming'
import { Grid } from '@material-ui/core'
import {
  InfoLane,
  OtherDetails,
  PaymentInfo,
  StyledButton,
  ButtonsStyled,
  PaymentInfoContainerStyled,
  IconButtonContainerStyled
} from '../styles'
import {
  CardStyled,
  CardHeader
} from './styles'
import { EditIconButton, ListIconButton } from '../../common/IconButtons'
import ChargeBreakdown from '../../ChargeBreakdown'

import type { Theme } from '../../../../theme'

type Props = {
  totalOwed: Object,
  theme: Theme,
  width: string,
  totalOwedLastUpdated: Object,
  lineItemsDetails: Array<Object>,
  userId: string,
  onClickPayNow: boolean => void,
  hasEditableFields: boolean,
  disableMakePaymentMenuTab: boolean,
  onClickViewAutoPay: () => void,
  disableAutopayMenuTab: boolean
}

const getButtonsFlexDirection = (width: string) => {
  if (width === 'lg' || width === 'xl' || width === 'sm') return 'row'
  return 'column'
}

export const WithBalance = (props: Props) => {
  const [showChargeBreakdown, setShowChargeBreakdown] = React.useState(false)
  const detailsSectionRef = React.useRef(null)

  const {
    theme,
    totalOwed,
    totalOwedLastUpdated,
    lineItemsDetails,
    onClickPayNow,
    width,
    hasEditableFields,
    disableMakePaymentMenuTab,
    onClickViewAutoPay,
    disableAutopayMenuTab
  } = props

  const handleShowChargeBreakdown = () => setShowChargeBreakdown(!showChargeBreakdown)

  const handlePayNowClick = () => {
    onClickPayNow(false)
  }

  const goToEditBalance = () => {
    onClickPayNow(hasEditableFields)
  }

  return (
    <CardStyled
      width={width}
      CardContentProps={{
        classes: {
          root: 'card-content-root'
        }
      }}>
      <Grid
        container
        justify="space-between"
        direction="column"
        ref={detailsSectionRef}>
        <CardHeader
          marginTop={!width === 'md' && theme.space.s10}
          marginBottom=""
          fullWidth
          justifySpaceBetween>
          <h4>My Current balance</h4>
          {lineItemsDetails.length > 0 ? (
            <IconButtonContainerStyled>
              <ListIconButton
                id="breakdownIcon"
                onClick={handleShowChargeBreakdown}
                color={theme.palette.neutral.dark}
              />
              <ChargeBreakdown
                lineItemsDetails={lineItemsDetails}
                isOpen={showChargeBreakdown}
                handleClose={handleShowChargeBreakdown}
              />
            </IconButtonContainerStyled>
          ) : null}
        </CardHeader>
        <PaymentInfoContainerStyled>
          <InfoLane marginTop="20px" justifySpaceBetween direction="column">
            <PaymentInfo primary H1 lineHeight="50px">
              <span>{totalOwed && totalOwed.toFormat()}</span>
            </PaymentInfo>
            <OtherDetails marginTop="8px">
              as of {getDate(totalOwedLastUpdated)}
            </OtherDetails>
          </InfoLane>
          {hasEditableFields && !disableMakePaymentMenuTab ? (
            <IconButtonContainerStyled
              data-testid="editContainer"
              style={{ marginTop: '15px' }}>
              <EditIconButton id="editIcon" onClick={goToEditBalance} />
            </IconButtonContainerStyled>
          ) : null}
        </PaymentInfoContainerStyled>
      </Grid>
      <ButtonsStyled
        direction={getButtonsFlexDirection(width)}
        fullWidth
        justifyCenter>
        <Grid container justify="space-between" spacing={3}>
          {!disableAutopayMenuTab && (
            <Grid xs={!disableMakePaymentMenuTab ? 5 : 12} item>
              <StyledButton
                fullWidth
                onClick={onClickViewAutoPay}
                primary>
                {width === 'xs' ? 'Setup' : 'Setup Auto Pay'}
              </StyledButton>
            </Grid>
          )}
          {!disableMakePaymentMenuTab && (
            <Grid xs={!disableAutopayMenuTab ? 7 : 12} item>
              <StyledButton
                className="payBtn"
                id="payBtn"
                fullWidth
                primary
                onClick={handlePayNowClick}>
                Pay Now
              </StyledButton>
            </Grid>
          )}
        </Grid>
      </ButtonsStyled>
    </CardStyled>
  )
}

export default withWidth()(withTheme(WithBalance))
