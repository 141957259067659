// @flow
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GridItem } from '@Common/Grid'
import Flex from '@Common/Flex'
import StartReporting from '../Banners/StartReporting'
import Reactivate from '../Banners/Reactivate'
import OptedInSuccess from '../Banners/OptedInSuccess'
import OptedInReports from '../Banners/OptedInReports'
import Loader from '@Common/Loader'
import MonthTracker from './MonthTracker'
import { CreditReportingDetails } from '../types'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import { hasPurchasedLookback } from 'zego-shared/store/payments/creditReporting/selectors'
import IncludePaymentAd from '@views/Payments/CreditReporting/LookbackAds/CreditReportingPage'
import LookbackAd from '@views/Payments/CreditReporting/LookbackAds/SummaryPage'
import {
  creditReportingReactivateAction,
  creditReportingOptOut
} from 'zego-shared/store/payments/creditReporting/actions'
import { useTheme } from '@material-ui/core/styles'
import {
  StyledCard,
  LevelCreditLogo,
  CRPageTitle,
  LCNotice,
  IntroTitle,
  IntroText,
  IntroSection,
  FeaturesUnderlay,
  FeaturesInner,
  FeatureCheckMark,
  FeatureTitle,
  FeatureSubTitle,
  Feature,
  FeatureRight,
  LearnMore,
  ShowForMdUp,
  ShowForMdDown,
  LookbackAdTabMob,
  AdFeaturesWrap,
  FooterNotice,
  BodyTextWrap,
  BodyText,
  StyledLink,
  OptOutText,
  SingleColumnFlexWrap,
  FooterWrap,
  Bold,
  OptOutModalText,
  InactiveStatusBox,
  InactiveStatusTextBold,
  InactiveStatusText,
  InactiveStatusRedHighLight,
  InactiveStatusHighLight
} from './styles'

type Props = {
  width: string,
  isOptedIn: string,
  isActive: boolean,
  transactionsData: { monthsReported: number, lookbackMonthsReported: number },
  hasTransactions: boolean,
  details: CreditReportingDetails,
  isFetching: boolean,
  lookbackEnabled: boolean
}

const getTitle = (isOptedIn, hasTransactions) => {
  return isOptedIn ? (
    hasTransactions ? (
      <IntroTitle id="optdInPayProcessedTtl">Checkout your progress</IntroTitle>
    ) : (
      <IntroTitle id="optdInPayProcessingTtl">
        Check back to see your progress
      </IntroTitle>
    )
  ) : (
    <IntroTitle id="notOptedInTitle">
      Make your payments work for you!
    </IntroTitle>
  )
}

const getDesc = (isOptedIn, hasTransactions) => {
  return isOptedIn ? (
    hasTransactions ? (
      <IntroText id="optdInPayProcessedSubTxt">
        LevelCredit has reported your Zego payments. Each month reported is
        recorded in the chart below
      </IntroText>
    ) : (
      <IntroText id="optdInPayProcessingSubTxt">
        We'll report your next Zego payment to LevelCredit & Keep track of every
        reported payment below
      </IntroText>
    )
  ) : (
    <IntroText id="notOptedInSubTxt">
      With our <Bold>LevelCredit</Bold> integration, get the credit you deserve
      <sup>&reg; </sup>&nbsp; by reporting your payments made within
      <Bold> Zego</Bold>.
    </IntroText>
  )
}

const getBanner = (
  isOptedIn,
  isActive,
  count = 0,
  lbCount = 0,
  variant = 'tall'
) => {
  if (isOptedIn) {
    if (!isActive) {
      return <Reactivate />
    }

    if (count > 0 || lbCount > 0) {
      return (
        <OptedInReports
          reportedTransactionsCount={count}
          reportedLookbackTransactionsCount={lbCount}
          variant={variant}
        />
      )
    } else {
      return <OptedInSuccess variant={variant} />
    }
  } else {
    return <StartReporting />
  }
}

const ActiveStatusSection = () => (
  <BodyTextWrap>
    <Bold id="optedInTitle">
      You are reporting your payments with LevelCredit
    </Bold>
    <BodyText id="optedInSubTxt">
      This means that LevelCredit will report your payments to Equifax and
      TransUnion.
    </BodyText>
  </BodyTextWrap>
)

const InactiveStatusSection = () => (
  <InactiveStatusBox>
    <InactiveStatusTextBold>
      You are <InactiveStatusRedHighLight>NOT</InactiveStatusRedHighLight>{' '}
      reporting your payments with LevelCredit.
    </InactiveStatusTextBold>
    <InactiveStatusText>
      This means that LevelCredit{' '}
      <InactiveStatusHighLight>WILL NOT</InactiveStatusHighLight> report your
      payments to Equifax and TransUnion.
    </InactiveStatusText>
  </InactiveStatusBox>
)

const StatusSection = ({ isActive }) =>
  isActive ? <ActiveStatusSection /> : <InactiveStatusSection />

const OptOutLink = withAlertModal(({ showAlertModal }) => (
  <StyledLink onClick={showAlertModal}>Deactivate Credit Reporting</StyledLink>
))

const ReactivateLink = ({ onClick }) => (
  <StyledLink onClick={onClick}>Reactivate Credit Reporting</StyledLink>
)

const activeFooter = dispatch => (
  <FooterWrap direction="column">
    <OptOutLink
      alertYesButtonText={'Yes, deactivate'}
      alertNoButtonText={'No, go back'}
      onAlertClickYes={() => dispatch(creditReportingOptOut())}>
      <OptOutModalText>
        Deactivating credit reporting will stop all payment reporting to credit
        bureaus and remove any future benefits of the service. Do you want to
        proceed?
      </OptOutModalText>
    </OptOutLink>
    <OptOutText id="optOutTxt">
      *After 3 months of no payments you will automatically be opted out of
      credit reporting
    </OptOutText>
  </FooterWrap>
)

const inactiveFooter = (dispatch, redirect) => (
  <ReactivateLink
    onClick={() => {
      dispatch(creditReportingReactivateAction({ redirect }))
    }}
  />
)

const optedOutFooter = () => (
  <FooterNotice id="lcrFooterNotice">
    LevelCredit and "Get the Credit You Deserve" are registered trademarks of
    RenTrack, Inc.
  </FooterNotice>
)

const getFooterMsg = (isOptedIn, isActive, dispatch, redirect) => {
  if (isOptedIn) {
    if (isActive) {
      return activeFooter(dispatch)
    } else {
      return inactiveFooter(dispatch, redirect)
    }
  } else {
    return optedOutFooter()
  }
}

const getSSOViewWrapper = (isSso, isOptedIn, children) => {
  if (isSso && !isOptedIn) {
    return <StyledCard>{children}</StyledCard>
  }

  return children
}

export const LevelCredit = ({
  isOptedIn,
  isActive,
  transactionsData,
  isFetching,
  lookbackEnabled,
  details
}: Props) => {
  const dispatch = useDispatch()
  const hasLookback: boolean = useSelector(hasPurchasedLookback)
  const theme = useTheme()
  const redirect = useHistory().push

  if (isFetching) {
    return <Loader />
  }

  const transactionsCount = transactionsData.monthsReported
  const lookbackTransactionsCount = transactionsData.lookbackMonthsReported
  const hasReportedTransactions = transactionsCount > 0
  const hasLookbackTrans: boolean = lookbackTransactionsCount > 0
  const { contactInfo, basicInfo } = details

  const lookbackPurchasedButNoTransaction = hasLookback && !hasLookbackTrans
  const isSSO = theme.ux.isSSO

  return (
    <GridItem columnStart={1} columnSpan={12}>
      <Flex justifySpaceBetween alignCenter>
        <Flex direction="column">
          <CRPageTitle id="creditReportingHeader">Credit Reporting</CRPageTitle>
          <LCNotice id="lcrNotice">with LevelCredit</LCNotice>
        </Flex>
        <Flex>
          <LevelCreditLogo />
        </Flex>
      </Flex>
      {getSSOViewWrapper(
        isSSO,
        isOptedIn,
        <Flex justifySpaceBetween width={isSSO ? '100%' : 'initial'}>
          <SingleColumnFlexWrap
            isOptedIn={isOptedIn}
            lookbackEnabled={lookbackEnabled}
            direction="column">
            {isOptedIn && (
              <ShowForMdDown isOptedIn={isOptedIn} isActive={isActive} zIndex>
                {getBanner(
                  isOptedIn,
                  isActive,
                  transactionsCount,
                  lookbackTransactionsCount
                )}
              </ShowForMdDown>
            )}
            {isActive && (
              <IntroSection isOptedIn={isOptedIn} direction="column">
                {getTitle(isOptedIn, hasReportedTransactions)}
                {getDesc(isOptedIn, hasReportedTransactions)}
              </IntroSection>
            )}
            {isOptedIn ? (
              <>
                <MonthTracker
                  data={transactionsData}
                  fullName={basicInfo.first}
                  address={contactInfo.street}
                />
                {!isSSO && (
                  <StatusSection isActive={isActive} />
                )}
                {lookbackEnabled && isActive && (
                  <LookbackAdTabMob>
                    <LookbackAd />
                  </LookbackAdTabMob>
                )}
                {lookbackPurchasedButNoTransaction && (
                  <BodyTextWrap>
                    <Bold id="optedInTitle">You are enrolled in lookback</Bold>
                    <BodyText id="optedInSubTxt">
                      We are currently processing your eligible historical
                      payments to report to LevelCredit. LevelCredit will report
                      those transactions to Equifax and TransUnion.
                    </BodyText>
                  </BodyTextWrap>
                )}
              </>
            ) : (
              <AdFeaturesWrap direction="column" id="lcrCheckTxtContainer">
                <ShowForMdDown zIndex>
                  {getBanner(isOptedIn, isActive)}
                </ShowForMdDown>
                <FeaturesUnderlay />
                <FeaturesInner>
                  <Feature>
                    <FeatureCheckMark />
                    <FeatureRight
                      direction="column"
                      justifySpaceBetween
                      id="reportPayCheck">
                      <FeatureTitle>Report your rent payments</FeatureTitle>
                      <FeatureSubTitle>
                        to the credit bureaus via LevelCredit
                      </FeatureSubTitle>
                    </FeatureRight>
                  </Feature>
                  <Feature>
                    <FeatureCheckMark />
                    <FeatureRight
                      direction="column"
                      justifySpaceBetween
                      id="updatesCheck">
                      <FeatureTitle>We'll keep you updated</FeatureTitle>
                      <FeatureSubTitle>
                        with a log of all months reported payments
                      </FeatureSubTitle>
                    </FeatureRight>
                  </Feature>
                  {/* <Feature>
                    <FeatureCheckMark />
                    <FeatureRight direction="column" justifySpaceBetween id="watchCreditCheck">
                      <FeatureTitle>
                        Find out how to watch your credit
                      </FeatureTitle>
                      <FeatureSubTitle>
                        with LevelCredit and Zego!
                      </FeatureSubTitle>
                    </FeatureRight>
                  </Feature> */}
                  <LearnMore>
                    <a
                      href="https://www.gozego.com/levelcredit-reporting-faq/"
                      target="_blank"
                      rel="noopener noreferrer">
                      Learn More
                    </a>
                  </LearnMore>
                </FeaturesInner>
              </AdFeaturesWrap>
            )}
            {getFooterMsg(isOptedIn, isActive, dispatch, redirect)}
          </SingleColumnFlexWrap>
          <ShowForMdUp zIndex>
            {isOptedIn && lookbackEnabled ? (
              <Flex direction="column" fullWidth>
                <>
                  {isActive && <IncludePaymentAd />}
                  {getBanner(isOptedIn, isActive, transactionsCount, 0, 'short')}
                </>
              </Flex>
            ) : (
              getBanner(
                isOptedIn,
                isActive,
                transactionsCount,
                lookbackTransactionsCount
              )
            )}
          </ShowForMdUp>
        </Flex>
      )}
    </GridItem>
  )
}

export default LevelCredit
