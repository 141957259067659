import gql from 'graphql-tag'
import { BANK_ACCOUNT_FIELDS, PAYMENT_CARD_FIELDS } from './fragments'

export const createBankAccount = gql`
  mutation($residentId: ID!, $input: BankAccountInput!) {
    createBankAccount(id: $residentId, input: $input) {
      ...BankAccountFields
    }
  }
  ${BANK_ACCOUNT_FIELDS}
`

export const createPaymentCard = gql`
  mutation($input: PaymentCardInput!) {
    createPaymentCard(input: $input) {
      ...PaymentCardFields
    }
  }
  ${PAYMENT_CARD_FIELDS}
`

export const deletePaymentMethod = gql`
  mutation($post: DeletePaymentMethodInput!) {
    deletePaymentMethod(input: { post: $post }) {
      id
      message
    }
  }
`
