// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Grid } from '@material-ui/core'
import { TextInput } from '@Common/Form'
import Snackbar from '@Common/Snackbar'

import MainContainer from '../MainContainer'
import TermsAndConditions from '../TermsAndConditions'
import type { Theme } from '../../../../theme'
import { PasswordInputs, FormStyled } from './styles'
import { WelcomeText, SubText, StyledButton } from '../styles'

type Props = {
  signUp: (string, boolean, number) => void,
  showError: (password: string) => void,
  isMobileDevice: boolean,
  showTermsAndConditions: Function,
  termsAndConditions: { url: string, id: number },
  isSigningUp: boolean
}

type State = {
  password: string,
  repassword: string,
  hasAcceptedTAndC: boolean
}

const PasswordHelperText = () => (
  <React.Fragment>
    <div>Must be at least 8 characters long</div>
    <div>Must include at least 1 numeric character (0-9)</div>
    <div>Must include at least 1 alphabetic character (a-z)</div>
  </React.Fragment>
)

export class PasswordSetup extends React.Component<Props, State> {
  state = {
    password: '',
    repassword: '',
    hasAcceptedTAndC: false
  }

  handleSubmit = (event: SyntheticEvent<>) => {
    const { signUp, showError, isMobileDevice, termsAndConditions } = this.props
    const { password, repassword } = this.state

    event.preventDefault()

    if (!password || !repassword) {
      showError('Password cannot be empty')
      return
    }

    if (password !== repassword) {
      showError('Passwords do not match')
      return
    }

    signUp(password, isMobileDevice, termsAndConditions.id)
  }

  handleChange = (name: string) => (
    event: SyntheticEvent<HTMLInputElement>
  ) => {
    const { value } = event.currentTarget
    this.setState({ ...this.state, [name]: value })
  }

  handleAcceptedTandCChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget
    this.setState({ hasAcceptedTAndC: checked })
  }

  render() {
    const { state, handleChange, handleSubmit, props } = this
    const { termsAndConditions, isSigningUp } = props
    return (
      <MainContainer>
        <WelcomeText>Fantastic!</WelcomeText>
        <SubText>
          You’re almost there. All you need to do is set a password for this
          account.
        </SubText>

        <FormStyled onSubmit={handleSubmit}>
          <Grid
            container
            alignItems="center"
            justify="center"
            direction="column"
            spacing={6}>
            <PasswordInputs item>
              <TextInput
                placeholder="password"
                type="password"
                value={state.password}
                onChange={handleChange('password')}
                helperText={!props.isMobileDevice && <PasswordHelperText />}
              />
              <TextInput
                error={state.repassword && state.password !== state.repassword}
                placeholder="password confirm"
                type="password"
                value={state.repassword}
                onChange={handleChange('repassword')}
                helperText={!props.isMobileDevice && <PasswordHelperText />}
              />
            </PasswordInputs>
            <TermsAndConditions
              onAcceptedChange={this.handleAcceptedTandCChange}
              accepted={state.hasAcceptedTAndC}
              termsAndConditionsUrl={termsAndConditions.url}
            />
          </Grid>
          <Grid container justify="center">
            <StyledButton
              disableRipple
              primary
              long
              type="submit"
              animate={isSigningUp}
              disabled={!state.hasAcceptedTAndC || isSigningUp}>
              Login
            </StyledButton>
          </Grid>
        </FormStyled>
        <Snackbar />
      </MainContainer>
    )
  }
}

type MainProps = {
  ...Props,
  theme: Theme
}

export default withTheme(({ theme, ...props }: MainProps) => {
  return (
    <PasswordSetup
      {...props}
      isMobileDevice={useMediaQuery(theme.breakpoints.down('sm'))}
    />
  )
})
