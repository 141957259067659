// @flow
import React from 'react'
import { withRouter } from 'react-router'
import { useSelector } from 'react-redux'
import SSOAppBar from './SSOAppBar'
import SSOEmailCollectionAppBar from './SSOEmailCollectionAppBar'
import { getShowSSOEmailCollectionForm } from 'zego-shared/store/pmSettings/selectors'
import type { Props } from '../AppBar'

const SSOViewAppBar = (props: Props) => {
  const currentUrl = props.location.pathname.split('/')
  const ssoEmailCollectionEnabled = useSelector(getShowSSOEmailCollectionForm)
  const showSSOEmailBar =
    ['one-time', 'auto-pay'].includes(currentUrl[3]) &&
    currentUrl[4] !== 'success'

  return showSSOEmailBar && ssoEmailCollectionEnabled ? (
    <SSOEmailCollectionAppBar />
  ) : (
    <SSOAppBar {...props} />
  )
}

export default withRouter(SSOViewAppBar)
