import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import Card from '@Common/Card'
import Button from '@Common/Button'
import { Grid } from '@material-ui/core'
import yellowBG from '@images/rectangle_pay_summary.png'

export const StyledGrid = styled('div')`
  padding-bottom: ${({ theme }) => (theme.ux.isSSO ? 0 : theme.space.s100)};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    max-width: 454px;
  }
`

export const StyledCard = styled(Card)`
  position: relative;
  height: ${({ theme }) => (theme.ux.isSSO ? '315px' : '378px')};

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    height: 274px;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    height: ${({ noSm }) => !noSm && '189px;'};
  }
`

export const PageTitle = styled('div')`
  margin-bottom: ${({ theme }) => (theme.ux.isSSO ? '10px' : '34px')};
  line-height: 40px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H3 : theme.custom.font.size.H2};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H3};
  }

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    margin-bottom: 10px;
  }
`

export const PageDescription = styled('div')`
  width: 100%;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  margin-bottom: ${({ theme }) => theme.space.s25};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    line-height: 14px;
    margin-bottom: ${({ theme }) => theme.space.s20};
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    margin-bottom: ${theme.space.s20};
    font-size: ${theme.custom.font.size.sm};
    `};
`

export const StyledFlex = styled(Flex)`
  height: 420px;
`

export const MyInfoWrap = styled(Flex)`
  height: ${({ theme }) => (theme.ux.isSSO ? '315px' : '378px')};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: auto;
    margin-bottom: ${({ theme }) => theme.space.s50};
  }
`

export const PaymentStateYellowBg = styled('div')`
  background-image: ${`url(${yellowBG})`};
  width: 48px;
  height: 192px;
  position: absolute;
  margin-top: -10px;
  right: 0;
  z-index: 0;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    right: -12px;
  }

  ${({ theme }) => theme.ux.isSSO && 'display: none;'}
`

export const MyPaymentsInfoWrap = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 70px;
    flex-direction: row;
  }
`

export const ResidentNameWrap = styled(Flex)`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: 60%;
  }
`

export const ShowGridForMdDown = styled(Grid)`
  display: none;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    position: relative;
    display: block;
  }
`

export const ShowGridForMdUp = styled(Grid)`
  position: relative;
  display: block;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: none;
  }
`

export const MyInfo = styled(Flex)`
  flex-direction: column;
  height: ${({ levelCreditEnabled, theme }) =>
    levelCreditEnabled ? '201px' : theme.ux.isSSO ? '315px' : '378px'};
  opacity: 0.78;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.neutral.light};
  padding: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s20 : theme.space.s30};
  order: 0;

  ${({ theme }) => (theme.ux.isSSO ? 'justify-content: center' : '')};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    border-radius: 9px 9px 0 0;
    position: relative;
    top: 2px;
    height: 90px;
    width: 100%;
    padding: ${({ theme }) =>
      `${theme.space.s14} ${theme.space.s32} ${theme.space.s14} ${theme.space.s29}`};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    padding: ${({ theme }) => `${theme.space.s14} ${theme.space.s15}`};
  }
`

export const InfoLane = styled(Flex)`
  min-height: 18px;
  ${({ isResidentNameInfoLane, theme }) =>
    isResidentNameInfoLane && `margin-top: ${theme.space.s30}`};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    order: ${({ order }) => order};
    min-height: 14px;
    ${({ isResidentNameInfoLane, theme }) =>
      isResidentNameInfoLane && 'margin-top: 0'};
  }
`

export const HeaderTextWrap = styled(Flex)(
  ({ theme, levelCreditEnabled }) => `
    min-height: 18px;
    width: 100%;
    margin-top: ${levelCreditEnabled ? theme.space.s16 : theme.space.s37};

    ${theme.breakpoints.down('md')} {
      width: 40%;
      margin-top: ${theme.space.s39};
    }

    ${theme.ux.isSSO ? 'margin-top: 0' : ''}
  `
)

export const ChargeDetailsFor = styled('span')`
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: 14px;
  }
`

export const MyInfoLink = styled('span')`
  height: 12px;
  min-width: 39px;
  line-height: 14px;
  text-decoration: underline;
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.secondary.dark};
`

export const UnitAddress = styled('span')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  min-height: 24px;
  line-height: 1.1;
  text-transform: ${({ capitalize }) => capitalize && 'capitalize'};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    text-align: right;
    width: 100%;
    ${({ isFlipped, theme }) =>
      isFlipped && `line-height: ${theme.space.s10};`};
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`

export const ResidentName = styled('div')`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${({ theme, H5 }) =>
    H5 ? theme.custom.font.size.H5 : theme.custom.font.size.H6};
  color: ${({ theme, primary }) =>
    primary ? theme.palette.primary.main : theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  text-transform: capitalize;
  margin-top: ${({ marginTop }) => marginTop};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    text-align: right;
    width: 100%;
    margin-bottom: ${({ theme }) => theme.space.s9};
    height: 14px;
    line-height: 14px;
  }
`

export const CompanyName = styled(ResidentName)`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-bottom: 0;
  }
`

export const OtherDetails = styled('span')`
  height: 16px;
  font-size: ${({ theme, H5 }) =>
    H5 ? theme.custom.font.size.H5 : theme.custom.font.size.H6};
  color: ${({ theme, primary }) =>
    primary ? theme.palette.primary.main : theme.palette.neutral.deep};
  font-weight: ${({ theme, light }) => light && theme.custom.font.weight.light};
  text-transform: ${({ lowercase }) => lowercase && 'lowercase'};
  margin-top: ${({ marginTop }) => marginTop};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`

export const Text = styled('span')`
  position: relative;
`

export const PaymentInfo = styled('div')`
  color: ${({ theme, primary }) =>
    primary ? theme.palette.primary.main : theme.palette.common.midnight};
  font-size: ${({ theme, H1 }) =>
    theme.ux.isSSO || !H1
      ? theme.custom.font.size.H2
      : theme.custom.font.size.H1};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  position: relative;
  width: max-content;
  line-height: ${({ lineHeight }) => lineHeight};
  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    font-size: ${({ theme, isTotalOwed }) =>
      isTotalOwed && theme.custom.font.size.H4};
  }
  ${({ theme }) => [theme.breakpoints.only('md')]} {
    font-size: ${({ theme, H1 }) => (H1 ? theme.custom.font.size.H2 : '')};
  }
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: ${({ theme, H1 }) => (H1 ? theme.custom.font.size.H3 : '')};
  }
  & span {
    position: relative;
  }
  &&::before {
    height: 14px;
    left: ${({ isText }) => (isText ? '5px' : '20px')};
    right: 0;
    bottom: -7px;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    transform: translateX(10px);
    content: '';
 }
 ${({ theme }) => [theme.breakpoints.only('xs')]} {
   &&::before {
     bottom: 0px;
   }
 }
  ${({ theme }) => [theme.breakpoints.only('md')]} {
    &&::before {
      bottom: -2px;
   }
  }
 }
`

export const StyledButton = styled(Button)`
  height: 35px;
  width: ${({ width }) => width};

  ${({ theme }) => theme.ux.isSSO && `font-size: ${theme.custom.font.size.md};`}
`

export const ButtonsStyled = styled(InfoLane)`
  position: relative;
  &::before {
    position: absolute;
    content: '';
    left: -50px;
    right: -50px;
    bottom: -50px;
    top: -20px;
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    position: fixed;
    height: 151px;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`

export const PaymentSummaryMemoStyled = styled('p')`
  margin: 0;
`

export const PaymentInfoContainerStyled = styled(Flex)`
  justify-content: space-between;
`

export const IconButtonContainerStyled = styled('div')`
  margin: 0;
`

export const AdWrapMdDown = styled.div`
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s10 : theme.space.s20};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    margin-top: 0;
  }
`
