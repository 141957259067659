import { call, put, takeLatest, select } from 'redux-saga/effects'
import { print } from 'graphql'
import {
  fetchPaymentOptionsAction,
  fetchPaymentOptionsStart,
  fetchPaymentOptionsSuccess,
  fetchPaymentOptionsError
} from '../actions'
import { fetchPlaidItemsAction } from '../../plaid/actions'
import api from '../../../../utils/api'
import { getGraphQlData } from '../../../../utils/graphql'
import { getPaymentToken } from '../../../authorization/selectors'
import * as queries from '../graphql/queries'
import { getPaymentState } from 'zego-shared/store/payments/summary/selectors'
import { paymentStatuses } from 'zego-shared/store/payments/summary/constants'

export function* fetchPaymentOptions({ isOnetimePayment }) {
  try {
    const paymentState = yield select(getPaymentState)
    if (paymentState !== paymentStatuses.BLOCKED) {
      yield put(fetchPaymentOptionsStart())
      const paymentToken = yield select(getPaymentToken)
      const tokens = { paymentToken }
      const response = yield call(
        api.graphqlQuery,
        tokens,
        print(queries.paymentOptions),
        { oneTimePayment: isOnetimePayment }
      )
      const paymentOptions = yield call(
        getGraphQlData,
        response,
        'getPaymentOptions'
      )
      yield put(fetchPaymentOptionsSuccess(paymentOptions))
      yield put(fetchPlaidItemsAction())
    }
  } catch (error) {
    yield put(fetchPaymentOptionsError(error))
  }
}

function* watchFetchPaymentOptions() {
  yield takeLatest(fetchPaymentOptionsAction().type, fetchPaymentOptions)
}

export default [watchFetchPaymentOptions()]
