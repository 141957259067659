// @flow
import * as React from 'react'
import isEqual from 'lodash/isEqual'
import Dinero from 'dinero.js'
import { amountToNumber, currencyToNumber } from 'zego-shared/utils/number'

import CurrencyTextInput from '../CurrencyTextInput'
import {
  Balance,
  BalanceEditStyled,
  FormGroupStyled,
  FormStyled,
  FormWrapperStyled,
  IconButtonContainerStyled,
  MyBalanceStyled,
  PaymentAmountStyled,
  TotalWrapperStyled
} from './styles'
import OptionalFields from './OptionalFields'
import type { Field } from './types'
import { ListIconButton } from '../common/IconButtons'
import ChargeBreakdown from '../ChargeBreakdown'

type Props = {
  onChange?: (event: Object) => mixed,
  value: {
    fields: Field[],
    optionalFields: Field[],
    total: string,
    fullBalance: string
  },
  fetchPaymentOptionFees: (Object[]) => mixed,
  lineItemsDetails: Array<Object>
}

class BalanceEdit extends React.PureComponent<Props> {
  state = {
    fields: this.props.value.fields,
    total: this.props.value.total,
    showChargeBreakdown: false
  }

  componentWillUnmount() {
    const { value } = this.props
    const { fields } = this.state
    const { fetchPaymentOptionFees } = this.props
    if (!isEqual(value.fields, fields)) {
      fetchPaymentOptionFees(value.fields)
    }
  }

  isOptionalField(name: string) {
    const { value: { optionalFields } } = this.props
    const optionalFieldKeys = optionalFields.map(({ key }) => key)
    return optionalFieldKeys.includes(name)
  }

  getSelected() {
    const { value: { fields, optionalFields } } = this.props
    const optionalFieldKeys = optionalFields.map(({ key }) => key)
    return fields.filter(({ key }) => optionalFieldKeys.includes(key))
  }

  handleChange = (event: SyntheticInputEvent<>) => {
    const { value, name } = event.target
    const { value: { fields } } = this.props
    const amount = amountToNumber(value)

    if (Number.isNaN(amount) || this.isOptionalField(name)) {
      return
    }

    const fieldIndex = fields.findIndex(({ key }) => key === name)
    if (-1 === fieldIndex) {
      return
    }
    const oldField = fields[fieldIndex]
    const newAmount = Dinero({ amount, currency: oldField.currency })
    const newField = { ...oldField, amount: newAmount.toFormat('0.00') }
    const newFields = [...fields.slice(0, fieldIndex), newField, ...fields.slice(fieldIndex + 1)]
    this._replaceFields(newFields)
  }

  handleEnterPress = (event: SyntheticKeyboardEvent<>) => {
    const expectedKeyPress = 'Enter'
    const { value: { total } } = this.props
  
    if (expectedKeyPress === event.key) {
      if (currencyToNumber(total) >= 1) {
        this.props.onContinueClicked()
      } else {
        event.preventDefault()
      }
    }
  }

  handleOptionalFieldChange = (field: Field) => {
    const { value: { fields } } = this.props
    const fieldIndex = fields.findIndex(({ key }) => key === field.key)
    let newFields = [...fields]
    if (-1 === fieldIndex) {
      newFields.push(field)
    } else {
      newFields[fieldIndex] = field
    }
    this._replaceFields(newFields)
  }

  handleOptionalFieldRemoved = (field: Field) => {
    const { value: { fields } } = this.props
    const newFields = fields.filter(({ key }) => key !== field.key)
    this._replaceFields(newFields)
  }

  _replaceFields = (newFields: Field[]) => {
    const total = newFields
      .reduce((total, { amount, currency }) => total.add(Dinero({
        amount: amountToNumber(amount),
        currency
      })), Dinero())

    this.props.onChange({
      ...this.props.value,
      fields: newFields,
      total: total.toFormat()
    })
  }

  toggleShowChargeBreakdown = (showChargeBreakdown: boolean) => () => {
    this.setState({ showChargeBreakdown })
  }

  render() {
    const { 
      value: { fields, optionalFields, total, fullBalance }, 
      isIntegratedUser,
      lineItemsDetails
    } = this.props
    const isZeroBalance = currencyToNumber(fullBalance) === 0
    const showBalance = (isIntegratedUser || !isZeroBalance)
    const showPaymentAmount = (total !== fullBalance || (!isIntegratedUser &&  isZeroBalance ))
    const dropdownLabel = !fields.length ? 'Select a Payment Type' : 'Add Another Payment Type'

    return (
      <BalanceEditStyled
        CardContentProps={{
          classes: {
            root: 'card-content-root'
          }
        }}>
        <FormWrapperStyled>
          <FormStyled>
            {fields.map(({ label, amount, symbol, key, locked }) => {
              if (this.isOptionalField(key)) {
                return null
              }
              return (
                <FormGroupStyled label={label} key={key}>
                  <CurrencyTextInput
                    symbol={symbol}
                    onChange={this.handleChange}
                    onKeyPress={this.handleEnterPress}
                    name={key}
                    value={amount}
                    inputProps={{ readOnly: locked }}
                  />
                </FormGroupStyled>
              )
            })}
            <OptionalFields
              fields={optionalFields}
              dropdownLabel={dropdownLabel}
              initiallySelected={this.getSelected()}
              onFieldChange={this.handleOptionalFieldChange}
              onFieldRemoved={this.handleOptionalFieldRemoved}
            />
          </FormStyled>
        </FormWrapperStyled>
        <TotalWrapperStyled>
          { !isZeroBalance &&  ( lineItemsDetails.length > 0 ) ? (
              <IconButtonContainerStyled justifyRight>
                <ListIconButton id="breakdownIcon" onClick={this.toggleShowChargeBreakdown(true)} />
                <ChargeBreakdown
                  lineItemsDetails={lineItemsDetails}
                  isOpen={this.state.showChargeBreakdown}
                  handleClose={this.toggleShowChargeBreakdown(false)}
                />
              </IconButtonContainerStyled>) : null
            }
          {showBalance && (
          <MyBalanceStyled showPaymentAmount={showPaymentAmount}>
            <span>my full balance </span>
            <Balance><span>{fullBalance}</span></Balance>
          </MyBalanceStyled>
          )}
          {showPaymentAmount && (
            <PaymentAmountStyled>
              <span>Payment Amount</span>
              <Balance><span>{total}</span></Balance>
            </PaymentAmountStyled>
          )}
        </TotalWrapperStyled>
      </BalanceEditStyled>
    )
  }
}

export default BalanceEdit
