// @flow
import { connect } from 'react-redux'
import { withFetchData } from 'zego-shared/views'
import Summary from './Summary'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { fetchPaymentBalanceAction } from 'zego-shared/store/payments/balance/actions'
import {
  fetchPaymentsSummaryAction,
} from 'zego-shared/store/payments/summary/actions'
import {
  getUserName,
  getUserId
} from 'zego-shared/store/authorization/selectors'
import {
  getUnitId,
  getUnitName,
  getUnitProperty,
  getUnitAddress,
  getUnitCompany
} from 'zego-shared/store/select/selectors'
import {
  getPaymentState,
  getNextAutoPayDate,
  getLastPayment
} from 'zego-shared/store/payments/summary/selectors'
import {
  getTotalOwed,
  getBalanceDetails,
  getLineItemsDetails,
  getTotalOwedLastUpdated,
  getHasEditableFields
} from 'zego-shared/store/payments/balance/selectors'
import { fetchPaymentHistory } from 'zego-shared/store/payments/history/actions'
import {
  isCreditReportingEnabled,
  isLevelCreditReportingEnabled,
  getIsOptIn,
  getLevelCreditActive,
  getLevelCreditInactive,
  isLookbackEnabled
} from 'zego-shared/store/payments/creditReporting/selectors'
import { getPaymentSummaryMemo } from 'zego-shared/store/company/selectors'
import { getDisableMakePaymentMenuTab } from 'zego-shared/store/pmSettings/selectors'
import { getAutoPays } from 'zego-shared/store/payments/autopay/selectors'
import { getDisableAutopayMenuTab } from 'zego-shared/store/pmSettings/selectors'

const SummaryWithFetchData = withFetchData(Summary)
const fetchingSelector = createIsFetchingSelector(['payments'])

export const getUserInfo = (state: Object) => ({
  residentName: getUserName(state),
  unitName: getUnitName(state),
  propertyName: getUnitProperty(state),
  address: getUnitAddress(state) || '',
  companyName: getUnitCompany(state)
})

export const mapStateToProps = (state: Object) => ({
  isFetching: fetchingSelector(state),
  unitId: getUnitId(state),
  myInfo: getUserInfo(state),
  totalOwed: getTotalOwed(state),
  totalOwedLastUpdated: getTotalOwedLastUpdated(state),
  balanceDetails: getBalanceDetails(state),
  lineItemsDetails: getLineItemsDetails(state),
  userId: getUserId(state),
  paymentState: getPaymentState(state),
  nextAutoPay: getNextAutoPayDate(state),
  lastPayment: getLastPayment(state),
  creditReportingEnabled: isCreditReportingEnabled(state),
  levelCreditReportingEnabled: isLevelCreditReportingEnabled(state),
  isOptedIn: getIsOptIn(state),
  levelCreditActive: getLevelCreditActive(state),
  levelCreditInactive: getLevelCreditInactive(state),
  paymentSummaryMemo: getPaymentSummaryMemo(state),
  hasEditableFields: getHasEditableFields(state),
  lookbackEnabled: isLookbackEnabled(state),
  disableMakePaymentMenuTab: getDisableMakePaymentMenuTab(state),
  autopays: getAutoPays(state),
  disableAutopayMenuTab: getDisableAutopayMenuTab(state)
})

export const mapDispatchToProps = (dispatch: Function) => ({
  fetchData: () => {
    dispatch(fetchPaymentBalanceAction())
    dispatch(fetchPaymentsSummaryAction())
    dispatch(fetchPaymentHistory())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryWithFetchData)
