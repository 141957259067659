// @flow
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import Button from '@Common/Button'
import Link from '@Common/Link'

export const NameText = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${({ theme }) => theme.space.s16};
`

export const InfoText = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.secondary.dark};
  text-decoration: underline;
  white-space: nowrap;
  padding-top: ${({ theme }) => theme.space.s30};
  padding-bottom: ${({ theme }) => theme.space.s30};

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding-top: ${({ theme }) => theme.space.s8};
  }
`

export const StyledButton = styled(Button)`
  margin: 0;
`
