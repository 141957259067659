// @flow
import React from 'react'
import Button from '@Common/Button'
import { TextInput } from '@Common/Form'
import { Grid, InputAdornment } from '@material-ui/core'
import Event from '@material-ui/icons/Event'
import Flex from '@Common/Flex'
import DayPicker from '../../../Payments/AutoPay/AutoPaySchedule/DayPicker'
import {
  ScheduleInputStyled,
  ActionBtnsStyled,
  CalenderWrapperStyled,
  PopoverStyled
} from './styles'

type Props = {
  selectedDay: number,
  DayPickerProps?: Object,
  value: string,
  handleChange: Function,
  label?: string
}

const ActionBtns = props => {
  const { hasLeftSection, onCancel, onDone } = props
  return (
    <ActionBtnsStyled
      justifySpaceBetween={hasLeftSection}
      justifyRight={!hasLeftSection}
      hasLeftSection={hasLeftSection}>
      <Button
        id="calendarCancel"
        onClick={onCancel}
        variant="text"
        disableRipple>
        Close
      </Button>
      <Button id="calendarDone" onClick={onDone} variant="text" disableRipple>
        Done
      </Button>
    </ActionBtnsStyled>
  )
}

const ScheduleInput = ({
  selectedDay,
  DayPickerProps = {},
  value,
  handleChange,
  label
}: Props) => {
  const [initDay] = React.useState(selectedDay)

  const initialState = {
    selectedDay: initDay,
    anchorEl: null
  }

  const [state, setState] = React.useState(initialState)
  // $FlowDisableNextLine
  const handleSetState = (key, value) => setState({ ...state, [key]: value })
  const handleClick = event => handleSetState('anchorEl', event.currentTarget)

  const cancelSelection = () => {
    setState(initialState)
    handleChange(initDay)
  }

  const done = () => {
    if (state.selectedDay) {
      handleSetState('anchorEl', null)
    } else {
      cancelSelection()
    }
  }

  const handleDayClick = (day, { selected }) => {
    handleSetState('selectedDay', day)
    handleChange(day)
  }

  React.useEffect(() => {
    handleSetState('selectedDay', selectedDay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay])

  const open = Boolean(state.anchorEl)
  const id = open ? 'schedule-calendar' : null

  return (
    <ScheduleInputStyled>
      <TextInput
        onClick={handleClick}
        value={value}
        label={label}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Event />
            </InputAdornment>
          )
        }}
      />
      <PopoverStyled
        id={id}
        open={open}
        anchorEl={state.anchorEl}
        onClose={cancelSelection}
        elevation={2}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Flex>
          <Grid container component={CalenderWrapperStyled}>
            <Grid item xs={12} sm={12}>
              <Flex direction="column" style={{ flex: 1 }}>
                <DayPicker
                  selectedDay={state.selectedDay}
                  onChange={handleDayClick}
                  allowedDays={[1, 31]}
                  disableAll={false}
                  {...DayPickerProps}
                />
                <ActionBtns onCancel={cancelSelection} onDone={done} />
              </Flex>
            </Grid>
          </Grid>
        </Flex>
      </PopoverStyled>
    </ScheduleInputStyled>
  )
}

export default ScheduleInput
