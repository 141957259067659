// @flow
import React from 'react'
import Modal from '@material-ui/core/Modal'
import Clear from '@material-ui/icons/Clear'
import Flex from '@Common/Flex'
import ListSimple from '@Common/ListSimple'
import {
  ChargeBreakdownCard,
  CloseIconButton,
  ModalBackDropStyled,
  ModalContentStyled,
  StyledButton,
  TitleTextStyled
} from './styles'

type Props = {
  lineItemsDetails: Array<Object>,
  isOpen: Boolean,
  handleClose: Function
}

const ChargeBreakdown = (props: Props) => {
  const {
    lineItemsDetails,
    isOpen,
    handleClose
  } = props

  return (
    <Modal
      open={isOpen}
      BackdropComponent={ModalBackDropStyled}
      onBackdropClick={handleClose}>
      <ModalContentStyled justifyCenter alignCenter>
        <ChargeBreakdownCard>
          <CloseIconButton onClick={handleClose}>
            <Clear />
          </CloseIconButton>
          <Flex direction="column">
            <TitleTextStyled>
              My Current Charge Breakdown
            </TitleTextStyled>
            <ListSimple items={lineItemsDetails} divider></ListSimple>
            <StyledButton fullWidth primary onClick={handleClose}>
              Got It!
            </StyledButton>
          </Flex>
        </ChargeBreakdownCard>
      </ModalContentStyled>
    </Modal>
  )

}

export default ChargeBreakdown
