// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import Button from '@Common/Button'
import { TextInput } from '@Common/Form'
import Flex from '@Common/Flex'
import { FormStyled, Title, ButtonsWrapper } from './styles'
import type { Theme } from '../../../theme'
import type { changeModeFn, handleChangeFn } from './types'

type Props = {
  email: string,
  changeMode: changeModeFn,
  handleChange: handleChangeFn,
  handleResetRequest: () => mixed,
  error: string,
  working: boolean,
  theme: Theme
}

const PasswordRecoveryRequest = ({
  email,
  changeMode,
  handleChange,
  handleResetRequest,
  error,
  working,
  theme
}: Props) => (
  <FormStyled onSubmit={handleResetRequest}>
    <Flex marginTop="-25px" marginBottom="10px" direction="column">
      <Title>Password Recovery</Title>
      Please enter your email address. We will send you an email to reset your
      password.
    </Flex>
    <TextInput
      fullWidth
      placeholder="email"
      value={email}
      onChange={handleChange}
      id="email"
    />
    <ButtonsWrapper marginTop="60px">
      <Button
        id="backToLoginBtn"
        disableRipple
        link
        color={theme.palette.secondary.main}
        onClick={() => {
          changeMode('login')
        }}>
        back to login
      </Button>

      <Button
        id="passwordResetBtn"
        disableRipple
        classes={{ label: 'login-label' }}
        animate={working && !error ? 'animate' : ''}
        fullWidth
        primary
        type="submit">
        Send
      </Button>
    </ButtonsWrapper>
  </FormStyled>
)

export default withTheme(PasswordRecoveryRequest)
