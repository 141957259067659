import {
  createFetchActions,
  createUpdateActions
} from '../../../helpers/actions/creators'

export const CREDIT_REPORTING = 'CREDITREPORTING'
export const CREDIT_REPORTING_OPT_IN = 'CREDITREPORTINGOPTIN'
export const CREDIT_REPORTING_REACTIVATE = 'CREDITREPORTINGREACTIVATE'
export const CREDIT_REPORTING_OPT_OUT = 'CREDITREPORTINGOPTOUT'
export const CLEAR_DETAILS = 'CLEAR_DETAILS'
export const LEVEL_CREDIT = 'LEVELCREDIT'
export const LOOK_BACK = 'LOOK_BACK'
export const LOOKBACK_PURCHASE = 'LOOKBACKPURCHASE'

export const {
  updateAction: lookbackPurchase,
  updateStartAction: lookbackPurchaseStart,
  updateSuccessAction: lookbackPurchaseSuccess,
  updateErrorAction: lookbackPurchaseError
} = createUpdateActions(LOOKBACK_PURCHASE)

export const {
  fetchAction: fetchCreditReportingConfig,
  fetchStartAction: fetchCreditReportingConfigStart,
  fetchSuccessAction: fetchCreditReportingConfigSuccess,
  fetchErrorAction: fetchCreditReportingConfigError
} = createFetchActions(CREDIT_REPORTING)

export const {
  updateAction: creditReportingOptInAction,
  updateStartAction: creditReportingOptInStart,
  updateSuccessAction: creditReportingOptInSuccess,
  updateErrorAction: creditReportingOptInError
} = createUpdateActions(CREDIT_REPORTING_OPT_IN)

export const {
  updateAction: creditReportingReactivateAction,
  updateStartAction: creditReportingReactivateStart,
  updateSuccessAction: creditReportingReactivateSuccess,
  updateErrorAction: creditReportingReactivateError
} = createUpdateActions(CREDIT_REPORTING_REACTIVATE)

export const {
  updateAction: creditReportingOptOutAction,
  updateStartAction: creditReportingOptOutStart,
  updateSuccessAction: creditReportingOptOutSuccess,
  updateErrorAction: creditReportingOptOutError
} = createUpdateActions(CREDIT_REPORTING_OPT_OUT)

export const {
  fetchAction: getReportedLevelCreditTrans,
  fetchStartAction: getReportedLevelCreditTransStart,
  fetchSuccessAction: getReportedLevelCreditTransSuccess,
  fetchErrorAction: getReportedLevelCreditTransError
} = createFetchActions(LEVEL_CREDIT)

export const {
  fetchAction: getReportedLookbackTrans,
  fetchStartAction: getReportedLookbackTransStart,
  fetchSuccessAction: getReportedLookbackTransSuccess,
  fetchErrorAction: getReportedLookbackTransError
} = createFetchActions(LOOK_BACK)

export const creditReportingOptIn = (input, onSuccess) =>
  creditReportingOptInAction({ input, onSuccess })

export const creditReportingOptOut = (redirect) =>
  creditReportingOptOutAction({ redirect })

export const clearDetails = () => ({
  type: CLEAR_DETAILS
})

export const purchaseLookbackAction = (input, onSuccess) =>
  lookbackPurchase({ input, onSuccess })
