// @flow
import React, { useState } from 'react'
import {
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { getLatestTAndC } from 'zego-shared/store/termsAndConditions/selectors'
import { DisclaimerText, DisclaimerTextFull } from './DisclaimerText'
import { CardDisclaimerStyled, StyledDialog } from './styles'
import { useSelector } from 'react-redux'

const DisclaimerDialog = ({ isOpen, onClose, termsLink }) => {
  return (
    <StyledDialog fullScreen onClose={onClose} aria-labelledby="Disclaimer Dialog" open={isOpen}>
      <DialogTitle disableTypography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DisclaimerTextFull termsLink={termsLink}/>
      </DialogContent>
    </StyledDialog>
  )
}

const SSOCardDisclaimer = () => {
  const [isOpen, setIsOpen] = useState(false)
  const termsLink = useSelector(getLatestTAndC).url
  const handleClose = () => setIsOpen(false)

  return (
    <CardDisclaimerStyled alignCenter>
      <DisclaimerText onClick={() => setIsOpen(true)} />
      <DisclaimerDialog
        onClose={handleClose}
        isOpen={isOpen}
        termsLink={termsLink}
      />
    </CardDisclaimerStyled>
  )
}

export default SSOCardDisclaimer
