// @flow
import React from 'react'
import Button from '@Common/Button'
import { Grid } from '@material-ui/core'
import { PhoneNumber, MaskInput } from '@Common/Form/Masks'
import {
  ImageArea,
  ContentArea,
  AppStoreBtns,
  AppStoreBtn,
  TextMe,
  FormGroupStyled
} from './styles'
import appleStore from '@images/apple_store.png'
import googlePlay from '@images/goggle_play.png'
import { validatePhoneNumber } from '@utils/validationUtils'
import Snackbar from '@Common/Snackbar'

type Props = {
  sendDownloadLink: string => void,
  showError: string => void
}

export const getHandleChange = (setState: string => void) => (
  event: SyntheticInputEvent<>
) => {
  const { value } = event.target
  setState(value)
}

const DownloadApp = (props: Props) => {
  const [phone, setPhone] = React.useState('')
  const { sendDownloadLink, showError } = props

  const handleSubmit = event => {
    event.preventDefault()
    if (!validatePhoneNumber(phone)) {
      return showError('Phone number is invalid')
    }

    sendDownloadLink(phone)
  }

  const handleChange = getHandleChange(setPhone)

  return (
    <Grid container>
      <ImageArea item xs />
      <ContentArea item xs={12} sm={6} lg={8} xl={6}>
        <form onSubmit={handleSubmit}>
          <h1>Get the Mobile App</h1>
          <p>
            Got a mobile phone? Use the Zego mobile app to make your rent
            payments or submit work orders. Just input your phone number below
            and we’ll text you the link to download the app and login with the
            same account to get started!
          </p>
          <AppStoreBtns>
            <AppStoreBtn href="https://apps.apple.com/us/app/zego-resident-paylease/id902382558?ls=1">
              <img src={appleStore} alt="Apple AppStore" />
            </AppStoreBtn>
            <AppStoreBtn href="https://play.google.com/store/apps/details?id=com.paylease.resident&hl=en_US">
              <img src={googlePlay} alt="Google PlayStore" />
            </AppStoreBtn>
          </AppStoreBtns>
          <TextMe alignCenter>
            <FormGroupStyled label="Phone Number">
              <MaskInput
                InputProps={{ inputComponent: PhoneNumber }}
                value={phone}
                onChange={handleChange}
              />
            </FormGroupStyled>
            <Button primary type="submit">
              Text me the link
            </Button>
          </TextMe>
          <Snackbar />
        </form>
      </ContentArea>
    </Grid>
  )
}

export default DownloadApp
