// @flow
import React, { useState } from 'react'
import { FormGroup, Select, TextInput } from '@Common/Form'
import ToolTip from '@Common/ToolTip'
import { getErrorInfo, getHasError } from '@utils/view'
import { BankFormStyled, ToolTipWrap } from './styles'

const accountTypes = [
  { label: 'Checking', value: 'CHECKING' },
  { label: 'Savings', value: 'SAVINGS' }
]

type Props = {
  handleChange: Function,
  handleBlur: Function,
  handleFocus: Function,
  errors: Object,
  touched: Object
}

const BankAccountForm = ({
  handleChange,
  handleBlur,
  handleFocus,
  errors = {},
  touched = {}
}: Props) => {
  const hasError: Function = getHasError(errors, touched)
  const errorDetails: Function = getErrorInfo(errors, touched)
  const [actTypeActive, setActTypeActive] = useState(false)

  const handleActTypeFocus = () => {
    setActTypeActive(true)
  }

  const handleActTypeBlur = () => {
    setActTypeActive(false)
  }

  return (
    <BankFormStyled>
      <FormGroup label="Name on Account" {...errorDetails('accountHolderName')}>
        <TextInput
          onChange={handleChange}
          name="accountHolderName"
          error={hasError('accountHolderName')}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </FormGroup>
      <FormGroup label="Bank Name" {...errorDetails('bankName')}>
        <TextInput
          onChange={handleChange}
          name="bankName"
          error={hasError('bankName')}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </FormGroup>
      <FormGroup label="Account Type" {...errorDetails('type')}>
        {actTypeActive ? (
          <ToolTipWrap>
            <ToolTip message="Caution: Please ensure that the account selected allows electronic transactions. Non-transaction accounts may result in payment errors and late fees." />
          </ToolTipWrap>
        ) : null}
        <Select
          options={accountTypes}
          placeholder="select"
          onChange={handleChange}
          name="type"
          error={hasError('type')}
          onBlur={handleActTypeBlur}
          onFocus={handleActTypeFocus}
        />
      </FormGroup>
      <FormGroup label="Routing Number" {...errorDetails('routingNumber')}>
        <TextInput
          onChange={handleChange}
          name="routingNumber"
          error={hasError('routingNumber')}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </FormGroup>
      <FormGroup label="Account Number" {...errorDetails('accountNumber')}>
        <TextInput
          onChange={handleChange}
          name="accountNumber"
          error={hasError('accountNumber')}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </FormGroup>
      <FormGroup
        label="Confirm Account Number"
        {...errorDetails('confirmAccountNumber')}>
        <TextInput
          onChange={handleChange}
          name="confirmAccountNumber"
          error={hasError('confirmAccountNumber')}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </FormGroup>
    </BankFormStyled>
  )
}

export default BankAccountForm
