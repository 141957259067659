// @flow

import type { PaymentMethod } from './types'

export const constants = {
  DEFAULT: 'default',
  EASY_SETUP: 'easySetup',
  SPEED: 'speed',
  LOW_FEES: 'lowFees'
}

export const sortOrder = {
  [constants.DEFAULT]: ['DebitCard', 'CreditCard', 'BankAccount', 'PayPal'],
  [constants.EASY_SETUP]: ['PayPal', 'DebitCard', 'CreditCard', 'BankAccount'],
  [constants.SPEED]: ['DebitCard', 'CreditCard', 'PayPal', 'BankAccount'],
  [constants.LOW_FEES]: ['BankAccount', 'DebitCard', 'CreditCard', 'PayPal']
}

export const sortMethods = (
  methods: PaymentMethod[],
  criteria: string = constants.DEFAULT
): PaymentMethod[] =>
  methods.sort(
    (m1, m2) =>
      sortOrder[criteria].indexOf(m1.typeName) -
      sortOrder[criteria].indexOf(m2.typeName)
  )
