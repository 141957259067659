import {
  saveHideAutopayType,
  savePaymentBalanceOverride,
  setCancelOptionBehaviorOverride,
  setPaymentSuccessRedirect,
  saveIpnUrl,
  saveIpnCustomValue,
  saveSecondaryResidentId
} from '../actions'

const initialState = {
  payments: {
    autoPay: {
      hide: ''
    },
    balance: {
      paymentFields: []
    },
    ipnUrl: '',
    ipnCustomValue: '',
    secondaryResidentId: '',
    behavior: {
      cancel_option: null,
      redir_url: null
    }
  }
}

const overrides = (state = initialState, action) => {
  switch (action.type) {
    case savePaymentBalanceOverride().type:
      return {
        ...state,
        payments: {
          balance: {
            paymentFields: action.paymentFields
          }
        }
      }
    case saveHideAutopayType().type:
      return {
        ...state,
        payments: {
          autoPay: {
            hide: action.hideAutopayType
          }
        }
      }
    case saveIpnUrl().type:
      return {
        ...state,
        ipnUrl: action.ipnUrl
      }
    case saveIpnCustomValue().type:
      return {
        ...state,
        ipnCustomValue: action.ipnCustomValue
      }
    case saveSecondaryResidentId().type:
      return {
        ...state,
        secondaryResidentId: action.secondaryResidentId
      }
    case setCancelOptionBehaviorOverride().type:
      return {
        ...state,
        payments: {
          ...state.payments,
          behavior: {
            ...state.payments.behavior,
            cancel_option: action.cancel_option
          }
        }
      }
    case setPaymentSuccessRedirect().type:
      return {
        ...state,
        payments: {
          ...state.payments,
          behavior: {
            ...state.payments.behavior,
            payment_success_redirect: action.payment_success_redirect
          }
        }
      }
    default:
      return state
  }
}

export default overrides
