import { PAYMENT_OPTIONS } from '../actions'
import withFetch from '../../../helpers/reducers/withFetch'

const options = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(PAYMENT_OPTIONS)(options)
