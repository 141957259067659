// @flow

import React, { useState } from 'react'
import Loader from '@Common/Loader'
import WithLeftSideNav from '@Common/WithLeftSideNav'
import Button from '../Common/Button'
import FilterChipSection from '../Common/FilterChipSection/FilterChipSection'
import Card from './Card'
import {
  PageTitle,
  Deals
} from './styles'

type Props = {
  isFetching: boolean,
  deals: { [key: string]: any },
  unitId: number
}

const Marketplace = (props: Props) => {
  const {
    isFetching,
    deals,
    unitId
  } = props

  const [dealsKey, setDealsKey] = useState(0)
  const [filtersSelected, _setFiltersSelected] = useState({ 'type': [] })
  const [dealsCount, showMoreDeals] = useState(16)

  const setFiltersSelected = (newFiltersSelected) => {
    _setFiltersSelected(newFiltersSelected)
    setDealsKey(dealsKey + 1)
  }

  if (isFetching) {
    return <Loader />
  }

  const filters = { 'type': ['services', 'devices'] }

  const typeFilterConvertFromDisplay = (filter) => {
    switch (filter) {
      case 'services':
        return 'service'
      case 'devices':
        return 'device'
      default:
        return filter
    }
  }

  const dealsToDisplay = () => {
    let result = deals || []
    const typeFilters = filtersSelected['type'] || []

    // apply user-selected type filters
    if (typeFilters.length !== 0) {
      const typeFilterValues = typeFilters.map(typeFilterConvertFromDisplay)
      result = result.filter(deal => typeFilterValues.includes(deal.type))
    }

    // apply creation date descending sort
    result = result.sort((a, b) =>
      new Date(b.inserted_at) - new Date(a.inserted_at)
    )

    return result
  }

  return (
    <WithLeftSideNav routes={[]}>
      <PageTitle>Marketplace</PageTitle>
      <FilterChipSection
        filters={filters}
        filtersSelected={filtersSelected}
        setFiltersSelected={setFiltersSelected}
      />
      <Deals key={dealsKey}>
        {dealsToDisplay().slice(0,dealsCount).map((deal) =>
          <Card
            deal={deal}
            key={deal.id}
            unitId={unitId}
          />
        )}
      </Deals>
      {dealsCount < dealsToDisplay().length && <Button name='Show More' primary={true}
        onClick={ () =>
          // increment dealsCount by 16, unless there are fewer than 16 additional deals to display
          showMoreDeals(dealsCount <= dealsToDisplay().length-16 ? dealsCount + 16 : dealsToDisplay().length)
        }
      />}
    </WithLeftSideNav>
  )
}

export default Marketplace
