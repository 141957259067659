import localForage from 'localforage'

const appPersistConfig = {
  key: 'zego_resident_data',
  storage: localForage,
  whitelist: ['authorization', 'survey', 'select', 'settings', 'company', 'overrides', 'pmSettings'],
  timeout: 0
}

export default appPersistConfig
