import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ldInitRequest } from 'zego-shared/store/integrations/launchDarkly/actions'
import { getFeatureFlag } from 'zego-shared/store/integrations/launchDarkly/selectors'

export function useFeatureFlag(flagName, defaultValue = false) {
  const [flag, setFlag] = useState(false)
  const dispatch = useDispatch()
  const featureFlag = useSelector(getFeatureFlag)

  useEffect(() => {
    dispatch(ldInitRequest())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFlag(featureFlag?.ld?.variation(flagName, defaultValue))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlag])

  return flag
}

// React class components don't allow hooks. If you don't want to refactor a class component into a function component
// you can use this as a non-rendered child component that manages the parent component flag's state.
export const UseFeatureFlagComponent = (props) => {
  const {
    setFlagValue,
    flagName,
    defaultValue,
    ldInitRequestProp
  } = props

  useEffect(() => {
    ldInitRequestProp()
  }, [ldInitRequestProp])

  const featureFlag = useSelector(getFeatureFlag)

  useEffect(() => {
    setFlagValue(
      featureFlag &&
      featureFlag.ld &&
      featureFlag.ld.variation(flagName, defaultValue)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlag])

  return null // do not render anything
}