// @flow

import get from 'lodash/get'

export const getSSOPaymentLink = (state: Object) => {
  return get(state, ['payments', 'ssoPayment', 'rent'])
}

export const getSSOPayment = (state: Object) => {
  return get(state, ['payments', 'ssoPayment']) !== null
}