export default {
  common: {
    midnight: '#0E1427',
    white: '#FFFFFF',
    grey: '#5C6274',
    iceberg: '#E6F6F0',
    lightBlue: '#E6F5F9'
  },
  primary: {
    deep: '#0E1427',
    main: '#0C173C',
    light: '#213061',
    contrastText: '#FFF'
  },
  secondary: {
    dark: '#06846E',
    main: '#59C09B',
    light: '#ACE0CD',
    light1: '#E6F6EF',
    contrastText: '#FFF'
  },
  alternative: {
    purple: '#3B3E98',
    brightblue: '#029EC6'
  },
  tertiary: {
    dark: '#ff9f00',
    main: '#fdb627',
    contrastText: '#FFF'
  },
  neutral: {
    deep: '#5C6274',
    dark: '#8A8D9E',
    main: '#BABABA',
    warm: '#D4D4D4',
    cool: '#E7E7E7',
    light: '#EDEDED',
    light1: '#CCCCCC',
    light2: '#F5F5F5',
    light3: '#DDDDDD',
    light4: '#EEEEEE'
  },
  text: {
    primary: '#5C6274'
  },
  error: {
    light: '#F78BA3',
    main: '#EE305A',
    dark: '#C6002E',
    contrastText: '#fff',
    plaidBalanceError: '#FCE0E6',
    border: '#C51F47',
  },
  feedback: {
    success: '#029385',
    warning: '#f6c243',
    error: '#ed3b6a'
  },
  secondaryCard: '#b9c9dc',
  alert: {
    lightgold: '#FFF4DF'
  },
  clientNotes: {
    titleBackGround: '#C51F47',
    titleText: '#FFFFFF',
    border: '#C51F47',
    contentBackGround: '#FDDCE2',
    contentText: '#0E1427'
  }
}
