import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import { Dialog, IconButton } from '@material-ui/core'
import { StyledLink } from '../styles'

import ProprtyIcon from '@images/credit_reporting/property_icon.png'
import Check from '@images/credit_reporting/check.png'
import CheckBlue from '@images/credit_reporting/check_blue.png'

export const Container = styled(Flex)(
  ({ theme }) => `
  width: 565px;
  padding: ${theme.space.s24} ${theme.space.s28} ${theme.space.s41};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 1;
  margin-top: ${theme.space.s27};

  @media (max-width: 1260px) {
    width: 452px;
  }

  ${[theme.breakpoints.down('xs')]} {
    width: 335px;
    padding: ${theme.space.s28} ${theme.space.s11} ${theme.space.s37};
  }

  ${theme.ux.isSSO &&
    `
      width: initial !important;
      max-width: 533px;
      margin-top: ${theme.space.s15};
      padding: ${theme.space.s11} ${theme.space.s15} ${theme.space.s15};
    `}
`
)

export const Header = styled(Flex)`
  justify-content: space-between;
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    height: 53px;
    &>*{
      transform: scale(0.88333);
      transform-origin: top;
    }
    `}
`

export const DetailsSection = styled(Flex)`
  minwidth: 250px;
  height: 60px;
`

export const Avatar = styled.div`
  background-image: url('${ProprtyIcon}');
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.space.s12};
`

export const Street = styled.div`
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  line-height: 19px;
  margin-top: ${({ theme }) => theme.space.s4};
`

export const GreyText = styled.div`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  margin-right: ${({ marginRight }) => marginRight};
`

export const MonthsContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  flex: 1;
  justify-content: space-around;
`
)

export const MonthLane = styled(MonthsContainer)`
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s6 : theme.space.s29};
`

export const Month = styled.div`
  color: ${({ theme }) => theme.palette.common.grey};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  margin: auto;
`

export const Tracker = styled(Flex)`
  height: 38px;
  background-color: rgba(237, 237, 237, 0.5);
  color: ${({ theme }) => theme.palette.common.grey};
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s7 : theme.space.s10};
`

export const TrackerDots = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.neutral.warm};
  margin: auto;

  @media (max-width: 1260px) {
    height: 7px;
    width: 7px;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    height: 6px;
    width: 6px;
  }
`

export const CheckMark = styled.div`
  width: 13px;
  height: 10px;
  background-image: url(${({ blue }) => (blue ? CheckBlue : Check)});
  background-size: cover;
  margin-top: ${({ theme }) => theme.space.s4};
`

export const TrackerCheckMark = styled.div`
  width: 13px;
  height: 10px;
  background-image: url(${({ blue }) => (blue ? CheckBlue : Check)});
  background-size: cover;
  margin: auto;
`

export const Year = styled.div`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  margin: auto 10px;
  width: 42px;
  text-align: center;

  @media (max-width: 1260px) {
    line-height: 22px;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`

export const DialogStyled = styled(Dialog)(
  ({ theme }) => `
  & .dialog-paper {
    width: 85%;
    max-width: 700px;
    padding: ${theme.space.s55} ${theme.space.s30} ${theme.space.s40};
    border-radius: 5px;
  }
`
)

export const Icon = styled(IconButton)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.space.s5};
    top: ${theme.space.s15};
  `
)

export const MoreHistoryLink = styled(StyledLink)(
  ({ theme }) => `
    width: content-width;
    margin-left: auto;
    margin-top: ${theme.space.s20}
  `
)

export const CounterWrapper = styled(Flex)(
  ({ theme }) => `
    font-size: ${theme.custom.font.size.md};
    font-weight: bold;
    margin-bottom: ${theme.space.s24};
    align-items: center;
  `
)

export const Counter = styled(Flex)(
  ({ theme, blue }) => `
    width: 24px;
    height: 24px;
    border-radius: 50%;
    justify-content: center;
    background-color: ${
      blue ? theme.palette.alternative.brightblue : theme.palette.secondary.main
    };
  `
)

export const CounterText = styled('span')(
  ({ theme }) => `
    margin-left: ${theme.space.s5};
    margin-right: ${theme.space.s10};
  `
)

export const FootNote = styled(Flex)(
  ({ theme }) => `
    justify-content: flex-end;
    margin-top: ${theme.space.s30};
    & > div {
      margin-left: ${theme.space.s14};
    }
  `
)
