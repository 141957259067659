// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import Card from '@Common/Card'
import IconButton from '@material-ui/core/IconButton'

export const PaymentMethodCardStyled = styled(Card)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => [theme.breakpoints.up('md')]} {
    height: ${({ theme }) =>
      theme.ux.isSSO ? `275px` : `308px`};
  }

  & .MuiCardContent-root {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;

    ${({ theme }) =>
      theme.ux.isSSO && `padding: 50px 30px 30px;`}
  }
`

export const MoreMethodsStyled = styled(Flex)`
  height: 100%;
  width: 100%;
  & > div {
    z-index: 1;
  }
`

export const MoreModalCardStyled = styled(PaymentMethodCardStyled)`
  min-height: 381px;
  height: ${({ theme }) =>
    theme.ux.isSSO ? `100%` : `80%`};
  max-width: 900px;
  width: ${({ theme }) =>
    theme.ux.isSSO ? `100%` : `90%`};
  border-radius: ${({ theme }) =>
    theme.ux.isSSO ? `0` : `0 45px 0 45px`};
  & .MuiCardContent-root {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: ${({ theme }) =>
      theme.ux.isSSO && `40px 30px 50px 30px`};
    & p {
      min-height: 64px;
      max-width: 412px;
      width: ${({ theme }) =>
        theme.ux.isSSO ? `40%` : `80%`};
      color: ${({ theme }) => theme.palette.common.midnight};
      font-size: ${({ theme }) =>
        theme.ux.isSSO
          ? theme.custom.font.size.H5
          : theme.custom.font.size.H4};
      line-height: ${({ theme }) =>
        theme.ux.isSSO ? `28px` : `32px`};
      text-align: center;
      margin: 0;
      margin-bottom: ${({ theme }) =>
        theme.ux.isSSO ? `20px` : `54px`};
    }
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    ${({ theme }) =>
      theme.ux.isSSO &&
      `
      & .MuiCardContent-root {
        & p {
          width: 70%;
          font-size: 16px;
        }
      }
      `}
  }
`

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 30px;
  top: 30px;
  & svg {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    color: ${({ theme }) => theme.palette.primary.main};
    width: ${({ theme }) => theme.ux.isSSO && `2em`};
    height: ${({ theme }) => theme.ux.isSSO && `2em`};
  }
`

export const CardNetworksStyled = styled.div`
  min-height: 16px;
  & p {
    width: 343px;
    color: ${({ theme }) => theme.palette.common.gray};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: 14px;
    margin: 0;
    word-break: break-word;
    width: 100%;

    ${({ theme }) => [theme.breakpoints.down('md')]} {
      margin: ${({ theme }) => `${theme.space.s45} 0 ${theme.space.s10}`};
    }
  }
`
