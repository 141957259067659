import { makeStyles } from '@material-ui/core/styles'


export const useStyles = makeStyles(() => ({
  acknowledgementDisclaimer: {
    "color": "red",
    "font-size": "12px"
  }
})
)
