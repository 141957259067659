// @flow

import React from 'react'
import ReactScrollToTop from 'react-scroll-up'
import ScrollTop from '@images/scroll_to_top.png'
import { Container } from './styles'

const ScrollToTop = () => (
  <Container>
    <ReactScrollToTop
      showUnder={0}
      style={{
        bottom: '-20px',
        right: '50%',
        left: '50%'
      }}>
      <img
        id="scropTop"
        src={ScrollTop}
        alt="ScrollTop"
        height="70px"
        width="80px"
      />
    </ReactScrollToTop>
  </Container>
)

export default ScrollToTop
