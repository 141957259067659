import { combineReducers } from 'redux'
import {
  AUTO_PAY_CONFIG,
  CASH_PAY_CONFIG,
  AUTO_PAY_STATE,
  AUTO_PAY_DETAILS_SET,
  AUTO_PAY_AMOUNT_TYPE_CHANGE,
  AUTO_PAY_DETAILS_UPDATE,
  AUTO_PAYS_SECTION,
  SKIP_FIXED_AUTOPAY_SUCCESS,
  SKIP_VARIABLE_AUTOPAY_SUCCESS,
  CANCEL_SKIP_FIXED_AUTOPAY_SUCCESS,
  CANCEL_SKIP_VARIABLE_AUTOPAY_SUCCESS,
  CANCEL_VARIABLE_AUTOPAY_SUCCESS,
  CANCEL_FIXED_AUTOPAY_SUCCESS
} from '../actions'
import withFetch from '../../../helpers/reducers/withFetch'
import { VAP } from '../constants'

const config = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const cash_pay_config = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

const state = (state = '', action) => {
  switch (action.type) {
    default:
      return state
  }
}

const details = (state = null, action) => {
  switch (action.type) {
    case AUTO_PAY_DETAILS_SET:
      return action.details
    case AUTO_PAY_DETAILS_UPDATE:
      return state ? { ...state, ...action.detail } : state
    case AUTO_PAY_AMOUNT_TYPE_CHANGE:
      return null
    default:
      return state
  }
}

const paymentAmountType = (state = VAP, action) => {
  switch (action.type) {
    case AUTO_PAY_AMOUNT_TYPE_CHANGE:
      return action.amountType
    default:
      return state
  }
}

const initialState = { fixedAutopays: [], variableAutopays: [] }

const autoPays = (state = initialState, action) => {
  switch (action.type) {
    case CANCEL_FIXED_AUTOPAY_SUCCESS:
      return {
        ...state,
        fixedAutopays: state.fixedAutopays.filter(
          autopay => autopay.id !== action.id
        )
      }
    case CANCEL_VARIABLE_AUTOPAY_SUCCESS:
      return {
        ...state,
        variableAutopays: state.variableAutopays.filter(
          autopay => autopay.id !== action.id
        )
      }
    case SKIP_FIXED_AUTOPAY_SUCCESS:
    case CANCEL_SKIP_FIXED_AUTOPAY_SUCCESS:
      return {
        ...state,
        fixedAutopays: [
          ...state.fixedAutopays.map(autopay => {
            return autopay.id === action.id
              ? {
                  ...autopay,
                  skipped: action.skipped
                }
              : { ...autopay }
          })
        ]
      }
    case SKIP_VARIABLE_AUTOPAY_SUCCESS:
    case CANCEL_SKIP_VARIABLE_AUTOPAY_SUCCESS:
      return {
        ...state,
        variableAutopays: [
          ...state.variableAutopays.map(autopay => {
            return autopay.id === action.id
              ? {
                  ...autopay,
                  skipped: action.skipped
                }
              : { ...autopay }
          })
        ]
      }
    default:
      return state
  }
}

export default combineReducers({
  config: withFetch(AUTO_PAY_CONFIG)(config),
  cash_pay_config: withFetch(CASH_PAY_CONFIG)(cash_pay_config),
  state: withFetch(AUTO_PAY_STATE)(state),
  autoPays: withFetch(AUTO_PAYS_SECTION)(autoPays),
  details,
  paymentAmountType
})
