import { connect } from 'react-redux'
import {
  getResidentFullName,
  getSummaryData,
  getPlaidInsufficientFundsError
} from 'zego-shared/store/payments/oneTimePayment/selectors'
import { makeOnetimePayment } from 'zego-shared/store/payments/oneTimePayment/actions'
import {
  isPayPalSelected,
  isDebitCardSelected,
  isPlaidAccountSelected,  
  getPaymentMethod,
  isCreditCardSelected
} from 'zego-shared/store/payments/methods/selectors'
import { getIsUpdatingOTP } from 'zego-shared/store/isUpdating/selectors'
import Summary from './Summary'
import type { BrowserRouter } from 'react-router-dom'
import {
  getPaymentDisclaimerMemo,
  getPaymentReviewMemo
} from 'zego-shared/store/company/selectors'
import { getResidentNsfFee } from 'zego-shared/store/pmSettings/selectors'
import { getPlaidBalanceChecks } from 'zego-shared/store/payments/options/selectors'
import { getPaymentNotes } from 'zego-shared/store/payments/paymentNotes/selectors'

export const mapStateToProps = (state: Object) => {
  return {
    fullName: getResidentFullName(state),
    summary: getSummaryData(state),
    isProcessing: getIsUpdatingOTP(state),
    isPayPal: isPayPalSelected(state),
    isDebitCard: isDebitCardSelected(state),
    paymentReviewMemo: getPaymentReviewMemo(state),
    paymentDisclaimerMemo: getPaymentDisclaimerMemo(state),
    residentNsfFee: getResidentNsfFee(state),
    plaidInsufficientFundsError: getPlaidInsufficientFundsError(state),
    isCreditCard: isCreditCardSelected(state),
    isPlaidBankAccount: isPlaidAccountSelected(state),
    hasBalanceChecksEnabled: getPlaidBalanceChecks(state),
    paymentNotes: getPaymentNotes(state),
    paymentMethod: getPaymentMethod(state)
  }
}

export const mapDispatchToProps = (
  dispatch: Function,
  {
    match: {
      params: { residentId }
    }
  }: BrowserRouter
) => {
  return {
    makePayment: onSuccess =>
      dispatch(makeOnetimePayment(residentId, onSuccess))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary)
