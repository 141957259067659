// @flow
import * as React from 'react'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import Modal from '@material-ui/core/Modal'
import Loader from '@Common/Loader'
import Button from '@Common/Button'
import PaymentMethod from '../PaymentMethod'
import MoreOptions from './MoreOptions'
import PaymentMethodDetails from './PaymentMethodDetails'
import Plaid from '../../Plaid'
import {
  ModalBackDropStyled,
  HeaderStyled,
  PaymentOptionsStyled
} from '../styles'
import {
  PaymentMethodCardStyled,
  MoreMethodsStyled,
  CardNetworksStyled
} from './styles'

type PaymentMethodType = {
  name: 'Bank acct' | 'Credit card' | 'PayPal' | 'MasterPass' | 'Apple Pay',
  fee?: string
}

type Props = {
  location: Object,
  onAddPaymentMethodChange: Function,
  value: string | Object,
  paymentOptions: PaymentMethodType[],
  paymentMethods: Object[],
  isFetching: boolean,
  paymentMethodProps: Object,
  saveActions: Object,
  selectPaymentMethod: string => mixed,
  showCardNetworks: Boolean,
  savedMethodType: string,
  showNotes?: boolean,
  selectedPaymentMethod: Object
}

type State = {
  showDetails: boolean,
  showMore: boolean
}

class PaymentMethods extends React.PureComponent<Props, State> {
  state = {
    showDetails: Boolean(this.props.value),
    showMore: false
  }

  componentDidUpdate(prevProps: Props) {
    const { value } = this.props
    if (prevProps.value !== value) {
      this.setState({ showDetails: Boolean(value) })
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.selectedPaymentMethod?.methodType) {
      return {
        showMore: false,
        showDetails: false
      }
    }

    return state
  }

  toggleShowMore = () => {
    const { showMore } = this.state
    this.setState({ showMore: !showMore })
  }

  setShowDetails = (showDetails: boolean) => () => {
    this.setState({ showDetails })
  }

  toggleAddPaymentMethod = (method: Object) => {
    const { onAddPaymentMethodChange, onAddPaymentMethod } = this.props
    onAddPaymentMethod(method)
    this.setState({ showDetails: true })
    onAddPaymentMethodChange && onAddPaymentMethodChange()
  }

  addPaymentMethod = () => {
    this.setState({ showDetails: false })
  }

  onSelectLinkedAccount = (paymentMethodId: string) => {
    const { selectPaymentMethod, onContinueClicked } = this.props
    selectPaymentMethod(paymentMethodId)
    onContinueClicked && onContinueClicked()
  }

  render() {
    const {
      location,
      value,
      paymentOptions,
      isFetching,
      paymentMethodProps,
      paymentMethods,
      selectPaymentMethod,
      showCardNetworks,
      showNotes
    } = this.props

    const { showDetails, showMore } = this.state
    const hasPaymentMethod = Boolean(value)

    if (showDetails) {
      return (
        <PaymentMethodDetails
          value={value}
          onAddClick={this.addPaymentMethod}
          onShowMoreClick={selectPaymentMethod}
          paymentMethods={paymentMethods}
          paymentMethodProps={paymentMethodProps}
          isFetching={isFetching}
          showNotes={showNotes}
        />
      )
    }

    return (
      <React.Fragment>
        <PaymentMethodCardStyled
          CardContentProps={{
            classes: {
              root: 'card-content-root'
            }
          }}>
          {isFetching && <Loader />}
          {!isFetching && (
            <>
              <HeaderStyled justifySpaceBetween alignCenter fullWidth>
                <h5>payment method</h5>
                {hasPaymentMethod && (
                  <Button
                    id="cancel"
                    disableRipple
                    link
                    onClick={this.setShowDetails(true)}>
                    cancel
                  </Button>
                )}
              </HeaderStyled>

              <PaymentOptionsStyled alignCenter fullWidth>
                {paymentOptions.map(({ name, fee }) => {
                  const props = paymentMethodProps[name]
                  const isBankAccount = 'Bank acct' === name
                  const PaymentMethodComponent = ({
                    enabled = false,
                    open = () => null
                  }) => (
                    <PaymentMethod
                      id={name.replace(/\s/g, '')}
                      key={name}
                      fee={fee}
                      name={name}
                      {...props}
                      onClick={() => {
                        if (enabled && open) {
                          open()
                        } else {
                          this.toggleAddPaymentMethod(props)
                        }
                      }}
                    />
                  )
                  if (isBankAccount) {
                    return (
                      <Plaid
                        key={name}
                        switchToManual={() => {
                          this.setState({
                            selectedPaymentMethod: props,
                            showMore: false
                          })
                          this.toggleAddPaymentMethod(props)
                        }}
                        render={PaymentMethodComponent}
                        onSelectLinkedAccount={this.onSelectLinkedAccount}
                      />
                    )
                  }
                  return <PaymentMethodComponent key={name} />
                })}
                {paymentOptions.length > 4 && (
                  <PaymentMethod empty onClick={this.toggleShowMore}>
                    <MoreHoriz />
                  </PaymentMethod>
                )}
              </PaymentOptionsStyled>
              <CardNetworksStyled>
                {showCardNetworks && (
                  <p>
                    * accepted credit types are visa, mastercard and american
                    express
                  </p>
                )}
              </CardNetworksStyled>
            </>
          )}
        </PaymentMethodCardStyled>
        <Modal
          open={showMore}
          BackdropComponent={ModalBackDropStyled}
          onBackdropClick={this.toggleShowMore}>
          <MoreMethodsStyled justifyCenter alignCenter>
            <MoreOptions
              location={location}
              onCloseBtnClick={this.toggleShowMore}
              onMethodClick={this.toggleAddPaymentMethod}
              paymentOptions={paymentOptions}
              paymentOptionsProps={paymentMethodProps}
            />
          </MoreMethodsStyled>
        </Modal>
      </React.Fragment>
    )
  }
}

export default PaymentMethods
