import BaseCard from '@material-ui/core/Card'
import BaseCardContent from '@material-ui/core/CardContent'
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

export const StyledCard = styled(BaseCard, {
  shouldForwardProp: prop => isPropValid(prop)
})`
  border-radius: 5px;

  &.MuiPaper-elevation1 {
    box-shadow: ${props => props.theme.custom.shadow};
    ${({ noShadow }) => noShadow && 'box-shadow: none'};
  }

  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
`

export const StyledCardContent = styled(BaseCardContent, {
  shouldForwardProp: prop => isPropValid(prop)
})`
  padding: ${({ theme, customPadding }) =>
    customPadding ? customPadding : `${theme.space.s50} ${theme.space.s30}`};
  &:last-child {
    padding: ${({ theme, customPadding }) =>
    customPadding ? customPadding : `${theme.space.s50} ${theme.space.s30}`};
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    padding: ${({ theme }) => theme.space.s20};
    &:last-child {
      padding: ${({ theme }) => theme.space.s20};
    }
  }
`
