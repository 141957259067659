// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import Card from '@Common/Card'
import { IconButton } from '@material-ui/core'

export const SuccessHeaderStyled = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space.s30};

  & button {
    height: fit-content;
  }
`

export const TransactionDetails = styled('div')`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.custom.font.size.H3};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: 43px;
  margin: 0;
  position: relative;
  & span {
    position: relative;
  }
  &::before {
    height: 14px;
    left: 0;
    right: 5px;
    bottom: -7px;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    transform: translateX(10px);
    content: '';
  }
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    &&::before {
      bottom: 7px;
      height: 10px;
    }
  }
  ${({ theme }) => [theme.breakpoints.only('md')]} {
    &&::before {
      bottom: -2px;
    }
  }
`

export const ConfirmationStyled = styled('p')`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  line-height: 20px;
  margin: 0;
`

export const TitleStyled = styled.h3`
  height: 43px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H3};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  line-height: 43px;
  margin: 0;
  margin-bottom: 13px;

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
  }
`

export const StatusStyled = styled(Flex)`
  & a {
    height: 16px;
    color: ${({ theme }) => theme.palette.secondary.dark};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    line-height: 19px;
    text-decoration: underline;
    text-align: right;
  }
  & p {
    text-align: left;
  }

  & p,
  & a {
    width: 50%;
  }
`

export const BackArrowButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  height: 44px !important;
  width: 44px;
  padding: 0;
  margin-bottom: 10px;
`

export const PrinterIconButton = styled(IconButton)`
  border: 2px solid ${({ theme }) => theme.palette.tertiary.main};
  height: 44px !important;
  width: 44px;
  padding: 0;
  box-sizing: border-box;
`

export const Circle = styled(Flex)`
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.primary.deep};
  font-size: ${({ theme }) => theme.custom.font.size.md};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  margin-right: ${({ theme }) => theme.space.s15};
`

export const DesktopOrTablet = styled('div')`
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    display: none;
  }
`

export const MobileView = styled('div')`
  display: none;
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    display: block;
  }
`

export const Amount = styled(Flex)`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  margin-right: ${({ theme }) => theme.space.s10};
`

export const TransactionType = styled(Flex)`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
`

export const TransactionStatus = styled(Flex)`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.md};

  & a {
    color: ${({ theme }) => theme.palette.primary.dark};
    font-size: ${({ theme }) => theme.custom.font.size.md};
    text-decoration: underline;
  }
`

export const DetailsLink = styled('div')`
  margin-top: 8px;
  height: 16px;

  & a {
    color: ${({ theme }) => theme.palette.secondary.dark};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    text-decoration: underline;
  }
`

export const HistoryDetailsWrap = styled('div')`
  margin-bottom: ${({ theme }) => theme.space.s100};
`

export const NoHistory = styled('div')`
  width: 512px;
  color: ${({ theme }) => theme.palette.common.black};
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  margin-top: ${({ theme }) => theme.space.s120};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  text-align: center;

  & a {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.secondary.dark};
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin-top: ${({ theme }) => theme.space.s80};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
  }
`

export const HistoryList = styled(Card)`
  max-height: 360px;
  overflow-y: scroll;

  & .MuiCardContent-root {
    padding-top: ${({ theme }) => theme.space.s25} !important;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    max-height: ${({ theme }) => theme.ux.isSSO && '100%'};
  }
`

export const Divider = styled(Flex)(
  ({ theme }) => `
    border-top: ${`1px solid ${theme.palette.neutral.cool}`};
    margin: 25px 0;
  `
)

export const ReturnStyle = styled('div')`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
`
