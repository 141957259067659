// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import OptinSuccess from '@images/credit_reporting/opt_in_success_desktop.png'
import OptinSuccessTab from '@images/credit_reporting/opt_in_success_tablet.png'
import OptinSuccessMob from '@images/credit_reporting/opt_in_success_mobile.png'
import HandsBG from '@images/credit_reporting/hands_desktop.png'
import HandsBGMobile from '@images/credit_reporting/hands_mobile.png'

export const Banner = styled.div`
  text-decoration: none;
  display: block;
  background-image: url('${OptinSuccess}');
  height: 485px;
  width: 252px;
  z-index: 1;
  margin-left: ${({ theme }) => theme.space.s2};

  ${({ theme, variant }) =>
    variant === 'short' &&
    `
    background-image: url('${OptinSuccess}');
    border-radius: 4px 4px 0 0;
    background-size: 249px 350px;
    width: 249px;
    height: 350px;
    position: relative;
    margin-top: ${theme.space.s33};
  `}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    background-image: url('${OptinSuccessTab}');
    background-size: cover;
    height: 204px;
    max-width: 452px;
    width: 100%;
    margin-top: 0;
    margin-bottom:  ${({ theme }) => theme.space.s22};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    background-image: url('${OptinSuccessMob}');
    background-size: cover;
    width: 100%;
    max-height: 210px;
    margin-left: 0;
  }
  ${({ theme, variant }) =>
    theme.ux.isSSO &&
    `
    background-position: bottom center;
    background-repeat: no-repeat;
    width: 170px;
    height: auto;
    margin-top: ${variant === 'short' ? theme.space.s10 : 0};
    padding-bottom: ${variant === 'short' ? 0 : theme.space.s40};
  `}
`

export const BottomText = styled.div`
  width: 165px;
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  color: ${({ theme }) => theme.palette.common.midnight};
  margin-top: ${({ theme }) => theme.space.s30};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  line-height: 18px;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: none;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    margin-top: 0;
    font-size: 11px;
    font-weight: ${theme.custom.font.weight.light};
    line-height: 13px;
  `}
`

export const ContentWrap = styled(Flex)`
  padding: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s30 : theme.space.s43};
  padding-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s35 : theme.space.s51};
  display: flex;
  flex-direction: column;

  ${({ theme, variant }) =>
    variant === 'short' &&
    `
    padding-top: 10px;
  `}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    padding-top: 0;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    padding-top: 0;
    margin-left: 0;
  }
`

export const Title = styled.div`
  width: 160px;
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H4 : theme.custom.font.size.H3};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: 1;
  color: ${({ theme }) => theme.palette.common.midnight};
`

export const SubTitle = styled.div`
  width: 160px;
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.sm : theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  line-height: ${({ theme }) => (theme.ux.isSSO ? 13 : 18)}px;
  color: ${({ theme }) => theme.palette.common.midnight};
  margin-top: ${({ theme }) => theme.space.s10};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s8};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`

export const Hands = styled(Flex)`
  background-image: url('${HandsBG}');
  width: 224px;
  height: 170px;
  margin-top: ${({ theme }) => theme.space.s30};

  ${({ theme, variant }) =>
    variant === 'short' &&
    `
    width: 175px;
    height: 120px;
    margin-top: 5px;
    background-size: cover;
  `}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: 202px;
    height: 155px;
    margin-top: 0;
    background-size: cover;
    margin-right: ${({ theme }) => theme.space.s10};
    margin-top: ${({ theme }) => theme.space.s4};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    background-image: url('${HandsBGMobile}');
    width: 181px;
    margin-right: 0;
    background-size: contain;
    margin-top: ${({ theme }) => theme.space.s12};
    background-repeat: no-repeat;
  }

  ${({ theme, variant }) =>
    theme.ux.isSSO &&
    `
      width: 150px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: ${variant === 'short' ? 85 : 150}px;
      margin:  ${variant === 'short' ? '5px' : 0} 0;
    `}
`

export const TitleHands = styled(Flex)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
`

export const TitleSubTitle = styled(Flex)`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s50};
    margin-left: ${({ theme }) => theme.space.s41};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    margin-left: ${({ theme }) => theme.space.s30};
  }
`
