// @flow

import React, { useEffect } from 'react'
import Button from '@Common/Button'
import Flex from '@Common/Flex'
import { paymentMethodTypes } from '@utils/view/paymentMethods'
import {
  CheckMark,
  CheckTextContainer,
  Title,
  SubTitle,
  CheckText,
  QuickPaymentsCard
} from './styles'
import { useTheme } from '@material-ui/core'

type Props = {
  addPaymentMethod: Function
}

const checkTexts = [
  'Realize faster payment processing',
  'Reduce late and returned payment fees',
  'Get peace of mind with consumer protections'
]

const DebitAd = ({ addPaymentMethod }: Props) => {
  const theme = useTheme()
  const isSSO = theme.ux.isSSO

  // Reduce check items to 2 for sso users
  useEffect(() => {
    if (isSSO && checkTexts.length > 2) {
      checkTexts.splice(2)
    }
  }, [isSSO])

  const renderCheckTexts = () => {
    return checkTexts.map(text => (
      <Flex direction="row" alignStart>
        <CheckMark />
        <CheckTextContainer alignCenter justifyCenter>
          <CheckText>{text}</CheckText>
        </CheckTextContainer>
      </Flex>
    ))
  }

  return (
    <QuickPaymentsCard>
      <Title>Quick &amp; Easy Payments</Title>
      <SubTitle>When using debit</SubTitle>
      <Flex direction="column" marginBottom="10px">
        {renderCheckTexts()}
      </Flex>
      <Button
        primary
        elong
        onClick={() => addPaymentMethod(paymentMethodTypes.DEBIT)}>
        Set Up Debit Card
      </Button>
    </QuickPaymentsCard>
  )
}

export default DebitAd
