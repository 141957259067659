// @flow
import get from 'lodash/get'

export const getHasAcceptedTAndC = (
  state: Object,
  tncType: string = 'resident'
) => get(state, `termsAndConditions.${tncType}.has_signed_latest_terms`)

export const getId = (state: Object, tncType: string = 'resident') =>
  get(state, `termsAndConditions.${tncType}.latest_terms.id`, 1)

export const getLatestTAndC = (state: Object, tncType: string = 'resident') => {
  const url = get(state, `termsAndConditions.${tncType}.latest_terms.body`, '')
  const id = get(state, `termsAndConditions.${tncType}.latest_terms.id`, '')
  return { url, id }
}

// The create Versions of the selectors are useful when using `useSelector` which does not
// allow an extra parameter to be passed to the selector
export const createHasAcceptedTAndCSelector = (tncType: string) => (
  state: Object
) => getHasAcceptedTAndC(state, tncType)

export const createIdSelector = (tncType: string) => (state: Object) =>
  getId(state, tncType)

export const createLatestTnCSelector = (tncType: string) => (state: Object) =>
  getLatestTAndC(state, tncType)
