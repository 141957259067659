import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePlaidLink } from 'react-plaid-link'
import {
  fetchPlaidLinkTokenAction,
  cleanError
} from 'zego-shared/store/payments/plaid/actions'
import { hasError } from 'zego-shared/store/payments/plaid/selectors'
import { useHasPlaid } from '../hooks'

export default function PlaidLink({ onExit = () => {}, render, onSuccess }) {
  const [enabledModal, setEnabledModal] = useState(false)
  const [success, setSuccess] = useState(false)

  const dispatch = useDispatch()
  const { enabled, token } = useHasPlaid()
  const error = useSelector(hasError)
  const cleanUp = useCallback(() => dispatch(cleanError()), [dispatch])
  const close = useCallback(() => {
    if (error) {
      cleanUp()
    }
    setEnabledModal(false)
  }, [setEnabledModal, error, cleanUp])

  const onPlaidExit = useCallback(
    (error, data) => {
      onExit(error)
      close()
    },
    [close, onExit]
  )

  useEffect(() => {
    !token && dispatch(fetchPlaidLinkTokenAction())
  }, [dispatch, token])

  const onEvent = (name, data) => {
    if ('HANDOFF' === name) {
      setSuccess(true)
    }
  }

  const openPlaid = useCallback(() => {
    if (!enabled) return
    setEnabledModal(true)
  }, [enabled])

  const renderProps = { openPlaid }

  if (error) return render({ exit: close, error, ...renderProps })

  if (token && enabled && enabledModal) {
    return (
      <PlaidWrapper
        options={{
          onExit: onPlaidExit,
          token,
          onSuccess,
          onEvent
        }}
        render={({ exit, error }) => {
          return success ? (
            render({ exit, error, success, ...renderProps })
          ) : (
            <></>
          )
        }}
      />
    )
  }

  return render(renderProps)
}

function PlaidWrapper({ options, render }) {
  const { error, ready, exit, open } = usePlaidLink(options)

  useEffect(() => {
    if (!ready) {
      return
    }
    open()
  }, [ready, open])

  return render({ error, exit })
}
