// @flow
import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'
import { convertHexToRGB } from '@utils/view/common'

export const CardsGrid = styled(Grid)`
  margin: 58px auto 131px;
  width: 680px;
  margin: 40px auto 131px;

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    width: 100%;
  }
`

export const Disclaimer = styled.div`
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.alert.lightgold};
  font-size: 14px;
  padding: ${({ theme }) => theme.space.s15};
  text-align: center;
`

export const StyledCard = styled('div')`
  border-radius: 15px;
  box-shadow:${({ theme }) =>
    `0px 2px 4px ${convertHexToRGB(theme.palette.common.midnight, 0.5)};`}
  padding: ${({ theme }) => theme.space.s32} ${({ theme }) => theme.space.s35};
  min-height: 235px;

  ${({ outline, error, theme }) =>
    outline &&
    ` border-color: ${
      error ? theme.palette.error.main : theme.palette.secondary.main
    };
      border-style: solid;
      border-width: 1px;`}
  
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding-left: ${({ theme }) => theme.space.s20};
    padding-right: ${({ theme }) => theme.space.s20};
    padding-bottom: ${({ theme }) => theme.space.s20};
    min-height: 200px;
  }
`
