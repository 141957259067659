import { call, put, takeLatest, select, debounce } from 'redux-saga/effects'
import { print } from 'graphql'
import get from 'lodash/get'
import {
  makeOnetimePaymentAction,
  makeOnetimePaymentStart,
  makeOnetimePaymentError,
  makeOnetimePaymentSuccess,
  makeFraudToolkitError,
  fetchOneTimeDataAction,
  fetchOneTimeDataError,
  fetchOneTimeDataStart,
  fetchOneTimeDataSuccess,
  getPayPalPaymentAction,
  getPayPalPaymentSuccess,
  getPayPalPaymentError,
  getPayPalPaymentStart,
  requestPaymentReceiptAction,
  requestPaymentReceiptStart,
  requestPaymentReceiptSuccess,
  requestPaymentReceiptError,
  makePlaidInsufficientFundsError
} from '../actions'
import { showSnackbar } from '../../../snackbar/actions'
import api from '../../../../utils/api'
import { getGraphQlData } from '../../../../utils/graphql'
import {
  failedToMakePayment,
  fraudToolkitErrorMessage,
  activeAutopaysCancelledMessage,
  failToConnectToPlaid
} from '../../../../utils/messages'
import logger from '../../../../utils/logger'
import { getPaymentToken } from '../../../authorization/selectors'
import { getPayload, getConfirmationId } from '../selectors'
import { getPaymentMethod } from '../../methods/selectors'
import {
  createOneTimePayment,
  getPayPalPaymentMethod,
  requestPaymentReceipt
} from '../graphql/mutations'
import { fetchPaymentOptions } from '../../options/sagas'
import { fetchPaymentBalance } from '../../balance/sagas'
import { fetchLastUsedMethod, fetchPaymentMethods } from '../../methods/sagas'
import { getPaymentFieldsAsPayload } from '../../balance/selectors'
import { FEE_TYPE } from '../../fees/actions'
import {
  PAYMENT_DEBOUNCE_OFFSET,
  FRAUD_TOOLKIT_ERROR_KEYS,
  PLAID_ERROR_KEYS
} from '../constants'
import { fetchPaymentOptionFeesSaga } from '../../fees/sagas'
import { getPaymentState } from 'zego-shared/store/payments/summary/selectors'
import { paymentStatuses } from 'zego-shared/store/payments/summary/constants'
import { fetchPaymentMethodsAction } from '../../methods/actions'
import { fetchPlaidItemsAction } from '../../plaid/actions'

export function* fetchOneTimePaymentData({ residentId }) {
  try {
    const paymentState = yield select(getPaymentState)
    if (paymentState !== paymentStatuses.BLOCKED) {
      yield put(fetchOneTimeDataStart())
      yield call(fetchPaymentBalance, { residentId })
      yield call(fetchPaymentOptions, { isOnetimePayment: true })
      yield call(fetchPaymentMethods, { residentId, isOnetimePayment: true, checkAllowed: true })
      yield call(fetchLastUsedMethod)
      const paymentFields = yield select(getPaymentFieldsAsPayload)
      if (paymentFields.length > 0) {
        yield call(fetchPaymentOptionFeesSaga, {
          residentId,
          paymentFields,
          feeType: FEE_TYPE.NORMAL
        })
      }
      yield put(fetchOneTimeDataSuccess())
    }
  } catch (error) {
    logger(error)
    yield put(fetchOneTimeDataError(error))
  }
}

function* handleFraudException(residentId, error) {
  const paymentMethod = yield select(getPaymentMethod)
  const fraudToolkitErrorMessageWithCardInfo = fraudToolkitErrorMessage(
    paymentMethod
  )
  error.message = `${fraudToolkitErrorMessageWithCardInfo} ${error.message}`

  const activeAutopaysCancelled = get(
    error.extensions.details,
    'activeAutopaysCancelled',
    false
  )

  if (activeAutopaysCancelled) {
    error.message = `${error.message} ${activeAutopaysCancelledMessage}`
  }

  yield put(fetchPaymentMethodsAction({ residentId, checkAllowed: true }))
  yield put(makeFraudToolkitError(true))
  yield put(showSnackbar(error.message, 'error'))
}

function* handlePlaidException(error) {
  if (PLAID_ERROR_KEYS.PLAID_INSUFFICIENT_FUNDS === error.extensions.langKey) {
    yield put(makePlaidInsufficientFundsError(true))
  } else if (
    PLAID_ERROR_KEYS.PLAID_LOGIN_REQUIRED === error.extensions.langKey
  ) {
    yield put(fetchPlaidItemsAction())
  } else {
    yield put(showSnackbar(failToConnectToPlaid, 'error'))
  }
}

function* handleMakeOneTimePaymentException(residentId, error) {
  if (error.extensions) {
    if (FRAUD_TOOLKIT_ERROR_KEYS.includes(error.extensions.langKey)) {
      yield handleFraudException(residentId, error)
    } else if (PLAID_ERROR_KEYS.hasOwnProperty(error.extensions.langKey)) {
      yield handlePlaidException(error)
    } else {
      yield put(showSnackbar(error.message || failedToMakePayment, 'error'))
    }
  } else {
    yield put(showSnackbar(error.message || failedToMakePayment, 'error'))
  }

  yield put(makeOnetimePaymentError(error))
}

export function* makeOneTimePayment({ residentId, onSuccess }) {
  try {
    yield put(makeOnetimePaymentStart())
    const payload = yield select(getPayload)
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(createOneTimePayment),
      { residentId, post: payload }
    )
    const rPayload = yield call(
      getGraphQlData,
      response,
      'createOneTimePayment'
    )
    yield put(makeOnetimePaymentSuccess(rPayload))
    onSuccess()
  } catch (error) {
    yield handleMakeOneTimePaymentException(residentId, error)
  }
}

export function* fetchPaypalPaymentMethod({ orderId, onSuccess }) {
  try {
    yield put(getPayPalPaymentStart())
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(getPayPalPaymentMethod),
      { orderId }
    )

    const payload = yield call(
      getGraphQlData,
      response,
      'getPayPalPaymentMethod'
    )

    yield put(getPayPalPaymentSuccess(payload))
    onSuccess()
  } catch (error) {
    yield put(getPayPalPaymentError(error))
    yield put(showSnackbar(error.message || failedToMakePayment, 'error'))
  }
}

export function* requestPaymentReceiptSaga({ transId, email }) {
  try {
    yield put(requestPaymentReceiptStart())
    const paymentToken = yield select(getPaymentToken)
    const transacId = transId ? transId : yield select(getConfirmationId)

    const tokens = { paymentToken }
    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(requestPaymentReceipt),
      { transactionId: transacId, email }
    )

    const payload = yield call(
      getGraphQlData,
      response,
      'requestPaymentReceipt'
    )

    yield put(requestPaymentReceiptSuccess(payload))
    yield put(showSnackbar('Payment receipt sent ', 'success'))
  } catch (error) {
    yield put(requestPaymentReceiptError(error))
    yield put(showSnackbar(error.message || 'receipt request failed', 'error'))
  }
}

export function* watchRequestPaymentReceipt() {
  yield takeLatest(requestPaymentReceiptAction().type, requestPaymentReceiptSaga)
}

export function* watchMakeOneTimePayment() {
  yield debounce(
    PAYMENT_DEBOUNCE_OFFSET,
    makeOnetimePaymentAction().type,
    makeOneTimePayment
  )
}

export function* watchFetchPaypalPaymentMethod() {
  yield takeLatest(getPayPalPaymentAction().type, fetchPaypalPaymentMethod)
}

export function* watchFetchOneTimePaymentData() {
  yield takeLatest(fetchOneTimeDataAction().type, fetchOneTimePaymentData)
}

export default [
  watchMakeOneTimePayment(),
  watchFetchOneTimePaymentData(),
  watchFetchPaypalPaymentMethod(),
  watchRequestPaymentReceipt()
]
