import { connect } from 'react-redux'
import {
  getSummaryData,
  getConfirmationId
} from 'zego-shared/store/payments/oneTimePayment/selectors'
import {
  isLevelCreditReportingEnabled,
  getLevelCreditActive,
  getLevelCreditInactive
} from 'zego-shared/store/payments/creditReporting/selectors'

import PaymentSuccess from './PaymentSuccess'
import { getPaymentNotes } from 'zego-shared/store/payments/paymentNotes/selectors'

export const mapStateToProps = (state: Object) => {
  return {
    confirmationId: getConfirmationId(state),
    summary: getSummaryData(state),
    levelCreditReportingEnabled: isLevelCreditReportingEnabled(state),
    levelCreditActive: getLevelCreditActive(state),
    levelCreditInactive: getLevelCreditInactive(state),
    paymentNotes: getPaymentNotes(state)
  }
}

export default connect(mapStateToProps)(PaymentSuccess)
