import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import AppContainer from './AppContainer'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  getUnitName,
  getUnitProperty
} from 'zego-shared/store/select/selectors'
import {
  getUserName,
  getUserProfilePicture,
  getUserId,
  getIsSsoPayments
} from 'zego-shared/store/authorization/selectors'
import {
  hasPayment,
  hasWorkOrder,
  nativePayer,
  nativePayerWithoutPayment,
  hasStandaloneWorkOrder,
  marketplaceEnabled,
  isPaymentOnly
} from 'zego-shared/store/settings/selectors'
import { fetchSSOPayment } from 'zego-shared/store/payments/ssoPayment/actions'
import { getSSOPaymentLink } from 'zego-shared/store/payments/ssoPayment/selectors'
import { fetchPaymentsSummaryAction } from 'zego-shared/store/payments/summary/actions'
import { getShowTimeOutComp } from 'zego-shared/store/loader/selector'
import { acceptTermsAndConditionsAction } from 'zego-shared/store/termsAndConditions/actions'
import {
  getHasAcceptedTAndC,
  getLatestTAndC
} from 'zego-shared/store/termsAndConditions/selectors'
import { getIsAdminLogin } from 'zego-shared/store/authorization/selectors'
import { fetchTermsAndConditionsAction } from 'zego-shared/store/termsAndConditions/actions'
import { getSurvey } from 'zego-shared/store/surveys/selectors'
import { fetchSurveyAction } from 'zego-shared/store/surveys/actions'
import { fetchLinkedAccountsAction } from 'zego-shared/store/payments/linkedAccounts/actions'
import { getLinkedAccounts } from 'zego-shared/store/payments/linkedAccounts/selectors'
import { ldInitRequest } from 'zego-shared/store/integrations/launchDarkly/actions'
import {
  getDisableMyProfileMenuTab,
  getDisableSummaryMenuTab,
  getDisableHelpMenuTab,
  getDisableLogOutMenuTab
} from 'zego-shared/store/pmSettings/selectors'

const mapStateToProps = state => ({
  userId: getUserId(state),
  propertyName: getUnitProperty(state),
  residentName: getUserName(state),
  unitName: getUnitName(state),
  profilePicture: getUserProfilePicture(state),
  isSsoPaymentsTheme: getIsSsoPayments(state),
  hasWorkOrder: hasWorkOrder(state),
  hasStandaloneWorkOrder: hasStandaloneWorkOrder(state),
  marketplaceEnabled: marketplaceEnabled(state),
  nativePayer: nativePayer(state),
  nativePayerWithoutPayment: nativePayerWithoutPayment(state),
  hasPayment: hasPayment(state),
  paymentLink: getSSOPaymentLink(state),
  showErrorModal: getShowTimeOutComp(state),
  hasAcceptedTAndC: getHasAcceptedTAndC(state),
  isAdminLogin: getIsAdminLogin(state),
  termsAndConditionsLink: getLatestTAndC(state).url,
  isPaymentOnlyExperience: isPaymentOnly(state),
  survey: getSurvey(state),
  linkedAccounts: getLinkedAccounts(state),
  disableMyProfileMenuTab: getDisableMyProfileMenuTab(state),
  disableHelpMenuTab: getDisableHelpMenuTab(state),
  disableSummaryMenuTab: getDisableSummaryMenuTab(state),
  disableLogOutMenuTab: getDisableLogOutMenuTab(state)
})

const mapDispatchToProps = dispatch => {
  return {
    ldInitRequest,
    fetchData: (hasPayment, nativePayer) => {
      dispatch(fetchSurveyAction())
      dispatch(fetchTermsAndConditionsAction())
      if (hasPayment) {
        if (nativePayer) {
          dispatch(fetchPaymentsSummaryAction())
        } else {
          dispatch(fetchSSOPayment())
        }
      }
      dispatch(fetchLinkedAccountsAction())
    },
    acceptTermsAndConditions: (hasAcceptedTAndC: boolean) => {
      if (!hasAcceptedTAndC) {
        dispatch(acceptTermsAndConditionsAction())
      }
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    fetchData() {
      dispatchProps.fetchData(stateProps.hasPayment, stateProps.nativePayer)
    }
  })
)(withRouter(withFetchData(AppContainer)))
