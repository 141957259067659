import styled from '@emotion/styled'
import FlexCommon from '@Common/Flex'
import Button from '@Common/Button'
import { TextInput } from '@Common/Form'
import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'

export const Bio = styled('div')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  line-height: 24px;
  overflow-wrap: break-word;
  margin-bottom: ${({ theme }) => (theme.ux.isSSO ? '20px' : '42px')};

  &:empty {
    display: none;
  }

  & a {
    color: ${({ theme }) => theme.palette.secondary.dark};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    text-decoration-line: underline;
  }
`
export const BioCaption = styled('span')`
  color: ${({ theme }) => theme.palette.neutral.deep};
  margin-right: 8px;
`

export const Contact = styled(FlexCommon)`
  padding-bottom: ${({ theme }) => `${theme.space.s20}`};
`

export const ContactLabel = styled(FlexCommon)`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.neutral.deep};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
`

export const ContactText = styled('span')`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  color: ${({ theme }) => theme.palette.common.midnight};
  text-transform: ${({ capitalize }) =>
    capitalize ? 'capitalize' : 'lowercase'};
`

export const StyledButton = styled(Button)`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '264px')};

  margin-bottom: ${({ theme }) => (theme.ux.isSSO ? '0px' : `inherit`)};

  & button {
    width: ${({ theme }) => (theme.ux.isSSO ? '98%' : 'inherit')};
    margin: ${({ theme }) => (theme.ux.isSSO ? 'unset' : 'inherit')};
    position: ${({ theme }) => (theme.ux.isSSO ? 'relative' : 'inherit')};
    left: ${({ theme }) => (theme.ux.isSSO ? '-23px' : 'inherit')};
    top: ${({ theme }) => (theme.ux.isSSO ? '-12px' : 'inherit')};
  }

  & button#editBtn {
    width: ${({ theme }) => (theme.ux.isSSO ? '50% !important' : 'inherit')};
    margin: ${({ theme }) => (theme.ux.isSSO ? 'unset' : 'inherit')};
    top: ${({ theme }) => (theme.ux.isSSO ? '-30px' : 'inherit')};
  }

  & button#editReminderBtn {
    top: ${({ theme }) => (theme.ux.isSSO ? '-30px' : 'inherit')};
  }
`

export const InfoWrapper = styled('div')`
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    max-width: 460px;
    margin: auto;

    & #userBio {
      text-align: left;
    }
  }
`

export const ContactInfo = styled(FlexCommon)`
  width: fit-content;
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin: auto;
  }
  width: ${({ theme }) => (theme.ux.isSSO ? '430px' : 'inherit')};
`

export const StyledTextInput = styled(TextInput)`
  height: ${({ multiline }) => (multiline ? '75px' : '40px')};
  font-size: ${({ theme }) => theme.custom.font.size.H6};

  & label {
    margin: ${({ theme }) => `-38px 0 0 -${theme.space.s15}`};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    color: ${({ theme }) => theme.palette.common.grey};
    z-index: ${({ theme }) => (theme.ux.isSSO ? 'inherit' : '-1')};
  }
`

export const InputLabel = styled('label')`
  margin: ${({ theme }) => `-${theme.space.s20} 0 0 0`};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  color: ${({ theme }) => theme.palette.common.grey};
  display: block;
`

export const Flex = styled(FlexCommon)`
  & > *:nth-of-type(even) {
    margin-left: ${({ theme }) =>
      theme.ux.isSSO ? '10px' : `${theme.space.s30}`};
  }
`

export const DeleteAccount = styled(FlexCommon)`
  text-align: right;
`

export const DeleteAccountText = styled(FlexCommon)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  margin-right: ${({ theme }) => theme.space.s15};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

export const FooterContainer = styled(FlexCommon)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  justify-content: center;
`

export const StyledWarningIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export const FooterText = styled(Typography)`
  font-size: 12px;
  line-height: 20px;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`

export const InfoContainer = styled('div')`
  height: ${({ theme }) => (theme.ux.isSSO ? '280px' : 'initial')};
  overflow: auto;
`

export const SectionTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.space.s20};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H4};
`
