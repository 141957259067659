import { createFetchActions } from '../../helpers/actions/creators'

export const USER_SETTINGS_FETCH_SECTION = 'USERSETTINGS'

export const {
  fetchAction: fetchActionUserSettings,
  fetchStartAction: fetchUserSettingsStart,
  fetchSuccessAction: fetchUserSettingsSuccess,
  fetchErrorAction: fetchUserSettingsError
} = createFetchActions(USER_SETTINGS_FETCH_SECTION)

export const fetchUserSettings = fetchActionUserSettings
