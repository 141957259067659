import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Arrow } from '@icons'
import {
  DefaultInstitutionLogo,
  StyledInstitutionAccountsCard,
  InstitutionAccountsHeader,
  InstitutionAccountsContent,
  StyledPlaidAccount
} from './styles'
import '@images/svg/default_institution_logo.svg'

const PlaidAccount = ({ id, type, lastFour, onSelectAccount }) => {
  return (
    <StyledPlaidAccount>
      <Box>
        <Typography display="inline">{String(type).toLowerCase()}</Typography>
        <span>-</span>
        <Typography display="inline">{lastFour}</Typography>
      </Box>
      {'function' === typeof onSelectAccount && (
        <Box className="select-for-payment" onClick={() => onSelectAccount(id)}>
          <Typography display="inline">Select for Payment</Typography>
          <span>
            <Arrow id="paymentArrow" name="arrow" width="12px" height="12px" />
          </span>
        </Box>
      )}
    </StyledPlaidAccount>
  )
}

const InstitutionAccountsCard = ({
  institutionName,
  accounts = [],
  onSelectAccount
}) => {
  accounts.sort((a, b) => String(a.type).localeCompare(b.type))

  return (
    <StyledInstitutionAccountsCard>
      <InstitutionAccountsHeader
        avatar={<DefaultInstitutionLogo name="default_institution_logo" />}
        title={institutionName}
      />
      <InstitutionAccountsContent>
        {accounts.map(({ id, type, lastFour }) => (
          <PlaidAccount
            key={id}
            id={id}
            type={type}
            lastFour={lastFour}
            onSelectAccount={onSelectAccount}
          />
        ))}
      </InstitutionAccountsContent>
    </StyledInstitutionAccountsCard>
  )
}

export default InstitutionAccountsCard
