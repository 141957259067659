// @flow
export function getBankAccountData(metadata: Object, bankAccountInfo: Object): void {
    const {
      account_id: accountId,
      link_session_id: linkSessionId,
      institution: { name: bankName, institution_id: institutionId },
      accounts: [{name: accountName, mask: accountMask}],
    } = metadata
    bankAccountInfo.current = {
      ...bankAccountInfo.current,
      payload: {
        accountId,
        bankName,
        linkSessionId,
        institutionId,
        accountName,
        accountMask,
      }
    }
  }

export function accountsMatch(
    {institutionId: institutionId1, name: accountName1, mask: mask1}: {institutionId: string, name: string, mask: string},
    {institutionId: institutionId2, name: accountName2, mask: mask2}: {institutionId: string, name: string, mask: string},
  ): boolean {
    return institutionId1 === institutionId2 && accountName1 === accountName2 && mask1 === mask2;
}
