// @flow
import React from 'react'
import MaskedInput from 'react-input-mask'

import { DATE_FORMAT } from './schema'

type Props = {
  inputRef: Function
}

const DateMask = (props: Props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={DATE_FORMAT}
      alwaysShowMask={false}
      maskChar="_"
      formatChars={{
        M: '[0-9]',
        D: '[0-9]',
        Y: '[0-9]'
      }}
    />
  )
}

export default DateMask
