// @flow

import React from 'react'
import { Route } from 'react-router-dom'

import CreditReportingView from './CreditReportingView'
import LevelCreditForm from './LevelCredit/LevelCreditForm'
import LookBackView from './LevelCredit/LookBackView'
import MyDetails from './LevelCredit/MyDetails'
import Purchase from './Lookback'

type Props = {
  match: {
    path: string
  }
}
const LevelCreditSignup = props => <LevelCreditForm {...props} isNew />

const CreditReporting = (props: Props) => {
  const { match } = props
  const mkPath = pth => `${match.path}/${pth}`
  return (
    <>
      <Route path={mkPath('lookback/purchase')} exact component={Purchase} />
      <Route path={mkPath('signup')} exact component={LevelCreditSignup} />
      <Route path={mkPath('my-details')} exact component={MyDetails} />
      <Route path={mkPath('lookback')} exact component={LookBackView} />
      <Route path={match.path} exact component={CreditReportingView} />
    </>
  )
}

export default CreditReporting
