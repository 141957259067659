// @flow
import React from 'react'
import { Moment } from 'moment'
import { DateInput, FormGroup, Select, TextInput } from '@Common/Form'
import {
  countries,
  buildRegionsOptions,
  getErrorInfo,
  getHasError
} from '@utils/view'
import { formatDateMMYY } from 'zego-shared/utils/date'

import { CardInfoStyled, ContactInfoStyled, FormStyled } from './styles'
import { EXPIRATION_FORMAT } from './schemas'
import ExpDateMask from '@Common/ExpDateMask'

type Props = {
  countries: any,
  states: any,
  handleChange: Function,
  handleBlur: Function,
  handleFocus: Function,
  setFieldValue: Function,
  errors: Object,
  touched: Object,
  values: Object,
  setInternational: Function
}

export const handleDateChange = (onChangeCb: Function) => (
  date: Moment,
  name: string
) => {
  const eventObject = {
    target: {
      value: date,
      name
    }
  }
  onChangeCb(eventObject)
}

export const getHandleCountryChange = (
  setCurrentCountry: Function,
  handleChange: (event: SyntheticInputEvent<>) => void,
  setFieldValue: (string, string) => void
) => (event: SyntheticInputEvent<>) => {
  const { value } = event.target
  setCurrentCountry(value)
  handleChange(event)
  setFieldValue('state', '')
}

const CreditCardForm = ({
  handleChange,
  errors = {},
  states,
  touched,
  handleBlur,
  handleFocus,
  setFieldValue,
  values,
  setInternational
}: Props) => {
  const [currentCountry, setCurrentCountry] = React.useState(values['country'])
  const handleCountryChange = getHandleCountryChange(
    setCurrentCountry,
    handleChange,
    setFieldValue
  )

  const hasError: Function = getHasError(errors, touched)
  const errorDetails: Function = getErrorInfo(errors, touched)
  const getNamedProps = (name: string) => {
    return {
      error: hasError(name),
      value: values[name],
      initialValue: values[name],
      name
    }
  }

  const isInternational = (currentCountry: string) => {
    const isInternational = currentCountry !== 'US'
    setInternational(isInternational)
    return isInternational
  }

  return (
    <FormStyled>
      <CardInfoStyled>
        <FormGroup label="Card Number" {...errorDetails('cardNumber')}>
          <TextInput
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            autocomplete="cc-number"
            {...getNamedProps('cardNumber')}
          />
        </FormGroup>
        <FormGroup label="Expiration" {...errorDetails('expiration')}>
          <DateInput
            name="expiration"
            width="232px"
            showSelectedDate={false}
            formatter={formatDateMMYY}
            readOnly={false}
            format={EXPIRATION_FORMAT}
            CalendarProps={{
              minPanel: 'month'
            }}
            InputProps={{
              error: hasError('expiration'),
              onFocus: handleFocus,
              onBlur: handleBlur,
              placeholder: EXPIRATION_FORMAT,
              name: 'expiration',
              autocomplete: 'cc-exp',
              inputComponent: ExpDateMask
            }}
            onChange={handleDateChange(handleChange)}
            initialDate={values['expiration']}
          />
        </FormGroup>
        <FormGroup label="CVV" {...errorDetails('cvv')}>
          <TextInput
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            autocomplete="cc-csc"
            {...getNamedProps('cvv')}
          />
        </FormGroup>
        <FormGroup label="Name on Card" {...errorDetails('name')}>
          <TextInput
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            autocomplete="cc-name"
            {...getNamedProps('name')}
          />
        </FormGroup>
      </CardInfoStyled>
      <ContactInfoStyled columnStart={7} columnSpan={12}>
        <FormGroup label="Address" {...errorDetails('address')}>
          <TextInput
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            autocomplete="address-line1"
            {...getNamedProps('address')}
          />
        </FormGroup>
        <FormGroup label="Address 2">
          <TextInput
            onChange={handleChange}
            autocomplete="address-line2"
            {...getNamedProps('address2')}
          />
        </FormGroup>
        <FormGroup label="Country" {...errorDetails('country')}>
          <Select
            options={countries}
            onChange={handleCountryChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            autocomplete="country-name"
            {...getNamedProps('country')}
          />
        </FormGroup>
        <FormGroup label="City" {...errorDetails('city')}>
          <TextInput
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            autocomplete="address-level12"
            {...getNamedProps('city')}
          />
        </FormGroup>
        {!isInternational(currentCountry) && (
          <FormGroup label="State" {...errorDetails('state')}>
            <Select
              options={buildRegionsOptions(currentCountry, true)}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              autocomplete="address-level11"
              {...getNamedProps('state')}
            />
          </FormGroup>
        )}
        {!isInternational(currentCountry) && (
          <FormGroup label="Zip" {...errorDetails('zipCode')}>
            <TextInput
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              autocomplete="postal-code"
              {...getNamedProps('zipCode')}
            />
          </FormGroup>
        )}
      </ContactInfoStyled>
    </FormStyled>
  )
}

export default CreditCardForm
