// @flow

import React, { useState } from 'react'
import {
  FilterChipSectionLabel,
  FilterChipWrapper,
  ClearFilterChip,
  FilterChipDivider,
  FilterChip
} from './styles'

type Props = {
  theme: { [key: string]: any},
  filters: { [key: string]: any},
  filtersSelected: { [key: string]: any},
  setFiltersSelected: (newFiltersSelected: { [key: string]: any}) => void
}

/*
  __PROPS GUIDE__
  filters prop should be structured in the following way:
  const filters = {
    'myCustomFilterType1': ['filter chip 1 display text', 'filter chip 2 display text'],
    'anotherTypeIfYouWant': ['another filter', 'yay filters', 'as many as i want', 'The sixth chip in this section']
  }

  filtersSelected should be structured the same as the filters, but with only the filters selected included in the values.
  You still need to include all type keys no matter what. In this example the user has selected two of the filter chips.
  const filtersSelected = {
    'myCustomFilterType1': [],
    'anotherTypeIfYouWant': ['another filter', 'The sixth chip in this section']
  }

  setFiltersSelected should not only set the filtersSelected property, but also call logic for applying the filters to the page.
  See '../Marketplace/Marketplace.js' for an example where filtersSelected also increments the key for
  the section to be filtered so that the items to display in that section are recalculated.
*/
const FilterChipSection = (props: Props) => {
  const {
    theme,
    filters,
    filtersSelected,
    setFiltersSelected
  } = props;

  const [key, setKey] = useState(0)

  const incrementKey = () => {
    setKey(key + 1)
  }

  const filterChips = () => {
    const elements = [
      <ClearFilterChip
        theme={theme}
        label="clear filters"
        key="clear filters"
        onClick={() => {
          clearFilters(filters, setFiltersSelected, incrementKey)
        }} />,
      <FilterChipDivider orientation="vertical" key="filter chip divider" />
    ]

    for (const filterType: string in filters) {
      const filterValues = filters[filterType] || []
      const selectedFilterValues = filtersSelected[filterType] || []

      for (const filterValuesIndex in filterValues) {
        const filterValue = filterValues[filterValuesIndex]
        const color = selectedFilterValues.includes(filterValue) ? "secondary" : "primary"

        elements.push(
          <FilterChip
            theme={theme}
            label={filterValue}
            key={filterValue}
            color={color}
            onClick={() => {
              handleChipClick(filterType, filterValue, filtersSelected, setFiltersSelected, incrementKey)
            }} />
        )
      }
    }

    return <FilterChipWrapper key={key}>{elements}</FilterChipWrapper>
  }

  return (
    <>
      <FilterChipSectionLabel>Filter by Category</FilterChipSectionLabel>
      {filterChips()}
    </>
  )
}

export const handleChipClick = (
  filterType: string,
  filterValue: string,
  filtersSelected: { [key: string]: any},
  setFiltersSelected: (newFiltersSelected: { [key: string]: any}) => void,
  incrementKey: () => void) => {
  let selectedFilterValues = filtersSelected[filterType] || []

  selectedFilterValues = selectedFilterValues.includes(filterValue)
    ? selectedFilterValues.filter(x => x !== filterValue) // toggle off
    : [...selectedFilterValues, filterValue] // toggle on

  const newFiltersSelected = filtersSelected
  newFiltersSelected[filterType] = selectedFilterValues

  setFiltersSelected(newFiltersSelected)
  incrementKey()
}

export const clearFilters = (
  filters: { [key: string]: any},
  setFiltersSelected: (newFiltersSelected: { [key: string]: any}) => void,
  incrementKey: () => void) => {
  const newFiltersSelected = {}

  for (const filterType of Object.keys(filters)) {
    newFiltersSelected[filterType] = []
  }

  setFiltersSelected(newFiltersSelected)
  incrementKey()
}

export default FilterChipSection