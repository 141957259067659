import { combineReducers } from 'redux'
import ssoPayment from './ssoPayment/reducers'
import balance from './balance/reducers'
import history from './history/reducers'
import methods, { method } from './methods/reducers'
import options from './options/reducers'
import fees from './fees/reducers'
import oneTimePayment from './oneTimePayment/reducers'
import autoPay from './autopay/reducers'
import statements from './statements/reducers'
import paymentsSummary from './summary/reducers'
import creditReporting from './creditReporting/reducers'
import plaid from './plaid/reducers'
import linkedAccounts from './linkedAccounts/reducers'
import paymentNotes from './paymentNotes/reducers'
import clientNotes from './clientNotes/reducers'
import paymentReminders from './paymentReminders/reducers'

const payments = combineReducers({
  ssoPayment,
  balance,
  history,
  methods,
  method,
  options,
  fees,
  statements,
  oneTimePayment,
  autoPay,
  paymentsSummary,
  creditReporting,
  plaid,
  linkedAccounts,
  paymentNotes,
  clientNotes,
  paymentReminders
})

export default payments
