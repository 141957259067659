import styled from '@emotion/styled'
import { css } from '@emotion/core'
import IconButton from '@material-ui/core/IconButton'
import Card from '@Common/Card'
import Flex from '@Common/Flex'
import { convertHexToRGB } from '@utils/view'
import Button from '@Common/Button'

export const H4Styled = styled.h4`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  line-height: 22px;
  margin: 0;
  margin-bottom: 12px;
`

export const PStyled = styled.p`
  color: ${({ theme, dark }) =>
    dark ? theme.palette.common.midnight : theme.palette.neutral.deep};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  line-height: 19px;
  margin: 0;
  word-wrap: break-word;
`

export const FlexStyled = styled(Flex)`
  ${({ bottomMargin }) => bottomMargin && 'margin-bottom: 30px'}
`

export const PaymentInfoCardStyled = styled(Card)`
  padding-bottom: ${({ theme }) => `calc(${theme.space.s15} - 5px)`};
`

export const RecipientInfoCardStyled = styled(Card)`
  padding-bottom: 18px;
`

export const TotalStyled = styled(Flex)`
  border-top: ${`1px solid ${convertHexToRGB('979797', 0.25)}`};
  margin-top: 35px;
  padding-top: ${({ theme }) => theme.space.s15};
  & p {
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    flex: 1;
  }

  & p:last-of-type {
    line-height: 1;
    text-align: right;
    font-size: ${({ theme }) => theme.custom.font.size.H5};
  }
`

export const IconButtonStyled = styled(IconButton)`
  box-sizing: border-box;
  height: 32px;
  width: 32px;
  border: ${({ theme }) => `2px solid ${theme.palette.neutral.deep}`};
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  margin-left: 17px;

  & svg {
    height: 16px;
    width: 16px;
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    color: ${({ theme }) => theme.palette.neutral.deep};
  }
`

export const PaymentsBlockedText = styled('div')`
  height: 64px;
  width: 698px;
  margin-right: ${({ theme }) => theme.space.s25};
  text-align: center;
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
`

export const ReturnButton = styled(Button)`
  height: 35px;
  width: ${({ width }) => width};
  background-color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
`

export const StyledMobileButtons = styled(Flex)`
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    flex-direction: column-reverse;
    justify-content: space-between;
    min-height: 100px;
  }
`

export const PaymentInfoStyled = styled.div`
  width: ${({ oneColumn, theme }) =>
    oneColumn && (theme.ux.isSSO ? '100%' : '90%')};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: ${({ oneColumn }) => oneColumn && '100%'};
  }
  display: ${({ oneColumn }) => !oneColumn && 'grid'};
  grid-template-columns: ${({ oneColumn, theme }) =>
    !oneColumn && theme.ux.isSSO
      ? '245px minmax(230px, 1fr)'
      : '200px minmax(230px, 1fr)'};
  grid-gap: ${({ oneColumn, theme }) => !oneColumn && theme.space.s30};
  ${({ oneColumn, theme }) => !oneColumn && [theme.breakpoints.down('xs')]} {
    grid-template-columns: 1fr;
    grid-gap: ${({ theme }) => theme.space.s15};
  }
`

export const buttonWrapperStyledSsoStyles = css`
  position: fixed;
  bottom: 0;
  left: 15px;
  right: 15px;
  margin: 0;
  background-color: white;
`

export const PaymentNotes = styled(TotalStyled)`
  & p {
    font-size: ${({ theme }) => theme.custom.font.size.md} !important;
    margin: 0 0 0 13px;
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    flex: initial !important;
    text-align: left !important;
  }
`
