// @flow

import React from 'react'
import type { Node } from 'react'
import Snackbar from '@Common/Snackbar'
import ScrollToTop from '@Common/ScrollToTop'
import SubNav from '@Common/SubNav'
import Hidden from '@material-ui/core/Hidden'
import { SCROLL_ELEMENT_ID } from '../Common/ScrollOnClick'
import TandCAlertModal from '@Common/AlertModal/TandCAlertModal'
import BackgroundProvider, {
  BackgroundContext
} from '../../contexts/background'
import { Container } from '@material-ui/core'
import DownloadAppBanner from './DownloadAppBanner'
import MobileButtons from '@Common/MobileButtons'
import ShowOnMedia from '@Common/ShowOnMedia'
import { ErrorAlertModalContainer } from '../Common/ErrorAlertModal'
import SurveyNotification from '@Common/SurveyNotification'
import withWidth from '@material-ui/core/withWidth'
import ClientNotes from '../Payments/common/ClientNotes'
import AdminLogAsVisibility from '../Common/AdminLogAsVisibility'
import { WORK_ORDER_FEATURE_FLAG } from 'zego-shared/store/workOrders/constants'
import { MARKETPLACE_FEATURE_FLAG } from 'zego-shared/store/marketplace/constants'
import { UseFeatureFlagComponent } from '../../hooks/useFeatureFlag/index'

import { Content, AppBarStyled, ContentBg, TermsLink } from './styles'

type Props = {
  children: Node,
  leftImageSrc?: string,
  rightImageSrc?: string,
  fullImageSrc?: string,
  isFetching: boolean,
  hasData: boolean,
  history: string[],
  propertyName: string,
  residentName: string,
  profilePicture: string,
  unitName: string,
  isPaymentOnlyExperience: boolean,
  isSsoPaymentsTheme: boolean,
  hasWorkOrder: boolean,
  hasStandaloneWorkOrder: boolean,
  marketplaceEnabled: boolean,
  hasPayment: boolean,
  nativePayer: boolean,
  paymentLink: string,
  match: { url: string, params: { propertyId: string, unitId: string } },
  showErrorModal: boolean,
  isAdminLogin: boolean,
  termsAndConditionsLink: string,
  showLevelCreditMigModal: boolean,
  survey: Object,
  userId: string,
  linkedAccounts: Object[],
  ldInitRequest: Function,
  disableMyProfileMenuTab: boolean,
  disableHelpMenuTab: boolean,
  disableSummaryMenuTab: boolean,
  disableLogOutMenuTab: boolean
}

type State = {
  mobileBtnsReady: boolean,
  isAcceptingTerms: boolean,
  showWorkOrderFF: boolean,
  showMarketplaceFF: boolean
}

class AppContainer extends React.Component<Props, State> {
  state = {
    mobileBtnsReady: false,
    isAcceptingTerms: false,
    showWorkOrderFF: false,
    showMarketplaceFF: false
  }

  componentWillMount() {
    const {
      match: { url },
      history,
      disableSummaryMenuTab,
      userId
    } = this.props

    if (this.shouldRedirectToPayments())
      return this.redirectIfNativePayment(url, history, disableSummaryMenuTab, userId)
  }

  /**
   * Checks to see whether we should redirect to Payments pages only based off the state.
   */
  shouldRedirectToPayments() {
    const {
      nativePayer,
      isPaymentOnlyExperience,
      hasWorkOrder,
      hasStandaloneWorkOrder,
      marketplaceEnabled
    } = this.props

    const { showWorkOrderFF, showMarketplaceFF } = this.state

    const showPayments = nativePayer || isPaymentOnlyExperience
    const showWorkOrder =
      hasWorkOrder || (hasStandaloneWorkOrder && showWorkOrderFF)
    const showMarketplace = marketplaceEnabled && showMarketplaceFF

    return showPayments && !showWorkOrder && !showMarketplace
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  setMobileBtnsReady = () => {
    this.setState({ mobileBtnsReady: true })
  }

  redirectIfNativePayment = (url: string, history: Object, disableSummaryMenuTab: boolean, userId: string) => {
    const splitUrl = url.split('/')

    if (disableSummaryMenuTab) {
      return history.push(`/payments/${userId}/one-time`)
    }

    if (!['payments'].includes(splitUrl[1])) {
      if (['my-account'].includes(splitUrl[1])) {
        return splitUrl.length > 2 ? history.push('/payments/summary') : ''
      }
      if (['lookback'].includes(splitUrl[1])) return null
      return history.push('/payments/summary')
    }
  }

  isMyAccountPage = () => {
    const {
      match: { url }
    } = this.props
    const splitUrl = url.split('/')

    return ['my-account'].includes(splitUrl[1])
  }

  hideSubNav = () => {
    const isMyAccount = this.isMyAccountPage()
    if (this.shouldRedirectToPayments() || isMyAccount) return true
  }

  hideNavBar = () => {
    const isMyAccount = this.isMyAccountPage()
    if (isMyAccount) return true
  }

  getTandCModalMsg = () => {
    const { termsAndConditionsLink } = this.props
    return (
      <div>
        We have updated our{' '}
        <TermsLink target="_blank" href={termsAndConditionsLink}>
          Terms of Use
        </TermsLink>
        {'. '}
        By continuing to use our services and applications you agree to these
        updated terms.
      </div>
    )
  }

  acceptTerms = () => {
    const { acceptTermsAndConditions, hasAcceptedTAndC } = this.props
    this.setState({ isAcceptingTerms: true })
    acceptTermsAndConditions(hasAcceptedTAndC)
  }

  render() {
    const {
      children,
      rightImageSrc,
      leftImageSrc,
      propertyName,
      residentName,
      profilePicture,
      fullImageSrc,
      unitName,
      match: { url },
      isPaymentOnlyExperience,
      isSsoPaymentsTheme,
      hasWorkOrder,
      hasStandaloneWorkOrder,
      hasPayment,
      nativePayer,
      paymentLink,
      showErrorModal,
      hasAcceptedTAndC,
      isAdminLogin,
      survey,
      width,
      userId,
      linkedAccounts,
      ldInitRequest,
      disableMyProfileMenuTab,
      disableHelpMenuTab,
      disableLogOutMenuTab
    } = this.props
    const { mobileBtnsReady, isAcceptingTerms } = this.state
    const APP_BAR_HEIGHT = isSsoPaymentsTheme ? 55 : 65
    const paymentLinkURL = nativePayer
      ? '/payments/summary'
      : paymentLink || '#'
    const hideSubNav = this.hideSubNav() || isSsoPaymentsTheme
    const hideNavBar = this.hideNavBar()

    const showNotification = !survey.surveyDismissed && !survey.surveyResponded

    const setShowWorkOrderFF = value => {
      this.setState({
        showWorkOrderFF: value
      })
    }

    const setShowMarketplaceFF = value => {
      this.setState({
        showMarketplaceFF: value
      })
    }

    return (
      <BackgroundProvider>
        <BackgroundContext.Consumer>
          {context => (
            <div>
              {showErrorModal && <ErrorAlertModalContainer />}
              {hasAcceptedTAndC === false && !isAdminLogin ? (
                <TandCAlertModal
                  show={!hasAcceptedTAndC}
                  title="ZEGO has updated our Terms of Use"
                  message={this.getTandCModalMsg}
                  button={[
                    {
                      onClick: this.acceptTerms,
                      primary: true,
                      text: 'Okay, got it!',
                      animate: isAcceptingTerms,
                      disabled: isAcceptingTerms
                    }
                  ]}
                />
              ) : (
                <React.Fragment>
                  {showNotification ? (
                    <SurveyNotification />
                  ) : (
                    <ShowOnMedia media="screen">
                      <DownloadAppBanner />
                    </ShowOnMedia>
                  )}
                  <AdminLogAsVisibility>
                    <div id={SCROLL_ELEMENT_ID}>
                      <ClientNotes />
                    </div>
                  </AdminLogAsVisibility>
                  <AppBarStyled
                    propertyName={propertyName}
                    residentName={residentName}
                    unitName={unitName}
                    profilePicture={profilePicture}
                    height={APP_BAR_HEIGHT}
                    showBoxShadow={
                      isPaymentOnlyExperience && !this.isMyAccountPage()
                    }
                    hidePropertyLink={isPaymentOnlyExperience}
                    disableMyProfileMenuTab={disableMyProfileMenuTab}
                    disableHelpMenuTab={disableHelpMenuTab}
                    disableLogOutMenuTab={disableLogOutMenuTab}
                    isMyAccountPage={this.isMyAccountPage()}
                    isStaticAppBar={hideSubNav}
                    hideNavBar={hideNavBar}
                    userId={userId}
                    linkedAccounts={linkedAccounts}
                  />
                  {!hideSubNav && (
                    <SubNav
                      top={APP_BAR_HEIGHT}
                      currentURl={url}
                      hasWorkOrder={hasWorkOrder}
                      hasStandaloneWorkOrder={hasStandaloneWorkOrder}
                      paymentLink={paymentLinkURL}
                      nativePayer={nativePayer}
                      hasPayment={hasPayment}
                    />
                  )}
                  <Content id="content">
                    <div id={SCROLL_ELEMENT_ID}>
                      <UseFeatureFlagComponent
                        setFlagValue={setShowWorkOrderFF}
                        flagName={WORK_ORDER_FEATURE_FLAG}
                        defaultValue={false}
                        ldInitRequestProp={ldInitRequest}
                      />
                      <UseFeatureFlagComponent
                        setFlagValue={setShowMarketplaceFF}
                        flagName={MARKETPLACE_FEATURE_FLAG}
                        defaultValue={false}
                        ldInitRequestProp={ldInitRequest}
                      />
                      <ContentBg
                        rightImageSrc={context.rightImageSrc || rightImageSrc}
                        leftImageSrc={
                          width !== 'xs' &&
                          (context.leftImageSrc || leftImageSrc)
                        }
                        fullImageSrc={context.fullImageSrc || fullImageSrc}>
                        <Container>{mobileBtnsReady && children}</Container>
                      </ContentBg>
                      <MobileButtons onMount={this.setMobileBtnsReady} />
                    </div>
                    <Hidden smDown>
                      <ScrollToTop />
                    </Hidden>
                  </Content>
                  <Snackbar />
                </React.Fragment>
              )}
            </div>
          )}
        </BackgroundContext.Consumer>
      </BackgroundProvider>
    )
  }
}

export default withWidth()(AppContainer)
