// @flow

import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import get from 'lodash/get'
import Loader from '@Common/Loader'
import Flex from '@Common/Flex'
import ShowOnMedia from '@Common/ShowOnMedia'
import { Arrow, PrinterIcon } from '@icons'
import { Grid } from '@material-ui/core'
import type { Theme } from '../../../theme'
import {
  SuccessHeaderStyled,
  ConfirmationStyled,
  TransactionDetails,
  HistoryDetailsWrap,
  PrinterIconButton,
  BackArrowButton,
  Divider,
  ReturnStyle
} from './styles'
import {
  PaymentInfoCardStyled,
  RecipientInfoCardStyled,
  PStyled,
  H4Styled,
  FlexStyled,
  TotalStyled,
  PaymentNotes
} from '../styles'
import SendPaymentReceiptButton from '../common/SendPaymentReceiptButton'

type Option = {
  value: string,
  label: string
}
type Props = {
  residentId: string,
  recipient: {
    propertyName: string,
    address: string,
    companyName: string
  },
  residentName: string,
  transaction: {
    transId: number,
    totalAmount: string,
    feeAmount: string,
    isExpressPay: boolean,
    expressPayFeeAmount: string,
    status: string,
    transactionDate: string,
    extendedTransactionDate: string,
    type: string,
    paymentMethodName: string,
    paymentFields: Option[],
    paymentType: string,
    returnReason: string,
    notes: string
  },
  isFetching: boolean,
  hasHistory: boolean,
  theme: Theme
}

const PaymentItem = ({ label, value }) => {
  return (
    <FlexStyled justifySpaceBetween>
      <PStyled>{label}</PStyled>
      <PStyled dark>{value}</PStyled>
    </FlexStyled>
  )
}

const Transaction = ({
  recipient,
  residentName,
  transaction,
  isFetching,
  hasHistory,
  residentId,
  theme
}: Props) => {
  const location = useLocation()
  const backLink = get(
    location,
    'state.from',
    `/payments/${residentId}/history`
  )

  if (isFetching) return <Loader />
  if (!hasHistory || !transaction) {
    return (
      <div>
        Transaction not found. Go back to <Link to={backLink}>History</Link>
      </div>
    )
  }

  const {
    transId,
    paymentFields,
    feeAmount,
    isExpressPay,
    expressPayFeeAmount,
    totalAmount,
    status,
    transactionDate,
    extendedTransactionDate,
    type,
    paymentMethodName,
    paymentType,
    returnReason,
    notes
  } = transaction

  const receiptInfo = (
    <>
      <FlexStyled direction="column" bottomMargin>
        <H4Styled>Recipient</H4Styled>
        <PStyled id="propertyName">{recipient.propertyName}</PStyled>
        <PStyled id="propertyAddress">{recipient.address}</PStyled>
        <PStyled id="companyName">{recipient.companyName}</PStyled>
      </FlexStyled>
      <FlexStyled direction="column">
        <H4Styled>Account Info</H4Styled>
        <PStyled id="residentName">{residentName}</PStyled>
        <PStyled id="paymentMethodName">{paymentMethodName}</PStyled>
      </FlexStyled>
    </>
  )

  const paymentInfo = (
    <>
      <FlexStyled justifySpaceBetween>
        <H4Styled>Payment</H4Styled>
        <PStyled id="transactionDate">{extendedTransactionDate}</PStyled>
      </FlexStyled>
      {paymentFields.map(({ label, value }) => {
        return <PaymentItem label={label} value={value} key={label} />
      })}
      {feeAmount && (
        <PaymentItem label={`${paymentType} Fee`} value={feeAmount} />
      )}
      {isExpressPay && expressPayFeeAmount && (
        <PaymentItem label={`Express Fee`} value={expressPayFeeAmount} />
      )}
      <TotalStyled justifySpaceBetween>
        <PStyled dark>Total</PStyled>
        <PStyled id="totalAmount" dark>
          {totalAmount}
        </PStyled>
      </TotalStyled>
      {notes && (
        <PaymentNotes justifySpaceBetween>
          <H4Styled>Notes</H4Styled>
          <PStyled id="paymentNotes">{notes}</PStyled>
        </PaymentNotes>
      )}
    </>
  )

  return (
    <HistoryDetailsWrap>
      <SuccessHeaderStyled alignStretch justifySpaceBetween>
        <Flex direction="column" justifySpaceBetween>
          <TransactionDetails>
            <span>
              {transactionDate} {type} - {status}{' '}
            </span>
          </TransactionDetails>
          <ReturnStyle>
              <span>
                {returnReason && `${returnReason}`}
              </span>
            </ReturnStyle>
          <ConfirmationStyled id="confirmationNumber">
            Confirmation #{transId}
          </ConfirmationStyled>
        </Flex>

        <Flex direction="column" alignEnd>
          <ShowOnMedia media="screen">
            <BackArrowButton background={theme.palette.tertiary.main} component={Link} to={backLink}>
              <Arrow
                id="backArrow"
                name="arrow"
                width="16px"
                height="12px"
                fill={theme.palette.primary.main}
              />
            </BackArrowButton>
          </ShowOnMedia>

          <ShowOnMedia media="screen">
            <SendPaymentReceiptButton transactionId={transId} />
            <PrinterIconButton onClick={window.print}>
              <PrinterIcon name="printer2" width="24px" height="24px" />
            </PrinterIconButton>
          </ShowOnMedia>
        </Flex>
      </SuccessHeaderStyled>

      <ShowOnMedia media="screen">
        <Grid container justify="space-between" spacing={6}>
          <Grid xs={12} md={4} item>
            <RecipientInfoCardStyled>{receiptInfo}</RecipientInfoCardStyled>
          </Grid>

          <Grid xs={12} md={8} item>
            <PaymentInfoCardStyled>{paymentInfo}</PaymentInfoCardStyled>
          </Grid>
        </Grid>
      </ShowOnMedia>

      <ShowOnMedia media="print">
        <RecipientInfoCardStyled>
          {receiptInfo}
          <Divider />
          {paymentInfo}
        </RecipientInfoCardStyled>
      </ShowOnMedia>
    </HistoryDetailsWrap>
  )
}

export default withTheme(Transaction)
