// @flow

import React from 'react'
import BackgroundContext from './context'
import type { Node } from 'react'

type Props = {
  children?: Node
}

type Images = {
  leftImageSrc?: any,
  rightImageSrc?: any,
  fullImageSrc?: any
}

export default class BackgroundProvider extends React.Component<Props, Images> {
  state = {
    leftImageSrc: null,
    rightImageSrc: null,
    fullImageSrc: null
  }

  setBackgroundImages = (images: Images) => {
    this.setState(images)
  }

  render() {
    return (
      <BackgroundContext.Provider
        value={{
          ...this.state,
          setBackgroundImages: this.setBackgroundImages
        }}
      >
        {this.props.children}
      </BackgroundContext.Provider>
    )
  }
}
