// @flow
import styled from '@emotion/styled'

import Flex from '@Common/Flex'
import CurrencyTextInput from '../../../CurrencyTextInput'
import { FormGroup } from '@Common/Form'

export const AmountStyled = styled(Flex)`
  height: 100%;
  min-height: 96px;
  border-left: ${({ theme }) => `1px solid ${theme.palette.neutral.main}`};
  padding-left: 11%;
  flex: 1;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    margin-top: 2em;
    border-left: 0;
    border-top: ${({ theme }) => `1px solid ${theme.palette.neutral.main}`};
    padding-right: 11%;
  }
`

export const FormGroupStyled = styled(FormGroup)`
  width: 100%;

  & input.MuiInputBase-input {
    height: 34.29px;
    width: 262px;
    max-width: 100%;
  }
`

export const CurrencyTextInputStyled = styled(CurrencyTextInput)`
  & > span {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
  }
`
