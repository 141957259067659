import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    '&.MuiPaper-root': { paddingLeft: '2em', paddingRight: '2em' }
  },
  warningIcon: {
    height: '6rem'
  },
  content: {
    fontSize: '1.5em',
    '& p': { marginBottom: '12px' },
    '& strong': { textTransform: 'capitalize' }
  },
  button: {
    flexGrow: 1,
    height: '54px'
  },
  title: {
    "font-weight": "bold",
    "margin-bottom": "10px",
  }
})
