// @flow
import React from 'react'
import { paymentIcons, paymentMethodProps } from '@utils/view/paymentMethods'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import { useTheme } from '@material-ui/core/styles'

import Flex from '../../../Common/Flex'
import Button from '../../../Common/Button'
import {
  AccountNameStyled,
  MethodNameStyled,
  PaymentIconsStyled
} from './styles'
import ViewLink from '../ViewLink'

import type { Account } from './types'

type MethodListItemProps = {
  deleteMethod: (string, string) => void,
  theme: Object
} & Account

const DeleteBtn = withAlertModal(({ showAlertModal }) => (
  <Button link onClick={showAlertModal} disableRipple>
    delete
  </Button>
))
const MethodListItem = ({
  id,
  typeName,
  feeName,
  name,
  account,
  type,
  deleteMethod,
  theme
}: MethodListItemProps) => {
  const { icon } = paymentMethodProps[feeName] || {}
  const size = paymentIcons[icon]

  return (
    <Flex marginBottom={theme.space.s20} alignCenter>
      <PaymentIconsStyled name={icon} {...size} />
      <Flex direction="column" flex={1} marginLeft="3%">
        <AccountNameStyled fullWidth justifySpaceBetween alignCenter>
          <span>
            {name} {account}
          </span>
          <ViewLink id={id} />
        </AccountNameStyled>
        <MethodNameStyled fullWidth justifyLeft alignCenter>
          <span>{type}</span>
          <DeleteBtn
            alertTitle="Are you sure you wish to delete?"
            onAlertClickYes={() => deleteMethod(id, typeName)}
          />
        </MethodNameStyled>
      </Flex>
    </Flex>
  )
}

type Props = {
  accounts: [Account],
  deleteMethod: (id: string, typeName: string) => void
}

const MethodListIMobile = ({ accounts, deleteMethod }: Props) => {
  const theme = useTheme()
  return (
    <Flex direction="column" marginBottom={theme.space.s75}>
      {accounts.map(account => (
        <MethodListItem
          {...account}
          theme={theme}
          key={account.id}
          deleteMethod={deleteMethod}
        />
      ))}
    </Flex>
  )
}

export default MethodListIMobile
