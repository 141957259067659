// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import { Link } from 'react-router-dom'
import PurchaseDesktop from '@images/credit_reporting/loopback_purchase_desktop.png'
import SignUpBG from '@images/credit_reporting/sign_up_bg.png'
import SignUpBGTab from '@images/credit_reporting/sign_up_bg_tab.png'
import SignUpBGMob from '@images/credit_reporting/sign_up_bg_mob.png'
import EnrollTab from '@images/credit_reporting/enroll _tablet.png'
import EnrollMobile from '@images/credit_reporting/enroll_mobile.png'

export const SignUpBtn = styled.div`
  width: 150px;
  height: 50px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  padding:  6px 0;
  margin-top: ${({ theme }) => theme.space.s32};
  z-index: 0;

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    transform: scale(0.607);
    transform-origin: top left; `}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    margin-right: ${({ theme }) => theme.space.s30};
    margin-top: ${({ theme }) => theme.space.s17};
    width: 113px;
    height: 40px;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    margin-right: ${({ theme }) => theme.space.s27};
    margin-top: ${({ theme }) => theme.space.s22};
    width: 103px;
    height: 39px;
  }

  &:before{
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    top: 0;
    left: 0;
    height: 48px;
    width: 0;
    transition: 0.25s width;
    z-index: -1;

    ${({ theme }) => [theme.breakpoints.down('md')]} {
      height: 40px;
    }
    ${({ theme }) => [theme.breakpoints.down('xs')]} {
      height: 39px;
    }
  }

  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background-image: url('${SignUpBG}');
    background-size: 100% 100%;
    z-index: 1;

    ${({ theme }) => [theme.breakpoints.down('md')]} {
      background-image: url('${SignUpBGTab}');
      height: 40px;
    }

    ${({ theme }) => [theme.breakpoints.down('xs')]} {
      background-image: url('${SignUpBGMob}');
      height: 39px;
      left: 3px;
    }
  }
`

export const Ad = styled(Link)`
  text-decoration: none;
  display: block;
  background-image: url('${PurchaseDesktop}');
  background-size: 100% 100%;
  width: ${({ theme }) => (theme.ux.isSSO ? '170px' : '282px')};
  height: ${({ theme }) => (theme.ux.isSSO ? '320px' : '555px')};
  z-index: 1;
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    margin-top: 20px;  
    margin-bottom: 0px;  `}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    background-image: url('${EnrollTab}');
    width: 100%;
    height: 208px;
    max-width: 463px;
    max-height: 208px
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    background-image: url('${EnrollMobile}');
    max-width: 383px;
  }
`

export const ContentWrap = styled(Flex)`
  padding-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s120 : theme.space.s200};
  margin-left: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s20 : theme.space.s37};
  display: flex;
  flex-direction: column;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    padding-top: ${({ theme }) => theme.space.s57};
    margin-left: ${({ theme }) => theme.space.s28};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    padding-top: ${({ theme }) => theme.space.s55};
    margin-left: ${({ theme }) => theme.space.s26};
  }

  &:hover ${SignUpBtn}:before, &:focus ${SignUpBtn}:before {
    width: 100%;
  }
`

export const Title = styled('div')`
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? '38px' : theme.custom.font.size.H1};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  height: 52px;
  line-height: 52px;
  z-index: 1;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H2};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H3};
  }
`

export const AmountDecimal = styled.span`
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H6 : theme.custom.font.size.H4};
  line-height: 36px;
  position: ${({ theme }) => (theme.ux.isSSO ? 'relative' : 'absolute')};
  top: ${({ theme }) => (theme.ux.isSSO ? '-15px' : 'initial')};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
  }
`

export const SubTitle = styled('div')`
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.sm : theme.custom.font.size.H5};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s5};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
    line-height: 26px;
    margin-top: ${({ theme }) => theme.space.s2};
  }
`

export const TitleUnderline = styled.div`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 7px;
    width: 69px;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    position: relative;
    left: 10px;
    z-index: -1;
  }

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    height: 6px;
    width: 60px;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    position: relative;
    top: 1px;
    left: 12px;
    z-index: -1;
    bottom: 3px;
  }
`
