export const LOADER_ON = 'LOADER_ON'
export const LOADER_OFF = 'LOADER_OFF'
export const HAS_API_ERRORS = 'HAS_API_ERRORS'
export const CLEAR_API_ERRORS = 'CLEAR_API_ERRORS'
export const SHOW_TIMEOUT_COMP = 'SHOW_TIMEOUT_COMP'
export const HIDE_TIMEOUT_COMP = 'HIDE_TIMEOUT_COMP'

export const loaderMounted = (timeoutCount = 0) => ({
  type: LOADER_ON,
  timeoutCount
})
export const loaderUnmounted = () => ({ type: LOADER_OFF })
export const hasApiError = () => ({ type: HAS_API_ERRORS })
export const clearApiError = () => ({ type: CLEAR_API_ERRORS })
export const showTimeoutComp = () => ({ type: SHOW_TIMEOUT_COMP })
export const hideTimeoutComp = () => ({ type: HIDE_TIMEOUT_COMP })
