// @flow
import get from 'lodash/get'

type State = {
  loader: {
    apiErrors: number,
    isShowErrorComp: boolean
  }
}

export const getApiErrors = (state: State): number =>
  get(state, 'loader.apiErrors', 0)
export const getShowTimeOutComp = (state: State): boolean =>
  get(state, 'loader.isShowErrorComp', false)
