import { createFetchActions } from '../../helpers/actions/creators'
  
export const DISMISS_SURVEY = 'DISMISS_SURVEY'
export const DISMISS_SURVEY_SUCCESS = 'DISMISS_SURVEY_SUCCESS'
export const DISMISS_SURVEY_FAILURE = 'DISMISS_SURVEY_FAILURE'

export const SUBMIT_SURVEY = 'SUBMIT_SURVEY'
export const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS'
export const SUBMIT_SURVEY_FAILURE = 'SUBMIT_SURVEY_FAILURE'

export const SURVEY = 'SURVEY'

export const {
  fetchAction: fetchSurveyAction,
  fetchStartAction: fetchSurveyStart,
  fetchSuccessAction: fetchSurveySuccess,
  fetchErrorAction: fetchSurveyError
} = createFetchActions(SURVEY)

export const dismissNotification = surveyId => ({
  type: DISMISS_SURVEY,
  surveyId
})

export const dismissNotificationSuccess = (surveyId, response) => ({
  type: DISMISS_SURVEY_SUCCESS,
  surveyId,
  response
})

export const dismissNotificationFail = (surveyId, response) => ({
  type: DISMISS_SURVEY_FAILURE,
  surveyId,
  response
})

export const submitSurveyResponse = (surveyId, rating, feedback) => ({
  type: SUBMIT_SURVEY,
  surveyId,
  rating,
  feedback
})

export const submitSurveyResponseSuccess = (surveyId, rating, feedback, response) => ({
  type: SUBMIT_SURVEY_SUCCESS,
  surveyId,
  rating,
  feedback,
  response
})

export const submitSurveyResponseFail = (surveyId, rating, feedback, response) => ({
  type: SUBMIT_SURVEY_FAILURE,
  surveyId,
  rating,
  feedback,
  response
})
