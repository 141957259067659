import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePlaidLink, PlaidLinkError } from 'react-plaid-link'
import {
  fetchPlaidLinkTokenAction,
  updatePlaidItemAction,
  clearToken,
  cleanError,
  createPlaidBankAccount
} from 'zego-shared/store/payments/plaid/actions'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import { useHasPlaid } from '../hooks'
import { hasError } from 'zego-shared/store/payments/plaid/selectors'
import { failedToReauthPlaid } from 'zego-shared/utils/messages'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { getBankAccountData, accountsMatch } from 'zego-shared/utils/plaid'

interface Props {
  plaidItem: {itemId: string, accountHolderName: string, institutionId: string, name: string, lastFour: string};
  onSuccess: (itemId: string) => {};
  onExit: (itemId: string) => {};
  onError: (error: PlaidLinkError) => {};
}

export default function PlaidLinkUpdate({
  plaidItem,
  onSuccess,
  onExit,
  onError,
}: Props) {
  const dispatch = useDispatch()
  const { token } = useHasPlaid()
  const [pristine, setPristine] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [componentReady, setComponentReady] = useState(false)
  const userId = useSelector(getUserId)
  const error = useSelector(hasError)
  const bankAccountInfo = useRef({ payload: {}, residentId: userId })
  const itemId = plaidItem.itemId;

  useEffect(() => {
    if (pristine) {
      setPristine(false)

      // if there's a link token already in the store
      // when loading the component the first time
      // then remove it as it may not be a valid token for update mode
      if (token) {
        dispatch(clearToken())
      }
    }

    if (!isFetching && !token && itemId) {
      setIsFetching(true)
      dispatch(fetchPlaidLinkTokenAction({ itemId }))
    }

    if (isFetching && token) {
      setIsFetching(false)
      setComponentReady(true)
    }
  }, [dispatch, isFetching, itemId, pristine, token])

  useEffect(() => {
    if(error) {
      dispatch(cleanError())
      dispatch(showSnackbar(failedToReauthPlaid, 'error'))
      onError && onError()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  const onPlaidSuccess = async (_public_token, metadata) => {
    onSuccess && onSuccess(itemId, metadata)
    const matchingAccounts = accountsMatch({...plaidItem, mask: plaidItem.lastFour}, {...metadata.accounts[0], institutionId: metadata.institution.institution_id})
    if(matchingAccounts) {
      dispatch(updatePlaidItemAction({ itemId, reauthRequired: false }))
    } else {
      getBankAccountData(metadata, bankAccountInfo)
      getUpdateData(bankAccountInfo, itemId, plaidItem.accountHolderName)
      dispatch(createPlaidBankAccount(bankAccountInfo.current))
    }
    dispatch(clearToken())
  }

  const onPlaidExit = async (err: PlaidLinkError | null) => {
    dispatch(clearToken())
    if (err) {
      onError && onError(err)
      return
    }
    onExit && onExit(itemId)
  }

  if (componentReady && token) {
    return (
      <PlaidLinkUpdateWrapper
        options={{
          onSuccess: onPlaidSuccess,
          onExit: onPlaidExit,
          token
        }}
      />
    )
  }

  return null
}

function PlaidLinkUpdateWrapper({ options }) {
  const { ready, open } = usePlaidLink(options)

  useEffect(() => {
    if (!ready) {
      return
    }

    open()
  }, [ready, open])

  return null
}

function getUpdateData(bankAccountInfo: Object, itemId: string, accountHolderName: string): void {
  bankAccountInfo.current = {
    ...bankAccountInfo.current,
    payload: {
      ...bankAccountInfo.current.payload,
      itemId,
      accountHolderName
    },
    isUpdate: true
  }
}
