export const CREATE_VAP = 'createVariableAutopay'

export const createVariableAutoPay = `
  mutation($input: CreateVariableAutopayInput!) {
    ${CREATE_VAP}(input: $input) {
      autopayId
      creationDate
    }
  }
`

export const CREATE_FAP = 'createFixedAutopay'

export const createFixedAutoPay = `
  mutation($input: CreateFixedAutopayInput!) {
    ${CREATE_FAP}(input: $input) {
      autopayId
      creationDate
    }
  }
`

export const cancelAutopayMutation = `
  mutation($input: AutopayInput!) {
    cancelAutopay(input: $input) {
      id
      message
  }
}
`

export const skipAutopayMutation = `
  mutation($input: AutopayInput!) {
    skipAutopay(input: $input) {
      id
      message
  }
}
`

export const cancelSkipAutopayMutation = `
  mutation($input: AutopayInput!) {
    cancelSkipAutopay(input: $input) {
      id
      message
  }
}
`

export const getAutoPayMutVars = (id, autopayType) => ({
  input: { id: Number(id), type: autopayType }
})
