// @flow
import React from 'react'
import Divider from '@material-ui/core/Divider'
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

import List from '../../../../Common/ListSimple'
import Flex from '../../../../Common/Flex'
import type { BankAccount } from '../../../types'

const getListItems = (account: BankAccount) => {
  const { routingNumber, type, accountHolderName, lastFour } = account
  return [
    {
      label: 'Name on Account',
      value: accountHolderName
    },
    {
      label: 'Account Type',
      value: type
    },
    {
      label: 'Account Number',
      value: lastFour
    },
    {
      label: 'Routing Number',
      value: routingNumber
    }
  ]
}

const BankAccountDetailsMobile = (props: { account: BankAccount }) => {
  const { account } = props
  const items = getListItems(account)
  const theme = useTheme()
  return (
    <Flex direction="column" justifyCenter>
      <Flex justifyCenter fullWidth marginBottom={theme.space.s15}>
        <Typography variant="h5"> {account.bankName}</Typography>
      </Flex>
      <Divider light />
      <List items={items} divider />
      <Divider />
    </Flex>
  )
}

export default BankAccountDetailsMobile
