// @flow

import React from 'react'
import { withRouter, Link, Redirect } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import { Box } from '@material-ui/core'
import { GridItem } from '@Common/Grid'
import { FabButton } from '@Common/Button'
import Loader from '@Common/Loader'
import { Arrow } from '@icons'
import type { Theme } from '../../../../theme'
import type { Account } from '../../types'
import BankAccountDetails from './BankAccountDetails'
import CardAccountDetails from './CardAccountDetails'
import { PaymentDetailsStyled, HeaderStyled, PageTitle} from './styles'

type Props = {
  account: Account,
  type: string,
  match: { url: string, params: { methodId: string } },
  isFetching: boolean,
  deleteMethod: (string, string) => void,
  theme: Theme
}

const MethodDetails = (props: Props) => {
  const {
    account,
    type,
    isFetching,
    deleteMethod,
    theme,
    match: {
      url,
      params: { methodId }
    }
  } = props
  const baseUrl = url.split(`/${methodId}`)[0]
  if (isFetching) {
    return <Loader />
  }

  if (!account) {
    return <Redirect to={baseUrl} from={url} />
  }

  return (
    <GridItem columnStart={1} columnSpan={12}>
      <HeaderStyled display="flex" alignItems="center">
        <Link to={baseUrl} from={url}>
          <FabButton
            background={theme.palette.tertiary.main}
            component={() => (
              <Arrow
              id="backArrow"
              name="arrow"
              width="16px"
              height="12px"
              fill={theme.palette.primary.main}/>
              )}
            />
          </Link>
        <Box ml={6} display="flex">
          <PageTitle>{type}</PageTitle>
        </Box>
      </HeaderStyled>
      <PaymentDetailsStyled>
        {'Bank Account' === type && (
          <BankAccountDetails account={account} deleteMethod={deleteMethod} />
        )}
        {['Credit Card', 'Debit Card'].includes(type) && (
          <CardAccountDetails account={account} deleteMethod={deleteMethod} />
        )}
      </PaymentDetailsStyled>
    </GridItem>
  )
}

export default withRouter(withTheme(MethodDetails))
