import { createFetchActions } from '../../../helpers/actions/creators'
import { createUpdateActions } from '../../../helpers/actions/creators'

export const AUTO_PAY_CONFIG = 'AUTOPAY_CONFIG'
export const CASH_PAY_CONFIG = 'CASHPAY_CONFIG'
export const AUTO_PAY_STATE = 'AUTOPAY_STATE'
export const AUTO_PAY_DATA = 'AUTOPAYDATA'
export const AUTO_PAY_DETAILS_SET = 'AUTO_PAY_DETAILS_SET'
export const AUTO_PAY_DETAILS_UPDATE = 'AUTO_PAY_DETAILS_UPDATE'
export const AUTO_PAY_AMOUNT_TYPE_CHANGE = 'AUTO_PAY_AMOUNT_TYPE_CHANGE'

export const AUTO_PAY = 'AUTOPAY'
export const AUTO_PAYS_SECTION = 'AUTOPAYSSECTION'

export const CANCEL_AUTOPAY = 'CANCEL_AUTOPAY'
export const CANCEL_FIXED_AUTOPAY_SUCCESS = 'CANCEL_FIXED_AUTOPAY_SUCCESS'
export const CANCEL_VARIABLE_AUTOPAY_SUCCESS = 'CANCEL_VARIABLE_AUTOPAY_SUCCESS'
export const CANCEL_AUTOPAY_ERROR = 'CANCEL_AUTOPAY_ERROR'

export const SKIP_AUTOPAY = 'SKIP_AUTOPAY'
export const SKIP_FIXED_AUTOPAY_SUCCESS = 'SKIP_FIXED_AUTOPAY_SUCCESS'
export const SKIP_VARIABLE_AUTOPAY_SUCCESS = 'SKIP_VARIABLE_AUTOPAY_SUCCESS'

export const CANCEL_SKIP_AUTOPAY = 'CANCEL_SKIP_AUTOPAY'
export const CANCEL_SKIP_AUTOPAY_ERROR = 'CANCEL_SKIP_AUTOPAY_ERROR'
export const CANCEL_SKIP_FIXED_AUTOPAY_SUCCESS = 'CANCEL_SKIP_FIXED_AUTOPAY_SUCCESS'
export const CANCEL_SKIP_VARIABLE_AUTOPAY_SUCCESS = 'CANCEL_SKIP_VARIABLE_AUTOPAY_SUCCESS'

export const {
  fetchAction: fetchAutoPaysAction,
  fetchStartAction: fetchAutoPaysStart,
  fetchSuccessAction: fetchAutoPaysSuccess,
  fetchErrorAction: fetchAutoPaysError
} = createFetchActions(AUTO_PAYS_SECTION)

export const {
  fetchAction: fetchAutoPayConfigAction,
  fetchStartAction: fetchAutoPayConfigStart,
  fetchSuccessAction: fetchAutoPayConfigSuccess,
  fetchErrorAction: fetchAutoPayConfigError
} = createFetchActions(AUTO_PAY_CONFIG)

export const {
  fetchAction: fetchCashPayConfigAction,
  fetchStartAction: fetchCashPayConfigStart,
  fetchSuccessAction: fetchCashPayConfigSuccess,
  fetchErrorAction: fetchCashPayConfigError
} = createFetchActions(CASH_PAY_CONFIG)

export const {
  fetchAction: fetchAutoPayStateAction,
  fetchStartAction: fetchAutoPayStateStart,
  fetchSuccessAction: fetchAutoPayStateSuccess,
  fetchErrorAction: fetchAutoPayStateError
} = createFetchActions(AUTO_PAY_STATE)

export const {
  fetchAction: fetchAutoPayDataAction,
  fetchStartAction: fetchAutoPayDataStart,
  fetchSuccessAction: fetchAutoPayDataSuccess,
  fetchErrorAction: fetchAutoPayDataError
} = createFetchActions(AUTO_PAY_DATA)

export const {
  updateAction: createAPAction,
  updateStartAction: createAPStart,
  updateSuccessAction: createAPSuccess,
  updateErrorAction: createAPError
} = createUpdateActions(AUTO_PAY)

export const createAP = (onSuccess) => createAPAction({ onSuccess })

export const fetchAutoPayData = residentId =>
  fetchAutoPayDataAction({ residentId })

export const saveDetails = details => ({
  type: AUTO_PAY_DETAILS_SET,
  details
})

export const updateDetails = detail => ({
  type: AUTO_PAY_DETAILS_UPDATE,
  detail
})

export const setPaymentAmtType = amountType => ({
  type: AUTO_PAY_AMOUNT_TYPE_CHANGE,
  amountType
})

export const cancelAutopay = (id, autopayType, redirectArgs) => ({
  id,
  autopayType,
  redirectArgs,
  type: CANCEL_AUTOPAY
})

export const cancelFixedAutopaySuccess = (id) => ({
  type: CANCEL_FIXED_AUTOPAY_SUCCESS,
  id
})

export const cancelVariableAutopaySuccess = (id) => ({
  type: CANCEL_VARIABLE_AUTOPAY_SUCCESS,
  id
})

export const cancelAutopayError = () => ({
  type: CANCEL_AUTOPAY_ERROR,
})

export const skipAutopay = (id, autopayType) => ({
  id,
  autopayType,
  type: SKIP_AUTOPAY
})

export const skipFixedAutopaySuccess = (id, skipped) => ({
  id,
  skipped,
  type: SKIP_FIXED_AUTOPAY_SUCCESS
})

export const skipVariableAutopaySuccess = (id, skipped) => ({
  id,
  skipped,
  type: SKIP_VARIABLE_AUTOPAY_SUCCESS
})

export const cancelSkipAutopay = (id, autopayType) => ({
  id,
  autopayType,
  type: CANCEL_SKIP_AUTOPAY
})

export const cancelSkipFixedAutopaySuccess = (id, skipped) => ({
  id,
  skipped,
  type: CANCEL_SKIP_FIXED_AUTOPAY_SUCCESS
})

export const cancelSkipVariableAutopaySuccess = (id, skipped) => ({
  id,
  skipped,
  type: CANCEL_SKIP_VARIABLE_AUTOPAY_SUCCESS
})

export const cancelSkipAutopayError = () => ({
  type: CANCEL_SKIP_AUTOPAY_ERROR,
})
