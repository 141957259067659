import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import SSOEMailBg from '@images/sso_email_success_page.png'
import GoBtnBG from '@images/auto_pay_ad/go_btn_bg.png'
import UpdateBtnBG from '@images/sso-update-btn-bg.png'
import { TextInput } from '@Common/Form'

export const Base = styled(Flex)`
  background-image: url(${SSOEMailBg});
  background-position: right bottom;
  background-repeat: no-repeat;
  height: 127px;
  border-radius: 4px;
  margin: ${({ theme }) => `${theme.space.s20} 0`};
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.warm}`};
  padding: ${({ theme }) => `${theme.space.s14} ${theme.space.s14} 0`};
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    line-height: 14px;
  }
`

export const TopSection = styled(Flex)`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.md};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  flex-direction: column;
  justify-content: center;
  line-height: 16px;
`

export const SubText = styled.div`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.md};
  line-height: 14px;
  margin-bottom: ${({ theme }) => theme.space.s4};
  margin-left: ${({ theme, leftMargin }) => leftMargin && theme.space.s4};
`

export const BottomSection = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space.s14};
`

export const GoButton = styled.div`
  height: 24px;
  width: 51.5px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
  line-height: 16px;
  font-size: ${({ theme }) => theme.custom.font.size.md};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  padding: 3px 0;
  margin-left: ${({ theme }) => theme.space.s10};
  z-index: 0;
  display: inline-block;
  cursor: pointer;
  &:before{
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    top: 0;
    left: 0;
    height: 24px;
    width: 0;
    transition: 0.25s width;
    z-index: -1;
  }
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 100%;
    background-image: url('${GoBtnBG}');
    background-size: 100% 100%;
    z-index: 1;
  }
`

export const StyledInput = styled(TextInput)`
  color: ${({ theme }) => theme.palette.neutral.dark};
  margin-left: ${({ theme }) => theme.space.s6};
`

export const HideOnSM = styled.div`
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    display: none;
  }
`

export const UpdateButton = styled.div`
  height: 30px;
  width: 104.5px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
  line-height: 16px;
  font-size:${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  padding: ${({ theme }) =>
    `${theme.space.s6} ${theme.space.s10} ${theme.space.s6} 0`};
  z-index: 0;
  margin-left: ${({ theme }) => theme.space.s8};

  &:before{
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    top: 0;
    left: 0;
    height: 30px;
    width: 0;
    transition: 0.25s width;
    z-index: -1;
  }
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100%;
    background-image: url('${UpdateBtnBG}');
    background-size: 100% 100%;
    z-index: 1;
  }
`
