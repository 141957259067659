import withFetch from '../../helpers/reducers/withFetch'
import { USER_SETTINGS_FETCH_SECTION } from '../actions'

const reducer = (state = null, result) => {
  const { type } = result
  switch (type) {
    default:
      return state
  }
}

export default withFetch(USER_SETTINGS_FETCH_SECTION)(reducer)
