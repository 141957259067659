// @flow
import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'

export const Title = styled('h4')(
  ({ theme }) => `
    font-size: ${theme.custom.font.size.H4};
    font-weight: ${theme.custom.font.weight.bold};
    text-align: center;
  `
)

export const StyledIconButton = styled(IconButton)`
  border: 2px solid ${({ theme }) => theme.palette.tertiary.main};
  height: 44px !important;
  width: 44px;
  padding: 0;
  box-sizing: border-box;
  margin-right: 10px;
`
