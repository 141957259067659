import { connect } from 'react-redux'
import MethodsList from './MethodsList'
import { deletePaymentMethod } from 'zego-shared/store/payments/methods/actions'
import {
  paymentsMethodsData,
  getPaymentOptionsConfig
} from 'zego-shared/store/payments/methods/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getPaymentState } from 'zego-shared/store/payments/summary/selectors'
const fetchingSelector = createIsFetchingSelector([
  'paymentmethodssection',
  'paymentoptions'
])

export const mapStateToProps = state => ({
  accounts: paymentsMethodsData(state),
  isFetching: fetchingSelector(state),
  optionsConfig: getPaymentOptionsConfig(state),
  paymentState: getPaymentState(state)
})

export const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { residentId }
    }
  }
) => ({
  deleteMethod: (id, typeName) => {
    dispatch(deletePaymentMethod(residentId, id, typeName))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(MethodsList)
