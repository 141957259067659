// @flow
import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { capitalizeFirstLetter } from 'zego-shared/utils/string'
import { Signature } from '@icons'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import RemoveMethodButton from '../RemoveMethodButton'
import BankAccountDetailsMobile from './BankAccountDetailsMobile'
import type { BankAccount } from '../../../types'
import { StyledCard, PStyled, BorderBox } from '../styles'

const maskAccount = (account = '') => {
  const lastFour = account.slice(-4)
  return lastFour.padStart(9, '•')
}

type MaskedAccountProps = {
  label: string,
  number: string,
  elementId?: string,
  unMask?: boolean
}

export const MaskedAccount = ({
  label,
  number,
  unMask,
  elementId
}: MaskedAccountProps) => {
  return (
    <React.Fragment>
      <PStyled light H6>
        {label}
      </PStyled>
      <Box color="primary.main" fontWeight="700" pl={2} pr={6} clone>
        <PStyled bolder id={elementId}>
          {unMask ? number : maskAccount(number)}
        </PStyled>
      </Box>
    </React.Fragment>
  )
}

const BankAccountDetails = (props: {
  account: BankAccount,
  deleteMethod: (string, string) => void
}) => {
  const { account, deleteMethod } = props
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const isSSO = theme.ux.isSSO
  const customPadding = isSSO ? '35px 23px' : undefined

  if (isSmDown) {
    return <BankAccountDetailsMobile account={account} />
  }

  return (
    <Box>
      <Box width="100%">
        <StyledCard customPadding={customPadding}>
          <BorderBox
            pb={isSSO ? 8 : 10}
            display="flex"
            justifyContent="space-between">
            <PStyled bolder id="holderName">
              {account.accountHolderName}
            </PStyled>
            <Box display="flex" justifyContent="flex-end">
              <Typography variant="h6" id="accountType">
                {capitalizeFirstLetter(account.type)}
              </Typography>
            </Box>
          </BorderBox>
          <Box
            color="common.midnight"
            display="flex"
            justifyContent="space-between"
            maxHeight={isSSO ? '70px' : 'auto'}>
            <BorderBox pt={2} pb={isSSO ? 6 : 8} width={300}>
              <Typography variant={isSSO ? 'h5' : 'h4'} id="bankName">
                {account.bankName}
              </Typography>
            </BorderBox>
            <BorderBox pt={isSSO ? 6 : 8} pb={1} px={2}>
              <Signature
                name="signature"
                width={isSSO ? '250px' : '373px'}
                height="29px"
              />
            </BorderBox>
          </Box>
          <Box color="common.grey" display="flex" pt={isSSO ? 5 : 6}>
            <MaskedAccount
              elementId="routingNum"
              label="Routing Number"
              number={account.routingNumber}
              unMask
            />
            <MaskedAccount
              elementId="accountNumber"
              label="Account Number"
              number={account.lastFour}
            />
          </Box>
        </StyledCard>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        fontWeight="fontWeightBold"
        fontSize="h6.fontSize"
        mt={isSSO ? 10 : 15}>
        <RemoveMethodButton
          elementId="removeBtn"
          deleteMethod={deleteMethod}
          id={account.id}
          typeName={account.typeName}
        />
      </Box>
    </Box>
  )
}

export default BankAccountDetails
