import ToolTip from '@material-ui/core/Tooltip'
import { withStyles, lighten } from '@material-ui/core/styles'

export const SnackbarContentStyles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    padding: `0 ${theme.spacing.unit * 16 / 5}px`,
    minHeight: theme.spacing.unit * 12,
  },
  success: {
    backgroundColor: theme.palette.feedback.success
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.feedback.success
  },
  warning: {
    backgroundColor: theme.palette.feedback.warning
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '80vw'
    }
  },
  action: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * -36/5,
    paddingLeft: 0,
    minHeight: theme.spacing.unit * 12,
    height: '100%',
    position: 'absolute',
    right: '0',
    [theme.breakpoints.only('xs')]: {
      marginRight: 0
    }
  },
  helpIcon: {
    marginLeft: theme.space.s5,
    marginRight: theme.spacing.unit
  }
})

export const SnackbarStyles = theme => ({
  close: {
    width: theme.spacing.unit * 4,
    height: theme.spacing.unit * 4
  }
})

export const StyledTooltip = withStyles(theme => ({
  tooltip: {
    color: theme.palette.common.midnight,
    fontWeight: theme.custom.font.weight.regular,
    fontSize: theme.custom.font.size.sm,
    background: lighten(theme.palette.tertiary.main, 0.8),
    border: `1px solid ${theme.palette.tertiary.main}`,
    padding: theme.space.s15
  },
  popper: {
    zIndex: 4000
  },
  arrow: {
    color: lighten(theme.palette.tertiary.main, 0.8),
  }
}))(ToolTip)
