import React from 'react'
import { generate as shortid } from 'shortid'
import ListFull from '@Common/ListFull'
import { TableRow } from '@material-ui/core'
import Loader from '@Common/Loader'
import {
  DateStyled,
  Status,
  Message,
  PageTitle,
  StyledGrid,
  Demarcation,
  StyledMsgBody,
  StyledMsgTtitle
} from './styles'
import { StyledTableBodyCell } from '@Common/ListFull/styles'

type Props = {
  workOrders: Array<Object>,
  breakPoint: number,
  isFetching?: boolean
}

const flex = [0.5, 2, 1]

const columns = [
  { label: 'Date', dataKey: 'date' },
  { label: 'Message', dataKey: 'message' },
  { label: 'Status', dataKey: 'status', align: 'right' }
]

const getRows = (workOrders, breakPoint) => {
  return workOrders.map((row, index) => {
    return (
      <React.Fragment key={shortid()}>
        {index === breakPoint && (
          <TableRow>
            <StyledTableBodyCell>
              <Demarcation />
            </StyledTableBodyCell>
            <StyledTableBodyCell>
              <Demarcation />
            </StyledTableBodyCell>
            <StyledTableBodyCell>
              <Demarcation />
            </StyledTableBodyCell>
          </TableRow>
        )}
        <TableRow className="woRow">
          {columns.map(({ dataKey }, index) => (
            <StyledTableBodyCell
              key={shortid()}
              align={index === 2 ? 'right' : ''}>
              <React.Fragment>
                {index === 0 ? (
                  <DateStyled className="woDate">{row[dataKey]}</DateStyled>
                ) : index === 1 ? (
                  <Message>
                    <StyledMsgTtitle className="woMsgTitle">
                      {row[dataKey].messageTitle}
                    </StyledMsgTtitle>
                    <StyledMsgBody className="woMsg">
                      {row[dataKey].messageBody}
                    </StyledMsgBody>
                  </Message>
                ) : (
                  <Status className="woStatus">{row[dataKey]}</Status>
                )}
              </React.Fragment>
            </StyledTableBodyCell>
          ))}
        </TableRow>
      </React.Fragment>
    )
  })
}

const History = ({ workOrders, breakPoint, isFetching }: Props) => {
  if (isFetching) {
    return <Loader />
  }
  return (
    <StyledGrid columnStart={4} columnSpan={8} rowStart={1} rowSpan={12}>
      <PageTitle id="woHistoryHeader">History</PageTitle>

        <ListFull
          columns={columns}
          rows={getRows(workOrders, breakPoint)}
          flex={flex}
          columnLength={flex ? flex.length : null}
          useInternalRow={false}
        />

    </StyledGrid>
  )
}

export default History
