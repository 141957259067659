import withFetch from '../../../helpers/reducers/withFetch'
import {
  PLAID_LINK_TOKEN,
  CLEAN_ERROR,
  fetchPlaidLinkTokenError,
  CLEAR_TOKEN,
  fetchPlaidItemsSuccess
} from '../actions'

const initialState = {
  token: null,
  error: false,
  items: []
}

const plaid = (state = initialState, action) => {
  switch (action.type) {
    case fetchPlaidLinkTokenError().type:
      return { ...state, error: true }
    case CLEAN_ERROR:
      return { ...state, error: false }
    case fetchPlaidItemsSuccess().type:
      return { ...state, items: [...action.payload] }
    case CLEAR_TOKEN: 
      return { ...state, token: null }
    default:
      return state
  }
}

export default withFetch(PLAID_LINK_TOKEN)(plaid)
