import { createFetchActions } from '../../../helpers/actions/creators'

export const PAYMENT_OPTIONS = 'PAYMENTOPTIONS'

export const {
  fetchAction: fetchPaymentOptionsAction,
  fetchStartAction: fetchPaymentOptionsStart,
  fetchSuccessAction: fetchPaymentOptionsSuccess,
  fetchErrorAction: fetchPaymentOptionsError
} = createFetchActions(PAYMENT_OPTIONS)

export const fetchPaymentOptions = isOnetimePayment =>
  fetchPaymentOptionsAction({ isOnetimePayment })
