import { showSnackbar } from '../../snackbar/actions'
import { failedToLoginGoogle } from '../../../utils/messages'

export const SAVE_AUTH = 'SAVE_AUTH'
export const LOGIN = 'LOGIN'
export const LOGIN_REFRESH = 'LOGIN_REFRESH'
export const LOGIN_EMAIL = 'LOGIN_EMAIL'
export const LOGIN_SOCIAL = 'LOGIN_SOCIAL'
export const LOGIN_TOKEN = 'LOGIN_TOKEN'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGIN_AS = 'LOGIN_AS'
export const LOGOUT_AND_REDIRECT = 'LOGOUT_AND_REDIRECT'
export const UPDATE_USER = 'UPDATE_USER_START'
export const UPDATE_USER_OPTIMISTIC = 'UPDATE_USER_OPTIMISTIC'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const UPDATE_SENDBIRD_INFO = 'UPDATE_SENDBIRD_INFO'

export const VALIDATE_SIGN_UP_LINK = 'VALIDATE_SIGN_UP_LINK'
export const VALIDATE_SIGN_UP_LINK_SUCCESS = 'VALIDATE_SIGN_UP_LINK_SUCCESS'
export const VALIDATE_SIGN_UP_LINK_FAILURE = 'VALIDATE_SIGN_UP_LINK_FAILURE'
export const COMPLETE_VALIDATE_SIGN_UP_LINK = 'COMPLETE_VALIDATE_SIGN_UP_LINK'
export const SIGN_UP = 'SIGN_UP'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR'
export const START_SIGN_UP = 'START_SIGN_UP'

export const RESET_PASS = 'RESET_PASS'
export const REQUEST_RESET_PASS = 'REQUEST_RESET_PASS'

export const DEAUTH = 'DEAUTH'

export const DELETE_RESIDENT = 'DELETE_RESIDENT'
export const DELETE_RESIDENT_SUCCESS = 'DELETE_RESIDENT_SUCCESS'
export const DELETE_RESIDENT_FAILED = 'DELETE_RESIDENT_FAILURE'

export const FETCH_RESOURCES_AND_REDIRECT_AFTER_LOGIN =
  'FETCH_RESOURCES_AND_REDIRECT_AFTER_LOGIN'

export const saveAuth = (profile, info) => ({
  type: SAVE_AUTH,
  profile,
  info
})

export const loginEmail = (username, password, spoofUserId) => ({
  type: LOGIN_EMAIL,
  username,
  password,
  spoofUserId
})

export const loginSocial = (provider, data) => ({
  type: LOGIN_SOCIAL,
  provider,
  data
})

export const loginToken = (token, messageAfterLogin) => ({
  type: LOGIN_TOKEN,
  token,
  messageAfterLogin
})

export const loginFailure = () => ({
  type: LOGIN_FAILED
})

export const loginAs = (userId, history) => ({
  type: LOGIN_AS,
  userId,
  history
})

export const logoutAndRedirect = history => ({
  type: LOGOUT_AND_REDIRECT,
  history
})

export const getTokenByRefresh = (refreshToken, resolve, reject) => ({
  type: LOGIN_REFRESH,
  refreshToken,
  resolve,
  reject
})

export const fetchResourcesAndRedirectAfterLogin = history => ({
  type: FETCH_RESOURCES_AND_REDIRECT_AFTER_LOGIN,
  history
})

export function updateUser(attributes, isSSOEmailUpdate = false) {
  return {
    type: UPDATE_USER,
    attributes,
    isSSOEmailUpdate
  }
}

export function updateUserOptimistic(attributes) {
  return {
    type: UPDATE_USER_OPTIMISTIC,
    attributes
  }
}

export function updateUserSuccess(attributes) {
  return {
    type: UPDATE_USER_SUCCESS,
    attributes
  }
}

export function updateUserError(error, attributes) {
  return {
    type: UPDATE_USER_ERROR,
    error,
    attributes
  }
}

export const validateSignUpLink = data => ({
  type: VALIDATE_SIGN_UP_LINK,
  data
})

export const validateSignUpLinkSuccess = () => ({
  type: VALIDATE_SIGN_UP_LINK_SUCCESS
})

export const validateSignUpLinkFailure = () => ({
  type: VALIDATE_SIGN_UP_LINK_FAILURE
})

export const signUp = (token, data, provider, history, isMobileDevice) => ({
  type: SIGN_UP,
  token,
  data,
  provider,
  history,
  isMobileDevice
})

export const signUpSuccess = () => ({
  type: SIGN_UP_SUCCESS
})

export const signUpError = () => ({
  type: SIGN_UP_ERROR
})

export const startSignUp = () => ({
  type: START_SIGN_UP
})

export const resetPass = (data, history) => ({
  type: RESET_PASS,
  data,
  history
})

export const requestResetPass = email => ({
  type: REQUEST_RESET_PASS,
  email
})

export const deauth = () => ({
  type: DEAUTH
})

export const LoginProviders = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google'
}

export const updateSendBirdInfo = sendbirdInfo => ({
  type: UPDATE_SENDBIRD_INFO,
  sendbirdInfo
})

export const setGoogleLoginError = () =>
  showSnackbar(failedToLoginGoogle, 'error')

export const deleteResident = history => ({
  type: DELETE_RESIDENT,
  history
})

export const deleteResidentSuccessful = () => ({
  type: DELETE_RESIDENT_SUCCESS
})

export const deleteResidentFailed = error => ({
  type: DELETE_RESIDENT_FAILED,
  error
})
