import {
  fetchSurveySuccess,
  DISMISS_SURVEY_SUCCESS, 
  SUBMIT_SURVEY_SUCCESS,
} from '../actions'

const initState = {
  surveyId: null,
  surveyResponded: true,
  surveyMessage: null,
  surveyDismissed: true
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case fetchSurveySuccess().type:
      const survey = action.payload.length >= 1 ? action.payload[0] : 
        { id: null, type: { message: null }, dismissed: true }

      return {
        ...state,
        surveyId: survey.id,
        surveyResponded: false,
        surveyMessage:  survey.type.message,
        surveyDismissed: survey.dismissed
      }
    case DISMISS_SURVEY_SUCCESS:
      return {
        ...state,
        surveyDismissed: true
     
      }
    case SUBMIT_SURVEY_SUCCESS:
      return {
        ...state,
        surveyResponded: true
      }
    
    default:
      return state
  }
}

export default reducer
