import { combineReducers } from 'redux'
import flowRight from 'lodash/flowRight'
import { select } from 'zego-shared/store/select/reducers'
import { SELECT_UNIT } from 'zego-shared/store/select/actions'
import isFetching from 'zego-shared/store/isFetching/reducers'
import isUpdating from 'zego-shared/store/isUpdating/reducers'
import authorization from 'zego-shared/store/authorization/reducers'
import billing from 'zego-shared/store/billing/reducers'
import payments from 'zego-shared/store/payments/reducers'
import settings from 'zego-shared/store/settings/reducers'
import workOrders from './WorkOrders/reducers'
import withPersist from 'zego-shared/store/helpers/reducers/withPersist'
import withReset from 'zego-shared/store/helpers/reducers/withReset'
import { DEAUTH } from 'zego-shared/store/authorization/actions'
import appPersistConfig from './persistConfig'
import snackbar from 'zego-shared/store/snackbar/reducers'
import { isFetchingNextReducer as isFetchingNext } from 'zego-shared/store/isFetchingNext'
import userResource from 'zego-shared/store/userResource/reducers'
import moreNavPosition from './moreNavPosition/reducers'
import loader from 'zego-shared/store/loader/reducer'
import profile from 'zego-shared/store/users/profile/reducers'
import termsAndConditions from 'zego-shared/store/termsAndConditions/reducers'
import previousUrl from 'zego-shared/store/previousUrl/reducers'
import launchDarkly from 'zego-shared/store/integrations/launchDarkly/reducers'
import company from 'zego-shared/store/company/reducers'
import resident from 'zego-shared/store/resident/reducers'
import surveys from 'zego-shared/store/surveys/reducers'
import marketplace from 'zego-shared/store/marketplace/reducers'
import overrides from 'zego-shared/store/overrides/reducers'
import pmSettings from 'zego-shared/store/pmSettings/reducers'

const withResetOnDeauth = withReset(DEAUTH)
const appEnhancer = flowRight(withPersist(appPersistConfig), withResetOnDeauth)

const resetOnSelectUnit = reducer => (state, action) => {
  if (action.type === SELECT_UNIT) {
    state = undefined
  }
  return reducer(state, action)
}

const rootReducer = (state, action) =>
  combineReducers({
    authorization,
    select,
    survey: resetOnSelectUnit(surveys),
    workOrders: resetOnSelectUnit(workOrders),
    isFetchingStates: resetOnSelectUnit(isFetching),
    isFetchingNext: resetOnSelectUnit(isFetchingNext),
    isUpdating: resetOnSelectUnit(isUpdating),
    snackbar: resetOnSelectUnit(snackbar),
    payments: resetOnSelectUnit(payments),
    settings: resetOnSelectUnit(settings),
    userResource: resetOnSelectUnit(userResource),
    marketplace: resetOnSelectUnit(marketplace),
    billing,
    moreNavPosition,
    loader,
    profile,
    termsAndConditions,
    previousUrl,
    launchDarkly,
    company,
    overrides,
    pmSettings,
    resident
  })(state, action)

export default appEnhancer(rootReducer)
