// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { TextInput } from '@Common/Form'
import Button from '@Common/Button'
import type { changeModeFn, handleChangeFn } from './types'
import { FormStyled, ButtonsWrapper } from './styles'
import type { Theme } from '../../../theme'

type Props = {
  email: string,
  password: string,
  userId?: string,
  changeMode?: changeModeFn,
  handleChange: handleChangeFn,
  handleLogin: () => mixed,
  handleSocialLogin?: (string, Object) => mixed,
  handleSocialFailure?: () => mixed,
  error: string,
  working: boolean,
  theme: Theme
}

const LoginSection = ({
  email,
  password,
  userId,
  changeMode,
  handleChange,
  handleLogin,
  handleSocialLogin,
  handleSocialFailure,
  error,
  working,
  theme
}: Props) => (
  <FormStyled onSubmit={handleLogin}>
    <TextInput
      className="input"
      fullWidth
      placeholder="email"
      value={email}
      onChange={handleChange}
      id="email"
    />
    <TextInput
      className="input"
      fullWidth
      placeholder="password"
      type="password"
      value={password}
      onChange={handleChange}
      id="password"
    />
    <ButtonsWrapper>
      <Button
        id="forgotPassButton"
        disableRipple
        link
        color={theme.palette.secondary.main}
        onClick={() => {
          changeMode && changeMode('passwordRecoveryRequest')
        }}>
        forgot password?
      </Button>
      <a
        href={window._env_.REACT_APP_NEED_HELP_LOGIN_LINK}
        target="_blank"
        rel="noopener noreferrer">
        need help logging in?
      </a>
      <Button
        id="loginButton"
        disableRipple
        classes={{ label: 'login-label' }}
        animate={working && !error ? 'animate' : ''}
        fullWidth
        primary
        type="submit">
        Login
      </Button>
    </ButtonsWrapper>
  </FormStyled>
)

export default withTheme(LoginSection)
