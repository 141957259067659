import { createFetchActions } from '../../../helpers/actions/creators'

export const COMPANIES_FETCH_SECTION = 'COMPANIES'
export const SELECT_COMPANY = 'SELECT_COMPANY'
export const FETCH_COMPANY_PROPERTIES_AND_SELECT =
  'FETCH_COMPANY_PROPERTIES_AND_SELECT'

export const { fetchSuccessAction: fetchCompaniesSuccess } = createFetchActions(
  COMPANIES_FETCH_SECTION
)

export const selectCompany = (id, fromSelectedProperty = true) => ({
  type: SELECT_COMPANY,
  fromSelectedProperty,
  id
})

export const fetchCompaniesPropertiesAndSelect = (companyId, history) => ({
  type: FETCH_COMPANY_PROPERTIES_AND_SELECT,
  companyId,
  history
})
