import withFetch from '../../../helpers/reducers/withFetch'
import { SSO_PAYMENT_FETCH_SECTION } from '../actions'

const reducer = (state = null, result) => {
  const { type } = result
  switch (type) {
    default:
      return state
  }
}

export default withFetch(SSO_PAYMENT_FETCH_SECTION)(reducer)
