// @flow
import moment from 'moment'

export const firstNameLastNameRegex = /^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+\s*$/i

export const nameRegex = /^[a-z]*([-']?[a-z]+)\s*$/i

export const CVVRegex = /^\d{3,4}$/

export const numberRegex = /^[0-9]*$/

const timeRegex = /^([0-9]|1[0-2]):00$/

const phoneRegex = /^\(?([1-9][0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const macRegex = /[0-9a-f]{12}/i

const numericRegex = /^\d+$/

const alphabetsRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/

export const zipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/

export const SSNRegex = /^\d{9}$/

export const optInNameRegex = /^[a-zA-Z\s'-]*$/

export const validateNumber = (value: string) => numericRegex.test(value)

export const validateAlpha = (value: string) => alphabetsRegex.test(value)

export const workOrderFileSizeLimit = 10485760

const validationObject = (isValid: boolean, message: string) => ({
  isValid,
  message
})

export const validateTime = (time: string) => timeRegex.test(time)

export const validatePhoneNumber = (phoneNumber: string) =>
  phoneRegex.test(phoneNumber) || /^\+1?[1-9]\d{1,9}$/.test(phoneNumber)

export const validateEmail = (email: string) => emailRegex.test(email)

export const validateMac = (mac: string) => macRegex.test(mac)

export const validateNonEmpty = (str: string) => str.length > 0

export const validateEqual = (str1: string, str2: string) => str1 === str2

export const validatePassword = (password: string) => {
  if (password.match(/\d/g) === null) {
    return validationObject(false, '*Password must contain at least one number')
  }

  if (password.match(/[a-zA-z]/g) === null) {
    return validationObject(false, '*Password must contain at least one letter')
  }

  if (password.length < 8) {
    return validationObject(
      false,
      '*Password must be greater than 8 characters'
    )
  }
  return validationObject(true, '')
}

export const isValidChatFile = (file: string) =>
  file.match(/\.(jpg|jpeg|png|gif|pdf)$/)

export const isValidImage = (name: string) =>
  name.match(/\.(jpg|jpeg|png|gif)$/i)

export const isValidImageFile = (file: ?Object) =>
  file && isValidImage(file.name) && file.size <= workOrderFileSizeLimit

export const isValidRecipient = (recipient: string) => {
  return recipient && recipient.length > 1 && recipient[0] === '@'
}

export function validateDate(formats: string) {
  return this.transform(function (value, originalValue) {
    if (this.isType(value)) return value

    value = moment(originalValue, formats, true)
    return value.isValid() ? value.toDate() : new Date('')
  })
}
