// @flow
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getIsBothAutoPays,
  getPaymentAmountType
} from 'zego-shared/store/payments/autopay/selectors'
import { setPaymentAmtType } from 'zego-shared/store/payments/autopay/actions'
import { FAP, VAP } from 'zego-shared/store/payments/autopay/constants'

import FixedAmount from '../FixedAmount'
import MaxAmount from '../MaxAmount'

type Props = {
  value: string,
  onChange: (string, ?boolean) => mixed
}

type APState = 'VAP' | 'FAP'

export const handleStateChange = (state: APState, dispatch: Function) => () => {
  dispatch(setPaymentAmtType(state))
}

const VariableAndFixedAmount = ({ value, onChange, ...props }: Props) => {
  const isBoth = useSelector(getIsBothAutoPays)
  const paymentAmountType = useSelector(getPaymentAmountType)
  const dispatch = useDispatch()

  if (paymentAmountType === VAP) {
    return (
      <MaxAmount
        {...props}
        value={value}
        onChange={onChange}
        fixedAmountLabel="Amount"
        isBoth={isBoth}
        payFixAmt={handleStateChange(FAP, dispatch)}
      />
    )
  }

  if (paymentAmountType === FAP) {
    return (
      <FixedAmount
        {...props}
        onPayFullBalanceClick={handleStateChange(FAP, dispatch)}
        showPayFullBalance
        value={value}
        onChange={onChange}
        fixedAmountLabel="Amount"
        isBoth={isBoth}
        payFullBal={handleStateChange(VAP, dispatch)}
      />
    )
  }

  return null
}

export default VariableAndFixedAmount
