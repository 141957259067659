// @flow

import React from 'react'
import { CommonIcon } from '@icons'

import {
  ErrorDialogStyled,
  ErrorModalSection,
  ContactSupportText,
  ContactDetailsWrap,
  ContactDetails,
  StyledIcon,
  Oops,
  Line
} from './styles'
import Flex from '../Flex'

export type Props = {
  classes: Object,
  isAuth: boolean,
  hide: Function
}

type State = {
  show: boolean
}

class ErrorAlertModal extends React.Component<Props, State> {
  state = {
    show: true
  }

  onClickChatNow = () => {
    const btn =
      document.getElementById('zegoHelpButtonEnabled') ||
      document.querySelector('.helpButtonEnabled.uiButton')

    if (btn) {
      btn.click()
      this.setState({ show: false }, this.props.hide)
    }
  }

  render() {
    const { show } = this.state
    const { isAuth } = this.props

    return (
      <ErrorDialogStyled
        classes={{
          paper: 'dialog-paper'
        }}
        open={show}
        maxWidth={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <ErrorModalSection>
          <Oops>Oops!</Oops>
          Something doesn’t seem to be working right. Try refreshing your
          browser!
        </ErrorModalSection>
        <Flex justifyCenter>
          <Line />
        </Flex>
        <ErrorModalSection>
          <Flex justifySpaceBetween direction="column">
            <Flex justifyCenter>
              <ContactSupportText justifyCenter>
                Please contact support
              </ContactSupportText>
            </Flex>
            <Flex justifyCenter>
              <ContactDetailsWrap isAuth={isAuth}>
                <ContactDetails>
                  <StyledIcon>
                    <CommonIcon name="phone_icon" height="19px" width="19px" />
                  </StyledIcon>
                  1-866-729-5327
                </ContactDetails>
                {isAuth && (
                  <ContactDetails data-testid="chatNowBtn" justifyRight onClick={this.onClickChatNow}>
                    <StyledIcon>
                      <CommonIcon name="chat_icon" height="19px" width="19px" />
                    </StyledIcon>
                    Chat Now
                  </ContactDetails>
                )}
              </ContactDetailsWrap>
            </Flex>
          </Flex>
        </ErrorModalSection>
      </ErrorDialogStyled>
    )
  }
}

export default ErrorAlertModal
