// @flow
import React from 'react'
import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import WithLeftSideNav from '@Common/WithLeftSideNav'
import Loader from '@Common/Loader'
import PaymentsBlocked from './Summary/PaymentsBlocked'
import ReauthorizationModal from './Plaid/Reauthorization'
import { getPaymentRoutes } from '../../routes/Payments'
import { getPaymentsMenuItems } from '@utils/view'

type SsoPaymentsContainerProps = {
  children: Node
}

const SsoPaymentsContainer = ({ children }: SsoPaymentsContainerProps) => (
  <Grid container>
    <Grid item xs={12}>
      {children}
    </Grid>
  </Grid>
)

type Props = {
  residentId: string,
  isFetching: boolean,
  hasStatements: boolean,
  nativePayerWithoutPayment: boolean,
  location: { pathname: string },
  isLevelCreditEnabled: boolean,
  isLegacyOptedIn: boolean,
  disableAutopayMenuTab: boolean,
  disableMakePaymentMenuTab: boolean,
  disableSummaryMenuTab: boolean,
  autopays: {
    autopayType: string,
    paymentFrequency: string,
    skipped: boolean,
    paymentInfo: string,
    maxLimit: string,
    total: string,
    nextPayment: string
  }[]
}

const Payment = (props: Props) => {
  const {
    residentId,
    isFetching,
    hasStatements,
    nativePayerWithoutPayment,
    isLevelCreditEnabled,
    isLegacyOptedIn,
    disableAutopayMenuTab,
    disableMakePaymentMenuTab,
    disableSummaryMenuTab,
    autopays
  } = props

  const theme = useTheme()
  const showCreditReporting = isLevelCreditEnabled || isLegacyOptedIn

  if (isFetching) return <Loader />

  if (theme.ux.isSSO) {
    return (
      <SsoPaymentsContainer>
        {nativePayerWithoutPayment ? (
          <PaymentsBlocked fullWidth />
        ) : (
          getPaymentRoutes(showCreditReporting)
        )}
        <ReauthorizationModal />
      </SsoPaymentsContainer>
    )
  }

  const pmSettings = {
    disableAutopayMenuTab: disableAutopayMenuTab && !autopays.length,
    disableMakePaymentMenuTab: disableMakePaymentMenuTab,
    disableSummaryMenuTab: disableSummaryMenuTab
  }

  let paymentNavLinks = getPaymentsMenuItems(
    residentId,
    hasStatements,
    showCreditReporting,
    nativePayerWithoutPayment,
    pmSettings
  )

  return (
    <WithLeftSideNav routes={paymentNavLinks}>
      {nativePayerWithoutPayment ? (
        <PaymentsBlocked fullWidth />
      ) : (
        getPaymentRoutes(showCreditReporting)
      )}
      <ReauthorizationModal />
    </WithLeftSideNav>
  )
}

export default Payment
