// @flow
import React from 'react'
import type { SyntheticEvent } from 'react'
import { useDispatch } from 'react-redux'
import { withTheme } from 'emotion-theming'
import { Hidden } from '@material-ui/core'
import Button from '@Common/Button'
import { TextInput } from '@Common/Form'
import Flex from '@Common/Flex'
import {
  FormStyled,
  Title,
  ButtonsWrapper,
  PasswordInfo,
  PasswordInfoTitle,
  PasswordResetWrapper,
  Bold
} from './styles'
import type { Theme } from '../../../theme'
import type { changeModeFn, handleChangeFn } from './types'
import { passwordSchema } from './schema'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

type Props = {
  password: string,
  passwordConfirm: string,
  changeMode: changeModeFn,
  handleChange: handleChangeFn,
  handleReset: (event: SyntheticEvent) => mixed,
  error: string,
  working: boolean,
  theme: Theme
}

const PasswordHelper = ({ fullWidth }) => (
  <PasswordInfo fullWidth={fullWidth}>
    <PasswordInfoTitle>
      Passwords <Bold>Must</Bold> Include <Bold>All</Bold> of the following:
    </PasswordInfoTitle>
    <ul>
      <li>Must be at least 8 characters long</li>
      <li>Must include at least 1 numeric character (0-9)</li>
      <li>Must include at least 1 alphabetic character (a-z)</li>
      <li>Must not match your 4 previous password</li>
    </ul>
  </PasswordInfo>
)

const PasswordReset = ({
  password,
  passwordConfirm,
  changeMode,
  handleChange,
  handleReset,
  error,
  working,
  theme
}: Props) => {
  const dispatch = useDispatch()

  const onSubmit = event => {
    event.preventDefault()

    passwordSchema
      .validate({ password, passwordConfirm })
      .then(() => handleReset(event))
      .catch(err => {
        dispatch(showSnackbar(err.message, 'error'))
      })
  }

  return (
    <PasswordResetWrapper>
      <FormStyled onSubmit={onSubmit}>
        <Flex
          marginTop="-25px"
          marginBottom="20px"
          direction="column"
          alignStart
          fullWidth>
          <Title>Reset Your Password</Title>
          Please enter and confirm a new password.
        </Flex>
        <Flex>
          <div>
            <TextInput
              fullWidth
              placeholder="password"
              type="password"
              value={password}
              onChange={handleChange}
              id="password"
            />
            <TextInput
              fullWidth
              placeholder="confirm password"
              type="password"
              value={passwordConfirm}
              onChange={handleChange}
              id="passwordConfirm"
            />

            <Hidden smUp>
              <PasswordHelper fullWidth />
            </Hidden>

            <ButtonsWrapper marginTop="60px">
              <Button
                id="backToLoginBtn"
                disableRipple
                link
                color={theme.palette.secondary.main}
                onClick={() => {
                  changeMode('login')
                }}>
                back to login
              </Button>
              <Button
                id="passwordResetBtn"
                disableRipple
                classes={{ label: 'login-label' }}
                animate={working && !error ? 'animate' : ''}
                fullWidth
                primary
                type="submit">
                Send
              </Button>
            </ButtonsWrapper>
          </div>
        </Flex>
      </FormStyled>

      <Hidden xsDown>
        <PasswordHelper />
      </Hidden>
    </PasswordResetWrapper>
  )
}

export default withTheme(PasswordReset)
