// @flow
import React from 'react'
import Flex from '@Common/Flex'
import { useSelector } from 'react-redux'
import { geyPaymentRemindersDetails } from 'zego-shared/store/payments/paymentReminders/selectors'
import { formatDateMMDD } from 'zego-shared/utils/date'
import { StyledButton, Contact, ContactLabel, ContactText } from '../styles'

type Props = {
  changeMode: () => null
}

const freqs = {
  M: 'monthly',
  Q: 'quarterly',
  B: 'bi-Annually',
  A: 'annually'
}

const ViewReminder = ({ changeMode }: Props) => {
  const {
    email,
    altEmail,
    mobile,
    altMobile,
    frequency,
    dateToSend
  } = useSelector(geyPaymentRemindersDetails)

  return (
    <>
      <Flex direction="column" marginBottom="50px">
        <Contact>
          <ContactLabel marginRight="32px">Delivery</ContactLabel>
        </Contact>
        <Flex marginLeft="24px" direction="column">
          <Contact>
            <ContactLabel marginRight="44px">Email</ContactLabel>
            <ContactText id="remindersEmail">{email}</ContactText>
          </Contact>
          {altEmail && (
            <Contact>
              <ContactLabel marginRight="24px">alt Email</ContactLabel>
              <ContactText id="remindersEmail">{altEmail}</ContactText>
            </Contact>
          )}
          <Contact>
            <ContactLabel marginRight="56px">Text</ContactLabel>
            <ContactText id="remindersText">{mobile}</ContactText>
          </Contact>
          {altMobile && (
            <Contact>
              <ContactLabel marginRight="34px">alt Text</ContactLabel>
              <ContactText id="remindersText">{altMobile}</ContactText>
            </Contact>
          )}
        </Flex>
        <Contact>
          <ContactLabel marginRight="32px">Frequency</ContactLabel>
          <ContactText id="remindersText">{freqs[frequency]}</ContactText>
        </Contact>
        <Contact>
          <ContactLabel marginRight="32px">Delivery Start Date</ContactLabel>
          <ContactText id="remindersText">
            {formatDateMMDD(dateToSend)}
          </ContactText>
        </Contact>
      </Flex>
      <Flex justifyRight>
        <StyledButton id="editReminderBtn" onClick={changeMode} primary>
          Edit
        </StyledButton>
      </Flex>
    </>
  )
}

export default ViewReminder
