// @flow
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import Flex from '@Common/Flex'
import { FabButton } from '@Common/Button'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import { withTheme } from 'emotion-theming'
import { PhoneNumber } from '@Common/Form/Masks'
import {
  formatDateMMDD,
  formatDateYYYYMMDD,
  getTommorowDate,
  isLessThanOrEqualsToday
} from 'zego-shared/utils/date'
import ScheduleInput from './DeliveryDateInput'
import { CheckBox, RadioButton, Select } from '@Common/Form'
import { getHasError } from '@utils/view'
import { updateReminders } from 'zego-shared/store/payments/paymentReminders/actions'
import {
  StyledButton,
  Contact,
  ContactLabel,
  SectionTitle,
  StyledTextInput
} from '../styles'
import {
  StyledInputLane,
  IconWrap,
  InputWrap,
  BottomWrap,
  StyledCard,
  SelecFieldsFlex,
  ErrorMessage
} from './styles'

import { paymentRemindersSchema } from './schemas'
import { geyPaymentRemindersDetails } from 'zego-shared/store/payments/paymentReminders/selectors'
import type { Theme } from '../../../../theme'
import { formatMobileDigitsOnly } from 'zego-shared/utils/string'

type Props = {
  theme: Theme,
  changeMode: () => null
}

const frequencies = [
  { label: 'Monthly', value: 'M' },
  { label: 'Quarterly', value: 'Q' },
  { label: 'Bi-Annually', value: 'B' },
  { label: 'Annually', value: 'A' }
]

const EditPaymentReminders = ({ theme, changeMode }: Props) => {
  const {
    email,
    altEmail,
    mobile,
    altMobile,
    frequency,
    dateToSend,
    remindersEnabled
  } = useSelector(geyPaymentRemindersDetails)

  const dispatch = useDispatch()
  const [hasAltEmail, setHasAltEmail] = React.useState(altEmail ? true : false)
  const [hasAltMobile, setHasAltMobile] = React.useState(
    altMobile ? true : false
  )
  const [isSending, setisSending] = React.useState(
    remindersEnabled ? true : false
  )
  const [isEmailChecked, setIsEmailChecked] = React.useState(
    remindersEnabled && email ? true : false
  )
  const [isMobileChecked, setIsMobileChecked] = React.useState(
    remindersEnabled && mobile ? true : false
  )

  const formik = useFormik({
    initialValues: {
      email,
      altEmail,
      mobile,
      altMobile,
      frequency,
      dateToSend,
      remindersEnabled
    },
    validationSchema: paymentRemindersSchema,
    validateOnChange: true,
    onSubmit: values => {
      const payload = {
        ...values,
        dateToSend: formatDateYYYYMMDD(values.dateToSend),
        email: isEmailChecked ? values.email : '',
        altEmail: isEmailChecked ? values.altEmail : '',
        mobile: isMobileChecked ? formatMobileDigitsOnly(values.mobile) : '',
        altMobile: isMobileChecked
          ? formatMobileDigitsOnly(values.altMobile)
          : '',
        remindersEnabled: !isMobileChecked && !isEmailChecked ? false : true
      }
      dispatch(updateReminders(payload))
      changeMode()
    }
  })

  const hasError = getHasError(formik.errors, formik.touched)
  const isSSO = theme.ux.isSSO

  const removeAltMobile = () => {
    formik.setFieldValue('altMobile', '')
    setHasAltMobile(false)
  }

  const addAltMobile = () => {
    setHasAltMobile(true)
  }

  const addAltEmail = () => {
    setHasAltEmail(true)
  }

  const removeAltEmail = () => {
    formik.setFieldValue('altEmail', '')
    setHasAltEmail(false)
  }

  const handDateSelect = (day: string) => {
    formik.setFieldValue('dateToSend', day)
  }

  const handleIsSending = (isEnabled: boolean) => {
    setisSending(isEnabled)
    formik.setFieldValue('remindersEnabled', isEnabled)

    if (!isEnabled) {
      setIsMobileChecked(false)
      setIsEmailChecked(false)
    }
  }

  const getSSOViewWrapper = (isSSO, children) => {
    if (isSSO) {
      return <StyledCard>{children}</StyledCard>
    }

    return children
  }

  const altEmailExistsCheck = () => {
    if (!formik.values.email && hasAltEmail) {
      return true
    } else {
      return false
    }
  }

  const altMobileExistsCheck = () => {
    if (!formik.values.mobile && hasAltMobile) {
      return true
    } else {
      return false
    }
  }

  const duplicateEntryCheck = (value1: string, value2: string) => {
    return value1 === value2
  }

  const emptyValuesErrorCheck = () => {
    if (!formik.values.email && !formik.values.mobile) {
      return true
    } else {
      return false
    }
  }

  const handleEmailCheckbox = (event: SyntheticEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget
    setIsEmailChecked(checked)
  }
  const handleMobileCheckbox = (event: SyntheticEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget
    setIsMobileChecked(checked)
  }

  const altMobileErorr = altMobileExistsCheck()
  const altEmailErorr = altEmailExistsCheck()
  const emptyValuesError = emptyValuesErrorCheck() && isSending
  const isDuplicateMobile =
    duplicateEntryCheck(formik.values.mobile, formik.values.altMobile) &&
    isMobileChecked

  const isDuplicateEmail =
    duplicateEntryCheck(formik.values.email, formik.values.altEmail) &&
    isEmailChecked

  const shouldDisableSubmit =
    altMobileErorr ||
    altEmailErorr ||
    emptyValuesError ||
    isDuplicateEmail ||
    isDuplicateMobile ||
    formik.errors.mobile ||
    formik.errors.email ||
    formik.errors.altMobile ||
    formik.errors.altEmail

  const getSelectedDate = (date: Date) => {
    return isLessThanOrEqualsToday(date) ? getTommorowDate() : date
  }

  return (
    <>
      {!isSSO && <SectionTitle>Payment Reminders</SectionTitle>}
      <form onSubmit={formik.handleSubmit}>
        {getSSOViewWrapper(
          isSSO,
          <>
            <Contact>
              <ContactLabel marginRight="70px" alignCenter>
                Status
              </ContactLabel>
              <Flex>
                <RadioButton
                  id="sendingRadioBtn"
                  label="Sending"
                  value="sending"
                  name="sending"
                  onChange={() => handleIsSending(true)}
                  selected={isSending}
                />
                <RadioButton
                  id="notSendingRadioBtn"
                  label="Not Sending"
                  value="not sending"
                  name="notSending"
                  onChange={() => handleIsSending(false)}
                  selected={!isSending}
                />
              </Flex>
            </Contact>
            <Contact>
              <ContactLabel marginRight="32px">Delivery</ContactLabel>
            </Contact>
            <Flex direction="column">
              <StyledInputLane marginBottom="20px" justifySpaceBetween>
                <CheckBox
                  checked={isSending && isEmailChecked}
                  onChange={isSending ? handleEmailCheckbox : () => null}
                  label="Email"
                />
                <Flex marginTop="5px">
                  <InputWrap>
                    <StyledTextInput
                      className="input"
                      label="Email Address"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      error={hasError('email') || altEmailErorr}
                      id="email"
                    />
                    {formik.errors.email ? (
                      <ErrorMessage>{formik.errors.email}</ErrorMessage>
                    ) : (
                      altEmailErorr
                    )}
                  </InputWrap>
                  <IconWrap>
                    {!hasAltEmail && (
                      <FabButton
                        background={theme.palette.primary.main}
                        size="24px"
                        onClick={addAltEmail}
                        component={() => <AddIcon />}
                      />
                    )}
                  </IconWrap>
                </Flex>
              </StyledInputLane>
              {hasAltEmail && (
                <StyledInputLane justifyRight>
                  <Flex marginTop="20px">
                    <InputWrap>
                      <StyledTextInput
                        className="input"
                        label="Secondary Email Address"
                        onChange={formik.handleChange}
                        error={hasError('altEmail')}
                        value={formik.values.altEmail}
                        id="altEmail"
                      />
                      {isDuplicateEmail ? (
                        <ErrorMessage>
                          Email & Alt Email Cannot Be The Same
                        </ErrorMessage>
                      ) : null}
                      {formik.errors.altEmail ? (
                        <ErrorMessage>{formik.errors.altEmail}</ErrorMessage>
                      ) : (
                        altEmailErorr
                      )}
                    </InputWrap>
                    <IconWrap>
                      <FabButton
                        background={theme.palette.error.main}
                        size="24px"
                        onClick={removeAltEmail}
                        component={() => <CloseIcon />}
                      />
                    </IconWrap>
                  </Flex>
                </StyledInputLane>
              )}
              <StyledInputLane justifySpaceBetween>
                <CheckBox
                  checked={isSending && isMobileChecked}
                  onChange={isSending ? handleMobileCheckbox : () => null}
                  label="Text"
                />
                <Flex marginTop="5px">
                  <InputWrap>
                    <StyledTextInput
                      className="input"
                      label="Mobile Number"
                      maskInput={PhoneNumber}
                      error={hasError('mobile') || altMobileErorr}
                      onChange={formik.handleChange}
                      value={formik.values.mobile}
                      id="mobile"
                    />
                    {formik.errors.mobile ? (
                      <ErrorMessage>{formik.errors.mobile}</ErrorMessage>
                    ) : (
                      altMobileErorr
                    )}
                  </InputWrap>
                  <IconWrap>
                    {!hasAltMobile && (
                      <FabButton
                        background={theme.palette.primary.main}
                        size="24px"
                        onClick={addAltMobile}
                        component={() => <AddIcon />}
                      />
                    )}
                  </IconWrap>
                </Flex>
              </StyledInputLane>
              {hasAltMobile && (
                <StyledInputLane justifyRight>
                  <Flex marginTop="20px">
                    <InputWrap>
                      <StyledTextInput
                        className="input"
                        label="Secondary Mobile Number"
                        maskInput={PhoneNumber}
                        error={hasError('altMobile')}
                        onChange={formik.handleChange}
                        value={formik.values.altMobile}
                        id="altMobile"
                      />
                      {isDuplicateMobile ? (
                        <ErrorMessage>
                          Phone Number and Alt Phone Number Cannot Be The Same
                        </ErrorMessage>
                      ) : null}
                      {formik.errors.altMobile ? (
                        <ErrorMessage>{formik.errors.altMobile}</ErrorMessage>
                      ) : (
                        altMobileErorr
                      )}
                    </InputWrap>
                    <IconWrap>
                      <FabButton
                        background={theme.palette.error.main}
                        size="24px"
                        onClick={removeAltMobile}
                        component={() => <CloseIcon />}
                      />
                    </IconWrap>
                  </Flex>
                </StyledInputLane>
              )}
            </Flex>
            <BottomWrap marginTop="16px" justifySpaceBetween>
              <SelecFieldsFlex>
                <InputWrap
                  marginRight="16px"
                  mobWidth={isSSO ? '50%' : '100%'}
                  mobMarginBotom="32px">
                  <Select
                    options={frequencies}
                    label="Frequencies"
                    initialValue={formik.values.frequency}
                    onChange={formik.handleChange}
                    value={formik.values.frequency}
                    id="frequency"
                    name="frequency"
                    error={hasError('frequency')}></Select>
                </InputWrap>
                <InputWrap mobWidth={isSSO ? '50%' : '100%'}>
                  <ScheduleInput
                    id="dateToSend"
                    label="Delivery Start Date"
                    value={formatDateMMDD(formik.values.dateToSend)}
                    handleChange={handDateSelect}
                    selectedDay={getSelectedDate(formik.values.dateToSend)}
                  />
                </InputWrap>
              </SelecFieldsFlex>
            </BottomWrap>
          </>
        )}
        <BottomWrap justifySpaceBetween mobMarginBotom="32px">
          <InputWrap marginRight="32px" mobWidth={isSSO ? '50%' : '100%'}>
            <StyledButton
              id="cancelBtn"
              onClick={changeMode}
              primary
              outline
              fullWidth>
              Cancel
            </StyledButton>
          </InputWrap>
          <InputWrap marginRight="16px" mobWidth={isSSO ? '50%' : '100%'}>
            <StyledButton
              id="saveBtn"
              type="submit"
              primary
              fullWidth
              disabled={shouldDisableSubmit}>
              Save
            </StyledButton>
          </InputWrap>
        </BottomWrap>
      </form>
    </>
  )
}

export default withTheme(EditPaymentReminders)
