// @flow

import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import { getDisableSummaryMenuTab } from 'zego-shared/store/pmSettings/selectors'
import { getUnitId } from 'zego-shared/store/select/selectors'
import { nativePayer, hasWorkOrder } from 'zego-shared/store/settings/selectors'

type HomeProps = {
  unitId: string | number,
  hasWorkOrder: boolean,
  nativePayer: boolean,
  disabledSummaryMenuTab: Boolean,
  residentId: string
}

export const mapStateToProps = (state: Object) => ({
  unitId: getUnitId(state),
  hasWorkOrder: hasWorkOrder(state),
  nativePayer: nativePayer(state),
  disabledSummaryMenuTab: getDisableSummaryMenuTab(state),
  residentId: getUserId(state)
})

const Home = ({
  unitId,
  hasWorkOrder,
  nativePayer,
  disabledSummaryMenuTab,
  residentId
}: HomeProps) => {
  const url = disabledSummaryMenuTab
    ? `/payments/${residentId}/one-time`
    : nativePayer
    ? '/payments/summary'
    : hasWorkOrder
    ? `/units/${unitId}/work-orders/summary`
    : '/download-app'
  return <Redirect to={url} />
}

export default connect(mapStateToProps, null)(Home)
