// @flow
import React from 'react'
import { useTheme } from '@material-ui/core'
import AppBar from './AppBar'
import type { Props } from './AppBar'
import SSOAppBar from './SSOAppBar'

export default (props: Props) => {
  const theme = useTheme()

  return theme.ux.isSSO ? <SSOAppBar {...props} /> : <AppBar {...props} />
}
