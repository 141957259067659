import styled from '@emotion/styled'

export const StyledToolTip = styled.div`
  width: 240px;
  height: 92px;
  padding: ${({ theme }) => theme.space.s10};
  background-color: ${({ theme }) => theme.palette.common.iceberg};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  border: ${({ theme }) => `1px solid ${theme.palette.secondary.light}`};
  line-height: 18px;
`

export const DownArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: ${({ theme }) => `16px solid ${theme.palette.common.iceberg}`};
  margin-right:  ${({ theme }) => theme.space.s10};
}
`
