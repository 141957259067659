// @flow
import React from 'react'
import { Grid } from '@material-ui/core'
import { TitleText, BodyText, StyledButton } from './styles'
import { StyledCard } from '../styles'

type Props = {
  onClick: Function
}

const LinkPropertyCard = ({ onClick }: Props) => {
  return (
    <StyledCard outline>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="stretch">
        <Grid item>
          <TitleText>I already have a Zego login</TitleText>
        </Grid>
        <Grid item>
          <BodyText>Link this property to my existing Zego account</BodyText>
        </Grid>
        <Grid item>
          <StyledButton
            outline
            disableRipple
            primary
            fullWidth
            type="button"
            onClick={onClick}>
            Link Property
          </StyledButton>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

export default LinkPropertyCard
