// @flow
import React from 'react'
import { GridItem } from '@Common/Grid'
import Flex from '@Common/Flex'
import { CRPageTitle, LCNotice, LookBackHeaderDiv } from './styles'
import { StyledCard } from '../styles'
import DetailsItem from '../DetailsItem'
import { FabButton } from '@Common/Button'
import { useHistory } from 'react-router-dom'
import { Arrow } from '@icons'
import { useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import {
  getCreditReportingDetails,
  getCreditReportingPurchaseDetails,
  hasPurchasedLookback
} from 'zego-shared/store/payments/creditReporting/selectors'
import {
  DetailsItemType,
  CreditReportingDetails,
  CreditReportingPurchaseDetails
} from '../types'

const MyDetails = () => {
  const history = useHistory()
  const theme = useTheme()
  const hasPurchaseDetails: boolean = useSelector(hasPurchasedLookback)
  const detailsPurchase: CreditReportingPurchaseDetails = useSelector(
    getCreditReportingPurchaseDetails
  )
  const details: CreditReportingDetails = useSelector(getCreditReportingDetails)
  const { contactInfo, basicInfo } = details
  const { purchaseInfo, paymentInfo } = detailsPurchase
  const isSSO = theme.ux.isSSO

  return (
    <GridItem columnStart={1} columnSpan={12}>
      <Flex justifySpaceBetween alignCenter>
        <Flex direction="column">
          <CRPageTitle id="creditReportingHeader">Credit Reporting</CRPageTitle>
          <LCNotice>with LevelCredit</LCNotice>
        </Flex>
        <Flex>
          <FabButton
            onClick={history.goBack}
            size={isSSO ? '36px' : '45px'}
            background={theme.palette.tertiary.main}
            component={() => (
              <Arrow
                id="backArrow"
                name="arrow"
                width="16px"
                height="12px"
                fill={theme.palette.primary.main}
              />
            )}
          />
        </Flex>
      </Flex>
      <StyledCard customPadding={isSSO && '19px 42px'}>
        <Flex direction="column">
          {basicInfo.map((item: DetailsItemType) => {
            return <DetailsItem {...item} key={item.label} />
          })}
        </Flex>
        <Flex direction="column">
          {contactInfo.map((item: DetailsItemType) => {
            return <DetailsItem {...item} key={item.label} />
          })}
        </Flex>
      </StyledCard>

      {hasPurchaseDetails && (
        <StyledCard
          hasPurchaseDetails={hasPurchaseDetails}
          customPadding={isSSO && '19px 42px '}>
          <Flex direction="column">
            <LookBackHeaderDiv>Lookback Purchase Receipt</LookBackHeaderDiv>
          </Flex>
          <Flex direction="column"></Flex>

          <Flex direction="column">
            {purchaseInfo.map((item: DetailsItemType) => {
              return <DetailsItem {...item} key={item.label} />
            })}
          </Flex>
          <Flex direction="column">
            {paymentInfo.map((item: DetailsItemType) => {
              return <DetailsItem {...item} key={item.label} />
            })}
          </Flex>
        </StyledCard>
      )}
    </GridItem>
  )
}

export default MyDetails
