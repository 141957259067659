// @flow

import { connect } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import {
  getTransaction,
  hasHistory,
  getResidentFullName,
  getRecipient
} from 'zego-shared/store/payments/history/selectors'
import { fetchUserResource } from 'zego-shared/store/userResource/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { fetchPaymentHistory } from 'zego-shared/store/payments/history/actions'
import { withFetchData } from 'zego-shared/views'
import Transaction from './Transaction'

const transactionWithFetchData = withFetchData(Transaction)

const fetchingSelector = createIsFetchingSelector([
  'property',
  'paymenthistorysection'
])

export const mapStateToProps = (
  state: Object,
  {
    match: {
      params: { residentId, transId }
    }
  }: BrowserRouter
) => ({
  recipient: getRecipient(state),
  residentName: getResidentFullName(state),
  transaction: getTransaction(state, parseInt(transId, 10)),
  isFetching: fetchingSelector(state),
  hasHistory: hasHistory(state),
  residentId
})

export const mapDispatchToProps = (
  dispatch: Object => mixed,
  {
    match: {
      params: { residentId }
    }
  }: BrowserRouter
) => ({
  fetchData: (hasHistory: boolean) => {
    dispatch(fetchUserResource(residentId))
    if (!hasHistory) dispatch(fetchPaymentHistory(residentId))
  }
})

export const mergeProps = (
  stateProps: Object,
  dispatchProps: Object,
  ownProps: Object
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  fetchData() {
    dispatchProps.fetchData(stateProps.hasHistory)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(transactionWithFetchData)
