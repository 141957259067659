// @flow
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getIsAuth } from 'zego-shared/store/authorization/selectors'
import AppContainer from '../views/AppContainer'
import belowFoldBgLC from '@images/below_fold_BG_LC.png'
import { useTheme } from '@material-ui/core/styles'

const getFullBackgroundImage = pathname => {}

const getRightBackgroundImage = pathname => {}

const getLeftBackgroundImage = pathname => {
  const splitPath = pathname.split('/')
  if (
    ['payments', ''].includes(splitPath[1]) ||
    splitPath[3] === 'work-orders' ||
    splitPath[3] === 'marketplace'
  ) {
    return belowFoldBgLC
  }
}

type PrivateRouteType = {
  component: Function,
  hideAppContainer?: boolean,
  isAuth?: boolean
}

const PrivateRoute = ({
  component: Component,
  hideAppContainer,
  isAuth,
  ...props
}: PrivateRouteType) => {
  const theme = useTheme()

  return (
    <Route
      {...props}
      render={props =>
        isAuth ? (
          hideAppContainer ? (
            <Component {...props} />
          ) : (
            <AppContainer
              fullImageSrc={getFullBackgroundImage(props.location.pathname)}
              leftImageSrc={
                theme.ux.isSSO
                  ? null
                  : getLeftBackgroundImage(props.location.pathname)
              }
              rightImageSrc={getRightBackgroundImage(props.location.pathname)}>
              <Component {...props} />
            </AppContainer>
          )
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

const mapStateToProps = state => ({
  isAuth: getIsAuth(state)
})

export default withRouter(connect(mapStateToProps)(PrivateRoute))
