import { SELECT_COMPANY, COMPANIES_FETCH_SECTION } from '../actions'
import withNormalizedFetch from '../../../helpers/reducers/withNormalizedFetch'

const initialState = {
  byId: {},
  allIds: [],
  selected: undefined
}

const companies = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_COMPANY:
    if (action.id) {
      return {
        ...state,
        selected: state.byId[action.id]
      }
    } else {
      // if there is no id, just select first one
      const firstCompanyId = state.allIds[0]
      const firstCompany = state.byId[firstCompanyId]

      return {
        ...state,
        selected: firstCompany
      }
    }
    default:
      return state
  }
}

export default withNormalizedFetch(COMPANIES_FETCH_SECTION)(companies)
