// @flow

import React, { useEffect, useState } from 'react'
import { Grid } from '@Common/Grid'
import { GridItem } from '@Common/Grid'
import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'
import WithLeftSideNav from '@Common/WithLeftSideNav'
import { FabButton } from '@Common/Button'
import { Arrow } from '@icons'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import {
  PageTitle,
  DetailsImageCard,
  DetailsImageHeader,
  DetailsImage,
  DetailsImageSeparator,
  DetailsSummaryCard,
  DetailsLabel,
  DetailsContent,
  DetailsText,
  GreenTopFlare,
  RedeemLink,
  RedeemDiv
} from './styles'

type Props = {
  dealId: number,
  deals: Object,
  deal: Object,
  isFetching: boolean
}

const MarketplaceDetails = (props: Props) => {

  const [deal, setDeal] = useState(props.deal)

  useEffect(() => {
    setDeal(props.deal)
  }, [props])

  const
    {
      id,
      image,
      description,
      discount,
      url,
      companyName,
      category
    } = deal
  const sideLinks = []

  const theme = useTheme()
  const dealTabId = `deal_${id}`
  const dealUrl = url && (url.toLowerCase().substring(0, 4) !== 'http') ?
    `http://${url}` : url

  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <>
      <WithLeftSideNav routes={sideLinks}>
        <Grid auto>
          <GridItem columnStart={1} columnSpan={10} rowStart={1}>
            <PageTitle>{companyName} - Details</PageTitle>
          </GridItem>
          <GridItem columnStart={isXs ? 11 : 12} columnSpan={2} rowStart={1}>
            {/* TODO: Back button goes here */}
            <Link to={'../list'}>
              <Box display="flex" alignItems="center" mr={6}>
                <FabButton
                  background={theme.palette.tertiary.main}
                  component={() => (
                    <Arrow
                      id="backArrow"
                      name="arrow"
                      width="16px"
                      height="12px"
                      fill={theme.palette.primary.main}
                    />
                  )}
                />
              </Box>
            </Link>
          </GridItem>
          <GridItem columnStart={1} columnSpan={isXs ? 12 : 5} rowStart={2} rowSpan={isXs ? 4 : 11}>
            <GreenTopFlare />
            <DetailsImageCard>
              <DetailsImageHeader>{companyName}</DetailsImageHeader>
              <DetailsImageSeparator />
              <DetailsImage style={{ marginTop: '10px' }} src={image} />
            </DetailsImageCard>
          </GridItem>
          <GridItem columnStart={isXs ? 1 : 6} columnSpan={isXs ? 12 : 6} rowStart={isXs ? 6 : 2} rowSpan={isXs ? 4 : 11}>
            <DetailsSummaryCard>
              <DetailsContent>
                <DetailsLabel>Category:</DetailsLabel>
                <DetailsText className="categoryTxt">{category}</DetailsText></DetailsContent>
              <DetailsContent>
                <DetailsLabel>Discount:</DetailsLabel>
                <DetailsText className="discountTxt">{discount}</DetailsText></DetailsContent>
              <DetailsContent>
                <DetailsText className="descriptionTxt">{description}</DetailsText>
              </DetailsContent>
              {dealUrl && <RedeemDiv>
                <RedeemLink className="redeemLink" href={dealUrl} target={dealTabId}>Redeem Now</RedeemLink>
              </RedeemDiv>
              }
            </DetailsSummaryCard>
          </GridItem>
        </Grid>
      </WithLeftSideNav>
    </>
  )
}

export default MarketplaceDetails
