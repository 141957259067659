import gql from 'graphql-tag'

export const createOneTimePayment = gql`
  mutation($residentId: ID!, $post: CreateOneTimePaymentInput!) {
    createOneTimePayment(input: { id: $residentId, post: $post }) {
      transId
    }
  }
`

export const initiatePayPalPayment = gql`
  mutation(
    $paymentFields: [PaymentFieldInput!]!
    $feeAmount: MoneyInput!
    $unitAmount: MoneyInput!
    $totalAmount: MoneyInput!
    $isExpressPay: Boolean
    $returnUrl: String
    $cancelUrl: String
  ) {
    initiatePayPalPayment(
      input: {
        paymentFields: $paymentFields
        feeAmount: $feeAmount
        unitAmount: $unitAmount
        totalAmount: $totalAmount
        isExpressPay: $isExpressPay
        returnUrl: $returnUrl
        cancelUrl: $cancelUrl
      }
    ) {
      payPalOrderId
      approvalLink
    }
  }
`

export const getPayPalPaymentMethod = gql`
  mutation($orderId: String!) {
    getPayPalPaymentMethod(input: { orderId: $orderId }) {
      paymentMethodId
      payPalEmail
    }
  }
`

export const requestPaymentReceipt = gql`
  mutation($transactionId: Int!, $email: String!) {
    requestPaymentReceipt(
      input: { transactionId: $transactionId, email: $email }
    )
  }
`
