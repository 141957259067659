import { createFetchActions } from '../../../helpers/actions/creators'

export const CLIENT_NOTES_SECTION = 'CLIENT_NOTES'

export const {
  fetchAction: fetchClientNotesAction,
  fetchStartAction: fetchClientNotesStart,
  fetchSuccessAction: fetchClientNotesSuccess,
  fetchErrorAction: fetchClientNotesError
} = createFetchActions(CLIENT_NOTES_SECTION)

export const fetchClientNotes = () => fetchClientNotesAction()
