import {
  SAVE_AUTH,
  UPDATE_USER_OPTIMISTIC,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  VALIDATE_SIGN_UP_LINK_SUCCESS,
  VALIDATE_SIGN_UP_LINK_FAILURE,
  SIGN_UP_SUCCESS,
  UPDATE_SENDBIRD_INFO,
  LOGIN_FAILED,
  START_SIGN_UP,
  SIGN_UP_ERROR
} from '../actions/'
import { DO_NOT_SHOW_TERMS_AND_CONDITIONS } from 'zego-shared/store/termsAndConditions/actions'

export const initialState = {
  isAuth: false,
  id: undefined,
  profile: undefined,
  token: undefined,
  refreshToken: undefined,
  sendbirdToken: undefined,
  sendbirdAppID: undefined,
  sendbirdUserID: undefined,
  isValidSignupToken: undefined,
  signupSuccessful: undefined,
  isSigningUp: undefined,
  loginFailed: undefined,
  isAdminLogin: false
}

const authorization = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_AUTH: {
      const {
        profile,
        info: {
          id,
          id_token: token,
          refresh_token: refreshToken,
          sendbird_token: sendbirdToken,
          sendbird_app_id: sendbirdAppID,
          sendbird_user_id: sendbirdUserID,
          magic_link: magicLink
        }
      } = action

      const { signupSuccessful, isAdminLogin } = state
      let { payment_token_info: paymentTokenInfo } = action.info

      if (paymentTokenInfo) {
        const {
          access_token: accessToken,
          expires_at: expiresAt,
          other_params: otherParams,
          refresh_token: paymentRefreshToken,
          token_type: tokenType
        } = paymentTokenInfo

        paymentTokenInfo = {
          accessToken,
          expiresAt,
          otherParams,
          paymentRefreshToken,
          tokenType
        }
      }

      return {
        isAuth: true,
        isAdminLogin,
        id,
        profile,
        token,
        refreshToken,
        sendbirdToken,
        sendbirdAppID,
        sendbirdUserID,
        paymentTokenInfo,
        magicLink,
        ...(signupSuccessful != null ? { signupSuccessful } : {})
      }
    }
    case UPDATE_USER_OPTIMISTIC:
    case UPDATE_USER_SUCCESS:
    case UPDATE_USER_ERROR:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.attributes
        }
      }
    case VALIDATE_SIGN_UP_LINK_SUCCESS:
      return {
        ...state,
        isValidSignupToken: 'YES'
      }
    case VALIDATE_SIGN_UP_LINK_FAILURE:
      return {
        ...state,
        isValidSignupToken: 'NO'
      }
    case START_SIGN_UP:
      return {
        ...state,
        isSigningUp: true
      }
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signupSuccessful: true,
        isSigningUp: false
      }
    case SIGN_UP_ERROR:
      return {
        ...state,
        isSigningUp: false
      }
    case UPDATE_SENDBIRD_INFO: {
      const {
        sendbirdInfo: { sendbirdToken, sendbirdAppID, sendbirdUserID }
      } = action

      return {
        ...state,
        sendbirdToken,
        sendbirdAppID,
        sendbirdUserID
      }
    }
    case LOGIN_FAILED:
      return {
        ...state,
        loginFailed: true
      }
    case DO_NOT_SHOW_TERMS_AND_CONDITIONS:
      return { ...state, isAdminLogin: true }
    default:
      return state
  }
}

export default authorization
