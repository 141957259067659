// @flow
import get from 'lodash/get'
import moment from 'moment'
import { formatPhoneNumber } from 'zego-shared/utils/string'
import {
  getUserEmail,
  getUserPhoneNumber
} from '../../../authorization/selectors'
import { getTommorowDate } from 'zego-shared/utils/date'
type RemindersData = {
  email: string,
  altEmail: string,
  mobile: string,
  altMobile: string,
  frequency: string,
  dateToSend: string,
  remindersEnabled: boolean
}

export const hasPaymentReminders = (state: Object) =>
  get(state, ['payments', 'paymentReminders', 'remindersEnabled'], false)

export const getRemindersData = (
  reminders: RemindersData,
  userEmail: string,
  userPhone: string
) => {
  const {
    email,
    altEmail,
    mobile,
    altMobile,
    frequency,
    dateToSend,
    remindersEnabled
  } = reminders
  return {
    email: email ? email : !remindersEnabled ? userEmail : '',
    altEmail,
    mobile: mobile
      ? formatPhoneNumber(mobile)
      : !remindersEnabled
        ? formatPhoneNumber(userPhone)
        : '',
    altMobile: formatPhoneNumber(altMobile),
    frequency: frequency ? frequency : 'M',
    dateToSend: dateToSend ? moment(dateToSend).toDate() : getTommorowDate(),
    remindersEnabled
  }
}

export const geyPaymentRemindersDetails = (state: Object) => {
  const userEmail = getUserEmail(state)
  const userPhone = getUserPhoneNumber(state)
  const reminders = get(state, ['payments', 'paymentReminders'], {})
  return getRemindersData(reminders, userEmail, userPhone)
}
