import axios from 'axios'
import moment from 'moment'
import log from '../logger'
import get from 'lodash/get'
import { buildAPIUrl, createHTTPHeader } from './utils'
import Raven from '../sentry'

let API_URL,
  LOGIN_URL,
  REFRESH_TOKEN_URL,
  BASE_USERS_URL,
  BASE_LINKED_ACCOUNTS_URL,
  BASE_EVENT_URL,
  APP_TYPE,
  BASE_PROPERTIES_URL,
  BASE_UNITS_URL,
  BASE_TERMS_AND_CONDITIONS_URL,
  BASE_CARDS_URL

const GRAPHQL_ENDPOINT = window._env_.REACT_APP_GRAPHQL_ENDPOINT
const AUTO_PAY_STATE_URL = window._env_.REACT_APP_AUTO_PAY_STATE_URL
const PAYMENT_STATUS_URL = window._env_.REACT_APP_PAYMENT_STATUS_URL
const MOCHI_ENDPOINT = window._env_.REACT_APP_MOCHI_ENDPOINT

const NETWORK_ERR_MSG = 'Network error'
const LOCAL_ERR_MSG = 'Something went wrong'
const UNKNOWN_ERR_MSG = 'Unknown error'
const HISTORY_REQUEST_DATE = moment()
  .subtract(1, 'month')
  .format('YYYY-MM-DD')

const deviceTypeMap = {
  all: ['door_lock', 'thermostat', 'switch'],
  lock: 'door_lock',
  switch: 'switch',
  thermostat: 'thermostat'
}

const NON_DISPLAY_EVENT_CODES = [
  0,
  3,
  4,
  100,
  200,
  2200,
  4000,
  5001,
  5002,
  5003,
  6001,
  6003,
  20001,
  20002,
  20003
]

const api = {
  configure: ({ url, appType }) => {
    API_URL = url
    LOGIN_URL = `${API_URL}/login`
    REFRESH_TOKEN_URL = `${API_URL}/login/refresh`
    BASE_USERS_URL = `${API_URL}/users`
    BASE_LINKED_ACCOUNTS_URL = `${API_URL}/resident/linked-accounts`
    BASE_PROPERTIES_URL = `${API_URL}/properties`
    BASE_UNITS_URL = `${API_URL}/units`
    BASE_CARDS_URL = `${API_URL}/cards`
    BASE_TERMS_AND_CONDITIONS_URL = `${API_URL}/terms-and-conditions`
    APP_TYPE = appType
  },

  getAutoPayState: token => {
    const headers = createHTTPHeader(token, APP_TYPE, true)
    const url = AUTO_PAY_STATE_URL
    return api.post(url, {}, headers)
  },

  resetPassViaEmail: email => {
    const url = `${API_URL}/password-reset-request`

    const body = {
      user_or_email: email
    }

    return api.post(url, body)
  },
  resetPassword: body => {
    const url = `${API_URL}/password-reset`
    return api.post(url, body)
  },
  checkActivationToken: token => {
    const url = `${BASE_USERS_URL}/check_token/${token}`
    return api.post(url)
  },

  getUserResource: (authToken, residentId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_USERS_URL}/${residentId}/get_user_resources`
    return api.get(url, headers)
  },

  getLatestTermsAndConditions: (
    authToken = '',
    residentId,
    tncType = 'resident'
  ) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_TERMS_AND_CONDITIONS_URL}/latest?type=${tncType}${
      residentId ? `&user_id=${residentId}` : ''
    }`
    return api.get(url, headers)
  },

  acceptTermsAndConditions: (authToken, id) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_TERMS_AND_CONDITIONS_URL}/accept/${id}`
    return api.post(url, {}, headers)
  },

  signUp: (token, data) => {
    const url = `${BASE_USERS_URL}/activate`
    const { password, firstName, lastName, email, phone, id } = data
    const body = {
      token,
      password,
      first_name: firstName,
      last_name: lastName,
      contact_email: email,
      phone_number: phone,
      terms_id: id
    }

    return api.post(url, body)
  },
  signUpSocial: (token, data, provider) => {
    const url = `${BASE_USERS_URL}/activate`
    const body = {
      token,
      provider,
      data
    }

    return api.post(url, body)
  },
  loginEmail: (user_or_email, password, spoofUserId) => {
    let body = {
      user_or_email,
      password
    }
    if (spoofUserId) {
      body.spoof_user_id = spoofUserId
    }
    return api.post(LOGIN_URL, body)
  },
  loginSocial: (provider, data) => {
    let body = {
      data
    }
    return api.post(`${LOGIN_URL}/${provider}`, body)
  },
  loginToken: loginToken => {
    const body = {
      login_token: loginToken
    }
    return api.post(LOGIN_URL, body)
  },
  loginAs: (userId, authToken) => {
    const url = `${BASE_LINKED_ACCOUNTS_URL}/log-as`
    const body = { linked_user_id: userId }
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.post(url, body, headers)
  },
  loginToLinkProperty: (email, password, activationToken) => {
    const url = `${BASE_USERS_URL}/link-new-account`
    const body = {
      parent_account_username: email,
      parent_account_password: password,
      token: activationToken
    }

    return api.post(url, body)
  },
  refreshToken: refresh_token => {
    let body = {
      refresh_token
    }
    return api.post(REFRESH_TOKEN_URL, body)
  },
  sendActivationEmail: (id, authToken) => {
    const url = `${BASE_USERS_URL}/${id}/send-confirmation`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.post(url, {}, headers)
  },

  getUnit: (unitId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUnitsUrl(unitId)

    return api.get(url, headers)
  },

  getUser: (userId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUserUrl({ userId, with_pms: true })

    return api.get(url, headers)
  },
  getUsers: (unitId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUsersUrl({
      unit_id: unitId,
      roles: 'resident',
      page_size: 100
    })

    return api.get(url, headers)
  },
  uploadProfileImage: (userId, file, authToken) => {
    const url = `${BASE_USERS_URL}/${userId}/upload`
    let headers = createHTTPHeader(authToken, APP_TYPE)

    const data = new FormData()

    data.append('file', file, file.name)
    return api.post(url, data, headers)
  },
  getPropertyUsers: (propertyId, authToken, name) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUsersUrl({
      property_id: propertyId,
      roles: 'property_staff,property_admin,company_admin',
      page_size: 100,
      name
    })

    return api.get(url, headers)
  },
  getPropertyResidents: (propertyId, authToken, page, name) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUsersUrl({
      property_id: propertyId,
      roles: 'resident',
      page_size: 8,
      page,
      name
    })

    return api.getWithHeaders(url, headers)
  },
  getPropertyInfo: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/info`
    return api.get(url, headers)
  },
  getPropertyStaff: (authToken, propertyId) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${propertyId}/staff`
    return api.get(url, headers)
  },
  getUnits: (authToken, _params) => {
    const url = getUnitsUrl({})
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },

  getCompaniesProperties: (authToken, id) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}?company_id=${id}&page_size=100&enabled=true`
    return api.get(url, headers)
  },

  getPropertyUsersSummary: (authToken, id) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${id}/user_summary`
    return api.get(url, headers)
  },

  createUser: (authToken, payload) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = BASE_USERS_URL

    const { email, role, unitId, propertyId, companyId } = payload
    const body = {
      email,
      role,
      ...(unitId ? { units: [unitId] } : {}),
      ...(propertyId ? { properties: [propertyId] } : {}),
      ...(companyId ? { companies: [companyId] } : {})
    }

    return api.post(url, body, headers)
  },
  deleteUser: (resource, resourceId, userId, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${API_URL}/${resource}/${resourceId}/unassign`

    let body = {
      user_id: userId
    }

    return api.post(url, body, headers)
  },

  getPropertySummary: (authToken, id) => {
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const url = `${BASE_PROPERTIES_URL}/${id}/summary`
    return api.get(url, headers)
  },

  getHistory: (authToken, unitID, deviceType) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getDeviceEventsUrl({ unitID, deviceType })

    return api.getWithHeaders(url, headers)
  },
  getWorkOrderHistory: (workOrderApiUrl, authToken, unitId) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getWorkOrdersUrl(workOrderApiUrl, unitId)

    return api.getWithHeaders(url, headers)
  },
  getWorkOrderOptions: (workOrderApiUrl, unitId, authToken) => {
    const url = `${getWorkOrdersUrl(workOrderApiUrl, unitId)}/options`
    const headers = createHTTPHeader(authToken)

    return api.get(url, headers)
  },
  updateUser: (user_id, attributes, authToken) => {
    let headers = createHTTPHeader(authToken, APP_TYPE)
    let url = getUsersUrl(encodeURI(user_id))
    let body = attributes

    return axios
      .put(url, body, { headers })
      .then(response => response.data)
      .catch(handleError)
  },
  getWorkOrderCount: (workOrderApiUrl, unitId, authToken) => {
    const url = `${workOrderApiUrl}/units/${unitId}/work_order_count`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getWorkOrderCategories: (unitId, authToken) => {
    const url = `${BASE_UNITS_URL}/${unitId}/work_order/categories`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  submitWorkOrder: (workOrderApiUrl, unitId, authToken, payload) => {
    const url = getWorkOrdersUrl(workOrderApiUrl, unitId)
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.post(url, payload, headers)
  },
  addFileToWorkOrder: (
    workOrderApiUrl,
    unitId,
    authToken,
    workOrderId,
    data,
    extraHeaders = {}
  ) => {
    const url = `${getWorkOrdersUrl(
      workOrderApiUrl,
      unitId
    )}/${workOrderId}/upload`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.post(url, data, { ...headers, ...extraHeaders })
  },
  getResidentSurvey: authToken => {
    const url = `${API_URL}/resident_surveys`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  dismissSurveyNotification: (authToken, surveyId) => {
    const url = `${API_URL}/resident_surveys/${surveyId}/dismiss`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.post(url, null, headers)
  },
  submitSurveyResponse: (authToken, surveyId, rating, feedback) => {
    const url = `${API_URL}/resident_surveys/${surveyId}?rating=${rating}`
    const headers = createHTTPHeader(authToken, APP_TYPE)
    const body = { feedback: feedback }

    return api.put(url, body, headers)
  },
  submitStandaloneWorkOrder: (authToken, payload) => {
    const url = `${MOCHI_ENDPOINT}/workorder`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.post(url, payload, headers)
  },
  getStandaloneWorkOrderCategories: authToken => {
    const url = `${MOCHI_ENDPOINT}/category`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getStandaloneWorkOrderHistory: authToken => {
    const url = `${MOCHI_ENDPOINT}/workorder/user`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getPaymentBalance: (userId, authToken) => {
    const url = `${BASE_USERS_URL}/${userId}/balance`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },
  getPaymentLink: authToken => {
    const url = `${BASE_USERS_URL}/payment-link`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },

  getSettings: authToken => {
    const url = `${BASE_USERS_URL}/mobile_settings`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },

  sendDownloadLink: (userID, phone, authToken) => {
    const url = `${BASE_USERS_URL}/${userID}/send_app_download`
    const headers = createHTTPHeader(authToken, APP_TYPE)
    return api.post(url, { phone_number: phone }, headers)
  },

  getLinkedAccounts: authToken => {
    const url = BASE_LINKED_ACCOUNTS_URL
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },

  getMarketplaceDeals: (propertyId, page, authToken) => {
    const url = `${BASE_CARDS_URL}?property_id=${propertyId}&page=${page}&type=service`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.getWithHeaders(url, headers)
  },

  post: (url, body, headers = createHTTPHeader('', APP_TYPE)) => {
    return axios
      .post(url, body, { headers })
      .then(response => response.data)
      .catch(handleError)
  },
  delete: (url, headers) => {
    return axios
      .delete(url, { headers })
      .then(response => response.data)
      .catch(handleError)
  },
  put: (url, body, headers) => {
    return axios
      .put(url, body, { headers })
      .then(response => response.data)
      .catch(handleError)
  },
  get: (url, headers) => {
    return axios
      .get(url, { headers })
      .then(response => response.data)
      .catch(handleError)
  },
  getWithHeaders: (url, headers) =>
    axios
      .get(url, { headers })
      .then(response => ({ data: response.data, headers: response.headers }))
      .catch(handleError),

  getUserPets: (userId, authToken) => {
    const url = `${BASE_USERS_URL}/${userId}/registration/pet/summary`
    const headers = createHTTPHeader(authToken, APP_TYPE)

    return api.get(url, headers)
  },

  getGraphqlEndpoint: () => GRAPHQL_ENDPOINT,

  graphqlQuery: (
    { paymentToken },
    query,
    variables = {},
    operationName = null
  ) => {
    const headers = paymentToken
      ? createHTTPHeader(paymentToken, APP_TYPE)
      : null
    const url = GRAPHQL_ENDPOINT
    const body = {
      query,
      variables,
      operationName
    }
    return api.post(url, body, headers)
  },
  fetchPaymentStatus: paymentToken => {
    const headers = createHTTPHeader(paymentToken, APP_TYPE, true)
    const body = {}
    return api.post(PAYMENT_STATUS_URL, body, headers)
  },
  setUserInterest: id => {
    const url = `${BASE_USERS_URL}/${id}/lease_interest_status`
    return api.put(url, { status_type: 'interested' }, '')
  },
  getActivationLink: id => {
    const url = `${BASE_USERS_URL}/${id}/activation_link`
    return api.get(url, {})
  }
}

const getUnitsUrl = params => {
  return buildAPIUrl(BASE_UNITS_URL, params)
}

const getUsersUrl = params => {
  return buildAPIUrl(BASE_USERS_URL, params)
}

const getUserUrl = ({ userId, ...params }) => {
  return buildAPIUrl(`${BASE_USERS_URL}/${userId}`, params)
}

const getDeviceEventsUrl = ({ unitID, deviceType }) => {
  const type = deviceTypeMap[deviceType] || deviceTypeMap.all
  return `${BASE_EVENT_URL}?inserted_at > ${HISTORY_REQUEST_DATE}T00:00:00.000Z&exclude_event_codes=${NON_DISPLAY_EVENT_CODES}&unit_id=${unitID}&page_size=${100}&device_type=${type}`
}

const getWorkOrdersUrl = (workOrderApiUrl, unitId) => {
  return `${workOrderApiUrl}/units/${unitId}/work_order`
}

const handleError = (error = {}) => {
  let message, statusCode, url, errorBody

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    log(error.response.status, error.response.data)
    const { friendly_error: friendlyError, errors } = error.response.data

    if (friendlyError || errors.details) {
      message = friendlyError || errors.details
    } else {
      message = UNKNOWN_ERR_MSG
    }

    url = get(error, 'response.config.url', '')
    statusCode = error.response.status
    errorBody = error.response.data
  } else if (error.request) {
    // The request was made but no response was received
    message = NETWORK_ERR_MSG
  } else {
    // Something happened in setting up the request that triggered an Error
    log('Error', error)
    message = LOCAL_ERR_MSG
  }

  let err = new Error(message)
  if (url) {
    err.url = url
  }
  if (statusCode) {
    err.statusCode = statusCode
  }
  if (error.response && typeof error.response.data.errors === 'object') {
    err.data = error.response.data.errors
  }

  const context = {
    url: err.url,
    statusCode: err.statusCode,
    errors: errorBody || {}
  }

  Raven.captureException(error, { extra: context })

  return Promise.reject(err)
}

export default api
