import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import { saveSelectedMethodType } from 'zego-shared/store/payments/methods/actions'
import { paymentMethodProps, BANK_ACCOUNT_TYPE } from '@utils/view'
import PaymentMethod from './PaymentMethod'
import Plaid from '../Plaid'
import { PaymentOptionsStyled, HeaderStyled } from './styles'

const PaymentMethodsList = ({
  paymentOptions,
  onSelectLinkedAccount,
  onMethodCreateSuccess,
  onPaymentMethodChanged
}) => {
  const residentUuid = useSelector(getUserId)
  const dispatch = useDispatch()
  let history = useHistory()

  const toggleAddPaymentMethod = (method: Object) => {
    onPaymentMethodChanged()
    dispatch(saveSelectedMethodType(method))
    let url = `/payments/${residentUuid}/one-time`
    history.push(url)
  }

  return (
    <>
      <HeaderStyled justifySpaceBetween alignCenter fullWidth>
        <h5>add a new payment method</h5>
      </HeaderStyled>

      <PaymentOptionsStyled alignCenter fullWidth>
        {paymentOptions.map(({ name, fee }) => {
          const props = paymentMethodProps[name]
          const isBankAccount = BANK_ACCOUNT_TYPE === name
          const PaymentMethodComponent = ({
            enabled = false,
            open = () => {}
          }) => (
            <PaymentMethod
              id={name.replace(/\s/g, '')}
              key={name}
              fee={fee}
              name={name}
              {...props}
              onClick={() => {
                if (enabled && open) {
                  open()
                } else {
                  toggleAddPaymentMethod(props)
                }
              }}
            />
          )
          if (isBankAccount) {
            return (
              <Plaid
                key={name}
                switchToManual={() => {
                  toggleAddPaymentMethod(props)
                }}
                render={PaymentMethodComponent}
                onSelectLinkedAccount={onSelectLinkedAccount}
                onMethodCreateSuccess={onMethodCreateSuccess}
              />
            )
          }
          return <PaymentMethodComponent key={name} />
        })}
      </PaymentOptionsStyled>
    </>
  )
}

export default PaymentMethodsList
