import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '../Button'

export const useStyles = makeStyles(theme => ({
  list: {
    width: 355,
    height: '100vh',
    boxShadow: '0 2px 20px 0 rgba(0,0,0,0.25)',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      width: '100vw'
    }
  },
  paper: {
    backgroundColor: 'transparent',
    overflow: 'hidden'
  },
  paperAnchorDockedRight: {
    borderLeft: 'none'
  }
}))

export const StyledList = styled(List)`
  margin-left: 25px;
  padding: 0;
`

export const IconWrap = styled('div')`
  margin: 33px 0 39px 25px;
`

export const StyledListItem = styled(ListItem, { shouldForwardProp: isPropValid })`
  padding: 0;
  margin-bottom: 23px;

  &:hover {
    background-color: transparent;
  }

  ${({ theme, mobileOnly }) =>
    mobileOnly &&
    `${[theme.breakpoints.up('lg')]} {
    display: none;
  }`}
`

export const StyledListItemText = styled(ListItemText)`
  height: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.H6};
  margin: 0;
  & span {
      font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  }
`

export const NavGroup = styled('div')`
  width: 640px;
  background: transparent;
  display: flex;
  flex-direction: row;

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    width: 100%;
  }
`

export const NavGroupButton = styled('div')`
  min-width: 285px;
  text-align: right;

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    display:none;
  }
`

export const MenuIconWrap = styled('div')`
  margin-top: 14px;
`

export const StyledButton = styled(Button)`
  line-height: 19px;
  height: 30px;
  min-width: 217px;
  width: ${({ width }) => `${width}px`};
  margin-right: 18px;
  margin-top: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ClickableArea = styled('div')`
  height: 100%;
  width: 100%;
`
