export const SAVE_PAYMENT_BALANCE_OVERRIDE = 'SAVE_PAYMENT_BALANCE_OVERRIDE'
export const SAVE_HIDE_AUTOPAY_TYPE_OVERRIDE = 'SAVE_HIDE_AUTOPAY_TYPE_OVERRIDE'
export const SAVE_IPN_URL_OVERRIDE = 'SAVE_IPN_URL_OVERRIDE'
export const SAVE_IPN_CUSTOM_VALUE_OVERRIDE = 'SAVE_IPN_CUSTOM_VALUE_OVERRIDE'
export const SAVE_SECONDARY_RESIDENT_ID_OVERRIDE = 'SAVE_SECONDARY_RESIDENT_ID_OVERRIDE'
export const SET_CANCEL_OPTION_BEHAVIOR_OVERRIDE = 'SET_CANCEL_OPTION_BEHAVIOR_OVERRIDE'
export const SET_PAYMENT_SUCCESS_REDIRECT = 'SET_PAYMENT_SUCCESS_REDIRECT'

export const savePaymentBalanceOverride = paymentFields => ({
  type: SAVE_PAYMENT_BALANCE_OVERRIDE,
  paymentFields
})

export const saveHideAutopayType = hideAutopayType => ({
  type: SAVE_HIDE_AUTOPAY_TYPE_OVERRIDE,
  hideAutopayType
})

export const saveIpnUrl = (ipnUrl) => ({
  type: SAVE_IPN_URL_OVERRIDE,
  ipnUrl
})


export const saveIpnCustomValue = (ipnCustomValue) => ({
  type: SAVE_IPN_CUSTOM_VALUE_OVERRIDE,
  ipnCustomValue
})

export const saveSecondaryResidentId = (secondaryResidentId) => ({
  type: SAVE_SECONDARY_RESIDENT_ID_OVERRIDE,
  secondaryResidentId
})

export const setCancelOptionBehaviorOverride = (cancel_option) => ({
  type: SET_CANCEL_OPTION_BEHAVIOR_OVERRIDE,
  cancel_option
})

export const setPaymentSuccessRedirect = (payment_success_redirect) => ({
  type: SET_PAYMENT_SUCCESS_REDIRECT,
  payment_success_redirect
})
