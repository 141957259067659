// @flow
import React from 'react'
import Edit from '@material-ui/icons/Edit'

import { IconButtonStyled } from './styles'

type Props = {
  onClick?: Function,
  color: ?String
}

const EditIconButton = ({
  onClick,
  color
}: Props) => {
  return (
    <IconButtonStyled
      onClick={onClick}
      color={color}>
      <Edit />
    </IconButtonStyled>
  )
}

export default EditIconButton
