import styled from '@emotion/styled'
import CROutTabletBG from '@images/credit_reporting/cr_out_tablet.png'
import CRInTabletBG from '@images/credit_reporting/cr_in_tablet.png'
import CROutBG from '@images/credit_reporting/cr_out.png'
import CROutSSO from '@images/credit_reporting/cr_out_sso.png'
import CRInBG from '@images/credit_reporting/cr_in.png'
import CRInSSO from '@images/credit_reporting/cr_in_sso.png'
import GoBtnBG from '@images/credit_reporting/go_btn_bg.png'
import { Link } from 'react-router-dom'

export const GoButton = styled.div`
  height: 30px;
  width: 104.5px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
  line-height: 16px;
  font-size:${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  padding: ${({ theme }) =>
    `${theme.space.s6} ${theme.space.s10} ${theme.space.s6} 0`};
  z-index: 0;
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s15 : theme.space.s30};

  &:before{
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    top: 0;
    left: 0;
    height: 30px;
    width: 0;
    transition: 0.25s width;
    z-index: -1;
  }
  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100%;
    background-image: url('${GoBtnBG}');
    background-size: 100% 100%;
    z-index: 1;
  }
`

export const Ad = styled(Link)`
  text-decoration: none;
  display: block;
  padding-left: ${({ theme }) => theme.space.s30};
  padding-top: ${({ theme, isOptedIn }) =>
    isOptedIn ? theme.space.s24 : theme.space.s33};
  background-image: url('${({ isOptedIn }) => (isOptedIn ? CRInBG : CROutBG)}');
  background-size: 100% 100%;
  height: ${({ isOptedIn }) => (isOptedIn ? '153px' : '157px')};
  max-width: 100%;
  min-width: 290px;
  margin-right: -${({ theme, isOptedIn }) =>
    isOptedIn ? theme.space.s5 : theme.space.s2};
  
  &:hover ${GoButton}:before, &:focus ${GoButton}:before {
    width: 100%;
  }

  ${({ theme }) => [theme.breakpoints.only('md')]} {
    height: 153px;
    padding-left: ${({ theme }) => theme.space.s30};
    padding-top: ${({ theme, isOptedIn }) =>
      isOptedIn ? theme.space.s15 : theme.space.s24};
    background-image: url('${({ isOptedIn }) =>
      isOptedIn ? CRInTabletBG : CROutTabletBG}');
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-left: -${({ theme }) => theme.space.s8};
    max-width: calc(100% + ${({ theme }) => theme.space.s20});
    
    ${({ theme }) => [theme.breakpoints.only('md')]} {
      margin-left: -${({ theme, isOptedIn }) =>
        isOptedIn ? theme.space.s12 : theme.space.s8};
      max-width: calc(100% + ${({ theme, isOptedIn }) =>
        isOptedIn ? theme.space.s12 : theme.space.s8}});  
    }
  }


  ${({ theme, isOptedIn }) =>
    theme.ux.isSSO &&
    `
    padding-left: ${theme.space.s18};
    padding-top: ${isOptedIn ? theme.space.s6 : theme.space.s15};
    height: 105px;
    min-width: 250px;
    margin-left: -0;
    margin-right: -0;
    background-image: url('${isOptedIn ? CRInSSO : CROutSSO}') ;

    &>div {
      transform: scale(0.85);
      transform-origin: top left;
    }
    `}
`

export const SubText = styled.div`
  line-height: 20px;
  font-size: 16px;
`

export const MainText = styled.div`
  line-height: 1;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  ${({ theme }) => [theme.breakpoints.only('md')]} {
    font-size: 24px;
  }

  margin-bottom: ${({ theme }) => theme.space.s5};
`
