import React from 'react'
import { useSelector } from 'react-redux'
import CardMedia from '@material-ui/core/CardMedia'
import { getPlaidBalanceChecks } from 'zego-shared/store/payments/options/selectors'
import PlaidLinkLogo from '@images/plaid_logo_icon.png'
import PlaidNext from '@images/rectangle.png'
import {
  Modal,
  ModalContent,
  ModalCard,
  ModalFooter,
  ModalText,
  ModalIcons,
  CloseIcon
} from './styles'

const PlaidModal = ({ onClickContinue, onClickCancel }) => {
  const isPlaidBalanceChecksEnabled = useSelector(getPlaidBalanceChecks)
  return (
    <Modal>
      <CloseIcon
        onClick={onClickCancel}
        name="cancel_icon"
        width="24px"
        height="24px"
      />
      <ModalContent>
        <h3>Bank Account</h3>
        <ModalCard onClick={onClickContinue}>
          <ModalText>
            <h2>Instant Verification</h2>
            <p>Set up with just a few clicks</p>
          </ModalText>
          <ModalIcons>
            <CardMedia
              component="img"
              alt="Plaid logo"
              height="40"
              image={PlaidLinkLogo}
              title="Plaid logo"
            />
            <CardMedia
              component="img"
              alt="Plaid next"
              height="40"
              image={PlaidNext}
              title="Plaid next"
            />
          </ModalIcons>
        </ModalCard>
        <ModalFooter onClick={onClickCancel}>
          <p>Manually Enter Bank Information</p>
        </ModalFooter>
        {isPlaidBalanceChecksEnabled && (
          <p>
            * Zego uses Plaid to link your bank and check your account balance
            prior to payment.
          </p>
        )}
      </ModalContent>
    </Modal>
  )
}

export default PlaidModal
