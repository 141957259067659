// @flow
import React from 'react'
import { useTheme, withWidth } from '@material-ui/core'
import { truncateString } from '@utils/stringUtils'
import {
  ChargeDetailsFor,
  InfoLane,
  UnitAddress,
  MyPaymentsInfoWrap,
  ResidentNameWrap,
  ResidentName,
  HeaderTextWrap,
  CompanyName
} from './styles'

type Props = {
  width: string,
  userInfo: {
    unitName: string,
    propertyName: string,
    residentName: string,
    address: string,
    companyName: string
  },
  levelCreditEnabled: boolean
}

export const MyPaymentsInfo = ({
  width,
  userInfo,
  levelCreditEnabled
}: Props) => {
  const theme = useTheme()
  const isSSO = theme.ux?.isSSO

  const {
    unitName,
    propertyName,
    residentName,
    address,
    companyName
  } = userInfo
  const ssoUnitAddress = `${unitName ? `${unitName},` : ''} ${propertyName}`
  const unitAddress = unitName
    ? `${address}${address && ','} ${unitName}`
    : residentName

  return (
    <React.Fragment>
      <MyPaymentsInfoWrap>
        <HeaderTextWrap
          fullWidth
          justifySpaceBetween
          levelCreditEnabled={levelCreditEnabled}>
          <ChargeDetailsFor>Charge Details For:</ChargeDetailsFor>
        </HeaderTextWrap>
        <ResidentNameWrap direction="column">
          <InfoLane
            fullWidth
            justifySpaceBetween
            order={1}
            isResidentNameInfoLane>
            <UnitAddress capitalize>
              {truncateString(isSSO ? ssoUnitAddress : unitAddress, 32)}
            </UnitAddress>
          </InfoLane>
          <InfoLane fullWidth justifySpaceBetween isFlipped>
            <ResidentName>
              {unitName || isSSO ? residentName : propertyName}
            </ResidentName>
          </InfoLane>
          <InfoLane fullWidth justifySpaceBetween isFlipped>
            <CompanyName>{companyName}</CompanyName>
          </InfoLane>
        </ResidentNameWrap>
      </MyPaymentsInfoWrap>
    </React.Fragment>
  )
}

export default withWidth()(MyPaymentsInfo)
