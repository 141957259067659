// @flow

import some from 'lodash/some'
import get from 'lodash/get'

// returns true if any of the fetching flags are set to true
// eslint-disable-next-line
export const createIsFetchingSelector = (keys: Array<string>) => (
  state: Object
) =>
  some(keys, key => {
    return get(state, ['isFetchingStates', 'isFetching', key]) === true
  })

export const getBlockedFetch = (state: Object) => {
  return get(state, 'isFetchingStates.isBlocked')
}
