// @flow
import styled from '@emotion/styled'
import Flex from '../../../Common/Flex'

export const BoxStyled = styled(Flex)`
  margin-bottom: ${({ theme }) => (theme.ux.isSSO ? 0 : theme.space.s160)};
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s28 : theme.space.s75};

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin-top: ${({ theme }) => theme.space.s45};
  }
`

export const HeaderStyled = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space.s45};

  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    margin-bottom: ${({ theme }) => (theme.ux.isSSO ? theme.space.s25 : '5%')};
  }
`
export const PageTitle = styled('div')`
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H4 : theme.custom.font.size.H3};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
  }
`
