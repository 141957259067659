// @flow
import { find, get } from 'lodash'
import { createSelector } from 'reselect'
import { getUserId } from 'zego-shared/store/authorization/selectors'

export const getLinkedAccounts = (state: Object) => get(state, 'payments.linkedAccounts.linked_accounts', [])

export const getParentAccountId = (state: Object) => {
  const linkedAccounts = getLinkedAccounts(state)
  const parentAccount = find(linkedAccounts, 'is_parent_account')
  return get(parentAccount, 'user_id', null)
}

export const getLinkedAccountId = (
  userId: String,
  parentAccountId: String,
  linkedAccounts: Object[]
) => {
  if (parentAccountId === userId) {
    const linkedAccount = find(linkedAccounts, ['is_parent_account', false])
    return linkedAccount && linkedAccount.user_id
  }
  return parentAccountId
}

export const getNextLinkedAccountToLogAs = createSelector(
  [getUserId, getParentAccountId, getLinkedAccounts],
  getLinkedAccountId
)
