import { uniqBy } from 'lodash'
import { FETCH_PREFIX, SUCCESS } from '../actions/constants'

const uniqSet = (edges, uniqueKey) =>
  uniqueKey ? uniqBy(edges, uniqueKey) : edges

export default (section, uniqueKey = 'cursor') => reducer => (
  state,
  action
) => {
  switch (action.type) {
    case `${FETCH_PREFIX}${section}${SUCCESS}`: {
      const {
        pageInfo: { hasNextPage },
        edges,
        cursor
      } = action.payload
      const { edges: existingEdges } = state || { edges: [] }
      return {
        ...state,
        edges: cursor
          ? uniqSet([...existingEdges, ...(edges || [])], uniqueKey)
          : edges,
        hasNextPage
      }
    }

    default:
      return reducer(state, action)
  }
}
