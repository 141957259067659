import { createFetchActions } from '../../../helpers/actions/creators'

export const LINKED_ACCOUNTS = 'LINKED_ACCOUNTS'

export const {
  fetchAction: fetchLinkedAccountsAction,
  fetchStartAction: fetchLinkedAccountsStart,
  fetchSuccessAction: fetchLinkedAccountsSuccess,
  fetchErrorAction: fetchLinkedAccountsError
} = createFetchActions(LINKED_ACCOUNTS)

