// @flow
import { createTheme } from '@material-ui/core/styles'
import colors from './color'

type Space = {|
  s5: string,
  s10: string,
  s15: string,
  s20: string,
  s30: string,
  s45: string,
  s60: string,
  s75: string,
  s100: string,
  s160: string
|}

export type Theme = {|
  palette: {|
    common: {|
      midnight: string,
      white: string,
      grey: string,
      iceberg: string,
      lightBlue: string
    |},
    primary: {|
      deep: string,
      main: string,
      light: string,
      contrastText: string
    |},
    secondary: {|
      dark: string,
      main: string,
      light: string,
      light1: string,
      contrastText: string
    |},
    tertiary: {|
      dark: string,
      main: string,
      light1: string,
      light2: string,
      light3: string,
      light4: string,
      light5: string
    |},
    neutral: {|
      deep: string,
      dark: string,
      main: string,
      warm: string,
      cool: string,
      light: string,
      light1: string,
      light2: string,
      light3: string,
      light4: string
    |},
    error: {|
      dark: string,
      main: string,
      light: string,
      contrastText: string
    |},
    background: {|
      default: string
    |},
    feedback: {|
      success: string,
      warning: string,
      error: string
    |},
    alert: {|
      lightgold: string
    |},
    clientNotes: {|
      titleBackGround: string,
      titleText: string,
      border: string,
      contentBackGround: string,
      contentText: string
    |}
  |},
  typography: {|
    fontFamily: string,
    fontSize: string
  |},
  custom: {|
    font: {|
      size: {|
        xs: string,
        sm: string,
        md: string,
        H6: string,
        H5: string,
        H4: string,
        H3: string,
        H2: string,
        H1: string
      |},
      weight: {|
        light: number,
        regular: number
      |}
    |}
  |},
  screenSize: {|
    desktop: string
  |},
  space: Space,
  ux: {
    isSSO: boolean
  }
|}

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightBold = 500
const fontWeightBolder = 700

const theme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255,255,255, 0.95)'
      }
    },
    MuiButton: {
      root: {
        borderRadius: '0px'
      }
    },
    MuiInputBase: {
      root: {
        fontSize: '0.8571428571428571rem',
        fontWeight: 'normal'
      }
    },
    MuiSnackbar: {
      root: {
        zIndex: 3000
      }
    },
    MuiFab: {
      label: {
        lineHeight: 1
      }
    }
  },
  spacing: 5,
  palette: {
    common: {
      midnight: colors.common.midnight,
      white: colors.common.white,
      grey: colors.common.grey,
      iceberg: colors.common.iceberg,
      lightBlue: colors.common.lightBlue
    },
    primary: {
      deep: colors.primary.deep,
      main: colors.primary.main,
      light: colors.primary.light,
      contrastText: colors.primary.contrastText
    },
    secondary: {
      dark: colors.secondary.dark,
      main: colors.secondary.main,
      light: colors.secondary.light,
      light1: colors.secondary.light1,
      contrastText: colors.secondary.contrastText
    },
    alternative: {
      purple: colors.alternative.purple,
      brightblue: colors.alternative.brightblue
    },
    tertiary: {
      dark: colors.tertiary.dark,
      main: colors.tertiary.main,
      light1: colors.tertiary.light1,
      light2: colors.tertiary.light2,
      light3: colors.tertiary.light3,
      light4: colors.tertiary.light4,
      light5: colors.tertiary.light5
    },
    neutral: {
      deep: colors.neutral.deep,
      dark: colors.neutral.dark,
      main: colors.neutral.main,
      warm: colors.neutral.warm,
      cool: colors.neutral.cool,
      light: colors.neutral.light,
      light1: colors.neutral.light1,
      light2: colors.neutral.light2,
      light3: colors.neutral.light3,
      light4: colors.neutral.light4
    },
    error: {
      dark: colors.error.dark,
      main: colors.error.main,
      light: colors.error.light,
      contrastText: colors.error.contrastText,
      plaidBalanceError: colors.error.plaidBalanceError,
      border: colors.error.border
    },
    background: {
      default: colors.common.white
    },
    feedback: colors.feedback,
    secondaryCard: colors.secondaryCard,
    alert: {
      lightgold: colors.alert.lightgold
    },
    clientNotes: {
      titleBackGround: colors.clientNotes.titleBackGround,
      titleText: colors.clientNotes.titleText,
      border: colors.clientNotes.border,
      contentBackGround: colors.clientNotes.contentBackGround,
      contentText: colors.clientNotes.contentText
    }
  },
  typography: {
    fontWeightRegular,
    fontWeightBold,
    fontWeightLight,
    fontFamily: 'acumin-pro, sans-serif',
    fontSize: 12,
    h1: {
      fontSize: 64,
      fontWeight: fontWeightRegular
    },
    h2: {
      fontSize: 48,
      fontWeight: fontWeightRegular
    },
    h3: {
      fontSize: 36,
      fontWeight: fontWeightRegular
    },
    h4: {
      fontSize: 24,
      fontWeight: fontWeightRegular
    },
    h5: {
      fontSize: 18,
      fontWeight: fontWeightRegular
    },
    h6: {
      fontSize: 16,
      fontWeight: fontWeightRegular
    },
    body1: {
      fontSize: 16,
      fontWeight: fontWeightLight
    },
    body2: {
      fontSize: 12,
      fontWeight: fontWeightRegular
    }
  },
  custom: {
    font: {
      size: {
        xxs: '8px',
        xs: '10px',
        sm: '12px',
        md: '14px',
        H6: '16px',
        H5: '18px',
        H22: '22px',
        H4: '24px',
        H28: '28px',
        H3: '36px',
        H2: '48px',
        H1: '64px'
      },
      weight: {
        light: fontWeightLight,
        regular: fontWeightRegular,
        bold: fontWeightBold,
        bolder: fontWeightBolder
      }
    },
    shadow: '0 2px 4px 0 rgba(0,0,0,0.5)'
  },
  screenSize: {
    desktop: '1400px',
    xxl: { height: '900px', width: '1280px' },
    xl: { height: '768px', width: '1024px' },
    l: { height: '600px', width: '1024px' },
    m: { height: '1024px', width: '768px' },
    s: { height: '1024px', width: '600px' },
    xs: { height: '600px', width: '375px' }
  },
  breakpoints: {
    values: { xs: 0, sm: 600, md: 768, lg: 1024, xl: 1280 }
  },
  space: {
    s2: '2px',
    s3: '3px',
    s4: '4px',
    s5: '5px',
    s6: '6px',
    s7: '7px',
    s8: '8px',
    s9: '9px',
    s10: '10px',
    s11: '11px',
    s12: '12px',
    s13: '13px',
    s14: '14px',
    s15: '15px',
    s16: '16px',
    s17: '17px',
    s18: '18px',
    s20: '20px',
    s21: '21px',
    s24: '24px',
    s22: '22px',
    s23: '23px',
    s25: '25px',
    s26: '26px',
    s27: '27px',
    s28: '28px',
    s29: '29px',
    s30: '30px',
    s31: '31px',
    s32: '32px',
    s33: '33px',
    s35: '35px',
    s37: '37px',
    s39: '39px',
    s40: '40px',
    s41: '41px',
    s43: '43px',
    s44: '44px',
    s45: '45px',
    s47: '47px',
    s48: '48px',
    s50: '50px',
    s51: '51px',
    s55: '55px',
    s57: '57px',
    s60: '60px',
    s65: '65px',
    s68: '68px',
    s70: '70px',
    s73: '73px',
    s75: '75px',
    s80: '80px',
    s90: '90px',
    s95: '95px',
    s100: '100px',
    s110: '110px',
    s120: '120px',
    s140: '140px',
    s150: '150px',
    s154: '154px',
    s157: '157px',
    s160: '160px',
    s172: '172px',
    s182: '182px',
    s200: '200px',
    s223: '223px'
  },
  props: {
    MuiTextField: {
      InputLabelProps: {
        shrink: false
      }
    },
    MuiContainer: {
      maxWidth: false
    },
    Hidden: { implementation: 'css' }
  },
  ux: {
    isSSO: false
  }
})

// Set options that use default theme values
theme.overrides.MuiContainer = {
  root: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.space.s75,
      paddingLeft: theme.space.s75,
      maxWidth: 1400
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.space.s30,
      paddingLeft: theme.space.s30
    }
  }
}

export default theme
