import { call, put, select, takeLatest } from 'redux-saga/effects'
import { print } from 'graphql'
import { getGraphQlData } from '../../../utils/graphql'
import {
  fetchPmSettingsAction,
  fetchPmSettingsError,
  fetchPmSettingsStart,
  fetchPmSettingsSuccess
} from '../actions'
import * as queries from '../graphql/queries'
import api from '../../../utils/api'
import { showSnackbar } from '../../snackbar/actions'
import { getPaymentToken } from '../../authorization/selectors'
import { failedToFetchPmSettings } from '../../../utils/messages'

export function* fetchPmSettingsSaga() {
  try {
    yield put(fetchPmSettingsStart())

    const paymentToken = yield select(getPaymentToken)
    const response = yield call(
      api.graphqlQuery,
      { paymentToken },
      print(queries.getPmSettings)
    )

    const payload = yield call(getGraphQlData, response, 'getPmSettings')
    yield put(fetchPmSettingsSuccess(payload))
  } catch (error) {
    yield put(fetchPmSettingsError(error))
    yield put(showSnackbar(failedToFetchPmSettings, 'error'))
  }
}

function* watchFetchPmSettings() {
  yield takeLatest(fetchPmSettingsAction().type, fetchPmSettingsSaga)
}

export default [watchFetchPmSettings()]
