import withGraphqlPaginatedFetch from '../../../helpers/reducers/withGraphqlPaginatedFetch'
import { PAYMENT_HISTORY_SECTION, VOID_PAYMENT_SUCCESS } from '../actions'

const history = (state = null, result) => {
  const { type, id } = result
  switch (type) {
    case VOID_PAYMENT_SUCCESS:
      const { edges } = state
      const newEdges = edges.map(edge => {
        const {
          node: { transId }
        } = edge
        if (transId === id) {
          return {
            ...edge,
            node: {
              ...edge.node,
              status: 'Cancelled',
              voidable: false
            }
          }
        }
        return edge
      })
      return {
        ...state,
        edges: newEdges
      }

    default:
      return state
  }
}

export default withGraphqlPaginatedFetch(PAYMENT_HISTORY_SECTION)(history)
