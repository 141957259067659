// @flow

import { connect } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { withFetchData } from 'zego-shared/views'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  getHistory,
  hasHistory,
  hasNextPage,
  getNextCursor
} from 'zego-shared/store/payments/history/selectors'
import {
  fetchPaymentHistory,
  voidPayment
} from 'zego-shared/store/payments/history/actions'
import History from './History'

const fetchingSelector = createIsFetchingSelector(['paymenthistorysection'])
const historyWithFetchData = withFetchData(History)

export const mapStateToProps = (
  state: Object,
  {
    match: {
      params: { residentId }
    }
  }: BrowserRouter
) => ({
  historyData: getHistory(state),
  hasHistory: hasHistory(state),
  isFetching: fetchingSelector(state),
  hasNextPage: hasNextPage(state),
  nextCursor: getNextCursor(state),
  residentId
})

export const mapDispatchToProps = (
  dispatch: Function,
  {
    match: {
      params: { residentId }
    }
  }: BrowserRouter
) => ({
  fetchData: () => dispatch(fetchPaymentHistory(residentId)),
  fetchNextPage: (cursor: string) =>
    dispatch(fetchPaymentHistory(residentId, cursor)),
  voidPayment: (id: string) => dispatch(voidPayment(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(historyWithFetchData)
