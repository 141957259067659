import { SAVE_PAYMENT_NOTES } from '../actions'

const paymentNotes = (state = "", action) => {
  switch (action.type) {
    case SAVE_PAYMENT_NOTES:
      return action.payload
    default:
      return state
  }
}

export default paymentNotes
