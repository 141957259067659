// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import Modal from '@material-ui/core/Modal'
import Clear from '@material-ui/icons/Clear'
import { 
  CloseIconButton,
  ModalBackDropStyled,
  ModalContentStyled,
  ReadMoreCard,
  ReadMoreStyled,
  MemoTextStyled,
  TitleTextStyled
} from './styles'


type Props = {
  title: string,
  text: string
}

const ReadMore = (props: Props) => {
  const {
    title,
    text
  } = props
  const [showMore, setShowMore] = React.useState(false)
  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

const renderTitle = () => title ? (
  <TitleTextStyled>
    { title }
  </TitleTextStyled>
  ) : null

return (
    <ReadMoreStyled>
      <Link to="#" onClick={toggleShowMore}>
        Read More
      </Link>
      <Modal
        open={showMore}
        BackdropComponent={ModalBackDropStyled}
        onBackdropClick={toggleShowMore}>
        <ModalContentStyled justifyCenter alignCenter>
          <ReadMoreCard>
            <CloseIconButton onClick={toggleShowMore}>
              <Clear />
            </CloseIconButton>
            {renderTitle()}
            <MemoTextStyled>
              { text }
            </MemoTextStyled>
          </ReadMoreCard>
        </ModalContentStyled>
      </Modal>
    </ReadMoreStyled>
  )
}

export default ReadMore