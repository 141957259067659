import styled from '@emotion/styled'
import MaterialCard from '@material-ui/core/Card'
import PointImage from '../../images/point.png'
import RectangleImage from '../../images/rectangle_plain.png'
import { Link } from 'react-router-dom'

export const PageTitle = styled('div')`
  margin-bottom: ${({ theme }) => (theme.ux.isSSO ? '10px' : '34px')};
  line-height: 40px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H3 : theme.custom.font.size.H2};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H3};
  }

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    margin-bottom: 10px;
  }
`

export const Deals = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    justify-content: center;
  }
`

export const DealCard = styled(MaterialCard)`
  padding: 15px 0px;
  display: flex;
  flex-direction: row;
  margin: 23px 22px;
  width: 400px;
`

export const DealHeader = styled('h3')`
  flex: 0 0 33%;
  padding: 0 12px 6px 12px;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.md};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  background-color: ${({ theme }) => theme.palette.common.iceberg};
  background-image: url(${PointImage});
  background-repeat: no-repeat;
  background-position: right;
  font-style: italic;
  line-height: 32px;
  position: absolute;
  margin: -5px 0 0 -17px;
  padding: 0 23px 0 12px;
  box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.25);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 190px;
`

export const DealImage = styled('div')`
  flex: 0 0 33%;
  margin-top: 32px;
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  min-width: 150px;
  min-height: 128px;

  :after {
    background-image: url(${({ imageSrc }) => imageSrc});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: ' ';
    flex: 1;
  }
`

export const DealContent = styled('div')`
  flex: 1 0;
  padding: 6px 12px;
  align-self: flex-start;
  color: ${({ theme }) => theme.palette.common.midnight};
  overflow: hidden;
  position: relative;

  h3 {
    margin: 0;
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  }

  h4 {
    margin: 0;
    color: ${({ theme }) => theme.palette.neutral.deep};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  }

  p {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }
`

export const DealFooter = styled('div')`
  padding: 6px;
  line-height: 30px;
  bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
`

export const DetailsDiv = styled('div')`
  padding: 0px;
  margin-left: 0px;
  margin-right: auto;
`

export const DetailsLink = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.secondary.dark};
  text-decoration: underline;
`

export const RedeemDiv = styled('div')`
  margin-left: auto;
  margin-right: 0px;
`

export const RedeemLink = styled('a')`
  cursor: pointer;
  text-align: justify;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.md};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  background-image: url(${RectangleImage});
  background-repeat: no-repeat;
  background-position: center;
  right: 0px;
  padding: 6px 22px 8px 12px;
`
