// @flow
import styled from '@emotion/styled'
import Button from '@Common/Button'

export const WelcomeText = styled('h2')`
  color: ${({ theme }) => theme.palette.primary.deep};
  font-size: 100px;
  line-height: 120px;
  text-align: center;
  margin: 0;
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H3};
    line-height: 60px;
  }
`

export const SubText = styled('div')`
  color: ${({ theme }) => theme.palette.primary.deep};
  font-size: 18px;
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  line-height: 24px;
  text-align: center;
`

export const StyledButton = styled(Button)`
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 2%
  }
`

export const Disclaimer = styled.div`
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.neutral.light};
  padding: ${({ theme }) => theme.space.s25} ${({ theme }) => theme.space.s55} ${({ theme }) => theme.space.s50};

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    position: absolute;
    bottom: ${({ theme }) => theme.space.s25};
    background-color: ${({ theme }) => theme.palette.common.white};
    padding: ${({ theme }) => theme.space.s45};
    left:0;
    right:0;
    text-align: center;
  }
`
