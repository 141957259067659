// @flow
import React, { useRef, useLayoutEffect, useState } from 'react'
import { withTheme } from 'emotion-theming'
import { Grid, Hidden } from '@material-ui/core'
import Avatar from '@Common/Avatar'
import { Arrow } from '@icons'
import Flex from '@Common/Flex'
import { FabButton } from '@Common/Button'
import type { Theme } from '../../../theme'

import {
  PageTitle,
  Name,
  ResidentName,
  UnitName,
  MainContent,
  StyledSVG,
  MyAvatar
} from '../styles'
import {
  ContactInfo,
  InfoTitle,
  InfoLane,
  Demarcation,
  MapIframe,
  StaffName,
  StaffRole
} from './styles'

type PropertyStaff = {
  name: string,
  picture: string,
  role: string
}

type Props = {
  propertyName: string,
  unitName: string,
  addressLineOne: string,
  addressLineTwo: string,
  companyName: string,
  addressState: string,
  addressCity: string,
  addressZip: string,
  officeHours: string,
  phone: string,
  propertyStaff: Array<PropertyStaff>,
  theme: Theme,
  history: Object,
  prevUrl: string
}

const getPropertyStaff = (propertyStaff: Array<PropertyStaff>) => {
  return propertyStaff.map(staff => (
    <Grid item xs={12} lg={4}>
      <Flex>
        <Avatar size="45px" picture={staff.picture} hideShadow={true} />
        <Flex direction="column" marginLeft="12px" marginTop="7px">
          <StaffName>{staff.name}</StaffName>
          <StaffRole>{staff.role}</StaffRole>
        </Flex>
      </Flex>
    </Grid>
  ))
}

const Map = ({ address }) => {
  const encodedAddress = address ? encodeURIComponent(address.trim()) : ''
  const ref = useRef()
  const [mapHeight, setMapHeight] = useState(282)

  const setHeight = () => {
    ref.current && setMapHeight(ref.current.clientWidth)
  }

  useLayoutEffect(() => {
    setHeight()
    window.addEventListener('resize', setHeight)
    return () => window.removeEventListener('resize', setHeight)
  }, [])

  return (
    <MapIframe
      ref={ref}
      title="adress-map"
      height={mapHeight}
      src={`https://maps.google.com/maps?q=${encodedAddress}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
    />
  )
}
const goBack = (prevUrl, history) => {
  return prevUrl ? history.push(prevUrl) : history.push('/')
}

const MyPropertyInfo = (props: Props) => {
  const {
    propertyName,
    propertyStaff,
    companyName,
    addressLineOne,
    addressLineTwo,
    addressCity,
    addressState,
    addressZip,
    officeHours,
    history,
    phone,
    prevUrl,
    theme
  } = props

  return (
    <>
      <StyledSVG name="account_bg" width="2000px" height="330px" />
      <Grid container>
        <Grid item xs={3} sm={2} md={1}>
          <FabButton
            onClick={() => goBack(prevUrl, history)}
            size="45px"
            background={theme.palette.tertiary.main}
            component={() => (
              <Arrow
                id="backArrow"
                name="arrow"
                width="16px"
                height="12px"
                fill={theme.palette.primary.main}
              />
            )}
          />
        </Grid>

        <Grid item md={4}>
          <Hidden smDown>
            <PageTitle> Property Info </PageTitle>
          </Hidden>
        </Grid>

        <Grid item xs={9} sm={10} md={7}>
          <Name>
            <ResidentName>{propertyName}</ResidentName>
            <UnitName>{companyName}</UnitName>
          </Name>
        </Grid>
      </Grid>

      <Grid container component={MainContent}>
        <Grid item xs={12} md={4}>
          <MyAvatar>
            <Map address={addressLineOne + addressCity} />
          </MyAvatar>
        </Grid>

        <Grid md={1} />
        <Grid item xs={12} md={7} lg={6}>
          <Flex justifySpaceBetween>
            <ContactInfo data-test-id="address">
              <InfoTitle marginBottom="13px">Address</InfoTitle>
              <InfoLane>{addressLineOne}</InfoLane>
              {addressLineTwo && <InfoLane>{addressLineTwo}</InfoLane>}
              <InfoLane>
                {addressCity}, {addressState} {addressZip}
              </InfoLane>
              <InfoLane>{phone}</InfoLane>
            </ContactInfo>

            <ContactInfo alignRight data-test-id="hours">
              <InfoTitle marginBottom="13px" justifyRight>
                Office Hours
              </InfoTitle>
              <InfoLane light>{officeHours}</InfoLane>
            </ContactInfo>
          </Flex>

          <Flex justifyCenter marginTop="28px" marginBottom="45px">
            <Demarcation />
          </Flex>

          <div data-test-id="staff">
            <InfoTitle marginBottom="19px">Property Team</InfoTitle>
            <Grid container spacing={6}>
              {getPropertyStaff(propertyStaff)}
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default withTheme(MyPropertyInfo)
