// @flow
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { string } from 'yup'
import { getUserEmail } from 'zego-shared/store/authorization/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { updateUser } from 'zego-shared/store/authorization/actions'
import type { Props } from '../AppBar'
import { SSOAppBar } from '../styles'
import {
  Base,
  LeftDesktop,
  GoButton,
  SubText,
  LeftMobile,
  Right,
  StyledInput,
  HideOnSM,
  EditBtn
} from './styles'

const AppBar = (props: Props) => {
  const {
    residentName,
    profilePicture,
    history,
    match,
    location,
    height,
    ...rest
  } = props

  const email = useSelector(getUserEmail)
  const isEmailSet = Boolean(email)

  const [editMode, setEditMode] = useState(!isEmailSet)
  const [emailValue, setEmailValue] = useState(email)

  const switchMode = () => {
    setEditMode(!editMode)
  }

  const onChangeText = event => {
    setEmailValue(event.target.value)
  }

  const dispatch = useDispatch()
  const updateEmail = async () => {
    try {
      await string()
        .required()
        .email()
        .validate(emailValue)
      dispatch(
        updateUser(
          {
            email: emailValue
          },
          true
        )
      )
      switchMode()
    } catch (err) {
      dispatch(
        showSnackbar('Please enter a properly formatted email address', 'error')
      )
    }
  }

  return (
    <SSOAppBar {...rest} height={height}>
      {editMode ? (
        <Base showBg justifySpaceBetween>
          <LeftDesktop>
            Receipts &amp; Important Messages
            <SubText>Direct to your Inbox</SubText>
          </LeftDesktop>
          <LeftMobile>Enter email for Receipts</LeftMobile>
          <Right>
            <HideOnSM>
              <SubText>email</SubText>
            </HideOnSM>
            <StyledInput
              className="input"
              fullWidth
              bgWhite
              placeholder="email"
              type="email"
              value={emailValue}
              onChange={onChangeText}
              id="password"
            />
            <GoButton onClick={updateEmail}>Go</GoButton>
          </Right>
        </Base>
      ) : (
        <Base justifyRight>
          <SubText alignRightOnMobile>
            All Messages are Directed to: {email}
          </SubText>
          <EditBtn onClick={switchMode}>edit</EditBtn>
        </Base>
      )}
    </SSOAppBar>
  )
}

export default withRouter(AppBar)
