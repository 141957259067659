import React from 'react'
import { useFormik } from 'formik'
import { string, object } from 'yup'
import { nameRegex } from '@utils/validationUtils'
import CloseIcon from '@material-ui/icons/Close'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@Common/Button'
import Loader from '@Common/Loader'
import { FormGroup  } from '@Common/Form'
import PlaidLinkLogo from '@images/plaid_logo.png'
import { StyledCard, IconButtonStyled } from '../styles'
import { getErrorInfo, getHasError } from '@utils/view'
import { FinalStepsAction, FinalStepsContent, NameInput } from './styles'

const LastStepModal = ({ isSaving, onClickContinue, onClickExit, onClickCancel }) => {
  const formik = useFormik({
    initialValues: {
      accountHolderFirstName: '',
      accountHolderLastName: ''
    },
    validationSchema: object().shape({
      accountHolderFirstName: string()
      .trim()
      .matches(new RegExp(nameRegex), {
        message: 'First Name is required'
      })
      .required('Account holder First Name is required'),
      accountHolderLastName: string()
      .trim()
      .matches(new RegExp(nameRegex), {
        message: 'Last Name is required'
      })
      .when('accountHolderFirstName', (firstName = '', schema) =>
      schema.max(
          59 - firstName.length,
          'Full Name must not be longer than 60 characters'
        )
      .required('Account holder Last Name is required')
      )
    }),
    validateOnChange: true,
    onSubmit: values => {
      onClickContinue(`${values.accountHolderFirstName} ${values.accountHolderLastName}`)
    }
  })
  const hasError = getHasError(formik.errors, formik.touched)
  const errorDetails = getErrorInfo(formik.errors, formik.touched)
  if (isSaving) {
    return <Loader />
  }
  return (
    <StyledCard unwrapContent customJustifyContent="normal">
      <CardMedia
        component="img"
        alt="Plaid Link Last step"
        height="60"
        image={PlaidLinkLogo}
        title="Plaid Link Last step"
      />
      <IconButtonStyled onClick={onClickExit}>
        <CloseIcon />
      </IconButtonStyled>
      <form className="accountHolderNameForm" onSubmit={formik.handleSubmit}>
        <FinalStepsContent customPadding="1px">
          <h3>Final Steps</h3>
          <p>
            Please enter the first and last name of the account holder
            associated with this bank
          </p>
          <FormGroup
            fullWidth
            {...errorDetails('accountHolderFirstName')}>
            <NameInput
              onChange={formik.handleChange}
              value={formik.values.accountHolderFirstName}
              error={hasError('accountHolderFirstName')}
              placeholder="First Name"
              name="accountHolderFirstName"
            />
          </FormGroup>
          <FormGroup
            fullWidth
            {...errorDetails('accountHolderLastName')}>
            <NameInput
              onChange={formik.handleChange}
              value={formik.values.accountHolderLastName}
              error={hasError('accountHolderLastName')}
              placeholder="Last Name"
              name="accountHolderLastName"
            />
          </FormGroup>
        </FinalStepsContent>
        <FinalStepsAction>
          <Button primaryLight elong type="submit" className="lastStep">
            Continue
          </Button>
        </FinalStepsAction>
      </form>
    </StyledCard>
  )
}

export default LastStepModal
