import { createUpdateActions } from '../../../helpers/actions/creators'
import { createFetchActions } from '../../../helpers/actions/creators'

export const ONE_TIME_PAYMENT = 'ONETIMEPAYMENT'

export const ONE_TIME_PAYMENT_DETAILS = 'ONE_TIME_PAYMENT_DETAILS'

export const FRAUD_TOOLKIT_ERROR = 'FRAUD_TOOLKIT_ERROR'

export const PLAID_INSUFFICIENT_FUNDS_ERROR = 'PLAID_INSUFFICIENT_FUNDS_ERROR'

export const GET_PAYPAL_PAYMENT = 'GETPAYPALPAYMENT'
export const PAYMENT_RECEIPT = 'PAYMENTRECEIPT'

export const {
  updateAction: requestPaymentReceiptAction,
  updateStartAction: requestPaymentReceiptStart,
  updateSuccessAction: requestPaymentReceiptSuccess,
  updateErrorAction: requestPaymentReceiptError
} = createUpdateActions(PAYMENT_RECEIPT)

export const {
  updateAction: makeOnetimePaymentAction,
  updateStartAction: makeOnetimePaymentStart,
  updateSuccessAction: makeOnetimePaymentSuccess,
  updateErrorAction: makeOnetimePaymentError
} = createUpdateActions(ONE_TIME_PAYMENT)

export const {
  fetchAction: getPayPalPaymentAction,
  fetchStartAction: getPayPalPaymentStart,
  fetchSuccessAction: getPayPalPaymentSuccess,
  fetchErrorAction: getPayPalPaymentError
} = createFetchActions(GET_PAYPAL_PAYMENT)

export const makeOnetimePayment = (residentId, onSuccess) =>
  makeOnetimePaymentAction({ residentId, onSuccess })

export const saveDetails = details => ({
  type: ONE_TIME_PAYMENT_DETAILS,
  details
})

export const {
  fetchAction: fetchOneTimeDataAction,
  fetchStartAction: fetchOneTimeDataStart,
  fetchSuccessAction: fetchOneTimeDataSuccess,
  fetchErrorAction: fetchOneTimeDataError
} = createFetchActions(ONE_TIME_PAYMENT)

export const fetchOnetimePaymentData = residentId =>
  fetchOneTimeDataAction({ residentId })

export const makeFraudToolkitError = hasError => ({
  type: FRAUD_TOOLKIT_ERROR,
  error: hasError
})

export const makePlaidInsufficientFundsError = hasError => ({
  type: PLAID_INSUFFICIENT_FUNDS_ERROR,
  error: hasError
})
