import { PAYMENT_REMINDERS_DATA, updateRemindersSuccess } from '../actions'
import withFetch from '../../../helpers/reducers/withFetch'
import { getTommorowDate } from 'zego-shared/utils/date'

const defaultState = {
  email: '',
  altEmail: '',
  mobile: '',
  altMobile: '',
  frequency: 'M',
  dateToSend: getTommorowDate(),
  remindersEnabled: false
}

const paymentReminders = (state = defaultState, action) => {
  switch (action.type) {
    case updateRemindersSuccess().type: {
      return action.payload
    }
    default:
      return state
  }
}

export default withFetch(PAYMENT_REMINDERS_DATA)(paymentReminders)
