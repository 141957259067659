// @flow
import styled from '@emotion/styled'
import IconButton from '@material-ui/core/IconButton'
import Card from '@Common/Card'
import Flex from '@Common/Flex'

export const PaymentMethodEditStyled = styled(Card)`
  height: 275px;
  min-height: fit-content;
  
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    
    ${({ theme }) => 
      theme.ux.isSSO && 
      `
      height: 300px
      `
    }
    
  }
  & h5 {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    line-height: 22px;
    margin: 0 0 45px;
    text-transform: capitalize;
  }
  & .MuiCardContent-root {
    height: fit-content;
    min-height: 100%;
    ${({ theme }) => theme.ux.isSSO && 'padding-bottom: 30px;'}
  }
`

export const MethodStyled = styled(Flex)`
  height: fit-content;
  min-height: 84px;
  min-width: 200px;
  flex: 1;
  margin-bottom: ${({ theme }) => theme.space.s20};

  & p {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    line-height: 19px;
    margin: 0 0 31px;
  }

  & > div {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    min-height: fit-content;

    & > span {
      font-size: ${({ theme }) => theme.custom.font.size.sm};
      font-weight: ${({ theme }) => theme.custom.font.weight.regular};
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

const IconButtonStyled = styled(IconButton)`
  height: 84px;
  width: 136px;
  border-radius: 15px;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 70px;
    width: 100px;
  }
`

export const AddIconButtonStyled = styled(IconButtonStyled)`
  background-color: ${({ theme }) => theme.palette.secondary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }

  & svg {
    font-size: ${({ theme }) => theme.custom.font.size.H3};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const MoreIconButtonStyled = styled(IconButtonStyled)`
  border: 3px solid rgba(185, 201, 220, 0.5);
  margin-right: ${({ theme }) => theme.space.s20};

  & svg {
    font-size: ${({ theme }) => theme.custom.font.size.H3};
    color: rgba(185, 201, 220, 0.75);
  }
`
