// @flow
import styled from '@emotion/styled'
import { ButtonWrapperStyled as ButtonWrapper } from '../styles'
import Flex from '@Common/Flex'
import { convertHexToRGB } from '@utils/view'

export const MakePaymentStyled = styled(Flex)`
  position: relative;
  & > p {
    max-width: 817px;
    color: ${({ theme }) => theme.palette.common.grey};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: 24px;
    margin: 15px 0 0 30px;

    ${({ theme }) => [theme.breakpoints.down('xs')]} {
      margin-left: 0;
    }
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    min-height:calc(100vh - 70px);

    > p {
      line-height: 18px;
    }
  `}
`

export const ButtonWrapperStyled = styled(ButtonWrapper)`
  margin-top: 52px;
`

export const PaymentReviewMemoStyled = styled.div`
  border-bottom: ${`1px solid ${convertHexToRGB('979797', 0.25)}`};
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  line-height: 24px;
  margin: 25px 0 0 30px;
  padding-bottom: 15px;
`
