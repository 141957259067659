// @flow
import styled from '@emotion/styled'
import Flex from '../Flex'
import {  Dialog } from '@material-ui/core'


export const ErrorDialogStyled = styled(Dialog)`
  & .dialog-paper {
    width: 918px;
    min-height: 598px;
    padding: ${({ theme }) => theme.space.s100};
    border-top-right-radius: 3em;
    border-bottom-left-radius: 3em;
    overflow: hidden;
  }

  & .dialog-paperWidthSm {
    max-width: 100%;
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    & .dialog-paper {
      width: 90%;
      padding: 10%;
      min-height: 0px;
    }
`


export const ErrorModalSection = styled('div')`
  width: 704px;
  text-align: center;
  line-height: 48px;
  font-size: ${({ theme }) => theme.custom.font.size.H3};
  color: ${({ theme }) => theme.palette.common.midnight};
  margin-bottom: ${({ theme }) => theme.space.s30};
  padding-bottom: ${({ theme }) => theme.space.s10};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: 90%;
    font-size: ${({ theme }) => theme.custom.font.size.H4};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    margin-bottom: ${({ theme }) => theme.space.s20};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    line-height: 36px;
  }
`

export const Oops = styled('div')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H2};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    margin-bottom: ${({ theme }) => theme.space.s20};
  }
`

export const Line = styled('div')`
  box-sizing: border-box;
  height: 1px;
  width: 482px;
  background-color: ${({ theme }) => theme.palette.common.grey};
`

export const ContactSupportText = styled(Flex)`
  height: 36px;
  line-height: 43px;
  margin-top: ${({ theme }) => theme.space.s90};
  margin-bottom: ${({ theme }) => theme.space.s30};
  font-size: ${({ theme }) => theme.custom.font.size.H3};
  color: ${({ theme }) => theme.palette.neutral.deep};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    margin-top: ${({ theme }) => theme.space.s30};
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin-bottom: ${({ theme }) => theme.space.s15};
    font-size: ${({ theme }) => theme.custom.font.size.H5};

  }
`

export const ContactDetailsWrap = styled(Flex)`
  width: 430px;
  justify-content: ${({ isAuth }) => (isAuth ? 'space-between' : 'center')};

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    flex-direction: column;
    width: 80%;
  }
`

export const ContactDetails = styled(Flex)`
  line-height: 29px;
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};

  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: 45%;
    font-size: ${({ theme }) => theme.custom.font.size.H5};
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin-bottom: ${({ theme }) => theme.space.s10};
    font-size: ${({ theme }) => theme.custom.font.size.md};

  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
`

export const StyledIcon = styled('div')`
  width: 17px;
  height: 23px;
  margin-top: ${({ theme }) => theme.space.s5};
  margin-right: ${({ theme }) => theme.space.s15};
`
