import { createStore, applyMiddleware, compose } from 'redux'
import raven from 'zego-shared/utils/sentry'
import { createLogger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'
import createRavenMiddleware from 'raven-for-redux'
import { setupAxios } from 'zego-shared/utils/api/axios'
import rootReducer from './rootReducer'
import { startSagas } from './sagas'

export const configureStore = Raven => {
  const sagaMiddleware = createSagaMiddleware({
    onError: error => {
      Raven.captureException(error)
    }
  })

  const ravenMiddleware = createRavenMiddleware(Raven)

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(...getMiddleware(sagaMiddleware, ravenMiddleware))
    )
  )

  const persistor = persistStore(store)
  setupAxios(store)
  sagaMiddleware.run(startSagas)

  return { store, persistor }
}

const getMiddleware = (sagaMiddleware, ravenMiddleware) => {
  if (process.env.NODE_ENV === 'development') {
    const loggerMiddleware = createLogger({
      collapsed: true,
      diff: false
    })
    return [sagaMiddleware, loggerMiddleware, ravenMiddleware]
  } else {
    return [sagaMiddleware, ravenMiddleware]
  }
}

export const { store, persistor } = configureStore(raven)
export default store
