// @flow
import styled from '@emotion/styled'
import Tab from '@material-ui/core/Tab'

export const Container = styled.div`
`

export const TabLabel = styled(Tab)`
  text-transform: unset;    
  & .MuiTab-wrapper {
    text-align: left;
    align-items: unset;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
  
    color: #5C6274;    
  }
`
export const DivTab = styled.div`
  margin-top: -40px;
  & .MuiTabs-indicator {
    max-width: 10px;
    height: 5px;
    margin-left: 12px;
    top: 38px;
    background-color: #FDB627;
  }
`