// @flow
import React from 'react'
import MaskedInput from 'react-input-mask'

type Props = {
  inputRef: Function
}
const PhoneNumber = (props: Props) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask="(199) 999-9999"
      maskChar={null}
      formatChars={{
        '1': '[1-9]',
        '9': '[0-9]'
      }}
    />
  )
}

export default PhoneNumber
