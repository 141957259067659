// @flow
import React from 'react'
import Flex from '@Common/Flex'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { creditReportingReactivateAction } from 'zego-shared/store/payments/creditReporting/actions'
import {
  Button,
  Ad,
  ContentWrap,
  Title,
  SubTitle,
  ShowForMdUp,
  ShowForMdDown
} from '../Common'

const Reactivate = () => {
  const dispatch = useDispatch()
  const { push: redirect } = useHistory()
  const onClick = () => {
    dispatch(creditReportingReactivateAction({ redirect }))
  }

  return (
    <Ad reactivate onClick={onClick}>
      <ContentWrap reactivate>
        <Flex direction="column" id="startReportingTxt">
          <ShowForMdUp>
            <Title reactivate>{["Don't", <br />, "Miss Out"]}</Title>
            <SubTitle reactivate>{["Get Credit for", <br />, "Your Payments!"]}</SubTitle>
          </ShowForMdUp>
          <ShowForMdDown>
            <Title reactivate>Don't Miss Out</Title>
            <SubTitle reactivate>Get Credit for your Payments!</SubTitle>
          </ShowForMdDown>
        </Flex>
        <Button reactivate id="reactivateBtn">Reactivate</Button>
      </ContentWrap>
    </Ad>
  )
}

export default Reactivate
