import { LD_INIT, LD_INIT_REQUEST } from './actions'
import { call, put, takeEvery, select } from 'redux-saga/effects'
import Promise from 'bluebird'
import * as ldClient from 'launchdarkly-js-client-sdk'
import { getLDUserInfo } from './selectors'

var ld

export function* watchInitLD() {
  yield takeEvery(LD_INIT_REQUEST, initLD)
}

export function* initLD() {
  const userInfo = yield select(getLDUserInfo)

  yield call(getLD, userInfo)
  yield put({ type: LD_INIT, ld })
}

function getLD(user) {
  ld = ldClient.initialize(window._env_.REACT_APP_LAUNCHDARKLY_API_KEY, user)
  var ldPromise = Promise.promisify(ld.on)
  return ldPromise('ready')
}

export default [watchInitLD()]
