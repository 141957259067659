// @flow

import { connect } from 'react-redux'
import { fetchPaymentOptionFees } from 'zego-shared/store/payments/fees/actions'
import { withRouter } from 'react-router-dom'
import { buildPaymentFields } from '@utils/view'
import { isIntegratedUser } from 'zego-shared/store/authorization/selectors'
import { getLineItemsDetails } from 'zego-shared/store/payments/balance/selectors'

import BalanceEdit from './BalanceEdit'


export const mapStateToProps = (state: Object) => ({
  isIntegratedUser: isIntegratedUser(state),
  lineItemsDetails: getLineItemsDetails(state)
})

export const mapDispatchToProps = (dispatch: Function, props: Object) => {
  const residentId = props.match.params.residentId
  return {
    fetchPaymentOptionFees: (paymentFields: Object[]) =>
      dispatch(
        fetchPaymentOptionFees(residentId, buildPaymentFields(paymentFields))
      )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BalanceEdit))
