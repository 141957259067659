import gql from 'graphql-tag'

export const GET_BILLING_PREFS_Q = gql`
{
  getResidentBillingPreferences {
    email
    paperStatementReason
    billingType
    propertyId
    tenantCode
    residentName
  }
}`;

export const GET_BILLING_PREFS_WITH_STREET_Q = gql`
{
  getResidentBillingPreferences {
    email
    paperStatementReason
    billingType
    propertyId
    tenantCode
    residentName
    address {
      address
    }
  }
}`;

export const UPDATE_BILLING_PREF_Q = gql`
mutation($billingType:Int!, $reasonCode:String, $email:String){
 updateResidentBillingPreferences(input: { billingType: $billingType, reasonCode: $reasonCode, email: $email} ){
    eBillResident{
      propertyId
      email
      tenantCode
      paperStatementReason
      billingType
    }
  }
}`;

export const VERIFY_RESIDENT_PIN_Q = gql`
query($pin:String!)
{
  verifyResidentPin(pin: $pin) {
    accessToken
  }
}`;
