// @flow
import React from 'react'
import isEqual from 'lodash/isEqual'
import FixedAmountEdit from './FixedAmountEdit'
import AmountBase from '../AmountBase'

type Props = {
  value: Object,
  onChange: (string, ?boolean) => mixed,
  isEditable: boolean,
  isBoth: boolean,
  payFullBal: Function,
  name: string,
  updateDetails: Object => void,
  fetchFees: Object => void
}

type State = {
  editMode: boolean,
  value: Object
}

class FixedAmount extends React.PureComponent<Props, State> {
  state = {
    editMode: false,
    value: this.props.value
  }

  componentWillUnmount() {
    const { value, fetchFees } = this.props
    if (!isEqual(this.state.value, value)) {
      value.fields && fetchFees(value.fields)
    }
  }

  updateDetailsOnChange = (editMode: boolean) => {
    const { value, name, updateDetails } = this.props
    const { value: initialVal } = this.state
    const hasChange = value !== initialVal
    const isCancel = !editMode
    isCancel && hasChange && updateDetails({ [name]: initialVal })
  }

  toggleEditMode = (editMode: boolean) => () => {
    this.updateDetailsOnChange(editMode)
    this.setState({ editMode })
  }

  render() {
    const { value, onChange, isEditable, isBoth, payFullBal } = this.props

    if (this.state.editMode) {
      return (
        <FixedAmountEdit
          id="editBtn"
          value={value}
          onChange={onChange}
          fixedAmountLabel="Amount"
          onCancel={this.toggleEditMode(false)}
        />
      )
    }

    const { total } = value

    return (
      <AmountBase
        title="Payment"
        description="Your amount to be paid with each auto payment."
        label="total"
        value={total}
        linkBtnText={isBoth && 'Pay My Full Balance'}
        onLinkBtnClick={payFullBal}
        onIconClicked={this.toggleEditMode(true)}
        disableEdit={!isEditable}
      />
    )
  }
}

export default FixedAmount
