// @flow
import React from 'react'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import { StyledButton } from './styles'

type Props = {
  id: string,
  typeName: string,
  elementId?: string,
  deleteMethod: (id: string, typeName: string) => void
}

const DeleteMethod = withAlertModal(({ showAlertModal, elementId }) => (
  <StyledButton id={elementId} width="100%" primary long onClick={showAlertModal}>
    Remove
  </StyledButton>
))
const RemoveMethodButton = ({
  id,
  typeName,
  elementId,
  deleteMethod = () => {}
}: Props) => {
  return (
    <DeleteMethod
      elementId={elementId}
      alertTitle="Are you sure you wish to delete?"
      onAlertClickYes={() => deleteMethod(id, typeName)}
    />
  )
}

export default RemoveMethodButton
