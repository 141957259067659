// @flow
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { string } from 'yup'
import Flex from '@Common/Flex'
import { getUserEmail } from 'zego-shared/store/authorization/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { updateUser } from 'zego-shared/store/authorization/actions'
import {
  Base,
  TopSection,
  GoButton,
  SubText,
  StyledInput,
  BottomSection,
  UpdateButton
} from './styles'

const SSOEmailUpdate = () => {
  const email = useSelector(getUserEmail)
  const isEmailSet = Boolean(email)

  const [editMode, setEditMode] = useState(!isEmailSet)
  const [emailValue, setEmailValue] = useState(email)

  const switchMode = () => {
    setEditMode(!editMode)
  }

  const onChangeText = event => {
    setEmailValue(event.target.value)
  }

  const dispatch = useDispatch()
  const updateEmail = async () => {
    try {
      await string().required().email().validate(emailValue)
      dispatch(
          updateUser(
            {
              email: emailValue
            },
            true
          )
      )
      switchMode()
    } catch (err) {
      dispatch(
        showSnackbar('Please enter a properly formatted email address', 'error')
      )
    }
  }

  return (
    <Base justifySpaceBetween direction="column">
      {isEmailSet ? (
        <TopSection>All Messages are Currently Directed to:</TopSection>
      ) : (
        <>
          <TopSection>Receipts &amp; Important Messages</TopSection>
          <SubText>Direct to your Inbox</SubText>
        </>
      )}
      <BottomSection direction="column">
        <SubText leftMargin>email</SubText>
        <Flex>
          <StyledInput
            className="input"
            fullWidth
            placeholder="email"
            type="email"
            bgWhite
            value={emailValue}
            onChange={onChangeText}
            id="ssoPaymentNotificationEmailId"
          />
          {isEmailSet ? (
            <UpdateButton onClick={updateEmail}>Update</UpdateButton>
          ) : (
            <GoButton onClick={updateEmail}>Go</GoButton>
          )}
        </Flex>
      </BottomSection>
    </Base>
  )
}

export default SSOEmailUpdate
