import { call, put, takeLatest, select, delay } from 'redux-saga/effects'
import { print } from 'graphql'
import {
  fetchCreditReportingConfig,
  fetchCreditReportingConfigError,
  fetchCreditReportingConfigStart,
  fetchCreditReportingConfigSuccess,
  getReportedLevelCreditTrans,
  getReportedLevelCreditTransStart,
  getReportedLevelCreditTransSuccess,
  getReportedLevelCreditTransError,
  getReportedLookbackTrans,
  getReportedLookbackTransStart,
  getReportedLookbackTransSuccess,
  getReportedLookbackTransError,
  creditReportingOptInAction,
  creditReportingOptInError,
  creditReportingOptInStart,
  creditReportingOptInSuccess,
  creditReportingReactivateAction,
  creditReportingReactivateStart,
  creditReportingReactivateSuccess,
  creditReportingReactivateError,
  creditReportingOptOutAction,
  creditReportingOptOutStart,
  creditReportingOptOutSuccess,
  creditReportingOptOutError,
  lookbackPurchase,
  lookbackPurchaseStart,
  lookbackPurchaseSuccess,
  lookbackPurchaseError,
} from '../actions'
import api from '../../../../utils/api'
import { getGraphQlData } from '../../../../utils/graphql'
import { getPaymentToken } from '../../../authorization/selectors'
import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'
import { showSnackbar } from '../../../snackbar/actions'
import {
  failedToFetchCreditReportingConfig,
  creditReportingSuccessMessage,
  levelCreditSuccessMessage,
  failedToGetReportedLCTrans,
  failedToGetReportedLBTrans,
  levelCreditDeactivationSuccessMessage,
  levelCreditReactivationSuccessMessage
} from '../../../../utils/messages'
import { isLevelCreditReportingEnabled } from '../selectors'
import { acceptTermsAndConditionsAction } from '../../../termsAndConditions/actions'

export function* fetchCreditReportingConfigSaga() {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }

    yield put(fetchCreditReportingConfigStart())

    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(queries.getCreditReportingConfig)
    )
    const payload = yield call(
      getGraphQlData,
      response,
      queries.GET_CREDIT_REPORTING_CONFIG
    )

    yield put(fetchCreditReportingConfigSuccess(payload))
  } catch (error) {
    yield put(fetchCreditReportingConfigError(error))
    yield put(showSnackbar(failedToFetchCreditReportingConfig, 'error'))
  }
}

export function* getLevelCreditTransactions() {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }

    yield put(getReportedLevelCreditTransStart())

    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(queries.getReportedLevelCreditTransactions)
    )

    const payload = yield call(
      getGraphQlData,
      response,
      queries.GET_REPORTED_LEVEL_CREDIT_TRANSACTIONS
    )

    yield put(getReportedLevelCreditTransSuccess(payload))
  } catch (error) {
    yield put(getReportedLevelCreditTransError(error))
    yield put(showSnackbar(failedToGetReportedLCTrans, 'error'))
  }
}

export function* getLookbackTransactions() {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }

    yield put(getReportedLookbackTransStart())

    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(queries.getReportedLookbackTransactions)
    )

    const payload = yield call(
      getGraphQlData,
      response,
      queries.GET_REPORTED_LOOKBACK_TRANSACTIONS
    )

    yield put(getReportedLookbackTransSuccess(payload))
  } catch (error) {
    yield put(getReportedLookbackTransError(error))
    yield put(showSnackbar(failedToGetReportedLBTrans, 'error'))
  }
}

export function* creditReportingOptInSaga({ input, onSuccess }) {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }

    yield put(creditReportingOptInStart())

    // Accept level credit TnC
    const isLevelCredit = yield select(isLevelCreditReportingEnabled)
    if (isLevelCredit) {
      yield put(acceptTermsAndConditionsAction({ tncType: 'level_credit' }))
    }

    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(mutations.creditReportingOptIn),
      { input }
    )
    const payload = yield call(
      getGraphQlData,
      response,
      mutations.CREDIT_REPORTING_OPT_IN
    )

    const successMessage = isLevelCredit
      ? levelCreditSuccessMessage
      : creditReportingSuccessMessage

    yield put(showSnackbar(successMessage, 'success'))
    yield delay(600)
    yield put(creditReportingOptInSuccess(payload))
    onSuccess && onSuccess()
  } catch ({ message }) {
    const errorMsg = message
    yield put(creditReportingOptInError())
    yield put(showSnackbar(errorMsg, 'error'))
  }
}

export function* creditReportingReactivateSaga(args) {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }

    yield put(creditReportingReactivateStart())

    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(mutations.creditReportingReactivateMutation)
    )

    const payload = yield call(
      getGraphQlData,
      response,
      mutations.CREDIT_REPORTING_REACTIVATE
    )

    yield put(creditReportingReactivateSuccess(payload))
    yield put(showSnackbar(levelCreditReactivationSuccessMessage, 'success'))

    if (args && args.redirect) {
      yield call(args.redirect, '/credit-reporting/reactivate/success')
    }
  } catch ({ message }) {
    yield put(creditReportingReactivateError())
    yield put(showSnackbar(message, 'error'))
  }
}

export function* creditReportingOptOutSaga({ redirect }) {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }

    yield put(creditReportingOptOutStart())

    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(mutations.creditReportingOptOutMutation)
    )

    const payload = yield call(getGraphQlData, response, mutations.CREDIT_REPORTING_OPT_OUT)

    yield put(creditReportingOptOutSuccess(payload))
    yield put(showSnackbar(levelCreditDeactivationSuccessMessage, 'success'))

    redirect && redirect('/payments/summary')
  } catch ({ message }) {
    yield put(creditReportingOptOutError())
    yield put(showSnackbar(message, 'error'))
  }
}

export function* purchaseLookbackSaga({ input, onSuccess }) {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }

    yield put(lookbackPurchaseStart())

    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(mutations.purchaseLookBack),
      { input }
    )
    const payload = yield call(
      getGraphQlData,
      response,
      mutations.PURCHASE_LOOKBACK
    )

    yield delay(200)
    yield put(lookbackPurchaseSuccess(payload))
    onSuccess && onSuccess()
  } catch (error) {
    const errorMsg = error.extensions?.details?.[0]?.message || error.message
    yield put(lookbackPurchaseError())
    yield put(showSnackbar(errorMsg, 'error'))
  }
}

function* watchFetchCreditReportingConfigSaga() {
  yield takeLatest(
    fetchCreditReportingConfig().type,
    fetchCreditReportingConfigSaga
  )
}

function* watchCreditReportingOptInSaga() {
  yield takeLatest(creditReportingOptInAction().type, creditReportingOptInSaga)
}

function* watchCreditReportingReactivate() {
  yield takeLatest(creditReportingReactivateAction().type, creditReportingReactivateSaga)
}

function* watchCreditReportingOptOut() {
  yield takeLatest(creditReportingOptOutAction().type, creditReportingOptOutSaga)
}

function* watchGetReportedLevelCreditTransactions() {
  yield takeLatest(
    getReportedLevelCreditTrans().type,
    getLevelCreditTransactions
  )
}

function* watchGetReportedLookbackTransactions() {
  yield takeLatest(getReportedLookbackTrans().type, getLookbackTransactions)
}

function* watchPurchaseLookbackSaga() {
  yield takeLatest(lookbackPurchase().type, purchaseLookbackSaga)
}

export default [
  watchFetchCreditReportingConfigSaga(),
  watchGetReportedLevelCreditTransactions(),
  watchCreditReportingOptInSaga(),
  watchCreditReportingReactivate(),
  watchCreditReportingOptOut(),
  watchGetReportedLookbackTransactions(),
  watchPurchaseLookbackSaga()
]
