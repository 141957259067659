import gql from 'graphql-tag'

export const GET_PROPERTY = 'getProperty'

export const getPropertyQuery = gql`
  query($propertyUuId: ID!) {
    ${GET_PROPERTY}(id: $propertyUuId) {
        address {
            address
            address2
            city
            state
            postalCode
            country
        }
    }
  }
`