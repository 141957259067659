// @flow

import React from 'react'
import Button from '@Common/Button'
import {
  ExistingCardWrapper,
  UnderlinedButton,
  CurrentMethodText,
  LinksContainer,
  MethodText,
  FeeText,
  ExistingCardContent,
  ExistingCardMainContent
} from './styles'
import PaymentMethodIcon from '../PaymentMethodIcon'
import PaymentNotes, { PaymentNotesBtn } from '../../PaymentNotes'
import { Hidden, useMediaQuery, useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getPaymentNotesEnabled } from 'zego-shared/store/pmSettings/selectors'
import Flex from '@Common/Flex'

type Props = {
  currentMethod: Object,
  onContinueClicked: Function,
  onShowMore: Function,
  onCancel: Function,
  showNotes: boolean
}

const ExistingCard = ({
  currentMethod,
  onContinueClicked,
  onShowMore,
  onCancel,
  showNotes
}: Props) => {
  const theme = useTheme()
  const isSSO = theme.ux.isSSO
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const hasPaymentNotes = useSelector(getPaymentNotesEnabled) && showNotes
  const { type, account, fee } = currentMethod

  const renderTitle = () => (
    <CurrentMethodText>Current Payment Method</CurrentMethodText>
  )

  const renderOptionsBtn = () => (
    <UnderlinedButton onClick={onShowMore}>
      View all Payment Options
    </UnderlinedButton>
  )

  const renderContinueBtn = () => (
    <Button primary onClick={onContinueClicked}>
      continue with current
    </Button>
  )

  const renderCard = () => (
    <ExistingCardWrapper
      showPaymentNotes={hasPaymentNotes}
      customPadding="30px 20px 30px 30px"
    >
      <Hidden lgUp>{renderTitle()}</Hidden>

      <ExistingCardContent justifySpaceBetween>
        <div>
          <PaymentMethodIcon
            {...currentMethod}
            existing={true}
            height={isMobile ? 60 : 145}
          />
        </div>

        <ExistingCardMainContent>
          <Hidden mdDown>{renderTitle()}</Hidden>
          <MethodText>
            {type} {account}
          </MethodText>
          <FeeText>Fee: {fee || 'Free'}</FeeText>
          <Hidden only="xs">
            <div>{renderContinueBtn()}</div>
          </Hidden>
        </ExistingCardMainContent>

        <Hidden mdDown>
          <div>{!isSSO && renderOptionsBtn()}</div>
        </Hidden>
      </ExistingCardContent>

      <Hidden smUp>{renderContinueBtn()}</Hidden>
    </ExistingCardWrapper>
  )

  return (
    <>
      <PaymentNotes disabled={isSSO || !hasPaymentNotes}>
        {renderCard()}
      </PaymentNotes>

      <LinksContainer direction="row" justifyRight>
        {isSSO ? (
          <>
            <Flex flex={1} justifyLeft>
              <PaymentNotesBtn />
            </Flex>
            <UnderlinedButton light onClick={onCancel}>
              Cancel Payment
            </UnderlinedButton>
            {renderOptionsBtn()}
          </>
        ) : (
          <>
            <UnderlinedButton light onClick={onCancel}>
              Exit &amp; Cancel Payment
            </UnderlinedButton>
            <Hidden lgUp>{renderOptionsBtn()}</Hidden>
          </>
        )}
      </LinksContainer>
    </>
  )
}

export default ExistingCard
