import { combineReducers } from 'redux'
import units from './units/reducers'
import companies from './companies/reducers'
import properties from './properties/reducers'

export const generateSelect = enhancerMap => {
  const select = combineReducers({
    properties: enhancerMap.properties(properties),
    units: enhancerMap.units(units),
    companies: enhancerMap.companies(companies)
  })

  return enhancerMap.self(select)
}

export const select = combineReducers({
  units,
  companies,
  properties
})
