import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Box from '@material-ui/core/Box'
import styled from '@emotion/styled'
import SVG from '../../../SVG'

export const DefaultInstitutionLogo = styled(SVG)`
  position: relative;
  height: 50px;
  width: 50px;
`

export const StyledInstitutionAccountsCard = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5em;
  margin-bottom: 1em;
`

export const InstitutionAccountsHeader = styled(CardHeader)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 0;

  & .MuiCardHeader-avatar {
    max-width: 50px;
    max-height: 50px;
  }

  & .MuiCardHeader-title {
    font-size: 1rem;
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    color: ${({ theme }) => theme.palette.common.midnight};
    text-transform: capitalize;
  }
`

export const InstitutionAccountsContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1.5em 0;

  &:last-child {
    padding-bottom: 0;
  }
`

export const StyledPlaidAccount = styled(Box)`
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.common.grey};
  text-transform: capitalize;
  border-top: 1px solid;
  border-top-color: ${({ theme }) => theme.palette.neutral.warm};
  padding: 1.5em 0.5em;

  & > .MuiBox-root {
    flex-grow: 1;
  }

  & .MuiTypography-root:first-of-type {
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  }

  & .select-for-payment {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    user-select: none;

    & .MuiTypography-root {
      text-decoration: underline;
      text-transform: none;
      font-size: ${({ theme }) => theme.custom.font.size.sm};
      color: black;
    }

    & span {
      width: 16px;
      height: 16px;
      border: 1px solid;
      border-color: ${({ theme }) => theme.palette.secondary.main};
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin-left: 5px;

      & svg {
        transform: scale(0.5) rotate(180deg);
      }
    }
  }
`
