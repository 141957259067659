import { put, takeLatest, select, call } from 'redux-saga/effects'
import { fetchResidentUserIdAction, fetchResidentUserIdSuccess, fetchResidentUserIdError, fetchResidentUserIdStart } from '../actions'
import { getToken, getPaymentToken, getUserId } from '../../authorization/selectors'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import { print } from 'graphql'
import { getGraphQlData } from '../../../utils/graphql'
import { getResidentUserIdQuery, GET_RESIDENT } from '../graphql/queries'

export function* fetchResidentUserId() {
    yield put(fetchResidentUserIdStart())

    try {
        const authToken = yield select(getToken)
        const paymentToken = yield select(getPaymentToken)
        const residentUuId = yield select(getUserId)
        const tokens = { authToken, paymentToken }
        const response = yield call(
            api.graphqlQuery,
            tokens,
            print(getResidentUserIdQuery),
            { residentUuId }
        )
        const payload = yield call(getGraphQlData, response, GET_RESIDENT)
        yield put(fetchResidentUserIdSuccess(payload.userId))
    } catch (error) {
        yield put(fetchResidentUserIdError(error))
        log(`Failed to fetch resident user id. Error: ${error}`)
    }
}

export function* watchFetchResidentUserId() {
    yield takeLatest(fetchResidentUserIdAction().type, fetchResidentUserId)
}

export default [watchFetchResidentUserId()]