import React from 'react'
import get from 'lodash/get'

const refetchData = currentProps => {
  return currentProps.refetchData
    ? currentProps.refetchData(currentProps)
    : currentProps.fetchData && currentProps.fetchData(currentProps)
}

// the only way to change property is using the dropdown
// the only way to change units is by searching
// we should be able to check independently and never have an issue
// if you dont want to refresh on resource change use refetch data and return null
const fetchDataOnResourceChange = (prevProps, currentProps) => {
  const previousPropertyId = get(prevProps, ['match', 'params', 'propertyId'])
  const currentPropertyId = get(currentProps, ['match', 'params', 'propertyId'])
  if (previousPropertyId !== currentPropertyId) {
    refetchData(currentProps)
    return
  }

  const previousUnitId = get(prevProps, ['match', 'params', 'unitId'])
  const currentUnitId = get(currentProps, ['match', 'params', 'unitId'])
  if (previousUnitId !== currentUnitId) {
    refetchData(currentProps)
    return
  }

  const previousUserId = get(prevProps, ['match', 'params', 'userId'])
  const currentUserId = get(currentProps, ['match', 'params', 'userId'])
  if (previousUserId !== currentUserId) {
    refetchData(currentProps)
    return
  }
}

const withFetchData = Component =>
  class extends React.Component {
    componentDidMount() {
      const { fetchData } = this.props
      fetchData && fetchData(this.props)
    }

    componentDidUpdate(prevProps) {
      const { componentDidUpdate } = this.props
      componentDidUpdate && componentDidUpdate(prevProps, this.props)
      fetchDataOnResourceChange(prevProps, this.props)
    }

    componentWillUnmount() {
      const { componentWillUnmount } = this.props
      componentWillUnmount && componentWillUnmount()
    }

    render() {
      const { fetchData, ...props } = this.props
      return <Component {...props} />
    }
  }

export default withFetchData
