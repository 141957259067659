import gql from 'graphql-tag'

export const GET_CONFIG = 'getPaymentReminder'

export const getPaymentReminderQuery = gql`
  query {
    ${GET_CONFIG} {
      email
      altEmail
      mobile
      altMobile
      frequency
      dateToSend
      remindersEnabled
    }
  }
`
