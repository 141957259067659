// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Flex from '@Common/Flex'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import {
  GoButton,
  SubText,
  MainText,
  SupText,
  Space,
  LookbackAdBase,
  LookbackAdMain,
  LookbackAdContent,
  LookbackAdText
} from './styles'

const LookbackAds = () => {
  const residentId = useSelector(getUserId)
  const url = `/payments/${residentId}/credit-reporting/lookback`

  return (
    <LookbackAdBase id="lookbackAd">
      <Link to={url}>
        <Space />
        <LookbackAdMain>
          <LookbackAdContent>
            <LookbackAdText>
              <MainText id="lookbackMainTxt">Count Past Payments </MainText>
              <SubText id="lookbackSubTxt">
                Toward Your Credit Score<SupText>*</SupText>
              </SubText>
            </LookbackAdText>
            <Flex id="goNowLink">
              <GoButton>Learn More</GoButton>
            </Flex>
          </LookbackAdContent>
        </LookbackAdMain>
      </Link>
    </LookbackAdBase>
  )
}

export default LookbackAds
