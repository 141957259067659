import gql from 'graphql-tag'
import fragments from './fragments'

export const GET_BALANCE_DETAIL = 'getBalanceDetail'

export const getBalDetail = gql`
  query($residentId: ID!) {
    ${GET_BALANCE_DETAIL}(id: $residentId) {
      paymentFields {
        ...PaymentField
      }
      optionalPaymentFields {
          ...PaymentField
      }
      lineItems {
        ...LineItem
      }
    }
  }
  ${fragments.PAYMENT_FIELD}
  ${fragments.LINE_ITEM}
`
