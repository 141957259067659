// @flow
import type { Node } from 'react'
import React from 'react'
import { methodTypes } from '@utils/view'
import { AddCardStyled } from './styles'
import BankAccountForm from './BankAccountForm'
import CreditCardForm from './CreditCardForm'

const AddPaymentMethodForm = ({
  methodType,
  formHeader,
  customPadding,
  ...props
}: {
  methodType: string,
  formHeader?: Node,
  customPadding: string,
  props: Object
}) => {
  const FormHeader = formHeader || null

  return (
    <AddCardStyled customPadding={customPadding}>
      {FormHeader && <FormHeader />}
      {methodTypes.bankAccount === methodType && <BankAccountForm {...props} />}
      {methodTypes.card === methodType && <CreditCardForm {...props} />}
    </AddCardStyled>
  )
}

export default AddPaymentMethodForm
