// @flow
import PaymentMethods from '../PaymentMethods'
import BalanceEdit from '../BalanceEdit'
import ExpressPay from '../ExpressPay'

import type { Flow } from '../PaymentFlow'
import { withPaymentNotes } from '../PaymentNotes'
import { getIsSsoPayments } from 'zego-shared/store/authorization/selectors'
import store from '../../../store'

const state = store.getState()
const isSSO = getIsSsoPayments(state)

export const balance: Flow = {
  Component: withPaymentNotes(BalanceEdit, { disabled: isSSO }),
  name: 'amount',
  nextFlow: 1,
  infoItem: {
    title: 'Payment',
    label: 'Total',
    value: 'amount.total',
    editable: true
  },
  progress: 40
}

export const paymentMethod: Flow = {
  Component: PaymentMethods,
  name: 'lastPaymentMethod',
  nextFlow: 2,
  required: true,
  infoItem: {
    title: 'lastPaymentMethod.name',
    label: 'Fee',
    isDynamicTitle: true,
    value: 'lastPaymentMethod.fee',
    editable: true
  },
  progress: 55,
  props: { showNotes: true }
}

export const expressPayUpSell: Flow = {
  Component: ExpressPay,
  name: 'useExpressPay',
  progress: 65
}
