import React from 'react'
import Grid from '@material-ui/core/Grid'
import { CheckBox } from '@Common/Form'
import { formatDateMMDDYYYY } from 'zego-shared/utils/date'
import { DATE_FORMAT } from '../schema'
import DateMask from '../DateMask'
import { buildRegionsOptions, UNITED_STATES } from '@utils/view'
import type { FieldsProps } from '../types'
import { HelpIcon } from '@Common/HelpText/styles'
import {
  StyledInputText,
  StyledInputDate,
  StyledSelect,
  StyledFormGroup
} from '../styles'
import {
  TermsAndConditionsStyled,
  // Used in phase 2
  // ZegoTermsAndCondionsStyled,
  // ZegoCheckboxStyled,
  LevelCreditTermsAndCondionsStyled,
  LevelCreditCheckboxStyled,
  Divider,
  LeaseEndWrap,
  StyledTooltip,
  LevelCreditFormHeader
} from './styles'
import { useTheme } from '@material-ui/styles'

const lcPrivacyUrl = 'https://www.levelcredit.com/privacy-policy'
const US_STATES = buildRegionsOptions(UNITED_STATES, true)
const handleCheckBoxChange = (cbFn: OnChange, values: Object) => (
  event: SyntheticInputEvent<>,
  checked: boolean
) => {
  const { name } = event.target
  cbFn({ ...values, [name]: checked })
}
const handleDateChange = (cbFn: OnChange, values: Object) => (
  date: Object,
  name: string
) => {
  cbFn({ ...values, [name]: date })
}
const LevelCreditFields = ({
  onChange,
  values,
  errors,
  setValues,
  getErrorDetails,
  getNamedProps,
  setFieldValue,
  handleBlur: onBlur,
  handleFocus: onFocus,
  handleLeaseMonthToMonthChange,
  latestTnC
}: FieldsProps & {
  handleLeaseMonthToMonthChange: Function
}) => {
  const inputProps = { onFocus, onBlur }
  const checkBoxChange = handleCheckBoxChange((value: Object) => {
    handleLeaseMonthToMonthChange(!value.leaseMonthToMonth)
    setTimeout(() => {
      // defer formik setValues till schema update
      setValues(value)
    }, 0)
  }, values)
  const dateChange = handleDateChange(setValues, values)
  const isSSO = useTheme().ux.isSSO

  return (
    <>
      <Grid container spacing={isSSO ? 2 : 3}>
        <Grid item xs={12} md={6} lg={4}>
          <StyledFormGroup
            label="first Name"
            errorId="first-name"
            {...getErrorDetails('firstName')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('firstName')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <StyledFormGroup
            label="last Name"
            errorId="last-name"
            {...getErrorDetails('lastName')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('lastName')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <StyledFormGroup
            label="birthdate"
            errorId="birth-date"
            {...getErrorDetails('birthDate')}>
            <StyledInputDate
              name="expiration"
              readOnly={false}
              formatter={formatDateMMDDYYYY}
              format={DATE_FORMAT}
              previousDatesEnabled={true}
              {...getNamedProps('birthDate')}
              InputProps={{
                ...inputProps,
                name: 'birthDate',
                inputComponent: DateMask,
                placeholder: DATE_FORMAT
              }}
              onChange={dateChange}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={8} lg={4}>
          <StyledFormGroup
            label="email"
            errorId="email"
            {...getErrorDetails('email')}>
            <StyledInputText
              name="email"
              onChange={onChange}
              {...getNamedProps('email')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <StyledFormGroup
            label="SSN"
            errorId="ssn"
            {...getErrorDetails('ssn')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('ssn')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <StyledFormGroup
            label="confirm SSN"
            errorId="confirm-ssn"
            {...getErrorDetails('confirmSnn')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('confirmSnn')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <LeaseEndWrap>
            <CheckBox
              name="leaseMonthToMonth"
              onChange={checkBoxChange}
              checked={values.leaseMonthToMonth}
            />
            <div>
              I am on a month to month lease
              <StyledTooltip
                placement="bottom-end"
                interactive
                title={
                  <span>
                    <p>
                      To setup an end date, uncheck this box to add your lease
                      end date.
                    </p>
                    <p>
                      To setup reporting without an end date, leave this box
                      checked.
                    </p>
                  </span>
                }>
                <HelpIcon />
              </StyledTooltip>
            </div>
          </LeaseEndWrap>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <StyledFormGroup
            label="lease End"
            hidden={values.leaseMonthToMonth}
            {...getErrorDetails('leaseEndDate')}>
            <StyledInputDate
              name="leaseEndDate"
              errorId="lease-end-date"
              readOnly={false}
              format={DATE_FORMAT}
              formatter={formatDateMMDDYYYY}
              {...getNamedProps('leaseEndDate')}
              InputProps={{
                name: 'leaseEndDate',
                inputComponent: DateMask,
                placeholder: DATE_FORMAT
              }}
              onChange={dateChange}
            />
          </StyledFormGroup>
        </Grid>
      </Grid>
      <Divider />
      <LevelCreditFormHeader>
        Please enter your rental address below
      </LevelCreditFormHeader>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <StyledFormGroup
            label="Property Address"
            errorId="address"
            helperText="P.O. Boxes are not accepted"
            {...getErrorDetails('address')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('address')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <StyledFormGroup
            label="Apartment, suite, unit, etc"
            errorId="address-2">
            <StyledInputText name="address2" onChange={onChange} />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <StyledFormGroup
            label="zip"
            errorId="zip"
            {...getErrorDetails('postalCode')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('postalCode')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <StyledFormGroup
            label="city"
            errorId="city"
            {...getErrorDetails('city')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('city')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <StyledFormGroup
            label="state"
            errorId="state"
            {...getErrorDetails('state')}>
            <StyledSelect
              id="stateSelect"
              options={US_STATES}
              onChange={onChange}
              {...getNamedProps('state')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
      </Grid>
      <Divider />
      <TermsAndConditionsStyled>
        {/* To be enabled in phase 2
        <ZegoTermsAndCondionsStyled>
          <ZegoCheckboxStyled
            name="zegoTermsAndConditions"
            onChange={checkBoxChange}
            checked={values.zegoTermsAndConditions}
          />
          <p>
            I agree that by clicking on the Submit button, I am providing
            written instructions to PayLease, LLC (DBA Zego) under the Fair
            Credit Reporting Act authorizing Zego to access and obtain your
            consumer report information, including any credit information, from
            any consumer reporting agency, for the purpose of facilitating your
            use of the LevelCredit services.
          </p>
        </ZegoTermsAndCondionsStyled> */}
        <LevelCreditTermsAndCondionsStyled>
          <LevelCreditCheckboxStyled
            name="levelCreditTermsAndConditions"
            onChange={checkBoxChange}
            checked={values.levelCreditTermsAndConditions}
          />
          <p>
            I agree to LevelCredit's{' '}
            <a href={latestTnC.url} target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>{' '}
            and{' '}
            <a href={lcPrivacyUrl} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          </p>
        </LevelCreditTermsAndCondionsStyled>
      </TermsAndConditionsStyled>
    </>
  )
}
export default LevelCreditFields
