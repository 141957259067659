// @flow
import React from 'react'
import compose from 'lodash/flowRight'
import { withTheme } from 'emotion-theming'
import AlertModal from './AlertModal'
import ScrollToTop from '../ScrollToTop'

type State = {
  showDialog: boolean
}

type Props = {
  alertYesButtonText: ?string,
  alertNoButtonText: ?string,
  alertTitle: string,
  alertMessage: string,
  onAlertClickYes: () => {},
  onAlertClickNo?: () => {},
  showScrollToTop?: boolean,
  theme: any
}

const withAlertModal = (WrappedComponent: Function) =>
  class withDialog extends React.Component<Props, State> {
    state = {
      showDialog: false
    }

    showAlertModal = () => {
      this.setState({
        showDialog: true
      })
    }

    closeAlertModal = () => {
      const { onAlertClickNo } = this.props
      this.setState({
        showDialog: false
      })

      onAlertClickNo && onAlertClickNo()
    }

    onClickYes = () => {
      const { onAlertClickYes } = this.props
      onAlertClickYes()

      this.setState({
        showDialog: false
      })
    }

    render() {
      const {
        alertYesButtonText,
        alertNoButtonText,
        alertTitle,
        alertMessage,
        showScrollToTop,
        renderBottom,
        theme,
        ...rest
      } = this.props
      return (
        <React.Fragment>
          <WrappedComponent
            showAlertModal={this.showAlertModal}
            {...rest}
            theme={theme}
          />
          <AlertModal
            title={alertTitle}
            message={alertMessage}
            buttons={[
              {
                onClick: this.onClickYes,
                primary: true,
                outline: true,
                long: true,
                text: alertYesButtonText || 'Yes'
              },
              {
                onClick: this.closeAlertModal,
                primary: true,
                long: true,
                text: alertNoButtonText || 'No'
              }
            ]}
            show={this.state.showDialog}
            onClose={this.closeAlertModal}
            renderBottom={renderBottom}
            children={this.props.children}
          />
          {showScrollToTop && <ScrollToTop />}
        </React.Fragment>
      )
    }
  }

export default compose([withTheme, withAlertModal])
