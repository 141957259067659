// @flow

import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
  color: ${({ theme, color }) => color || theme.palette.primary.main};
  cursor: pointer;
  text-decoration: none;
`
