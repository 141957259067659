// @flow
import { string, object, number, ref, addMethod, date } from 'yup'
import moment from 'moment'
import { methodTypes } from '@utils/view'
import {
  CVVRegex,
  firstNameLastNameRegex,
  numberRegex,
  validateDate
} from '@utils/validationUtils'

export const EXPIRATION_FORMAT = 'MM/YY'

addMethod(date, 'format', validateDate)

const bankAccountSchema = object().shape({
  accountHolderName: string().required('account holder name is required'),
  bankName: string().required('bank name is required'),
  type: string().required('type is required'),
  routingNumber: number()
    .typeError('routing number must be a number')
    .required('routing number is required')
    .positive('routing number must be a positive number'),
  accountNumber: number()
    .typeError('account number must be a number')
    .required('account number is required')
    .positive('account number must be a positive number'),
  confirmAccountNumber: number()
    .typeError('confirm account number must be a number')
    .oneOf([ref('accountNumber'), null], "account numbers don't match")
    .required('confirm account number is required')
})

export const getCardSchema = (isInternational: boolean) =>{
  return object().shape({
    name: string()
      .matches(new RegExp(firstNameLastNameRegex), {
        message: 'full name is required'
      })
      .required('name on card is required'),
    address: string().required('address is required'),
    expiration: date()
      .format(EXPIRATION_FORMAT)
      .typeError('expiration date is invalid')
      .min(
        moment()
          .subtract('M', 1)
          .toDate(),
        'expiration date is past'
      )
      .required('expiration date is required'),
    country: string().required('country is required'),
    state: isInternational ? undefined : string().required('state is required'),
    city: string().required('city is required'),
    zipCode: isInternational ? undefined : string().required('Zip code is required'),
    cvv: string()
      .matches(CVVRegex, {
        message: 'CVV must be 3 or 4 digits'
      })
      .required('CVV is required'),
    cardNumber: string()
      .matches(new RegExp(numberRegex), {
        message: 'card number must be numeric'
      })
      .required('card number is required')
  })
}

export default {
  [methodTypes.bankAccount]: bankAccountSchema
}
