import { fetchResidentUserIdSuccess } from '../actions'

const initialState = {
  payUserId: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchResidentUserIdSuccess().type:
      return {
        ...state,
        payUserId: action.payload
      }
    default:
      return state
  }
}

export default reducer