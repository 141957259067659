import React from 'react'
import Flex from '@Common/Flex'
import { StyledButton } from '../styles'
import { NoRemindersText } from './styles'

type Props = {
  changeMode: () => null
}

const NoReminderSet = ({ changeMode }: Props) => {
  return (
    <>
      <NoRemindersText>
        Payment reminders are not currently enabled. Use the button below to
        schedule email and/or text reminders for payments due.
      </NoRemindersText>
      <Flex justifyRight>
        <StyledButton id="scheduleRemindersBtn" onClick={changeMode} primary>
          Schedule Reminder
        </StyledButton>
      </Flex>
    </>
  )
}

export default NoReminderSet
