// @flow
import React from 'react'
import { Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchAutoPayData } from 'zego-shared/store/payments/autopay/actions'
import AddAutoPay from './AddAutoPay'
import AutoPayView from './AutoPayView'

type props = {
  match: Object
}

const AutoPay = ({ match }: props) => {
  const { path } = match
  const dispatch = useDispatch()
  React.useEffect(() => {
    const {
      params: { residentId }
    } = match
    dispatch(fetchAutoPayData(residentId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <React.Fragment>
      <Route component={AutoPayView} path={path} exact />
      <Route component={AddAutoPay} path={`${path}/add`} />
      <Route component={AddAutoPay} path={`${path}/edit`} />
    </React.Fragment>
  )
}

export default AutoPay
