// @flow
import React from 'react'

class Logout extends React.Component<{ logout: Function }> {
  componentDidMount() {
    this.props.logout()
  }
  render() {
    return null
  }
}

export default Logout
