// @flow
import React from 'react'
import { Box, Typography } from '@material-ui/core'
import amex from '@images/cc_amex.png'
import discover from '@images/cc_discover.png'
import mastercard from '@images/cc_mastercard.png'
import visa from '@images/cc_visa.png'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import RemoveMethodButton from '../RemoveMethodButton'
import CardAccountDetailsMobile from './CardAccountDetailsMobile'
import { StyledCard } from '../styles'
import type { CreditCardAccount } from '../../../types'

const maskAccount = account => {
  const lastFour = account.slice(-4)
  return lastFour.padStart(4, '•')
}

export const getNetwork = (network: string) => {
  switch (network.toUpperCase()) {
    case 'AMEX':
      return <img width="25%" height="25%" src={amex} alt="amex" />
    case 'DISCOVER':
      return <img width="25%" height="25%" src={discover} alt="discover" />
    case 'MASTERCARD':
      return <img width="25%" height="25%" src={mastercard} alt="mastercard" />
    case 'VISA':
      return <img width="25%" height="25%" src={visa} alt="visa" />
    default:
      return network
  }
}

export const PartialCardNumber = ({
  number = '••••',
  elementId
}: {
  number: string,
  elementId: string
}) => (
  <Typography id={elementId}>
    <Box
      color="primary.main"
      fontSize={26}
      fontWeight="fontWeightBold"
      component="span">
      {number}
    </Box>
  </Typography>
)

const CardAccountDetails = (props: {
  account: CreditCardAccount,
  deleteMethod: (string, string) => void
}) => {
  const { account, deleteMethod } = props
  const CardNetwork = getNetwork(account.network)
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const isSSO = theme.ux.isSSO
  const customPadding = isSSO ? '40px 25px' : undefined

  if (isSmDown) {
    return <CardAccountDetailsMobile account={account} Network={CardNetwork} />
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      alignItems={isSSO ? 'center' : 'flex-start'}>
      <Box flexShrink="0" width={408}>
        <StyledCard customPadding={customPadding}>
          <Box pb={10} display="flex" justifyContent="flex-start">
            {CardNetwork}
          </Box>
          <Box pr={4} display="flex" justifyContent="space-between">
            <PartialCardNumber />
            <PartialCardNumber />
            <PartialCardNumber />
            <PartialCardNumber
              elementId="lastFour"
              number={maskAccount(account.lastFour)}
            />
          </Box>
          <Box pt={6} display="flex">
            <Typography id="holderName">
              <Box pr={18} fontWeight="700" component="div">
                {account.cardHolderName}
              </Box>
            </Typography>
            <Typography>EXP</Typography>
            <Typography id="expDate" component="div">
              <Box pl={2} fontWeight="700" component="span">
                {account.expirationMonth}/{account.expirationYear}
              </Box>
            </Typography>
          </Box>
        </StyledCard>
      </Box>
      <Box
        width="100%"
        display="flex"
        mt={isSSO ? '30px' : '70px'}
        fontWeight="fontWeightBold"
        fontSize="h6.fontSize"
        justifyContent="flex-end">
        <RemoveMethodButton
          elementId="removeBtn"
          deleteMethod={deleteMethod}
          id={account.id}
          typeName={account.typeName}
        />
      </Box>
    </Box>
  )
}

export default CardAccountDetails
