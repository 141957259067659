// @flow
import * as React from 'react'
import type { Node } from 'react'

import { Container, Item } from './styles'

type Props = {
  children: Node,
  rows?: boolean
}

type GridItemProps = {
  children: Node,
  columnStart: number,
  columnSpan: number,
  rowStart?: number,
  rowSpan?: number
}

function Grid(props: Props) {
  const { children, rows = true, ...rest } = props
  return (
    <Container {...rest} rows={rows}>
      {children}
    </Container>
  )
}

export const GridItem = (props: GridItemProps) => {
  const { children, ...rest } = props
  return <Item {...rest}>{children}</Item>
}

export default Grid
