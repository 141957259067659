// @flow
import styled from '@emotion/styled'
import Card from '@Common/Card'

export const ScheduleStyled = styled(Card)`
  min-height: 159px;
  & div.MuiCardContent-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: fit-content;
    padding-bottom: 45px;
  }

  & h5 {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    line-height: 22px;
    margin: 0;
  }

  & button {
    padding: 0;

    &:hover {
      background-color: transparent;
    }
  }

  & .form-group {
    width: 100%;
  }

  & .form-group p {
    display: none;
  }
`
