// @flow
import React from 'react'
import Flex from '@Common/Flex'
import { useSelector } from 'react-redux'
import { hasPurchasedLookback } from 'zego-shared/store/payments/creditReporting/selectors'
import {
  Banner,
  ContentWrap,
  Count,
  CountCircle,
  Title,
  SubTitle,
  CircleWrap,
  MonthsOfPayments,
  CircleContainer
} from './styles'

type Props = {
  variant: 'tall' | 'short',
  reportedTransactionsCount: number,
  reportedLookbackTransactionsCount: number
}

const getSubtitleText = (
  hasLBnoLCTrans,
  hasLookbackTrans,
  hasLookback,
  reportedTransactionsCount
) => {
  const defaultText =
    reportedTransactionsCount > 1
      ? 'We’ve reported your recent payments'
      : 'We’ve reported your recent payment'

  if (!hasLookback) return defaultText
  return !hasLBnoLCTrans
    ? 'We’ve reported your historical payments'
    : hasLookbackTrans
    ? 'We’ve reported your recent and historical payments'
    : defaultText
}

const OptedInReports = ({
  variant,
  reportedTransactionsCount,
  reportedLookbackTransactionsCount
}: Props) => {
  const hasLookback: boolean = useSelector(hasPurchasedLookback)
  const hasLookbackTrans = reportedLookbackTransactionsCount > 0
  const hasReportedTransactions = reportedTransactionsCount > 0
  const hasLBnoLCTrans = hasLookback && hasReportedTransactions

  return (
    <Banner hasLookback={hasLookback} variant={variant}>
      <ContentWrap hasLookback={hasLookback} variant={variant}>
        <Flex direction="column">
          <Title>Success!</Title>
          <SubTitle>
            {getSubtitleText(
              hasLBnoLCTrans,
              hasLookbackTrans,
              hasLookback,
              reportedTransactionsCount
            )}
          </SubTitle>
        </Flex>
        {hasReportedTransactions && (
          <CircleContainer hasLookback={hasLookback} direction="column">
            <CircleWrap hasLookback={hasLookback} justifyCenter>
              <CountCircle
                hasLookback={hasLookback}
                justifyCenter
                variant={variant}>
                <Count hasLookback={hasLookback} alignSelf="center">
                  {reportedTransactionsCount}
                </Count>
              </CountCircle>
            </CircleWrap>
            <MonthsOfPayments hasLookback={hasLookback}>
              {reportedTransactionsCount > 1
                ? 'Months of Payments'
                : 'Month of Payment'}
            </MonthsOfPayments>
          </CircleContainer>
        )}
        {hasLookback && (
          <CircleContainer hasLookback={hasLookback} direction="column">
            <CircleWrap hasLookback={hasLookback} justifyCenter>
              {hasLookbackTrans ? (
                <CountCircle blue hasLookback={hasLBnoLCTrans} justifyCenter>
                  <Count hasLookback={hasLBnoLCTrans} alignSelf="center">
                    {reportedLookbackTransactionsCount}
                  </Count>
                </CountCircle>
              ) : (
                <CountCircle lightBlue hasLookback={hasLookback} justifyCenter>
                  <Count
                    inProgress
                    hasLookback={hasLookback}
                    alignSelf="center"
                    direction="column">
                    <span>In</span>
                    <span>Progress</span>
                  </Count>
                </CountCircle>
              )}
            </CircleWrap>
            <MonthsOfPayments hasLookback={hasLookback}>
              {`Lookback Payment${
                reportedLookbackTransactionsCount > 1 ? 's' : ''
              }`}
            </MonthsOfPayments>
          </CircleContainer>
        )}
      </ContentWrap>
    </Banner>
  )
}

export default OptedInReports
