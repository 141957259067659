// @flow
import React, { useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import Flex from '@Common/Flex'
import ShowOnMedia from '@Common/ShowOnMedia'
import PaymentInfo from '../PaymentInfo'
import CreditReportingAd from '@views/Payments/CreditReportingAd/CreditReportingAd'
import { getShowSSOEmailCollectionForm } from 'zego-shared/store/pmSettings/selectors'
import { ReturnIcon, PrinterIcon } from '@icons'
import SSOEmailUpdate from './SSOEmailUpdate'
import {
  SuccessHeaderStyled,
  ConfirmationStyled,
  ConfirmationWrapperStyled,
  Header,
  ShowForMdUp,
  ShowForMdDown,
  BoxShadowWrap,
  ReturnToSummary,
  ReturnToSummaryText,
  ConfirmationText,
  ConfirmationId,
  SummaryInfoStyled,
  AutoPayAdOTP,
  PrinterIconButton
} from './styles'

type Props = {
  confirmationId: string,
  summary: Object,
  width: string,
  match: { url: string, params: { residentId: string } },
  levelCreditActive: boolean,
  levelCreditInactive: boolean,
  levelCreditReportingEnabled: boolean,
  paymentNotes?: string
}

const PaymentSuccess = ({
  width,
  levelCreditActive,
  levelCreditInactive,
  levelCreditReportingEnabled,
  confirmationId,
  summary,
  paymentNotes,
  match: {
    url,
    params: { residentId }
  }
}: Props) => {
  const history = useHistory()
  const location = useLocation()
  const theme = useTheme()
  const isSSO = theme.ux.isSSO
  const ssoEmailCollectionEnabled = useSelector(getShowSSOEmailCollectionForm)
  const showSSOEmailUpdateBox = isSSO && ssoEmailCollectionEnabled

  useEffect(() => {
    window.scrollTo(0, 0)

    const listener = event => {
      history.go(1)
    }

    // Push the current location to prevent the browser from visibly going back
    summary && history.push(location.pathname)

    window.addEventListener('popstate', listener)

    return () => window.removeEventListener('popstate', listener)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const summaryRoute = `/payments/summary`

  if (!summary) {
    return <Redirect to={summaryRoute} from={url} />
  }

  return (
    <>
      <SuccessHeaderStyled alignStart justifySpaceBetween>
        <ConfirmationWrapperStyled direction="column">
          <Header>
            <span>Payment Success!</span>
          </Header>
          <ConfirmationStyled direction="column" justifyCenter>
            <p id="otpConfId">
              Confirmation <ConfirmationId>#{confirmationId}</ConfirmationId>
            </p>
            <ShowOnMedia media="screen">
              <ConfirmationText>
                A receipt has been sent to your email address on file and your
                property management has been notified of your payment.
              </ConfirmationText>
            </ShowOnMedia>
          </ConfirmationStyled>
        </ConfirmationWrapperStyled>

        <ShowOnMedia media="screen">
          <Flex direction="column">
            <ReturnToSummary to={summaryRoute}>
              <ReturnIcon name="return" width="24px" height="24px" />
              <ReturnToSummaryText>return to summary</ReturnToSummaryText>
            </ReturnToSummary>

            <PrinterIconButton onClick={window.print}>
              <PrinterIcon name="printer2" width="24px" height="24px" />
            </PrinterIconButton>
          </Flex>
        </ShowOnMedia>
      </SuccessHeaderStyled>

      <ShowOnMedia media="print">
        <PaymentInfo paymentInfo={summary} paymentNotes={paymentNotes} />
      </ShowOnMedia>

      <ShowOnMedia media="screen">
        <SummaryInfoStyled>
          <Flex direction="column" flex={width === 'md' ? 0.8 : 1}>
            <BoxShadowWrap>
              <ShowForMdDown>
                <AutoPayAdOTP />
              </ShowForMdDown>
              <PaymentInfo
                paymentInfo={summary}
                paymentNotes={paymentNotes}
                oneColumn
              />
            </BoxShadowWrap>
            {showSSOEmailUpdateBox && <SSOEmailUpdate />}
            {levelCreditReportingEnabled &&
              !showSSOEmailUpdateBox &&
              !levelCreditInactive && (
                <ShowForMdDown marginTop>
                  <CreditReportingAd isOptedIn={levelCreditActive} />
                </ShowForMdDown>
              )}
          </Flex>

          {levelCreditReportingEnabled && !levelCreditInactive && (
            <ShowForMdUp fixedWidth>
              <Flex flex="0.6" direction="column">
                <CreditReportingAd isOptedIn={levelCreditActive} />
                <Flex marginTop="33px">
                  <AutoPayAdOTP />
                </Flex>
              </Flex>
            </ShowForMdUp>
          )}

          {!levelCreditReportingEnabled && (
            <ShowForMdUp>
              <Flex flex="0.6" direction="column" fullHeight>
                <AutoPayAdOTP variant="tall" />
              </Flex>
            </ShowForMdUp>
          )}
        </SummaryInfoStyled>
      </ShowOnMedia>
    </>
  )
}

export default withWidth()(PaymentSuccess)
