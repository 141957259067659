export const paymentScheduledMessage = {
  message: 'A payment was recently scheduled.',
  tooltip:
    "Looks like there's a payment processing! Please note that your " +
    'balance will update once we receive an updated balance from your Property Manager.'
}

export const activeAutopayMessage = {
  message: 'You have an existing Autopay.',
  tooltip:
    'There is already an active Autopay associated with this account. ' +
    'To edit an existing payment schedule, go to Autopay. ' +
    'To make another payment, click Continue.'
}
