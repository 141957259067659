// @flow
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PlaidCard from '@Common/PlaidCard'
import Button from '@Common/Button'
import SVG from '../../../SVG'
import { useStyles } from './styles'
import '@images/svg/warning_icon.svg'

export type Props = {
  bankName: string,
  onClose: Function,
  onContinue: Function
}

const BankAccountWarningModal = ({ bankName, onClose, onContinue }: Props) => {
  const classes = useStyles()

  return (
    <PlaidCard
      classes={{ root: classes.root, content: classes.content }}
      title={
        <React.Fragment>
          <SVG className={classes.warningIcon} name="warning_icon" />
          <Typography variant="h4" component="h1" className={classes.title}>
            Important notice regarding your {bankName} bank account linking
          </Typography>
        </React.Fragment>
      }
      onClose={onClose}
      actions={
        <Button primary onClick={onContinue} className={classes.button}>
          Continue
        </Button>
      }>
        
      <React.Fragment>
        <Typography>
          Please note that {bankName} allows linking of only<Typography style={{"font-weight": "bold"}} display="inline"> one bank account per account login. </Typography> 
          If you have used the same {bankName} login credentials to link a bank account in this account or any other Zego account,
          your previously added payment method will be deactivated and all autopays associated with that payment method will fail.
        </Typography>
      </React.Fragment>
    </PlaidCard>
  )
}

export default BankAccountWarningModal
