// @flow
import React from 'react'
import Button from '@Common/Button'
import { getLatestTAndC } from 'zego-shared/store/termsAndConditions/selectors'
import { DisclaimerText, DisclaimerTextFull } from './DisclaimerText'
import { CardDisclaimerStyled } from './styles'
import { useSelector } from 'react-redux'

const CardDisclaimer = () => {
  const [expand, setExpand] = React.useState(false)
  const TermsLink = useSelector(getLatestTAndC).url

  const handleChange = () => {
    setExpand(!expand)
  }
  return (
    <CardDisclaimerStyled alignCenter>
      {!expand && <DisclaimerText onClick={handleChange} />}
      {expand && (
        <DisclaimerTextFull termsLink={TermsLink}>
          <Button
            id="readLessLink"
            onClick={handleChange}>
            Read Less -
          </Button>
        </DisclaimerTextFull>
      )}
    </CardDisclaimerStyled>
  )
}

export default CardDisclaimer
