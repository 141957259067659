// @flow

import { useSelector } from 'react-redux'
import { getIsAdminLogin } from 'zego-shared/store/authorization/selectors'

type Props = {
  isAdminLogin: boolean,
  children?: Node
}

const AdminLogAsVisibility = ({children, isAdminLogin} : Props) => {
  const isAdmin = isAdminLogin ?? useSelector(getIsAdminLogin)

  return isAdmin ? children : null
}

export default AdminLogAsVisibility
