// @flow
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { withTheme } from 'emotion-theming'
import Edit from '@material-ui/icons/Edit'
import Button from '@Common/Button'

import {
  AmountValueStyled,
  DescriptionStyled,
  AmountStyled,
  IconButtonStyled
} from './styles'
import Flex from '../../../Common/Flex'
import { Grid } from '@material-ui/core'
import type { Theme } from '../../../../theme'

type Props = {
  isEditIconClicked: boolean,
  onIconClicked: Function,
  title: string,
  description: string,
  value: string,
  label: string,
  disableEdit: boolean,
  linkBtnText: string,
  onLinkBtnClick: Function,
  fullBal: boolean,
  theme: Theme
}

const Amount = ({
  onIconClicked,
  title,
  description,
  value,
  label,
  disableEdit,
  linkBtnText,
  onLinkBtnClick,
  fullBal,
  theme
}: Props) => {
  const changeLayout = useMediaQuery('(max-width:700px)')
  const ssoPaymentLayout = theme.ux.isSSO && changeLayout

  return (
    <AmountStyled>
      <Grid container>
        <Grid item xs={12} sm={ssoPaymentLayout ? 6 : 7}>
          <DescriptionStyled direction="column">
            <h5>{title}</h5>
            <p>{description}</p>
            {linkBtnText && (
              <Button
                id={linkBtnText.replace(/\s/g, '')}
                link
                disableRipple
                onClick={onLinkBtnClick}
                color={theme.palette.secondary.dark}>
                {linkBtnText}
              </Button>
            )}
          </DescriptionStyled>
        </Grid>
        <Grid item xs={12} sm={ssoPaymentLayout ? 6 : 5}>
          <AmountValueStyled justifyCenter alignCenter fullBal={fullBal}>
            {/* {label && <span>total</span>} */}
            <Flex justifyCenter alignCenter>
              {value}
              {!disableEdit && (
                <IconButtonStyled id="editBtn" onClick={onIconClicked}>
                  <Edit />
                </IconButtonStyled>
              )}
            </Flex>
          </AmountValueStyled>
        </Grid>
      </Grid>
    </AmountStyled>
  )
}

export default withTheme(Amount)
