import withNormalizedFetch from '../../../helpers/reducers/withNormalizedFetch'
import {
  PAYMENT_METHODS_SECTION,
  SET_PAYMENT_METHOD,
  SAVE_SELECTED_METHOD_TYPE,
  updateBankAccountSuccess,
  updateCardSuccess,
  fetchLastUsedPaymentMethodSuccess,
  fetchPaymentMethodsSuccess
} from '../actions'

import { initialState } from './initialState'

const paymentMethods = (state = initialState, { type, payload }) => {
  switch (type) {
    case updateBankAccountSuccess().type:
    case updateCardSuccess().type: {
      return {
        allIds: [...state.allIds.filter(id => id !== payload.id), payload.id],
        byId: { ...state.byId, [payload.id]: payload }
      }
    }
    case SAVE_SELECTED_METHOD_TYPE:
      return { ...state, selectedMethodType: payload }
    default:
      return state
  }
}

export const method = (state = {}, { type, payload }) => {
  switch (type) {
    case fetchLastUsedPaymentMethodSuccess().type:
      return payload || {}

    case fetchPaymentMethodsSuccess().type:
      return payload[0] || {}

    case updateBankAccountSuccess().type:
    case updateCardSuccess().type:
      return payload
    case SET_PAYMENT_METHOD:
      return payload
    default:
      return state
  }
}

export default withNormalizedFetch(PAYMENT_METHODS_SECTION)(paymentMethods)
