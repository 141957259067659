// @flow
import styled from '@emotion/styled'
import { StyledCard, InfoLane } from '../styles'

export const NewUserNoBalanceStyled = styled(StyledCard)`
  ${({ theme }) => !theme.ux.isSSO && 'height: 374px'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    margin-top: -20px;
    height: 250px;
    width: 235px;
  }
  & .MuiCardContent-root {
    ${({ theme }) => theme.ux.isSSO && 'padding: 30px !important'};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  & h4 {
    width: 374px;
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    line-height: 29px;
    text-align: center;
    margin: 0;
    z-index: 1;
  }
  & p {
    height: 24px;
    width: 328px;
    color: ${({ theme }) => theme.palette.common.grey};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: 14px;
    text-align: center;
  }
  & button {
    text-transform: capitalize;
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  }
  & svg {
    margin-top: -45px;
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    height: 310px;
  }
`

export const StyledButton = styled(InfoLane)`
  position: relative;
  width: 300px;

  ${({ theme }) => [theme.breakpoints.between('md', 'lg')]} {
    width: 70%;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    position: fixed;
    height: 110px;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: ${({ theme }) => theme.palette.common.white};
    margin-top: 0px;
  }
`
