// @flow
import styled from '@emotion/styled'
import PlaidBg from '@images/modal_bg.png'
import { CommonIcon } from '@icons'

export const Modal = styled.div`
  font-family: acumin-pro, sans-serif;
  background-color: white;
  color: #0e1427;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  text-align: center;
  box-shadow: -1px 1px 5px #dedede;
  border-radius: 4px;
  padding: 5em 3em;
  h3 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 2em;
    font-family: acumin-pro, sans-serif;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
  display: flex;
  height: 100vh;
  width: 100vw;
`}
`

export const ModalContent = styled.div`
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    margin: auto;
    width: 50%
    max-width: 320px;
  `}
`

export const ModalCard = styled.div`
  cursor: pointer;
  padding: 1em 2em;
  background: url(${PlaidBg}) no-repeat center center;
  background-size: cover;
  box-shadow: 2px 2px 4px #ababab80;
`

export const ModalText = styled.div`
  text-align: left;
  h2 {
    font-size: 23px;
    font-weight: bold;
    margin-top: 0;
    margin-block-end: 0;
  }
  p {
    font-size: 15px;
    margin-top: 0;
    margin-block-start: 0;
    margin-block-end: 0em;
  }
  padding-bottom: 20px;
`
export const ModalIcons = styled.div`
  display: flex;
  img {
    object-fit: none;
  }
`

export const ModalFooter = styled.div`
  cursor: pointer;
  background: #e6f6f0;
  border-radius: 3px;
  width: 100%;
  margin-top: 3em;
  margin-bottom: 5em;
  & > p {
    font-size: 13px;
    padding: 5px;
    margin-block-start: 0;
    margin-block-end: 0;
  }
`

export const CloseIcon = styled(CommonIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  fill: #848484;
  cursor: pointer;

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    top: 28px;
    right: 28px;
  `}
`
