// @flow
import styled from '@emotion/styled'
import { ListItem } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Flex from '@Common/Flex'
import { convertHexToRGB } from '@utils/view'
import { ButtonWrapperStyled as ButtonWrapper } from '../styles'

export const ModalBackDropStyled = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) =>
    convertHexToRGB(theme.palette.common.white, 0.85)};
  position: absolute;
`

export const ModalContent = styled(Flex)`
  min-height: 481px;
  background-color: ${({ theme }) => theme.palette.common.white};
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  box-shadow: -1px 1px 5px #dedede;
  border-radius: 4px;
  padding: 4em 3em;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 45px 0 45px;
  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    width: 75vw;
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    width: 90vw;
    padding: 4em 1.5em;
  }
`

export const ErrorContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.error.plaidBalanceError};
  border: ${({ theme }) => `1px solid ${theme.palette.error.border}`};
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  padding: 10px 20px;
  margin-bottom: 30px;
`

export const ErrorIconWrapperStyled = styled.div`
  margin-right: 10px;
  & .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.error.main};
  }
`

export const ErrorMsg = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-style: normal;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  line-height: 19px;
  letter-spacing: 0px;
  p {
    margin: 0;
  }
  span {
    font-weight: 700;
  }
`

export const ButtonWrapperStyled = styled(ButtonWrapper)`
  margin-top: 15px;
`

export const MethodContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.light};
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 5px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.palette.neutral.warm};
  border-style: solid;
`

export const MethodsStyled = styled(Flex)`
  width: 100%;
  margin: 0;
  height: 100%;

  & > *:nth-child(n + 2) {
    margin-top: 15px;
  }
  padding-right: 15px;
  max-height: 220px;
  overflow: scroll;

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding-right: 10px;
    ${({ theme }) =>
      theme.ux.isSSO &&
      `
      width: 90%;
      `}
  }
`

export const StyledListItem = styled(ListItem)`
  min-height: ${({ theme }) => (theme.ux.isSSO ? `66px` : `84px`)};
  padding-top: 0px;
  padding-bottom: 0px;
`

export const HeaderStyled = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space.s35};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-bottom: ${({ theme }) => theme.space.s30};
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin-bottom: ${({ theme }) => theme.space.s25};
  }

  & h5 {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    line-height: 22px;
    text-transform: capitalize;
    margin: 0;
  }
`

const IconButtonStyled = styled(IconButton)`
  height: 84px;
  width: 136px;
  border-radius: 15px;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 70px;
    width: 100px;
  }
`

export const AddIconButtonStyled = styled(IconButtonStyled)`
  background-color: ${({ theme }) => theme.palette.secondary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }

  & svg {
    font-size: ${({ theme }) => theme.custom.font.size.H3};
    color: ${({ theme }) => theme.palette.primary.main};
  }
  margin-left: 15px;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: 20px;
  }
`

export const MethodListContainer = styled(Flex)`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    flex-direction: column;
    align-items: flex-end;
  }
`
export const NameStyled = styled(Flex)`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  display: inline-block;
  height: 16px;
  line-height: 19px;
  padding-right: 10px;
`
