import gpl from 'graphql-tag'
import { CREDIT_REPORTING_CONFIG } from './fragments'

export const CREDIT_REPORTING_OPT_IN = 'creditReportingOptin'
export const CREDIT_REPORTING_REACTIVATE = 'creditReportingReactivate'
export const CREDIT_REPORTING_OPT_OUT = 'creditReportingOptout'
export const PURCHASE_LOOKBACK = 'purchaseLookBack'

export const creditReportingOptIn = gpl`
mutation($input: CreditReportingOptinInput! ) {
  ${CREDIT_REPORTING_OPT_IN}(input: $input) {
    ...CreditReportingConfig
  }
}
${CREDIT_REPORTING_CONFIG}
`

export const creditReportingReactivateMutation = gpl`
  mutation {
    ${CREDIT_REPORTING_REACTIVATE} {
      ...CreditReportingConfig
    }
  }
  ${CREDIT_REPORTING_CONFIG}
`

export const creditReportingOptOutMutation = gpl`
  mutation {
    ${CREDIT_REPORTING_OPT_OUT} {
      ...CreditReportingConfig
  }
}
${CREDIT_REPORTING_CONFIG}
`

export const purchaseLookBack = gpl`
mutation($input: PurchaseLookBackInput! ) {
  ${PURCHASE_LOOKBACK}(input: $input) {
    ...CreditReportingConfig
  }
}
${CREDIT_REPORTING_CONFIG}
`
