import {
  createFetchActions,
  createRefreshActions
} from '../../../helpers/actions/creators'

export const UNITS_FETCH_SECTION = 'UNITS'

export const UNIT_SECTION = 'UNIT'

export const UNIT_INFO_SECTION = 'UNIT_INFO'

export const SELECT_UNIT = 'SELECT_UNIT'

export const CLEAR_UNIT = 'CLEAR_UNIT'

export const {
  fetchAction: fetchActionUnits,
  fetchStartAction: fetchUnitsStart,
  fetchSuccessAction: fetchUnitsSuccess,
  fetchErrorAction: fetchUnitsError
} = createFetchActions(UNITS_FETCH_SECTION)

export const fetchUnits = name => fetchActionUnits({ name })

export const {
  fetchAction: fetchActionUnit,
  fetchStartAction: fetchUnitStart,
  fetchSuccessAction: fetchUnitSuccess,
  fetchErrorAction: fetchUnitError
} = createFetchActions(UNIT_SECTION)

export const fetchUnit = id => fetchActionUnit({ id })

export const {
  fetchAction: fetchActionUnitInfo,
  fetchStartAction: fetchUnitInfoStart,
  fetchSuccessAction: fetchUnitInfoSuccess,
  fetchErrorAction: fetchUnitInfoError
} = createFetchActions(UNIT_INFO_SECTION)

export const {
  refreshAction: refreshUnitAction,
  refreshStartAction: refreshUnitStart,
  refreshSuccessAction: refreshUnitSuccess,
  refreshErrorAction: refreshUnitError
} = createRefreshActions(UNIT_SECTION)

export const refreshUnit = id => refreshUnitAction({ id, isRefreshing: true })

export const selectUnit = id => ({
  type: SELECT_UNIT,
  id
})

export const clearUnit = () => ({
  type: CLEAR_UNIT
})
