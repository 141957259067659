import styled from '@emotion/styled'
import Flex from '@Common/Flex'

export const ContactInfo = styled('div')`
  min-width: ${({ theme }) => theme.space.s100};
  max-width: ${({ theme }) => theme.space.s150};
  text-align: ${({ alignRight }) => alignRight && 'right'};
`

export const InfoTitle = styled(Flex)`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  color: ${({ theme }) => theme.palette.primary.main};
`

export const InfoLane = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  color: ${({ theme }) => theme.palette.common.midnight};
  line-height: 24px;
  font-weight: ${({ light, theme }) => light && theme.custom.font.weight.light};
`

export const Demarcation = styled('div')`
  box-sizing: border-box;
  height: 1px;
  width: 408px;
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.secondary}`};
`

export const StaffName = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  color: ${({ theme }) => theme.palette.common.midnight};
  text-transform: capitalize;
  line-height: 19px;
`

export const StaffRole = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  font-weight: ${({ light, theme }) => light && theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.common.grey};
  line-height: 12px;
`

export const MapIframe = styled('iframe')`
  display: block;
  width: 100%;
  border: 0;
  margin: 0;
  overflow: hidden;
`
