import { combineReducers } from 'redux'
import {
  HAS_API_ERRORS,
  CLEAR_API_ERRORS,
  SHOW_TIMEOUT_COMP,
  HIDE_TIMEOUT_COMP
} from './actions'

const apiErrors = (state = 0, action) => {
  switch (action.type) {
    case HAS_API_ERRORS:
      return state + 1
    case CLEAR_API_ERRORS:
      return 0
    default:
      return state
  }
}

const isShowErrorComp = (state = false, action) => {
  switch (action.type) {
    case SHOW_TIMEOUT_COMP:
      return true
    case HIDE_TIMEOUT_COMP:
      return false
    default:
      return state
  }
}

export default combineReducers({
  apiErrors,
  isShowErrorComp
})
