// @flow
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import Button from '@Common/Button'

export const TitleText = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.space.s14};
`

export const BodyText = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.grey};
  line-height: ${({ theme }) => theme.space.s22};
  margin-bottom: ${({ theme }) => theme.space.s60};
`

export const StyledButton = styled(Button)`
  margin: 0;
  margin-bottom: -2px;
`
