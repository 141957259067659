import { createFetchActions } from '../../helpers/actions/creators'

export const PM_SETTINGS = 'PM_SETTINGS'

export const {
  fetchAction: fetchPmSettingsAction,
  fetchStartAction: fetchPmSettingsStart,
  fetchSuccessAction: fetchPmSettingsSuccess,
  fetchErrorAction: fetchPmSettingsError
} = createFetchActions(PM_SETTINGS)
