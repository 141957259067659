import React from 'react'

const LookbackVideo = () => {
  return (
    <iframe
      width=""
      height=""
      src="https://www.youtube.com/embed/dQbR3DZLTqE"
      frameborder="0"
      title="LevelCredit Reporting"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen="true">
    </iframe>
  )
}

export default LookbackVideo
