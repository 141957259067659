import React from 'react'
import { Redirect } from 'react-router-dom'
import Activation from './Activation'
import queryString from 'query-string'
import api from 'zego-shared/utils/api'
import Loader from '@Common/Loader'

const queryParams = {}

const getUserStatus = async data => {
  const { token } = data
  if (!token) {
    return false
  }
  const response = await api.checkActivationToken(token).catch(err => false)
  return response ? true : false
}

class Onboarding extends React.Component {
  state = {
    tokenIsValid: undefined
  }

  async componentDidMount() {
    const {
      location: { search }
    } = this.props

    Object.assign(queryParams, queryString.parse(search))
    const status = await getUserStatus(queryParams)

    this.setState({ tokenIsValid: status })
  }

  render() {
    const { state } = this
    const {
      location: { search }
    } = this.props

    const { first_name: firstName, last_name: lastName, token: activationToken, property_name: propertyName, address1, email, zip, city } = queryParams
    
    const account = { firstName, lastName, activationToken, propertyName, address1, email, zip, city }
    const continueUrl = `/new-resident/password-setup${search}`;
    const supportUrl = '//gozego.force.com/residents';

    return state.tokenIsValid === true ? (
      <Activation
        account={account}
        continueUrl={continueUrl}
        supportUrl={supportUrl}
      />
    ) : state.tokenIsValid === false ? (
      <Redirect to="/login" />
    ) : (
      <Loader />
    )
  }
}

export default Onboarding
