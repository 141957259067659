import React from 'react'
import LoginContainer from '../../views/Auth/Login'
import Logout from '../../views/Logout'
import { Route, Redirect } from 'react-router-dom'
import NewUser from '../../views/Auth/NewUser'
import LeaseRenewal from '../../views/Auth/LeaseRenewal'
import PasswordSetup from '../../views/Auth/NewUser/PasswordSetup'
import MobileApp from '../../views/Auth/NewUser/MobileApp'
import AccountDeactivated from '../../views/Auth/AccountDeactivated'
import ChangeDeliveryTypeContainer from '../../views/Auth/Login/ChangeDeliveryTypeContainer'

const getAuthRoutes = () => [
  <Route key="login" exact path="/login" component={LoginContainer} />,
  <Route key="logout" exact path="/logout" component={Logout} />,
  <Route key="deactivated" exact path="/account-deactivated" component={AccountDeactivated} />,
  <Route
    key="resetPassword"
    exact
    path="/password-reset"
    render={props => <LoginContainer {...props} mode="passwordReset" />}
  />,
  <Route
    key="resetPassword"
    exact
    path="/request-password-reset"
    render={props => <LoginContainer {...props} mode="passwordRecoveryRequest" />}
  />,
  <Route key="newUser" path="/new-resident" component={NewUser} exact />,
  <Route key="leaseRenewal" exact path="/lease_renew" component={LeaseRenewal}  />,
  <Route
    key="passwordSetup"
    path="/new-resident/password-setup"
    component={PasswordSetup}
  />,
  <Route
    key="mobileApp"
    path="/new-resident/mobile-app"
    component={MobileApp}
  />,
  <Route
    key="changeDeliveryType"
    path="/sdp"
    component={ChangeDeliveryTypeContainer}
  />,
  <Route
    key="sdp"
    path="/deliveryPreference"
    render={props => (
      <Redirect to={{
        pathname: '/sdp',
        search: props.location.search
      }}
      />)}
  />
]

export default getAuthRoutes
