import { combineReducers } from 'redux'
import {
  FRAUD_TOOLKIT_ERROR,
  PLAID_INSUFFICIENT_FUNDS_ERROR,
  ONE_TIME_PAYMENT_DETAILS,
  makeOnetimePaymentSuccess,
  getPayPalPaymentSuccess,
  requestPaymentReceiptSuccess
} from '../actions'

const details = (state = null, action) => {
  switch (action.type) {
    case ONE_TIME_PAYMENT_DETAILS:
      return action.details
    case getPayPalPaymentSuccess().type:
      return {
        ...state,
        lastPaymentMethod: {
          ...state.lastPaymentMethod,
          id: action.payload.paymentMethodId,
          payPalEmail: action.payload.payPalEmail
        }
      }
    default:
      return state
  }
}

const data = (state = {}, action) => {
  switch (action.type) {
    case makeOnetimePaymentSuccess().type:
      return action.payload
    default:
      return state
  }
}

const fraudToolkit = (state = { error: false }, action) => {
  switch (action.type) {
    case FRAUD_TOOLKIT_ERROR:
      return {
        error: action.error
      }
    default:
      return state
  }
}

const paymentReceipt = (state = {}, action) => {
  switch (action.type) {
    case requestPaymentReceiptSuccess().type:
      return action.payload
    default:
      return state
  }
}

const plaidInsufficientFunds = (state = { error: false }, action) => {
  switch (action.type) {
    case PLAID_INSUFFICIENT_FUNDS_ERROR:
      return {
        error: action.error
      }
    default:
      return state
  }
}

export default combineReducers({
  details,
  data,
  fraudToolkit,
  paymentReceipt,
  plaidInsufficientFunds
})
