import styled from '@emotion/styled'
import Flex from '../../views/Common/Flex'
import Button from '../../views/Common/Button'

export const Wrapper = styled('div')`
  width: 320px;
  max-height: 498px;
  border-radius: 8px 8px 0 0;
  position: fixed;
  left: auto;
  bottom: 0;
  right: 20px;
  margin: 0;
  height: 90%;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  background: ${({ theme }) => theme.palette.common.white};
  z-index: 1000;
`

export const Header = styled(Flex)`
  background: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.common.white};
  height: 44px;
  border-radius: 8px 8px 0 0;
`
export const Content = styled('div')`
  padding: ${({ theme }) => `${theme.space.s10} ${theme.space.s20}`};
`

// below are based on salesforce suggested values
export const EmbeddedServiceHelpButton = styled('div')`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  box-shadow: none;
  overflow: visible;
  z-index: 999;
  font-family: sans-serif;
`

export const HelpButton = styled('div')`
  position: fixed;
  bottom: 12px;
  right: 12px;
  height: 46px;
  width: 173.672px;
  -webkit-font-smoothing: subpixel-antialiased;
`

export const EmbeddedServiceIcon = styled('span')`
  display: inline-block;
`
export const ChatButton = styled(Button)`
  position: absolute;
  bottom: ${({ theme }) => theme.space.s5};
  width: 280px;
`
