// @flow

import moment from 'moment'

export const STATEMENTS_QUERY_NAME = 'getResidentStatements'

export const getStatementsQuery = () => {
  return `query GetResidentStatements($dateRange: DateRange) {
    ${STATEMENTS_QUERY_NAME}(dueDate: $dateRange) {
        dueDate
        amountDue {
          currency
          amount
        }
        statementUrl
      }
    }
  `
}

export const getStatementsVariables = () => ({
  dateRange: {
    start: moment()
      .subtract(12, 'months')
      .toISOString(),
    end: moment()
      .add(1, 'M')
      .add(1, 'd')
      .toISOString()
  }
})
