import { createFetchActions } from '../../helpers/actions/creators'

export const USER_RESOURCE = 'USERRESOURCE'

export const {
  fetchAction: fetchUserResourceAction,
  fetchStartAction: fetchUserResourceStart,
  fetchSuccessAction: fetchUserResourceSuccess,
  fetchErrorAction: fetchUserResourceError
} = createFetchActions(USER_RESOURCE)

export const fetchUserResource = residentId =>
  fetchUserResourceAction({ residentId })
