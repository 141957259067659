import withFetch from '../../../helpers/reducers/withFetch'
import { PAYMENT_BALANCE_SECTION } from '../actions'
import { initialState } from './initialState'

const paymentBalance = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(PAYMENT_BALANCE_SECTION)(paymentBalance)
