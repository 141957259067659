import gql from 'graphql-tag'
import { FEES_FIELDS } from './fragments'

export const paymentOptions = gql`
  query($oneTimePayment: Boolean) {
    getPaymentOptions(oneTimePayment: $oneTimePayment) {
      ach
      cc
      debit
      paypal
      plaid
      achPlaidOnly
      plaidBalanceChecks
    }
  }
`

export const fees = gql`
  query(
    $residentId: ID!
    $paymentFields: [PaymentFieldInput!]!
    $cc: Boolean!
    $ach: Boolean!
    $debit: Boolean!
  ) {
    getFees(id: $residentId, paymentFields: $paymentFields) {
      creditCardFee @include(if: $cc) {
        ...FeesFields
      }
      achFee @include(if: $ach) {
        ...FeesFields
      }
      debitCardFee @include(if: $debit) {
        ...FeesFields
      }
    }
  }
  ${FEES_FIELDS}
`
