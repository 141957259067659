/* eslint-disable no-undef */
// @flow
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import Button from '@Common/Button'
import Flex from '@Common/Flex'
import Hidden from '@material-ui/core/Hidden'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import Layout from '../Layout'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import PaymentFlow, { usePaymentFlow } from '../PaymentFlow'
import Summary from './Summary'
import PaymentSuccess from './PaymentSuccess'
import PaymentsBlocked from '../PaymentsBlocked'
import createOrder from 'zego-shared/store/payments/oneTimePayment/paypal/createOrder'
import { paymentStatuses } from 'zego-shared/store/payments/summary/constants'
import { StyledMobileButtons } from '../styles'
import {
  ButtonWrapperStyled,
  PayPalButtonWrap,
  LaunchPayPalButtonBtn,
  UnderlinedButton
} from './styles'
import type { Flow } from '../PaymentFlow'
import { paymentScheduledMessage, activeAutopayMessage } from './messages'
import { makeFraudToolkitError } from 'zego-shared/store/payments/oneTimePayment/actions'
import { useSelectedMethod } from '../../../hooks'
import ReauthFlowModal from '../Plaid/Reauthorization/ReauthFlowModal'
import PayPalButton from '../common/PayPalButton/PayPalButton'
import { useTheme } from '@material-ui/core'
import { PaymentNotesBtn } from '../PaymentNotes'
import { getCancelOptionBehaviorOverride } from 'zego-shared/store/overrides/selectors'

const ExitBtn = withAlertModal(({ showAlertModal, isSSO }) =>
  isSSO ? (
    <UnderlinedButton id="exitBtn" onClick={showAlertModal}>Cancel Payment</UnderlinedButton>
  ) : (
    <Button id="exitBtn" outline primary onClick={showAlertModal}>
      Exit &amp; Cancel
    </Button>
  )
)

type Props = {
  match: { url: string, path: string, params: { residentId: string } },
  details: Object,
  balance: number,
  flows: Flow[],
  startFlow: number,
  recipient: Object,
  fullName: string,
  isPayPal: boolean,
  saveDetails: Object => mixed,
  history: { push: string => mixed },
  location: { pathname: string },
  expressFee: string,
  isFetching: boolean,
  paymentState: boolean,
  hasPaymentMethod: boolean,
  isFetchingPayPalPaymentMethod: boolean,
  createOrderErrorMsg: Function,
  payPalPayLoad: Function,
  getPayPalPaymentMethod: Function,
  isLastPaymentProcessing: boolean,
  hasAutopays: boolean,
  showMessageWithTooltip: Function,
  fraudToolkitError: boolean,
  itemForReauth: Object | null
}

export const getInitiatePayPal =
  ({
    saveDetails,
    createOrder,
    payPalPayLoad,
    createOrderErrorMsg,
    details
  }: {
    saveDetails: Function,
    createOrder: Function,
    payPalPayLoad: Object,
    createOrderErrorMsg: Function,
    details: Object
  }) =>
  () => {
    saveDetails(details)
    return createOrder(payPalPayLoad, createOrderErrorMsg)
  }

export const getOnApprove =
  (getPayPalPaymentMethod: Function, getPayPalPaymentMethodSuccess: Function) =>
  (data: Object) => {
    getPayPalPaymentMethod(data.orderID, getPayPalPaymentMethodSuccess)
  }

export const getToggleHideBtns =
  (setHideBtns: Function, hideBtns: boolean) => () => {
    setHideBtns(!hideBtns)
  }

export const getHandleChange =
  (saveDetails: Function, details: Object) =>
  (name: string) =>
  (value: any) => {
    saveDetails({ ...details, [name]: value })
  }

const OneTimePayment = ({
  match: {
    url,
    path,
    params: { residentId }
  },
  history: { push },
  location,
  details,
  balance,
  flows,
  isPayPal,
  startFlow,
  saveDetails,
  expressFee,
  isFetching,
  paymentState,
  createOrderErrorMsg,
  hasPaymentMethod,
  getPayPalPaymentMethod,
  payPalPayLoad,
  isFetchingPayPalPaymentMethod,
  hasAutopays,
  showMessageWithTooltip,
  fraudToolkitError,
  itemForReauth
}: Props) => {
  const [hideBtns, setHideBtns] = useState(false)
  const [showReauth, setShowReauth] = useState(false)
  const [flow, { gotoNextFlow }] = usePaymentFlow()
  const [{ method, selectedPaymentMethod }, setSelectedPaymentMethod] =
    useSelectedMethod(setHideBtns, gotoNextFlow)
  const handleChange = getHandleChange(saveDetails, details)
  const { nextFlow, progress, name: flowName } = flows[flow]
  const isSummaryNext = !Boolean(nextFlow)
  const gotoPaymentSummary = () => push(`${url}/summary`)
  const toggleHideBtns = getToggleHideBtns(setHideBtns, hideBtns)
  const onApprove = getOnApprove(getPayPalPaymentMethod, gotoPaymentSummary)

  const cancelOption = useSelector(getCancelOptionBehaviorOverride)
  const onCancelClick = () => {
    if (cancelOption) {
      window.location.replace(cancelOption)
    } else {
      push('/payments/summary')
    }
  }

  const initiatePayPal = getInitiatePayPal({
    saveDetails,
    createOrder,
    details,
    payPalPayLoad,
    createOrderErrorMsg
  })
  const dispatch = useDispatch()
  const theme = useTheme()
  const { isSSO } = theme.ux
  const otpPaymentMethodSelectRoute = `/payments/${residentId}/one-time?nextflow=1`
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }
  const query = useQuery()
  const nextFlowParam = query.get('nextflow')
  const showPaymentNotesBtn = isSSO && flow === 0

  const reauthIsRequired = () => {
    return (
      itemForReauth && ['lastPaymentMethod', 'useExpressPay'].includes(flowName)
    )
  }

  const onContinueClicked = () => {
    if (reauthIsRequired()) {
      setShowReauth(true)
    } else if (isSummaryNext) {
      saveDetails(details)
      gotoPaymentSummary()
    } else {
      if (nextFlow === 2) {
        saveDetails(details)
      }

      gotoNextFlow(nextFlow)
    }
  }

  const onSelectPayPal = () => {
    saveDetails(details)
    if (isSummaryNext) {
      return
    }
    return gotoNextFlow(nextFlow)
  }

  const disableContinue = () => {
    if (isFetchingPayPalPaymentMethod) return true
    if (flow === 1) {
      return hasPaymentMethod ? false : true
    }
    return balance > 0 ? false : true
  }

  const onIconClick = nextFlow => {
    // Clear selectedPaymentMethod from state if exists so form will not
    // be automatically rendered
    if (selectedPaymentMethod?.methodType) {
      setSelectedPaymentMethod({})
    }
    gotoNextFlow(nextFlow)
  }

  const isPaymentStateProcessing = paymentState === paymentStatuses.PROCESSING

  useEffect(() => {
    if (isPaymentStateProcessing && !hasAutopays) {
      showMessageWithTooltip([paymentScheduledMessage])
    } else if (!isPaymentStateProcessing && hasAutopays) {
      showMessageWithTooltip([activeAutopayMessage])
    } else if (isPaymentStateProcessing && hasAutopays) {
      showMessageWithTooltip([activeAutopayMessage, paymentScheduledMessage])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentStateProcessing, hasAutopays])

  useEffect(() => {
    if (!selectedPaymentMethod?.methodType) {
      hideBtns && setHideBtns(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow, details])

  useEffect(() => {
    gotoNextFlow(startFlow)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])

  useEffect(() => {
    details.lastPaymentMethod && saveDetails(details)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.lastPaymentMethod])

  useEffect(() => {
    if (nextFlowParam) {
      gotoNextFlow(parseInt(nextFlowParam))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextFlowParam])

  useEffect(() => {
    const isSummaryPage = location.pathname.includes('summary')
    if (!showReauth) {
      setShowReauth(!!itemForReauth && isSummaryPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemForReauth, location.pathname])

  if (fraudToolkitError) {
    dispatch(makeFraudToolkitError(false))
    return <Redirect to={otpPaymentMethodSelectRoute} />
  }

  const Btns = (
    <React.Fragment>
      {showPaymentNotesBtn && (
        <Flex flex={1} justifyLeft alignCenter>
          <PaymentNotesBtn />
        </Flex>
      )}
      <ExitBtn
        alertTitle="Are you sure you wish to exit and cancel this payment?"
        onAlertClickYes={onCancelClick}
        isSSO={isSSO}
      />
      {isPayPal && !nextFlow && !isFetchingPayPalPaymentMethod ? (
        <PayPalButtonWrap>
          <LaunchPayPalButtonBtn>
            <PayPalButton
              id="continueToPayPalBtn"
              createOrder={initiatePayPal}
              onApprove={onApprove}
              disabled={disableContinue()}>
              Continue to PayPal
            </PayPalButton>
          </LaunchPayPalButtonBtn>
        </PayPalButtonWrap>
      ) : (
        <Button
          id="continueBtn"
          primary
          onClick={onContinueClicked}
          disabled={disableContinue()}
          animate={isFetchingPayPalPaymentMethod}>
          {isFetchingPayPalPaymentMethod ? 'Processing' : 'Continue'}
        </Button>
      )}
    </React.Fragment>
  )

  return paymentState === paymentStatuses.BLOCKED ? (
    <PaymentsBlocked />
  ) : (
    <Switch>
      <Route
        exact
        path={path}
        render={() => (
          <React.Fragment>
            <Layout progress={progress} isFetching={isFetching}>
              <PaymentFlow
                flows={flows}
                flow={flow}
                details={details}
                handleDetailsChange={handleChange}
                onIconClick={onIconClick}
                onAddPaymentMethodChange={toggleHideBtns}
                setHideBtns={setHideBtns}
                onContinueClicked={onContinueClicked}
                onSelectPayPal={onSelectPayPal}
                expressFee={expressFee}
                selectedPaymentMethod={method}
                setMethod={setSelectedPaymentMethod}
                createPayPalOrder={initiatePayPal}
                onPayPalApprove={onApprove}
              />
              {!hideBtns && (
                <Hidden smDown>
                  <ButtonWrapperStyled>{Btns}</ButtonWrapperStyled>
                </Hidden>
              )}
              {showReauth && (
                <ReauthFlowModal
                  plaidItem={itemForReauth}
                  onClose={() => setShowReauth(false)}
                />
              )}
            </Layout>
            {!isFetching && !hideBtns && (
              <MobileBtnsPortal>
                <StyledMobileButtons>{Btns}</StyledMobileButtons>
              </MobileBtnsPortal>
            )}
          </React.Fragment>
        )}
      />
      <Route
        exact
        key="summary"
        path={`${path}/summary`}
        render={routeProps => (
          <React.Fragment>
            <Summary {...routeProps} />
            {showReauth && (
              <ReauthFlowModal
                plaidItem={itemForReauth}
                onClose={() => setShowReauth(false)}
              />
            )}
          </React.Fragment>
        )}
      />
      <Route
        exact
        key="one-time-payment-success"
        path={`${path}/success`}
        component={PaymentSuccess}
      />
    </Switch>
  )
}

export default OneTimePayment
