// @flow

import React from 'react'
import { DialogActions, DialogContent } from '@material-ui/core'
import Button from '../Button'
import {
  DialogStyled,
  ButtonsWrapper,
  DialogTitleStyled,
  StyledDialogContentText
} from './styles'

type Props = {
  show: boolean,
  onClose: Function,
  title: string,
  message: Function,
  button: [{ onClick: Function, text: string }]
}

class AlertModal extends React.Component<Props, {}> {
  render() {
    const { show, onClose, title, message, button } = this.props

    return (
      <DialogStyled
        classes={{
          paper: 'dialog-paper'
        }}
        open={show}
        onClose={onClose}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitleStyled id="alert-dialog-title" isTerms>
          {title}
        </DialogTitleStyled>
        {message && (
          <DialogContent>
            <StyledDialogContentText
              isTerms
              id="alert-dialog-description">
              {message()}
            </StyledDialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <ButtonsWrapper fullWidth justifyCenter isTerms>
            <Button id="acceptTermsBtn" {...button[0]} isTerms>
              {button[0].text}
            </Button>
          </ButtonsWrapper>
        </DialogActions>
      </DialogStyled>
    )
  }
}

export default AlertModal
