import axios from 'axios'
import { createHTTPHeader } from './utils'
import { isValidToken } from '../auth'
import {
  getToken,
  getPaymentToken,
  getRefreshToken
} from '../../store/authorization/selectors'
import { getTokenByRefresh } from '../../store/authorization/actions'
import { getBlockedFetch } from '../../store/isFetching/selectors'

let authTokenRequest

const zegoApiURL = window._env_.REACT_APP_API_URL

const isZegoAPICall = url => url.indexOf(zegoApiURL) === 0

const getTokenByRefreshPromise = (refreshToken, dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(getTokenByRefresh(refreshToken, resolve, reject))
  })
}

const resetAuthTokenRequest = () => {
  authTokenRequest = null
}

const getAuthToken = (store, error) => {
  if (!authTokenRequest) {
    const refreshToken = getRefreshToken(store.getState())

    if (isValidToken(refreshToken)) {
      authTokenRequest = getTokenByRefreshPromise(refreshToken, store.dispatch)
      authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest)
    } else {
      return Promise.reject(error)
    }
  }
  return authTokenRequest
}

const isReqBlocked = store => {
  const state = store.getState()
  return getBlockedFetch(state)
}

export const setupAxios = store => {
  axios.interceptors.response.use(
    response => {
      const isBlocked = isReqBlocked(store)
      if (isBlocked) {
        throw new Error('Response blocked')
      }
      return response
    },
    async error => {
      const originalConfig = error.config
      const response = error.response

      if (response && response.status === 401 && !originalConfig._retry) {
        return getAuthToken(store, error).then(() => {
          const state = store.getState()
          const token = getToken(state)
          const paymentToken = getPaymentToken(state)
          const headers = isZegoAPICall(originalConfig.url)
            ? createHTTPHeader(token)
            : createHTTPHeader(paymentToken, null, true)
          Object.entries(headers).forEach(
            ([k, v]) => (originalConfig.headers[k] = v)
          )
          originalConfig._retry = true
          return axios(originalConfig)
        })
      }
      return Promise.reject(error)
    }
  )

  axios.interceptors.request.use(config => {
    const isBlocked = isReqBlocked(store)

    if (isBlocked) {
      config.timeout = 1
    }

    return config
  })
}
