// @flow

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Switches from './Switch'
import { SwitchText, SwitchTextWrap, SwitchWrap } from './styles'

type Props = {
  classes: Object,
  isOptedIn: boolean,
  switchedOnText: string,
  switchedOffText: string,
  handleChange: Function,
  elementId?: string
}

const CustomSwitch = withStyles(theme => ({
  root: {
    width: 110,
    height: 25,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'visible'
  },
  switchBase: {
    padding: 1,
    left: '-2px',
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.secondary.light,
      width: 166,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none'
      }
    },
    '&$checked:hover': {
      boxShadow: 'none',
      backgroundColor: 'transparent'
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 27,
    height: 25,
    marginTop: -1
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.neutral.dark,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {
    width: 110
  },
  focusVisible: {}
}))(
  ({
    classes,
    isOptedIn,
    switchedOnText,
    switchedOffText,
    handleChange,
    elementId,
    ...props
  }: Props) => {
    return (
      <SwitchWrap direction="column" justifyCenter>
        <SwitchTextWrap>
          <Switches
            {...props}
            elementId={elementId}
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            checked={isOptedIn}
            handleChange={handleChange}
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked
            }}
          />
          <SwitchText checked={isOptedIn}>
            {isOptedIn ? switchedOnText : switchedOffText}
          </SwitchText>
        </SwitchTextWrap>
      </SwitchWrap>
    )
  }
)

export default CustomSwitch
