// @flow

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import { getSelectedCompany } from 'zego-shared/store/select/selectors'
import { getUserRole } from 'zego-shared/store/authorization/selectors'
import {
  getCompanies,
  checkSuperAdmin
} from 'zego-shared/store/select/companies/selectors'
import { getProfileUrlOrDefault } from 'zego-shared/utils/profilePicture'

const emptyObj = {}

export const getIsPropertyReadyToRender = (state: Object) => {
  return get(state, 'select.properties.isReady')
}

export const getSelectedProperty = (state: Object) =>
  get(state, 'select.properties.selected', emptyObj)

export const isPropertySelected = (state: Object) =>
  !isEmpty(getSelectedProperty(state))

export const getPropertyId = (state: Object) =>
  get(state, 'select.properties.selected.id', -1)

export const getPropertyName = (state: Object) =>
  get(state, 'select.properties.selected.name', 'unknown property')

export const getCompanyId = (state: Object) =>
  get(state, 'select.properties.selected.company_id', -1)

export const getCompanyName = (state: Object) =>
  get(state, 'select.properties.selected.company_name', 'Unnamed Company')

export const getAllProperties = (state: Object) =>
  Object.values(get(state, 'select.properties.byId', {}))

export const getProperties = (state: Object) => {
  const selectedCompany = getSelectedCompany(state)
  const allProperties = getAllProperties(state)
  const companies = getCompanies(state)
  const isSuperAdmin = checkSuperAdmin(getUserRole(state))

  return isSuperAdmin
    ? selectedCompany && selectedCompany.id
      ? sortBy(companies.byId[selectedCompany.id].properties, [
          company => company.name.toLowerCase()
        ])
      : []
    : sortBy(allProperties, [property => property.name.toLowerCase()])
}

export const staffToStaffData = (data: Object[]) => {
  return data.map<Object>(staff => {
    return {
      name: staff.name,
      picture: getProfileUrlOrDefault(staff.picture, staff.name),
      role: staff.title || ''
    }
  })
}

export const getPropertyStaff = (state: Object) => {
  const data = get(state, 'select.properties.propertyStaff', [])

  return staffToStaffData(data)
}

export const getOfficeAddress = (state: Object) =>
  get(state, 'select.properties.propertyInfo.address', '')

export const getOfficeAddressLineOne = (state: Object) =>
  get(state, 'select.properties.propertyInfo.office_address_line_1', '')

export const getOfficeAddressLineTwo = (state: Object) =>
  get(state, 'select.properties.propertyInfo.office_address_line_2', '')

export const getOfficeAddressState = (state: Object) =>
  get(state, 'select.properties.propertyInfo.office_address_state', '')

export const getOfficeAddressCity = (state: Object) =>
  get(state, 'select.properties.propertyInfo.office_address_city', '')

export const getOfficeAddressZip = (state: Object) =>
  get(state, 'select.properties.propertyInfo.office_address_zip', '')

export const getOfficeHours = (state: Object) =>
  get(state, 'select.properties.propertyInfo.office_hours', '')

export const getPhoneNumber = (state: Object) =>
  get(state, 'select.properties.propertyInfo.phone_number', '')

export const getPMS = (state: Object) =>
  get(state, 'select.properties.propertyInfo.pms', '')

export const getPropertyAddress = (state: Object) =>
  get(state, 'select.properties.propertyAddress.address', '')
