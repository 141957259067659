import { call, put, takeLatest, select, delay } from 'redux-saga/effects'
import objectToFormData from 'object-to-formdata'

import {
  getToken,
  getUnitId,
  getUserId
} from '../../../authorization/selectors'
import { workOrderApiUrl } from '../../../settings/selectors'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import { showSnackbar, hideSnackbar } from '../../../snackbar/actions'
import {
  failedToSubmitWorkOrder,
  genericErrorMsg,
  submitWorkOrderSuccess
} from '../../../../utils/messages'
import {
  SUBMIT_WORK_ORDER,
  fetchWorkOrderOptions,
  fetchWorkOrderOptionsStart,
  fetchWorkOrderOptionsSuccess,
  fetchWorkOrderOptionsError,
  submitWorkOrderStart,
  submitWorkOrderEnd
} from '../actions'

export function* getWorkOrderOptions() {
  try {
    const authToken = yield select(getToken)

    yield put(fetchWorkOrderOptionsStart())

    const unitId = yield select(getUnitId)
    const apiUrl = yield select(workOrderApiUrl)
    const result = yield call(api.getWorkOrderOptions, apiUrl, unitId.id, authToken)

    yield put(fetchWorkOrderOptionsSuccess(result))
  } catch (error) {
    log(error)
    yield put(fetchWorkOrderOptionsError(error))
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* submitWorkOrder(action) {
  try {
    yield put(submitWorkOrderStart)
    const authToken = yield select(getToken)
    const unitId = yield select(getUnitId)
    const apiUrl = yield select(workOrderApiUrl)

    const { message, options, file, history } = action

    const userId = yield select(getUserId)

    const pms_options = Object.entries(options || {}).map(([k, v]) => ({
      name: k,
      id: v.toString()
    }))

    const payload = {
      user_id: userId,
      message,
      pms_options
    }

    const { id } = yield call(api.submitWorkOrder, apiUrl, unitId.id, authToken, payload)

    if (file) {
      for(const photo of file) {
        const data = yield call(objectToFormData, { file: photo })
        yield call(api.addFileToWorkOrder, apiUrl, unitId.id, authToken, id, data)
      }
    }

    yield put(showSnackbar(submitWorkOrderSuccess, 'success'))
    yield delay(500)
    yield put(hideSnackbar())
    yield history.push(`/units/${unitId}/work-orders/history`)
    yield put(submitWorkOrderEnd)
  } catch (error) {
    log(error)
    yield put(showSnackbar(failedToSubmitWorkOrder, 'error'))
    yield put(submitWorkOrderEnd)
  }
}

export function* watchFetchWorkOrderOptions() {
  yield takeLatest(fetchWorkOrderOptions().type, getWorkOrderOptions)
}

export function* watchSubmitWorkOrder() {
  yield takeLatest(SUBMIT_WORK_ORDER, submitWorkOrder)
}

export default [watchFetchWorkOrderOptions(), watchSubmitWorkOrder()]
