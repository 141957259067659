import { connect } from 'react-redux'
import Summary from './Summary'
import {
  getEmergencyPhone,
  getPropertyPhone
} from 'zego-shared/store/select/selectors'
import { fetchWorkOrderHistoryAction } from 'zego-shared/store/workOrders/actions'
import { getUnitId } from 'zego-shared/store/select/selectors'
import { getOpenWorkOrders } from 'zego-shared/store/workOrders/selectors'
import { withFetchData } from 'zego-shared/views'

export const mapStateToProps = state => ({
  emergencyPhone: getEmergencyPhone(state),
  propertyPhone: getPropertyPhone(state),
  workOrders: getOpenWorkOrders(state),
  unitId : getUnitId(state)
})

const mapDispatchToProps = { fetchData: fetchWorkOrderHistoryAction }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(Summary))
