import { genericErrorMsg } from './messages'

export const getGraphQlData = (response, methodName) => {
  const { errors, data } = response
  if (errors) {
    let error
    if (Array.isArray(errors) && errors[0] && errors[0].message) {
      error = errors[0]
    } else {
      error = new Error(genericErrorMsg)
    }
    throw error
  }
  return data[methodName]
}
