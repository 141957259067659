import MarketplaceDetails from './MarketplaceDetails'
import { connect } from 'react-redux'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { getMarketplaceDeals, getMarketplaceCard } from 'zego-shared/store/marketplace/selectors'
import {
  fetchMarketplaceDeals
} from 'zego-shared/store/marketplace/actions'

const fetchingSelector = createIsFetchingSelector(['marketplace_card'])

const MarketplaceDetailsWithFetchData = withFetchData(MarketplaceDetails)

export const mapStateToProps = (
  state,
  { match: { params: { id } } }
) => ({
  dealId: id, 
  deals: getMarketplaceDeals(state),
  deal: getMarketplaceCard(state, id),
  isFetching: fetchingSelector(state)
})
export const mapDispatchToProps = (dispatch) => ({
  fetchData: () => {
    dispatch(fetchMarketplaceDeals())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceDetailsWithFetchData)
