// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'

export const Deactivated = styled(Flex)`
  height: 400px;

  & a {
    text-decoration: underline;
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: 100%;
    padding: 0px 30px;
  }

  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    height: 410px;
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    height: 450px;
  }
`

export const AccountDeactivatedTitle = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H3};
  color: ${({ theme }) => theme.palette.common.midnight};
  text-align: center;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
  }
`

export const AccountDeactivatedSubTitle = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  color: ${({ theme }) => theme.palette.common.grey};
  text-align: center;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`

export const ReRegistrationInfo = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.common.grey};
  margin-top: ${({ theme }) => theme.space.s20};
  width: 440px;
  text-align: center;
`

export const SupportWrap = styled('div')`
  width: 263px;
  height: 100px;
  text-align: center;
`

export const SupportInfo = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.common.grey};
`

export const NeedHelp = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  color: ${({ theme }) => theme.palette.common.midnight};
  margin-bottom: ${({ theme }) => theme.space.s10};
`

export const SSOContainer = styled(Flex)`
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding-bottom: 15%;
`
