import {
  createFetchActions,
  createUpdateActions
} from '../../../helpers/actions/creators'

export const PAYMENT_REMINDERS_DATA = 'PAYMENT_REMINDERS_DATA'
export const PAYMENT_REMINDERS = 'PAYMENT_REMINDERS'

export const {
  fetchAction: fetchRemindersAction,
  fetchStartAction: fetchRemindersStart,
  fetchSuccessAction: fetchRemindersSuccess,
  fetchErrorAction: fetchRemindersError
} = createFetchActions(PAYMENT_REMINDERS_DATA)

export const {
  updateAction: updateRemindersAction,
  updateStartAction: updateRemindersStart,
  updateSuccessAction: updateRemindersSuccess,
  updateErrorAction: updateRemindersError
} = createUpdateActions(PAYMENT_REMINDERS)

export const getReminders = () => fetchRemindersAction()
export const updateReminders = (payload) => {
  return updateRemindersAction({ payload })
}
