// @flow
import React from 'react'
import { withRouter } from 'react-router-dom'

import { StyledLink } from './styles'

type Props = {
  match: Object,
  id: string
}
const ViewLink = ({ match: { url }, id }: Props) => {
  return <StyledLink to={`${url}/${id}`}>view</StyledLink>
}

export default withRouter(ViewLink)
