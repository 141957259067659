// @flow
import styled from '@emotion/styled'

import { StyledTableBodyCell } from '@Common/ListFull'

export const TitleStyled = styled.h3`
  height: 43px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H3};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  line-height: 43px;
  margin: 0;
  margin-bottom: 13px;
`

export const TableBodyCellStyled = styled(StyledTableBodyCell)`
  &:last-of-type {
    padding-right: 0;
  }

  ${({ align }) => align && `text-align: ${align};`}
`

export const LinkStyled = styled.a`
  text-decoration: underline;
  color: ${({ theme, color }) => color || theme.palette.secondary.main};
  cursor: pointer;
`
