// @flow
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import Flex from '@Common/Flex'
import { StyledLink } from '../styles'
import {
  getCreditReportingConfigDetails,
  hasPurchasedLookback
} from 'zego-shared/store/payments/creditReporting/selectors'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import {
  Container,
  Header,
  DetailsSection,
  Avatar,
  Street,
  GreyText,
  CheckMark,
  MonthLane,
  Month,
  Tracker,
  TrackerDots,
  TrackerCheckMark,
  Year,
  MoreHistoryLink,
  MonthsContainer
} from './styles'
import MonthTrackerModal from './MonthTrackerModal'

type Props = {
  data: {
    transactions: Object,
    years: Array<number>,
    monthsReported: number,
    lookbackMonthsReported: number
  }
}

type Details = {
  address: string,
  city: string,
  state: string,
  postalCode: string
}

export const getDotOrCheckMark = (months: Array<Object>): React.Node =>
  months.map(month =>
    month.isLC || month.isLB ? (
      <TrackerCheckMark blue={month.isLB} className="check-mark" />
    ) : (
      <TrackerDots className="tracker-dots" />
    )
  )

const MonthTracker = ({ data }: Props) => {
  const [showModal, setShowModal] = useState(false)

  const details: Details = useSelector(getCreditReportingConfigDetails).address
  const residentId: string = useSelector(getUserId)
  const hasLookback: boolean = useSelector(hasPurchasedLookback)
  const { address, city, state, postalCode } = details
  const { transactions, years } = data

  const isSSO = useTheme().ux.isSSO
  const slicedYears = isSSO ? years.slice(-2) : years
  const currentYear = new Date().getFullYear()
  const toggleModal = () => setShowModal(!showModal)

  return (
    <Container direction="column">
      <Header>
        <DetailsSection>
          <Avatar />
          <Flex direction="column">
            <Street id="street">{address}</Street>
            <GreyText>
              <span id="city">{city}, </span>
              <span id="state">{state}</span>{' '}
              <span id="postal-code">{postalCode}</span>
            </GreyText>
            <StyledLink
              to={`/payments/${residentId}/credit-reporting/my-details`}>
              view my details
            </StyledLink>
          </Flex>
        </DetailsSection>
        <Flex direction="column">
          {hasLookback && (
            <Flex justifyRight>
              <GreyText marginRight="7px">lookback reported</GreyText>
              <CheckMark blue />
            </Flex>
          )}
          <Flex justifyRight>
            <GreyText marginRight="7px">month reported</GreyText>
            <CheckMark />
          </Flex>
        </Flex>
      </Header>
      <MonthLane>
        <Month>J</Month>
        <Month>F</Month>
        <Month>M</Month>
        <Month>A</Month>
        <Month>M</Month>
        <Month>J</Month>
        <Month>J</Month>
        <Month>A</Month>
        <Month>S</Month>
        <Month>O</Month>
        <Month>N</Month>
        <Month>D</Month>
        <Year />
      </MonthLane>
      {slicedYears.length < 1 ? (
        <Tracker>
          <MonthsContainer>{getDotOrCheckMark(transactions)}</MonthsContainer>
          <Year id="year">{currentYear}</Year>
        </Tracker>
      ) : (
        slicedYears.map(year => {
          return (
            <Tracker>
              <MonthsContainer>
                {getDotOrCheckMark(transactions[year])}
              </MonthsContainer>
              <Year id="year">{year}</Year>
            </Tracker>
          )
        })
      )}

      {years.length > slicedYears.length && (
        <>
          <MoreHistoryLink onClick={toggleModal}>more history</MoreHistoryLink>
          <MonthTrackerModal
            data={data}
            show={showModal}
            onClose={toggleModal}
          />
        </>
      )}
    </Container>
  )
}

export default MonthTracker
