import { connect } from 'react-redux'
import queryString from 'query-string'
import type { BrowserRouter } from 'react-router-dom'
import { signUp } from 'zego-shared/store/authorization/actions'
import { getIsSigningUp } from 'zego-shared/store/authorization/selectors'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { getLatestTAndC } from 'zego-shared/store/termsAndConditions/selectors'
import { withFetchData } from 'zego-shared/views'
import { fetchTermsAndConditionsAction } from 'zego-shared/store/termsAndConditions/actions'
import PasswordSetup from './PasswordSetup'

const mapStateToProps = (state: Object, props) => {
  return {
    termsAndConditions: getLatestTAndC(state),
    isSigningUp: getIsSigningUp(state)
  }
}

const mapDispatchToProps = (
  dispatch: Object => mixed,
  props: BrowserRouter
) => {
  const { history, location = {} } = props
  const {
    token,
    first_name: firstName,
    last_name: lastName,
    email,
    phone
  } = queryString.parse(location.search)
  const payload = { firstName, lastName, email, phone }
  return {
    showError: (error: string) => dispatch(showSnackbar(error, 'error')),
    signUp: (password: string, isMobileDevice: boolean, id: number) => {
      dispatch(
        signUp(
          token,
          { password, id, ...payload },
          null,
          history,
          isMobileDevice
        )
      )
    },
    fetchData: () => dispatch(fetchTermsAndConditionsAction())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(PasswordSetup))
