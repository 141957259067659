import { call, put, takeLatest, select } from 'redux-saga/effects'
import { print } from 'graphql'
import { isEmpty } from 'lodash'
import {
  fetchPaymentOptionFeesAction,
  fetchPaymentOptionFeesSuccess,
  fetchPaymentOptionFeesError,
  fetchPaymentOptionFeesStart,
  FEE_TYPE
} from '../actions'
import api from '../../../../utils/api'
import { getGraphQlData } from '../../../../utils/graphql'
import { getPaymentToken } from '../../../authorization/selectors'
import * as queries from '../graphql/queries'
import { getPaymentOptions } from '../../options/selectors'
import { showSnackbar } from '../../../snackbar/actions'
import { 
  failedToFetchPaymentOptionsFees, 
  unavailablePaymentOptions, 
  unavailablePaymentService 
} from '../../../../utils/messages'

export function* fetchFixedAutoPayFeesSaga({ tokens, options, paymentFields }) {
  const response = yield call(
    api.graphqlQuery,
    tokens,
    print(queries.getFixedAutoPayFees),
    {
      paymentFields,
      ...options
    }
  )
  const payload = yield call(getGraphQlData, response, 'getFixedAutopayFees')
  yield put(fetchPaymentOptionFeesSuccess(payload))
}

export function* fetchVarAutoPayFeesSaga({ tokens, options }) {
  const response = yield call(
    api.graphqlQuery,
    tokens,
    print(queries.getVarAutoPayFees),
    options
  )
  const payload = yield call(getGraphQlData, response, 'getVariableAutopayFees')
  yield put(fetchPaymentOptionFeesSuccess(payload))
}

export function* fetchPaymentFeesSaga({
  tokens,
  options,
  paymentFields,
  residentId
}) {
  const response = yield call(
    api.graphqlQuery,
    tokens,
    print(queries.getFees),
    {
      residentId,
      paymentFields,
      ...options
    }
  )
  const payload = yield call(getGraphQlData, response, 'getFees')
  yield put(fetchPaymentOptionFeesSuccess(payload))
}

const getFetchSaga = feeType => {
  const sagas = {
    [FEE_TYPE.NORMAL]: fetchPaymentFeesSaga,
    [FEE_TYPE.VARIABLE]: fetchVarAutoPayFeesSaga,
    [FEE_TYPE.FIXED]: fetchFixedAutoPayFeesSaga
  }
  return sagas[feeType] || sagas[FEE_TYPE.NORMAL]
}

export function* fetchPaymentOptionFeesSaga({
  residentId,
  paymentFields,
  feeType
}) {
  try {
    yield put(fetchPaymentOptionFeesStart())
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const options = yield select(getPaymentOptions)

    // stop fetching fees if no payment options
    const emptyPaymentOptions = yield call(isEmpty, options)
    if(emptyPaymentOptions){
      throw new Error(unavailablePaymentOptions)
    }

    const fetchSaga = getFetchSaga(feeType)
    yield call(fetchSaga, { tokens, options, residentId, paymentFields })
  } catch (error) {
    yield put(fetchPaymentOptionFeesError(error))

    if(error.message === unavailablePaymentOptions) {
      yield put(showSnackbar(unavailablePaymentService, 'error'))
      return
    }
    
    yield put(showSnackbar(failedToFetchPaymentOptionsFees, 'error'))
    
  }
}

function* watchFetchPaymentOptionFees() {
  yield takeLatest(
    fetchPaymentOptionFeesAction().type,
    fetchPaymentOptionFeesSaga
  )
}

export default [watchFetchPaymentOptionFees()]
