// @flow
import React from 'react'
import { StyledTooltip, HelpIcon } from './styles'

type Props = {
  title: string,
  size?: string
}

export default ({ title, size = '18px' }: Props) => (
  <StyledTooltip placement="right-start" title={title}>
    <HelpIcon size={size} />
  </StyledTooltip>
)
