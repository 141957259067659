// @flow

import { connect } from 'react-redux'
import queryString from 'query-string'
import {
  validateEmailToken,
  updateResidentBillingPref,
  verifyResidentPin
} from 'zego-shared/store/billing/deliveryPreference/actions'
import { getDeliveryProfile } from 'zego-shared/store/billing/deliveryPreference/selectors'
import { ldInitRequest } from 'zego-shared/store/integrations/launchDarkly/actions'
import { getFeatureFlag } from 'zego-shared/store/integrations/launchDarkly/selectors'
import FeatureFlags from '../../Common/FeatureFlag/FeatureFlags'
import ChangeDeliveryTypeV2 from './ChangeDeliveryTypeV2'
import ChangeDeliveryType from './ChangeDeliveryType'

const mapStateToProps = (state, { location: { search } }) => {
  const { token, verify, pin } = queryString.parse(search)

  return {
    ...getDeliveryProfile(state),
    error: state.snackbar.message,
    linkToken: token,
    linkVerify: verify,
    linkPin: pin
  }
}

const mapDispatchToProps = { validateEmailToken, updateResidentBillingPref, verifyResidentPin }

var changeDeliveryType = connect(mapStateToProps, mapDispatchToProps)(ChangeDeliveryType)
var changeDeliveryTypeV2 = connect(mapStateToProps, mapDispatchToProps)(ChangeDeliveryTypeV2)

export default connect((state) => ({
  featureFlag: getFeatureFlag(state),
  flagName: 'utility-ebilling-enrollment-pref',
  flagValues: { false: changeDeliveryType, true: changeDeliveryTypeV2 }
}), { ldInitRequest })(FeatureFlags)
