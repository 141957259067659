// @flow
import styled from '@emotion/styled'
import { ListItem } from '@material-ui/core'
import Flex from '@Common/Flex'

export const MethodsStyled = styled(Flex)`
  max-width: 406px;
  width: 100%;
  margin: 0;
  height: 100%;

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    ${({ theme }) => 
      theme.ux.isSSO && 
      `
      width: 90%;
      `
    }
  }
`

export const StyledListItem = styled(ListItem)`
  min-height: ${({ theme }) => theme.ux.isSSO ? `66px` : `84px`};
`

export const NameStyled = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  display: inline-block;
  height: 16px;
  line-height: 19px;
`

export const ListItemStyled = styled(ListItem)`
  min-height: fit-content;
`
