import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  fetchPaymentHistoryAction,
  fetchPaymentHistorySuccess,
  fetchPaymentHistoryError,
  fetchPaymentHistoryStart,
  VOID_PAYMENT,
  voidPaymentSuccess,
  voidPaymentError,
  requestPaymentReceipt,
  requestPaymentReceiptSuccess,
  requestPaymentReceiptError,
  requestPaymentReceiptStart
} from '../actions'
import api from '../../../../utils/api'
import { getGraphQlData } from '../../../../utils/graphql'
import {
  getPaymentToken,
  getToken,
  getUserId
} from '../../../authorization/selectors'
import {
  getHistoryQuery,
  getHistoryVariables,
  HISTORY_QUERY_NAME
} from '../graphql/queries'
import {
  voidPaymentMutation,
  requestPaymentReceipt as requestReceiptMutation
} from '../graphql/mutations'
import { showSnackbar } from '../../../snackbar/actions'
import {
  failedToFetchPaymentHistory,
  failedToVoidPayment
} from '../../../../utils/messages'
import { updateUserSuccess } from '../../../authorization/actions'

export function* fetchPaymentHistory({ cursor }) {
  try {
    yield put(fetchPaymentHistoryStart())
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = getHistoryQuery()
    const variables = getHistoryVariables(cursor)

    const response = yield call(api.graphqlQuery, tokens, query, variables)
    const payload = yield call(getGraphQlData, response, HISTORY_QUERY_NAME)
    yield put(
      fetchPaymentHistorySuccess({
        ...payload,
        cursor
      })
    )
  } catch (error) {
    yield put(showSnackbar(failedToFetchPaymentHistory(error), 'error'))
    yield put(fetchPaymentHistoryError(error))
  }
}

export function* voidPayment({ id }) {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }

    const variables = { input: { id, post: { transId: id } } }
    const response = yield call(
      api.graphqlQuery,
      tokens,
      voidPaymentMutation,
      variables
    )
    const getResponse = yield call(getGraphQlData, response, 'voidPayment')

    yield put(voidPaymentSuccess(id))
    yield put(showSnackbar(getResponse.message, 'success'))
  } catch (error) {
    yield put(showSnackbar(failedToVoidPayment, 'error'))
    yield put(voidPaymentError(id))
  }
}

export function* requestReceipt({ email, saveEmail, transactionId }) {
  try {
    const paymentToken = yield select(getPaymentToken)
    const userId = yield select(getUserId)
    const authToken = yield select(getToken)

    yield put(requestPaymentReceiptStart())

    const tokens = { paymentToken }
    const variables = { input: { email, transactionId } }

    // Send receipt request
    yield call(api.graphqlQuery, tokens, requestReceiptMutation, variables)

    // Save notification email
    if (saveEmail) {
      const update = yield call(api.updateUser, userId, { email }, authToken)
      yield put(updateUserSuccess({ email: update.email }))
    }

    yield put(requestPaymentReceiptSuccess())
    yield put(showSnackbar('Receipt request was successful!'))
  } catch (error) {
    yield put(showSnackbar('Receipt request failed', 'error'))
    yield put(requestPaymentReceiptError(error))
  }
}

function* watchFetchHistory() {
  yield takeLatest(fetchPaymentHistoryAction().type, fetchPaymentHistory)
}

function* watchVoidPayment() {
  yield takeLatest(VOID_PAYMENT, voidPayment)
}

function* watchRequestPaymentReceipt() {
  yield takeLatest(requestPaymentReceipt().type, requestReceipt)
}

export default [
  watchFetchHistory(),
  watchVoidPayment(),
  watchRequestPaymentReceipt()
]
