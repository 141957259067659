// @flow

import React from 'react'
import truncate from 'lodash/truncate'
import { Link } from 'react-router-dom'
import HistoryMobileView from './HistoryMobileView'
import { TableRow, Typography } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import { StyledTableBodyCell } from '@Common/ListFull'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import ListFull from '@Common/ListFull'
import Flex from '@Common/Flex'
import Loader from '@Common/Loader'
import {
  TitleStyled,
  StatusStyled,
  DesktopOrTablet,
  MobileView,
  NoHistory,
  HistoryList
} from './styles'
import type { Match } from 'react-router-dom'
import { Rows } from './types'
import { useTheme } from '@material-ui/core/styles'

const headers = [
  { label: 'Amount', dataKey: 'amount', sortable: false },
  { label: 'Account', dataKey: 'account', sortable: false },
  { label: 'Type', dataKey: 'type', sortable: false },
  { label: 'Date', dataKey: 'date', sortable: false },
  { label: 'Status', dataKey: 'status', sortable: false }
]

type StatusType = 'processing' | 'failed' | 'paid'

type Props = {
  match: Match,
  historyData: Rows[],
  hasHistory: boolean,
  isFetching: boolean,
  hasNextPage: boolean,
  nextCursor: ?string,
  residentId: string,
  fetchNextPage: (?string) => mixed,
  voidPayment: number => mixed
}

type StatusProps = {
  showAlertModal: Function,
  status: StatusType,
  transId: number,
  voidable: boolean,
  match: Match
}

export const Status = withAlertModal(
  ({ showAlertModal, status, voidable, transId, match }: StatusProps) => {
    return (
      <StatusStyled alignCenter>
        <Typography variant="body1">{status}</Typography>
        {voidable && (
          <Link to="#" onClick={showAlertModal} id="cancel">
            cancel
          </Link>
        )}
        <Link to={`/payments/${match.params.residentId}/history/${transId}`}>
          details
        </Link>
      </StatusStyled>
    )
  }
)

const buildRows = (rows, voidPayment, match) => {
  return rows.map(({ transId, voidable, ...row }, index) => {
    return (
      <TableRow key={index}>
        {headers.map(({ dataKey }, index) => {
          return (
            <StyledTableBodyCell key={row[dataKey]}>
              {dataKey === 'status' ? (
                <Status
                  status={row[dataKey]}
                  transId={transId}
                  match={match}
                  voidable={voidable}
                  alertTitle="Are you sure you wish to cancel this transaction?"
                  onAlertClickYes={() => voidPayment(transId)}
                />
              ) : (
                <Typography variant={index === 0 ? 'h5' : 'body1'}>
                  {truncate(row[dataKey], { length: 15 })}
                </Typography>
              )}
            </StyledTableBodyCell>
          )
        })}
      </TableRow>
    )
  })
}

const ListViewWrapper = ({ isSso, children }) => {
  if (isSso) {
    return <HistoryList>{children}</HistoryList>
  }

  return children
}

const History = ({
  historyData,
  hasHistory,
  isFetching,
  hasNextPage,
  nextCursor,
  fetchNextPage,
  voidPayment,
  match,
  residentId
}: Props) => {
  const theme = useTheme()
  const isSso = theme.ux.isSSO

  const tableProps = {}
  if (isSso) {
    tableProps.stickyHeader = true
  }

  if (isFetching && !hasHistory) return <Loader />

  return (
    <React.Fragment>
      <TitleStyled id="historySummaryTitle">History</TitleStyled>
      {!hasHistory ? (
        <Flex justifyCenter id="noPrevPaymentElem">
          <NoHistory>
            At this time there is no transaction history. Check back later or
            <Link to={`/payments/${residentId}/one-time`}>
              {' '}
              make your first payment!
            </Link>
          </NoHistory>
        </Flex>
      ) : (
        <ListViewWrapper isSso={isSso}>
          <InfiniteScroll
            style={{ marginBottom: '60px' }}
            dataLength={historyData.length}
            next={() => fetchNextPage(nextCursor)}
            hasMore={hasNextPage}
            loader={
              <Flex justifyCenter paddingBottom="2em">
                <Loader fullScreen={false} />
              </Flex>
            }>
            <DesktopOrTablet>
              <ListFull
                elementId="paymentHistory"
                columns={headers}
                rows={buildRows(historyData, voidPayment, match)}
                useInternalRow={false}
                tableProps={tableProps}
              />
            </DesktopOrTablet>
            <MobileView>
              <HistoryMobileView
                match={match}
                historyData={historyData}
                voidPayment={voidPayment}
              />
            </MobileView>
          </InfiniteScroll>
        </ListViewWrapper>
      )}
    </React.Fragment>
  )
}

export default History
