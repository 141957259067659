// @flow

import { connect } from 'react-redux'
import {
  getStatements,
  hasStatements
} from 'zego-shared/store/payments/statements/selectors'

import Statements from './Statements'

export const mapStateToProps = (state: Object) => ({
  statementsData: getStatements(state),
  hasStatements: hasStatements(state)
})

export default connect(mapStateToProps)(Statements)
