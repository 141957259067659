import { createFetchActions } from '../../../helpers/actions/creators'
export const PAYMENTS_SUMMARY = 'PAYMENTSSUMMARY'
export const NEXT_AUTOPAY = 'NEXTAUTOPAY'

export const {
  fetchAction: fetchPaymentsSummaryAction,
  fetchStartAction: fetchPaymentsSummaryStart,
  fetchSuccessAction: fetchPaymentsSummarySuccess,
  fetchErrorAction: fetchPaymentsSummaryError,
} = createFetchActions(PAYMENTS_SUMMARY)
