import { connect } from 'react-redux'
import DownloadApp from './DownloadApp'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { sendDownloadLink } from 'zego-shared/store/users/actions'

export const mapDispatchToProps = dispatch => {
  return {
    sendDownloadLink: phone => dispatch(sendDownloadLink(phone)),
    showError: error => dispatch(showSnackbar(error, 'error'))
  }
}

export default connect(null, mapDispatchToProps)(DownloadApp)
