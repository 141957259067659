// @flow
import React, { useState } from 'react'
import {
  DialogContent,
  DialogTitle,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { 
  StyledDialog,
  DialogStyledList,
  InstructionsStyled,
  InstructionsTextButton
} from './styles'
import { getInstructions } from './utils'

type Props = {
  paymentDisclaimerMemo: string,
  isDebitCard: boolean,
  nsfFee: string | null,
  shouldShowBalanceChecksDisclaimer: boolean
}

type DialogProps = {
  isOpen: boolean,
  onClose: Function,
  instructions: Object[]
}


const SSOInstructionsDialog = ({ isOpen, onClose, instructions }: DialogProps) => {
  return (
    <StyledDialog fullScreen open={isOpen} onClose={onClose} aria-labelledby="AutoPay Instructions Dialog">
      <DialogTitle disableTypography>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogStyledList>
          {instructions.map(({name, text}) => {
            return (
              <li id={name} key={name}>{text}</li>
            )
          })}
        </DialogStyledList>
      </DialogContent>
    </StyledDialog>
  )
}

const SSOInstructions = ({
  paymentDisclaimerMemo,
  isDebitCard,
  nsfFee,
  shouldShowBalanceChecksDisclaimer
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => setIsOpen(false)
  const instructions = getInstructions(paymentDisclaimerMemo, isDebitCard, nsfFee, shouldShowBalanceChecksDisclaimer)

  return (
    <InstructionsStyled>
      <InstructionsTextButton onClick={() => setIsOpen(true)}>
        View Disclaimers
      </InstructionsTextButton>
      <SSOInstructionsDialog
        onClose={handleClose}
        isOpen={isOpen}
        instructions={instructions}
      />
    </InstructionsStyled>        
  )
}

export default SSOInstructions
