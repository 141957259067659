// @flow
import React from 'react'
import Flex from '../Flex'
import { StyledToolTip, DownArrow } from './styles'

type props = {
  message: string
}

const ToolTip = ({ message }: props) => (
  <Flex direction="column" alignEnd>
    <StyledToolTip>{message}</StyledToolTip>
    <DownArrow />
  </Flex>
)

export default ToolTip
