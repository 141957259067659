import styled from '@emotion/styled'
import APTabletBG from '@images/auto_pay_ad/ap_tablet.png'
import APInTabletBG from '@images/auto_pay_ad/ap_in_tablet.png'
import APBG from '@images/auto_pay_ad/ap.png'
import APTallBG from '@images/auto_pay_ad/ap_tall.png'
import APInBG from '@images/auto_pay_ad/ap_in.png'
import GoBtnBG from '@images/auto_pay_ad/go_btn_bg.png'
import GoBtnLongBG from '@images/auto_pay_ad/go_btn_long.png'
import { Link } from 'react-router-dom'

export const Wrapper = styled.div()

export const Calendar = styled.img`
  display: none;
  margin-right: -${({ theme }) => theme.space.s40};
  margin-bottom: ${({ theme }) => theme.space.s20};
  width: 50%;
  max-width: 170px;
  min-width: 120px;
`

export const SubText = styled.div`
  line-height: 20px;
  font-size: ${({ theme }) => (theme.ux.isSSO ? '13px' : '16px')};

  ${({ theme }) => [theme.breakpoints.only('md')]} {
    font-size: 18px;
  }
`

export const MainText = styled.div`
  line-height: 1;
  font-size: ${({ theme }) => (theme.ux.isSSO ? '15px' : '18px')};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};

  ${({ theme }) => [theme.breakpoints.only('md')]} {
    font-size: 24px;
  }
`

export const GoButton = styled.div`
  height: 30px;
  width: 51.5px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
  line-height: 16px;
  font-size: 18px;
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  padding:  6px 0;
  margin-top: ${({ theme }) => theme.space.s25};
  z-index: 0;
  display: inline-block;

  &:before{
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    top: 0;
    left: 0;
    height: 30px;
    width: 0;
    transition: 0.25s width;
    z-index: -1;
  }

  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100%;
    background-image: url('${GoBtnBG}');
    background-size: 100% 100%;
    z-index: 1;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    height: 25px;
    width: 41px;
    font-size: 15px;
    text-align: left;
    margin-top: ${theme.space.s10};

    &:after{
      left: -6px;
    }    
  `}
`

export const Ad = styled(Link)`
  text-decoration: none;
  display: block;
  padding-right: ${({ theme }) => theme.space.s20};
  padding-top: ${({ theme }) => theme.space.s45};
  background-image: url('${({ hasAutoPay }) => (hasAutoPay ? APInBG : APBG)}');
  background-size: 100% 100%;
  height: 204px;
  min-height: 180px;
  max-height: 220px;
  width: 100%;
  min-width: 300px;
  margin-left: -${({ theme }) => theme.space.s10};
  text-align: right;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    padding-right: ${({ theme }) => theme.space.s30};
    padding-top: ${({ theme, hasAutoPay }) =>
      hasAutoPay ? theme.space.s25 : theme.space.s30};
    background-image: url('${({ hasAutoPay }) =>
      hasAutoPay ? APInTabletBG : APTabletBG}');
    min-width: 430px;
    width: 100%;
    height: 204px;
    margin-left: 0;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    padding-right: ${({ theme }) => theme.space.s20};
    min-width: 0;
    height: auto;
  }

  /* Tall Ads */
  ${({ theme }) => [theme.breakpoints.up('lg')]} {
    ${({ theme, variant }) =>
      variant === 'tall' &&
      `
      padding: 4% 0 9%;
      background-image: url('${APTallBG}');
      height: 100%;
      max-height: initial;
      margin-left: 0;
      text-align: center;

      ${Wrapper}{
        margin: auto;
        display: table;
        text-align: left;
      }

      ${Calendar}{
        display: inline-block;
      }

      ${MainText}{
        font-size: 24px;
      }

      ${SubText}{
        font-size: 18px;
      }

      ${GoButton}{
        width: 108px;
        margin-top: ${theme.space.s35};
      }

      ${GoButton}:after{
        background-image: url('${GoBtnLongBG}');
      }
    `}
  }

  &:hover ${GoButton}:before,
  &:focus ${GoButton}:before {
    width: 100%;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    height: 170px;
    min-height: 0;
    max-height: 170px;
    min-width: 280px;
    max-width: 280px;
  `}
`
