import styled from '@emotion/styled'
import Card from '@Common/Card'
import Button from '@Common/Button'

export const StyledCard = styled(Card)`
  min-height: 375px;
  display: flex;
  flex-direction: column;
  ${({ theme, secondary }) =>
    secondary && `background-color: ${theme.palette.neutral.light2}`}
`

export const StyledButton = styled(Button)`
  height: 35px;
  width: 225px;
  line-height: 19px;
  text-transform: unset;
`
