import { capitalizeFirstLetter } from './string'

export const failedToFetchWorkOrderHistory = error =>
  `Failed to fetch work order history. ${error}`

export const genericTimedOutMessage = 'Communication with server timed out'

export const failedToLogin = error => `Failed to login - ${error.message}`

export const failedToLogAs = error =>
  error.statusCode === 403
    ? 'Account has been deactivated.'
    : `Failed to login with the next linked account. ${error}`

export const failedToLoginGoogle = 'Failed to login with Google'

export const failedToLogout = 'Failed to logout'

export const failedToConnect = provider => {
  return `Failed to connect to ${capitalizeFirstLetter(provider)}`
}

export const succeedToLoginLink = propertyName =>
  `Your property ${propertyName} has been successfully linked.`

export const failedToLoginLink = error => `${error.message}`

export const sendEmailConfirmation = 'Activation email sent!'

export const sendEmailError = 'Unable to send activation email'

export const genericDeleteSuccess = 'Successfully deleted'

export const genericErrorMsg = 'Something went wrong!'

export const searchUnitsError = 'Unable to retrieve units'

export const hubFirmwareUpgradeError = 'Hub command upgrade failed'

export const hubCommandError = commandType =>
  `Hub command ${commandType} failed`

export const thermostatCommandError = `Thermostat command failed`

export const lockCommandError = `Lock command failed`

export const hubAddError = 'Adding hub failed'

export const failedToFetchProperties = 'Unable to retrieve properties'

export const failedToSelectProperty = 'Failed to select property'

export const failedToFetchSettings = 'Unable to fetch Settings'

export const failedToRunTest = 'Failed to run test'

export const failedToFetchDevices = 'Faled to retrieve devices'

export const failedToFetchSummary = 'Failed to fetch summary'

export const failedToFetchPropertyBatteryLevels =
  'Failed to fetch property battery summary'

export const failedToFetchUnitBatteryLevels =
  'Failed to fetch unit battery summary'

export const failedToFetchChatChannels = 'Failed to fetch chat channels'

export const failedToFetchChatChannelMessages =
  'Failed to fetch chat channel messages'

export const faileToSendChatMessage = 'Failed to send chat message'

export const failedToGetPermissions = 'Failed to get lock code permissions'

export const noMoreCodeIndex = category =>
  `${capitalizeFirstLetter(category)} codes are full`

export const failedToCreateCode = error => `Failed to create code. ${error}`

export const failedToCreateUserLockCode = error =>
  `Failed to create code: ${error}`

export const failedToEditCode = error => `Failed to edit code. ${error}`

export const failedToDeleteCode = error => `Failed to delete code. ${error}`

export const noUnitSelected = 'No unit has been selected'

export const noPropertySelected = 'No property has been selected'

export const noSocketChannelFound = 'No channel found'

export const failedToRenewToken = 'Failed to reauthenticate'

export const failedToResetPass = 'Failed to reset password'

export const failedToVerifySignupToken = 'Your signup link is no longer valid'

export const failedToSearch = 'Failed to return search results'

export const failedToFetchSearchHistory = 'Failed to return search history'

export const failedToAddSearchHistory = 'Failed to add to search history'

export const failedToEditDeviceName = 'Failed to edit device name'

export const switchCommandError = 'Switch command failed'

export const failedToAddUser = 'Failed to add a user'

export const failedToAddUnit = 'Failed to add unit'

export const failedToFetchSchedule = 'Failed to fetch schedule'
export const failedToSwitchScheduleEnabled = newState =>
  `Failed to ${newState} schedule`
export const failedToUpdateSchedule = 'Failed to update schedule'

export const messageHubAddError = 'Failed to add hub'

export const deleteUserConfirmation = 'User deleted successfully'

export const deleteCommunityThreadConfirmation =
  'Community thread deleted successfully'

export const communityThreadNonExistent = 'Community thread does not exist'

export const commentDeletedConfirmation = 'Comment deleted successfully'

export const failedToFetchMessage = 'Failed to get message'

export const failedToFetchMessageUserSummary =
  'Failed to retrieve user information of message'

export const failedToFetchGroupMessageTemplate = 'Failed to retrieve template'

export const failedToUpdateGroupMessageTemplate = 'Failed to update template'

export const failedToCreateGroupMessageTemplate = error => {
  return error.data.template_name_per_resource
    ? `Template name already exists. Please rename the template`
    : `Failed to create template. All fields are required.`
}

export const createGroupMessageTemplateSuccess =
  'Template was created successfully'

export const failedToFetchReplacementOptions = 'Failed to retrieve merge fields'

export const failedToDeleteGroupMessageTemplate = 'Failed to delete template'

export const deleteGroupMessageTemplateSuccess = 'Successfully deleted template'

export const failedToFetchGroupMessageTemplatePermission =
  'Failed to retrieve template permission data'

export const deleteGroupMessageSuccess = 'Successfully deleted message'

export const failedToDeleteDevice = 'Failed to delete device'

export const deleteDeviceSuccess = 'Successfully deleted device'

export const pinChannelSuccess = 'Channel has been pinned'

export const unpinChannelSuccess = 'Channel has been unpinned'

export const failedToUpdatePackage = 'Failed to update package'

export const updatePackageReminderSuccessful =
  'Successfully updated package reminder'

export const failedToUpdatePackageReminder = 'Failed to update package reminder'

export const failedToUpdateAmenity = 'Failed to update amenity'

export const failedToUpdatePet = 'Failed to update pet'

export const failedToUpdateVehicle = 'Failed to update vehicle'

export const failedToResendVendorCode = 'Failed to resend vendor code'

export const failedToCreateVendorCode = 'Failed to create vendor code'

export const failedToUpdateVendorCode = 'Failed to update vendor code'

export const failedToInvalidateVendorCode = 'Failed to delete vendor code'

export const invalidateVendorCodeSuccess = 'Vendor Code deleted Successfully '

export const resendVendorCodeSuccess = 'Vendor Code resend Successful '

export const reSyncSuccess =
  'Re-sync started and will be completed in about 15mins'

export const reSyncInProgress = 'Re-sync in progress. Try again later'

export const failedToSubmitWorkOrder =
  'An error occurred while creating work order'

export const submitWorkOrderSuccess = 'Your work order has been created.'

export const failedToFetchStatements = error =>
  `Failed to fetch statements: ${error}`

export const failedToMakePayment = 'Failed to make one time payment'

export const failedToFetchPaymentMethods = 'Failed to fetch payment methods'

export const failedToFetchPaymentOptions = 'Failed to fetch payment options'

export const failedToFetchPaymentOptionsFees =
  'Failed to fetch payment options fees'

export const failedToFetchPaymentHistory = error =>
  `Failed to fetch history: ${error}`

export const failedToVoidPayment = 'Failed to void the payment'

export const unavailablePaymentOptions = 'Unavailable payment options'

export const unavailablePaymentService =
  'Error accessing payment service. Please try again.'

export const paymentMethodAdded = 'Your payment method has been added!'
export const failedToSkipAutoPay = 'Failed to skip autopay'
export const failedToCancelAutoPay = 'Failed to cancel autopay'
export const failedToCancelAutoPaySkip = 'Failed to cancel autopay skip'
export const autoPaySuccess = 'Your auto payment has been successfully setup!'
export const autoPayFail = 'Failed to setup auto payment'
export const payPalErrorMessage =
  'We were unable to connect to PayPal. Please try again or select another payment method.'
export const failedToFetchCreditReportingConfig =
  'Failed to fetch credit reporting config'
export const creditReportingSuccessMessage =
  'Your on time rental payments will now be reported to the Credit Bureaus.'
export const levelCreditDeactivationSuccessMessage =
  'Your Credit Reporting account has been successfully deactivated'
export const levelCreditReactivationSuccessMessage =
  'Your Credit Reporting account has been successfully reactivated'
export const levelCreditSuccessMessage =
  'Your rental payments will now be reported to LevelCredit.'
export const tAndCAcceptanceErrorMessage =
  'An error occurred while accepting terms and condition'
export const failedToCloseAccount = 'Failed to close account'
export const failDismisSurveyMsg = 'Could not dismiss survey.'
export const failSubmitSurveyMsg = 'Could not submit survey.'
export const failFetchSurveyMsg = 'Could not fetch survey.'
export const successSubmitSurveyMsg = 'Submitted survey response'
export const failedToGetReportedLCTrans =
  'Failed to fetch level credit transactions'
export const failedToGetReportedLBTrans =
  'Failed to fetch level credit transactions'
export const failedToFetchPmSettings = 'Failed to query pm settings'
export const failedToFetchClientNotes = 'Failed to fetch client notes'

export const fraudToolkitErrorMessage = paymentMethod =>
  `Your payment was unsuccessful (${paymentMethod.network} #${
    paymentMethod.lastFour
  }).`
export const activeAutopaysCancelledMessage =
  'The Autopay(s) associated with this card have been cancelled and need to be re-established.'

export const failToConnectToPlaid = 'We have experienced an error while processing your payment. Please try again or use a different payment method.'
export const failedToReauthPlaid = 'Zego is currently unable to reauthorize your account. Please try again later.'
