// @flow
import * as React from 'react'
import { withTheme } from 'emotion-theming'

import Flex from '../../Common/Flex'
import ProgressBar from '../ProgressBar'
import Loader from '../../Common/Loader'
import { DividerStyled, LayoutStyled } from './styles'

type Props = {
  progress: number,
  children: React.Node,
  theme: Object,
  isFetching: boolean
}
const Layout = (props: Props) => {
  const { progress, theme, children, isFetching } = props

  return (
    <LayoutStyled direction="column">
      {isFetching && <Loader />}
      {!isFetching && (
        <React.Fragment>
          <Flex
            justifyCenter
            alignCenter
            paddingLeft={theme.space.s20}
            paddingRight={theme.space.s20}>
            <ProgressBar value={progress} />
          </Flex>
          <DividerStyled />
          {children}
        </React.Fragment>
      )}
    </LayoutStyled>
  )
}

export default withTheme(Layout)
