// @flow

import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import type { BrowserLocation } from 'history'
import type { Node } from 'react'
import { generate as shortid } from 'shortid'

type Props = {
  children: Node,
  location: BrowserLocation
}

export const SCROLL_ELEMENT_ID = shortid()

const ScrollOnClick = ({ children, location: { pathname } }: Props) => {
  useEffect(() => {
    if (pathname !== '/') {
      const content: HTMLElement | null = document.getElementById(
        SCROLL_ELEMENT_ID
      )
      if (content) {
        const scrollY = window.scrollY || window.pageYOffset
        const absTop = scrollY + content.getBoundingClientRect().top
        window.scrollTo(0, absTop - 60)
      } else {
        window.scrollTo(0, window.innerHeight)
      }
    }
  }, [pathname])

  return children || null
}

export default withRouter(ScrollOnClick)
