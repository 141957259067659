import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getMarketplaceDeals } from 'zego-shared/store/marketplace/selectors'
import { fetchMarketplaceDeals } from 'zego-shared/store/marketplace/actions'
import { getUnitId } from 'zego-shared/store/select/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import Marketplace from './Marketplace'
import { connect } from 'react-redux'

const fetchingSelector = createIsFetchingSelector(['marketplacedeallist'])

export const mapStateToProps = (state) => ({
  isFetching: fetchingSelector(state),
  deals: getMarketplaceDeals(state),
  unitId: getUnitId(state)
})

export const mapDispatchToProps = (dispatch) => ({
  fetchData: () => {
    dispatch(fetchMarketplaceDeals())
  }
})

const MarketplaceWithFetchData = withFetchData(Marketplace)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceWithFetchData)
