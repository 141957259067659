// @flow
import { startCase } from 'lodash';
import { VAP_AND_FAP, VAP, FAP, NO_AUTOPAY } from '../payments/autopay/constants'
import type { AutoPayState } from '../payments/autopay/constants';
import log from '../../utils/logger';


const lockAmountTrueValues = ['yes', 'y']

export function formatPaymentAmountsFromParams(
  paymentAmount: string,
  lockAmount: boolean
): Array<any> 
{
  const paymentAmountsJson: Object = JSON.parse(paymentAmount)
  return Object.entries(paymentAmountsJson).map((key) => {
      return mapPaymentFields(key, lockAmount)
  })
}

/**
 * This function returns the allowed autopay type(s) based on retrieved and overriden values
 * 
 * @param {AutoPayState} override value from override store to eliminate as autopay option
 * @param {AutoPayState} stored value of autopay options from store based on resident setup
 * @returns AutoPayState corresponding to allowed autopay type(s)
 */
export function getOverridenAutoPayType(hideAutoPayType: AutoPayState, storedAutoPayType: AutoPayState): AutoPayState {
  if (hideAutoPayType === storedAutoPayType) {
    return NO_AUTOPAY
  }

  if (storedAutoPayType === VAP_AND_FAP) {
    if (hideAutoPayType === VAP) {
      return FAP
    }
    if (hideAutoPayType === FAP) {
      return VAP
    }
  }

  // case where the value in the store does not match FAP|VAP
  return storedAutoPayType
}

export function convertLockAmount(lockAmount: string) {
  return lockAmount && lockAmountTrueValues.includes(lockAmount.toLowerCase())
}

function mapPaymentFields(paymentAmounts: Object, lockAmount: boolean) {
  const [ label, value ] = paymentAmounts
  let amount

  try {
    amount = Math.max(Math.floor(parseFloat(value) * 100), 0)

    if (isNaN(amount)) {
      amount = 0
    }
  } catch (e) {
    // amount sent over was not valid
    log(`Failed to parse paymentAmount ${value} for payment field ${label}`)
    amount = 0
  }

  return {
    key: label,
    label: startCase(label),
    money: {
      currency: 'USD',
      amount: amount
    },
    updatedDate: null,
    locked: lockAmount
  }
}
