import { TableCell, TableBody } from '@material-ui/core'
import styled from '@emotion/styled'

export const StyledTableHeaderCell = styled(TableCell, {
  shouldForwardProp: prop => !['columnLength'].includes(prop)
})`
  color: ${({ theme }) => theme.palette.neutral.deep};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  padding: 0 0 ${({ theme }) => theme.space.s15};
  ${({ flex, columnLength }) =>
    flex && `width: ${(100 / columnLength) * flex}%`};
  ${({ align }) =>
    align &&
    `
      text-align: ${align};
      padding: 0 !important;
    `}
  ${({ width }) => width && `width: ${width}`}
`

export const StyledTableBodyCell = styled(StyledTableHeaderCell)`
color: ${({ theme }) => theme.palette.primary.deep};
  border-bottom: 0;
  & h5 {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
  }

  &:last-child {
    padding-right: 0;
  }
`

export const StyledTableBody = styled(TableBody)`
  &::before {
    height: 30px;
    content: '_';
    color: transparent;
    display: block;
  }
`
