// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import { buttonWrapperStyledSsoStyles } from '../../styles'
import { methodTypes } from '@utils/view'

const ssoPaymentsTitleMarginBottom = {
  [methodTypes.bankAccount]: `20px;`,
  [methodTypes.card]: `15px;`
}

export const FlexStyled = styled(Flex)`
  margin-bottom: ${({ theme, type }) =>
      theme.ux.isSSO ? ssoPaymentsTitleMarginBottom[type] : `25px;`}
    & p {
    color: ${({ theme }) => theme.palette.primary.black};
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }

  & button {
    font-size: 16px;
    line-height: 19px;
    text-align: right;
  }
`
export const ButtonWrapperStyled = styled(Flex)`
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.space.s75};

  & button {
    height: 35px;
    width: 225px;
    text-transform: unset;
    margin-left: 22px;
  }
  
  ${({ theme }) => theme.ux.isSSO && buttonWrapperStyledSsoStyles}
`

export const CardDisclaimerWrapper = styled('div')`
  position: relative;
  top: 40px;
`
