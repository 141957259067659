// @flow
import get from 'lodash/get'
import { formatDateMDYY } from '../../../../utils/date'
import Dinero from 'dinero.js'

export const getStatements = (state: Object) => {
  const statements = get(state, 'payments.statements')
  if (!statements) return null

  return statements.map(({ dueDate, amountDue, statementUrl }) => {
    return {
      dueDate: formatDateMDYY(dueDate.substring(0,10)),
      amount: Dinero(amountDue).toFormat(),
      billPDFUrl: statementUrl || '#'
    }
  })
}

export const hasStatements = (state: Object) => {
  const statements = get(state, 'payments.statements')
  return statements != null && statements.length > 0
}
