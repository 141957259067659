import {
  FETCH_PREFIX,
  START,
  SUCCESS,
  ERROR
} from '../../helpers/actions/constants'
import { combineReducers } from 'redux'
import { BLOCK_FETCH } from '../actions'
import { endsWithAny } from '../../../utils/string'
import { HIDE_TIMEOUT_COMP } from '../../loader/actions'

const SUPPORTED_PREFIX = FETCH_PREFIX
const SUPPORTED_POSTFIXES = [START, SUCCESS, ERROR]

// handles all actions with type format: PREFIX_SOMEWORD_SUFFIX
const isFetching = (state = {}, action) => {
  const { type } = action

  if (
    !type ||
    !(
      type.startsWith(SUPPORTED_PREFIX) ||
      endsWithAny(SUPPORTED_POSTFIXES, type)
    )
  ) {
    return state
  }

  if (type === BLOCK_FETCH) {
    return {}
  }

  // if its not start just set isFetching to false
  const isFetching = type.includes(START)
  const requestName = type.split('_')[1]

  return {
    ...state,
    [requestName.toLowerCase()]: isFetching
  }
}

const isBlocked = (state = false, action) => {
  switch (action.type) {
    case BLOCK_FETCH:
      return true
    case HIDE_TIMEOUT_COMP:
      return false
    default:
      return state
  }
}

export default combineReducers({ isFetching, isBlocked })
