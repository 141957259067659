// @flow
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import Button from '@Common/Button'

export const StyledLink = styled(NavLink)`
  ${({ theme }) => ({
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.h6.fontSize,
  })};
  text-decoration: underline;
`

export const StyledButton = styled(Button)`
  height: 35px;
  width: 225px;

  &:last-of-type {
    margin-left: ${({ theme }) => `${theme.spacing(9)}px`};
  }
`
