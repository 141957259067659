import styled from '@emotion/styled'
import SVG from '../SVG'

export const MainContent = styled('div')`
  margin-top: calc(10vh + 75px);
  margin-bottom: ${({ theme }) => (theme.ux.isSSO ? '0px' : '10vh')};
  ${({ theme }) => [theme.breakpoints.only('lg')]} {
    margin-top: ${({ theme }) =>
      theme.ux.isSSO ? 'calc(5vh + 4px)' : 'calc(5vh + 75px)'};
  }
  ${({ theme }) => [theme.breakpoints.only('md')]} {
    margin-top: calc(12vh + 75px);
  }
  ${({ theme }) => [theme.breakpoints.only('sm')]} {
    margin-top: ${({ theme }) =>
      theme.ux.isSSO ? 'calc(12vh + 6px)' : 'calc(12vh + 60px)'};
  }
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    margin-top: 10vh;
  }
`

export const PageTitle = styled('div')`
  color: ${({ theme }) => theme.palette.primary.deep};
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: 29px;
  margin-top: ${({ theme }) => theme.space.s10};
`

export const MyAvatar = styled('div')`
  margin: 0 auto ${({ theme }) => theme.space.s30} auto;
  width: fit-content;
  max-width: 100%;
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    width: 190px;
  }
  border-radius: 50%;
  overflow: hidden;
`

export const Name = styled('div')`
  margin-top: ${({ theme }) => theme.space.s10};
`

export const ResidentName = styled('div')`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  height: 29px;
  line-height: 29px;
`

export const UnitName = styled('div')`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  height: 19px;
  text-transform: capitalize;
`

export const StyledSVG = styled(SVG)`
  position: absolute;
  left: 30%;
  top: 0;
  max-width: 70%;
  z-index: -1;

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    left: 0;
    margin-left: 0;
    max-width: 100%;
  }

  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    top: calc(5vh - 75px);
  }
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    top: -75px;
  }
`
