import React, { useState } from 'react'
import { withTheme } from 'emotion-theming'
import { CommonIcon } from '@icons'
import { Hidden } from '@material-ui/core';
import SurveyContainer from '@Common/Survey'
import CloseIcon from '@material-ui/icons/Close'

import {
  CloseBtn,
  DisplayMsg,
  NotificationRight,
  NotificationBanner,
  NotificationContainer,
  StyledFab,
  StyledButton,
  StyledDismiss
} from './styles'

const Notification = (props) => {
  const { dismissNotification, surveyId, surveyMessage, theme } = props
  const [showModal, setShowModal] = useState(false)

  return(
    <>
    <NotificationContainer>
      <NotificationBanner>
        <StyledFab disableRipple>
          <CommonIcon
            name="star"
            height="25px"
            width="25px"
            fill={theme.palette.common.white}
          />
        </StyledFab>

        <Hidden mdUp> 
          <CloseBtn onClick={() => { dismissNotification(surveyId) }}>
            <CloseIcon />
          </CloseBtn>
        </Hidden>

        <DisplayMsg style={{ fontWeight: 'bold' }}> We'd love your feedback! </DisplayMsg> 
          <Hidden smUp> <br /></Hidden>
        <DisplayMsg> Rate your experience with our property </DisplayMsg>
          <Hidden smUp> <br /></Hidden>
        <StyledButton onClick={() => { setShowModal(!showModal) }}>
          Give Feedback
        </StyledButton>

      </NotificationBanner >

      <Hidden smDown>
        <NotificationRight>
          <StyledDismiss disableRipple name={"Dismiss"} 
            onClick={() => { dismissNotification(surveyId) }}
            endIcon={<CloseIcon /> }>
          </StyledDismiss>
        </NotificationRight>
      </Hidden>

    </NotificationContainer>

    {showModal && <SurveyContainer setShowModal={setShowModal} surveyId={surveyId} surveyMessage={surveyMessage} /> }

    </>
  )
}

export default withTheme(Notification)