// @flow
export const getPaymentMethodsQuery = () => {
  return ` query($residentId: ID!, $checkAllowed:Boolean) {
    getPaymentMethods(id: $residentId, checkAllowed:$checkAllowed) {
        ... on BankAccount {
          __typename
          id
          bankName
          accountHolderName
          type
          lastFour
          routingNumber
          currencyCode
          isPlaidBankAccount,
          isTan
        }
        ... on CreditCard {
          __typename
          id
          network
          lastFour
          cardHolderName
          expirationMonth
          expirationYear
        }
        ... on DebitCard {
          __typename
          id
          network
          lastFour
          cardHolderName
          expirationMonth
          expirationYear
        }
      }
    }`
}

export const getPaymentMethodsVariables = (
  residentId: string,
  checkAllowed: boolean = false
) => {
  return {
    residentId,
    checkAllowed
  }
}
