// @flow

import styled from '@emotion/styled'
import Card from '@Common/Card'
import Flex from '@Common/Flex'
import { Button } from '@Common/Button/styles'
import { buttonWrapperStyledSsoStyles } from '../styles'

export const CardStyled = styled(Card)`
  width: 100%;

  & .MuiCardContent-root {
    width: 100%;
    height: 100%;
  }
  &,
  & * {
    box-sizing: border-box;
  }
`

export const ButtonWrapperStyled = styled(Flex)`
  justify-content: flex-end;
  margin-top: 75px;

  ${Button} {
    height: 35px;
    width: 225px;
    text-transform: unset;
    margin-left: 22px;
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  }

  ${({ theme }) => theme.ux.isSSO && buttonWrapperStyledSsoStyles}
`

export const LaunchPayPalButtonBtn = styled('div')`
  position: absolute;

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    position: relative;
    width: 100%;
  }
`

export const PayPalButtonWrap = styled('div')`
  height: 35px;
  width: 225px;

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    width: 100%;
  }
`

export const UnderlinedButton = styled('button')(
  ({ theme }) => `
  background: none;
  border: none;
  cursor: pointer;
  color: ${theme.palette.common.grey};
  font-size: ${theme.custom.font.size.H6} !important;
  font-weight: ${theme.custom.font.weight.regular};
  text-decoration-line: underline;
  ${theme.breakpoints.down('xs')} {
    font-size: ${theme.custom.font.size.sm};
  }
`
)
