import React from 'react'
import Loader from '@Common/Loader'

class FeatureFlags extends React.Component<Props, State> {
    componentDidMount() {
        const { ldInitRequest } = this.props

        ldInitRequest()
    }
    
    render() {
        const { featureFlag, location, flagName, flagValues } = this.props
        const loaded = featureFlag && featureFlag.ld && flagValues
        const flag = loaded && featureFlag.ld.variation(flagName, false)
        const ChildComponent = loaded ? flagValues[flag] : Loader

        return (<ChildComponent location={location} />)
    }
}

export default FeatureFlags