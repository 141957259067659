import { connect } from 'react-redux'
import get from 'lodash/get'
import History from './History'
import { fetchWorkOrderHistoryAction } from 'zego-shared/store/workOrders/history/actions'
import { getWorkOrderHistory } from 'zego-shared/store/workOrders/selectors'
import { withFetchData } from 'zego-shared/views'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'

const historyWithFetchData = withFetchData(History)
const fetchingSelector = createIsFetchingSelector(['workorderhistorysection'])

export const mapStateToProps = state => ({
  workOrders: get(getWorkOrderHistory(state), 'data'),
  breakPoint: get(getWorkOrderHistory(state), 'breakPoint'),
  isFetching: fetchingSelector(state),
})

const mapDispatchToProps = { fetchData: fetchWorkOrderHistoryAction }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(historyWithFetchData)
