// @flow

import React from 'react'
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import Flex from '../../../Common/Flex'
import { Grid } from '@material-ui/core'
import { BlockedPaymentStyled, IconButtonStyled } from './styles'

export type Props = {
  fullWidth?: boolean
}

const PaymentsBlocked = ({ fullWidth }: Props) => {
  return (
    <BlockedPaymentStyled
      noSm
      CardContentProps={{
        classes: {
          root: 'card-content-root'
        }
      }}>
      <Grid xs={12} direction="column">
        <Flex alignCenter justifyLeft fullWidth>
          <IconButtonStyled>
            <PriorityHigh />
          </IconButtonStyled>
          <h4>My Current Balance</h4>
        </Flex>
        <p>
          Please note you are unable to make payments or create an Auto Pay at
          this time. You can still view your transaction history. If you had
          setup an Auto Pay, it will be skipped till the status of your account
          is updated.
          <br />
          <br /> Contact your management company with questions regarding your
          account status.
        </p>
      </Grid>
    </BlockedPaymentStyled>
  )
}

export default PaymentsBlocked
