// @flow
import styled from '@emotion/styled'
import { Container } from '@material-ui/core'
import SVG from '../../../SVG'

export const MainContainerBG = styled(SVG)`
  background: ${({ theme }) => theme.palette.common.white};
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: -1;
  & ~ * {
    position: relative;
  }
`

export const ContentWrapper = styled(Container)`
  padding: 70px 0 0 0;
  height: calc(100vh - 56px);
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding: 40px 5% 0 5%;
  }
`
