import withFetch from '../../../helpers/reducers/withFetch'
import { CLIENT_NOTES_SECTION } from '../actions'

const clientNotes = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(CLIENT_NOTES_SECTION)(clientNotes)
