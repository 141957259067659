import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import Flex from '@Common/Flex'
import PaymentMethod from '../../PaymentMethods/PaymentMethod'
import { paymentMethodProps } from '../../../../utils/view'
import {
  MethodContainer,
  MethodsStyled,
  StyledListItem,
  HeaderStyled,
  AddIconButtonStyled,
  MethodListContainer,
  NameStyled
} from './styles'

const SavedMethodsList = ({
  paymentMethods,
  setShowSavedMethods,
  onSavedMethodSelected,
}) => {
  const renderMethods = () => {
    return (
      <Flex alignCenter fullWidth style={{ overflow: 'auto', flex: 1 }}>
        <MethodsStyled id="paymentMethods" direction="column">
          {paymentMethods.map((paymentMethod, i) => {
            const { name, id, account, ...method } = paymentMethod
            const props = paymentMethodProps[method.feeName]
            return (
              <MethodContainer>
                <StyledListItem disableGutters key={id}>
                  <PaymentMethod
                    {...method}
                    {...props}
                    onClick={() => onSavedMethodSelected(id)}
                    variant="list">
                    <NameStyled>
                      {name} {account}
                    </NameStyled>
                  </PaymentMethod>
                </StyledListItem>
              </MethodContainer>
            )
          })}
        </MethodsStyled>
      </Flex>
    )
  }
  return (
    <div>
        <HeaderStyled justifySpaceBetween alignCenter fullWidth>
          <h5>Payment Method</h5>
        </HeaderStyled>
      <MethodListContainer alignStart>
        {renderMethods()}
        <AddIconButtonStyled
          id="addMethod"
          onClick={() => setShowSavedMethods(false)}>
          <AddIcon />
        </AddIconButtonStyled>
      </MethodListContainer>
    </div>
  )
}

export default SavedMethodsList
