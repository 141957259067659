// @flow
import React from 'react'
import { PaymentIconsA, PaymentIcons } from '@icons'
import { pascalToSentenceCase } from 'zego-shared/utils/string'
import { Root, Wrapper, Name, LightText, Nickname } from './styles'

type PaymentTypes = 'BankAccount' | 'CreditCard' | 'PayPal' | 'DebitCard'

type Props = {
  typeName: PaymentTypes,
  name?: string,
  account?: string,
  existing?: boolean,
  network?: string,
  height?: number,
  selected?: boolean,
  onClick: Function
}

const PaymentMethodIcon = ({
  typeName,
  account,
  name,
  existing,
  network = '',
  height = 60,
  onClick,
  selected
}: Props) => {
  const [name1, name2] = pascalToSentenceCase(typeName).split(' ')

  const renderExistingMethod = () => {
    const icon = network.toLowerCase() || 'bank2'
    const whiteText = typeName !== 'BankAccount'
    const reverse = typeName === 'CreditCard'
    const shortName = typeName === 'PayPal' ? 'PayPal' : name1
    const scale = height / 60

    return (
      <Root existing scale={scale}>
        <Wrapper
          type={typeName}
          existing
          reverse={reverse}
          scale={scale}
          selected={selected}
          onClick={onClick}
        >
          <PaymentIcons name={icon} icon={icon} height="16px" />
          <Nickname white={whiteText} reverse={reverse} scale={scale}>
            {name}
          </Nickname>
          <Name white={whiteText}>
            {shortName.toLowerCase()} <LightText>{account}</LightText>
          </Name>
        </Wrapper>
      </Root>
    )
  }

  const renderNewMethod = () => {
    const spaceName = typeName !== 'PayPal'
    const lightText = typeName === 'BankAccount' ? 'Acct' : name2

    return (
      <Root>
        <Wrapper type={typeName} selected={selected} onClick={onClick}>
          <Name existing>
            {name1}
            {spaceName && ' '}
            <LightText>{lightText}</LightText>
          </Name>
          <PaymentIconsA name={typeName} icon={typeName} height="28px" />
        </Wrapper>
      </Root>
    )
  }

  return existing ? renderExistingMethod() : renderNewMethod()
}

export default PaymentMethodIcon
