// @flow
import { connect } from 'react-redux'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import {
  isLevelCreditReportingEnabled,
  getIsOptIn
} from 'zego-shared/store/payments/creditReporting/selectors'
import { getDisableMakePaymentMenuTab } from 'zego-shared/store/pmSettings/selectors'
import { hasStatements } from 'zego-shared/store/payments/statements/selectors'
import { nativePayerWithoutPayment } from 'zego-shared/store/settings/selectors'
import { getDisableAutopayMenuTab } from 'zego-shared/store/pmSettings/selectors'
import { getAutoPays } from 'zego-shared/store/payments/autopay/selectors'
import SsoPaymentsMenu from './SsoPaymentsMenu'

export const mapStateToProps = (state: Object) => ({
  residentId: getUserId(state),
  hasStatements: hasStatements(state),
  nativePayerWithoutPayment: nativePayerWithoutPayment(state),
  isLevelCreditEnabled: isLevelCreditReportingEnabled(state),
  isLegacyOptedIn: getIsOptIn(state),
  disableMakePaymentMenuTab: getDisableMakePaymentMenuTab(state),
  disableAutopayMenuTab: getDisableAutopayMenuTab(state),
  autopays: getAutoPays(state)
})

export default connect(mapStateToProps)(SsoPaymentsMenu)
