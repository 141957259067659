import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'
import MaterialButton from '@material-ui/core/Button'

export const Button = styled(MaterialButton, {
  shouldForwardProp: prop =>
    [
      'secondary',
      'primary',
      'primaryLight',
      'secondaryLight',
      'left',
      'elong',
      'long',
      'animate',
      'thin',
      'outline',
      'regular'
    ].indexOf(prop) === -1
})`
  margin: 7px 0;
  padding: 0 15px 3px 15px;
  min-width: 90px;
  border: 2px solid transparent;
  font-weight: ${({ theme, regular }) =>
    regular
      ? theme.custom.font.weight.regular
      : theme.custom.font.weight.bolder};
  text-transform: capitalize;
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ color, theme }) => (color ? color : theme.palette.text.primary)};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
  ${({ left }) => left && `justify-content:left`};
  ${({ elong }) => elong && `padding: 5px 45px;`};
  ${({ long }) => long && `width: 225px;`};
  ${({ thin }) => thin && `min-height: 25px;`};
  ${({ primary, theme }) =>
    primary &&
    `background-color: ${theme.palette.primary.main};
      color: ${theme.palette.common.white};
    &:hover {
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.secondary.main};
    }`}
  ${({ primaryLight, theme }) =>
    primaryLight &&
    `background-color: ${theme.palette.primary.light};
      color: ${theme.palette.common.white};
    &:hover {
      color: ${theme.palette.primary.light};
      background-color: ${theme.palette.secondary.main};
    }`}
  ${({ secondary, theme }) =>
    secondary &&
    `background-color: ${theme.palette.secondary.main};
      color: ${theme.palette.common.white};
      &:hover {
        color: ${theme.palette.primary.main};
        background-color: ${theme.palette.secondary.main};
      }`};
  ${({ outline, primary, theme }) =>
    outline &&
    primary &&
    ` background-color:transparent;
      color: ${theme.palette.primary.main};
      border: 3px solid ${theme.palette.primary.main};
      &:hover {
        background-color: transparent;
        border: 3px solid ${theme.palette.secondary.main};
        color: ${theme.palette.primary.main};
      }`};
  ${({ outline, disabled, theme }) =>
    disabled &&
    `
      border: 3px solid ${theme.palette.neutral.main};
      background-color:  ${
        outline ? 'transparent' : theme.palette.neutral.main
      };
      &.disabled {
        color: ${
          outline ? theme.palette.neutral.main : theme.palette.common.white
        };
        background-color:  ${
          outline ? 'transparent' : theme.palette.neutral.main
        };
      }
    `};

  ${({ link, theme }) =>
    link &&
    `text-decoration: underline;
      text-transform: lowercase;
      color: ${({ color, theme }) =>
        color ? color : theme.palette.text.primary};
      background-color:transparent;
      &:hover {
        text-decoration: underline;
      }
    `};
  ${({ animate }) =>
    animate &&
    css`
      &:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ${ellipsis} steps(4, end) 1200ms infinite;
        content: '\\2026';
        width: 0;
      }

      & > span {
        width: auto;
      }
    `};

    ${({ isTerms }) =>
      isTerms &&
      `
        width: 247px;
        height: 35px;
    `};
    
  .login-label {
    width: auto;
  }
`

const ellipsis = keyframes`
  to {
    width: 1.25em;
  }
`

export const FabButton = styled(MaterialButton, {
  shouldForwardProp: prop => !['component', 'size', 'zIndex'].includes(prop)
})`
  position: ${({ position }) => (position ? position : 'relative')};
  color: ${({ theme }) => theme.palette.common.white};
  min-width: ${({ size }) => (size ? size : '3em')};
  width: ${({ size }) => (size ? size : '3em')};
  min-height: ${({ size }) => (size ? size : '3em')};
  height: ${({ size }) => (size ? size : '3em')};
  border-radius: 50%;
  background: ${({ theme, background }) =>
    background ? background : theme.palette.primary.main};
  ${({ primaryLight, theme }) =>
    primaryLight &&
    `background-color: ${theme.palette.primary.light};
      color: ${theme.palette.common.white};
    &:hover {
      color: ${theme.palette.primary.light};
      background-color: ${theme.palette.secondary.main};
    }`}
  &:hover {
    background: ${({ theme, background }) =>
      background ? background : theme.palette.primary.main};
  }
  ${({ zIndex }) => zIndex && `z-index:${zIndex}`};
  ${({ left }) => left && `left:${left}`};
  ${({ right }) => right && `right:${right}`};
  ${({ top }) => top && `top:${top}`};
  ${({ bottom }) => bottom && `bottom:${bottom}`};
  & > span {
    width: auto;
  }
`
