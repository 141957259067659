// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import Card from '@Common/Card'
import Button from '@Common/Button'
import { Link } from 'react-router-dom'
import { TextInput, DateInput, Select, FormGroup } from '@Common/Form'

export const HeaderStyled = styled.h3`
  height: 43.2px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H3};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  line-height: 43px;
  margin: 0 0 34px;
`

export const BasicFieldsStyled = styled(Card)`
  min-height: 190px;
  display: flex;
  margin-bottom: 20px;

  & .MuiCardContent-root {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    & > div {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      grid-gap: 30px;
      margin-bottom: ${({ theme }) => theme.space.s20};
    }

    & > div:last-of-type {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`

export const AddressFieldsStyled = styled(Card)`
  min-height: 190px;
  display: flex;
  margin-bottom: 75px;

  & .MuiCardContent-root {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    & > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      grid-gap: 30px;
      margin-bottom: ${({ theme }) => theme.space.s20};
    }

    & > div:last-of-type {
      grid-template-columns: repeat(4, 1fr);

      & div:first-of-type {
        grid-column: 1 / span 2;
      }
    }
  }
`

export const ButtonWrapperStyled = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.s30};

  & p {
    height: ${({ theme }) => theme.space.s12};
    color: ${({ theme }) => theme.palette.common.grey};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: 14px;
    margin-right: ${({ theme }) => theme.space.s40};
  }

  & button {
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    height: ${({ theme }) => theme.space.s35};
    width: 225px;
  }

  & a {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary.dark};
  }

  display: ${({ isLevelCredit }) => (isLevelCredit ? 'grid' : 'flex')};
`

export const StyledCard = styled(Card)`
  margin-top: ${({ hasPurchaseDetails, theme }) =>
    hasPurchaseDetails ? theme.space.s41 : theme.space.s75};

  min-height: ${({ theme }) => (theme.ux.isSSO ? 0 : 245)}px;
  height: fit-content;

  & div.MuiCardContent-root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    padding: ${({ theme, customPadding }) => !customPadding && theme.space.s45};

    ${({ theme }) => [theme.breakpoints.down('md')]} {
      grid-template-columns: none;
    }
  }

  ${({ theme }) => theme.ux.isSSO && 'margin-top: 18px;'}
`

export const DetailsItemStyled = styled(Flex)`
  height: ${({ theme }) => theme.space.s16};
  min-height: fit-content;
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  line-height: 19px;
  margin-bottom: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s13 : theme.space.s30};

  & span:first-of-type {
    min-width: 68px;
    color: ${({ theme }) => theme.palette.common.grey};
    margin-right: ${({ theme }) => theme.space.s30};
    text-transform: capitalize;
  }
`

export const OpOutText = styled('div')`
  margin-top: ${({ theme }) => theme.space.s30};
  height: ${({ theme }) => theme.space.s80};
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  line-height: ${({ theme }) => theme.space.s20};
`

export const StyledButton = styled(Button)`
  margin-right: 13px;

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    width: 314px;
    height: 35px;
  }
`

export const Bold = styled('span')`
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
`

export const LearnMore = styled('div')`
  margin-left: ${({ theme }) => theme.space.s50};
  height: 15px;
  margin-top: ${({ theme }) => theme.space.s24};
  & a {
    color: ${({ theme }) => theme.palette.secondary.dark};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    text-decoration-line: underline;
  }
`

export const ShowForMdUp = styled('div')`
  display: block;
  ${({ zIndex }) => zIndex && 'z-index: 1'};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: none;
  }
`

export const ShowForMdDown = styled(Flex)`
  display: none;
  ${({ zIndex }) => zIndex && 'z-index: 1'};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: block;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    ${({ isOptedIn }) =>
      isOptedIn &&
      `position: absolute;
       left: 0;
       right: 0;
       width: 100%;
    `}
`

export const StyledLink = styled(Link)`
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  line-height: 14px;
  text-decoration: underline;
`

export const OptOutText = styled.div`
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  line-height: 14px;
`
export const StyledFormGroup = styled(FormGroup)`
  width: 100%;
`

export const StyledInputText = styled(TextInput)`
  & .MuiInputBase-input {
    height: 44px;
    border-radius: 0;
    border: none;
  }
  fieldset {
    border-radius: 4px;
  }
`

export const StyledInputDate = styled(DateInput)`
  & .MuiInputBase-input {
    height: 44px !important;
    border-radius: 0;
    border: none;
  }
  fieldset {
    border-radius: 4px !important;
  }
`

export const StyledSelect = styled(Select)`
  & .MuiInputBase-input {
    height: 44px;
    border-radius: 0;
    border: none;
  }
  fieldset {
    border-radius: 4px !important;
  }
`
