// @flow
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { Formik } from 'formik'
import Flex from '@Common/Flex'
import Hidden from '@material-ui/core/Hidden'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import { getErrorInfo, getHasError } from '@utils/view'
import { createIsUpdatingSelector } from 'zego-shared/store/isUpdating/selectors'
import { purchaseLookbackAction } from 'zego-shared/store/payments/creditReporting/actions'
import { hasPurchasedLookback } from 'zego-shared/store/payments/creditReporting/selectors'
import initialValues from './initialValues'
import { cardSchema } from './schema'
import LookbackFields from './LookbackFields'
import {
  LCButtonWrap,
  LCStyledButton,
  LevelCreditLogoDiv,
  LookBackHeader,
  LookBackHeaderInfo,
  LookBackHeaderTitle,
  LookBackPaymentAmount,
  LookBackPaymentAmountDecimal,
  LookBackPaymentInfo,
  LevelCreditFormContainer,
  FooterInfo,
  LookBackTitleInfo,
  LookBackPaymentContainer,
  LookBackMessage,
  LookBackMessageMob,
  LogoWrap
} from './styles'

type Props = {
  history: {
    push: Function
  },
  match: {
    url: string
  },
  isNew?: boolean
}
type FormProps = {
  hasDetails: boolean,
  prevDetails: Object,
  latestTnC: Object,
  isLevelCreditReportingEnabled: boolean,
  defaultEmail: string
} & Props

const getButtonText = (isProcessing: boolean) => {
  if (isProcessing) return 'Processing'
  return 'Submit'
}

const checkAddressLine2 = data => {
  if (!data.addressLine2) {
    data.addressLine2 = null
    return data
  }

  return data
}

export const getSubmitForm = ({
  setIsProcessing,
  dispatch,
  redirectToSuccessPage
}: {
  setIsProcessing: Function,
  dispatch: Function,
  redirectToSuccessPage: Function
}) => (formData: Object) => {
  setIsProcessing(true)
  dispatch(
    purchaseLookbackAction(checkAddressLine2(formData), redirectToSuccessPage)
  )
}

export const getButtons = ({
  handleSubmit,
  disableSubmit,
  isProcessing,
  onCancelClick
}: {
  handleSubmit: Function,
  disableSubmit: Function,
  isProcessing: boolean,
  onCancelClick:Function
}) => {
  return (
    <LCButtonWrap>
      <LCStyledButton
        id="cancelBtn"
        onClick={onCancelClick}
        primary
        outline
        fullWidth>
        Cancel
      </LCStyledButton>
      <LCStyledButton
        id="submitBtn"
        primary
        onClick={handleSubmit}
        disabled={disableSubmit || isProcessing}
        animate={isProcessing}>
        {getButtonText(isProcessing)}
      </LCStyledButton>
    </LCButtonWrap>
  )
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const LookbackPurchase = ({ history, match }: FormProps) => {
  const isUpdating: boolean = useSelector(
    createIsUpdatingSelector(['lookbackpurchase'])
  )
  const hasLookback: boolean = useSelector(hasPurchasedLookback)
  const query = useQuery()
  const newOptIn = query.get('newOptIn')
  const [isProcessing, setIsProcessing] = React.useState(false)
  const landingPage = match.url.replace('/lookback/purchase', '')
  const redirectToSuccessPage = () =>
    history.push(
      newOptIn ? '/lookback/success?newOptIn=true' : '/lookback/success'
    )
  const onCancelClick = () => history.push(landingPage)

  const dispatch = useDispatch()
  const submitForm = getSubmitForm({
    setIsProcessing,
    dispatch,
    redirectToSuccessPage
  })

  const formikProps = {
    initialValues: initialValues,
    onSubmit: submitForm,
    validationSchema: cardSchema(),
    validateOnMount: true
  }

  React.useEffect(() => {
    setIsProcessing(isUpdating)
  }, [isUpdating])

  if (hasLookback) {
    return <Redirect to={landingPage} />
  }

  return (
    <Flex direction="column">
      <LookBackHeader>
        <LookBackTitleInfo>
          <LookBackHeaderTitle>Lookback</LookBackHeaderTitle>
          <LookBackHeaderInfo>
            <LookBackMessage>
              Zego will search for eligible payments dating back up to 24 months
              and report those payments to LevelCredit. LevelCredit will then
              report the payment information to the credit bureaus, resulting in
              those previous payments counting toward your collective credit
              score.
            </LookBackMessage>
            <LookBackMessageMob>
              Zego via LevelCredit will report eligible past payments dating
              back 24 months, counting them toward your collective credit score.
            </LookBackMessageMob>
          </LookBackHeaderInfo>
        </LookBackTitleInfo>
        <LookBackPaymentContainer>
          <LookBackPaymentInfo>one payment of</LookBackPaymentInfo>
          <LookBackPaymentAmount>
            $49<LookBackPaymentAmountDecimal>.95</LookBackPaymentAmountDecimal>
          </LookBackPaymentAmount>
        </LookBackPaymentContainer>
      </LookBackHeader>
      <LevelCreditFormContainer direction="column">
        <Formik {...formikProps}>
          {({
            handleChange,
            handleSubmit,
            errors,
            touched,
            values,
            ...formik
          }) => {
            const hasError: string => boolean = getHasError(errors, touched)
            const disableSubmit = Object.keys(errors).length > 0
            const getErrorDetails: string => Object = getErrorInfo(
              errors,
              touched
            )
            const getNamedProps = (name: string) => {
              return {
                error: hasError(name),
                value: values[name],
                initialDate: values[name],
                initialValue: values[name],
                name
              }
            }
            return (
              <React.Fragment>
                <LookbackFields
                  onChange={handleChange}
                  getErrorDetails={getErrorDetails}
                  getNamedProps={getNamedProps}
                  values={values}
                  errors={errors}
                  {...formik}
                />
                <Hidden smDown>
                  {getButtons({
                    handleSubmit,
                    disableSubmit,
                    isProcessing,
                    onCancelClick
                  })}
                </Hidden>
                <MobileBtnsPortal>
                  {getButtons({
                    handleSubmit,
                    disableSubmit,
                    isProcessing,
                    onCancelClick
                  })}
                </MobileBtnsPortal>
              </React.Fragment>
            )
          }}
        </Formik>
      </LevelCreditFormContainer>
      <LogoWrap marginTop="60px" justifyRight>
        <LevelCreditLogoDiv />
      </LogoWrap>
      <Flex marginBottom="30px">
        <FooterInfo>
          Zego will provide your payment information to LevelCredit for
          processing
        </FooterInfo>
      </Flex>
    </Flex>
  )
}

export default LookbackPurchase
