// @flow
import { connect } from 'react-redux'
import { withRouter, BrowserRouter } from 'react-router-dom'
import { createIsUpdatingSelector } from 'zego-shared/store/isUpdating/selectors'
import {
  updateBankAccount,
  updateCard,
  redirectOnMethodCreateSuccess
} from 'zego-shared/store/payments/methods/actions'
import { getPaymentOptions } from 'zego-shared/store/payments/options/selectors'
import { methodTypes } from '@utils/view'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'

import AddPaymentMethod from './AddPaymentMethod'

const updatingSelector = createIsUpdatingSelector(['bankaccount', 'card'])
const fetchingSelector = createIsFetchingSelector(['paymentoptions'])

export const mapStateToProps = (state: Object) => {
  return {
    isSaving: updatingSelector(state),
    isFetching: fetchingSelector(state),
    paymentOptions: getPaymentOptions(state)
  }
}

export const mapDispatchToProps = (
  dispatch: Function,
  {
    match: {
      params: { residentId, methodType }
    },
    history: { push }
  }: BrowserRouter
) => {
  return {
    redirectOnMethodCreateSuccess: (url: string) => {
      dispatch(redirectOnMethodCreateSuccess(() => push(url)))
    },
    saveActions: {
      [methodTypes.bankAccount]: (payload: Object) =>
        dispatch(updateBankAccount(residentId, payload)),
      [methodTypes.card]: (payload: Object, isDebitCard: boolean) =>
        dispatch(updateCard(payload, isDebitCard))
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethod)
)
