import styled from '@emotion/styled'
import { AppBar, Toolbar } from '@material-ui/core'

export const StyledLogo = styled('img')`
  height: 34px;
  position: relative;
  top: -3px;
  margin-right: 0.5rem;
`

export const StyledAppBar = styled(AppBar)`
  grid-area: head;
  box-shadow: none;
  background-color: transparent;
  height: ${({ height }) => `${height}px`};
`

export const Typography = styled('div')`
  flex: 1;
  padding-top: ${({ theme }) => theme.space.s20};
`

export const StyledToolbar = styled(Toolbar)`
  padding: 0 ${({ theme }) => theme.space.s75};
  width: 100%;
  max-width: 1400px;
  margin: auto;
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding: 0 ${({ theme }) => theme.space.s15};
  }
`
