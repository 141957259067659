// @flow
import { connect } from 'react-redux'
import { withFetchData } from 'zego-shared/views'
import MyAccount from './MyAccount'
import {
  getUserBio,
  getUserPreferredContactMethod,
  getUserEmail,
  getUserPhoneNumber,
  getUserProfilePicture,
  getUserFirstName,
  getUserLastName,
  getUserLogin,
  getIsPayLeasePmsUser
} from 'zego-shared/store/authorization/selectors'
import { getUnitName } from 'zego-shared/store/select/selectors'
import {
  updateUser,
  deleteResident
} from 'zego-shared/store/authorization/actions'
import { getReminders } from 'zego-shared/store/payments/paymentReminders/actions'
import { validatePhoneNumber } from '../../../utils/validationUtils'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import {
  getDeliveryPreference,
  updateDeliveryPreference
} from 'zego-shared/store/users/profile/actions'
import {
  getDeliveryPreferenceFromState,
  getReasonCodeFromState
} from 'zego-shared/store/users/profile/selectors'
import { getPreviousUrl } from 'zego-shared/store/previousUrl/selectors'
import { createIsUpdatingSelector } from 'zego-shared/store/isUpdating/selectors'

const updatingUserSelector = createIsUpdatingSelector(['user'])
const myAccountFetchedData = withFetchData(MyAccount)

export const mapStateToProps = (state: Object) => ({
  firstName: getUserFirstName(state),
  lastName: getUserLastName(state),
  bio: getUserBio(state),
  contactMethod: getUserPreferredContactMethod(state),
  email: getUserEmail(state),
  unitName: getUnitName(state),
  phone: getUserPhoneNumber(state),
  login: getUserLogin(state),
  profilePicture: getUserProfilePicture(state),
  deliveryPreference: getDeliveryPreferenceFromState(state),
  reasonCode: getReasonCodeFromState(state),
  prevUrl: getPreviousUrl(state),
  isPayleasePmsUser: getIsPayLeasePmsUser(state),
  isUpdating: updatingUserSelector(state)
})

export const mapDispatchToProps = (dispatch: Function, { history }: Object) => {
  return {
    fetchData: () => {
      dispatch(getReminders())
    },
    updateResident: (details: Object) => {
      const {
        bio,
        phone,
        firstName,
        lastName,
        email,
        contactMethod,
        login
      } = details
      const contact_methods = ['in-app', contactMethod]
      dispatch(
        updateUser(
          {
            phone_number: validatePhoneNumber(phone) ? phone : '(000) 000-0000',
            first_name: firstName,
            last_name: lastName,
            email,
            login,
            user_metadata: { bio, contact_methods }
          },
          false
        )
      )
    },
    showError: (message: string) => dispatch(showSnackbar(message, 'error')),
    getDeliveryPreference: () => dispatch(getDeliveryPreference()),
    closeAccount: () => dispatch(deleteResident(history)),
    updateDeliveryPreference: (billingType: string, reasonCode: string) =>
      dispatch(updateDeliveryPreference({ billingType, reasonCode }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(myAccountFetchedData)

