// @flow
export const selectItem = (() => {
  let keyBuffer = ''
  let timeout = null
  return (key: string, menuItems: HTMLCollection<HTMLElement> | null) => {
    keyBuffer += key
    if (menuItems) {
      const menuItem = Array.prototype.find.call(menuItems, item =>
        item.innerText.toLowerCase().startsWith(keyBuffer.toLowerCase())
      )
      if (menuItem) {
        menuItem.focus()
        menuItem.scrollIntoView(true)
      }
    }
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      keyBuffer = ''
      timeout = null
    }, 500)
  }
})()
