// @flow
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { buildPaymentFields } from '@utils/view'
import { updateDetails } from 'zego-shared/store/payments/autopay/actions'
import { fetchPaymentFixAutoPayFees } from 'zego-shared/store/payments/fees/actions'

import FixedAmount from './FixedAmount'

export const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchFees: (paymentFields: Object) =>
      dispatch(fetchPaymentFixAutoPayFees(buildPaymentFields(paymentFields))),
    updateDetails: (detail: Object) => dispatch(updateDetails(detail))
  }
}

export default withRouter(connect(null, mapDispatchToProps)(FixedAmount))
