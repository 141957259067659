// @flow
import React from 'react'
import { getFullDateTimeInPacificTimezone } from 'zego-shared/utils/date'
import Flex from '@Common/Flex'
import { PaymentInfoStyled, PaymentNotes } from '../../styles'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { useTheme } from '@material-ui/core'
import {
  PaymentInfoCardStyled,
  PStyled,
  H4Styled,
  FlexStyled,
  TotalStyled,
  Line
} from './styles'

type Option = {
  value: string,
  label: string
}

type Info = {
  recipient: {
    propertyName: string,
    address: string,
    companyName: string
  },
  accountInfo: {
    fullName: string,
    methodName: string,
    payPalEmail: string
  },
  payment: {
    lineItems: Option[],
    methodFee: Option,
    total: string,
    expressPayFee: Option
  }
}

type Props = {
  paymentInfo: Info,
  width: string,
  oneColumn?: boolean,
  paymentNotes?: string
}

const PaymentItem = ({ label, value, elementId, oneColumn }) => {
  return (
    <FlexStyled justifySpaceBetween>
      <PStyled oneColumn={oneColumn}>{label}</PStyled>
      <PStyled dark id={elementId} oneColumn={oneColumn}>
        {value}
      </PStyled>
    </FlexStyled>
  )
}

export const PaymentInfo = ({
  paymentInfo,
  width,
  oneColumn,
  paymentNotes
}: Props) => {
  const { recipient, accountInfo, payment } = paymentInfo
  const { fullName, methodName, payPalEmail } = accountInfo
  const { lineItems, methodFee } = payment

  let { expressPayFee } = payment
  // Don't show express pay fee on checkout card if zero
  if (expressPayFee && '$0.00' === expressPayFee.value) {
    expressPayFee = undefined
  }

  const today = getFullDateTimeInPacificTimezone(new Date())
  const theme = useTheme()
  const isSsoPayments = theme.ux.isSSO
  const payContainerPadding = isSsoPayments
    ? `${theme.space.s30} ${theme.space.s30} 0`
    : undefined
  const payTitleMarginBottom = isSsoPayments ? 0 : undefined

  return (
    <PaymentInfoStyled oneColumn={oneColumn}>
      {!oneColumn && (
        <PaymentInfoCardStyled>
          <FlexStyled direction="column" bottomMargin>
            <H4Styled>Recipient</H4Styled>
            <PStyled id="propName">{recipient.propertyName}</PStyled>
            <PStyled id="propAddress">{recipient.address}</PStyled>
            <PStyled id="companyName">{recipient.companyName}</PStyled>
          </FlexStyled>
          <FlexStyled direction="column">
            <H4Styled>Account Info</H4Styled>
            {!payPalEmail && <PStyled id="resName">{fullName}</PStyled>}
            <PStyled id="payMethodName">{methodName}</PStyled>
            {payPalEmail && <PStyled id="payPalEmail">{payPalEmail}</PStyled>}
          </FlexStyled>
        </PaymentInfoCardStyled>
      )}
      <PaymentInfoCardStyled
        noShadow={isWidthDown('md', width) && oneColumn}
        innerPadding={payContainerPadding}>
        {oneColumn && (
          <>
            <Flex justifySpaceBetween>
              <FlexStyled flex="50%" direction="column" bottomMargin>
                <H4Styled oneColumn={oneColumn}>Recipient</H4Styled>
                <PStyled id="propName" oneColumn={oneColumn}>
                  {recipient.propertyName}
                </PStyled>
                <PStyled id="propAddress" oneColumn={oneColumn}>
                  {recipient.address}
                </PStyled>
              </FlexStyled>
              <FlexStyled flex="50%" direction="column" alignTextRight>
                <H4Styled oneColumn={oneColumn}>Account Info</H4Styled>
                {!payPalEmail && (
                  <PStyled
                    id="resName"
                    alignTextRight
                    capitalize
                    oneColumn={oneColumn}>
                    {fullName}
                  </PStyled>
                )}
                <PStyled
                  id="payMethodName"
                  alignTextRight
                  capitalize
                  oneColumn={oneColumn}>
                  {methodName}
                </PStyled>
                {payPalEmail && (
                  <PStyled
                    id="payPalEmail"
                    alignTextRight
                    capitalize
                    oneColumn={oneColumn}>
                    {payPalEmail}
                  </PStyled>
                )}
              </FlexStyled>
            </Flex>
            <Line />
          </>
        )}
        <FlexStyled justifySpaceBetween marginBottom={payTitleMarginBottom}>
          <H4Styled oneColumn={oneColumn}>Payment</H4Styled>
          <PStyled id="currDate" oneColumn={oneColumn}>
            {today}
          </PStyled>
        </FlexStyled>
        {lineItems.map(({ label, value }) => {
          return (
            <PaymentItem
              elementId={label.replace(/\s/g, '')}
              label={label}
              value={value}
              key={label}
              oneColumn={oneColumn}
            />
          )
        })}
        {methodFee && (
          <PaymentItem
            elementId={methodFee.label.replace(/\s/g, '')}
            label={methodFee.label}
            value={methodFee.value}
            oneColumn={oneColumn}
          />
        )}
        {expressPayFee && (
          <PaymentItem
            elementId={expressPayFee.label.replace(/\s/g, '')}
            label={expressPayFee.label}
            value={expressPayFee.value}
            oneColumn={oneColumn}
          />
        )}
        <TotalStyled justifySpaceBetween>
          <H4Styled dark>Total</H4Styled>
          <H4Styled id="otpTotal" dark>
            {payment.total}
          </H4Styled>
        </TotalStyled>

        {paymentNotes && (
          <PaymentNotes justifySpaceBetween>
            <H4Styled>Notes</H4Styled>
            <p id="notes">{paymentNotes}</p>
          </PaymentNotes>
        )}
      </PaymentInfoCardStyled>
    </PaymentInfoStyled>
  )
}

export default withWidth()(PaymentInfo)
