// @flow
import styled from '@emotion/styled'
import Card from '@Common/Card'
import Flex from '@Common/Flex'
import { convertHexToRGB } from '@utils/view'
import IconButton from '@material-ui/core/IconButton'

export const ReadMoreStyled = styled('span')`
  & a {
      color: ${({ theme }) => theme.palette.secondary.dark};
      margin-left: 5px;
      text-decoration: underline;
      text-align: right;
  }
`

export const ModalBackDropStyled = styled('div')`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) =>
    convertHexToRGB(theme.palette.common.white, 0.85)};
  position: absolute;
`

export const ModalContentStyled = styled(Flex)`
  height: 100%;
  width: 100%;
  & > div {
    z-index: 1;
  }
`

export const ReadMoreCard = styled(Card)`
  display: flex;
  flex-direction: row;
  border-radius: 0 45px 0 45px;
  height: fit-content;
  min-height: 200px;
  max-height: 590px;
  max-width: 600px;
  width: 60%;
  position: relative;
  padding: 3%;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: 80%;
    padding: 5%;
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding: 10% 5% 10% 5%;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    padding: 15% 2% 10% 2%;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
  }
`

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 30px;
  top: 30px;
  & svg {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
export const TitleTextStyled = styled('div')`
  color: ${({ theme }) => theme.palette.common.midnight};
  display: block;
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  margin-bottom: 30px;
  text-overflow: ellipsis;
  text-align: center;
`
export const MemoTextStyled = styled('div')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  text-overflow: ellipsis;
  text-align: center;
  overflow-y: auto;
`