// @flow
import React from 'react'
import { MaskInputStyled } from './styles'

type Props = {
  InputProps?: Object
}
const MaskInput = ({ InputProps = {}, ...props }: Props) => {
  return (
    <MaskInputStyled
      {...props}
      InputProps={{
        classes: {
          input: 'mask-input'
        },
        ...InputProps
      }}
    />
  )
}

export default MaskInput
