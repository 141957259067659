// @flow
import styled from '@emotion/styled'
import DayPicker from 'react-day-picker'

export const DayPickerStyled = styled(DayPicker)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  & .DayPicker-wrapper {
    padding: 0 1em 1em;
    overflow: hidden;
    outline: none;
  }

  & .DayPicker-NavBar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    transform: translateY(17px);
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: -2em -1em;
      height: calc(100% + 4em);
      background-color: ${({ theme }) => theme.palette.neutral.cool};
      pointer-events: none;
    }
    & .DayPicker-NavButton {
      top: 0;
      background-image: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      font-size: ${({ theme }) => theme.custom.font.size.H5};
      outline: none;
      position: relative;
      z-index: 1;
      color: ${({ theme, disableNavBtns }) =>
        disableNavBtns
          ? theme.palette.neutral.dark
          : theme.palette.primary.main};
      line-height: 16px;
      ${({ theme, disableNavBtns }) => disableNavBtns && `pointer-events:none`};

      &--prev {
        left: 0;
        margin-right: 0;
        &::before {
          content: '‹';
          height: fit-content;
        }
      }
      &--next {
        right: 0;
        &::before {
          content: '›';
          height: fit-content;
        }
      }
    }
  }

  & .DayPicker-Caption {
    padding: 0;
    margin: 0;
    position: relative;
    pointer-events: none;

    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${({ theme }) => theme.palette.common.midnight};
      line-height: 14px;
    }
  }

  & .DayPicker-Months {
    pointer-events: none;
  }

  & .DayPicker-Month {
    margin: 0;
    width: 100%;
    z-index: 1;
  }

  & .DayPicker-Week {
    z-index: 1;
    pointer-events: visible;
    &:first-child .DayPicker-Day {
      padding-top: ${({ theme }) => theme.space.s20};
    }
  }

  & .DayPicker-Weekday {
    padding-top: 1em;
    height: 8px;
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme }) => theme.custom.font.size.xxs};
    line-height: 10px;
    text-align: center;
  }

  & .DayPicker-Day {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme }) => theme.custom.font.size.xs};
    line-height: 12px;
    border-radius: 0;
    box-sizing: border-box;
    outline: none;
    padding: 0.1em;
    &:hover {
      background-color: transparent !important;
    }

    &--disabled {
      cursor: not-allowed;
      color: ${({ theme }) => theme.palette.neutral.main};
    }
    &--isSelected:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
      & div {
        background-color: ${({ theme }) => theme.palette.primary.main};
      }
      color: ${({ theme }) => theme.palette.common.white};
    }
    &--outside {
      color: transparent;
      background-color: transparent;
      cursor: not-allowed;
      & div {
        font-size: ${({ theme }) => theme.custom.font.size.md};
        color: ${({ theme }) => theme.palette.neutral.dark};
      }
    }
    & div {
      height: 21px;
      width: 21px;
      min-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
    }
  }
`
