// @flow
export const FAP = 'FAP'
export const VAP = 'VAP'
export const VAP_AND_FAP = 'VAP_AND_FAP'
export const NO_AUTOPAY = 'NO_AUTOPAY'
export const MY_FULL_BAL = 'My Full Balance'
export const FREQUENCIES = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  BIANNUALLY: 'BIANNUALLY',
  ANNUALLY: 'ANNUALLY'
}

export type AutoPayState = 'VAP' | 'FAP' | 'VAP_AND_FAP' | 'NO_AUTOPAY'
