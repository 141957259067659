// @flow
import styled from '@emotion/styled'
import { Typography, makeStyles } from '@material-ui/core'
import Button from '@Common/Button'
import { TextInput, CheckBox } from '@Common/Form'

export const useStyles = makeStyles({
  formContainer: {
    padding: '0 15px'
  }
})

export const TitleText = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  line-height: 1.5;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space.s25};
`

export const StyledTextInput = styled(TextInput)`
  margin-bottom: ${({ theme }) => theme.space.s10};
`

export const StyledCheckBox = styled(CheckBox)`
  color: ${({ theme }) => theme.palette.common.midnight};
  margin-top: ${({ theme }) => theme.space.s25};
  margin-bottom: ${({ theme }) => theme.space.s40};

  &.MuiFormControlLabel-root {
    align-items: flex-start;
  }

  & .MuiFormControlLabel-label {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    line-height: 1.5;
  }
`

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.secondary.dark};
  text-decoration: underline;
`

export const StyledButton = styled(Button)`
  margin: 0;
  text-transform: none;
`
