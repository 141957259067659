import styled from '@emotion/styled'
import LookBackBlueTab from '@images/credit_reporting/lookback_blue_tab_bg.png'
import LookBackCalTab from '@images/credit_reporting/lookback_right_tab_bg.png'
import LookBackAdBg from '@images/credit_reporting/lookback_ad_bg.png'
import learnMoreBg from '@images/credit_reporting/lookback_go.png'
import LookBackSSO from '@images/credit_reporting/lookback_sso.png'

export const LookbackAdBase = styled.div`
  height: 150px;
  max-width: 100%;
  background-image: url(${LookBackAdBg});
  background-repeat: no-repeat;
  background-size: 100% 150px;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 143px;
    margin-left: -9px;
    background-position: left top;
    background-size: initial;
    background-image: url(${LookBackBlueTab});
  }

  @media (max-width: 480px) {
    height: 123px;
    background-image: url(${LookBackAdBg});
    background-size: 100% 123px;
    width: 100%;
    padding-right: 10px;
    box-sizing: unset;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `background-image: url(${LookBackSSO});
    background-size: 100% 100%;
    height: 108px;
    overflow: hidden;
    max-width: initial;
    margin: 0 -2px;

    & > a >*{
      transform: scale(0.9);
      transform-origin: top left;
    }
  `}
`

export const LookbackAdMain = styled.div`
  height: 150px;
  width: 98%;
  margin-left: 9px;
  background-image: none;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 135px;
    width: 100%;
    background-image: none;
    background-color: white;
    background-position: right bottom;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  }

  ${({ theme }) => [theme.breakpoints.only('sm')]} {
    background-size: 100% auto;
  }

  @media (max-width: 480px) {
    height: 123px;
    box-shadow: none;
    background-color: transparent;
  }
`

export const LookbackAdContent = styled.div`
  height: 150px;
  width: 100%;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 135px;
    width: 100%;
    background-position: top right;
    background-repeat: no-repeat;
    background-image: url(${LookBackCalTab});
  }

  @media (max-width: 480px) {
    height: 123px;
    background-image: none;
  }
`

export const LookbackAdText = styled.div`
  padding-top: ${({ theme }) => theme.space.s33};
  height: 74px;
  margin-left: ${({ theme }) => theme.space.s20};
  margin-bottom: ${({ theme }) => theme.space.s21};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 57px;
    margin-bottom: ${({ theme }) => theme.space.s27};
    padding-top: ${({ theme }) => theme.space.s16};
  }

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    height: 41px;
    margin-bottom: ${({ theme }) => theme.space.s17};
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    height: auto;
    padding: ${theme.space.s10} 0;
    margin: 0 ${theme.space.s10};
    `}
`

export const MainText = styled.div`
  font-size: 18px;
  line-height: 18px;
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  margin-bottom: ${({ theme }) => theme.space.s2};
`

export const SubText = styled.div`
  line-height: 16px;
  height: 20px;
  line-height: 20px;
  font-size: ${({ theme }) => theme.custom.font.size.H6};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`
export const SupText = styled.sup`
  position: absolute;
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
`

export const Space = styled.div`
  @media (max-width: 1024px) and (min-width: 480px) {
    height: 8px;
  }
`

export const GoButton = styled.div`
  height: 30px;
  width: 116.5px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
  line-height: 16px;
  margin-left: ${({ theme }) => (theme.ux.isSSO ? 10 : 20)}px;
  background-repeat: no-repeat;
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  padding: ${({ theme }) =>
    `${theme.space.s6} ${theme.space.s10} ${theme.space.s6} 0`};
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    top: 0;
    left: 0;
    height: 30px;
    width: 0;
    transition: 0.25s width;
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 100%;
    background-image: url(${learnMoreBg});
    background-size: 100% 100%;
    z-index: 1;
  }

  &:hover :before,
  &:focus :before {
    width: 100%;
  }
`
