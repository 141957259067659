import React from 'react'
import { Grid, GridItem } from '@Common/Grid'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  DealCard,
  DealContent,
  DealHeader,
  DealImage,
  DealFooter,
  DetailsLink,
  RedeemLink,
  DetailsDiv,
  RedeemDiv
} from './styles'

export default ({ unitId, deal }) => {
  const {
    data: {
      image,
      redirect_url: url,
      discount_description,
      title,
      short_title,
      category,
      description,
      manufacturer
    },
    id
  } = deal;

  const dealTabId = `deal_${id}`
  const dealUrl = url && (url.toLowerCase().substring(0, 4) !== 'http') ?
    `http://${url}` : url

  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <>
      <DealCard>
        <DealHeader>{discount_description || manufacturer}</DealHeader>
        <Grid auto>
          <GridItem
            columnStart={1}
            columnSpan={isXs ? 12 : 5}
            rowStart={1}
            rowSpan={isXs ? 6 : 12}
            alignCenter={isXs ? true : false}
            style={isXs ? {} : { borderRight: `2px solid ${theme.palette.neutral.warm}` }}>
            <DealImage imageSrc={image}/>
          </GridItem>
          <GridItem
            columnStart={isXs ? 1 : 6}
            columnSpan={isXs ? 12 : 7}
            rowStart={isXs ? 7 : 1}
            rowSpan={isXs ? 6 : 12}>
            <DealContent>
              {title && <h3>{title}</h3>}
              {(short_title || category) && (<h4>{short_title || category}</h4>)}
              {description && (<p>{description}</p>)}
              <DealFooter>
                <DetailsDiv><DetailsLink to={`/units/${unitId}/marketplace/deal/${id}`}>View details</DetailsLink></DetailsDiv>
                <RedeemDiv><RedeemLink className="redeemLink" href={dealUrl} target={dealTabId}>Redeem Now</RedeemLink></RedeemDiv>
              </DealFooter>
            </DealContent>
          </GridItem>
        </Grid>
      </DealCard>
    </>
  )
}
