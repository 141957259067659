import { fetchPmMemosSuccess } from '../actions'

const initialState = {
  memos: []
}
  
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case fetchPmMemosSuccess().type:
      return { 
        ...state, 
        memos: action.payload
      }
    default:
      return state
  }
}

export default reducers  