// @flow
import axios from 'axios'
import { initiatePayPalPayment } from 'zego-shared/store/payments/oneTimePayment/graphql/mutations'
import { print } from 'graphql'

const createOrder = async (payload: Object, createOrderErrorMsg: Function) => {
  const { variables, headers } = payload
  const query = print(initiatePayPalPayment)
  const url = window._env_.REACT_APP_GRAPHQL_ENDPOINT
  const operationName = null

  const body = {
    query,
    variables,
    operationName
  }

  try {
    const response = await axios.post(url, body, { headers })
    return response.data.data.initiatePayPalPayment.payPalOrderId
  } catch (error) {
    createOrderErrorMsg()
  }
}

export default createOrder
