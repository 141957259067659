// @flow
import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getPaymentMethods } from '../../methods/selectors'
import { getBankAccountAutoPays } from '../../autopay/selectors'
import { getPlaidBalanceChecks } from '../../options/selectors'

const isBankAccount = paymentMethod =>
  paymentMethod && 'BankAccount' === paymentMethod.__typename

const mapPaymentMethodWithItemId = (item, paymentMethod) => ({
  itemId: item.itemId,
  institutionId: item.institutionId,
  name: item.accountName,
  bankName: paymentMethod.bankName,
  type: paymentMethod.type,
  lastFour: paymentMethod.lastFour,
  accountHolderName: paymentMethod.accountHolderName,
})

const shouldAddAccount = (item = null, isBalanceChecksEnabled = false, paymentMethod = {}) => {
  const shouldAddItem = item && isBankAccount(paymentMethod)

  return shouldAddItem && (isBalanceChecksEnabled || paymentMethod.isTan)
}

export const hasPlaid = (state: Object) => get(state, 'payments.options.plaid')

export const achPlaidOnly = (state: Object) =>
  get(state, 'payments.options.achPlaidOnly')

export const getLinkToken = (state: Object) =>
  get(state, 'payments.plaid.token')

export const hasError = (state: Object) => get(state, 'payments.plaid.error')

export const getPlaidItems = (state: Object) =>
  get(state, 'payments.plaid.items')

export const getAccountsGroupedByInstitution = createSelector(
  [hasPlaid, getPaymentMethods, getPlaidItems],
  (isPlaidEnabled, { byId = {} } = {}, items = []) => {
    if (!isPlaidEnabled) return []

    const accountsByInstitution = new Map()

    items.forEach(item => {
      const method = byId[item.zegoAccountId]

      if (!method) return

      if ('string' == typeof method.bankName) {
        const institutionName = method.bankName.toLowerCase()

        if (!accountsByInstitution.has(institutionName)) {
          accountsByInstitution.set(institutionName, {
            institutionName,
            accounts: []
          })
        }

        // flow complains about destructuring directly from the object
        // returned by the get function because it may be undefined,
        // so, we need to check the value first.
        const institution = accountsByInstitution.get(institutionName)

        if (institution && Array.isArray(institution.accounts)) {
          institution.accounts.push({ ...method })
        }
      }
    })

    return [...accountsByInstitution.values()]
  }
)

export const getReauthItemsByZegoAccountId = createSelector(
  [hasPlaid, getPlaidItems],
  (isPlaidEnabled = false, items = []) => {
    if (!isPlaidEnabled) return {}
    return items.filter(item => item.reauthRequired).reduce((byId, item) => {
      byId[item.zegoAccountId] = item
      return byId
    }, {})
  }
)

export const getReauthItemByZegoAccountId = createSelector(
  [
    (_state, zegoAccountId) => zegoAccountId,
    getReauthItemsByZegoAccountId,
    getPaymentMethods,
    getPlaidBalanceChecks
  ],
  (zegoAccountId, itemsByZegoAccountId = {}, { byId = {} } = {}, isBalanceChecksEnabled = false) => {
    if (!zegoAccountId) return null
  
    const item = itemsByZegoAccountId[zegoAccountId]
    const paymentMethod = byId[zegoAccountId]

    if (shouldAddAccount(item, isBalanceChecksEnabled, paymentMethod)) {
      return mapPaymentMethodWithItemId(item, paymentMethod)
    }

    return null
  }
)

export const getReauthItemsInAutoPays = createSelector(
  [getBankAccountAutoPays, getReauthItemsByZegoAccountId, getPaymentMethods, getPlaidBalanceChecks],
  (autoPays = [], itemsByZegoAccountId = {}, { byId = {} } = {}, isBalanceChecksEnabled = false) => {
    const items = []
    autoPays.forEach(({ paymentMethodId, total, nextRun, type }) => {
      const item = itemsByZegoAccountId[paymentMethodId]
      const paymentMethod = byId[paymentMethodId]
      
      if (shouldAddAccount(item, isBalanceChecksEnabled, paymentMethod)) {
        items.push({  
          ...mapPaymentMethodWithItemId(item, paymentMethod),
          total,
          nextRun,
          isVariable: 'Variable' === type
        })
      }
    })

    return items
  }
)
