import gql from 'graphql-tag'

export const GET_UNIT = 'getUnit'

export const getUnitUuIdQuery = gql`
  query($unitUuid: ID!) {
    ${GET_UNIT}(id: $unitUuid) {
      property {
        id
        name
      }
    }
  }
`