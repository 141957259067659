import Styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'
import { FlexItem } from '@Common/Flex'

export const Wrapper = Styled('div')`
  padding: 16px 0;
  background-color: ${({ theme }) => theme.palette.neutral.main};
`

export const Logo = Styled('img')`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 8px;
  display: block;
  height: 40px;
  width: 40px;
`

export const CloseBtn = Styled(IconButton)`
  margin-left: -${({ theme }) => theme.space.s15};
  margin-right: -${({ theme }) => theme.space.s5};
`

export const Text = Styled(FlexItem)`
  padding-left: ${({ theme }) => theme.space.s15};
  color: ${({ theme }) => theme.palette.common.grey}
`

export const Title = Styled('h4')`
  margin: 0;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size:  ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
`
