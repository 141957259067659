import { call, put, takeLatest, select } from 'redux-saga/effects'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import { getToken } from '../../authorization/selectors'
import { getUnitPropertyId } from 'zego-shared/store/select/selectors'
import { showSnackbar } from '../../snackbar/actions'

import {
  fetchMarketplaceDeals,
  fetchMarketplaceDealsStart,
  fetchMarketplaceDealsSuccess,
  fetchMarketplaceDealsError
} from '../actions'

export function* getMarketplaceDeals() {
  const page = 1
  try {
    const propertyId = yield select(getUnitPropertyId)
    if (propertyId < 0) return

    yield put(fetchMarketplaceDealsStart())
    const authToken = yield select(getToken)
    const response = yield call(api.getMarketplaceDeals, propertyId, page, authToken)
    yield put(fetchMarketplaceDealsSuccess(response))
  } catch (error) {
    yield put(showSnackbar('failed to fetch marketplace deals', 'error'))
    yield put(fetchMarketplaceDealsError(error))
    log(error)
  }
}

export function* watchGetMarketplaceDeals() {
  yield takeLatest(fetchMarketplaceDeals().type, getMarketplaceDeals)
}

export default [
  getMarketplaceDeals(),
  watchGetMarketplaceDeals()
]
