import { combineReducers } from 'redux'
import {
  CREDIT_REPORTING,
  creditReportingOptInSuccess,
  creditReportingReactivateSuccess,
  creditReportingOptOutSuccess,
  lookbackPurchaseSuccess,
  getReportedLevelCreditTransSuccess,
  getReportedLookbackTransSuccess,
  clearDetails
} from '../actions'
import withFetch from '../../../helpers/reducers/withFetch'

const initialState = {
  eligible: false,
  levelCreditEligible: false,
  optedIn: false,
  levelCreditOptedIn: false,
  levelCreditOptInState: 'inactive',
  creditReportingDetails: null,
  reportedTransactions: [],
  lookbackReportedTransactions: [],
  defaultEmail: '',
  lookBackEligible: false,
  purchaseDetails: null
}

const creditReportingConfig = (state = initialState, action) => {
  switch (action.type) {
    case creditReportingOptInSuccess().type:
    case creditReportingReactivateSuccess().type:
    case creditReportingOptOutSuccess().type:
    case lookbackPurchaseSuccess().type:
      return action.payload
    case getReportedLevelCreditTransSuccess().type:
      return {
        ...state,
        reportedTransactions: action.payload
      }
    case getReportedLookbackTransSuccess().type:
      return {
        ...state,
        lookbackReportedTransactions: action.payload
      }
    case clearDetails().type:
      return {
        ...state,
        optedIn: false,
        creditReportingDetails: null,
        levelCreditOptedIn: false,
        levelCreditOptInState: 'inactive',
        purchaseDetails: null
      }
    default:
      return state
  }
}

export default combineReducers({
  config: withFetch(CREDIT_REPORTING)(creditReportingConfig)
})
