// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import Popover from '@material-ui/core/Popover'
import Calendar from '@Common/Calendar'

export const ScheduleInputStyled = styled.div`
  position: relative;
  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const LeftSectionStyled = styled(Flex)`
  padding: ${({ theme }) =>
    `${theme.space.s30} ${theme.space.s15} ${theme.space.s15}`};
  background: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.common.white};
  height: 100%;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    padding: ${({ theme }) => theme.space.s15};
  }
  & .radio-label {
    height: 12px;
    color: ${({ theme }) => theme.palette.common.white};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: 14px;
  }
  & .radio svg {
    height: 12px;
    width: 12px;
    color: ${({ theme }) => theme.palette.common.white};
  }
`
export const ActionBtnsStyled = styled(Flex)`
  padding: ${({ theme, hasLeftSection }) => !hasLeftSection && theme.space.s15};

  & button {
    width: fit-content;
    color: ${({ hasLeftSection, theme }) =>
      hasLeftSection
        ? theme.palette.common.white
        : theme.palette.primary.main};
    font-size: 10px;
    background-color: transparent;
    min-width: unset;
    padding: 0;
    margin: 0;
    text-transform: capitalize;
    & :hover {
      background-color: transparent;
    }
    &:last-of-type {
      font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
      margin-left: 20px;
    }
  }
`
export const CalenderWrapperStyled = styled(Flex)`
  position: relative;
  background-color: transparent;
  width: ${({ hasLeftSection }) => (hasLeftSection ? '363px' : 'fit-content')};
  max-width: 100%;
  min-height: 150px;

  &::before {
    content: '';
    position: absolute;
    right: 14px;
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.palette.neutral.cool};
    ${({ theme }) => [theme.breakpoints.down('sm')]} {
      display: none;
    }
  }
  & .calendar-nav {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: -12px -12px;
      height: calc(100% + 20px);
      background-color: ${({ theme }) => theme.palette.neutral.cool};
      pointer-events: none;
    }
  }

  & .datetime-picker {
    & button,
    & .current-date {
      z-index: 1;
      pointer-events: ${({ theme, disableAll }) =>
        disableAll ? 'none' : 'initial'};
    }

    & button::before {
      font-size: ${({ theme }) => theme.custom.font.size.sm};
      background: ${({ theme }) => theme.palette.tertiary.cool};
      color: ${({ theme, disableAll }) =>
        disableAll ? theme.palette.neutral.dark : theme.palette.primary.main};
      line-height: 16px;
    }

    & tbody tr td {
      color: ${({ theme }) => theme.palette.primary.main};
      font-size: ${({ theme }) => theme.custom.font.size.xs};
      line-height: 12px;
      padding: ${({ theme }) => theme.space.s10};

      &.selected:not(.next):not(.prev) {
        background-color: ${({ theme, disableAll }) =>
          !disableAll && theme.palette.primary.main};
        color: ${({ theme, disableAll }) =>
          !disableAll && theme.palette.common.white} !important;
      }
      &.disabled {
        cursor: not-allowed;
        color: ${({ theme }) => theme.palette.neutral.main};
      }

      ${({ disableAll, theme }) =>
        disableAll &&
        `cursor: not-allowed;
       color: ${theme.palette.neutral.main} !important;
    `}
    }
  }
`

export const PopoverStyled = styled(Popover)`
  & .MuiPopover-paper {
    overflow: visible;
  }
`

export const CalendarStyled = styled(Calendar)`
  ${({ hasLeftSection }) => !hasLeftSection && `width: 100%;`}
`

export const RadioButtons = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    flex-direction: row;
    margin-bottom: ${({ theme }) => theme.space.s20};
  }

  & > label {
    margin: 0;

    & span {
      padding-left: 0;
    }
  }
`
