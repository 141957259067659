import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { Fab } from '@material-ui/core'

export const SideNav = styled('div')`
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    position: fixed;
    bottom: ${({ bottomPos }) => bottomPos || 0};
    right: 0;
    width: 100vw;
    z-index: 1101;
  }
`

export const Wrapper = styled('div')`
  position: relative;
  .active {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    display: ${({ showOnMobile }) => (showOnMobile ? 'block' : 'none')};
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-radius: 100vw 0 0 0;
    text-align: right;
    padding: 120px 30px;
  }
`

export const StyledFab = styled(Fab)`
  position: absolute;
  right: 30px;
  bottom: 30px;
  display: none;
  background-color: ${({ theme }) => theme.palette.primary.main};
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    display: inline-flex;
  }
`

export const StyledLink = styled(NavLink)`
  display: block;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  height: 18px;
  margin-bottom: 18px;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    color: ${({ theme }) => theme.palette.primary.main};
    & svg {
      display: none;
    }
  }
  & svg {
    margin-left: -20px;
    margin-right: 10px;
  }
`
