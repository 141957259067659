// @flow
import {
  DialogTitle,
  DialogContentText,
  Grid,
  Typography,
  IconButton
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/ReportProblemOutlined'
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import { Link } from 'react-router-dom'
import { ButtonWrapperStyled as ButtonWrapper } from '../styles'
import AutoPayAd from '@views/Payments/AutoPay/AutoPayAd'

export const SuccessHeaderStyled = styled(Flex)`
  margin-bottom: ${({ theme }) => (theme.ux.isSSO ? `10px` : `30px`)};
  min-height: fit-content;
  width: 100%;

  & button {
    height: fit-content;
  }
`

export const Header = styled('div')`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H3 : theme.custom.font.size.H2};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: 43px;
  height: 36px;
  min-height: fit-content;
  margin: 0;
  margin-bottom: 10px;
  width: max-content;
  position: relative;

  & span {
    position: relative;
  }
  &::before {
    height: ${({ theme }) => (theme.ux.isSSO ? '9px' : '14px')};
    left: 0;
    right: 5px;
    bottom: ${({ theme }) => (theme.ux.isSSO ? '-4px' : '-14px')};
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    transform: translateX(10px);
    content: '';

    ${({ theme }) => [theme.breakpoints.only('xs')]} {
      height: 8px;
    }
  }
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    &&::before {
      bottom: 0px;
    }
  }
`

export const ButtonWrapperStyled = styled(ButtonWrapper)`
  margin: 0;
  & button {
    margin-left: 0;
    margin-right: ${({ theme }) => theme.space.s20};
  }
`

export const ConfirmationWrapperStyled = styled(Flex)`
  width: calc(100% - 45px);
`

export const ConfirmationStyled = styled(Flex)`
  height: 53px;
  min-height: fit-content;
  color: ${({ theme }) => theme.palette.neutral.deep};
  margin-top: ${({ theme }) => (theme.ux.isSSO ? 0 : theme.space.s30)};

  & p {
    width: 753px;
    font-size: ${({ theme }) =>
      theme.ux.isSSO ? theme.custom.font.size.H5 : theme.custom.font.size.H4};
    line-height: 20px;
    margin: 0;
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};

    ${({ theme }) => [theme.breakpoints.only('xs')]} {
      font-size: ${({ theme }) => theme.custom.font.size.H6};
    }
  }
`

export const ConfirmationText = styled('span')`
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? '11px' : theme.custom.font.size.sm};
  line-height: 20px;
  margin: 0;
  word-break: break-word;
  width: 100%;
`

export const ConfirmationId = styled('span')`
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
`

export const TurnAutoPayCTAStyled = styled(Flex)`
  height: fit-content;
  min-height: 150px;
  margin: ${({ theme }) =>
    `${theme.space.s75} 0 ${theme.space.s75} ${theme.space.s24}`};

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin: ${({ theme }) => `${theme.space.s30} 0 ${theme.space.s75}`};
    min-height: 100px;
  }
`

export const TurnAutoPayStyled = styled(Flex)`
  height: fit-content;
  min-height: 44px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  max-width: 374px;
  margin-right: ${({ theme }) => theme.space.s10};
  & p {
    line-height: 22px;
    margin: 0;
    color: inherit;
  }

  & span {
    color: inherit;
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  }
`

export const DialogTitleStyled = styled(DialogTitle)`
  & h2 {
    height: fit-content;
    min-height: 32px;
    color: ${({ theme }) => theme.palette.common.midnight};
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    line-height: 32px;
    text-align: center;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    & h2 {
      min-height: 24px;
      width: 100%;
      font-size: ${({ theme }) => theme.custom.font.size.H6};
      line-height: 28px;
    }
  }
`

export const DialogTextStyled = styled(DialogContentText)`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
`

export const BottomContainer = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
  justify-content: center;
`

export const WarningIconStyled = styled(WarningIcon)`
  color: ${({ theme }) => theme.palette.tertiary.main};
`

export const BottomText = styled(Typography)`
  font-size: 11px;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`

export const DesktopAdsContainer = styled(Flex)`
  display: block;
`

export const FlexContainerForLcEnabaled = styled(Flex)`
  flex-basis: 65%;
`

export const SummaryInfoStyled = styled(Flex)`
  margin-bottom: ${({ theme }) => (theme.ux.isSSO ? '0' : '100px')};
  justify-content: space-between;
`

export const ShowForMdUp = styled('div')`
  display: block;
  ${({ fixedWidth }) => fixedWidth && 'width: 330px'};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: none;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    margin-left: 10px;
    width: auto;
  `}
`

export const ShowForMdDown = styled('div')`
  display: none;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: block;
    ${({ marginTop }) => marginTop && 'margin-top: 20px'}
  }
`

export const BoxShadowWrap = styled('div')`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    box-shadow: 0 2px 4px rgba(14, 20, 39, 0.5);
    border-radius: 5px;
  }
`

export const ReturnToSummaryText = styled('div')`
  display: none;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  position: absolute;
  width: 150px;
  right: 75px;
  margin-top: ${({ theme }) => theme.space.s12};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    right: 35px;
  }
`

export const ReturnToSummary = styled(Link)`
  background-color: ${({ theme }) => theme.palette.tertiary.main};
  height: 44px;
  width: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover > ${ReturnToSummaryText} {
    display: block;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    &:hover + div {
      display: none;
    }
  }
`

export const PrinterIconButton = styled(IconButton)`
  border: 2px solid ${({ theme }) => theme.palette.tertiary.main};
  height: 44px !important;
  width: 44px;
  padding: 0;
  box-sizing: border-box;
  margin-top: 10px;
`

export const AutoPayAdOTP = styled(AutoPayAd)`
  ${({ theme, variant }) =>
    theme.ux.isSSO &&
    !variant &&
    `
      min-width: 250px;
      max-width: 100%;
      margin-left: 0;
      padding-right: 10px;      
    `}
`
