import { LD_INIT } from "./actions";

const initialState = {}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LD_INIT:
            return {
                ...state,
                ld: action.ld
            };
        default:
            return state;
    }
}

export default reducer;