// @flow
import styled from '@emotion/styled'

import { FormGroup } from '@Common/Form'
import Card from '@Common/Card'
import Flex from '@Common/Flex'

export const PayFullBalanceStyled = styled.button`
  height: 12px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 12px;
  line-height: 14px;
  text-decoration: underline;
  margin-top: ${({ theme }) => theme.space.s30};
  cursor: pointer;
  border: none;
  width: fit-content;
  margin: 0;
`

export const BalanceEditStyled = styled(Card)`
  & .MuiCardContent-root {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(100px, 1fr);
    ${({ theme }) => [theme.breakpoints.down('lg')]} {
      &:last-child {
        padding: ${({ theme }) =>
          theme.ux.isSSO ? theme.space.s35 : theme.space.s20};
      }
    }

    ${({ theme }) => [theme.breakpoints.down('xs')]} {
      grid-template-columns: 1fr;
    }
  }
`
export const Balance = styled(`span`)`
  position: relative;
  & span {
    position: relative;
  }
  &::before {
    height: 14px;
    left: ${({ theme }) => (theme.ux.isSSO ? `23px` : `15px`)};
    right: ${({ theme }) => (theme.ux.isSSO ? `20px` : `0`)};
    bottom: -1px;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    transform: translateX(10px);
    content: '';
  }
`

export const FormWrapperStyled = styled('div')(
  ({ theme }) => `
    padding-right: 50px;
    ${theme.breakpoints.down('xs')} {
      padding-right: 0;
    }

    ${
      theme.ux.isSSO &&
      `overflow: auto;
      max-height: 240px;
      `
    }
`
)

export const TotalWrapperStyled = styled('div')(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid ${theme.palette.neutral.main};
    flex-direction: column;
    padding-left: ${theme.space.s30};
    ${theme.breakpoints.down('xs')} {
      padding-left: 0;
      padding-top: ${theme.space.s30};
      border-top: 1px solid ${theme.palette.neutral.main};
      border-left: none;
    }

    ${
      theme.ux.isSSO &&
      `overflow: auto;
      max-height: 240px;
      `
    }
`
)

export const FormHeader = styled.h5`
  color: ${({ theme }) => theme.palette.black};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  line-height: 22px;
  width: 100%;
  margin: ${({ theme }) => `${theme.space.s15} 0 ${theme.space.s20}`};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  &:first-of-type {
    margin-top: 0;
  }

  & span {
    color: ${({ theme }) => theme.palette.primary.main};
    text-align: right;
    float: right;
  }
`
export const FormStyled = styled.form`
  display: grid;
`

export const FormGroupStyled = styled(FormGroup)`
  margin-bottom: ${({ theme }) => theme.space.s20};

  &:last-of-type {
    margin-bottom: 19px;
  }
`
export const PaymentAmountStyled = styled.span`
  color: ${({ theme }) => theme.palette.black};
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  line-height: 29px;
  text-align: right;
  flex: 1;
  display: flex;
  justify-content: ${({ theme }) => (theme.ux.isSSO ? `flex-end` : `center`)};
  align-items: center;
  text-transform: capitalize;
  max-height: ${({ theme }) => (theme.ux.isSSO ? `20%` : `fit-content`)};
  padding: ${({ theme }) => theme.ux.isSSO && `0 25px 80px 0`};
  flex-wrap: wrap;
  width: 100%;
  & span:last-of-type {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme }) => theme.custom.font.size.H3};
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    line-height: 43px;
    word-break: break-all;
  }

  & span {
    text-align: center;
    margin: ${({ theme }) => `0 ${theme.space.s10}`};
  }
`

export const MyBalanceStyled = styled(PaymentAmountStyled)`
  ${({ theme, showPaymentAmount }) =>
    showPaymentAmount &&
    `
  height: 24px;
  color: ${theme.palette.common.grey};
  font-size: ${theme.custom.font.size.H4};
  line-height: 29px;
  margin-bottom: ${theme.space.s30};
  & span:last-of-type{
    color: ${theme.palette.common.grey};
    font-size: ${theme.custom.font.size.H4};
    font-weight: ${theme.custom.font.weight.regular};
    line-height: 29px;
    box-shadow: none;
    &::before {
      background-color: ${theme.palette.common.white};
    }
  }
`}
`
export const IconButtonContainerStyled = styled(Flex)`
  margin: 0;
  width: 100%;
`
