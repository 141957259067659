import { makeStyles } from '@material-ui/core/styles'
import styled from '@emotion/styled'
import Card from '@Common/Card'

export const CardStyled = styled(Card)`
  height: ${({ theme }) => (theme.ux.isSSO ? '210px' : '361px')};
  min-width: ${({ theme }) => (theme.ux.isSSO ? undefined : '408px')};

  & .MuiCardContent-root {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 100%;

    & button {
      text-transform: unset;
      font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    }
  }
`
export const NoMethodStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${({ theme }) => theme.space.s30};
  margin-top: ${({ theme }) => theme.space.s30};
`

export const useStyles = makeStyles(theme => ({
  credit: {
    width: theme.ux.isSSO ? 121 : 151,
    height: theme.ux.isSSO ? 86 : 106
  },
  bank: {
    width: theme.ux.isSSO ? 125 : 155,
    height: theme.ux.isSSO ? 73 : 93,
    marginTop: theme.ux.isSSO ? 14 : 30
  }
}))
