// @flow
import { connect } from 'react-redux'
import WorkOrders from './WorkOrders'
import { getUnitId } from 'zego-shared/store/select/selectors'

export const mapStateToProps = (state: Object) => ({
  unitId: getUnitId(state)
})

export default connect(mapStateToProps)(WorkOrders)
