import { getSelectedUnit, getPMS } from 'zego-shared/store/select/selectors'
import { getProfile } from 'zego-shared/store/authorization/selectors'

export const getLiveAgentProfile = state => {
  const {
    id: unitId,
    name: unitName,
    property: {
      id: propertyId,
      name: propertyName,
      company_id: companyId,
      company_name: companyName,
      company_pms_id: companyPmsId
    } = {}
  } = getSelectedUnit(state)

  const isPayleaseUser = getPMS(state) === 'paylease'

  const {
    sub: userId,
    first_name: firstName,
    last_name: lastName,
    name: fullName,
    email
  } = getProfile(state)

  return {
    email,
    userId,
    firstName,
    lastName,
    fullName,
    unitId,
    unitName,
    propertyName,
    propertyId,
    companyId,
    companyName,
    companyPmsId,
    isPayleaseUser
  }
}
