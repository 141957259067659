import { call, takeLatest, put, select } from 'redux-saga/effects'
import { getPaymentToken } from '../../../authorization/selectors'
import { showSnackbar } from '../../../snackbar/actions'
import { genericErrorMsg } from '../../../../utils/messages'
import api from '../../../../utils/api'
import {
  fetchPaymentsSummaryAction,
  fetchPaymentsSummaryStart,
  fetchPaymentsSummarySuccess,
  fetchPaymentsSummaryError,
} from '../actions'

export function* fetchPaymentsSummary() {
  try {
    const paymentToken = yield select(getPaymentToken)

    yield put(fetchPaymentsSummaryStart())
    const response = yield call(api.fetchPaymentStatus, paymentToken)

    yield put(fetchPaymentsSummarySuccess(response))
  } catch (error) {
    yield put(fetchPaymentsSummaryError(error))
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

function* watchFetchPaymentsSummary() {
  yield takeLatest(fetchPaymentsSummaryAction().type, fetchPaymentsSummary)
}

export default [watchFetchPaymentsSummary()]
