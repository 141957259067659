// @flow
import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import MethodsList from './MethodsList'
import AddPaymentMethod from './AddPaymentMethod'
import MethodDetails from './MethodDetails'

type Props = {
  match: { path: string }
}

const Methods = ({ match: { path } }: Props) => (
  <Switch>
    <Route exact path={path} component={MethodsList} />
    <Route path={`${path}/:methodType/add`} component={AddPaymentMethod} />
    <Route
      exact
      key="method-info"
      path={`${path}/:methodId`}
      component={MethodDetails}
    />
  </Switch>
)

export default withRouter(Methods)
