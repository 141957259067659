// @flow
export const getHasError = (errors: Object, touched: Object) => (
  name: string
): boolean => {
  return Boolean(errors[name]) && touched[name]
}

export const getErrorInfo = (errors: Object, touched: Object) => (
  name: string
): { error?: string, touched: boolean } => {
  return { error: errors[name], touched: touched[name] }
}
