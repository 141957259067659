// @flow
import React from 'react'
import { Hidden } from '@material-ui/core'
import { Ad, GoButton, SubText, MainText, Calendar, Wrapper } from './styles'
import { useSelector } from 'react-redux'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import calendar from '@images/auto_pay_ad/calendar.png'

type Props = {
  hasAutoPay: boolean,
  variant: 'tall' | 'short'
}

const AutoPayAd = (props: Props) => {
  const { hasAutoPay = false, variant = 'short' } = props
  const residentId = useSelector(getUserId)
  const AutoPayUrl = `/payments/${residentId}/auto-pay`

  return (
    <Ad {...props} to={AutoPayUrl}>
      <Calendar src={calendar} />
      <Wrapper>
        {hasAutoPay ? (
          <>
            <SubText>Your next Auto Pay is soon</SubText>
            <MainText>Check them out!</MainText>
          </>
        ) : (
          <>
            <SubText>Make this payment often?</SubText>
            <MainText>Setup an Auto Pay!</MainText>
          </>
        )}
        <Hidden implementation="css" mdDown>
          <GoButton>{variant === 'tall' ? 'Go Now!' : 'GO'}</GoButton>
        </Hidden>
        <Hidden implementation="css" lgUp>
          <GoButton>GO</GoButton>
        </Hidden>
      </Wrapper>
    </Ad>
  )
}

export default AutoPayAd
