// @flow
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Hidden, useTheme } from '@material-ui/core'
import Flex from '@Common/Flex'
import Button from '@Common/Button'
import { Carousel } from '@gozego/nebula-web'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import { renderMethodDetails, renderOptionDetails } from './MethodDetails'
import Plaid from '../../Plaid'
import PaymentMethodIcon from '../PaymentMethodIcon'
import { sortMethods, constants } from '../utils'
import { paymentsMethodsData } from 'zego-shared/store/payments/methods/selectors'
import {
  Header,
  MethodsCarousel,
  CardStyled,
  AvailablePaymentOptions,
  ExistingPaymentOptions,
  SortButton,
  CardTitle,
  Buttons
} from './styles'
import PayPalButton from '../../common/PayPalButton/PayPalButton'

type Props = {
  paymentOptions: Object[],
  paymentTotal: string,
  onCancel: Function,
  onMethodClick: Function,
  paymentMethodProps: Object,
  onAddPaymentMethod: Function,
  currentMethod: Object,
  createPayPalOrder: Function,
  onPayPalApprove: Function
}

const MorePaymentMethods = ({
  paymentOptions,
  onMethodClick,
  onCancel,
  paymentMethodProps,
  onAddPaymentMethod,
  currentMethod,
  createPayPalOrder,
  onPayPalApprove
}: Props) => {
  const paymentMethods = useSelector(paymentsMethodsData)
  const [selectedMethod, setSelectedMethod] = useState()
  const [currentOption, setCurrentOption] = useState()
  const [sortCriteria, setSortCriteria] = useState(constants.DEFAULT)
  const theme = useTheme()
  const isSSO = theme.ux.isSSO

  useEffect(() => {
    const cardOption =
      paymentOptions.find(({ typeName }) => typeName === 'DebitCard') ||
      paymentOptions.find(({ typeName }) => typeName === 'CreditCard')

    setSelectedMethod(currentMethod)
    currentMethod || showOptionDetails(cardOption)
    //eslint-disable-next-line
  }, [])

  const triggerNextStep = () => {
    onMethodClick(selectedMethod.id)
  }

  const addMethod = () => {
    const method = paymentMethodProps[currentOption.name]
    onAddPaymentMethod(method)
  }

  const showOptionDetails = option => {
    setSelectedMethod()
    setCurrentOption(option)
  }

  const showMethodDetails = method => {
    setCurrentOption()
    setSelectedMethod(method)
  }

  const carouselContent = (
    <Flex>
      <ExistingPaymentOptions>
        {sortMethods(paymentMethods, sortCriteria).map(method => (
          <PaymentMethodIcon
            {...method}
            key={method.id}
            existing
            onClick={() => showMethodDetails(method)}
            selected={selectedMethod?.id === method.id}
          />
        ))}
      </ExistingPaymentOptions>

      <AvailablePaymentOptions>
        {sortMethods(paymentOptions, sortCriteria).map(option => {
          const card = (
            <PaymentMethodIcon
              {...option}
              key={option.name}
              onClick={() => showOptionDetails(option)}
              selected={currentOption?.name === option.name}
            />
          )

          return option.typeName !== 'BankAccount' ? (
            card
          ) : (
            <Plaid
              key={option.name}
              switchToManual={() => showOptionDetails(option)}
              render={() => card}
            />
          )
        })}
      </AvailablePaymentOptions>
    </Flex>
  )

  const buttons = (
    <Buttons>
      <Button onClick={onCancel}>{!isSSO && 'Exit &'} Cancel Payment</Button>

      {selectedMethod ? (
        <Button primary long onClick={triggerNextStep}>
          Use {selectedMethod?.type}
        </Button>
      ) : (
        currentOption &&
        (currentOption.name === 'PayPal' ? (
          <PayPalButton
            onApprove={onPayPalApprove}
            createOrder={createPayPalOrder}
            long
          >
            Authorize PayPal
          </PayPalButton>
        ) : (
          <Button primary long onClick={addMethod}>
            Add {currentOption?.name}
          </Button>
        ))
      )}
    </Buttons>
  )

  return (
    <>
      <CardStyled>
        <Header>
          <CardTitle>Payment Method Options</CardTitle>
          <Flex alignSelf="end">
            <SortButton
              selected={sortCriteria === constants.EASY_SETUP}
              onClick={() => setSortCriteria(constants.EASY_SETUP)}
            >
              Easy Setup
            </SortButton>
            <SortButton
              selected={sortCriteria === constants.SPEED}
              onClick={() => setSortCriteria(constants.SPEED)}
            >
              Fast Processing
            </SortButton>
            <SortButton
              selected={sortCriteria === constants.LOW_FEES}
              onClick={() => setSortCriteria(constants.LOW_FEES)}
            >
              Cheapest
            </SortButton>
          </Flex>
        </Header>

        <MethodsCarousel>
          <Carousel width="100%" objectCarousel={carouselContent} />
        </MethodsCarousel>

        {renderMethodDetails(selectedMethod)}
        {renderOptionDetails(currentOption)}
      </CardStyled>

      <Hidden smDown>{buttons}</Hidden>
      <Hidden mdUp>
        <MobileBtnsPortal>{buttons}</MobileBtnsPortal>
      </Hidden>
    </>
  )
}

export default MorePaymentMethods
