import { call, takeLatest, put, select } from 'redux-saga/effects'
import { print } from 'graphql'

import api from 'zego-shared/utils/api'

import {
  fetchClientNotesAction,
  fetchClientNotesError,
  fetchClientNotesStart,
  fetchClientNotesSuccess
} from '../actions'

import * as queries from '../graphql/queries'

import { showSnackbar } from '../../../snackbar/actions'
import { failedToFetchClientNotes } from '../../../../utils/messages'
import { getGraphQlData } from '../../../../utils/graphql'
import { getPaymentToken } from '../../../authorization/selectors'

export function* fetchClientNotes() {
  try {
    yield put(fetchClientNotesStart())

    const paymentToken = yield select(getPaymentToken)
    const response = yield call(
      api.graphqlQuery,
      { paymentToken },
      print(queries.getClientNotes)
    )

    const payload = yield call(getGraphQlData, response, 'getClientNotes')
    yield put(fetchClientNotesSuccess(payload))
  } catch (error) {
    yield put(fetchClientNotesError(error))
    yield put(showSnackbar(failedToFetchClientNotes, 'error'))
  }
}

function* watchFetchClientNotes() {
  yield takeLatest(fetchClientNotesAction().type, fetchClientNotes)
}

export default [watchFetchClientNotes()]
