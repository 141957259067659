// @flow
import styled from '@emotion/styled'
import { DialogTitle, Dialog } from '@material-ui/core'
import Flex from '@Common/Flex'
import Card from '@Common/Card'
import { Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import GreenBG from '@images/credit_reporting/green_bg.png'
import Check from '@images/credit_reporting/check.png'
import BlueCheck from '@images/credit_reporting/check_blue.png'
import LClogo from '@images/credit_reporting/level_credit_logo.png'
import LClogoMob from '@images/credit_reporting/level_credit_logo_mob.png'
import { Link } from 'react-router-dom'
import Button from '@Common/Button'
import { CheckBox } from '@Common/Form'

export const LevelCreditLogo = styled('div')`
  width: ${({ theme }) => (theme.ux.isSSO ? 88 : 151)}px;
  height: ${({ theme }) => (theme.ux.isSSO ? 23 : 40)}px;
  background-image: url('${LClogo}');
  background-size: cover;
  text-align: right;
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
  transform: scale(0.72);
  transform-origin: bottom; `}
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    background-image: url('${LClogoMob}');
    width: 101px;
    height: 27px;
  }
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-bottom: ${({ theme }) => theme.space.s20};
  }
`

export const CRPageTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H3 : theme.custom.font.size.H2};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: ${({ theme }) => (theme.ux.isSSO ? 36 : 58)}px;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H3};
    line-height: 43px;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    line-height: 29px;
  }
`

export const LCNotice = styled('div')`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.md : theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  line-height: 1.19;
`

export const IntroTitle = styled('div')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H5 : theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: ${({ theme }) => (theme.ux.isSSO ? 22 : 29)}px;
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`

export const IntroSection = styled(Flex)`
  width: 100%;
  max-width: ${({ theme }) => (theme.ux.isSSO ? '427px' : '555px')};
  @media (max-width: 1260px) {
    max-width: ${({ theme }) => (theme.ux.isSSO ? '427px' : '455px')};
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    max-width: 373px;
    ${({ isOptedIn }) => isOptedIn && 'margin-top: 230px;'}
  }
`

export const IntroText = styled('div')`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? '14px' : theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  margin-top: ${({ theme }) => theme.space.s5};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ isCreditBlock }) => isCreditBlock && `max-width: 98%`};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    ${({ isCreditBlock }) => isCreditBlock && `max-width: 455px`};
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme, isCreditBlock }) =>
      isCreditBlock ? theme.custom.font.size.H6 : theme.custom.font.size.sm};
    ${({ isCreditBlock }) => isCreditBlock && `max-width: 95%`};
  }
  & sup {
    position: absolute;
  }
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    font-size: ${theme.custom.font.size.md}
  `}
`

export const Bold = styled('span')`
  font-weight: ${({ theme }) =>
    theme.ux.isSSO
      ? theme.custom.font.weight.light
      : theme.custom.font.weight.bolder};
  color: ${({ theme }) => theme.palette.common.midnight};
`

export const AdFeaturesWrap = styled(Flex)`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s33};
  }
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    position: relative;
    width: 100%; `}
`

export const FeaturesUnderlay = styled('div')`
  position: absolute;
  width: ${({ theme }) => (theme.ux.isSSO ? '800px' : '72%')};
  height: ${({ theme }) => (theme.ux.isSSO ? '180px' : '283px')};
  max-width: 990px;
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s10 : theme.space.s37};
   {
    /* choose either green background image or the provided background color */
  }
  background-image: ${({ color }) => (!color ? `url(${GreenBG})` : undefined)};
  background-color: ${({ color }) => (color ? color : undefined)};
  opacity: ${({ color }) => (color ? '0.75' : undefined)};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: 73%;
    height: 360px;
    margin-top: 137px;
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    width: 95%;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    width: 100%;
    height: 274px;
    left: 0;
  }
`

export const FeaturesInner = styled('div')`
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) =>
    theme.ux.isSSO ? '18px 0 18px 18px' : '59px 0 40px 38px'};
  height: ${({ theme }) => (theme.ux.isSSO ? '180px' : '283px')};
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s10 : theme.space.s37};
  z-index: 1;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s10};
    padding-top: ${({ theme }) => theme.space.s20};
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    height: 200px;
    margin-top: 0;
    padding-top: ${({ theme }) => theme.space.s25};
    padding-left: ${({ theme }) => theme.space.s10};
    padding-bottom: ${({ theme }) => theme.space.s10};
  }
`

export const Feature = styled(Flex)`
  width: 100%;
  min-height: 40px;
  margin-bottom: ${({ theme }) => theme.space.s15};
`

export const FeatureRight = styled(Flex)`
  max-width: 440px;
  line-height: 15px;
  width: ${({ theme }) => (theme.ux.isSSO ? '340px' : 'initial')};
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    max-width: 250px;
  }
`

export const FeatureCheckMark = styled('div')`
  width: 27px;
  height: 20px;
  background-image: ${({ color }) =>
    `url(${color === 'blue' ? BlueCheck : Check})`};
  background-size: 100% 100%;
  margin-right: ${({ theme }) => theme.space.s15};
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    min-width: 30px;
    min-height: 25px;
    margin-right: ${({ theme }) => theme.space.s20};
  }
`

export const FeatureTitle = styled('div')`
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H5 : theme.custom.font.size.H22};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  color: ${({ theme }) => theme.palette.common.midnight};
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`

export const FeatureSubTitle = styled('div')`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.md : theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  line-height: 22px;
  margin-top: ${({ theme }) => theme.space.s5};
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: 18px;
  }
`
export const LevelCreditHeaderStyled = styled.h3`
  height: 43.2px;
  width: 416px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H3};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: 43px;
  margin: 0 0 5px;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    line-height: 29px;
    height: 29px;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    width: 278px;
  }
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
      margin-bottom: 0;
      height: auto;
      line-height: 1;
    `}
`

export const LearnMore = styled('div')`
  margin-left: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s40 : theme.space.s50};
  height: 15px;
  margin-top: ${({ theme }) => (theme.ux.isSSO ? '0px' : theme.space.s60)};
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    position: relative;  
    top: -10px;  `}
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s65};
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    margin-top: ${({ theme }) => theme.space.s24};
  }
  & a {
    color: ${({ theme }) => theme.palette.secondary.dark};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    text-decoration-line: underline;
    ${({ theme }) => [theme.breakpoints.down('xs')]} {
      font-size: ${({ theme }) => theme.custom.font.size.sm};
    }
  }
`

export const ShowForMdUp = styled('div')`
  display: block;
  ${({ zIndex }) => zIndex && 'z-index: 1'};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: none;
  }
`

export const ShowForMdDown = styled(Flex)`
  display: none;
  ${({ zIndex }) => zIndex && 'z-index: 1'};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: block;
    margin-left: 10px;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    margin-left: -10px;
    margin-right: -10px;
    ${({ isOptedIn, isActive }) =>
      isOptedIn &&
      isActive &&
      `position: absolute;
       left: 0;
       right: 0;
       width: 100%;
    `}
`

export const LookbackAdTabMob = styled(Flex)`
  display: none;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: block;
    max-width: 455px;
    margin-top: 41px;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    margin-top: 31px;
  }
`

export const FooterNotice = styled('div')`
  width: 100%;
  height: ${({ theme }) => (theme.ux.isSSO ? '10px' : '15px')};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.xs};
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s7 : theme.space.s10};
  margin-bottom: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s5 : theme.space.s10};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s95};
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    width: 80%;
    margin-top: ${({ theme }) => theme.space.s73};
  }
}
`

export const BodyTextWrap = styled.div`
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s37 : theme.space.s40};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.sm : theme.custom.font.size.H5};
  color: ${({ theme }) => theme.palette.common.midnight};
  max-width: 590px;
  @media (max-width: 1260px) {
    max-width: 453px;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    max-width: 315px;
  }
`

export const BodyText = styled.div`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.sm : theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  line-height: 1.37;
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    margin-top: ${({ theme }) => theme.space.s15};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: 14px;
  }
`

export const StyledLink = styled(Link)`
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  color: ${({ theme }) => theme.palette.secondary.dark};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  line-height: 14px;
  text-decoration: underline;
`

export const OptOutText = styled.div`
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.xs : theme.custom.font.size.sm};
  line-height: 1.67;
`

export const SingleColumnFlexWrap = styled(Flex)`
  flex: 1;
  margin-right: ${({ theme }) => theme.space.s13};
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s18 : theme.space.s48};
  margin-bottom: ${({ theme }) => (theme.ux.isSSO ? 0 : theme.space.s100)};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme, isOptedIn }) => isOptedIn && theme.space.s26};
  }
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    width: 100%;
    margin-top: ${({ theme, isOptedIn }) => !isOptedIn && theme.space.s30};
    margin-bottom: ${({ theme, isOptedIn }) => !isOptedIn && theme.space.s30};
  }
`

export const FooterWrap = styled(Flex)`
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s23 : theme.space.s68};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s33};
  }
`

export const LCStyledButton = styled(Button)`
  margin-left: ${({ theme }) => theme.space.s10};
  width: 175px;
  height: 35px;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: 236px;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    width: 314px;
  }
`
export const LCButtonWrap = styled(Flex)`
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.space.s30};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    justify-content: space-between;
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    flex-direction: column-reverse;
    min-height: 100px;
    margin-top: 0;
  }
`

export const LevelCreditSubHeaderStyled = styled.h6`
  height: ${({ theme }) => theme.space.s24};
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.md : theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  line-height: ${({ theme }) => theme.space.s24};
  margin: 0px;
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    width: 315px !important;
  }
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: ${({ theme }) => theme.space.s20};
    width: 513px;
    height: ${({ theme }) => theme.space.s20};
  }
`

export const HeaderDiv = styled('div')`
  height: ${({ theme }) => (theme.ux.isSSO ? 'auto' : '72px')};
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    height: 90px !important;
  }
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: 420px !important;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    width: 315px !important;
  }
`

export const HeaderContainer = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space.s25};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-bottom: 0px;
  }
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    align-items: flex-end;
    margin-bottom: ${theme.space.s10};
    `};
`

export const LevelCreditFormContainer = styled(Flex)`
  box-shadow: 0px 2px 4px rgba(14, 20, 39, 0.5);
  border-radius: 5px;
  padding: ${({ theme }) => `${theme.space.s40} ${theme.space.s28}`};
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin-bottom: ${({ theme }) => theme.space.s48};
  }
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    padding: ${theme.space.s15} ${theme.space.s24};
    height: 350px;
    overflow: scroll;
    `};
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    height: ${({ theme }) => theme.ux.isSSO && '100%'};
  }
`

export const LevelCreditLogoDiv = styled('div')`
  width: ${({ theme }) => (theme.ux.isSSO ? 88 : 151)}px;
  height: ${({ theme }) => (theme.ux.isSSO ? 23 : 40)}px;
  background-image: url('${LClogo}');
  background-size: cover;
  text-align: right;
  ${({ theme }) => theme.ux.isSSO && `margin-bottom: ${theme.space.s20};`};
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    display: none;
  }
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-bottom: ${({ theme }) => theme.space.s20};
    width: 105px;
    height: 28px;
  }
`

export const StyledTooltip = withStyles(theme => ({
  tooltip: {
    position: 'relative',
    backgroundColor: theme.palette.common.iceberg,
    color: theme.palette.common.midnight,
    fontSize: theme.custom.font.size.sm,
    border: `1px solid ${theme.palette.secondary.light}`,
    padding: `11px ${theme.space.s8} 11px ${theme.space.s8}`,
    top: '-0.4em',
    borderRadius: '0px',
    maxWidth: '240px',
    '&::before': {
      content: '""',
      backgroundColor: theme.palette.common.iceberg,
      position: 'absolute',
      transform: 'rotate(45deg)',
      width: '10px',
      height: '10px',
      top: '-5px',
      right: '2%',
      borderLeft: `1px solid ${theme.palette.secondary.light}`,
      borderTop: `1px solid ${theme.palette.secondary.light}`
    }
  }
}))(Tooltip)

export const Divider = styled.div`
  padding-bottom: 1px;
  background-color: ${({ theme }) => theme.palette.neutral.cool};
  margin-top: ${({ theme }) => theme.space.s24};
  margin-bottom: ${({ theme }) => theme.space.s37};
`

export const LeaseEndWrap = styled(Flex)`
  justify-content: start;
  margin-top: ${({ theme }) => theme.space.s30};
  height: ${({ theme }) => theme.space.s17};
  & div {
    color: ${({ theme }) => theme.palette.common.grey};
    font-size: ${({ theme }) => theme.custom.font.size.md};
    font-weight: 500 !important;
    line-height: 17px !important;
    position: relative;
    top: -4px;    
    left: -20px;
    width: 230px;
  }
  & .MuiSvgIcon-root {
    margin-left: ${({ theme }) => theme.space.s5};
    margin-top: 3px;  
    fill: ${({ theme }) => theme.palette.common.midnight};
    width: ${({ theme }) => theme.space.s16};
    height ${({ theme }) => theme.space.s16};
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    margin-top: -5px;    
    & div {
      margin-top: 5px !important;
      font-size: ${({ theme }) => theme.custom.font.size.md} !important;
    }
  }
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: max-content;
    & div {
      margin-top: 4px !important;
      font-size: ${({ theme }) => theme.custom.font.size.sm};
    }
  }
`

export const LevelCreditCheckboxStyled = styled(CheckBox)`
  & > span {
    color: ${({ theme }) => theme.palette.common.grey} !important;
    font-size: ${({ theme }) => theme.custom.font.size.md} !important;
    font-weight: 500 !important;
    line-height: 17px !important;
  }
`

export const LevelCreditTermsAndCondionsStyled = styled('div')`
  height: ${({ theme }) => theme.space.s17};
  padding-top: ${({ theme }) => theme.space.s16};  
  padding-left: ${({ theme }) => theme.space.s35};
  padding-right: ${({ theme }) => theme.space.s55};
  position: relative;
  top: 26px;
  margin-bottom: ${({ theme }) => theme.space.s100};
  & > p {
    color: ${({ theme }) => theme.palette.common.grey} !important;
    font-size: ${({ theme }) => theme.custom.font.size.md} !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    position: relative;
    top: -45px;
    left: ${({ theme }) => theme.space.s30};
  }
  & a {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    top: ${({ theme }) => !theme.ux.isSSO && '110px !important!'};
  }
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    top: 42px;
    margin-bottom: 110px;
    & p {
      font-size: ${({ theme }) => theme.custom.font.size.sm} !important;
    }
`

export const TermsAndConditionsStyled = styled('div')`
  opacity: 0.5px;
  background: ${({ theme }) => theme.palette.common.iceberg};
  min-height: 161px;
  margin-left: -28px;
  margin-right: -28px;
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    height: 270px;
    height: ${({ theme }) => theme.ux.isSSO && '100px'};
  }
`

export const ZegoTermsAndCondionsStyled = styled('div')`
  padding-top: ${({ theme }) => theme.space.s30};
  padding-bottom: ${({ theme }) => theme.space.s30};
  padding-left: ${({ theme }) => theme.space.s35};
  padding-right: ${({ theme }) => theme.space.s55};
  height: 51px;
  position: relative;
  & p {
    color: ${({ theme }) => theme.palette.common.grey} !important;
    font-size: ${({ theme }) => theme.custom.font.size.md} !important;
    font-weight: 300 !important;
    line-height: 17px !important;
    position: relative;
    top: -45px;
    left: ${({ theme }) => theme.space.s30};
  }
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    & p {
      font-size: ${({ theme }) => theme.custom.font.size.sm} !important;
    }
`

export const ZegoCheckboxStyled = styled(CheckBox)`
  min-height: fit-content;
  display: flex;
  align-items: flex-start;
  & input[type='checkbox'] {
    height: 0px !important;
    font-size: 40px;
  }
  & span {
    color: ${({ theme }) => theme.palette.common.grey} !important;
    font-size: ${({ theme }) => theme.custom.font.size.md} !important;
    font-weight: 300 !important;
    line-height: 17px !important;
  }
`
export const LookBackHeaderDiv = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  padding-bottom: ${({ theme }) => theme.space.s35};
`

// Lookback

export const LookbackMoreSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.s60};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    flex-direction: column-reverse;
    max-width: 455px;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
  }
`

export const ChartGraph = styled.div`
  max-width: 489px;
  height: 292px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    max-width: 455px;
    max-height: 272px;
    height: auto;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    min-width: 317px;
    min-height: 189px;
  }
`

export const ChartGraphText = styled.div`
  max-width: 281px;
  height: 333px;
  width: 100%;
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    max-width: 100%;
    height: auto;
    margin-bottom: ${({ theme }) => theme.space.s20};
  }
`

export const LookbackSectionTitle = styled.div`
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  margin-bottom: ${({ theme }) => theme.space.s10};
`

export const ChartGraphTextTFooter = styled.div`
  margin-top: ${({ theme }) => theme.space.s10};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  color: ${({ theme }) => theme.palette.common.midnight};
`

export const VideoBox = styled.div`
  display: flex;
  justify-content: space-between;
  height: 283px;
  background-color: ${({ theme }) => theme.palette.common.lightBlue};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space.s60};
  padding: ${({ theme }) => `0 ${theme.space.s43}`};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    flex-direction: column-reverse;
    height: auto;
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding: ${({ theme }) => `0 ${theme.space.s28}`};
  }
`

export const VideoBoxLeft = styled.div`
  width: 360px;
  height: 203px;
  margin-top: ${({ theme }) => theme.space.s28};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    max-width: 426px;
    width: 100%;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.md};
  }
`

export const VideoBoxRight = styled.div`
  width: 426px;
  height: 240px;
  margin-top: ${({ theme }) => theme.space.s22};
  iframe {
    width: 100%;
    height: 240px;
  }
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    max-width: 426px;
    width: 100%;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    max-width: 317px;
    height: 179px;
    iframe {
      height: 179px;
    }
  }
`

export const FuturePlansText = styled.div`
  max-width: 844px;
  min-height: 71px;
  margin-bottom: ${({ theme }) => theme.space.s60};
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
`

export const TinyFooterText = styled('div')`
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.xs};
`

export const StyledCard = styled(Card)`
  margin-top: ${({ theme }) => theme.space.s8};
  overflow: scroll;
  height: 340px;
  overflow-x: hidden;
  & div.MuiCardContent-root {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
  }
`
export const LevelCreditFormHeader = styled.h6`
  height: ${({ theme }) => theme.space.s33};
  color: ${({ theme }) => theme.palette.common.grey};
  margin: ${({ theme }) => `0 0 ${theme.space.s20}`};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.md : theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  line-height: ${({ theme }) => theme.space.s33};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: ${({ theme }) => theme.space.s20};
    width: 513px;
    height: ${({ theme }) => theme.space.s20};
  }
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    width: 315px;
  }
`

export const DialogStyled = styled(Dialog)`
  & .dialog-paper {
    width: 850px;
    padding: ${({ theme }) => `${theme.space.s45} ${theme.space.s100}`};
    border-top-right-radius: 3em;
    border-bottom-left-radius: 3em;
  }

  & .dialog-paperWidthSm {
    max-width: 100%;
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    & .dialog-paper {
      width: 90%;
      padding: 5%;
      min-height: 0px;
    }
  }
`

export const DialogTitleStyled = styled(DialogTitle)`
  & h2 {
    height: fit-content;
    min-height: 32px;
    width: 100%;
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    line-height: 32px;
    text-align: center;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    & h2 {
      min-height: 24px;
      width: 100%;
      font-size: ${({ theme }) => theme.custom.font.size.H6};
      line-height: 28px;
    }
  }
`

export const StyledButton = styled(Button)`
  height: 35px;
  margin: 0;
  width: 225px;
  margin-right: ${({ rightMargin }) => rightMargin && '24px'};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin: ${({ theme }) => `${theme.space.s10} 0`};
    margin-right: ${({ rightMargin }) => rightMargin && '24px'};
  }
}
`

export const ModalText = styled.div`
  max-width: 844px;
  text-align: center;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  margin-bottom: ${({ theme }) => theme.space.s40};
`

export const ModalInner = styled(Flex)`
  max-width: 486px;
  min-height: 500px;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
      max-width: 100%;
      min-height: 0px;
    }
  }
`

export const ReviewItem = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.space.s30};
  justify-content: center;
  font-size: ${({ theme }) => theme.custom.font.size.md};
}
`

export const ReviewItemTitle = styled.div`
  text-align: right;
  min-width: 50%;
  max-width: 50%;
  margin-right: ${({ theme }) => theme.space.s35};
  color: ${({ theme }) => theme.palette.common.grey};
`

export const ReviewItemValue = styled.div`
  text-align: left;
  min-width: 50%;
  max-width: 50%;
  color: ${({ theme }) => theme.palette.common.midnight};
`

export const OptOutModalText = styled('div')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H5 : theme.custom.font.size.H4};
  line-height: ${({ theme }) => (theme.ux.isSSO ? 22 : 29)}px;
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
  margin-top: ${({ theme }) => theme.space.s13};
  margin-bottom: ${({ theme }) => theme.space.s13};
`

export const InactiveStatusBox = styled('div')`
  background-color: ${({ theme }) => theme.palette.alert.lightgold};
  width: 565px;
  border-radius: 5px;
  z-index: 1;
  margin-top: ${({ theme }) => theme.space.s27};
  margin-bottom: ${({ theme }) => theme.space.s27};
  padding: ${({ theme }) =>
    `${theme.space.s12} ${theme.space.s28} ${theme.space.s12}`};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: 452px;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    width: 335px;
  }
`

export const InactiveStatusTextBold = styled.div`
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  line-height: 19px;
`

export const InactiveStatusText = styled.div`
  margin-top: ${({ theme }) => theme.space.s2};
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  line-height: 18px;
`

export const InactiveStatusRedHighLight = styled.span`
  color: ${({ theme }) => theme.palette.error.dark};
`

export const InactiveStatusHighLight = styled.span`
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  color: ${({ theme }) => theme.palette.neutral.deep};
`
