import { connect } from 'react-redux'
import Snackbar from './Snackbar'
import { hideSnackbar } from 'zego-shared/store/snackbar/actions'

const mapStateToProps = state => ({
  message: state.snackbar.message,
  show: state.snackbar.show,
  variant: state.snackbar.variant,
  messagesWithTooltip: state.snackbar.messagesWithTooltip
})

const mapDispatchToProps = dispatch => ({
  hideSnackbar: () => dispatch(hideSnackbar())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Snackbar)
