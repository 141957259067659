// @flow
import styled from '@emotion/styled'
import IconButton from '@material-ui/core/IconButton'

import { PaymentMethodCardStyled } from '../styles'

export const MorePaymentMethodCardStyled = styled(PaymentMethodCardStyled)`
  height: fit-content;
  min-height: 381px;
  max-width: 900px;
  border-radius: 0 45px 0 45px;
  & .MuiCardContent-root {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    & p {
      height: 64px;
      min-height: fit-content;
      width: 70%;
      color: ${({ theme }) => theme.palette.common.midnight};
      font-size: ${({ theme }) => theme.custom.font.size.H4};
      line-height: 32px;
      text-align: center;
      margin: 0;
      margin: 54px 0;
    }
  }
`

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 30px;
  top: 30px;
  & svg {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`
