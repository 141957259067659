// @flow
import React from 'react'
import Edit from '@material-ui/icons/Edit'
import { Link } from 'react-router-dom'

import Flex from '@Common/Flex'
import { PaymentCardStyled } from './styles'
import { IconButtonStyled } from '../styles'

type Props = {
  title: string,
  label: string,
  value: string,
  onIconClick?: Function,
  linkTo?: string,
  IconComponent?: Function,
  editable?: Function
}

const PaymentCard = ({
  linkTo,
  title,
  label,
  value,
  onIconClick,
  IconComponent = Edit,
  editable
}: Props) => {
  return (
    <PaymentCardStyled
      CardContentProps={{
        classes: {
          root: 'card-content-root'
        }
      }}>
      <p>{title}</p>
      <Flex alignCenter>
        <p>
          {label} {value && <b>{value}</b>}
        </p>
        {linkTo && editable && (
          <Link to={linkTo} id={`edit${label}Btn`}>
            <IconButtonStyled>
              <IconComponent />
            </IconButtonStyled>
          </Link>
        )}
        {!linkTo && editable && (
          <IconButtonStyled id={`edit${label}Btn`} onClick={onIconClick}>
            <IconComponent />
          </IconButtonStyled>
        )}
      </Flex>
    </PaymentCardStyled>
  )
}

export default PaymentCard
