import React from 'react'
import { generate as shortid } from 'shortid'
import { Link } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import Flex from '@Common/Flex'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import {
  Circle,
  Amount,
  TransactionStatus,
  TransactionType,
  DetailsLink
} from './styles'
import { Rows } from './types'

type Props = {
  match: Match,
  historyData: Rows[],
  voidPayment: number => mixed,
  showAlertModal: boolean,
  theme: Object
}

const CancelTransaction = withAlertModal(({ showAlertModal }) => (
  <>
    {' / '}
    <Link to="#" onClick={showAlertModal}>
      cancel
    </Link>
  </>
))

export const HistoryMobileView = ({
  match,
  historyData,
  voidPayment,
  theme
}: Props) => {
  return historyData.map(
    ({ transId, voidable, date, status, amount, type }) => (
      <Flex justifySpaceBetween marginBottom={theme.space.s30} key={shortid()}>
        <Flex justifySpaceBetween>
          <Circle direction="column" justifyCenter alignCenter>
            {date}
          </Circle>
          <Flex
            direction="column"
            justifySpaceBetween
            marginTop="8px"
            marginBottom="14px">
            <Flex marginBottom="2px">
              <Amount>{amount}</Amount>
              <TransactionType>{type}</TransactionType>
            </Flex>
            <Flex>
              <TransactionStatus>
                {status}
                {voidable && (
                  <CancelTransaction
                    alertTitle="Are you sure you wish to cancel this transaction?"
                    onAlertClickYes={() => voidPayment(transId)}
                  />
                )}
              </TransactionStatus>
            </Flex>
          </Flex>
        </Flex>
        <DetailsLink>
          <Link to={`/payments/${match.params.residentId}/history/${transId}`}>
            details
          </Link>
        </DetailsLink>
      </Flex>
    )
  )
}

export default withTheme(HistoryMobileView)
