// @flow
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { getReauthItemsInAutoPays } from 'zego-shared/store/payments/plaid/selectors'
import {
  REAUTHORIZATION_REQUESTED,
  getSessionStorageItem,
  setSessionStorageItem
} from 'zego-shared/utils/sessionStorage'
import Reauthorization from '../ReauthorizationCard'
import { formatAutoPay } from './utils'
import PlaidLinkUpdate from '../../PlaidLinkUpdate'
import ReauthZegoConfirmationCard from '../ReauthZegoConfirmationCard'
import { accountsMatch } from 'zego-shared/utils/plaid'

const ReauthorizationModal = () => {
  const [show, setShow] = useState(false)
  const [plaidItem, setPlaidItem] = useState({});
  const [updatedPlaidItem, setUpdatedPlaidItem] = useState({
    originalPlaidItem: {},
    id: '',
    bankName: '',
    subtype: '',
    mask: ''
  })
  const autoPays = useSelector(getReauthItemsInAutoPays)

  useEffect(() => {
    const wasShown = Boolean(getSessionStorageItem(REAUTHORIZATION_REQUESTED))
    if (!wasShown && autoPays.length > 0) {
      setShow(true)
      // setting value to '1' as this will be parsed to a boolean.
      setSessionStorageItem(REAUTHORIZATION_REQUESTED, '1')
    }
  }, [autoPays])

  const handleReauth = itemIdForReauth => {
    setPlaidItem(autoPays.filter(item => item.itemId === itemIdForReauth)[0]);
  }

  const handleClose = () => {
    setPlaidItem({})
    setShow(false)
  }

  const handleSuccess = (_itemId, metadata) => {
    const account = metadata.accounts[0]
    if(!accountsMatch({...plaidItem, mask: plaidItem.lastFour}, {...account, institutionId: metadata.institution.institution_id})) {
        setUpdatedPlaidItem({
          originalPlaidItem: plaidItem,
          id: account.id,
          bankName: metadata.institution.name,
          subtype: account.subtype,
          mask: account.mask
        })
    }

    setPlaidItem({})
  }

  const handleContinueAndCloseAfterZegoSuccess = () => {
    setUpdatedPlaidItem({
      originalPlaidItem: {},
      id: '',
      bankName: '',
      subtype: '',
      mask: ''
    })
  }

  const getReauthModal = () => {
    if(autoPays.length) {
      return <Dialog disableEnforceFocus disableAutoFocus open={show}>
      {(<Reauthorization
        autoPays={autoPays.map(formatAutoPay)}
        onClose={handleClose}
        onReauthorize={handleReauth}
        />)}
        {plaidItem ? (
          <PlaidLinkUpdate
            plaidItem={plaidItem}
            onSuccess={handleSuccess}
            onExit={handleClose}
            onError={handleClose}
          />
        ) : null}
      </Dialog>
    }
    return null
  }

  return updatedPlaidItem.id ?
    (<Dialog disableEnforceFocus disableAutoFocus open={show}>
      {(<ReauthZegoConfirmationCard
          updatedPlaidItem={updatedPlaidItem}
          onClose={handleContinueAndCloseAfterZegoSuccess}
          onContinue={handleContinueAndCloseAfterZegoSuccess}
      />)}
    </Dialog>
  ) : getReauthModal()
}

export default ReauthorizationModal
