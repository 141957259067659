// @flow
import React, { useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import { createPlaidBankAccount } from 'zego-shared/store/payments/plaid/actions'
import { getAccountsGroupedByInstitution } from 'zego-shared/store/payments/plaid/selectors'
import { redirectOnMethodCreateSuccess } from 'zego-shared/store/payments/methods/actions'
import { createIsUpdatingSelector } from 'zego-shared/store/isUpdating/selectors'
import { methodTypes } from '@utils/view'
import Modal from '@material-ui/core/Modal'
import Flex from '@Common/Flex'
import { ModalBackDropStyled } from '../PaymentMethods/styles'
import LastStepModal from './LastStepModal'
import LinkedAccountsModal from './LinkedAccountsModal'
import PlaidModal from './PlaidModal'
import PlaidLink from './PlaidLink'
import Unavailable from './Unavailable'
import { useHasPlaid } from './hooks'
import { getBankAccountData } from 'zego-shared/utils/plaid'
import BankAccountWarningModal from './BankAccountWarningModal'

const updatingSelector = createIsUpdatingSelector(['bankaccount'])

const screens = {
  MODAL: 'modal',
  LAST_STEP: 'lastStep',
  FIRST_STEP: 'firstStep',
  LINKED_ACCOUNTS: 'linkedAccounts',
  ERROR: 'error'
}

type Props = {
  switchToManual: Function,
  render: Function,
  onSelectLinkedAccount?: Function,
  onMethodCreateSuccess?: Function
}

const Plaid = ({ switchToManual, render, onSelectLinkedAccount, onMethodCreateSuccess }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const route = useRouteMatch()
  const userId = useSelector(getUserId)
  const isSaving = useSelector(state => updatingSelector(state))
  const { enabled, plaidOnly } = useHasPlaid()
  const accountsByInstitution = useSelector(getAccountsGroupedByInstitution)
  const bankAccountInfo = useRef({ payload: {}, residentId: userId })
  const initialScreen = plaidOnly ? screens.FIRST_STEP : screens.MODAL

  const [showModal, setShowModal] = useState(false)
  const [activeScreen, setActiveScreen] = useState(initialScreen)
  const [showWarning, setShowWarning] = useState(false)

  const onDismiss = () => {
    plaidOnly ? setShowModal(false) : switchToManual()
  }

  const baseUrl = route.url.split(`/${methodTypes.bankAccount}`)[0]

  const reset = () => {
    setActiveScreen(initialScreen)
    setShowModal(false)
  }

  const saveBankAccountAndRedirect = () => {
    const redirectSuccess = () => {
      history.push(baseUrl)
      bankAccountInfo.current = { residentId: userId, payload: {} }
      onMethodCreateSuccess && onMethodCreateSuccess()
      reset()
    }
    const redirectFailure = () => {
      onDismiss()
    }

    dispatch(createPlaidBankAccount(bankAccountInfo.current))
    dispatch(redirectOnMethodCreateSuccess(redirectSuccess, redirectFailure))
  }

  const handleSubmit = accountHolderName => {
    bankAccountInfo.current = {
      ...bankAccountInfo.current,
      payload: {
        ...bankAccountInfo.current.payload,
        accountHolderName
      }
    }
    saveBankAccountAndRedirect()
  }

  const onSuccess = useCallback((linkToken, metadata) => {
    getBankAccountData(metadata, bankAccountInfo )
    const {
      public_token: token,
      institution: { institution_id: institutionId }
    } = metadata
    bankAccountInfo.current = {
      ...bankAccountInfo.current,
      payload: {
        ...bankAccountInfo.current.payload,
        token
      }
    }
    if (shouldDisplayWarningModal(institutionId)) setShowWarning(true)
  }, [])

  const navigateToSummary = () => {
    history.push('/payments/summary')
  }

  const onSelectAccount =
    'function' === typeof onSelectLinkedAccount
      ? methodTypeId => {
          setShowModal(false)
          onSelectLinkedAccount(methodTypeId)
        }
      : null

  const renderLinkedAccountsModal = () => {
    return (
      <PlaidLink
        onSuccess={onSuccess}
        onExit={onPlaidExit}
        render={({ openPlaid, exit, error, success }) => {
          if (error) {
            exit()
            setActiveScreen(screens.ERROR)
            return
          }

          if (success) {
            if (showWarning) return renderBankAccountWarning()
            return renderLastStep()
          }

          return (
            <LinkedAccountsModal
              institutionAccounts={accountsByInstitution}
              onClickExit={onDismiss}
              onClickLinkNewBank={openPlaid}
              onSelectAccount={onSelectAccount}
            />
          )
        }}
      />
    )
  }

  const renderPlaidModal = () => {
    return (
      <PlaidLink
        onSuccess={onSuccess}
        onExit={onPlaidExit}
        render={({ openPlaid, exit, error, success }) => {
          if (error) {
            exit()
            setActiveScreen(screens.ERROR)
          }

          if (success) {
            if (showWarning) return renderBankAccountWarning()
            return renderLastStep()
          }

          return (
            <PlaidModal
              onClickContinue={() => {
                if (accountsByInstitution.length) {
                  setActiveScreen(screens.LINKED_ACCOUNTS)
                  return
                }

                openPlaid()
              }}
              onClickCancel={onDismiss}
            />
          )
        }}
      />
    )
  }

  const renderPlaidScreen = () => {
    if (accountsByInstitution.length) {
      return renderLinkedAccountsModal()
    }

    return (
      <PlaidLink
        onSuccess={onSuccess}
        onExit={onPlaidExit}
        render={({ openPlaid, exit, error, success }) => {
          if (error) {
            exit()
            setActiveScreen(screens.ERROR)
          }

          if (success) {
            if (showWarning) return renderBankAccountWarning()
            return renderLastStep()
          }

          // App crashes without a delay
          setTimeout(openPlaid, 5)
          return <div />
        }}
      />
    )
  }

  const renderLastStep = () => {
    return (
      <LastStepModal
        isSaving={isSaving}
        onClickContinue={handleSubmit}
        onClickCancel={navigateToSummary}
        onClickExit={onDismiss}
      />
    )
  }

  const renderBankAccountWarning = () => {
    return (
      <BankAccountWarningModal
      bankName={bankAccountInfo.current.payload.bankName}
      onContinue={() => setShowWarning(false)}
      onClose={onDismiss}
      />
    )
  }

  const shouldDisplayWarningModal = (institutionId: string) => {
    return ['ins_128026', 'ins_118129', 'ins_3', 'ins_56'].includes(institutionId)
  }

  const renderError = () => {
    return (
      <Unavailable
        onManuallyEnterDetails={onDismiss}
        plaidOnly={plaidOnly}
        onChoosePaymentMethod={reset}
        onClickCancel={navigateToSummary}
      />
    )
  }

  const renderScreen = () => {
    switch (activeScreen) {
      case screens.MODAL:
        return renderPlaidModal()
      case screens.FIRST_STEP:
        return renderPlaidScreen()
      case screens.LAST_STEP:
        return renderLastStep()
      case screens.LINKED_ACCOUNTS:
        return renderLinkedAccountsModal()
      case screens.ERROR:
      default:
        return renderError()
    }
  }

  const open = () => {
    setShowModal(true)
  }

  const onPlaidExit = (error, data) => {
    if (error) {
      setActiveScreen(screens.ERROR)
    }

    onDismiss()
  }

  if (showModal) {
    return (
      <Modal
        open={showModal}
        BackdropComponent={ModalBackDropStyled}
        disableEnforceFocus
        disableAutoFocus>
        <Flex alignCenter fullWidth fullHeight justifyCenter>
          {renderScreen()}
        </Flex>
      </Modal>
    )
  }

  return render({ enabled, open })
}

export default Plaid
