// @flow
import styled from '@emotion/styled'
import { CheckBox } from '@Common/Form'

export const ExpressPayAdStyled = styled.div`
  margin-top: ${({ theme }) => theme.space.s25};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.secondary.light1};
  padding: ${({ theme: { space } }) => `${space.s30}`};

  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    margin-top: 0;
    padding: ${({ theme }) => theme.space.s20};
  }

  & h5 {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 16px;
  }

  & p {
    max-width: 700px;
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: 16px;
    margin-bottom: ${({ theme }) => (theme.ux.isSSO ? `27px` : `37px`)};
    word-break: break-word;
  }
`

export const CheckBoxStyled = styled(CheckBox)`
  margin-bottom: 5px;
`
