// @flow
import compose from 'lodash/flowRight'

export const capitalize = (str: string) => str.toUpperCase()

export const capitalizeWord = (str: string = '') =>
  str.replace(/\b\w/g, l => l.toUpperCase())

export const convertSeparatorToSpace = (
  str: string,
  pattern: RegExp | string
) => str.replace(pattern, ' ')

export const underscoreToSpace = (str: string) =>
  convertSeparatorToSpace(str, /_/g)

export const dashToSpace = (str: string) => convertSeparatorToSpace(str, /-/g)

export const capitalizeUnderscoreToSpace = compose([
  capitalize,
  underscoreToSpace
])

export const capitalizeWordDashToSpace = compose([capitalizeWord, dashToSpace])

export const capitalizeFirstLetter = (str: string = '') =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const isString = (object: Object) =>
  typeof object === 'string' || object instanceof String

export const shortenAndFormatString = (aString: Object, maxLength: number) => {
  const temp = isString(aString) ? aString : `${aString}`

  if (temp.length <= maxLength) {
    return temp
  }

  return `${temp.substr(0, maxLength).trim()}...`
}

export const truncateString = (str: string, len: number = 0) => 
  str.substring(0, len || str.length)
