import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

export const Container = styled('div', {
  shouldForwardProp: prop =>
    ![
      'wrap',
      'fullWidth',
      'justifyLeft',
      'justifyRight',
      'justifyCenter',
      'justifySpaceBetween',
      'alignStart',
      'alignEnd',
      'alignCenter',
      'alignStretch',
      'alignSelf',
      'fullWidth',
      'fullHeight',
      'paddingTop',
      'paddingBottom',
      'paddingLeft',
      'paddingRight',
      'marginTop',
      'marginBottom',
      'marginLeft',
      'marginRight'
    ].includes(prop) && isPropValid(prop)
})`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')}};
  flex: ${({ flex }) => (flex ? flex : 'none')}};
  ${({ justifyLeft }) => justifyLeft && `justify-content: flex-start;`};
  ${({ justifyRight }) => justifyRight && `justify-content: flex-end;`};
  ${({ justifyCenter }) => justifyCenter && `justify-content: center;`};
  ${({ justifySpaceBetween }) =>
    justifySpaceBetween && `justify-content: space-between;`};
  ${({ alignStart }) => alignStart && `align-items: flex-start;`};
  ${({ alignEnd }) => alignEnd && `align-items: flex-end;`};
  ${({ alignCenter }) => alignCenter && `align-items: center;`};
  ${({ alignStretch }) => alignStretch && `align-items: stretch;`};
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf}`};
  ${({ fullWidth }) => fullWidth && `width: 100%;`};
  ${({ fullHeight }) => fullHeight && `height: 100%;`};
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
  ${({ wrap }) => wrap && `flex-wrap: wrap`};
`

export const Item = styled('div', {
  shouldForwardProp: prop =>
    ![
      'fullWidth',
      'paddingTop',
      'paddingBottom',
      'paddingLeft',
      'paddingRight'
    ].includes(prop) && isPropValid(prop)
})`
  flex: ${({ flex }) => (flex ? flex : 'none')}};
  ${({ fullWidth }) => fullWidth && `width: 100%;`};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
`
