import { call, select, put, takeLatest } from 'redux-saga/effects'
import api from '../../../utils/api'
import log from '../../../utils/logger'
import { SEND_DOWNLOAD_LINK } from '../actions'
import { getToken, getUserId } from '../../authorization/selectors'
import { showSnackbar } from '../../snackbar/actions'

export function* sendDownloadLink({ phone }) {
  try {
    const authToken = yield select(getToken)
    const id = yield select(getUserId)
    yield call(api.sendDownloadLink, id, phone, authToken)
    yield put(showSnackbar('Notification link sent', 'success'))
  } catch (err) {
    log(`Failed to send download link to ${phone}: Error ${err}`)
    yield put(showSnackbar(err.message, 'error'))
  }
}

export function* watchSendDownloadLink() {
  yield takeLatest(SEND_DOWNLOAD_LINK, sendDownloadLink)
}

export default [watchSendDownloadLink()]
