// @flow
import React, { Component } from 'react'
import {
  Grid,
  Hidden,
  useMediaQuery,
  Select,
  MenuItem
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Loader from '@Common/Loader'
import { PhoneNumber } from '@Common/Form/Masks'
import Avatar from '@Common/Avatar'
import { Arrow } from '@icons'
import { validatePhoneNumber } from '@utils/validationUtils'
import Snackbar from '@Common/Snackbar'
import HelpText from '@Common/HelpText'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import { FabButton } from '@Common/Button'
import { TabPanel, BasicTabs } from '@Common/Tabs/Tabs'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import { truncateString } from '@utils/stringUtils'
import PaymentReminders from './PaymentReminders'
import {
  Bio,
  Contact,
  ContactLabel,
  ContactText,
  StyledButton,
  InputLabel,
  StyledTextInput,
  DeleteAccount,
  DeleteAccountText,
  InfoWrapper,
  ContactInfo,
  FooterContainer,
  StyledWarningIcon,
  FooterText,
  Flex,
  InfoContainer,
  BioCaption,
  SectionTitle
} from './styles'
import {
  PageTitle,
  MyAvatar,
  Name,
  ResidentName,
  UnitName,
  StyledSVG,
  MainContent
} from '../styles'
import { RadioButton } from '@Common/Form'
import '@images/svg/account.svg'

type PropsStateCommon = {
  firstName: string,
  lastName: String,
  bio: string,
  contactMethod: 'text' | 'email' | '',
  phone: string,
  email: string,
  login: string,
  deliveryPreference: string,
  reasonCode: string,
  activeTab: number
}

type Props = {
  ...PropsStateCommon,
  profilePicture: string,
  unitName: string,
  isMobile?: boolean,
  updateResident: () => {},
  showError: () => {},
  history: Object,
  prevUrl: string,
  isPayleasePmsUser: boolean,
  isUpdating: boolean
}

type State = {
  ...PropsStateCommon,
  viewMode: boolean
}

const tabs = [
  { id: 0, title: 'Personal Information' },
  { id: 1, title: 'Payment Reminders' }
]

const deliveryPreferences = {
  '0': 'Paper',
  '1': 'E-Statement',
  '2': 'Both',
  '3': 'Paper',
  '4': 'E-Statement',
  '5': 'Both'
}

const excerptLength = 74

const DeleteAccountLink = withAlertModal(({ showAlertModal }) => (
  <DeleteAccountText onClick={showAlertModal}>
    Close my account
  </DeleteAccountText>
))

const closeAccountModalFooter = () => {
  return (
    <FooterContainer container direction="row" alignItems="center">
      <StyledWarningIcon />
      <FooterText variant="body1">
        If you close this account, you can no longer access any account
        information.
      </FooterText>
    </FooterContainer>
  )
}

class MyAccount extends Component<Props, State> {
  state = {
    firstName: this.props.firstName,
    lastName: this.props.lastName,
    contactMethod: this.props.contactMethod,
    bio: this.props.bio,
    phone: this.props.phone,
    email: this.props.email,
    login: this.props.login,
    viewMode: true,
    editRemindersMode: false,
    deliveryPreference: this.props.deliveryPreference,
    reasonCode: this.props.reasonCode,
    showExcerpt: true,
    activeTab: 0
  }

  componentDidMount() {
    this.props.getDeliveryPreference()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isUpdating && !this.props.isUpdating) {
      this.resetState()
    }
  }

  changeMode = () => {
    this.setState({
      viewMode: !this.state.viewMode
    })
  }

  changeRemindersMode = () => {
    this.setState({
      editRemindersMode: !this.state.editRemindersMode
    })
  }

  resetState = () => {
    this.setState({
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      contactMethod: this.props.contactMethod,
      bio: this.props.bio,
      phone: this.props.phone,
      email: this.props.email,
      login: this.props.login,
      viewMode: true,
      deliveryPreference: this.props.deliveryPreference,
      reasonCode: this.props.reasonCode
    })
  }

  handleChange = ({
    currentTarget: { name, value }
  }: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ [name]: value })
  }

  reasonCodeChange = ({ target: { value } }) => {
    this.setState({ reasonCode: value })
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue })
  }

  onSave = () => {
    const { updateResident, showError } = this.props
    const {
      bio,
      phone,
      firstName,
      lastName,
      email,
      contactMethod,
      login,
      deliveryPreference,
      reasonCode
    } = this.state

    //Validate phone number
    if (!validatePhoneNumber(phone)) {
      showError('Phone number is invalid')
      return
    }

    updateResident({
      bio,
      phone,
      firstName,
      lastName,
      email,
      contactMethod,
      login
    })

    if (deliveryPreference || reasonCode) {
      this.props.updateDeliveryPreference(deliveryPreference, reasonCode)
    }
  }

  goBack = () => {
    const { history, prevUrl } = this.props
    return prevUrl ? history.push(prevUrl) : history.push('/')
  }

  toggleExcerpt = () => {
    this.setState({ showExcerpt: !this.state.showExcerpt })
  }

  render() {
    const {
      profilePicture,
      unitName,
      theme,
      isMobile,
      closeAccount,
      isPayleasePmsUser,
      isUpdating
    } = this.props
    const {
      firstName,
      lastName,
      viewMode,
      editRemindersMode,
      bio,
      phone,
      email,
      login,
      contactMethod,
      deliveryPreference,
      reasonCode,
      showExcerpt,
      activeTab
    } = this.state

    const isSSO = theme.ux.isSSO
    const showReadMore = bio.length > excerptLength

    const EditBtn = (
      <StyledButton id="editBtn" onClick={this.changeMode} primary>
        Edit
      </StyledButton>
    )

    const EditActions = isUpdating ? (
      <Loader />
    ) : (
      <>
        <StyledButton
          id="cancelBtn"
          onClick={this.resetState}
          primary
          outline
          fullWidth>
          Cancel
        </StyledButton>
        <StyledButton id="saveBtn" onClick={this.onSave} primary fullWidth>
          Save
        </StyledButton>
      </>
    )
    const renderTruncatedBio = () =>
      showExcerpt ? (
        <>
          {truncateString(bio, excerptLength)}
          {showReadMore && (
            <span>
              ...{' '}
              <a href onClick={this.toggleExcerpt}>
                read more
              </a>
            </span>
          )}
        </>
      ) : (
        <>
          {bio}{' '}
          <a href onClick={this.toggleExcerpt}>
            read less
          </a>
        </>
      )

    const myAccountDetails = () => {
      return viewMode ? (
        <div data-test-id="userInfo">
          <InfoContainer>
            {isSSO ? (
              <Bio id="userBio">
                <BioCaption marginRight="32px">Bio:</BioCaption>
                <span>{renderTruncatedBio()}</span>
              </Bio>
            ) : (
              <Bio id="userBio">{bio}</Bio>
            )}
            <ContactInfo direction="column" justifySpaceBetween>
              <Contact>
                <ContactLabel marginRight="32px">
                  Contact Method Preference
                </ContactLabel>
                <ContactText id="preferredContactMethod" capitalize>
                  {contactMethod}
                </ContactText>
              </Contact>
              {deliveryPreference ? (
                <Contact>
                  <ContactLabel marginRight="22px">
                    Statement Type Preference
                  </ContactLabel>
                  <ContactText id="deliveryPreference" capitalize>
                    {deliveryPreferences[deliveryPreference]}
                  </ContactText>
                </Contact>
              ) : null}
              <Contact>
                <ContactLabel marginRight="42px">Phone</ContactLabel>
                <ContactText id="phoneNumberText">{phone}</ContactText>
              </Contact>
              <Contact>
                <ContactLabel alignEnd marginRight={isMobile ? '48px' : '18px'}>
                  Login
                  {!isMobile && (
                    <HelpText
                      title="This is the username you use to login"
                      size="18px"
                    />
                  )}
                </ContactLabel>
                <ContactText id="loginText">{login}</ContactText>
              </Contact>
              <Contact>
                <ContactLabel alignEnd marginRight={isMobile ? '48px' : '18px'}>
                  Email
                  {!isMobile && (
                    <HelpText
                      title="This is the email we will use to contact you"
                      size="18px"
                    />
                  )}
                </ContactLabel>
                <ContactText id="emailText">{email}</ContactText>
              </Contact>
            </ContactInfo>
          </InfoContainer>
          {!isSSO ? (
            <>
              <Hidden smDown>
                <Flex
                  justifyRight
                  marginRight="5px"
                  marginTop={isSSO ? theme.space.s10 : theme.space.s60}>
                  {EditBtn}
                </Flex>
              </Hidden>
              <MobileBtnsPortal>{EditBtn}</MobileBtnsPortal>
            </>
          ) : (
            <Hidden>
              <Flex
                justifyRight
                marginRight="5px"
                marginTop={isSSO ? theme.space.s10 : theme.space.s60}>
                {EditBtn}
              </Flex>
            </Hidden>
          )}
        </div>
      ) : (
        <div data-test-id="editInfo">
          <Grid container spacing={6}>
            {isPayleasePmsUser && (
              <DeleteAccount fullWidth justifyRight>
                <DeleteAccountLink
                  id="saveBtn"
                  alertTitle="Are you sure you wish to close this account?"
                  onAlertClickYes={closeAccount}
                  renderBottom={closeAccountModalFooter}
                />
              </DeleteAccount>
            )}
            <Grid item xs={12} lg={6}>
              <StyledTextInput
                id="bio"
                name="bio"
                label="Bio"
                placeholder=""
                value={bio}
                multiline
                rowsMax={1}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={isSSO ? 6 : 12} md={isSSO && 6} lg={6}>
              <StyledTextInput
                id="phone"
                name="phone"
                label="Phone"
                maskInput={PhoneNumber}
                value={phone}
                onChange={this.handleChange}
                error={!validatePhoneNumber(phone)}
              />
            </Grid>
            <Grid item xs={isSSO ? 6 : 12} md={isSSO && 6} lg={6}>
              <InputLabel>Prefered Contact Method</InputLabel>
              <Flex flex={1}>
                <RadioButton
                  id="textRadioBtn"
                  label="Text"
                  value="text"
                  name="contactMethod"
                  onChange={this.handleChange}
                  selected={contactMethod === 'text'}
                />
                <RadioButton
                  id="emailRadioBtn"
                  label="Email"
                  value="email"
                  name="contactMethod"
                  onChange={this.handleChange}
                  selected={contactMethod === 'email'}
                />
              </Flex>
            </Grid>
            <Grid item xs={isSSO ? 6 : 12} md={isSSO && 6} lg={6}>
              <StyledTextInput
                id="login"
                name="login"
                label="Login"
                value={login}
                onChange={this.handleChange}
                helperText="This is the username you use to login"
                helperTextPlacement="bottom-end"
              />
            </Grid>
            {deliveryPreference ? (
              <Grid item xs={isSSO ? 6 : 12} md={isSSO && 6} lg={6}>
                <InputLabel>Statement Type Preference</InputLabel>
                <Flex flex={1}>
                  <RadioButton
                    id="eBillRadioButton"
                    label="E-Statement"
                    value="4"
                    name="deliveryPreference"
                    onChange={this.handleChange}
                    selected={
                      deliveryPreferences[deliveryPreference] === 'E-Statement'
                    }
                    style={{ whiteSpace: 'nowrap' }}
                  />
                  <RadioButton
                    id="paperRadioButton"
                    label="Paper"
                    value="3"
                    name="deliveryPreference"
                    onChange={this.handleChange}
                    selected={
                      deliveryPreferences[deliveryPreference] === 'Paper'
                    }
                    style={{ marginLeft: 0 }}
                  />
                  <RadioButton
                    id="bothButton"
                    label="Both"
                    value="5"
                    name="deliveryPreference"
                    onChange={this.handleChange}
                    selected={
                      deliveryPreferences[deliveryPreference] === 'Both'
                    }
                  />
                </Flex>
              </Grid>
            ) : null}
            <Grid item xs={isSSO ? 6 : 12} md={isSSO && 6} lg={6}>
              <StyledTextInput
                id="email"
                type="email"
                name="email"
                label="Email"
                value={email}
                onChange={this.handleChange}
                helperText="This is the email we will use to contact you"
                helperTextPlacement="bottom-end"
              />
            </Grid>
            {['Paper', 'Both'].includes(
              deliveryPreferences[deliveryPreference]
            ) ? (
              <Grid item xs={isSSO ? 6 : 12} md={isSSO && 6} lg={6}>
                <InputLabel>Reason for Switch?</InputLabel>
                <Select
                  value={reasonCode}
                  displayEmpty
                  onChange={this.reasonCodeChange}>
                  <MenuItem value="" disabled>
                    Reason...
                  </MenuItem>
                  <MenuItem value="DontHaveEmail">I don’t have email</MenuItem>
                  <MenuItem value="DontCheckEmail">
                    I don’t check my email
                  </MenuItem>
                  <MenuItem value="ReceiveManyEmails">
                    I receive too many emails
                  </MenuItem>
                  <MenuItem value="PreferPaper">
                    I prefer paper statements
                  </MenuItem>
                  <MenuItem value="NeedPaper">
                    I need paper statements for expense documentation
                  </MenuItem>
                </Select>
              </Grid>
            ) : null}
          </Grid>
          {!isSSO ? (
            <>
              <Hidden smDown>
                <Flex justifySpaceBetween marginTop={isSSO ? '8px' : '75px'}>
                  {EditActions}
                </Flex>
              </Hidden>
              <MobileBtnsPortal>{EditActions}</MobileBtnsPortal>
            </>
          ) : (
            <Hidden>
              <Flex justifySpaceBetween marginTop={isSSO ? '8px' : '75px'}>
                {EditActions}
              </Flex>
            </Hidden>
          )}
        </div>
      )
    }
    return (
      <>
        {!isSSO && (
          <StyledSVG name="account_bg" width="2000px" height="330px" />
        )}
        <Grid container>
          <Grid item xs={3} sm={2} md={1}>
            <FabButton
              onClick={this.goBack}
              size="45px"
              background={theme.palette.tertiary.main}
              component={() => (
                <Arrow
                  id="backArrow"
                  name="arrow"
                  width="16px"
                  height="12px"
                  fill={theme.palette.primary.main}
                />
              )}
            />
          </Grid>
          <Grid item md={4}>
            <Hidden smDown>
              <PageTitle> My Account</PageTitle>
            </Hidden>
          </Grid>
          <Grid item xs={9} sm={10} md={7} style={{ zIndex: 0 }}>
            <Name>
              <ResidentName id="residentName">
                {firstName} {lastName}
              </ResidentName>
              <UnitName id="unitName">{unitName}</UnitName>
            </Name>
          </Grid>
        </Grid>

        <Grid container component={MainContent}>
          <Grid item xs={isSSO ? 3 : 12} md={4}>
            <MyAvatar>
              <Avatar size="282px" picture={profilePicture} hideShadow={true} />
            </MyAvatar>
          </Grid>
          <Grid item xs={isSSO & 1} md={1} />
          <Grid item xs={isSSO ? 8 : 12} md={7} lg={isSSO ? 7 : 6}>
            {editRemindersMode ? (
              <PaymentReminders
                isEdit={editRemindersMode}
                changeMode={this.changeRemindersMode}
              />
            ) : (
              <InfoWrapper>
                {isSSO && viewMode ? (
                  <BasicTabs
                    tabs={tabs}
                    value={activeTab}
                    handleChange={this.handleTabChange}>
                    <TabPanel value={activeTab} index={0}>
                      {myAccountDetails()}
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                      <PaymentReminders
                        changeMode={this.changeRemindersMode}
                        isSSO={isSSO}
                      />
                    </TabPanel>
                  </BasicTabs>
                ) : (
                  <>
                    <SectionTitle>Personal Information</SectionTitle>
                    {myAccountDetails()}
                    {viewMode && (
                      <PaymentReminders changeMode={this.changeRemindersMode} />
                    )}
                  </>
                )}
              </InfoWrapper>
            )}
          </Grid>
          <Snackbar />
        </Grid>
      </>
    )
  }
}

export default (props: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return <MyAccount {...props} theme={theme} isMobile={isMobile} />
}
