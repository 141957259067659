//@flow
import { useState } from 'react'

export const usePaymentFlow = (
  startFlow: number = 0
): [number, { gotoNextFlow: Function, gotoPreviousFlow: Function }] => {
  const [flow, setFlow] = useState(startFlow)

  const gotoNextFlow = (nextFlow: number) => {
    if (typeof nextFlow === 'number') {
      setFlow(nextFlow)
    } else {
      setFlow(flow + 1)
    }
  }

  const gotoPreviousFlow = () => {
    setFlow(flow - 1)
  }

  return [flow, { gotoNextFlow, gotoPreviousFlow }]
}
