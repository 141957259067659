import styled from '@emotion/styled'
import { StyledCardContent } from '../styles'

const triangleWidth = `360px`

export const Triangle = styled.div`
  box-sizing: border-box;
  height: 0;
  width: 0;
  border-left: ${triangleWidth} solid transparent;
  border-right: 0;
  border-bottom: 20px solid #fff4df;
  margin-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s40 : theme.space.s60};
`

export const StyledWarningContent = styled.div`
  background-color: #fff4df;
  height: ${({ theme }) => (theme.ux.isSSO ? theme.space.s160 : `180px`)};
  padding: 15px 5px;
  text-align: center;
  width: ${({ theme }) => theme.ux.isSSO && triangleWidth};
`

export const UnavailableCardContent = styled(StyledCardContent)`
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    padding-bottom: ${theme.space.s20};
    width: ${triangleWidth};
    > p {
      margin: 0;
      padding-bottom: 0;
    }
  `}
`
