// @flow

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'
import { getAuthRoutes } from './Auth'
import DownloadApp from '../views/DownloadApp'
import WorkOrders from '../views/WorkOrders'
import PrivateRoute from './PrivateRoute'
import Payments from '../views/Payments'
import Marketplace from '../views/Marketplace'
import MarketplaceDetails from '../views/Marketplace/Details'
import LookbackSuccess from '../views/Payments/CreditReporting/Lookback/Success'
import Home from '../views/Home'
import { MyAccount, PropertyInfo } from '../views/MyDetails'
import {
  nativePayer,
  hasWorkOrder,
  hasStandaloneWorkOrder,
  isPaymentOnly,
  marketplaceEnabled
} from 'zego-shared/store/settings/selectors'
import { getFeatureFlag } from 'zego-shared/store/integrations/launchDarkly/selectors'
import { ldInitRequest } from 'zego-shared/store/integrations/launchDarkly/actions'
import { WORK_ORDER_FEATURE_FLAG } from 'zego-shared/store/workOrders/constants'
import { MARKETPLACE_FEATURE_FLAG } from 'zego-shared/store/marketplace/constants'
import ReactivateSuccess from '../views/Payments/CreditReporting/LevelCredit/ReactivateSuccess'

type Props = {
  nativePayer: boolean,
  hasWorkOrder: boolean,
  hasStandaloneWorkOrder: boolean,
  isPaymentOnlyExperience: boolean,
  marketplaceEnabled: boolean,
  featureFlag: Object,
  ldInitRequest: Function
}

const mapStateToProps = state => ({
  nativePayer: nativePayer(state),
  hasWorkOrder: hasWorkOrder(state),
  hasStandaloneWorkOrder: hasStandaloneWorkOrder(state),
  isPaymentOnlyExperience: isPaymentOnly(state),
  marketplaceEnabled: marketplaceEnabled(state),
  featureFlag: getFeatureFlag(state)
})

const mapDispatchToProps = { ldInitRequest }

const Routes = (props: Props) => {
  const {
    nativePayer,
    hasWorkOrder,
    hasStandaloneWorkOrder,
    isPaymentOnlyExperience,
    marketplaceEnabled,
    featureFlag,
    ldInitRequest
  } = props
  const [showWorkOrderFF, setShowWorkOrderFF] = useState(false)
  const [showMarketplaceFF, setShowMarketplaceFF] = useState(false)

  useEffect(() => {
    ldInitRequest()
  }, [ldInitRequest])

  useEffect(() => {
    setShowWorkOrderFF(
      featureFlag &&
      featureFlag.ld &&
      featureFlag.ld.variation(WORK_ORDER_FEATURE_FLAG, false)
    )

    setShowMarketplaceFF(
      featureFlag &&
      featureFlag.ld &&
      featureFlag.ld.variation(MARKETPLACE_FEATURE_FLAG, false)
    )
  }, [featureFlag])

  const showPayments = nativePayer || isPaymentOnlyExperience
  const showWorkOrder = hasWorkOrder || (hasStandaloneWorkOrder && showWorkOrderFF)
  const showMarketplace = marketplaceEnabled && showMarketplaceFF

  return (
    <Switch>
      {getAuthRoutes()}
      <PrivateRoute exact path="/" component={Home} />
      {showWorkOrder &&
        <PrivateRoute
          path="/units/:unitId/work-orders"
          component={WorkOrders}
        />
      }
      {showPayments &&
        <PrivateRoute path="/payments" component={Payments} />
      }
      <PrivateRoute
        exact
        path="/lookback/success"
        component={LookbackSuccess}
      />
      <PrivateRoute
        exact
        path="/credit-reporting/reactivate/success"
        component={ReactivateSuccess}
      />
      <PrivateRoute path="/download-app" component={DownloadApp} />
      {showMarketplace &&
          <PrivateRoute path="/units/:unitId/marketplace/list" component={Marketplace} />
      }
      {showMarketplace &&
          <PrivateRoute path="/units/:unitId/marketplace/deal/:id" component={MarketplaceDetails} />
      }
      <PrivateRoute path="/my-account/property" component={PropertyInfo} />
      <PrivateRoute path="/my-account" component={MyAccount} />
    </Switch>
  )
}

export { Routes }

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
