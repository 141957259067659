// @flow
import get from 'lodash/get'
import sum from 'lodash/sum'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'
import Dinero from 'dinero.js'
import moment, { Moment } from 'moment'
import { amountToNumber } from '../../../../utils/number'
import { getPaymentMethodData } from '../../methods/selectors'
import { getUserName, getIsSsoPayments } from '../../../authorization/selectors'
import { capitalizeFirstLetter } from '../../../../utils/string'
import { getExpressPayFee, getPaymentOptionFees } from '../../fees/selectors'
import { getUIPaymentsDisabled } from '../../../pmSettings/selectors'
import { getNextAutoPayDateById } from '../../summary/selectors'
import { } from 'zego-shared/utils/date'
import {
  getPaymentFieldsToPaymentFields,
  getTotalOwed
} from '../../balance/selectors'
import {
  getRecipient,
  getPaymentMethodFee,
  buildLineItems,
  typeNameToPaymentType,
  buildPayload
} from '../../oneTimePayment/selectors'
import {
  formatDateMMDDYY,
  isLessThanOrEqualsToday,
  getOrdinalSuffix
} from '../../../../utils/date'
import { getOverridenAutoPayType } from '../../../overrides/utils'

import { VAP_AND_FAP, VAP, MY_FULL_BAL, FAP, NO_AUTOPAY } from '../constants'

import type { AutoPayState } from '../constants'

export const isSSO = getIsSsoPayments()

export const getAutoPayConfig = (state: Object) =>
  get(state, 'payments.autoPay.config', {})

export const getAllowCancel = (state: Object) =>
  get(state, 'payments.autoPay.config.allowCancel')

export const getAllowSkip = (state: Object) =>
  get(state, 'payments.autoPay.config.allowSkip')

export const getAllowEdit = (state: Object) =>
  get(state, 'payments.autoPay.config.allowEdit')

export const getAutoPayState = (state: Object) => {
  const hideAutoPayType = get(state, 'overrides.payments.autoPay.hide', false)
  const autoPayState = get(state, 'payments.autoPay.state.state', NO_AUTOPAY)

  return hideAutoPayType
    ? getOverridenAutoPayType(hideAutoPayType, autoPayState)
    : autoPayState
}

export const getSavedDetails = (state: Object) =>
  get(state, 'payments.autoPay.details')

export const getPaymentAmountType = (state: Object) =>
  get(state, 'payments.autoPay.paymentAmountType')

export const getAllowIndefinite = (state: Object) =>
  get(state, 'payments.autoPay.config.allowIndefinite', false)

export const getCashPayEnabled = (state: Object) =>
  get(state, 'payments.autoPay.cash_pay_config.enabled')

export const getIsBothAutoPays = createSelector(
  getAutoPayState,
  (state: AutoPayState) => {
    return state === VAP_AND_FAP
  }
)

export const getIsAutopayDisabled = createSelector(
  [getAutoPayState, getIsSsoPayments, getUIPaymentsDisabled],
  (
    state: AutoPayState,
    isSSO: boolean,
    uiPaymentsDisabled: boolean
  ): boolean => {
    return !isSSO
      ? uiPaymentsDisabled
        ? true
        : state === NO_AUTOPAY
      : state === NO_AUTOPAY
  }
)

export const getHasMax = createSelector(
  [getAutoPayConfig, getAutoPayState],
  (config: Object, state: AutoPayState): boolean => {
    const allowMaxLimit = get(config, 'variableSettings.allowMaxLimit', false)
    if ([VAP_AND_FAP, VAP].includes(state)) {
      return allowMaxLimit
    }
    return false
  }
)

export const getVAPDetails = createSelector(
  [getSavedDetails, getPaymentMethodData, getHasMax],
  (prevDetails: Object, lastPaymentMethod: Object, hasMax: boolean) => {
    const details = prevDetails || {
      amount: {
        total: MY_FULL_BAL,
        maxLimit: Dinero({ amount: 0 }).toFormat('0.00'),
        hasMax
      },
      useExpressPay: false
    }
    return { ...details, lastPaymentMethod }
  }
)

export const getFAPDetails = createSelector(
  [
    getSavedDetails,
    getPaymentFieldsToPaymentFields,
    getTotalOwed,
    getPaymentMethodData,
    getAllowIndefinite
  ],
  (
    prevDetails: Object,
    fields: Object[],
    totalOwed: Object,
    lastPaymentMethod: Object,
    allowIndefinite: boolean
  ) => {
    const total = totalOwed.toFormat()
    const details = prevDetails || {
      amount: {
        fields,
        total,
        totalAmt: totalOwed.getAmount()
      },
      useExpressPay: false
    }
    return { ...details, lastPaymentMethod }
  }
)

const getDetails = (autoPayState, state) => {
  switch (autoPayState) {
    case VAP:
      return getVAPDetails(state)
    case FAP:
      return getFAPDetails(state)
    default:
      return {}
  }
}

export const getAutoPayDetails = (state: Object) => {
  const autoPayState: AutoPayState = getAutoPayState(state)
  const paymentAmountType = getPaymentAmountType(state)
  if (autoPayState === VAP_AND_FAP) {
    return getDetails(paymentAmountType, state)
  }
  return getDetails(autoPayState, state)
}

const getSettings = (config: Object, state?: AutoPayState) => {
  if (state === FAP) {
    return config.fixedSettings
  }
  if (state === VAP) {
    return config.variableSettings
  }
  return {}
}

export const buildScheduleConfig = (
  config: Object,
  state?: AutoPayState,
  paymentAmountType?: AutoPayState
) => {
  const { allowIndefinite } = config
  if (state === VAP_AND_FAP) {
    return buildScheduleConfig(config, paymentAmountType)
  }
  const { startDay = 0, endDay = 0 } = getSettings(config, state)

  return {
    allowIndefinite,
    allowedDaysRange: [startDay, endDay]
  }
}

export const getScheduleConfig = createSelector(
  [getAutoPayConfig, getAutoPayState, getPaymentAmountType],
  buildScheduleConfig
)

const buildScheduleSummary = (
  schedule: {
    frequency: string,
    startDate: Moment,
    endDate?: Moment
  },
  maxLimit: ?string
) => {
  const { frequency, startDate, endDate } = schedule || {}
  return {
    frequency: capitalizeFirstLetter(frequency),
    startDate: formatDateMMDDYY(startDate),
    endDate: endDate
      ? formatDateMMDDYY(endDate.date(startDate.date()))
      : 'Indefinite',
    maxLimit
  }
}

const getNextPaymentDate = (startDate: Moment) => {
  if (isLessThanOrEqualsToday(startDate)) {
    return formatDateMMDDYY(startDate.add('M', 1))
  }
  return formatDateMMDDYY(startDate)
}

const buildSummary = (details: Object, paymentOptionFees: Object[]) => {
  const {
    lastPaymentMethod,
    useExpressPay,
    schedule,
    amount: { maxLimit }
  } = details
  const { typeName, amount, currency } = lastPaymentMethod
  const expressPayAmount = getExpressPayFee(
    paymentOptionFees,
    typeName,
    useExpressPay
  )
  const paymentMethodFee = sum([expressPayAmount, amount])
  const methodFee = getPaymentMethodFee(paymentMethodFee, currency, typeName)
  const scheduleInfo = buildScheduleSummary(schedule, maxLimit)
  const nextPayDate = getNextPaymentDate(get(schedule, 'startDate'))

  return {
    methodFee,
    scheduleInfo,
    nextPayDate,
    paymentMethodFee
  }
}

const buildSummaryReturn = ({
  total,
  methodFee,
  nextPayDate,
  autoPayAmt,
  recipient,
  name,
  scheduleInfo,
  fullName
}: {
  total: string,
  methodFee: Object,
  nextPayDate: string,
  autoPayAmt: string,
  recipient: Object,
  name: string,
  scheduleInfo: Object,
  fullName: string
}) => {
  return {
    paymentInfo: {
      total,
      methodFee,
      autoPayAmt,
      nextPayDate
    },
    scheduleInfo,
    residentInfo: {
      recipient,
      accountInfo: {
        fullName,
        methodName: name
      }
    }
  }
}

const buildVAPSummary = (
  details: Object | null,
  recipient: Object,
  paymentOptionFees: Object[],
  fullName: string
) => {
  if (!details) return null
  const {
    amount: { total },
    lastPaymentMethod
  } = details
  const { name = '' } = lastPaymentMethod
  const { methodFee, nextPayDate, scheduleInfo } = buildSummary(
    details,
    paymentOptionFees
  )
  return buildSummaryReturn({
    total: `${total} + ${methodFee.value}`,
    autoPayAmt: total,
    nextPayDate,
    scheduleInfo,
    fullName,
    name,
    recipient,
    methodFee
  })
}

const getVAPSummary = createSelector(
  [getSavedDetails, getRecipient, getPaymentOptionFees, getUserName],
  buildVAPSummary
)

const buildFAPSummary = (
  details: Object | null,
  recipient: Object,
  paymentOptionFees: Object[],
  fullName: string
) => {
  if (!details) return null
  const {
    amount: { fields: paymentFields },
    lastPaymentMethod
  } = details
  const { name = '' } = lastPaymentMethod
  const {
    methodFee,
    nextPayDate,
    scheduleInfo,
    paymentMethodFee
  } = buildSummary(details, paymentOptionFees)
  const { subtotal: autoPayAmt, currency } = buildLineItems(paymentFields)
  const total = autoPayAmt
    .add(
      Dinero({
        amount: paymentMethodFee,
        currency
      })
    )
    .toFormat()
  return buildSummaryReturn({
    total,
    autoPayAmt: autoPayAmt.toFormat(),
    nextPayDate,
    scheduleInfo,
    fullName,
    name,
    recipient,
    methodFee
  })
}

const getFAPSummary = createSelector(
  [getSavedDetails, getRecipient, getPaymentOptionFees, getUserName],
  buildFAPSummary
)

export const getSummary = (autoPayState: string, state: Object) => {
  switch (autoPayState) {
    case VAP:
      return getVAPSummary(state)
    case FAP:
      return getFAPSummary(state)
    default:
      return {}
  }
}

export const getAutoPaySummary = (state: Object) => {
  const autoPayState: AutoPayState = getAutoPayState(state)
  const paymentAmountType = getPaymentAmountType(state)

  if (autoPayState === VAP_AND_FAP) {
    return getSummary(paymentAmountType, state)
  }
  return getSummary(autoPayState, state)
}

const buildSchedulePayload = (
  schedule: {
    startDate: Moment,
    endDate?: Moment,
    frequency: string
  } = {
      frequency: 'MONTHLY',
      startDate: moment()
    }
) => {
  const { startDate, endDate, frequency } = schedule
  if (isLessThanOrEqualsToday(startDate)) {
    startDate.add('M', 1)
  }

  if (endDate) {
    const endDay = startDate.date()
    endDate.date(endDay)
  }

  // Ensure timezone conversion doesn't change the date
  const startDay = startDate.date?.()
  // eslint-disable-next-line
  startDate
    .utc?.()
    .date(startDay)
    .hour(12)

  return {
    startDate,
    endDate,
    frequency
  }
}

const getMaxLimit = (maxLimit, currency) => {
  const maxLimitAmt = amountToNumber(maxLimit)
  return maxLimitAmt
    ? { amount: amountToNumber(maxLimit), currency }
    : undefined
}

const formatEndDate = endDate => {
  return `Auto Pay runs ${endDate ? 'until ' + formatDateMMDDYY(endDate) : 'Indefinitely'
    }`
}

const buildVAPPayload = (details: Object, paymentOptionFees, isSSO): Object => {
  const {
    amount: { maxLimit },
    lastPaymentMethod,
    useExpressPay,
    schedule
  } = details
  const { typeName, id, currency, amount } = lastPaymentMethod
  const expressPayAmount = getExpressPayFee(
    paymentOptionFees,
    typeName,
    useExpressPay
  )
  const feeAmount = sum([expressPayAmount, amount])
  return {
    maxLimit: getMaxLimit(maxLimit, currency),
    feeAmount: { currency, amount: feeAmount },
    paymentType: typeNameToPaymentType[typeName],
    paymentMethodId: Number(id),
    isExpressPay: useExpressPay,
    schedule: buildSchedulePayload(schedule),
    isSSO: isSSO
  }
}

export const getVAPPayload = createSelector(
  [getVAPDetails, getPaymentOptionFees, getIsSsoPayments],
  buildVAPPayload
)

const buildFAPPayload = (details: Object, paymentOptionFees, isSSO): Object => {
  
  const payload = buildPayload(details, paymentOptionFees, isSSO)
  const { schedule } = details
  return {
    ...payload,
    schedule: buildSchedulePayload(schedule)
  }
}

export const getFAPPayload = createSelector(
  [getFAPDetails, getPaymentOptionFees, getIsSsoPayments],
  buildFAPPayload
)

const getAutopayData = (autopays: Object[], state: Object): Object[] => {
  return autopays.map(
    ({
      id,
      type,
      frequency,
      skipped,
      paymentMethod,
      debitDay,
      maxLimit,
      totalAmount,
      endDate
    }) => {
      return {
        autopayId: id,
        autopayType: type || '',
        paymentFrequency:
          `${frequency} on the ${getOrdinalSuffix(debitDay)}` || '',
        skipped,
        paymentInfo: `Paid with ${paymentMethod}` || '',
        frequency: frequency || '',
        maxLimit: maxLimit
          ? maxLimit.amount > 0
            ? Dinero(maxLimit).toFormat()
            : ''
          : '',
        total: totalAmount ? Dinero(totalAmount).toFormat() : '',
        nextPayment: getNextAutoPayDateById(state, id) || '',
        endDate: formatEndDate(endDate)
      }
    }
  )
}

export const getAllAutoPays = (state: Object) => {
  const fixed = get(state, 'payments.autoPay.autoPays.fixedAutopays', [])
  const variable = get(state, 'payments.autoPay.autoPays.variableAutopays', [])

  return [...fixed, ...variable]
}

export const getLastAutopay = createSelector(
  [getAllAutoPays],
  (autoPays = []) => {
    if (isEmpty(autoPays)) {
      return null
    } else if (autoPays.length === 1) {
        return autoPays[0].startDate
    } else {
        return autoPays.reduce((a, b) => (a.startDate > b.startDate ? a.startDate : b.startDate));
    }
  })

export const getAutoPays = createSelector(
  [getAllAutoPays, state => state],
  (autoPays: Object, state) => getAutopayData(autoPays, state)
)

export const getBankAccountAutoPays = createSelector(
  [getAllAutoPays, state => state],
  (allAutoPays, state) =>
    allAutoPays
      .filter(autoPay => 'ACH' === autoPay.paymentType)
      .map(autoPay => ({
        ...autoPay,
        total: Dinero(autoPay.totalAmount).toFormat(),
        nextRun: getNextAutoPayDateById(state, autoPay.id)
      }))
)

export const hasData = (state: Object) => {
  const data = getAutoPays(state)

  return data.length > 0
}
