// @flow
import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'

export const Container = styled('div', {
  shouldForwardProp: prop => isPropValid(prop)
})`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  ${({ rows }) => rows && `grid-template-rows: repeat(12, 1fr)`};
  ${({ justifyStart }) => justifyStart && `justify-items: start;`};
  ${({ justifyEnd }) => justifyEnd && `justify-items: end;`};
  ${({ justifyCenter }) => justifyCenter && `justify-items: center;`};
  ${({ justifyStretch }) => justifyStretch && `justify-items: stretch;`};

  ${({ alignStart }) => alignStart && `align-items: start;`};
  ${({ alignEnd }) => alignEnd && `align-items: end;`};
  ${({ alignCenter }) => alignCenter && `align-items: center;`};
  ${({ alignStretch }) => alignStretch && `align-items: stretch;`};
`

export const Item = styled('div')`
  grid-column: ${({ columnStart, columnSpan }) =>
    `${columnStart} / span ${columnSpan}`};
  grid-row: ${({ rowStart, rowSpan }) =>
    rowStart && rowSpan && `${rowStart} / span ${rowSpan}`};

  ${({ alignStart }) => alignStart && `align-self: start;`};
  ${({ alignEnd }) => alignEnd && `align-self: end;`};
  ${({ alignCenter }) => alignCenter && `align-self: center;`};
  ${({ alignStretch }) => alignStretch && `align-self: stretch;`};

  ${({ justifyStart }) => justifyStart && `justify-self: start;`};
  ${({ justifyEnd }) => justifyEnd && `justify-self: end;`};
  ${({ justifyCenter }) => justifyCenter && `justify-self: center;`};
  ${({ justifyStretch }) => justifyStretch && `justify-self: stretch;`};
`
