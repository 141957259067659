// @flow
import styled from '@emotion/styled'
import { DialogTitle, Dialog } from '@material-ui/core'
import Flex from '../Flex'
import { DialogContentText, IconButton } from '@material-ui/core'

export const DialogStyled = styled(Dialog)`
  & .dialog-paper {
    width: 550px;
    padding: ${({ theme }) => theme.space.s30};
    border-top-right-radius: 3em;
    border-bottom-left-radius: 3em;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    & .dialog-paper {
      width: 90%;
    }
  }
`

export const DialogTitleStyled = styled(DialogTitle)`
  & h2 {
    height: fit-content;
    min-height: 32px;
    width: ${({ isTerms }) => (isTerms ? '100%' : '412px')};
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    line-height: 32px;
    text-align: center;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    & h2 {
      min-height: 24px;
      width: 100%;
      font-size: ${({ theme }) => theme.custom.font.size.H6};
      line-height: 28px;
    }
  }
`

export const ButtonsWrapper = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.s20};
  justify-content: center;
  & button:last-of-type {
    margin-left: ${({ theme, isTerms }) => (isTerms ? '' : theme.space.s30)};
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  }
  @media only screen and (max-width: 352px) {
    flex-wrap: wrap;
    & button:last-of-type {
      margin-left: 0;
    }
  }
`

export const StyledDialogContentText = styled(DialogContentText)`
  text-align: ${({ isTerms }) => (isTerms ? 'left' : 'center')};
`

export const Icon = styled(IconButton)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(2)}px;
  top: ${({ theme }) => theme.spacing(1)}px;
`