// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import ReportingDesktop from '@images/credit_reporting/reporting_desktop.png'
import ReportingTablet from '@images/credit_reporting/reporting_tablet.png'
import ReportingMob from '@images/credit_reporting/reporting_mobile.png'
import ReportingTabletLB from '@images/credit_reporting/reporting_tablet_lb.png'
import ReportingMobLB from '@images/credit_reporting/reporting_mobile_lb.png'

export const Banner = styled.div`
  text-decoration: none;
  display: block;
  background-image: url('${ReportingDesktop}');
  height: 485px;
  width: 252px;
  z-index: 1;
  margin-left: ${({ theme }) => theme.space.s2};
  
  ${({ theme, variant }) =>
    variant === 'short' &&
    `
    background-image: url('${ReportingDesktop}');
    border-radius: 4px 4px 0 0;
    background-size: 249px 350px;
    width: 249px;
    height: 350px;
    position: relative;
    margin-top: ${theme.space.s33};
  `}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    background-image: ${({ hasLookback }) =>
      hasLookback ? `url(${ReportingTabletLB})` : `url(${ReportingTablet})`};
    background-size: cover;
    height: 204px;
    max-width: 446px;
    width: 100%;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
        background-image: ${({ hasLookback }) =>
          hasLookback ? `url(${ReportingMobLB})` : `url(${ReportingMob})`};
    background-size: cover;
    width: 100%;
    max-height: 210px;
    margin-left: 0;
  }
  
  ${({ theme, variant }) =>
    theme.ux.isSSO &&
    `
    background-position: bottom center;
    background-repeat: no-repeat;
    width: 170px;
    height: ${variant === 'short' ? 'auto' : '344px'};
    margin-top: ${variant === 'short' ? theme.space.s10 : 0};
    padding-bottom: ${variant === 'short' ? 0 : theme.space.s20};
  `}
`

export const ContentWrap = styled(Flex)`
  padding-left: ${({ theme, hasLookback }) =>
    hasLookback ? theme.space.s43 : theme.space.s47};
  padding-right: ${({ theme, hasLookback }) =>
    hasLookback ? theme.space.s40 : theme.space.s47};
  display: flex;
  flex-direction: column;

  ${({ theme, variant }) =>
    variant === 'short' &&
    `
    padding-top: 10px;
  `}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    padding-top: ${({ theme }) => theme.space.s20};
    padding-left: ${({ theme }) => theme.space.s32};
    padding-right: ${({ theme }) => theme.space.s23};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    padding-top: ${({ theme }) => theme.space.s31};
    padding-left: ${({ theme }) => theme.space.s30};
    padding-right: ${({ theme }) => theme.space.s36};
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    padding: ${theme.space.s30};
    padding-top: ${theme.space.s20};
  `}
`

export const Title = styled('div')`
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H4 : theme.custom.font.size.H3};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: 1;
  color: ${({ theme }) => theme.palette.common.midnight};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    padding-top: ${({ theme }) => theme.space.s25};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    padding-top: ${({ theme }) => theme.space.s20};
  }
`

export const SubTitle = styled('div')`
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.xs : theme.custom.font.size.H6};
  line-height: ${({ theme }) => (theme.ux.isSSO ? 13 : 18)}px;
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  max-width: 166px;
  color: ${({ theme }) => theme.palette.common.midnight};
  margin-top: ${({ theme }) => theme.space.s5};
  ${({ theme }) => theme.ux.isSSO && 'margin-right: -10px;'}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s5};
  }
`

export const CountCircle = styled(Flex)`
  width: ${({ hasLookback }) => (hasLookback ? '80px' : '128px')};
  height: ${({ hasLookback }) => (hasLookback ? '80px' : '128px')};
  background-color: ${({ theme, blue, lightBlue }) =>
    blue
      ? theme.palette.alternative.brightblue
      : lightBlue
      ? theme.palette.alternative.brightblue
      : theme.palette.secondary.main};
  border-radius: 50%;
  margin-top: ${({ theme, hasLookback }) =>
    hasLookback ? theme.space.s33 : theme.space.s57};

  ${({ theme, variant }) =>
    variant === 'short' &&
    `
    width: 107px;
    height: 107px;
    margin-top: 15px;
  `}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    ${({ hasLookback }) => !hasLookback && 'width: 107px;'};
    ${({ hasLookback }) => !hasLookback && 'height: 107px;'};
    margin-top: 0;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    width: ${({ hasLookback }) => (hasLookback ? '66px' : '104px')};
    height: ${({ hasLookback }) => (hasLookback ? '66px' : '99px')};
  }

  ${({ theme, hasLookback, variant }) =>
    theme.ux.isSSO &&
    `
    width: ${hasLookback ? 56 : 90}px;
    height: ${hasLookback ? 56 : 90}px;
    margin-top: ${variant === 'short' ? 15 : !hasLookback ? 45 : 25}px;
  `}
`

export const Count = styled(Flex)`
  font-size: ${({ theme, hasLookback, inProgress }) =>
    hasLookback
      ? inProgress
        ? theme.custom.font.size.H6
        : theme.custom.font.size.H2
      : theme.custom.font.size.H1};
  color: ${({ theme }) => theme.palette.common.midnight};
  line-height: ${({ hasLookback, inProgress }) =>
    hasLookback ? (inProgress ? '17px' : '40px') : '33px'};
  min-height: 45px;
  height: auto;
  ${({ inProgress }) => inProgress && 'text-align: center;'};

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H2};
  }

  ${({ theme, hasLookback, inProgress }) =>
    theme.ux.isSSO &&
    `
    ${!(hasLookback && inProgress) ? 'font-weight: bold' : ''};
    font-size: ${
      hasLookback
        ? inProgress
          ? theme.custom.font.size.sm
          : theme.custom.font.size.H3
        : theme.custom.font.size.H2
    };
  `}
`

export const MonthsOfPayments = styled.div`
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.xs : theme.custom.font.size.H6};
  color: ${({ theme }) => theme.palette.common.midnight};
  margin-top: ${({ theme, hasLookback }) =>
    hasLookback ? theme.space.s9 : theme.space.s13};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  text-align: center;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme, hasLookback }) =>
      hasLookback && theme.custom.font.size.md};
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }
`

export const CircleWrap = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    justify-content: flex-end;
    margin-right: ${({ theme, hasLookback }) =>
      !hasLookback && theme.space.s26};
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    margin-right: ${({ theme, hasLookback }) => !hasLookback && theme.space.s5};
  }
`

export const CircleContainer = styled(Flex)`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    ${({ hasLookback, theme }) =>
      hasLookback && `width: 80px; margin-top: ${theme.space.s26}`};
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    ${({ hasLookback, theme }) =>
      hasLookback && `width: 66px; margin-top: ${theme.space.s20}`};
  }
`
