// @flow
import React from 'react'
import { ActionBox, MobileContainer, FooterHeading, FooterSubHeading, LeaseRenewalMobile } from '../styles'
import lease_renewal_mobile from '@images/lease_renewal_mobile.png'
import api from 'zego-shared/utils/api'

const defaultMagicLink = process.env.REACT_APP_MAGICK_LINK

const redirectActivationLink = async userId => {
  if (!userId) {
    return ""
  }
  const response = await api.getActivationLink(userId).catch(err => false)
  return response || "/login"
}


class MobileWeb extends React.Component {

  state = {
    activationLink: undefined,
    url: undefined
  }

  async componentDidMount() {
    const {
      userId
    } = this.props
    const url = await redirectActivationLink(userId)
    this.setState({ url })
  }

  render() {
    const { state } = this

    return (
      <MobileContainer>
        <LeaseRenewalMobile src={lease_renewal_mobile} />
        <FooterHeading>Want to chat now?</FooterHeading>
        <FooterSubHeading>Choose the option below that best describes you and we’ll help you get there!</FooterSubHeading>
        <ul>
          <ActionBox><a href={state.url}>I don’t have a Zego account or the app</a></ActionBox>
          <ActionBox><a href={defaultMagicLink}>I have a Zego account, but not the app</a></ActionBox>
          <ActionBox><a href={defaultMagicLink}>I have the Zego app, take me there now!</a></ActionBox>
        </ul>
      </MobileContainer>
    )
  }
}
export default MobileWeb
