import React from 'react'
import { useStyles } from './styles'

type Props = {
 acknowledgement: boolean
}

const PlaidAcknowledgementDisclaimer = ({ acknowledgement }: Props) => {
  const classes = useStyles()
  return acknowledgement ? <div className={classes.acknowledgementDisclaimer}>Please agree before continuing</div> : <div className={classes.acknowledgementSpacer}></div>
}

export default PlaidAcknowledgementDisclaimer
