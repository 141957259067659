import { getSelectedUnit, getUnitType } from 'zego-shared/store/select/selectors'
import { isLevelCreditReportingEnabled } from 'zego-shared/store/payments/creditReporting/selectors'
import { getSSOPayment } from 'zego-shared/store/payments/ssoPayment/selectors'
import { getPaymentOptions } from 'zego-shared/store/payments/options/selectors'
import { getCashPayEnabled, getLastAutopay } from 'zego-shared/store/payments/autopay/selectors'
import { getResidentUserId } from 'zego-shared/store/resident/selectors'
import { getLastUsedPaymentMethod } from 'zego-shared/store/payments/methods/selectors'
import { marketplaceEnabled } from 'zego-shared/store/settings/selectors'
import { getProfile, hasAutopay, getLastTransactionDate } from 'zego-shared/store/authorization/selectors'
import { getPropertyAddress } from 'zego-shared/store/select/selectors'

export const getPendoVisitorProfile = state => {
 
  const hasMarketPlace = marketplaceEnabled(state)

  const fromSSO = getSSOPayment(state)

  const hasLevelCredit = isLevelCreditReportingEnabled(state)

  const hasAutoPay = hasAutopay(state)

  const hasCashPay = getCashPayEnabled(state)

  const lastTransaction = getLastTransactionDate(state)

  const lastPaymentMethod = getLastUsedPaymentMethod(state)

  const lastPayMethod =  lastPaymentMethod?.__typename ?? ''

  const lastAutoPay = getLastAutopay(state)

  const payUserId = getResidentUserId(state)
 
  const { 
    ach: hasACH,
    debit: hasDebit,
    cc: hasCredit,
    paypal: hasPayPal,
    plaid: hasPlaid
  } = getPaymentOptions(state)

  const {
    sub: userId,
    role
  } = getProfile(state)

  return {
    id: userId,
    payUserId,
    role,
    fromSSO,
    hasMarketPlace,
    hasLevelCredit,
    hasAutoPay,
    hasDebit,
    hasCredit,
    hasACH,
    hasPlaid,
    hasCashPay,
    hasPayPal,
    lastAutoPay,
    lastTransaction,
    lastPayMethod

  }
}

export const getPendoAccountProfile = state => {
  const {
    property: {
      id: propertyId,
      company_name: companyName,
      company_pms_id: companyID,
      company_uuid: companyUUID,
      name: propertyName
    } = {}
  } = getSelectedUnit(state)

  const {
    postalCode: zip,
    state: propertyState,
    country
  } = getPropertyAddress(state)

  const propertyType = getUnitType(state)

  return {
    id: propertyId,
    propertyName,
    propertyType,
    zip,
    state: propertyState,
    country,
    companyID,
    companyUUID,
    companyName
  }
}
