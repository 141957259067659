import { call, put, select, takeLatest } from 'redux-saga/effects'
import { print } from 'graphql'

import {
  fetchPlaidLinkTokenAction,
  fetchPlaidLinkTokenError,
  fetchPlaidLinkTokenStart,
  fetchPlaidLinkTokenSuccess,
  createPlaidBankAccount,
  fetchPlaidItemsAction,
  fetchPlaidItemsStart,
  fetchPlaidItemsSuccess,
  fetchPlaidItemsError,
  updatePlaidItemAction,
  updatePlaidItemError,
  updatePlaidItemSuccess,
  updatePlaidItemStart
} from '../actions'
import { hasPlaid } from '../selectors'
import {
  updateBankAccountStart,
  updateBankAccountSuccess,
  updateBankAccountError,
  selectPaymentMethod
} from '../../methods/actions'
import {
  CREATE_PLAID_LINK_TOKEN,
  CREATE_PLAID_BANK_ACCOUNT,
  UPDATE_PLAID_ITEM,
  createPlaidLinkTokenMutation,
  createPlaidBankAccountMutation,
  updatePlaidItemMutation,
  updateAndCreatePlaidBankAccountMutation,
  UPDATE_AND_CREATE_PLAID_BANK_ACCOUNT,
} from '../graphql/mutations'
import { getPlaidItemsQuery } from '../graphql/queries'
import api from '../../../../utils/api'
import { getPaymentToken } from '../../../authorization/selectors'
import { getGraphQlData } from '../../../../utils/graphql'
import { paymentMethodAdded, failedToReauthPlaid } from '../../../../utils/messages'
import { showSnackbar } from '../../../snackbar/actions'

export function* fetchPlaidLinkToken({ itemId }) {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    yield put(fetchPlaidLinkTokenStart())
    const query = print(createPlaidLinkTokenMutation)
    const response = yield call(
      api.graphqlQuery,
      tokens,
      query, {
        itemId
      }
    )
    const payload = yield call(
      getGraphQlData,
      response,
      CREATE_PLAID_LINK_TOKEN
    )
    yield put(fetchPlaidLinkTokenSuccess(payload))
  } catch (error) {
    yield put(fetchPlaidLinkTokenError(error))
  }
}

/**
 * Saves Bank Account using Plaid information, uses the same updateBankAccount in payments/methods/actions to trigger reducers
 * in order to update the methods in the list and set the current method
 * @param {{
 *  payload,
 *  residentId,
 *  isUpdate
 * }} bankAccount - Bank Account Information.
 * @param {Object} bankAccount.payload - Plaid Link Information gathered.
 * @param {string} bankAccount.residentId - Resident ID.
 * @param {boolean} isUpdate - defaults to false for regular Bank Account Creation. Set to true for Oauth reauth flow
 */
export function* savePlaidBankAccount({ payload, residentId, isUpdate = false }) {
  try {
    yield put(updateBankAccountStart())
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }

    const response = yield call(api.graphqlQuery, tokens, isUpdate ? print(updateAndCreatePlaidBankAccountMutation) : print(createPlaidBankAccountMutation), {
      residentId,
      ...payload
    })

    const rPayload = yield call(
      getGraphQlData,
      response,
      isUpdate ? UPDATE_AND_CREATE_PLAID_BANK_ACCOUNT : CREATE_PLAID_BANK_ACCOUNT
    )
    // the mutation returns a type PlaidBankAccount with same fields as BankAccount
    rPayload.__typename = 'BankAccount'
    rPayload.isPlaidBankAccount = true
    yield put(updateBankAccountSuccess(rPayload))
    yield put(selectPaymentMethod(rPayload.id))
    yield put(showSnackbar(paymentMethodAdded, 'success'))
    yield put(fetchPlaidItemsAction())
    if (isUpdate) yield put(updatePlaidItemAction({ itemId: payload.itemId, reauthRequired: false }))
  } catch (error) {
    yield put(updateBankAccountError(error))
    yield put(showSnackbar(error.message, 'error'))
  }
}

export function* fetchPlaidItems() {
  try {
    const isPlaidEnabled = yield select(hasPlaid)
    if (!isPlaidEnabled) return

    yield put(fetchPlaidItemsStart())
    const paymentToken = yield select(getPaymentToken)
    const query = getPlaidItemsQuery()
    const response = yield call(api.graphqlQuery, { paymentToken }, query)
    yield put(fetchPlaidItemsSuccess(response?.data?.getPlaidItems))
  } catch (err) {
    yield put(fetchPlaidItemsError(err))
  }
}

/**
 * @param {string} itemId - itemId of Plaid Item to be updated
 * @param {boolean} reauthRequired - flag for whether an item needs reauthorized.
 */
export function* updatePlaidItem({ itemId, reauthRequired }) {
  try {
    yield put(updatePlaidItemStart())
    const paymentToken = yield select(getPaymentToken)

    const tokens = { paymentToken }

    const query = print(updatePlaidItemMutation)

    const response = yield call(api.graphqlQuery, tokens, query, {
      itemId,
      reauthRequired,
    })

    const payload = yield call(
      getGraphQlData,
      response,
      UPDATE_PLAID_ITEM
    )
    yield put(updatePlaidItemSuccess(payload))
    yield put(fetchPlaidItemsAction())
  } catch (error) {
    yield put(updatePlaidItemError(error))
    yield put(showSnackbar(failedToReauthPlaid, 'error'))
  }
}

function* watchFetchPlaidLinkToken() {
  yield takeLatest(fetchPlaidLinkTokenAction().type, fetchPlaidLinkToken)
}

function* watchSavePlaidBankAccount() {
  yield takeLatest(createPlaidBankAccount().type, savePlaidBankAccount)
}

function* watchFetchPlaidItems() {
  yield takeLatest(fetchPlaidItemsAction().type, fetchPlaidItems)
}

function* watchUpdatePlaidItem() {
  yield takeLatest(updatePlaidItemAction().type, updatePlaidItem)
}

export default [
  watchFetchPlaidLinkToken(),
  watchSavePlaidBankAccount(),
  watchFetchPlaidItems(),
  watchUpdatePlaidItem()
]
