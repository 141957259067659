export default {
  nameOnCard: '',
  cardNumber: '',
  expiration: '',
  cvv: '',
  addressLine1: '',
  addressLine2: null,
  zip: '',
  city: '',
  state: ''
}
