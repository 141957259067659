export const getInstructions = (
  paymentDisclaimerMemo: string,
  isDebitCard: boolean,
  nsfFee: string | null,
  shouldShowBalanceChecksDisclaimer: boolean,
  isCreditCard: boolean
): Object[] => {
  let instructions = [
    {
      name: 'refundDisclaimer',
      text: `Once the payment begins processing, a refund of the amount
      owed can only be authorized through your management company.
      The fee will not be refunded.`
    },
    {
      name: 'chargebackDislaimer',
      text: `Please be advised that attempted chargebacks for Non-Fraudulent
      transactions through the Zego system will be subject to criminal
      investigation and these individuals will be prosecuted to the fullest
      extent of the law.`
    }
  ]

  if (isDebitCard) {
    instructions.unshift({
      name: 'debitCardDisclaimer',
      text: `For debit card payments, once the payment begins processing you may no
      longer void your transaction.`
    })
  }

  if (isCreditCard) {
    instructions.push({
      name: 'coloradoDisclaimer',
      text:
        `Colorado Property Residents: To cover the cost of processing a
          credit or charge card transaction, and pursuant to section 5-2-212, Colorado revised statutes, a seller or lessor may impose a processing
          surcharge in an amount not to exceed the merchant discount fee that the seller or lessor incurs in processing the sales or lease transaction.
          A seller or lessor shall not impose a processing surcharge on payments made by use of cash, a check, or a debit card or redemption of a gift card.`
    })
  }

  if (nsfFee) {
    instructions.push({
      name: 'echeckDislaimer',
      text: `E-check Transactions: In the event that my bank returns this transaction
      for Insufficient Funds (NSF), I authorize Zego to assess and process an
      automatic ${nsfFee} NSF Fee to the same account from which this payment
      was initiated.`
    })
  } else {
    instructions.push({
      name: 'echeckDislaimer',
      text: `E-check Transactions: In the event that my bank returns this transaction
      for Insufficient Funds (NSF), I authorize the management company to
      assess a fee based on my contract agreement.`
    })
  }

  if (paymentDisclaimerMemo) {
    instructions.push({
      name: 'disclaimerMemo',
      text: paymentDisclaimerMemo
    })
  }

  if (shouldShowBalanceChecksDisclaimer) {
    instructions.unshift({
      name: 'balanceChecksDisclaimer',
      text: `Zego will check the account balance using Plaid prior to completing the transaction.
      If there are insufficient funds to cover the transaction, the transaction will not process
      and you will be required to choose a different payment method or add a new account.`
    })
  }

  return instructions
}
