import { connect } from 'react-redux'
import SubNav from './SubNav'
import { getUnitId } from 'zego-shared/store/select/selectors'
import { marketplaceEnabled } from 'zego-shared/store/settings/selectors'
import { getFeatureFlag } from 'zego-shared/store/integrations/launchDarkly/selectors'
import { ldInitRequest } from 'zego-shared/store/integrations/launchDarkly/actions'

const getActivePage = url => {
  const pages = ['payments', 'workOrders', 'marketplace', 'downloadApp']
  const splitUrl = url.split('/')

  if (splitUrl.includes('payments')) return pages[0]
  if (splitUrl.includes('work-orders')) return pages[1]
  if (splitUrl.includes('marketplace')) return pages[2]
  if (splitUrl.includes('download-app')) return pages[3]
}

const mapStateToProps = (state, { currentURl }) => ({
  unitId: getUnitId(state),
  activePage: getActivePage(currentURl),
  marketplaceEnabled: marketplaceEnabled(state),
  featureFlag: getFeatureFlag(state)
})

const mapDispatchToProps = { ldInitRequest }

export default connect(mapStateToProps, mapDispatchToProps)(SubNav)
