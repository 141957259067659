// @flow
import React from 'react'
import { Typography } from '@material-ui/core'
import { GridItem } from '@Common/Grid'
import Button from '@Common/Button'
import Flex from '@Common/Flex'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import CustomSwitch from '@Common/Switch/CustomSwitch'
import { useDispatch } from 'react-redux'
import { creditReportingOptOut } from 'zego-shared/store/payments/creditReporting/actions'
import { DetailsItemType } from './types'
import DetailsItem from './DetailsItem'
import { StyledCard, ButtonWrapperStyled, OpOutText } from './styles'

type CreditReportingDetails = {
  basicInfo: DetailsItemType[],
  contactInfo: DetailsItemType[]
}

type Props = {
  match: { url: string },
  details: CreditReportingDetails,
  isOptedInLegacy: boolean,
  history: Object
}

const SaveButton = withAlertModal(({ showAlertModal }) => (
  <Button id="saveBtn" onClick={showAlertModal} primary>
    Save
  </Button>
))

export const LegacyCreditReporting = ({
  match,
  history,
  isOptedInLegacy,
  details
}: Props) => {
  const dispatch = useDispatch()
  const [checked, setIsChecked] = React.useState(isOptedInLegacy)

  const handleOptInChange = () => {
    setIsChecked(!checked)
  }

  const isOptInChecked = checked ? isOptedInLegacy : checked

  const { contactInfo, basicInfo } = details
  return (
    <GridItem columnStart={1} columnSpan={12}>
      <Flex justifySpaceBetween alignCenter>
        <Typography variant="h3" id="creditReportingHeader">
          Credit Reporting
        </Typography>
        <CustomSwitch
          elementId="optSwitch"
          isOptedIn={checked}
          handleChange={handleOptInChange}
          switchedOnText="Opted In"
          switchedOffText="Opted Out"
        />
      </Flex>
      {!isOptInChecked ? (
        <OpOutText>
          By opting out of credit reporting your information will no longer be
          reported monthly to the Credit Bureaus.
        </OpOutText>
      ) : (
        <StyledCard
          CardContentProps={{
            classes: {
              root: 'card-content-root'
            }
          }}>
          <Flex direction="column">
            {basicInfo.map((item: DetailsItemType) => {
              return <DetailsItem {...item} key={item.label} />
            })}
          </Flex>
          <Flex direction="column">
            {contactInfo.map((item: DetailsItemType) => {
              return <DetailsItem {...item} key={item.label} />
            })}
          </Flex>
        </StyledCard>
      )}
      <ButtonWrapperStyled marginTop={'56px'} justifyRight alignCenter>
        {!isOptInChecked && (
          <SaveButton
            id="saveBtn"
            alertTitle="Are you sure you wish to opt out of Credit Reporting? "
            onAlertClickYes={() =>
              dispatch(creditReportingOptOut(history.push))
            }
            onAlertClickNo={handleOptInChange}
          />
        )}
      </ButtonWrapperStyled>
    </GridItem>
  )
}

export default LegacyCreditReporting
