// @flow
import get from 'lodash/get'
import Dinero from 'dinero.js'
import { createSelector } from 'reselect'
import { formatDateMMDDYY } from '../../../../utils/date'

type PurchaseDetails = {
  purchaseDate: string,
  purchaseAmount: Object,
  purchaseRecipient: string,
  purchaseCCPayment: string,
  purchaseCCName: string
}

type Config = {
  eligible: boolean,
  levelCreditEligible: boolean,
  optedIn: boolean,
  levelCreditOptInState: string,
  creditReportingDetails: Object,
  defaultEmail: string,
  lookBackEligible: boolean,
  purchaseDetails: PurchaseDetails
}

type State = {
  payments: {
    creditReporting: {
      config: Config
    }
  }
}

const levelCreditOptInStateActive = 'active'
const levelCreditOptInStateInactive = 'inactive'

export const getCreditReportingConfig = (state: State): Config =>
  get(state, 'payments.creditReporting.config') || {}

export const getCreditReportingConfigDetails = (state: State): Config =>
  get(state, 'payments.creditReporting.config.creditReportingDetails', false)

export const getLookbackPurchaseDetails = (state: State): Config =>
  get(state, 'payments.creditReporting.config.purchaseDetails')

export const isCreditReportingEnabled = (state: State): boolean => {
  return get(state, 'payments.creditReporting.config.eligible')
}

export const getIsOptIn = (state: State): boolean => {
  return get(state, 'payments.creditReporting.config.optedIn')
}

export const getLevelCreditActive = (state: State): boolean => {
  return get(state, 'payments.creditReporting.config.levelCreditOptInState', null) === levelCreditOptInStateActive
}

export const getLevelCreditInactive = (state: State): boolean => {
  return get(state, 'payments.creditReporting.config.levelCreditOptInState', null) === levelCreditOptInStateInactive
}

export const getLevelCreditNonExistent = (state: State): boolean => {
  const optInState = get(state, 'payments.creditReporting.config.levelCreditOptInState', null)

  return (optInState !== levelCreditOptInStateActive && optInState !== levelCreditOptInStateInactive)
}

export const getLevelCreditDefaultEmail = (state: State): string => {
  return get(state, 'payments.creditReporting.config.defaultEmail')
}

export const hasCreditReportingDetails = (state: State): boolean => {
  const details = getCreditReportingConfigDetails(state)
  return Boolean(details)
}

export const hasPurchasedLookback = (state: State): boolean => {
  const details = getLookbackPurchaseDetails(state)
  return Boolean(details)
}

export const buildCreditReportingViewData = (
  creditReportingDetails: Object | null
) => {
  if (!creditReportingDetails) {
    return {
      basicInfo: [],
      contactInfo: []
    }
  }
  const buildInfo = (label: string = '', value: string = '') => ({
    label,
    value: value || ''
  })
  const {
    address: { address, city, postalCode, state },
    firstName,
    lastName,
    leaseMonthToMonth,
    leaseEndDate
  } = creditReportingDetails || { address: {} }
  const leaseEnd = leaseMonthToMonth
    ? 'Month to Month'
    : formatDateMMDDYY(leaseEndDate)
  return {
    basicInfo: [
      buildInfo('first name', firstName),
      buildInfo('last name', lastName),
      buildInfo('lease end', leaseEnd)
    ],
    contactInfo: [
      buildInfo('street', address),
      buildInfo('city', city),
      buildInfo('state', state),
      buildInfo('zip', postalCode)
    ]
  }
}

export const buildCreditReportingPurchaseViewData = (
  purchaseDetails: PurchaseDetails | null
) => {
  if (!purchaseDetails) {
    return {
      purchaseInfo: [],
      paymentInfo: []
    }
  }
  const buildInfo = (label: string = '', value: string = '') => ({
    label,
    value: value || ''
  })
  const {
    purchaseDate,
    purchaseAmount,
    purchaseRecipient,
    purchaseCCPayment,
    purchaseCCName
  } = purchaseDetails
  return {
    purchaseInfo: [
      buildInfo('date', purchaseDate),
      buildInfo('amount', Dinero(purchaseAmount).toFormat()),
      buildInfo('recipient', purchaseRecipient)
    ],
    paymentInfo: [
      buildInfo('name', purchaseCCName),
      buildInfo('payment', purchaseCCPayment)
    ]
  }
}

export const getCreditReportingDetails = createSelector(
  getCreditReportingConfigDetails,
  buildCreditReportingViewData
)

export const getCreditReportingPurchaseDetails = createSelector(
  getLookbackPurchaseDetails,
  buildCreditReportingPurchaseViewData
)

export const buildCreditReportingOptInPayload = (
  data: Object
): {
  firstName: string,
  lastName: string,
  address: string,
  address2: string,
  city: string,
  state: string,
  postalCode: string,
  leaseEndDate?: string,
  leaseMonthToMonth?: boolean,
  ssn: string,
  birthDate: string,
  email: string,
  phoneNumber: string
} => {
  const {
    confirmSnn,
    zegoTermsAndConditions,
    levelCreditTermsAndConditions,
    leaseEndDate,
    leaseMonthToMonth,
    ...rest
  } = data

  if (leaseEndDate && !leaseMonthToMonth) {
    rest.leaseEndDate = leaseEndDate
  }

  if (leaseMonthToMonth) {
    rest.leaseMonthToMonth = leaseMonthToMonth
  }

  return rest
}

const buildInitialValues = (details: Object | null) => {
  if (!details) return
  const {
    firstName,
    lastName,
    leaseMonthToMonth,
    leaseEndDate,
    email,
    phoneNumber,
    address: { address, address2, state, postalCode, city }
  } = details

  return {
    firstName,
    lastName,
    state,
    city,
    leaseMonthToMonth,
    postalCode,
    address,
    address2,
    leaseEndDate,
    email,
    phoneNumber
  }
}
export const getInitialValues = createSelector(
  getCreditReportingConfigDetails,
  buildInitialValues
)

export const isLevelCreditReportingEnabled = (state: State): boolean => {
  return get(state, 'payments.creditReporting.config.levelCreditEligible')
}

// $FlowFixMe
const emptyMonths = new Array(12).fill(false)

export const getLevelCreditTransactions = (state: State) => {
  const lcTrans = get(
    state,
    'payments.creditReporting.config.reportedTransactions',
    []
  )

  const lbTrans = get(
    state,
    'payments.creditReporting.config.lookbackReportedTransactions',
    []
  )

  return lcTrans.length > 0 || lbTrans.length > 0
    ? getTransactionsData(state, Object.values(lcTrans), Object.values(lbTrans))
    : {
        transactions: emptyMonths,
        years: [],
        monthsReported: []
      }
}

const getTotalMonthsReported = (transactions, years) => {
  let total = 0

  years.map(year => {
    total += transactions[year].length
    return total
  })

  return total
}

export const getTransactionsData = (
  state: Object,
  transactions: Array<Object>,
  lbTransactions: Array<Object>
) => {
  const months = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12'
  ]

  const extractedLCYears = transactions
    ? transactions.map(transaction => transaction.updated_at.split('-')[0])
    : []

  const extractedLBYears = lbTransactions
    ? lbTransactions.map(transaction => transaction.trans_date.split('-')[0])
    : []

  let years = [...new Set([...extractedLCYears, ...extractedLBYears])]

  // $FlowFixMe
  const sortYears = years.sort()

  const reportedTrans = {}
  const reportedLBTrans = {}
  const allReported = {}

  sortYears.forEach(year => {
    reportedTrans[year] = []
    reportedLBTrans[year] = []
    allReported[year] = []

    transactions.forEach(transaction => {
      if (transaction.updated_at.includes(year)) {
        reportedTrans[year].push(transaction.updated_at.split('-')[1])
      }
    })
    reportedTrans[year] = [...new Set(reportedTrans[year])]

    lbTransactions.forEach(transaction => {
      if (transaction.trans_date.includes(year)) {
        reportedLBTrans[year].push(transaction.trans_date.split('-')[1])
      }
    })
    reportedLBTrans[year] = [...new Set(reportedLBTrans[year])]

    months.forEach(month => {
      if (reportedLBTrans[year].includes(month)) {
        return allReported[year].push({ isLB: true, value: true })
      }
      if (reportedTrans[year].includes(month)) {
        return allReported[year].push({ isLC: true, value: true })
      }

      return allReported[year].push({ isLC: false, value: false })
    })

    return allReported
  })

  return {
    transactions: sortYears.length < 1 ? emptyMonths : allReported,
    years: sortYears,
    lookbackMonthsReported: getTotalMonthsReported(reportedLBTrans, years),
    monthsReported: getTotalMonthsReported(reportedTrans, years)
  }
}

export const isLookbackEnabled = (state: State): boolean => {
  return get(state, 'payments.creditReporting.config.lookBackEligible')
}
