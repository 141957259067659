// @flow

import React from 'react'
import { DialogActions, DialogContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Button from '../Button'
import {
  DialogStyled,
  ButtonsWrapper,
  DialogTitleStyled,
  StyledDialogContentText,
  Icon
} from './styles'
import { FlexItem } from '../Flex/FlexDirection'

type Props = {
  show: boolean,
  onClose: Function,
  title: string,
  message: string,
  buttons: [
    { onClick: Function, text: string },
    { onClick: Function, text: string }
  ],
  showCloseButton: boolean
}

class AlertModal extends React.Component<Props, {}> {
  renderContent = () => {
    const { message, title, children } = this.props
    const content = (
      <>
        <DialogTitleStyled id="alert-dialog-title">{title}</DialogTitleStyled>
        {message && (
          <DialogContent>
            <StyledDialogContentText id="alert-dialog-description">
              {message}
            </StyledDialogContentText>
          </DialogContent>
        )}
      </>
    )

    return children ? children : content
  }

  renderActions = () => {
    const { buttons = [] } = this.props

    return buttons.length ? (
      <DialogActions>
        <ButtonsWrapper fullWidth>
          {buttons.map((button, index) => (
            <React.Fragment key={`button.text${index}`}>
              <Button id={button.text} {...button}>
                {button.text}
              </Button>
              {index !== (0 || buttons.length - 1) && <FlexItem flex={0.2} />}
            </React.Fragment>
          ))}
        </ButtonsWrapper>
      </DialogActions>
    ) : null
  }

  render() {
    const { show, onClose, showCloseButton, renderBottom } = this.props

    return (
      <DialogStyled
        classes={{
          paper: 'dialog-paper'
        }}
        open={show}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {showCloseButton ? (
          <Icon aria-label="close" onClick={onClose}>
            <CloseIcon />
          </Icon>
        ) : null}
        {this.renderContent()}
        {this.renderActions()}
        {renderBottom && renderBottom()}
      </DialogStyled>
    )
  }
}

export default AlertModal
