// @flow
import React from 'react'
import { PaymentIcons } from '@icons'
import Flex from '@Common/Flex'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { paymentIcons } from '@utils/view/paymentMethods'

import { MethodStyled } from './styles'

type Icon = 'bank' | 'credit' | 'paypal' | 'mastercard'

type Props = {
  children: React.Node,
  name?: string,
  fee?: string,
  empty?: boolean,
  icon?: Icon,
  variant?: 'list' | 'box',
  account?: string
}

const PaymentMethod = ({
  children,
  name,
  fee,
  empty,
  icon,
  variant = 'box',
  account,
  ...props
}: Props) => {
  const size = icon ? paymentIcons[icon] : {}
  const isBox = variant === 'box'
  const theme = useTheme()
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'))
  const isSsoMobile =
    useMediaQuery(theme.breakpoints.down('sm')) && theme.ux.isSSO

  const Icon = () => {
    return (
      <Flex direction="column">
        {!empty && !isMdDown && <span>{name}</span>}
        {!empty && <PaymentIcons name={icon} {...size} />}
      </Flex>
    )
  }

  const Name = () => {
    if (isMdDown && !empty) {
      return (
        <span>
          {name} {account}
          <i>&#8211;</i>
        </span>
      )
    }
    return null
  }

  const Fee = () => {
    if (!isBox || !isMdDown) {
      return (
        <span>
          <b>{fee ? `fee ${fee}` : 'free'}</b>
        </span>
      )
    }
    if (isBox && isMdDown) {
      return <span>{fee ? `fee ${fee}` : 'free'}</span>
    }
    return null
  }

  return (
    <MethodStyled
      {...props}
      empty={empty}
      direction={isBox && !isMdDown ? 'column' : 'row'}
      justifyCenter={isBox}
      alignCenter
      justifySpaceBetween={!isBox || isMdDown}
      fullWidth
      isBox={isBox}>
      <Icon />
      <Flex
        justifySpaceBetween
        alignCenter
        style={!isBox ? { flex: '1' } : {}}
        marginLeft={isBox ? 0 : isSsoMobile ? '7px' : '27px'}>
        {children}
        {name && <Name />}
        {!empty && <Fee />}
      </Flex>
    </MethodStyled>
  )
}

export default PaymentMethod
