import { call, put, takeLatest } from 'redux-saga/effects'
import { print } from 'graphql'
import {
  getResidentBillingPref,
  residentPinLogin,
  UPDATE_RES_BILLING_INFO,
  VALIDATE_EMAIL_TOKEN,
  VERIFY_RESIDENT_PIN
} from '../actions'

import { showSnackbar } from '../../../snackbar/actions'
import { GET_BILLING_PREFS_Q, UPDATE_BILLING_PREF_Q, GET_BILLING_PREFS_WITH_STREET_Q, VERIFY_RESIDENT_PIN_Q } from '../graphql/queries'
import api from '../../../../utils/api'

export function* validateEmailToken({ token, includeStreet }) {
  try {
    const resident = yield call(
      api.graphqlQuery,
      { paymentToken: token },
      print(includeStreet ? GET_BILLING_PREFS_WITH_STREET_Q : GET_BILLING_PREFS_Q)
    )
    const billingPrefs = resident.data.getResidentBillingPreferences

    yield put(getResidentBillingPref(billingPrefs))
  } catch (e) {
    yield put(showSnackbar('Invalid Token', 'error'))
  }
}

export function* watchValidateEmailToken() {
  yield takeLatest(VALIDATE_EMAIL_TOKEN, validateEmailToken)
}

export function* updateBillingPreferences({ token, residentPrefs }) {
  try {
    var excludeSnackbar = residentPrefs.excludeSnackbar || false
    const residentPrefsParams = {
      billingType: parseInt(residentPrefs.preference),
      reasonCode: residentPrefs.reason,
      email: residentPrefs.email
    }

    const updatedResident = yield call(
      api.graphqlQuery,
      { paymentToken: token },
      print(UPDATE_BILLING_PREF_Q),
      residentPrefsParams
    )
    const updatedPrefs =
      updatedResident.data.updateResidentBillingPreferences.eBillResident

    yield put(getResidentBillingPref(updatedPrefs))
    if (!excludeSnackbar) {
      yield put(showSnackbar(residentPrefs.infoMessage ? residentPrefs.infoMessage : 'Billing Preferences Updated'))
    }
  } catch (e) {
    yield put(showSnackbar('Error while updating billing preference', 'error'))
  }
}

export function* watchUpdateBillingPreferences() {
  yield takeLatest(UPDATE_RES_BILLING_INFO, updateBillingPreferences)
}

export function* verifyResidentPin({ pin }) {
  try {
    const resident = yield call(
      api.graphqlQuery,
      {},
      print(VERIFY_RESIDENT_PIN_Q),
      { pin }
    )
    if (resident.data.verifyResidentPin) {
      yield put(residentPinLogin(resident.data.verifyResidentPin))
    } else {
      yield put(showSnackbar('Invalid Pin', 'error'))
    }
  } catch (e) {
    yield put(showSnackbar('Invalid Pin', 'error'))
  }
}

export function* watchVerifyResidentPin() {
  yield takeLatest(VERIFY_RESIDENT_PIN, verifyResidentPin)
}

export default [watchValidateEmailToken(), watchUpdateBillingPreferences(), watchVerifyResidentPin()]
