// @flow

import React from 'react'
import { Container } from '@material-ui/core'
import { Wrapper, Logo, CloseBtn, Text, Title } from './styles'
import Flex from '@Common/Flex'
import Button from '@Common/Button'
import CloseIcon from '@material-ui/icons/Close'
import PayLeaseLogo from '@images/pl_logo_white.png'

type Props = {
  magicLink: string,
  dismiss: () => void
}

const DownloadAppBanner = ({ magicLink, dismiss }: Props) => (
  <Wrapper>
    <Container>
      <Flex alignCenter>
        <CloseBtn onClick={dismiss}>
          <CloseIcon />
        </CloseBtn>
        <Logo src={PayLeaseLogo} />
        <Text flex={1}>
          <div>view in the</div>
          <Title>ZEGO App!</Title>
        </Text>
        <Button primary href={magicLink} component="a" role="link">
          Download
        </Button>
      </Flex>
    </Container>
  </Wrapper>
)

export default DownloadAppBanner
