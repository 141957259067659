// @flow

import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'
import ScrollOnClick from './views/Common/ScrollOnClick'
import ErrorBoundary from './views/Common/ErrorBoundary'
import ErrorAlertModal from './views/Common/ErrorAlertModal'
import LiveAgent from './integrations/LiveAgent'
import Pendo from './integrations/Pendo'

export default class App extends Component<{}> {  
  render() {
    return (
      <ErrorBoundary ErrorScreen={ErrorAlertModal}>
        <BrowserRouter>
          <ScrollOnClick />
          <Routes />
          <LiveAgent />
          <Pendo />
        </BrowserRouter>
      </ErrorBoundary>
    )
  }
}
