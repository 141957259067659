// @flow
import styled from '@emotion/styled'
import { Grid } from '@material-ui/core'

export const WelcomeText = styled('h2')`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.space.s100};
  line-height: 120px;
  text-align: center;
  margin: 0;
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H3};
  }
`

export const SubText = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  line-height: 24px;
  text-align: center;
`

export const PasswordInputs = styled(Grid)`
  width: 210px;
  box-sizing: content-box;

  & > div:first-of-type {
    margin-bottom: ${({ theme }) => theme.space.s10};
  }

  & > div {
    margin-bottom: ${({ theme }) => theme.space.s10};

    & input {
      height: 35px !important;
    }

    & fieldset {
      border-radius: 31px;
    }
  }

  & button:first-of-type {
    padding: 0;
  }

  & button:last-of-type {
    text-transform: capitalize;
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  }
`

export const FormStyled = styled('form')`
  margin-top: ${({ theme }) => theme.space.s75};

  & button:last-of-type {
    text-transform: capitalize;
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  }
`

export const ButtonWrapper = styled(Grid)`
  margin: ${({ theme }) => theme.space.s120} auto
    ${({ theme }) => theme.space.s172};
  text-align: center;
`
