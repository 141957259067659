import { fetchPaymentOptionFeesSuccess } from '../actions'

const fees = (state = {}, action) => {
  switch (action.type) {
    case fetchPaymentOptionFeesSuccess().type:
      return action.payload

    default:
      return state
  }
}

export default fees
