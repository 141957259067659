import gql from 'graphql-tag'

export const CREDIT_REPORTING_CONFIG = gql`
  fragment CreditReportingConfig on CreditReportingConfig {
    eligible
    levelCreditEligible
    optedIn
    levelCreditOptInState
    lookBackEligible
    defaultEmail
    creditReportingDetails {
      firstName
      lastName
      email
      phoneNumber
      address {
        address
        address2
        city
        state
        postalCode
        country
      }
      leaseMonthToMonth
      leaseEndDate
    }
    purchaseDetails {
      purchaseDate      
      purchaseAmount {
        amount
        currency
      }
      purchaseRecipient
      purchaseCCPayment
      purchaseCCName
    }
  }
`
