import { createFetchActions } from '../../../helpers/actions/creators'

export const PAYMENT_OPTION_FEES = 'PAYMENTOPTIONS_FEES'

export const {
  fetchAction: fetchPaymentOptionFeesAction,
  fetchStartAction: fetchPaymentOptionFeesStart,
  fetchSuccessAction: fetchPaymentOptionFeesSuccess,
  fetchErrorAction: fetchPaymentOptionFeesError
} = createFetchActions(PAYMENT_OPTION_FEES)

export const FEE_TYPE = {
  FIXED: 'fixed',
  VARIABLE: 'variable',
  NORMAL: 'normal'
}

export const fetchPaymentOptionFees = (residentId, paymentFields) =>
  fetchPaymentOptionFeesAction({
    residentId,
    paymentFields,
    feeType: FEE_TYPE.NORMAL
  })

export const fetchPaymentFixAutoPayFees = paymentFields =>
  fetchPaymentOptionFeesAction({
    paymentFields,
    feeType: FEE_TYPE.FIXED
  })

export const fetchPaymentVarAutoPayFees = () =>
  fetchPaymentOptionFeesAction({
    feeType: FEE_TYPE.VARIABLE
  })
