// @flow
import { string, object, addMethod, date, boolean } from 'yup'
import moment from 'moment'
import {
  CVVRegex,
  firstNameLastNameRegex,
  numberRegex,
  validateDate,
  zipCodeRegex
} from '@utils/validationUtils'

export const EXPIRATION_FORMAT = 'MM/YY'

addMethod(date, 'format', validateDate)

export const cardSchema = () => {
  return object().shape({
    nameOnCard: string()
      .min(2, 'Name too short')
      .max(50, 'Name length exceeded')
      .matches(firstNameLastNameRegex, {
        message: 'Full name is required'
      })
      .required('Name on card is required'),
    addressLine1: string()
      .min(2, 'Invalid billing address')
      .required('Billing Address is required')
      .trim(),
    expiration: date()
      .format(EXPIRATION_FORMAT)
      .typeError('Expiration date is invalid')
      .min(
        moment()
          .subtract('M', 1)
          .toDate(),
        'Expiration date is past'
      )
      .required('Expiration date is required'),
    state: string()
      .min(1, 'Invalid state')
      .required('State is required')
      .trim(),
    city: string()
      .min(2, 'Invalid city name')
      .required('City is required')
      .trim(),
    zip: string()
      .matches(zipCodeRegex, {
        message: 'Invalid zip code'
      })
      .required('Zip code is required'),
    cvv: string()
      .matches(CVVRegex, {
        message: 'CVV must be 3 or 4 digits'
      })
      .required('CVV is required'),
    cardNumber: string()
      .min(13, 'Invalid card number')
      .max(19, 'Invalid card number')
      .matches(numberRegex, {
        message: 'Card number must be numeric'
      })
      .required('Card number is required')
  })
}

export const requestPaymentEmailSchema = object({
  email: string()
    .email('Invalid email address entered')
    .required(),
  saveEmail: boolean().default(false)
})
