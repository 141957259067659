// @flow
import React from 'react'
import moment from 'moment'
import isEqual from 'lodash/isEqual'
import { useSelector } from 'react-redux'
import Flex from '@Common/Flex'
import { Select, FormGroup } from '@Common/Form'
import { formatDateMMDDYY, formatDateMMYY } from 'zego-shared/utils/date'
import { getScheduleConfig } from 'zego-shared/store/payments/autopay/selectors'
import { FREQUENCIES } from 'zego-shared/store/payments/autopay/constants'
import { dateTypes, getInputValue } from '@utils/view/autoPaySchedule'
import { Grid } from '@material-ui/core'
import ScheduleInput from './ScheduleInput'
import { ScheduleStyled } from './styles'
import type { AutoPayScheduleProps as Props } from './types'

type AllowedDays = [number, number]

type HandleFirstPaymentChangeArg = {
  leftSectionValue: string,
  date: Date,
  selectedDay: string
}

const lastPaymentOptions = [
  { label: dateTypes.indefinite, value: dateTypes.indefinite },
  { label: dateTypes.pickDate, value: dateTypes.pickDate }
]

const frequencies = [
  { label: 'Monthly', value: FREQUENCIES.MONTHLY },
  { label: 'Quarterly', value: FREQUENCIES.QUARTERLY },
  { label: 'Annually', value: FREQUENCIES.ANNUALLY },
  { label: 'Biannually', value: FREQUENCIES.BIANNUALLY }
]

export const getStartMonth = ([startDay, endDay]: AllowedDays): Date => {
  const today: number = moment().date()
  if (today < startDay || today < endDay) {
    return new Date()
  }
  return moment()
    .add(1, 'month')
    .toDate()
}

export const getFirstPaymentDate = (
  dateType: string,
  date: Date,
  [startDay, endDay]: AllowedDays
) => {
  const getDate = (day: number) => {
    const jsd = new Date()
    const today = moment().date()
    const _date = moment(new Date(jsd.getFullYear(), jsd.getMonth(), day, 12))
    return today >= day ? _date.add(1, 'month') : _date
  }

  if (dateTypes.firstDay === dateType) {
    return getDate(1)
  }

  if (dateTypes.lastDay === dateType) {
    if (startDay > endDay) {
      const lastDayOfTheMth: number = moment()
        .endOf('month')
        .date()
      return getDate(lastDayOfTheMth)
    }
    return getDate(endDay)
  }

  return date
}

export const getHandleFirstPaymentChange = (
  setFirstPayment: Function,
  setSchedule: Object => void,
  schedule: Object,
  allowedDaysRange: AllowedDays
): (HandleFirstPaymentChangeArg => void) => ({
  leftSectionValue,
  date,
  selectedDay
}) => {
  setFirstPayment(leftSectionValue)
  setSchedule({
    ...schedule,
    startDate: getFirstPaymentDate(leftSectionValue, date, allowedDaysRange),
    selectedDay
  })
}

type HandleLastPaymentChangeArg = {
  leftSectionValue: Object,
  date: Date
}

export const getHandleLastPaymentChange = (
  setLastPayment: Function,
  setSchedule: Object => void,
  schedule: Object
): (HandleLastPaymentChangeArg => void) => ({ leftSectionValue, date }) => {
  setLastPayment(leftSectionValue)
  setSchedule({ ...schedule, endDate: date })
}

export const getHandleFrequencyChange = (
  setSchedule: Object => void,
  schedule: Object
): ((event: SyntheticInputEvent<>) => void) => ({ target: { value } }) => {
  setSchedule({ ...schedule, frequency: value })
}

const AutoPaySchedule = ({ onChange, value = {} }: Props) => {
  const config = useSelector(getScheduleConfig)
  const { allowIndefinite, allowedDaysRange } = config
  const [firstPayment, setFirstPayment] = React.useState(value.firstPayment)
  const [lastPayment, setLastPayment] = React.useState(allowIndefinite && !value.lastPayment ? dateTypes.indefinite : value.lastPayment)
  const [schedule, setSchedule] = React.useState(value)
  const { frequency, startDate, endDate } = schedule

  const handleFirstPaymentChange = getHandleFirstPaymentChange(
    setFirstPayment,
    setSchedule,
    schedule,
    allowedDaysRange
  )

  const handleLastPaymentChange = getHandleLastPaymentChange(
    setLastPayment,
    setSchedule,
    schedule
  )
  const handleFrequencyChange = getHandleFrequencyChange(setSchedule, schedule)

  React.useEffect(() => {
    if (!isEqual(schedule, value)) {
      onChange({ ...schedule, lastPayment, firstPayment })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule])

  return (
    <ScheduleStyled
      CardContentProps={{
        classes: {
          root: 'card-content-root'
        }
      }}>
      <Flex alignStart justifySpaceBetween marginBottom="21px">
        <h5>Schedule</h5>
      </Flex>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <FormGroup
            id="frequencyDropDown"
            label="Frequency"
            classes={{ root: 'form-group' }}>
            <Select
              options={frequencies}
              initialValue={frequency}
              onChange={handleFrequencyChange}></Select>
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormGroup
            id="firstPayDropDown"
            label="First Payment"
            classes={{ root: 'form-group' }}>
            <ScheduleInput
              value={getInputValue(startDate, firstPayment, formatDateMMDDYY)}
              allowedDays={allowedDaysRange}
              leftSectionOptions={[]}
              leftSectionValue="Pick a Date"
              onDone={handleFirstPaymentChange}
              selectedDay={schedule.selectedDay}
              DayPickerProps={{
                initialMonth: getStartMonth(allowedDaysRange)
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormGroup
            id="lastPayDropDown"
            label="Last Payment"
            classes={{ root: 'form-group' }}>
            <ScheduleInput
              value={getInputValue(endDate, lastPayment, formatDateMMYY)}
              leftSectionValue={lastPayment}
              date={endDate}
              leftSectionOptions={
                allowIndefinite
                  ? lastPaymentOptions
                  : lastPaymentOptions.slice(1)
              }
              onDone={handleLastPaymentChange}
              showMonth
            />
          </FormGroup>
        </Grid>
      </Grid>
    </ScheduleStyled>
  )
}

export default AutoPaySchedule
