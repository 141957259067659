import { createFetchActions } from '../../../helpers/actions/creators'

export const SSO_PAYMENT_FETCH_SECTION = 'SSOPAYMENT'

export const {
  fetchAction: fetchActionSSOPayment,
  fetchStartAction: fetchSSOPaymentStart,
  fetchSuccessAction: fetchSSOPaymentSuccess,
  fetchErrorAction: fetchSSOPaymentError
} = createFetchActions(SSO_PAYMENT_FETCH_SECTION)

export const fetchSSOPayment = fetchActionSSOPayment
