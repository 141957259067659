// @flow
import React from 'react'
import MainContainer from '../MainContainer'
import Button from '@Common/Button'
import { StyledGrid, ResidentWebLink } from './styles'
import { WelcomeText, SubText, Disclaimer } from '../styles'

type Props = {
  magicLink: string
}
const MobileApp = ({ magicLink }: Props) => (
  <MainContainer>
    <WelcomeText>Success!</WelcomeText>
    <SubText>
      Your account is all setup! To continue, click the button below to download
      the app and login with your email and password.
    </SubText>
    <StyledGrid container justify="center">
      <a href={magicLink}>
        <Button disableRipple long primary type="submit">
          Download Mobile App
        </Button>
      </a>
    </StyledGrid>
    <Disclaimer>
      *Sorry, our mobile experience is only available via the official
      iOS/Android resident app at this time. Otherwise, please use a desktop
      browser and go to{' '}
      <ResidentWebLink href="https://resident.paylease.com">
        resident.paylease.com
      </ResidentWebLink>
      .
    </Disclaimer>
  </MainContainer>
)

export default MobileApp
