// @flow
import styled from '@emotion/styled'
import { IconButton } from '@material-ui/core'

export const IconButtonStyled = styled(IconButton)`
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  border: ${({ color, theme }) => `2px solid ${(color ? color : theme.palette.secondary.main)}`};
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  margin-left: 17px;

  & svg {
    height: 15px;
    width: 15px;
    color: ${({ color, theme }) => (color ? color : theme.palette.secondary.main)};

    ${({ theme }) => [theme.breakpoints.down('md')]} {
      height: 14px;
      width: 14px;
    }
  
    ${({ theme }) => [theme.breakpoints.down('sm')]} {
      height: 12px;
      width: 12px;
    }
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 28px;
    width: 28px;
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    height: 24px;
    width: 24px;
  }
`
