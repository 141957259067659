// @flow
import React from 'react'
import { ProgressBarStyled } from './styles'

type Props = {
  value?: number
}
const ProgressBar = ({ value = 1, ...props }: Props) => {
  return (
    <ProgressBarStyled
      {...props}
      variant="determinate"
      value={value}
      classes={{ bar: 'bar' }}
    />
  )
}

export default ProgressBar
