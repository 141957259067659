import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getActiveOptions,
  getShowCardNetworks
} from 'zego-shared/store/payments/options/selectors'
import {
  getEnabledPaymentMethods,
  paypalDetails
} from 'zego-shared/store/payments/methods/selectors'
import { setPaymentMethod } from 'zego-shared/store/payments/methods/actions'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  updateBankAccount,
  updateCard,
  selectPaymentMethod
} from 'zego-shared/store/payments/methods/actions'

import PaymentMethods from './VariantSwitcher'
import { paymentMethodProps, methodTypes } from '@utils/view'
import { getFeatureFlagValue } from 'zego-shared/store/integrations/launchDarkly/selectors'
import { featureFlags } from '@utils/view/paymentMethods'

const fetchingSelector = createIsFetchingSelector(['paymentoptions'])

const mapStateToProps = state => {
  return {
    paymentOptions: getActiveOptions(state),
    paymentMethods: getEnabledPaymentMethods(state),
    isFetching: fetchingSelector(state),
    showCardNetworks: getShowCardNetworks(state),
    paymentMethodProps,
    testVariant: getFeatureFlagValue(featureFlags.PAYMENT_METHODS)(state)
  }
}

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { residentId }
    }
  }
) => {
  return {
    saveActions: {
      [methodTypes.bankAccount]: payload =>
        dispatch(updateBankAccount(residentId, payload)),
      [methodTypes.card]: (payload, isDebitCard) =>
        dispatch(updateCard(payload, isDebitCard))
    },
    selectPaymentMethod: id => dispatch(selectPaymentMethod(id)),
    setPaymentMethodToPayPal: () => dispatch(setPaymentMethod(paypalDetails))
  }
}

const PaymentMethodsWithFetchData = withFetchData(PaymentMethods)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsWithFetchData)
)
