import styled from '@emotion/styled'
import MaterialCard from '@material-ui/core/Card'
import RectangleImage from '../../../images/rectangle_plain.png'
import greenFlare from '@images/rectangle_marketplace_details.png'

export const PageTitle = styled('div')`
  margin-bottom: 10px;
  line-height: 40px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  font-size: ${({ theme }) => theme.custom.font.size.H3};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
  }
`

export const GreenTopFlare = styled('div')`
  background-image: ${`url(${greenFlare})`};
  width: 270px;
  height: 31px;
  position: relative;
  left:-70px;
  bottom:-45px;
  z-index: -1;
`

export const DetailsImageSeparator = styled('div')`
  height: 3px;
  width: 15%;
  left: 0px;
  margin: 5px 0px 5px 0px;
  background: #59C09B;
`

export const DetailsImageHeader = styled('div')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  min-height: 24px;
  line-height: 19px;

  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    line-height: 28px;
  }
`

export const DetailsImageCard = styled(MaterialCard)`
  padding: 15px 0px 15px 15px;
  display: flex;
  flex-direction: column;
  margin: 23px 22px;
`

export const DetailsSummaryCard = styled('div')`
  padding: 15px 15px 15px 0px;
  display: flex;
  flex-direction: column;
  margin: 23px 22px;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    padding: 5px;
    margin: 0px;
  }
`

export const DetailsImage = styled('img')`
  flex: 0 0 33%;
  padding: 25px 35px 35px 20px;
  border: 1px;
  display: flex;
  flex-direction: row;
  max-width: 350px;
  max-height: 280px;
`

export const DetailsLabel = styled('span')`
  align-self: flex-start;
  padding: 0px 5px 0px 0px;
  color: ${({ theme }) => theme.palette.common.midnight};
  margin: 0;
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  position: relative;
`

export const DetailsText = styled('span')`
  align-self: flex-start;
  color: ${({ theme }) => theme.palette.common.midnight};
  margin: 0;
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  position: relative;
`

export const DetailsContent = styled('div')`
  flex: 1 0;
  padding: 10px 0 10px 5px;
  align-self: flex-start;
  overflow: hidden;
  position: relative;
`

export const RedeemDiv = styled('div')`
  position: relative;
  padding: 20px 0px 0px 0px;
  margin-left: auto;
  margin-right: 0px;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    margin-right: auto;
  }
`

export const RedeemLink = styled('a')`
  cursor: pointer;
  text-align: justify;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.md};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  background-image: url(${RectangleImage});
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  right: 0px;
  padding: 6px 22px 8px 12px;
`