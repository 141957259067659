import gql from 'graphql-tag'
import { PLAID_BANK_ACCOUNT_FIELDS } from './fragments'

export const CREATE_PLAID_LINK_TOKEN = 'createPlaidLinkToken'
export const CREATE_PLAID_BANK_ACCOUNT = 'createPlaidBankAccount'
export const UPDATE_AND_CREATE_PLAID_BANK_ACCOUNT = 'updateAndCreatePlaidBankAccount'
export const UPDATE_PLAID_ITEM = 'updatePlaidItem'

export const createPlaidLinkTokenMutation = gql`
  mutation($itemId: String) {
    ${CREATE_PLAID_LINK_TOKEN} (itemId: $itemId) {
      token
  }
}
`

export const updatePlaidItemMutation = gql`
mutation($itemId: String!, $reauthRequired: Boolean!) {
  ${UPDATE_PLAID_ITEM}(itemId: $itemId, reauthRequired: $reauthRequired) {
      itemId,
      reauthRequired
    }
  }
`

export const createPlaidBankAccountMutation = gql`
  mutation(
    $bankName: String!
    $accountHolderName: String!
    $accountId: String!
    $token: String!
    $linkSessionId: String!
    $accountName: String!
    $accountMask: String!
    $institutionId: String!) {
    ${CREATE_PLAID_BANK_ACCOUNT}(
      bankName: $bankName,
      accountHolderName: $accountHolderName,
      accountId: $accountId,
      token: $token,
      linkSessionId: $linkSessionId,
      accountName: $accountName,
      accountMask: $accountMask,
      institutionId: $institutionId) {
      ...PlaidBankAccountFields
    }
  }
  ${PLAID_BANK_ACCOUNT_FIELDS}
`

export const updateAndCreatePlaidBankAccountMutation = gql`
mutation(
    $bankName: String!
    $accountHolderName: String!
    $accountId: String!
    $linkSessionId: String!
    $accountName: String!
    $accountMask: String!
    $institutionId: String!
    $itemId: String!) {
    ${UPDATE_AND_CREATE_PLAID_BANK_ACCOUNT}(
      bankName: $bankName,
      accountHolderName: $accountHolderName,
      accountId: $accountId,
      linkSessionId: $linkSessionId,
      accountName: $accountName,
      accountMask: $accountMask,
      institutionId: $institutionId
      itemId: $itemId) {
      ...PlaidBankAccountFields
    }
  }
  ${PLAID_BANK_ACCOUNT_FIELDS}
`

