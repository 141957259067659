// @flow
import React from 'react'
import Divider from '@material-ui/core/Divider'
import { useTheme } from '@material-ui/core/styles'

import List from '../../../../Common/ListSimple'
import Flex from '../../../../Common/Flex'
import type { CreditCardAccount } from '../../../types'

const getListItems = (account: CreditCardAccount) => {
  const { expirationMonth, expirationYear, cardHolderName, lastFour } = account
  return [
    {
      label: 'Name on Card',
      value: cardHolderName
    },
    {
      label: 'Credit Card Number',
      value: lastFour
    },
    {
      label: 'Expiration Date',
      value: `${expirationMonth}/${expirationYear}`
    }
  ]
}

const CardAccountDetailsMobile = (props: {
  account: CreditCardAccount,
  Network: Object
}) => {
  const { account, Network } = props
  const items = getListItems(account)
  const theme = useTheme()
  return (
    <Flex direction="column" justifyCenter>
      <Flex justifyCenter fullWidth marginBottom={theme.space.s10}>
        {Network}
      </Flex>
      <Divider />
      <List items={items} divider />
      <Divider />
    </Flex>
  )
}

export default CardAccountDetailsMobile
