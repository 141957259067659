// @flow
export const BANK_ACCOUNT_TYPE = 'Bank acct'
export const CREDIT_CARD_TYPE = 'Credit card'
export const DEBIT_CARD_TYPE = 'Debit card'
export const PAYPAL_TYPE = 'PayPal'
export const APPLE_PAY_TYPE = 'Apple Pay'

export const methodTypes = {
  bankAccount: 'bank-account',
  card: 'card',
  paypal: 'paypal',
  applePay: 'apple-pay'
}

export const paymentMethodTypes = {
  BANK: 'BankAccount',
  CREDIT: 'CreditCard',
  DEBIT: 'DebitCard',
  PAYPAL: 'PayPal'
}

export const featureFlags = {
  PAYMENT_METHODS: 'payment-methods'
}

export const paymentMethodProps = {
  [BANK_ACCOUNT_TYPE]: {
    methodType: methodTypes.bankAccount,
    icon: 'bank'
  },
  [CREDIT_CARD_TYPE]: {
    methodType: methodTypes.card,
    icon: 'credit',
    isDebitCard: false
  },
  [PAYPAL_TYPE]: {
    methodType: methodTypes.paypal,
    icon: 'paypal'
  },
  [DEBIT_CARD_TYPE]: {
    methodType: methodTypes.card,
    icon: 'credit',
    isDebitCard: true
  },
  [APPLE_PAY_TYPE]: {
    methodType: methodTypes.applePay,
    icon: 'apple-pay'
  }
}

export const paymentIcons = {
  bank: { width: 60, height: 32 },
  credit: { width: 47, height: 38 },
  paypal: { width: 29, height: 33 },
  mastercard: { width: 45, height: 29 },
  'apple-pay': { width: 45, height: 39 }
}

export type PaymentsNavLink = {
  name: string,
  url: string
}

export const isDebitCard = (paymentMethod: Object) =>
  paymentMethod.typeName === paymentMethodTypes.DEBIT

export const isBankAccount = (paymentMethod: Object) =>
  paymentMethod.typeName === paymentMethodTypes.BANK

export const isPayPal = (paymentOption: Object) =>
  paymentOption.name === 'PayPal'

export const isCard = (paymentMethod: ?Object) =>
  [paymentMethodTypes.DEBIT, paymentMethodTypes.CREDIT].includes(
    paymentMethod?.typeName
  )

export const getPaymentsMenuItems = (
  residentId: string,
  hasStatements: boolean,
  showCreditReporting: boolean,
  nativePayerWithoutPayment: boolean,
  pmSettings: Object
): Array<PaymentsNavLink> => {
  const paymentsNavLinks = [
    {
      name: 'Summary',
      url: `/payments/summary`,
      enabled: !pmSettings.disableSummaryMenuTab
    },
    {
      name: 'Auto Pay',
      url: `/payments/${residentId}/auto-pay`,
      enabled: !pmSettings.disableAutopayMenuTab
    },
    {
      name: 'One-Time Payment',
      url: `/payments/${residentId}/one-time`,
      enabled: !pmSettings.disableMakePaymentMenuTab
    },
    {
      name: 'Payment Methods',
      url: `/payments/${residentId}/methods`,
      enabled: true
    },
    {
      name: 'History',
      url: `/payments/${residentId}/history`,
      enabled: true
    },
    {
      name: 'Credit Reporting',
      url: `/payments/${residentId}/credit-reporting`,
      enabled: showCreditReporting
    },
    {
      name: 'Statements',
      url: `/payments/${residentId}/statements`,
      enabled: hasStatements
    }
  ]

  return filterPaymentsNavLinks(paymentsNavLinks, nativePayerWithoutPayment)
}

const filterPaymentsNavLinks = (
  navLinks: Array<Object>,
  nativePayerWithoutPayment: boolean = false
) =>
  navLinks
    .filter(navLink => navLink.enabled)
    .map(navLink => ({
      name: navLink.name,
      url: nativePayerWithoutPayment ? '#' : navLink.url
    }))

export const sortDebitCardsFirst = (paymentMethods: Object) => {
  const sortedArr = [
    ...paymentMethods.filter(item => item.feeName === DEBIT_CARD_TYPE),
    ...paymentMethods.filter(item => item.feeName !== DEBIT_CARD_TYPE)
  ]
  return sortedArr
}

export const discardSelectedMethod =
  (selectedMethod: Object) => (method: Object) => {
    return method.id !== selectedMethod.id
  }
