// @flow
import React from 'react'
import type { Node } from 'react'
import ReactDOM from 'react-dom'

import { MOBILE_BTNS_CLASS } from './styles'

type Props = { children: Node }

export const PORTAL_ID = 'mobile-btn-root'
export const ADD_ELEMENT_EVENT = 'add-portal-element'
export const REMOVE_ELEMENT_EVENT = 'remove-portal-element'

export class MobileBtnsPortal extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.portalRoot = document.getElementById(PORTAL_ID)
    this.el = document.createElement('div')
    this.el.classList.add(MOBILE_BTNS_CLASS)
  }

  componentDidMount() {
    if (this.portalRoot) {
      this.portalRoot.appendChild(this.el)
      this.portalRoot.dispatchEvent(new CustomEvent(ADD_ELEMENT_EVENT))
    }
  }

  componentWillUnmount() {
    if (this.portalRoot) {
      this.portalRoot.removeChild(this.el)
      this.portalRoot.dispatchEvent(new CustomEvent(REMOVE_ELEMENT_EVENT))
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}
