// @flow
import React, { useEffect, useState } from 'react'
import { methodTypes } from '@utils/view'
import AddPaymentMethod from './AddPaymentMethod'
import PaymentMethodDetails from './PaymentMethodDetails'

type Props = {
  paymentMethods: Object[],
  value: { id: string, fee: string, name: string, typeName: string },
  paymentMethodProps: Object,
  selectPaymentMethod: string => mixed,
  paymentOptions: Object[],
  setHideBtns: ?Function,
  showNotes: boolean,
  onContinueClicked: Function,
  onPayPalApprove: Function,
  createPayPalOrder: Function,
  setPaymentMethodToPayPal: Function,
  setHideBtns: Function,
  onSelectPayPal: Function,
  setMethod: Function,
  selectedPaymentMethod: Object,
  saveActions: { [String]: Function }
}

const PaymentMethods = ({
  saveActions,
  selectedPaymentMethod: selectedMethod,
  setPaymentMethodToPayPal,
  onSelectPayPal,
  setHideBtns,
  setMethod,
  ...otherProps
}: Props) => {
  const [showAddPayment, setShowAddPayment] = useState()
  // Hide AddPaymentMethod after successfully adding payment
  useEffect(() => {
    if (showAddPayment && !selectedMethod.methodType) {
      setShowAddPayment(false)
    }
  }, [showAddPayment, selectedMethod])

  const onAddPaymentMethod = method => {
    if (method.methodType === methodTypes.paypal) {
      setPaymentMethodToPayPal()
      onSelectPayPal()
      setTimeout(() => setHideBtns(false))
    } else {
      setShowAddPayment(true)
      setMethod(method)
      setTimeout(() => setHideBtns(true)) // Delay slightly before run
    }
  }

  const hideAddPaymentMethod = () => {
    setMethod({})
    setShowAddPayment(false)
  }

  const savePaymentMethod = (details: Object) => {
    const { methodType, isDebitCard } = selectedMethod
    saveActions[methodType](details, isDebitCard)
  }

  if (showAddPayment) {
    return (
      <AddPaymentMethod
        cancel={hideAddPaymentMethod}
        methodType={selectedMethod?.methodType}
        savePayment={savePaymentMethod}
      />
    )
  }

  return (
    <PaymentMethodDetails
      {...otherProps}
      setHideBtns={setHideBtns}
      onAddPaymentMethod={onAddPaymentMethod}
    />
  )
}

export default PaymentMethods
