// @flow
import styled from '@emotion/styled'
import { convertHexToRGB } from '@utils/view/common'
import Hidden from '@material-ui/core/Hidden'

export const MOBILE_BTNS_CLASS = 'mobile-btns'

export const ButtonWrapperStyled = styled('div')`
& .${MOBILE_BTNS_CLASS} {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space.s20};
  padding: ${({ theme }) => ` ${theme.space.s30}`};
  background-color: ${({ theme }) => theme.palette.common.white};
  left: 0;
  right: 0;
  position: relative;
  bottom: 0;
  box-shadow:${({ theme }) =>
    `0px 3px 4px 1px ${convertHexToRGB(theme.palette.common.midnight, 0.3)};`}

  & button {
    width: 100%;
    text-transform: unset;
    margin: 0;
    height: 40px;
  }
}

`

export const HiddenStyled = styled(Hidden)`
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 5;
`
