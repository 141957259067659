// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import Button from '@Common/Button'
import Loader from '@Common/Loader'
import { capitalizeWordDashToSpace } from '@utils/stringUtils'
import Hidden from '@material-ui/core/Hidden'
import { methodTypes } from '@utils/view/paymentMethods'
import {
  FlexStyled,
  ButtonWrapperStyled,
  CardDisclaimerWrapper
} from './styles'
import AddPaymentMethodForm from '../../AddPaymentMethodForm'
import schemas, { getCardSchema } from '../../AddPaymentMethodForm/schemas'
import initialValues from '../../AddPaymentMethodForm/initialValues'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import { StyledMobileButtons } from '../../styles'
import CardDisclaimer from '../../CardDisclaimer'
import { getCancelOptionBehaviorOverride } from 'zego-shared/store/overrides/selectors'
type Props = {
  methodType: string,
  countries: Object[],
  states: Object[],
  cancel: Object => mixed,
  savePayment: Function,
  isSaving: boolean
}
const AddPaymentMethod = ({
  cancel,
  countries = [],
  states = [],
  methodType = '',
  savePayment,
  isSaving
}: Props) => {
  const theme = useTheme()
  const [isInternational, setIsInternational] = React.useState(false)
  const [values, setValues] = React.useState()
  const cancelOption = useSelector(getCancelOptionBehaviorOverride)
  const { push } = useHistory()
  const onCancel = () => {
    if (cancelOption) {
      window.location.replace(cancelOption)
    } else {
      push("/payments/summary")
    }
  }

  if (isSaving) {
    return <Loader />
  }

  const isCard = methodType === methodTypes.card
  const customPadding = theme.ux.isSSO
    ? isCard
      ? '15px 30px'
      : '40px 30px 30px'
    : undefined

  const onSubmit = input => {
    savePayment(input)
    setValues(input)
  }

  const FormHeader = () => (
    <FlexStyled justifySpaceBetween alignCenter type={methodType}>
      <p>Payment Method - Add a {capitalizeWordDashToSpace(methodType)}</p>
      <Button id="cancel" disableRipple onClick={() => cancel({})} link>
        cancel
      </Button>
    </FlexStyled>
  )
  const Btns = ({ handleSubmit }) => (
    <React.Fragment>
      <Button id="exitBtn" outline primary onClick={onCancel}>
        Exit &amp; Cancel
      </Button>
      <Button id="saveBtn" primary onClick={handleSubmit}>
        Save
      </Button>
    </React.Fragment>
  )
  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={
        isCard ? getCardSchema(isInternational) : schemas[methodType]
      }
      initialValues={values || initialValues[methodType]}>
      {({ handleSubmit, ...props }) => {
        return (
          <React.Fragment>
            <AddPaymentMethodForm
              methodType={methodType}
              countries={countries}
              states={states}
              formHeader={FormHeader}
              setInternational={setIsInternational}
              customPadding={customPadding}
              {...props}
            />
            {isCard && (
              <CardDisclaimerWrapper>
                <CardDisclaimer />
              </CardDisclaimerWrapper>
            )}
            <Hidden smDown>
              <ButtonWrapperStyled>
                <Btns handleSubmit={handleSubmit} />
              </ButtonWrapperStyled>
            </Hidden>
            <MobileBtnsPortal>
              <StyledMobileButtons>
                <Btns handleSubmit={handleSubmit} />{' '}
              </StyledMobileButtons>
            </MobileBtnsPortal>
          </React.Fragment>
        )
      }}
    </Formik>
  )
}
export default AddPaymentMethod
