// TODO token and app type should only be needed once
export function createHTTPHeader(authToken, appType, withoutXApi) {
  const token = authToken == null ? 'test' : authToken
  // const clientType = 'resident-webapp'
  if (withoutXApi) {
    return {
      Authorization: `Bearer ${token}`
    }
  }
  return {
    Authorization: `Bearer ${token}`,
    'X-Api-Version': 2
    // 'X-Client-Type': clientType
  }
}

export const createLambdaHeader = paymentToken => ({
  Authorization: `Bearer ${paymentToken}`
})

export const buildAPIUrl = (base_url, params) => {
  let finalParams = ''
  if (typeof params === 'object' && Object.keys(params).length > 0) {
    const parts = []
    for (var key in params) {
      if (params[key] !== undefined) parts.push(`${key}=${params[key]}`)
    }
    finalParams = `?${parts.join('&')}`
  } else if (typeof params !== 'object') {
    finalParams = `/${params}`
  }

  if (finalParams === '') {
    return base_url
  }

  return `${base_url}${finalParams}`
}
