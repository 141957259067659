import styled from '@emotion/styled'
import Grid from '@material-ui/core/Grid'

export const StyledGrid = styled(Grid)`
  padding-top: ${({ theme }) => theme.space.s80};
`

export const ResidentWebLink = styled.a`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  text-decoration: none;
`
