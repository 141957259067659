// @flow
import get from 'lodash/get'
import Dinero from 'dinero.js'
import { createSelector } from 'reselect'
import getSymbolFromCurrency from 'currency-symbol-map'

export const getPaymentFieldOverrides = (state: Object) =>
  get(state, ['overrides', 'payments', 'balance', 'paymentFields'], [])

export const getZegoPaymentFields = (state: Object) =>
  get(state, ['payments', 'balance', 'paymentFields'], [])

export const getOptionalPaymentFields = (state: Object) =>
  get(state, ['payments', 'balance', 'optionalPaymentFields'], [])

/**
 * If the user has overriden payment fields, we do not want to show them
 * if they are not set up for them
 */
export const getPaymentFields = createSelector(
  [getPaymentFieldOverrides, getZegoPaymentFields],
  (paymentFieldOverrides: Object[], apiPaymentFields: Object[]) => {
    if (!paymentFieldOverrides.length) {
      return apiPaymentFields
    }
    const keys = apiPaymentFields.map(field => field.key)
    return paymentFieldOverrides.filter(field => keys.includes(field.key))
  }
)

export const getLineItems = (state: Object) =>
  get(state, ['payments', 'balance', 'lineItems'], [])

export const getRegularAndOptionalPaymentFields = createSelector(
  [getPaymentFields, getOptionalPaymentFields],
  (paymentFields: Object[], optionalPaymentFields: Object[]) => {
    const zeroBalance = paymentFields.filter(({ money }) => !money || money.amount === 0)
    let updatedRegularPaymentFields = paymentFields.filter(({ money }) => money && money.amount !== 0)
    let updatedOptionalPaymentFields = optionalPaymentFields.concat(zeroBalance)

    if (!updatedRegularPaymentFields.length && updatedOptionalPaymentFields.length === 1) {
      updatedRegularPaymentFields = updatedOptionalPaymentFields
      updatedOptionalPaymentFields = []
    }

    return { updatedRegularPaymentFields, updatedOptionalPaymentFields }
  }
)

export const getTotalOwed = createSelector(
  getPaymentFields,
  (paymentFields: Object[]) => {
    const currency = paymentFields.length
      ? paymentFields[0].money.currency
      : 'USD'

    return Dinero({
      amount: paymentFields.reduce(
        (acc, { money: { amount } }) => acc + amount,
        0
      ),
      currency
    })
  }
)

export const getTotalOwedLastUpdated = createSelector(
  getPaymentFields,
  (paymentFields: Object[]) =>
    paymentFields.reduce((acc, { updatedDate }) => {
      const newDateIsGreater = (!acc && updatedDate) || acc < updatedDate
      if (newDateIsGreater) {
        return updatedDate
      }

      return acc
    }, null)
)

const formattedPaymentFieldsCombiner = (paymentFields: Object[]) => {
  return paymentFields
    .filter(({ money: { amount }, locked }) => !(!amount && locked))
    .map(({ money: { currency, amount }, locked, label, key }) => {
      return {
        symbol: getSymbolFromCurrency(currency),
        amount: Dinero({ amount }).toFormat('0.00'),
        currency,
        label,
        key,
        locked,
      }
    })
}

const formattedRegularAndOptionalPaymentFieldsCombiner = (paymentFields: Object) => {
  const { updatedRegularPaymentFields, updatedOptionalPaymentFields } = paymentFields

  return {
    fields: formattedPaymentFieldsCombiner(updatedRegularPaymentFields),
    optionalFields: formattedPaymentFieldsCombiner(updatedOptionalPaymentFields)
  }
}

export const getPaymentFieldsToPaymentFields = createSelector(
  getPaymentFields,
  formattedPaymentFieldsCombiner
)

export const getOptionalPaymentFieldsToPaymentFields = createSelector(
  getOptionalPaymentFields,
  formattedPaymentFieldsCombiner
)

export const getUpdatedPaymentFields = createSelector(
  getRegularAndOptionalPaymentFields,
  formattedRegularAndOptionalPaymentFieldsCombiner
)

export const getPaymentFieldsAsPayload = createSelector(
  getPaymentFields,
  (paymentFields: Object[]) => {
    return paymentFields
      .filter(({ money }) => money && money.amount > 0)
      .map(({ money, key }) => {
        return {
          money,
          key
        }
      })
  }
)

export const getBalanceDetails = createSelector(
  getPaymentFields,
  (paymentFields: Object[]) => {
    return paymentFields
      .filter(({ money }) => money && money.amount > 0)
      .map(({ money, label }) => {
        return {
          label,
          value: Dinero(money).toFormat()
        }
      })
  }
)

export const getLockedFieldsStatus = createSelector(
  getPaymentFields,
  (paymentFields: Object[]) => {
    return paymentFields.find(({ locked }) => locked === true) ? true : false
  }
)

export const getLineItemsDetails = createSelector(
  getLineItems,
  (lineItems: Object[]) => {
    return lineItems
      .filter(({ money }) => money && money.amount > 0)
      .map(({ money, label }) => {
        return {
          label,
          value: Dinero(money).toFormat()
        }
      })
  }
)

export const hasBalance = createSelector(
  getPaymentFields,
  (paymentFields: Object[]) => {
    const zeroCheck = Dinero({
      amount: paymentFields.reduce(
        (acc, { money: { amount } }) => acc + amount,
        0
      )
    }).isZero()
    return !zeroCheck
  }
)

export const getHasEditableFields = createSelector(
  getPaymentFields,
  (paymentFields: Object[]) => {
    return paymentFields.some(({ locked }) => locked === false)
  }
)
