import { call, takeLatest, put, select } from 'redux-saga/effects'
import { print } from 'graphql'
import {
  fetchPaymentBalanceAction,
  fetchPaymentBalanceError,
  fetchPaymentBalanceStart,
  fetchPaymentBalanceSuccess
} from '../actions'
import api from '../../../../utils/api'
import {
  getPaymentToken,
  getUserId
} from '../../../authorization/selectors'
import { showSnackbar } from '../../../snackbar/actions'
import { genericErrorMsg } from '../../../../utils/messages'
import { GET_BALANCE_DETAIL, getBalDetail } from '../graphql/queries'
import { getGraphQlData } from '../../../../utils/graphql'

export function* fetchPaymentBalance() {
  try {
    const paymentToken = yield select(getPaymentToken)
    const residentId = yield select(getUserId)
    const tokens = { paymentToken }
    const variables = { residentId }

    yield put(fetchPaymentBalanceStart())
    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(getBalDetail),
      variables
    )
    const payload = yield call(getGraphQlData, response, GET_BALANCE_DETAIL)
    yield put(fetchPaymentBalanceSuccess(payload))
  } catch (error) {
    yield put(fetchPaymentBalanceError(error))
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

function* watchFetchPaymentBalance() {
  yield takeLatest(fetchPaymentBalanceAction().type, fetchPaymentBalance)
}

export default [watchFetchPaymentBalance()]
