// @flow
import styled from '@emotion/styled'
import { Card } from '@material-ui/core'
import Flex from '@Common/Flex'
import Button from '@Common/Button'
import checkmark from '@images/check2.png'

export const CardStyled = styled(Card)(
  ({ theme }) => `
    padding: ${theme.space.s16} ${theme.space.s25} ${theme.space.s30};
    box-shadow: 0px 2px 2px rgba(186, 186, 186, 0.1), 0px 2px 8px rgba(14, 20, 39, 0.25);


    ${theme.breakpoints.down('sm')} {
      padding: 20px;
    } 
  `
)

export const Header = styled('div')(
  ({ theme }) => `
    display: flex;
    justify-content: space-between;

    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  `
)

export const CardTitle = styled('h4')(
  ({ theme }) => `
    font-size: ${theme.custom.font.size.H5};
    font-weight: ${theme.custom.font.weight.regular};
    line-height: 1;
    white-space: nowrap;
    margin: 0;

    ${theme.breakpoints.down('md')} {
      margin-bottom: ${theme.space.s14};
    }
  `
)

export const MethodsCarousel = styled('div')(
  ({ theme }) => `
    border-radius: 4px;
    margin: ${theme.space.s10} 0 0;
    position: relative;
  `
)

export const ExistingPaymentOptions = styled(Flex)(
  ({ theme }) => `
    background-color: ${theme.palette.neutral.warm};
    border-radius: 5px;
    margin-right: ${theme.space.s10};
    padding: ${theme.space.s8} ${theme.space.s15};

    &:empty{
        display: none;
    }
  `
)

export const AvailablePaymentOptions = styled(Flex)(
  ({ theme }) => `
    border: 1px solid ${theme.palette.neutral.warm};
    border-radius: 5px;
    padding: ${theme.space.s8} ${theme.space.s15};
  `
)

export const MethodDetails = styled(Flex)(
  ({ theme }) => `
    padding-top: ${theme.ux.isSSO ? theme.space.s14 : theme.space.s65};
    font-size: ${theme.custom.font.size.md};
    font-weight: ${theme.custom.font.weight.light};
    &>div {
      padding: 0 ${theme.space.s40} ${theme.space.s40};
      min-height: 120px;

      ${
        theme.ux.isSSO &&
        `padding-bottom: 0px;
        min-height: unset;
        padding-right: 0;
        max-width: 58%;
      `
      }

      ${theme.breakpoints.down('sm')} {
        padding: 0 0 ${theme.space.s25};
      }
    }

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      padding-top: ${theme.space.s45};
    }
  `
)

export const MethodInfo = styled('div')(
  ({ theme }) => `
    border-right: 1px solid ${theme.palette.neutral.warm};

    ${
      theme.ux.isSSO &&
      `
      padding: 0 80px 0 0 !important;
      margin-bottom: 5px;
    `
    }

    ${theme.breakpoints.down('md')} {
      padding-left: 0 !important;
    }

    ${theme.breakpoints.down('sm')} {
      border-right: 0;
    }
  `
)

export const MethodDetailsText = styled('div')(
  ({ theme }) =>
    theme.ux.isSSO &&
    `margin-left: -20px;
    overflow: scroll;
    max-height: 80px;
    `
)

export const MethodTitle = styled('h4')(
  ({ theme }) => `
    font-size: ${
      theme.ux.isSSO ? theme.custom.font.size.H5 : theme.custom.font.size.H4
    };
    font-weight: ${theme.custom.font.weight.regular};
    margin: 0;
    text-transform: capitalize;
  `
)

export const MethodTitleFee = styled('span')(
  ({ theme }) => `
    font-weight: ${theme.custom.font.weight.light};
    color: ${theme.palette.neutral.deep};
    text-transform: lowercase;
  `
)

export const MethodLineText = styled('li')(
  ({ theme }) => `
    line-height: 1;
    color: ${theme.palette.common.grey};
    margin-top: ${theme.space.s10};

    ${
      theme.ux.isSSO &&
      `
      margin-top: ${theme.space.s3};
      &:first-of-type{
        margin-top: 0;
      }
    `
    }
  `
)

export const MethodListItem = styled('div')(
  ({ theme }) => `
    font-size: ${theme.custom.font.size.md};
    line-height: 1;
    margin-top: ${theme.space.s20};
    background: url(${checkmark}) left no-repeat;
    background-size: 24px 24px;
    padding-left: ${theme.space.s35};
    white-space: nowrap;
    position: relative;
    
    ${
      theme.ux.isSSO &&
      `margin-top: ${theme.space.s12};
      top: -5px;
    `
    }
  `
)

export const Cheque = styled('img')`
  width: 200px;
  object-fit: contain;
  object-position: left;
`

export const SortButton = styled(Button)(
  ({ theme, selected }) => `
    background-color: ${selected ? theme.palette.common.iceberg : 'white'};
    border: 1px solid ${theme.palette.secondary.main};
    border-radius: 24px;
    color: ${theme.palette.common.grey};
    font-size: ${theme.custom.font.size.md};
    font-weight: ${theme.custom.font.weight.regular};
    line-height: 1;
    height: 26px;
    padding: ${theme.space.s2} ${theme.space.s10};
    margin: 0 ${theme.space.s5};
    white-space: nowrap;

    &:hover{
      color: ${theme.palette.primary.main};
      background-color: ${selected ? theme.palette.common.iceberg : 'white'};
    }

    ${theme.breakpoints.down('sm')} {
      font-size: 10px;
      height: 20px;
      min-width: 0;
    }
  `
)

export const Buttons = styled(Flex)(
  ({ theme }) => `
    justify-content: flex-end;
    margin-top: ${theme.ux.isSSO ? theme.space.s13 : theme.space.s50};

    &>* {
      margin-left: 22px;
    }
    
    &>*:first-child {
      font-weight: ${theme.custom.font.weight.regular};
      font-size: ${theme.custom.font.size.H6};
      text-decoration-line: underline;
      color: ${theme.palette.neutral.deep};
    }

    ${theme.breakpoints.down('sm')} {
      flex-direction: column-reverse;
      margin-top: 0;

      & button:first-child {
        position: relative;
        top: 15px;
      }
    }
  `
)
