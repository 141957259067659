import { CardContent } from '@Common/Card'
import { Box, Divider, Typography } from '@material-ui/core'
import { StyledCard } from './styles'
import React from 'react'

const SupportCard = ({ propertyPhone, emergencyPhone }) => {
  const hasEmergencyPhone = !!emergencyPhone
  return (
    <StyledCard elevation={0} secondary unwrapContent>
      <>
        <CardContent>
          <Typography variant="h4" align="center">
            {hasEmergencyPhone ? 'After-Hours Support' : 'Daytime Support'}
          </Typography>
          <Box color="common.grey" py={2} clone>
            <Typography variant="body2" align="center">
              {hasEmergencyPhone
                ? `If you require after-hours emergency assistance, please call the
              number below and someone from the property maintenance team will
              be able to assist you`
                : `If you would like to speak with someone, please call the number below during office hours`}
            </Typography>
          </Box>
          <Box color="primary.main" fontSize={32} clone>
            <Typography align="center" id="emergencyPhone">
              {emergencyPhone || propertyPhone}
            </Typography>
          </Box>
        </CardContent>
        <Box mt="auto" px={6} pb={6} clone>
          <Typography align="center" variant="body2">
            If this is a life-threatening emergency, please call 911.
          </Typography>
        </Box>
      </>
      {emergencyPhone && propertyPhone && (
        <>
          <Divider />
          <CardContent>
            <Typography align="center" variant="h5">
              Daytime Support
            </Typography>
            <Box color="common.grey" pt={2} clone>
              <Typography align="center" variant="h5" id="propertyPhone">
                {propertyPhone}
              </Typography>
            </Box>
          </CardContent>
        </>
      )}
    </StyledCard>
  )
}

export default SupportCard
