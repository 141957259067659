import { createFetchActions } from '../../../helpers/actions/creators'

export const WORK_ORDER_HISTORY_SECTION = 'WORKORDERHISTORYSECTION'

export const {
  fetchAction: fetchWorkOrderHistoryAction,
  fetchStartAction: fetchWorkOrderHistoryStart,
  fetchSuccessAction: fetchWorkOrderHistorySuccess,
  fetchErrorAction: fetchWorkOrderHistoryError
} = createFetchActions(WORK_ORDER_HISTORY_SECTION)
