import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  fetchStatementsAction,
  fetchStatementsSuccess,
  fetchStatementsError,
  fetchStatementsStart
} from '../actions'
import api from '../../../../utils/api'
import { getGraphQlData } from '../../../../utils/graphql'
import { getPaymentToken } from '../../../authorization/selectors'
import {
  getStatementsQuery,
  getStatementsVariables,
  STATEMENTS_QUERY_NAME
} from '../graphql/queries'

export function* fetchStatements() {
  try {
    yield put(fetchStatementsStart())
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = getStatementsQuery()
    const variables = getStatementsVariables()

    const response = yield call(api.graphqlQuery, tokens, query, variables)
    const payload = yield call(getGraphQlData, response, STATEMENTS_QUERY_NAME)
    yield put(fetchStatementsSuccess(payload))
  } catch (error) {
    yield put(fetchStatementsError(error))
  }
}

function* watchFetchStatements() {
  yield takeLatest(fetchStatementsAction().type, fetchStatements)
}

export default [watchFetchStatements()]
