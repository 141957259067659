import { connect } from 'react-redux'
import MyPropertyInfo from './MyPropertyInfo'
import {
  getUnitProperty,
  getUnitCompany,
  getUnitPropertyId,
  getPropertyStaff,
  getOfficeAddress,
  getOfficeAddressLineOne,
  getOfficeAddressLineTwo,
  getOfficeAddressState,
  getOfficeAddressCity,
  getOfficeAddressZip,
  getOfficeHours,
  getPhoneNumber
} from 'zego-shared/store/select/selectors'
import { getPreviousUrl } from 'zego-shared/store/previousUrl/selectors'
import { formatPhoneNumber } from 'zego-shared/utils/string'

export const mapStateToProps = state => ({
  propertyId: getUnitPropertyId(state),
  propertyStaff: getPropertyStaff(state),
  propertyName: getUnitProperty(state),
  companyName: getUnitCompany(state),
  address: getOfficeAddress(state),
  addressLineOne: getOfficeAddressLineOne(state),
  addressLineTwo: getOfficeAddressLineTwo(state),
  addressState: getOfficeAddressState(state),
  addressCity: getOfficeAddressCity(state),
  addressZip: getOfficeAddressZip(state),
  officeHours: getOfficeHours(state),
  phone: formatPhoneNumber(getPhoneNumber(state)),
  prevUrl: getPreviousUrl(state)
})

export default connect(mapStateToProps)(MyPropertyInfo)
