// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { GridItem } from '@Common/Grid'
import Flex from '@Common/Flex'
import LookBackAd from '../Banners/LookBackAd'
import Graph from './Graph'
import LookbackVideo from './LookbackVideo'
import { isLookbackEnabled } from 'zego-shared/store/payments/creditReporting/selectors'
import { useTheme } from '@material-ui/core/styles'
import { StyledCard } from './styles'
import {
  LevelCreditLogo,
  CRPageTitle,
  LCNotice,
  IntroTitle,
  IntroText,
  IntroSection,
  FeaturesUnderlay,
  FeaturesInner,
  FeatureCheckMark,
  FeatureTitle,
  FeatureSubTitle,
  Feature,
  FeatureRight,
  LearnMore,
  ShowForMdUp,
  ShowForMdDown,
  AdFeaturesWrap,
  ChartGraph,
  ChartGraphText,
  SingleColumnFlexWrap,
  LookbackSectionTitle,
  ChartGraphTextTFooter,
  LookbackMoreSection,
  TinyFooterText,
  VideoBox,
  VideoBoxLeft,
  VideoBoxRight,
  FuturePlansText,
  Bold
} from './styles'

type Props = {
  match: { url: string }
}

const getSSOViewWrapper = (isSso, children) => {
  if (isSso) {
    return <StyledCard>{children}</StyledCard>
  }

  return children
}

export const LookBackView = ({ match }: Props) => {
  const isLookbackEligible = useSelector(isLookbackEnabled)
  const theme = useTheme()
  const isSSO = theme.ux.isSSO

  if (!isLookbackEligible) {
    const { url } = match
    const creditReportingUrl = url.split('/lookback')[0]
    return <Redirect to={creditReportingUrl} from={url} />
  }

  return (
    <GridItem columnStart={1} columnSpan={12}>
      <Flex justifySpaceBetween alignCenter>
        <Flex direction="column">
          <CRPageTitle id="lbHeader">Lookback</CRPageTitle>
          <LCNotice id="lbNotice">with LevelCredit</LCNotice>
        </Flex>
        <Flex>
          <LevelCreditLogo />
        </Flex>
      </Flex>
      {getSSOViewWrapper(
        isSSO,
        <>
          <Flex justifySpaceBetween width={isSSO ? '100%' : 'initial'}>
            <SingleColumnFlexWrap marginBottom="100px" direction="column">
              <IntroSection direction="column">
                <IntroTitle id="lbTitle">
                  Make past payments count too!
                </IntroTitle>
                <IntroText id="lbSubTxt">
                  With <Bold>Lookback</Bold>, add past payments made within{' '}
                  <Bold>Zego</Bold> and get the credit you deserve
                  <sup>&reg;</sup>
                  &nbsp;.
                </IntroText>
              </IntroSection>
              <AdFeaturesWrap direction="column" id="lbContainer">
                <ShowForMdDown zIndex>
                  <LookBackAd />
                </ShowForMdDown>
                <FeaturesUnderlay
                  color="#E6F5F9"
                  width={isSSO ? '100%' : 'initial'}
                />
                <FeaturesInner>
                  <Feature>
                    <FeatureCheckMark color="blue" />
                    <FeatureRight
                      direction="column"
                      justifySpaceBetween
                      id="lbAddCreditCheck">
                      <FeatureTitle>
                        Add rent payments you’ve already made
                      </FeatureTitle>
                      <FeatureSubTitle>
                        which can help you get a jumpstart on building credit
                        without adding new debt
                      </FeatureSubTitle>
                    </FeatureRight>
                  </Feature>
                  <Feature>
                    <FeatureCheckMark color="blue" />
                    <FeatureRight
                      direction="column"
                      justifySpaceBetween
                      id="lbAddMonthsCheck">
                      <FeatureTitle>Add up to 24 months</FeatureTitle>
                      <FeatureSubTitle>
                        of eligible rent payments
                      </FeatureSubTitle>
                    </FeatureRight>
                  </Feature>
                  <LearnMore>
                    <a
                      href="https://www.gozego.com/levelcredit-reporting-faq/"
                      target="_blank"
                      rel="noopener noreferrer">
                      Learn More
                    </a>
                  </LearnMore>
                </FeaturesInner>
              </AdFeaturesWrap>
            </SingleColumnFlexWrap>
            <ShowForMdUp zIndex>
              <LookBackAd />
            </ShowForMdUp>
          </Flex>

          <Flex direction="column" marginTop={isSSO ? '0px' : '20px'}>
            <Flex direction="column" marginBottom={isSSO ? '40px' : '60px'}>
              <IntroTitle id="bcTitle">Build Credit Without Debt</IntroTitle>
              <IntroText id="bcSubTxt" isCreditBlock>
                Get a jumpstart on building credit by adding the rent payments
                you have already made. These past payments will be reported to 2
                of the top 3 credit bureaus, which provide some of the
                information creditors and lenders use to help them make
                important lending decisions.
              </IntroText>
            </Flex>
            <LookbackMoreSection>
              <ChartGraph>
                <Graph />
              </ChartGraph>
              <ChartGraphText>
                <LookbackSectionTitle>
                  <Bold>
                    How can rental credit reporting help to boost your credit
                    score?
                  </Bold>
                </LookbackSectionTitle>
                On average, residents who enrolled in the rental credit
                reporting program saw their scores increase by as much as 20
                points in just two months, and 50 points after two years.
                Residents with lower-than-average credit scores have seen even
                faster results.
                <ChartGraphTextTFooter>
                  Lookback can help move you toward your goals faster by adding
                  months or years of payments you have already made through
                  Zego*.
                </ChartGraphTextTFooter>
              </ChartGraphText>
            </LookbackMoreSection>
            <VideoBox>
              <VideoBoxLeft>
                <LookbackSectionTitle>
                  <Bold>What is Rental Credit Reporting?</Bold>
                </LookbackSectionTitle>
                Rental Credit Reporting is the reporting of your monthly,
                rent-related payments to the credit bureaus in order to help
                boost your credit score.
                <br />
                Watch this video to learn how the program works and how easy it
                is to sign up.
              </VideoBoxLeft>
              <VideoBoxRight>
                <LookbackVideo />
              </VideoBoxRight>
            </VideoBox>
            <Flex>
              <FuturePlansText>
                <LookbackSectionTitle>
                  <Bold>Get a leg up on future plans</Bold>
                </LookbackSectionTitle>
                Having a positive credit history and a high credit score has
                many benefits including the opportunity to get loans with lower
                interest rates, higher chances of getting approved for a car or
                home loan, and an easier time in getting rental housing.
              </FuturePlansText>
            </Flex>
            <Flex marginBottom="80px">
              <TinyFooterText>
                * Payments must have been made through the Zego or PayLease
                platforms to be eligible
              </TinyFooterText>
            </Flex>
          </Flex>
        </>
      )}
    </GridItem>
  )
}

export default LookBackView
