// @flow
export const PAYMENT_DEBOUNCE_OFFSET = 1000

export const FRAUD_TOOLKIT_ERROR_KEYS = [
  'LITLE_PRIOR_CHARGEBACK',
  'LITLE_FRAUD_TOOLKIT'
]

export const PLAID_ERROR_KEYS = {
  PLAID_SERVICE_API_ERROR: 'PLAID_SERVICE_API_ERROR',
  PLAID_INSUFFICIENT_FUNDS: 'PLAID_INSUFFICIENT_FUNDS',
  PLAID_LOGIN_REQUIRED: 'PLAID_LOGIN_REQUIRED'
}
