export default {
  firstName: '',
  lastName: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  ssn: '',
  birthDate: '',
  leaseEndDate: '',
  confirmSnn: '',
  leaseMonthToMonth: false,
  email: '',
  phoneNumber: ''
}
