import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import api from 'zego-shared/utils/api'
import log from 'zego-shared/utils/logger'
import { failedToLoginLink } from 'zego-shared/utils/messages'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'

const useTokenToLinkProperty = activationToken => {
  const dispatch = useDispatch()
  const [error, setError] = useState(null)
  const [token, setToken] = useState(null)

  const submit = useCallback(
    async ({ email, password }) => {
      setError(null)
      setToken(null)

      try {
        const response = await api.loginToLinkProperty(email, password, activationToken)

        setToken(response.token)
      } catch (error) {
        log(error)

        const friendlyError = error.friendly_error
          ? new Error(error.friendly_error)
          : error

        setError(friendlyError)
        dispatch(showSnackbar(failedToLoginLink(friendlyError), 'error'))
      }
    },
    [activationToken, dispatch]
  )

  return [{ error, token }, submit]
}

export default useTokenToLinkProperty
