export const SHOW_SNACK_BAR = 'SHOW_SNACK_BAR'
export const HIDE_SNACK_BAR = 'HIDE_SNACK_BAR'

export const showSnackbar = (message, variant, messagesWithTooltip) => ({
  type: SHOW_SNACK_BAR,
  message,
  variant,
  messagesWithTooltip
})

export const hideSnackbar = () => ({
  type: HIDE_SNACK_BAR
})
