import styled from '@emotion/styled'
import Button from '@Common/Button'
import { TextField } from '@material-ui/core'
import { CommonIcon } from '@icons'

export const RatingLabel = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 4em;

  ${({ theme }) => [theme.breakpoints.up('md')]} { 
      margin-left: 10em;
      margin-right: 10em;
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} { 
    justify-content: space-around;
  }
`

export const StyledSubmit = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: auto; 
  background-color: ${({ theme }) => theme.palette.primary.main};
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
  min-width: 200px;
  margin-top: 3em;

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const StyledDismiss = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.palette.neutral.main};
  margin-left: auto;
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  font-size: 12px;
  padding-right: 2.5em;
  padding-top: 1em;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.neutral.main};
  }
`

export const modalStyles = {
  root: {
    height: '60%',
    borderTopRightRadius: '50px',
    borderBottomLeftRadius: '50px',
    overflowX: 'hidden'
  }
}


export const StyledSurveyMsg = styled('div')`
  font-size: 20px;
  text-align: center;

  ${({ theme }) => [theme.breakpoints.up('sm')]} {
    padding-left: 3em;
    padding-right: 3em;
  }
`

export const StyledStarIcon = styled(CommonIcon)`
  height: 46px;
  width: 46px;

  ${({ theme }) => [theme.breakpoints.down('sm')]} { 
    height: 30px;
    width: 30px;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} { 
    height: 14px;
    width: 14px;
  }
`

export const StyledContent = styled('div')`
  margin-top: -2em;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5em;
`

export const SurveyInput = styled('div')`
  background-color: ${({ theme }) => theme.palette.common.iceberg};
  margin-top: 2em;
  height: 50%;
  text-align: center;

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    height: 30%;
  }
  
  :after {
    content: "";
    border-top: 55px solid ${({ theme }) => theme.palette.common.iceberg};
    border-right: 61em solid transparent;
    top: 6em;
    position: relative;
    left: -30%;

    ${({ theme }) => [theme.breakpoints.down('sm')]} {
      top: 64%;
      border-top: 57px solid ${({ theme }) => theme.palette.common.iceberg};
      border-right: 71em solid transparent;
    }  

    ${({ theme }) => [theme.breakpoints.up('sm')]} {
      top: 72%;
      border-top: 76px solid ${({ theme }) => theme.palette.common.iceberg};
      border-right: 130em solid transparent;
    }
  }
`
export const SurveyInputStyles = {
  step1: {
    padding: "3em"
  }, 
  step2: {
    height: "70%"
  }
}

export const SurveyMessageStyles = {
  thankYouMsg: {fontSize: 24, textAlign: "center"},
  subtitleMsg: {fontSize: 14, textAlign: "center"},
  center: {textAlign: "center"}
}

export const StyledTextInput = styled(TextField)`
  min-width: 70%;
  background: ${({ theme }) => theme.palette.common.white};
  margin-top: 2em;
  margin-bottom: -6em;
`
