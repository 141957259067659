// @flow
import TextField from '@material-ui/core/TextField'
import styled from '@emotion/styled'
import { withStyles } from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { Tooltip } from '@material-ui/core'
import { convertHexToRGB } from '../../../../utils/view'

export const StyledTextInput = styled(TextField)`
  font-size: 16px;
  width: 100%;
  text-align: left;

  ${({ label, theme }) =>
    label &&
    `label {
      margin: -${theme.space.s45} 0 0 -${theme.space.s15};
    }`}

  & fieldset {
    padding: 0;
  }

  & .MuiInputBase-input {
    padding: 0 15px;
    height: 30px;
    min-height: ${({ multiline }) => (multiline ? '80px' : 'unset')};
    display: flex;
    align-items: center;
  }

  & .MuiOutlinedInput-root {
    border-radius: 15px;
    background-color: ${({ theme, bgWhite }) => bgWhite && theme.palette.common.white};
  }

  /* TextArea styles */

  & textarea.MuiInputBase-input {
    padding: ${({ theme }) => `${theme.space.s5} ${theme.space.s15}`};
    box-sizing: border-box;
  }

  & .MuiOutlinedInput-multiline {
    padding: 0;
  }
  
  /* Readonly styles */

  ${({ theme, inputProps }) => inputProps && inputProps.readOnly && `
    & .MuiInputBase-input:read-only + fieldset {
      background-color: ${convertHexToRGB(theme.palette.neutral.light, 0.6)};
    }
  `}
  

  /* Select specific styles */

  & svg.MuiSelect-icon {
    right: 7px;
    top: 50%;
    transform: translateY(-50%);
  }

  & .MuiSelect-select {
    padding-right: ${({ theme }) => theme.space.s30};
    &:focus {
      background-color: transparent;
    }
  }

  /* general styes */
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
`

export const HelpIcon = styled(HelpOutlineIcon)`
  margin-right: -5px;
  cursor: default;
  color: ${({ theme }) => theme.palette.neutral.main};
`

export const StyledTooltip = withStyles(theme => ({
  tooltip: {
    position: 'relative',
    backgroundColor: theme.palette.neutral.light3,
    color: theme.palette.common.midnight,
    fontSize: theme.custom.font.size.sm,
    border: '0px',
    borderRadius: '0.5em',
    margin: '-0.3em',
    '&[class*=bottom]': {
      top: '1.9em'
    },
    '&[class*=right]': {
      left: '1.9em'
    },
    '&[class*=top]': {
      bottom: '1.9em'
    },
    '&[class*=left]': {
      right: '1.9em'
    },
    padding: '12px 20px 12px 30px',
    '& .arrow': {
      position: 'absolute',
      margin: '-0.3em'
    },
    '& .arrow[x-placement*=bottom]': {
      top: '0'
    },
    '& .arrow[x-placement*=bottom-start]': {
      left: '1em'
    },
    '& .arrow[x-placement*=bottom-end]': {
      right: '1em'
    },

    '& .arrow[x-placement*=top]': {
      bottom: '0'
    },
    '& .arrow[x-placement*=top-start]': {
      left: '1em'
    },
    '& .arrow[x-placement*=top-end]': {
      right: '1em'
    },

    '& .arrow[x-placement*=right]': {
      left: '0'
    },
    '& .arrow[x-placement*=right-start]': {
      top: '1em'
    },
    '& .arrow[x-placement*=right-end]': {
      bottom: '1em'
    },

    '& .arrow[x-placement*=left]': {
      top: '0'
    },
    '& .arrow[x-placement*=left-start]': {
      top: '1em'
    },
    '& .arrow[x-placement*=left-end]': {
      bottom: '1em'
    },

    '& .arrow::before': {
      content: '""',
      display: 'block',
      backgroundColor: theme.palette.tertiary.light3,
      transform: 'rotate(45deg)',
      position: 'relative',
      width: '1em',
      height: '1em'
    }
  }
}))(Tooltip)
