// @flow
import React from 'react'
import type { Node } from 'react'

import { Container, Item } from './styles'

type FlexProps = {
  children: Node,
  direction?: string,
  marginTop?: string,
  marginRight?: string,
  marginBottom?: string,
  marginLeft?: string,
  wrap?: boolean,
  fullWidth?: boolean,
  justifyLeft?: boolean,
  justifyRight?: boolean,
  justifyCenter?: boolean,
  justifySpaceBetween?: boolean,
  alignStart?: boolean,
  alignEnd?: boolean,
  alignCenter?: boolean,
  alignSelf?: boolean,
  alignStretch?: boolean,
  fullWidth?: boolean,
  fullHeight?: boolean,
  paddingTop?: string,
  paddingBottom?: string,
  paddingLeft?: string,
  paddingRight?: string,
  flex?: number
}

type FlexItemProps = {
  children: Node | mixed,
  flex?: number,
  fullWidth?: boolean
}

export const Flex = React.forwardRef((props: FlexProps, ref) => {
  const { direction, children, ...rest } = props
  return (
    <Container {...rest} direction={direction} ref={ref}>
      {children}
    </Container>
  )
})

export const FlexItem = (props: FlexItemProps) => {
  const { flex, children, ...rest } = props
  return (
    <Item {...rest} flex={flex}>
      {children}
    </Item>
  )
}

export default Flex
