import { all } from 'redux-saga/effects'
import log from 'zego-shared/utils/logger'
import selectSagas from 'zego-shared/store/select/sagas'
import residentSagas from 'zego-shared/store/resident/sagas'
import authorizationSagas from 'zego-shared/store/authorization/sagas'
import billingSagas from 'zego-shared/store/billing/sagas'
import launchDarklySagas from 'zego-shared/store/integrations/launchDarkly/sagas'
import usersSagas from 'zego-shared/store/users/sagas'
import { workOrderSagas } from 'zego-shared/store/workOrders/sagas'
import settingsSagas from 'zego-shared/store/settings/sagas'
import { paymentsSagas } from 'zego-shared/store/payments/sagas'
import pmSettingsSagas from 'zego-shared/store/pmSettings/sagas'
import userResource from 'zego-shared/store/userResource/sagas'
import sentrySagas from 'zego-shared/store/integrations/sentry/sagas'
import loaderSagas from 'zego-shared/store/loader/sagas'
import profileSagas from 'zego-shared/store/users/profile/sagas'
import termsAndConditions from 'zego-shared/store/termsAndConditions/sagas'
import marketplaceSagas from 'zego-shared/store/marketplace/sagas'
import { surveySagas } from 'zego-shared/store/surveys/sagas'

export function* startSagas() {
  log('starting sagas')
  yield all([
    ...authorizationSagas,
    ...billingSagas,
    ...selectSagas,
    ...residentSagas,
    ...workOrderSagas,
    ...paymentsSagas,
    ...pmSettingsSagas,
    ...sentrySagas,
    ...settingsSagas,
    ...usersSagas,
    ...userResource,
    ...loaderSagas,
    ...profileSagas,
    ...termsAndConditions,
    ...launchDarklySagas,
    ...surveySagas,
    ...marketplaceSagas
  ])
}
