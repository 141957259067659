import { call, select, put, takeLatest } from 'redux-saga/effects'
import {
  getToken,
  getUserId,
  getPaymentToken
} from '../../../authorization/selectors'
import {
  fetchUserProfile as fetchUserProfileAction,
  fetchUserProfileStart,
  fetchUserProfileSuccess,
  fetchUserProfileError,
  uploadProfileImageSuccess,
  uploadProfileImageFailure,
  UPLOAD_PROFILE_IMAGE,
  GET_USER_BALANCE,
  GET_DELIVERY_PREF,
  UPDATE_DELIVERY_PREF,
  getDeliveryPreferenceFailure,
  getDeliveryPreferenceSuccess,
  updateDeliveryPreferenceFailure,
  updateDeliveryPreferenceSuccess,
  getUserBalanceSuccess,
  getUserBalanceFailure
} from '../actions'
import { GET_DELIVERY_PREF_Q, UPDATE_DELIVERY_PREF_M } from '../graphql/queries'
import { print } from 'graphql'

import api from '../../../../utils/api'
import log from '../../../../utils/logger'

const getUserProfileActions = () => ({
  start: fetchUserProfileStart,
  success: fetchUserProfileSuccess,
  error: fetchUserProfileError
})

export function* fetchUser(isRefreshing, { userId }) {
  const actions = getUserProfileActions()
  const id = userId ? userId : yield select(getUserId)

  try {
    yield put(actions.start())
    const authToken = yield select(getToken)

    yield put(fetchUserProfileStart())
    const response = yield call(api.getUser, id, authToken)
    yield put(fetchUserProfileSuccess(response))
  } catch (error) {
    yield put(fetchUserProfileError())
    log(`Failed to fetch user. Error: ${error}`)
  }
}

export function* getUserBalance({ userId }) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(api.getPaymentBalance, userId, authToken)
    yield put(getUserBalanceSuccess(response))
  } catch (error) {
    yield put(getUserBalanceFailure())
    log(`Failed to get user balance`)
  }
}

export function* uploadProfileImage({ userId, file }) {
  try {
    const authToken = yield select(getToken)

    const response = yield call(api.uploadProfileImage, userId, file, authToken)
    yield put(uploadProfileImageSuccess(response))
  } catch (error) {
    yield put(uploadProfileImageFailure())
    log(`Failed to upload`)
  }
}

export function* getDeliveryPreference() {
  try {
    const authToken = yield select(getToken)
    const paymentToken = yield select(getPaymentToken)
    const tokens = { authToken, paymentToken }
    const response = yield call(api.graphqlQuery, tokens, GET_DELIVERY_PREF_Q)

    yield put(getDeliveryPreferenceSuccess(response))
  } catch (error) {
    log(`Failed to fetch delivery preferences`)
    yield put(getDeliveryPreferenceFailure())
  }
}

export function* updateDeliveryPreference({ deliveryPreferences }) {
  try {
    const authToken = yield select(getToken)
    const paymentToken = yield select(getPaymentToken)
    const tokens = { authToken, paymentToken }
    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(UPDATE_DELIVERY_PREF_M),
      {
        billingType: parseInt(deliveryPreferences.billingType),
        reasonCode: deliveryPreferences.reasonCode
      }
    )

    yield put(updateDeliveryPreferenceSuccess(response))
  } catch (error) {
    log(`Failed to fetch delivery preferences`)
    yield put(updateDeliveryPreferenceFailure())
  }
}

function* watchGetDeliveryPreference() {
  yield takeLatest(GET_DELIVERY_PREF, getDeliveryPreference)
}

function* watchUpdateDeliveryPreference() {
  yield takeLatest(UPDATE_DELIVERY_PREF, updateDeliveryPreference)
}

function* watchFetchUser() {
  yield takeLatest(fetchUserProfileAction().type, fetchUser, false)
}

function* watchGetUserBalance() {
  yield takeLatest(GET_USER_BALANCE, getUserBalance)
}

function* watchUploadProfileImage() {
  yield takeLatest(UPLOAD_PROFILE_IMAGE, uploadProfileImage)
}

export default [
  watchFetchUser(),
  watchUploadProfileImage(),
  watchGetUserBalance(),
  watchGetDeliveryPreference(),
  watchUpdateDeliveryPreference()
]
