// @flow
import React from 'react'
import momentModule, { Moment } from 'moment'
import { DatetimePicker } from 'rc-datetime-picker'
import { getMonth, getDateNumber, getDay } from 'zego-shared/utils/date'

import { CalendarWrapper, SelectedDate, Date, Day, Month } from './styles'

type Props = {
  width?: string,
  moment: Moment,
  flex?: number,
  handleChange: () => void,
  previousDatesEnabled?: boolean,
  noBoxShadow?: boolean,
  flexDirection?: string,
  minPanel?: 'day' | 'month',
  showSelectedDate?: boolean,
  startDate?: Moment
}

class Calendar extends React.PureComponent<Props> {
  static defaultProps = {
    width: '',
    moment: '',
    flex: 0,
    previousDatesEnabled: false,
    noBoxShadow: false,
    flexDirection: '',
    minPanel: 'day'
  }
  render() {
    const {
      width,
      flex,
      moment,
      flexDirection,
      handleChange,
      previousDatesEnabled,
      noBoxShadow = false,
      minPanel,
      showSelectedDate = true,
      startDate = momentModule()
    } = this.props

    const momentFormatted = moment ? moment.format() : ''
    return (
      <CalendarWrapper
        flexDirection={flexDirection}
        flex={flex}
        width={width}
        noBoxShadow={noBoxShadow}>
        {showSelectedDate && (
          <SelectedDate>
            <Day>{getDay(momentFormatted)}</Day>
            <Date>{getDateNumber(momentFormatted)}</Date>
            <Month>{getMonth(momentFormatted)}</Month>
          </SelectedDate>
        )}

        <DatetimePicker
          minDate={previousDatesEnabled ? null : startDate}
          moment={moment}
          onChange={handleChange}
          minPanel={minPanel}
        />
      </CalendarWrapper>
    )
  }
}

export default Calendar
