import { useSelector } from 'react-redux'
import {
  getLinkToken,
  hasPlaid,
  achPlaidOnly
} from 'zego-shared/store/payments/plaid/selectors'

export function useHasPlaid() {
  return {
    enabled: useSelector(hasPlaid),
    token: useSelector(getLinkToken),
    plaidOnly: useSelector(achPlaidOnly),
  }
}
