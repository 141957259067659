import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  fetchTermsAndConditionsAction,
  fetchTermsAndConditionsStart,
  fetchTermsAndConditionsSuccess,
  fetchTermsAndConditionsError,
  acceptTermsAndConditionsAction,
  acceptTermsAndConditionsSuccess,
  acceptTermsAndConditionsError
} from '../actions'
import api from '../../../utils/api'
import { getToken, getUserId } from '../../authorization/selectors'
import { getId } from '../selectors'
import { showSnackbar } from '../../snackbar/actions'
import { tAndCAcceptanceErrorMessage } from '../../../utils/messages'

export function* fetchLatestTAndC({ tncType = 'resident' } = {}) {
  try {
    const authToken = yield select(getToken)
    const residentId = yield select(getUserId)

    yield put(fetchTermsAndConditionsStart())

    const response = yield call(
      api.getLatestTermsAndConditions,
      authToken,
      residentId,
      tncType
    )

    yield put(fetchTermsAndConditionsSuccess({ ...response, tncType }))
  } catch (error) {
    yield put(fetchTermsAndConditionsError(error))
  }
}

export function* acceptTAndC({ tncType = 'resident' } = {}) {
  try {
    const authToken = yield select(getToken)
    const id = yield select(getId, tncType)

    const response = yield call(api.acceptTermsAndConditions, authToken, id)
    yield put(acceptTermsAndConditionsSuccess(response))
  } catch (error) {
    yield put(acceptTermsAndConditionsError(error))
    yield put(showSnackbar(tAndCAcceptanceErrorMessage, 'error'))
  }
}

export function* watchFetchLatestTAndC() {
  yield takeLatest(fetchTermsAndConditionsAction().type, fetchLatestTAndC)
}

export function* watchAcceptTAndC() {
  yield takeLatest(acceptTermsAndConditionsAction().type, acceptTAndC)
}

export default [watchFetchLatestTAndC(), watchAcceptTAndC()]
