import React from 'react'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import api from 'zego-shared/utils/api'
import { createHTTPHeader } from 'zego-shared/utils/api/utils'
import { connect } from 'react-redux'
import { getToken } from 'zego-shared/store/authorization/selectors'
import { HttpLink } from 'apollo-link-http'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'

// generated by https://graphql-code-generator.com/docs/plugins/fragment-matcher
import introspectionQueryResultData from './fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const Provider = ({ children, authToken }) => {
  const client = new ApolloClient({
    link: ApolloLink.from([
      setContext(() => ({ headers: createHTTPHeader(authToken) })),
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          )
        if (networkError) console.log(`[Network error]: ${networkError}`)
      }),
      new HttpLink({
        uri: api.getGraphqlEndpoint()
      })
    ]),
    cache: new InMemoryCache({ fragmentMatcher })
  })
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

const mapStateToProps = state => ({
  authToken: getToken(state)
})

export default connect(mapStateToProps)(Provider)
