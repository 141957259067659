// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import {
  Bold,
  LCLogoSuccess,
  SuccessLogoContainer,
  SuccessMessage,
  SuccessTitle,
  SuccessMessageContainer, AdLink, AdText, MainText, SubText, GoButton, AdContainer
} from '../Lookback/styles'

const ReactivateSuccess = () => {
  const residentId = useSelector(getUserId)
  const creditReportingUrl = `/payments/${residentId}/credit-reporting`

  return (
    <>
      <SuccessLogoContainer justifySpaceBetween>
        <SuccessTitle>
          <span>Welcome Back!</span>
        </SuccessTitle>
        <LCLogoSuccess />
      </SuccessLogoContainer>
      <SuccessMessageContainer>
        <SuccessMessage>
          <Bold>You have reactivated your Credit Reporting account.</Bold>
          Moving forward, your qualifying payments will again be reported to Equifax and TransUnion.
        </SuccessMessage>
        <AdContainer justifyCenter>
          <AdLink>
            <AdText>
              <MainText>Check Out </MainText>
              <SubText> Your Credit Reporting Page</SubText>
            </AdText>
            <GoButton to={creditReportingUrl}>Go Now!</GoButton>
          </AdLink>
        </AdContainer>
      </SuccessMessageContainer>
    </>
  )
}

export default ReactivateSuccess
