// @flow
import moment from "moment"
import { string, object, ref, addMethod, date, boolean } from 'yup'
import { zipCodeRegex, SSNRegex, validateDate, optInNameRegex } from '@utils/validationUtils'

export const DATE_FORMAT = 'MM/DD/YYYY'
const maxBirthDate = moment().subtract(18, 'year')

addMethod(date, 'format', validateDate)

export default (
  validateLeaseEndDate: boolean,
  isLevelCreditEnabled: boolean
) => {
  const shape = {
    firstName: string()
      .trim()
      .min(2, 'First name must be at least 2 characters long')
      .matches(new RegExp(optInNameRegex), 'First name can only contain letters, spaces, hyphens and apostrophes')
      .required('First name is required'),
    lastName: string()
      .trim()
      .min(2, 'Last name must be at least 2 characters long')
      .matches(new RegExp(optInNameRegex), 'Last name can only contain letters, spaces, hyphens and apostrophes')
      .required('Last name is required'),
    address: string()
      .trim()
      .min(2, 'Address must be at least 2 characters long')
      .required('Address is required'),
    address2: string().ensure(),
    city: string()
      .trim()
      .min(2, 'City must be at least 2 characters long')
      .required('City is required'),
    state: string()
      .trim()
      .required('State is required'),
    postalCode: string()
      .trim()
      .matches(new RegExp(zipCodeRegex), { message: 'Invalid zip code' })
      .required('Zip code is required'),
    ssn: string()
      .trim()
      .matches(new RegExp(SSNRegex), { message: 'SSN must be 9 digits' })
      .required('SSN is required'),
    confirmSnn: string()
      .trim()
      .oneOf([ref('ssn'), null], 'SSN must match')
      .required('Confirm SSN is required'),
    birthDate: date()
      .format(DATE_FORMAT)
      .typeError('Birthdate is invalid')
      .required('Birthdate is required')
      .max(maxBirthDate, 'You must be at least 18 years old'),

    leaseEndDate: undefined,
    email: undefined,
    zegoTermsAndConditions: undefined,
    levelCreditTermsAndConditions: undefined
  }

  if (validateLeaseEndDate) {
    shape.leaseEndDate = date()
      .format(DATE_FORMAT)
      .typeError('Lease end date is invalid')
      .required('Lease end date is required')
  }

  if (isLevelCreditEnabled) {
    shape.email = string()
      .trim()
      .email()
      .required('Email is required')
    /* This will be enabled in phase 2
    shape.zegoTermsAndConditions = boolean()
      .oneOf([true], "You must agree to Zego's Terms and Conditions")
      .required()
    */

    shape.levelCreditTermsAndConditions = boolean()
      .oneOf(
        [true],
        "You must agree to LevelCredit's Terms and conditions required"
      )
      .required()
  }

  return object().shape(shape)
}
