// @flow
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import { Drawer } from '@material-ui/core'
import { generate as shortid } from 'shortid'
import { CommonIcon } from '@icons'
import type { BrowserLocation } from 'history'
import type { Theme } from '../../../theme'
import { getPaymentsMenuItems } from '@utils/view'
import { getLatestTAndC } from 'zego-shared/store/termsAndConditions/selectors'
import {
  useStyles,
  HeaderIconWrap,
  DrawerIconWrap,
  HeaderText,
  StyledList,
  StyledLink
} from './styles'

type Props = {
  residentId: string,
  hasStatements: boolean,
  nativePayerWithoutPayment: boolean,
  isLevelCreditEnabled: boolean,
  isLegacyOptedIn: boolean,
  open: boolean,
  location: BrowserLocation,
  theme: Theme,
  disableMakePaymentMenuTab: boolean,
  disableAutopayMenuTab: boolean,
  autopays: {
    autopayType: string,
    paymentFrequency: string,
    skipped: boolean,
    paymentInfo: string,
    maxLimit: string,
    total: string,
    nextPayment: string
  }[]
}

const SsoPaymentsMenu = (props: Props) => {
  const {
    residentId,
    hasStatements,
    nativePayerWithoutPayment,
    isLevelCreditEnabled,
    isLegacyOptedIn,
    open = false,
    location,
    theme,
    disableMakePaymentMenuTab,
    disableAutopayMenuTab,
    autopays
  } = props
  const classes = useStyles()
  const [isOpen, setState] = useState(open)
  const { url: termsAndConditionsUrl } = useSelector(getLatestTAndC)
  const showCreditReporting = isLevelCreditEnabled || isLegacyOptedIn

  const pmSettings = {
    disableMakePaymentMenuTab: disableMakePaymentMenuTab,
    disableAutopayMenuTab: disableAutopayMenuTab && !autopays.length
  }

  const navigationItems = [
    ...getPaymentsMenuItems(
      residentId,
      hasStatements,
      showCreditReporting,
      nativePayerWithoutPayment,
      pmSettings
    ),
    {
      name: 'Support Center',
      url: 'https://gozego.force.com/residents/',
      externalUrl: true
    },
    {
      name: 'Terms and Conditions',
      url: termsAndConditionsUrl,
      externalUrl: true
    }
  ]

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState(open)
  }

  const renderLink = link => {
    const isActive = location.pathname.includes(link.url)
    const id = link.name.replace(/\s/g, '')

    return link.externalUrl ? (
      <StyledLink
        id={id}
        key={shortid()}
        to={{ pathname: link.url }}
        target="_blank"
        rel="noopener noreferrer">
        {link.name}
      </StyledLink>
    ) : (
      <StyledLink
        id={id}
        key={shortid()}
        exact
        to={link.url}
        onClick={toggleDrawer(false)}
        activeClassName="active"
        isActive={(match, location) => {
          return location.pathname.includes(link.url)
        }}>
        {isActive && (
          <CommonIcon
            name="square"
            height="10px"
            width="10px"
            fill={theme.palette.tertiary.dark}
          />
        )}
        {link.name}
      </StyledLink>
    )
  }

  return (
    <>
      <HeaderIconWrap onClick={toggleDrawer(true)}>
        <CommonIcon
          id="topNavEnableBtn"
          name="menu_icon"
          width="19px"
          height="14px"
        />
        <HeaderText>Menu</HeaderText>
      </HeaderIconWrap>
      <Drawer
        anchor="left"
        variant="persistent"
        open={isOpen}
        onClose={toggleDrawer(false)}
        classes={{
          paper: classes.paper,
          paperAnchorDockedLeft: classes.paperAnchorDockedLeft
        }}>
        <DrawerIconWrap>
          <CommonIcon
            id="topNavCloseBtn"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            name="cancel_icon"
            width="24px"
            height="24px"
          />
        </DrawerIconWrap>
        <StyledList>{navigationItems.map(renderLink)}</StyledList>
      </Drawer>
    </>
  )
}

export default withRouter(withTheme(SsoPaymentsMenu))
