// @flow

import { combineReducers } from 'redux'
import withFetch from '../../helpers/reducers/withFetch'
import { MARKETPLACEDEALLIST } from '../actions'

const marketplaceReducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default combineReducers({
  list: withFetch(MARKETPLACEDEALLIST)(marketplaceReducer)
})
