import { makeStyles } from '@material-ui/core/styles'
import styled from '@emotion/styled'
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const useStyles = makeStyles({
  root: {
    '&.MuiPaper-root': { paddingLeft: '2em', paddingRight: '2em' }
  },
  warningIcon: {
    height: '6rem'
  },
  content: {
    fontSize: '1.5em',
    '& p': { marginBottom: '12px' },
    '& strong': { textTransform: 'capitalize' }
  },
  button: {
    flexGrow: 1,
    height: '54px'
  }
})

export const AcknowledgementCheckbox = styled('input')`
  display: flex;
  align-self: start;
  justify-self: start;
  margin-right: 5px;
`
export const StyledFormControlLabel= styled(FormControlLabel)`
    margin-left: 5px;
    span.MuiFormControlLabel-label{
      font-size: 14px;
      font-weight: 600;
  }
`
