// @flow
import React from 'react'
import Avatar from '@Common/Avatar'
import SsoPaymentsMenu from '@Common/SsoPaymentsMenu'
import { Container, ButtonBase, Grid, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { withRouter } from 'react-router'
import { useDispatch } from 'react-redux'
import { setPreviousUrl } from 'zego-shared/store/previousUrl/actions'
import type { Props } from '../AppBar'
import { SSOAppBar, SSOHeaderText, SSOToolbar } from '../styles'
import { truncateString } from '@utils/stringUtils'

const AppBar = (props: Props) => {
  const {
    residentName,
    profilePicture,
    history,
    match,
    location,
    height,
    ...rest
  } = props

  const dispatch = useDispatch()

  const setPreviousURL = () => {
    const currentUrl = match.url.split('/')
    const isMyAccount = ['my-account'].includes(currentUrl[1])
    !isMyAccount && dispatch(setPreviousUrl(location.pathname))
  }

  const goToMyAccount = () => {
    setPreviousURL()
    history.push('/my-account')
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <SSOAppBar {...rest} height={height}>
      <Container style={{ zIndex: 1 }}>
        <SSOToolbar disableGutters={true}>
          <Grid container direction="row">
            <Grid item xs container direction="row">
              <SsoPaymentsMenu />
            </Grid>
            <Grid item xs container direction="row" justify="flex-end">
              <ButtonBase onClick={goToMyAccount}>
                <Avatar
                  size="22px"
                  picture={profilePicture}
                  hideShadow={true}
                />
              </ButtonBase>
              <SSOHeaderText
                id="resName"
                onClick={goToMyAccount}
                style={{ cursor: 'pointer' }}>
                {isMobile ? truncateString(residentName, 14) : residentName}
              </SSOHeaderText>
            </Grid>
          </Grid>
        </SSOToolbar>
      </Container>
    </SSOAppBar>
  )
}

export default withRouter(AppBar)
