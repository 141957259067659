// @flow
import * as React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { capitalizeOnlyTheFirstLetter } from 'zego-shared/utils/string'

import { FormControl } from './styles'

type Props = {
  children: React.Node,
  htmlFor?: string,
  errorId?: string,
  label: string,
  LabelProps?: Object,
  error?: string,
  touched?: boolean,
  disabled?: boolean,
  hidden?: boolean,
  helperText?: string
}

const FormGroup = ({
  LabelProps = {},
  children,
  label,
  htmlFor,
  error,
  touched,
  disabled,
  hidden,
  helperText,
  errorId = '',
  ...props
}: Props) => {
  return (
    <FormControl
      {...props}
      error={Boolean(error && touched)}
      disabled={disabled}
      hidden={hidden}>
      <InputLabel htmlFor={htmlFor} {...LabelProps}>
        {label}
      </InputLabel>
      {children}
      <FormHelperText id={`${errorId && `${errorId}-error`}`}>
        {error && touched ? capitalizeOnlyTheFirstLetter(error) : helperText}
      </FormHelperText>
    </FormControl>
  )
}

export default FormGroup
