import { WORK_ORDER_HISTORY_SECTION } from '../actions'
import withFetch from '../../../helpers/reducers/withFetch'
import { fetchWorkOrderHistorySuccess } from '../actions'

const initialState = { data: [] }

const workOrderHistory = (state = initialState, action) => {
  switch (action.type) {
    case fetchWorkOrderHistorySuccess().type:
      return {
        ...state,
        data: action.data
      }
    default:
      return state
  }
}

export default withFetch(WORK_ORDER_HISTORY_SECTION)(workOrderHistory)
