// @flow
import React from 'react'
import Button from '@Common/Button'
import { TextInput } from '@Common/Form'
import moment, { Moment } from 'moment'
import { Grid, InputAdornment } from '@material-ui/core'
import Event from '@material-ui/icons/Event'
import Flex from '@Common/Flex'
import Calendar from '@Common/Calendar'
import { dateTypes } from '@utils/view/autoPaySchedule'

import { isPickADate as isPickADateFn } from '@utils/view/autoPaySchedule'

import LeftSection from './LeftSection'
import DayPicker from '../DayPicker'
import {
  ScheduleInputStyled,
  LeftSectionStyled,
  ActionBtnsStyled,
  CalenderWrapperStyled,
  PopoverStyled
} from './styles'

type Option = { value: string, label: string }

type Props = {
  leftSectionOptions?: Option[],
  selectedDay: number,
  onDateChange: Function,
  onLeftSectionValueChange: Function,
  leftSectionValue: string,
  DayPickerProps?: Object,
  value: string,
  onDone?: Function,
  date: Date,
  allowedDays: number[],
  showMonth: boolean
}
type SetState = Object => void

export const getHandleDateChange = (
  setState: SetState,
  state: Object,
  onDone: Function
) => (date: Date) => {
  const updatedState = { ...state, selectedDay: date, date: moment(date) }

  onDone && onDone(updatedState)
  setState(updatedState)
}

export const getHandleMonthChange = (
  isPickADate: boolean,
  setState: SetState,
  state: Object,
  onDone: Function
) => (date: Moment) => {
  if (isPickADate) {
    const updatedState = { ...state, date }

    onDone && onDone(updatedState)
    setState(updatedState)
  }
}

export const getHandleLeftSectionChange = (
  setState: SetState,
  state: Object,
  onDone: Function
) => (dateType: string) => {
  const updatedState = {
    ...state,
    leftSectionValue: dateType,
    selectedDay: null,
    date: undefined
  }

  if (
    dateTypes.firstDay === dateType ||
    dateTypes.lastDay === dateType ||
    dateTypes.indefinite === dateType
  ) {
    onDone && onDone(updatedState)
  }

  setState(updatedState)
}

export const getCancel = (setState: SetState, initialState: Object) => () => {
  setState(initialState)
}

const ActionBtns = props => {
  const { hasLeftSection, onCancel, onDone } = props
  return (
    <ActionBtnsStyled
      justifySpaceBetween={hasLeftSection}
      justifyRight={!hasLeftSection}
      hasLeftSection={hasLeftSection}>
      <Button
        id="calendarCancel"
        onClick={onCancel}
        variant="text"
        disableRipple>
        Close
      </Button>
      <Button id="calendarDone" onClick={onDone} variant="text" disableRipple>
        Done
      </Button>
    </ActionBtnsStyled>
  )
}

const ScheduleInput = ({
  leftSectionOptions = [],
  selectedDay,
  leftSectionValue,
  DayPickerProps = {},
  value,
  date,
  onDone,
  allowedDays,
  showMonth
}: Props) => {
  const initialState = {
    leftSectionValue,
    selectedDay,
    date,
    anchorEl: null
  }

  const [state, setState] = React.useState(initialState)
  const isPickADate = isPickADateFn(state.leftSectionValue)
  const handleSetState = (key: string, value) =>
    setState({ ...state, [key]: value })
  const handleClick = event => handleSetState('anchorEl', event.currentTarget)
  const handleDateChange = getHandleDateChange(setState, state, onDone)
  const handleMonthChange = getHandleMonthChange(
    isPickADate,
    setState,
    state,
    onDone
  )
  const handleLeftSectionChange = getHandleLeftSectionChange(
    setState,
    state,
    onDone
  )
  const cancel = getCancel(setState, initialState)

  const done = () => {
    const { anchorEl, ...rest } = state
    if ((isPickADate && state.date) || !isPickADate) {
      onDone && onDone(rest)
      handleSetState('anchorEl', null)
    } else {
      cancel()
    }
  }

  React.useEffect(() => {
    handleSetState('selectedDay', selectedDay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay])

  const open = Boolean(state.anchorEl)
  const id = open ? 'schedule-calendar' : null
  const hasLeftSection = leftSectionOptions.length > 0

  return (
    <ScheduleInputStyled>
      <TextInput
        onClick={handleClick}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Event />
            </InputAdornment>
          )
        }}
      />
      <PopoverStyled
        id={id}
        open={open}
        anchorEl={state.anchorEl}
        onClose={cancel}
        elevation={2}
        anchorOrigin={{
          vertical: 40,
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Flex>
          <Grid
            container
            component={CalenderWrapperStyled}
            hasLeftSection={hasLeftSection}
            disableAll={!isPickADate}>
            {hasLeftSection && (
              <Grid item xs={12} sm={5}>
                <LeftSectionStyled direction="column" justifySpaceBetween>
                  <LeftSection
                    selected={state.leftSectionValue}
                    options={leftSectionOptions}
                    handleChange={handleLeftSectionChange}
                  />
                  <ActionBtns
                    onCancel={cancel}
                    onDone={done}
                    hasLeftSection={hasLeftSection}
                  />
                </LeftSectionStyled>
              </Grid>
            )}
            <Grid item xs={12} sm={hasLeftSection ? 7 : 12}>
              <Flex direction="column" style={{ flex: 1 }}>
                {!showMonth && (
                  <DayPicker
                    selectedDay={state.selectedDay}
                    onChange={handleDateChange}
                    disableAll={false}
                    allowedDays={allowedDays}
                    {...DayPickerProps}
                  />
                )}
                {showMonth && (
                  <Calendar
                    width="100%"
                    showSelectedDate={false}
                    moment={state.date ? moment(state.date) : undefined}
                    minPanel="month"
                    handleChange={handleMonthChange}
                    startDate={moment().add('M', 1)}
                  />
                )}
                {!hasLeftSection && (
                  <ActionBtns
                    onCancel={cancel}
                    onDone={done}
                    hasLeftSection={hasLeftSection}
                  />
                )}
              </Flex>
            </Grid>
          </Grid>
        </Flex>
      </PopoverStyled>
    </ScheduleInputStyled>
  )
}

export default ScheduleInput
