// @flow
import React, { Fragment } from 'react'
import { withTheme } from 'emotion-theming'
import { TableRow, Typography } from '@material-ui/core'

import ListFull from '@Common/ListFull'
import { TitleStyled, TableBodyCellStyled, LinkStyled } from './styles'

import type { Theme } from '../../../theme'

type Row = {
  dueDate: string,
  amount: string,
  billPDFUrl: string
}

type Header = {
  label: string,
  dataKey: string,
  sortable: boolean,
  active?: boolean,
  link?: boolean,
  linkText?: string,
  align?: string,
  width?: string
}

type Props = {
  statementsData: Row[],
  hasStatements: boolean,
  isFetching: boolean,
  theme: Theme
}

const buildHeaders = (isXS: boolean) => ([
  { label: 'Due Date', dataKey: 'dueDate', sortable: true, active: true },
  { label: 'Amount', dataKey: 'amount', sortable: false },
  {
    label: 'File',
    dataKey: 'billPDFUrl',
    sortable: false,
    link: true,
    linkText: 'View Bill PDF',
    align: 'right',
    width: isXS ? '45%' : '75%'
  }
])

const buildRows = (rows: Row[], headers: Header[]) => {
  return rows.map((row, index) => {
    return (
      <TableRow key={index}>
        {headers.map(({ dataKey, align, link, linkText }: Header, index) => {
          return (
            <TableBodyCellStyled key={row[dataKey]} align={align}>
              <Typography variant={index === 0 ? 'h5' : 'body1'}>
                {link ? (
                  <LinkStyled
                    href={row[dataKey]}
                    target="_blank"
                    rel="noopener noreferrer">
                    {linkText}
                  </LinkStyled>
                ) : (
                  row[dataKey]
                )}
              </Typography>
            </TableBodyCellStyled>
          )
        })}
      </TableRow>
    )
  })
}

const Statements = ({ isFetching, hasStatements, statementsData, theme }: Props) => {
  if (!hasStatements) return <div>No Statements Available</div>

  const isXS = theme.breakpoints.only('xs')
  const headers = buildHeaders(isXS)
  return (
    <Fragment>
      <TitleStyled>Statements</TitleStyled>
      <ListFull
        columns={headers}
        rows={buildRows(statementsData, headers)}
        useInternalRow={false}
      />
    </Fragment>
  )
}

export default withTheme(Statements)
