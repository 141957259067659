// @flow
import { useState } from 'react'

type Order = 'asc' | 'desc'
type Rows = Object[]
type OrderBy = string | null
type HandleSort = (property: OrderBy) => void

const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const sort = (array, cmp) => {
  const withIndex = array.map((el, index) => [el, index])
  withIndex.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return withIndex.map(el => el[0])
}

const getSorting = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

const useSortBy = (rows: Rows = []): [Rows, Order, OrderBy, HandleSort] => {
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState(null)

  const handleSort: HandleSort = property => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const sorted = orderBy ? sort(rows, getSorting(order, orderBy)) : rows

  return [sorted, order, orderBy, handleSort]
}

export default useSortBy
