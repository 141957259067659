import {
  fetchTermsAndConditionsSuccess,
  acceptTermsAndConditionsSuccess
} from '../actions'

const defaultAction = {}
const defaultPayload = {}

const reducers = (
  state = {},
  {
    type,
    payload: { tncType = 'resident', ...data } = defaultPayload
  } = defaultAction
) => {
  switch (type) {
    case fetchTermsAndConditionsSuccess().type:
      return { ...state, [tncType]: data }
    case acceptTermsAndConditionsSuccess().type:
      return {
        ...state,
        [tncType]: { ...state[tncType], has_signed_latest_terms: true }
      }
    default:
      return state
  }
}

export default reducers
