// @flow
import React from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useStyles } from './styles'

export type Props = {
  itemId: string,
  index?: number | string,
  nextRun: string,
  total: string,
  account: string,
  isVariable: boolean,
  acknowledgement: boolean,
  onClick: (itemId: string) => {}
}

const ReauthAutoPayCard = ({
  itemId,
  index = '',
  nextRun,
  total,
  account,
  isVariable,
  acknowledgement,
  onClick
}: Props) => {
  const classes = useStyles({ nextRun })

  return (
    <Card className={classes.container}>
      <CardContent className={classes.content}>
        <Typography className={classes.titleText}>
          Auto Payment {index}
        </Typography>
        <Typography className={classes.dateText}>
          Next run: {nextRun}
        </Typography>
        <Typography className={classes.totalText}>
          {isVariable ? 'Variable Amount' : `Total ${total}`}
        </Typography>
        <Typography className={classes.accountText}>
          Paid with <span>{account}</span>
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button disabled={acknowledgement} className={classes.button} onClick={() => onClick(itemId)}>
          Reauthorize
        </Button>
      </CardActions>
    </Card>
  )
}

export default ReauthAutoPayCard
