// @flow
import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'

import { FormControlLabel } from '../RadioButton/styles'
import { CheckboxIcon } from '@icons'

type Props = {
  checked: boolean,
  onChange: (event: SyntheticEvent<HTMLInputElement>, checked: boolean) => void,
  value: string,
  label?: string,
  CheckBoxProps?: Object
}

const CheckBox = ({
  checked,
  onChange,
  value,
  label,
  CheckBoxProps = {},
  ...props
}: Props) => (
  <FormControlLabel
    {...props}
    value={value}
    selected={checked}
    control={
      <Checkbox
        icon={<CheckboxIcon name="circle" height="22px" width="24px" />}
        checkedIcon={
          <CheckboxIcon name="checkmark" height="23px" width="24px" />
        }
        checked={checked}
        onChange={onChange}
        {...CheckBoxProps}
      />
    }
    label={label}
  />
)

export default CheckBox
