// @flow
import React from 'react'
import { Grid } from '@material-ui/core'
import Loader from '@Common/Loader'
import withWidth from '@material-ui/core/withWidth'
import { withRouter } from 'react-router'
import PaymentsBlocked from './PaymentsBlocked'
import MyPaymentsInfo from './MyPaymentsInfo'
import NewUserNoBalance from './NewUserNoBalance'
import WithBalance from './WithBalance'
import WithBalanceAutoPay from './WithBalanceAutoPay'
import LastPayment from './LastPayment'
import ReadMore from './ReadMore'
import { paymentStatuses } from 'zego-shared/store/payments/summary/constants'
import LookbackAd from '@views/Payments/CreditReporting/LookbackAds/SummaryPage'
import CreditReportingAd from '@views/Payments/CreditReportingAd'
import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { shortenAndFormatString } from '@utils/stringUtils'
import {
  MyInfo,
  PageDescription,
  PageTitle,
  StyledGrid,
  MyInfoWrap,
  ShowGridForMdDown,
  ShowGridForMdUp,
  PaymentStateYellowBg,
  PaymentSummaryMemoStyled,
  AdWrapMdDown
} from './styles'

type Props = {
  isFetching?: boolean,
  userId: string,
  width: string,
  myInfo: {
    residentName: string,
    unitName: string,
    propertyName: string,
    address: string
  },
  balanceDetails: {
    label: string,
    value: string
  },
  lineItemsDetails: {
    label: string,
    value: string
  },
  nextAutoPay: string,
  totalOwed: Object,
  history: Object,
  paymentState: number,
  totalOwedLastUpdated: string,
  lastPayment: Object,
  isLastPaymentProcessing: boolean,
  creditReportingEnabled: boolean,
  levelCreditReportingEnabled: boolean,
  lookbackEnabled: boolean,
  isOptedIn: boolean,
  levelCreditActive: boolean,
  levelCreditInactive: boolean,
  paymentSummaryMemo: string,
  hasEditableFields: boolean,
  disableMakePaymentMenuTab: boolean,
  autopays: {
    autopayType: string,
    paymentFrequency: string,
    skipped: boolean,
    paymentInfo: string,
    maxLimit: string,
    total: string,
    nextPayment: string
  }[],
  disableAutopayMenuTab: boolean
}
export const getGoToAutoPay = (push: string => mixed, userId: string) => () => {
  push(`/payments/${userId}/auto-pay`)
}
export const getGoToOneTime = (push: string => mixed, userId: string) => (
  editBal?: boolean
) => {
  const url = editBal
    ? `/payments/${userId}/one-time?edit_bal=true`
    : `/payments/${userId}/one-time`
  push(url)
}
const getGoToPaymentDetails = (
  push: (url: string, state: Object) => mixed,
  userId: string
) => (transId: string, state: Object) => {
  push(`/payments/${userId}/history/${transId}`, state)
}

const DEFAULT_SUMMARY_MEMO =
  'Make your property payments with Zego. Pay now with one-time payments or set up an auto payment to repeat payments you make on a regular basis.'
const SUMMARY_MEMO_NO_OTP =
  'Make your property payments with Zego. Set up an auto payment now to repeat payments you make on a regular basis.'

export const Summary = (props: Props) => {
  const {
    isFetching,
    myInfo,
    totalOwed,
    totalOwedLastUpdated,
    balanceDetails,
    lineItemsDetails,
    paymentState,
    nextAutoPay,
    lastPayment,
    history: { push },
    userId,
    width,
    isOptedIn,
    levelCreditActive,
    levelCreditInactive,
    levelCreditReportingEnabled,
    lookbackEnabled,
    paymentSummaryMemo,
    hasEditableFields,
    disableMakePaymentMenuTab,
    autopays,
    disableAutopayMenuTab
  } = props
  const goToAutoPay = getGoToAutoPay(push, userId)
  const goToOneTime = getGoToOneTime(push, userId)
  const goToPaymentDetails = getGoToPaymentDetails(push, userId)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const maxMemoCharactersAllowed = isMobile ? 100 : 200
  const formattedPaymentSummaryMemo = shortenAndFormatString(
    paymentSummaryMemo,
    maxMemoCharactersAllowed
  )
  const autopayCount = autopays.length

  const renderPaymentState = () => {
    if (paymentState === paymentStatuses.BLOCKED) {
      return <PaymentsBlocked />
    } else if (paymentState === paymentStatuses.LOCKED_ZERO) {
      return <NewUserNoBalance />
    } else if (paymentState === paymentStatuses.UNLOCKED_ZERO) {
      return (
        <NewUserNoBalance
          unlockedZero={true}
          disableMakePaymentMenuTab={disableMakePaymentMenuTab}
          onClickPayNow={goToOneTime}
          onClickSetupAutoPay={goToAutoPay}
          autopayCount={autopayCount}
        />
      )
    } else if (paymentState === paymentStatuses.PROCESSING) {
      return (
        <LastPayment
          lastPayment={lastPayment}
          goToOneTime={goToOneTime}
          goToPaymentDetails={goToPaymentDetails}
          disableMakePaymentMenuTab={disableMakePaymentMenuTab}
        />
      )
    } else if (nextAutoPay) {
      return (
        <WithBalanceAutoPay
          totalOwedLastUpdated={totalOwedLastUpdated}
          balanceDetails={balanceDetails}
          totalOwed={totalOwed}
          userId={userId}
          nextAutoPay={nextAutoPay}
          onClickPayNow={goToOneTime}
          onClickViewAutoPay={goToAutoPay}
          hasEditableFields={hasEditableFields}
          lineItemsDetails={lineItemsDetails}
          disableMakePaymentMenuTab={disableMakePaymentMenuTab}
        />
      )
    } else {
      return (
        <WithBalance
          totalOwedLastUpdated={totalOwedLastUpdated}
          totalOwed={totalOwed}
          lineItemsDetails={lineItemsDetails}
          userId={userId}
          onClickPayNow={goToOneTime}
          onClickViewAutoPay={goToAutoPay}
          hasEditableFields={hasEditableFields}
          disableMakePaymentMenuTab={disableMakePaymentMenuTab}
          disableAutopayMenuTab={disableAutopayMenuTab}
        />
      )
    }
  }
  if (isFetching) {
    return <Loader />
  }

  const renderCreditReportingAd = () => {
    if (lookbackEnabled) return <LookbackAd />
    if (
      (levelCreditReportingEnabled === true && !levelCreditInactive) &&
      (isOptedIn === false || levelCreditActive === true)
    ) {
      return <CreditReportingAd isOptedIn={levelCreditActive} />
    }
  }

  const showLevelCreditAd = () => {
    if (
      (levelCreditReportingEnabled === true && !levelCreditInactive) &&
      (isOptedIn === false || levelCreditActive === true)
    ) {
      return true
    } else {
      return false
    }
  }

  const renderPaymentSummaryMemo = () => {
    const shouldShowReadMore =
      paymentSummaryMemo.length > maxMemoCharactersAllowed
    const title = myInfo.propertyName
      ? `Message from ${myInfo.propertyName}`
      : ''

    const summaryMemo = disableMakePaymentMenuTab
      ? SUMMARY_MEMO_NO_OTP
      : DEFAULT_SUMMARY_MEMO

    return paymentSummaryMemo ? (
      <PaymentSummaryMemoStyled>
        {formattedPaymentSummaryMemo}
        {shouldShowReadMore ? (
          <ReadMore title={title} text={paymentSummaryMemo} />
        ) : null}
      </PaymentSummaryMemoStyled>
    ) : (
      summaryMemo
    )
  }

  return (
    <StyledGrid>
      <PageTitle>Your Payments Summary</PageTitle>
      <PageDescription>{renderPaymentSummaryMemo()}</PageDescription>
      <Grid
        container
        direction={(width === 'sm' || width === 'xs') && 'column-reverse'}
        justify="space-between"
        spacing={3}
        marginTop="10px">
        <Grid lg={5} md={12} xs={12} item>
          <MyInfoWrap direction="column" justifySpaceBetween>
            <MyInfo levelCreditEnabled={showLevelCreditAd()}>
              <MyPaymentsInfo
                levelCreditEnabled={showLevelCreditAd()}
                userInfo={myInfo}
                paymentState={paymentState}
              />
            </MyInfo>
            <ShowGridForMdDown md={12} item>
              <PaymentStateYellowBg />
              {renderPaymentState()}
            </ShowGridForMdDown>
            <AdWrapMdDown>{renderCreditReportingAd()}</AdWrapMdDown>
          </MyInfoWrap>
        </Grid>
        <ShowGridForMdUp xs={12} lg={7} item>
          <PaymentStateYellowBg />
          {renderPaymentState()}
        </ShowGridForMdUp>
      </Grid>
    </StyledGrid>
  )
}

export default withWidth()(withRouter(Summary))
