import { call, put, takeLatest , select} from 'redux-saga/effects'
import api from 'zego-shared/utils/api'
import { getToken } from '../../../authorization/selectors'
import {
    fetchLinkedAccountsAction,
    fetchLinkedAccountsError,
    fetchLinkedAccountsStart,
    fetchLinkedAccountsSuccess
} from '../actions'

export function* fetchLinkedAccounts() {
    try {
        const authToken = yield select(getToken)

        yield put(fetchLinkedAccountsStart())

        const response = yield call(api.getLinkedAccounts, authToken)
        yield put(fetchLinkedAccountsSuccess(response))
    } catch (error) {
        yield put(fetchLinkedAccountsError(error))
    }
}

export function* watchFetchLinkedAccounts() {
    yield takeLatest(fetchLinkedAccountsAction().type, fetchLinkedAccounts)
}

export default [watchFetchLinkedAccounts()]
