// @flow
import React from 'react'
import {
  Banner,
  ContentWrap,
  Title,
  SubTitle,
  BottomText,
  Hands,
  TitleHands,
  TitleSubTitle
} from './styles'
import { Bold } from '../../LevelCredit/styles'
import Flex from '@Common/Flex'

type Props = {
  variant: 'tall' | 'short'
}

const OptedInSucess = (props: Props) => {
  return (
    <Banner {...props}>
      <ContentWrap {...props}>
        <TitleHands>
          <TitleSubTitle direction="column" id="lcrSuccessBanner">
            <Flex justifyCenter>
              <Title id="lcrSuccessTitle">Success!</Title>
            </Flex>
            <Flex justifyCenter>
              <SubTitle id="lcrSuccessSubtxt">You’re all set up with credit reporting</SubTitle>
            </Flex>
          </TitleSubTitle>

          <Flex justifyCenter>
            <Hands {...props} />
          </Flex>
        </TitleHands>

        <Flex justifyCenter>
          <BottomText id="lcrSuccessBtmTxt">
            <Bold>LevelCredit</Bold> will report your next payment
          </BottomText>
        </Flex>
      </ContentWrap>
    </Banner>
  )
}

export default OptedInSucess
