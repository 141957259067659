// @flow
import React from 'react'
import { useDispatch } from 'react-redux'

import { ButtonWrapperStyled, HiddenStyled } from './styles'
import {
  PORTAL_ID,
  ADD_ELEMENT_EVENT,
  REMOVE_ELEMENT_EVENT
} from './MobileBtnsPortal'
import { setPosition } from '../../../store/moreNavPosition/actions'

type Props = { onMount: () => void }

const MobileButtons = ({ onMount }: Props) => {
  const ref = React.createRef()
  const dispatch = useDispatch()
  React.useEffect(() => {
    const { current } = ref

    if (current) {
      const setPos = () => dispatch(setPosition(current.clientHeight))
      const resetPos = () => dispatch(setPosition(0))

      current.addEventListener(ADD_ELEMENT_EVENT, setPos)
      current.addEventListener(REMOVE_ELEMENT_EVENT, resetPos)

      return () => {
        current.removeEventListener(ADD_ELEMENT_EVENT, setPos)
        current.removeEventListener(REMOVE_ELEMENT_EVENT, resetPos)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  React.useEffect(() => {
    onMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HiddenStyled mdUp implementation="css">
      <ButtonWrapperStyled id={PORTAL_ID} ref={ref} />
    </HiddenStyled>
  )
}

export default MobileButtons
