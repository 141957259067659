// @flow
import { methodTypes } from '../../../utils/view'
import { UNITED_STATES } from '@utils/view'

const bankAccount = {
  accountHolderName: '',
  bankName: '',
  routingNumber: '',
  accountNumber: '',
  confirmAccountNumber: '',
  type: ''
}

const creditCard = {
  name: '',
  address: '',
  address2: '',
  expiration: '',
  country: UNITED_STATES,
  state: '',
  city: '',
  zipCode: '',
  cvv: '',
  cardNumber: ''
}

export default {
  [methodTypes.bankAccount]: bankAccount,
  [methodTypes.card]: creditCard
}
