import { connect } from 'react-redux'
import { withFetchData } from 'zego-shared/views'
import ClientNotes from './ClientNotes'
import { getClientNotes } from "zego-shared/store/payments/clientNotes/selectors";
import { fetchClientNotes } from "zego-shared/store/payments/clientNotes/actions";
import { createIsFetchingSelector } from "zego-shared/store/isFetching/selectors";

const fetchingSelector = createIsFetchingSelector(['client'])

const mapStateToProps = state => ({
  isFetching: fetchingSelector(state),
  clientNotes: getClientNotes(state)
})

const mapDispatchToProps = dispatch => ({
  fetchData: () => dispatch(fetchClientNotes())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(ClientNotes))
