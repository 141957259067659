// @flow
import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import PlaidCard from '@Common/PlaidCard'
import ReauthAutoPayCard from '../ReauthAutoPayCard'
import type { Props as ReauthAutoPayCardType } from '../ReauthAutoPayCard'
import SVG from '../../../../SVG'
import { useStyles, StyledFormControlLabel, AcknowledgementCheckbox } from './styles'
import '@images/svg/warning_icon.svg'
import PlaidAcknowledgement from '../../../../Common/PlaidAcknowledgement'
import PlaidAcknowledgementDisclaimer from '../../../../Common/PlaidAcknowledgementDisclaimer/PlaidAcknowledgementDisclaimer'

export type Props = {
  autoPays: [ReauthAutoPayCardType],
  onClose: Function,
  onReauthorize: Function
}

const ReauthorizationCard = ({ autoPays, onClose, onReauthorize }: Props) => {
  const classes = useStyles()
  const [acknowledgement, setAcknowledgement] = useState(false)

  return autoPays.length ? (
    <PlaidCard
      classes={{ content: classes.content }}
      title={
        <React.Fragment>
          <SVG className={classes.warningIcon} name="warning_icon" />
          <Typography variant="h4" component="h1" className={classes.title}>
            Reauthorization Needed
          </Typography>
        </React.Fragment>
      }
      onClose={onClose}>
      <React.Fragment>
        <Typography>
          The following Auto Pays utilize accounts that were linked with Plaid
          Account Connect and will remain unavailable for payments until you
          have reauthorized.
        </Typography>
        <React.Fragment>
          <PlaidAcknowledgement acknowledgementType='multi' acknowledgement={acknowledgement} >
            <StyledFormControlLabel value="end"
            onChange={() => setAcknowledgement(!acknowledgement)}
            control={<AcknowledgementCheckbox type="checkbox" />}
            label={`I acknowledge that reauthorization requires choosing the same account as those listed below. 
            Choosing a different account will result in the replacement of the original account.`}
            labelPlacement="end"/>
          </PlaidAcknowledgement>
          <PlaidAcknowledgementDisclaimer acknowledgement={!acknowledgement} />
        </React.Fragment>
        {autoPays.map(
          ({ itemId, nextRun, total, account, isVariable }, index) => (
            <ReauthAutoPayCard
              key={`${index}-${itemId}`}
              itemId={itemId}
              index={index + 1}
              nextRun={nextRun}
              total={total}
              account={account}
              isVariable={isVariable}
              acknowledgement={!acknowledgement}
              onClick={onReauthorize}
            />
          )
        )}
      </React.Fragment>
    </PlaidCard>
  ) : null
}

export default ReauthorizationCard
