// @flow
import React from 'react'
import ReactDOM from 'react-dom'
import Button from '@Common/Button'
import type { ButtonProps } from '@Common/Button'
import { PayPalButtonContainer, PayPalButtonWrapper } from './styles'

type Props = {
  ...ButtonProps,
  createOrder: Function,
  onApprove: Function
}

const PayPalButton = ({
  createOrder,
  onApprove,
  children,
  ...buttonProps
}: Props) => {
  const empty = () => null
  const PayPalBtn =
    window.paypal?.Buttons.driver('react', { React, ReactDOM }) || empty

  return (
    <PayPalButtonContainer>
      <Button {...buttonProps} primary>
        {children || 'Authorize PayPal'}
      </Button>
      <PayPalButtonWrapper>
        <PayPalBtn createOrder={createOrder} onApprove={onApprove} />
      </PayPalButtonWrapper>
    </PayPalButtonContainer>
  )
}

export default PayPalButton
