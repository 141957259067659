// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import {
  PaymentInfoCardStyled as PaymentInfoCard,
  buttonWrapperStyledSsoStyles
} from '../../styles'

export const ConfirmPaymentStyled = styled.p`
  max-width: 800px;
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.xs : theme.custom.font.size.sm};
  padding: ${({ theme }) => ` 0 ${theme.space.s30}`};
  line-height: 16px;
  margin-bottom: ${({ theme }) => theme.space.s45};
  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    padding: ${({ theme }) =>
      theme.ux.isSSO ? `${theme.space.s10} 0` : `${theme.space.s30} 0`};
    margin-bottom: ${({ theme }) => theme.space.s10};
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin: 0 !important;
  }
`

export const ButtonWrapperStyled = styled(Flex)`
  & button {
    width: 225px;
    text-transform: capitalize;
  }
  & > button {
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    margin-left: 22px;
  }

  ${({ theme }) => theme.ux.isSSO && buttonWrapperStyledSsoStyles}
`

export const FlexXSRow = styled(Flex)`
  margin-bottom: ${({ bottomMargin, theme }) =>
    bottomMargin
      ? theme.ux.isSSO
        ? theme.space.s10
        : theme.space.s30
      : theme.space.s20};
  flex-direction: column;
  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${({ theme }) => theme.space.s20};

    & > * {
      flex: 1;
    }

    & p {
      text-align: right;
    }
  }
`

export const PaymentInfoCardStyled = styled(PaymentInfoCard)`
  height: 100%;
  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    .MuiCardContent-root {
      padding: 10px 20px;
      
      h4 {
        font-size: ${theme.custom.font.size.H6};
        margin-bottom: ${theme.space.s10}
      }
      p {
        font-size: ${theme.custom.font.size.sm};
      }
    }
  `}
`
