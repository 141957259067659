import flowRight from 'lodash/flowRight'
import withNormalizedFetch from '../../../helpers/reducers/withNormalizedFetch'
import withRefresh from '../../../helpers/reducers/withRefresh'
import {
  UNITS_FETCH_SECTION,
  UNIT_SECTION,
  SELECT_UNIT,
  CLEAR_UNIT,
  fetchUnitSuccess,
  fetchUnitInfoSuccess
} from '../actions'

const UNIT_REFRESH_KEY = 'selected'

const initialState = {
  byId: {},
  allIds: [],
  selected: undefined,
  unitPropertyUuid: undefined
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchUnitSuccess().type:
      return {
        ...state,
        selected: action.payload
      }
    case fetchUnitInfoSuccess().type:
      return {
        ...state,
        unitPropertyUuid: action.payload
      }
    case SELECT_UNIT:
      return {
        ...state,
        selected: state.byId[action.id]
      }
    case CLEAR_UNIT:
      return {
        ...state,
        selected: undefined
      }
    default:
      return state
  }
}

const withFetchAndRefresh = flowRight(
  withNormalizedFetch(UNITS_FETCH_SECTION),
  withRefresh(UNIT_SECTION, UNIT_REFRESH_KEY)
)

export default withFetchAndRefresh(reducer)
