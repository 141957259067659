// @flow

import styled from '@emotion/styled'

export const PayPalButtonContainer = styled('div')`
  position: relative;
  overflow: hidden;
`

export const PayPalButtonWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.01;
`
