import withFetch from '../../../helpers/reducers/withFetch'
import { LINKED_ACCOUNTS } from '../actions'

const reducer = (state = {}, result) => {
  const { type } = result
  switch (type) {
    default:
      return state
  }
}

export default withFetch(LINKED_ACCOUNTS)(reducer)
