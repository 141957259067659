import { connect } from 'react-redux'
import ErrorAlertModal from './ErrorAlertModal'
import { getIsAuth } from 'zego-shared/store/authorization/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import { blockIsFetching } from 'zego-shared/store/isFetching/actions'
import { hideTimeoutComp } from 'zego-shared/store/loader/actions'

const mapStateToProps = state => ({
  isAuth: getIsAuth(state)
})

const mapDispatchToProps = dispatch => {
  return {
    fetchData: () => dispatch(blockIsFetching()),
    hide: () => dispatch(hideTimeoutComp())
  }
}

export const ErrorAlertModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(ErrorAlertModal))
