// @flow
import React from 'react'
import Button from '@Common/Button'
import { withTheme } from 'emotion-theming'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Dinero from 'dinero.js'
import { amountToNumber } from 'zego-shared/utils/number'
import { Grid } from '@material-ui/core'
import type { Theme } from '../../../../../theme'

import {
  AmountStyled,
  FormGroupStyled,
  CurrencyTextInputStyled
} from './styles'
import {
  DescriptionStyled,
  CancelBtnStyled,
  AmountStyled as MaxAmountStyled
} from '../styles'

type Props = {
  value: Object,
  onChange: (string, ?boolean) => mixed,
  onCancel?: Function,
  theme: Theme
}

const MaxAmountEdit = ({ value, onChange, onCancel, theme }: Props) => {
  const changeLayout = useMediaQuery('(max-width:700px)')
  const ssoPaymentLayout = theme.ux.isSSO && changeLayout

  const { maxLimit = '' } = value
  const handleChange = event => {
    const { target } = event
    const maxLimit = amountToNumber(target.value)
    if (!Number.isNaN(maxLimit)) {
      onChange({
        ...value,
        maxLimit: Dinero({ amount: maxLimit }).toFormat('0.00')
      })
    }
  }

  return (
    <MaxAmountStyled>
      <Grid container>
        <Grid item xs={12} sm={ssoPaymentLayout ? 6 : 7}>
          <DescriptionStyled direction="column">
            <h5>Payment</h5>
            <p>
              Set a max amount for your auto pay. If an auto pay is more than
              this max your entire payment will not be paid.
            </p>
          </DescriptionStyled>
        </Grid>
        <Grid item xs={12} sm={ssoPaymentLayout ? 6 : 5}>
          <AmountStyled justifyCenter alignStart direction="column">
            <CancelBtnStyled justifyRight alignStart fullWidth>
              <Button
                id="cancelLink"
                link
                color={theme.palette.secondary.dark}
                disableRipple
                onClick={onCancel}>
                cancel
              </Button>
            </CancelBtnStyled>
            <FormGroupStyled label="Max Amount">
              <CurrencyTextInputStyled
                symbol="$"
                value={maxLimit}
                onChange={handleChange}
              />
            </FormGroupStyled>
          </AmountStyled>
        </Grid>
      </Grid>
    </MaxAmountStyled>
  )
}

export default withTheme(MaxAmountEdit)
