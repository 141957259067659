// @flow
import * as React from 'react'
import { Box, MenuItem, Typography } from '@material-ui/core'
import TextInput from '../TextInput'

type Option = { label: string, value: string }

type Props = {
  options: Option[],
  initialValue?: string,
  onChange?: (event: Object) => mixed,
  placeholder?: string
}

const Select = ({
  options,
  onChange,
  initialValue = '',
  placeholder,
  ...props
}: Props) => {
  const [value, setValue] = React.useState(initialValue)

  const handleChange = (event: Object) => {
    const { value } = event.target
    onChange && onChange(event)
    setValue(value)
  }

  return (
    <TextInput
      {...props}
      value={value}
      onChange={handleChange}
      variant="outlined"
      SelectProps={{ displayEmpty: !!placeholder }}
      select>
      {placeholder && (
        <MenuItem key="placeholder" value="" disabled>
          <Box color="common.grey" clone>
            <Typography>{placeholder}</Typography>
          </Box>
        </MenuItem>
      )}
      {options.map((option: Option) => {
        return (
          <MenuItem id={option.value.toLowerCase()} key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )
      })}
    </TextInput>
  )
}

export default Select
