import styled from '@emotion/styled'
import isPropValid from '@emotion/is-prop-valid'
import AppBar from '../AppBar'

export const Content = styled('div')`
  scroll-behavior: smooth;
  min-height: fit-content;
  width: 100vw;
`

export const ContentBg = styled('div', {
  shouldForwardProp: prop => isPropValid(prop)
})`
  ${({ leftImageSrc, rightImageSrc, fullImageSrc }) => {
    if (leftImageSrc && rightImageSrc) {
      return `background: url(${leftImageSrc}) left bottom fixed, url(${rightImageSrc}) right fixed;`
    } else if (leftImageSrc) {
      return `background: url(${leftImageSrc}) left bottom fixed;`
    } else if (rightImageSrc) {
      return `background: url(${rightImageSrc}) right top;`
    } else if (fullImageSrc) {
      return `background: url(${fullImageSrc}) center top;`
    }
  }};
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.3s all;
  height: fit-content;
  padding-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s20 : theme.space.s30};
  overflow-x: hidden;
`

export const Image = styled('span')`
  position: absolute;
  pointer-events: none;
`

export const AppBarStyled = styled(AppBar)`
  background-color: ${({ theme, isMyAccountPage }) =>
    !isMyAccountPage ? theme.palette.common.white : 'transparent'};
  transition: background-color ease-in 0.3s;
  box-shadow: ${({ showBoxShadow }) =>
    showBoxShadow && '0 2px 4px 0 rgba(0,0,0,0.25)'};
`

export const TermsLink = styled('a')`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.secondary.dark};
  cursor: pointer;
`
