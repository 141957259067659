// @flow
import React from 'react'
import PaymentMethodIcon from '../PaymentMethodIcon'
import type {
  PaymentOption,
  PaymentMethod as PaymentMethodType
} from '../types'
import {
  MethodDetails,
  MethodInfo,
  MethodTitle,
  MethodLineText,
  MethodListItem,
  Cheque,
  MethodTitleFee,
  MethodDetailsText
} from './styles'
import cheque from '@images/cheque.png'
import { useTheme } from '@material-ui/core'

const PaymentOptionDetails = ({ name, points, text, link, fee }) => {
  const theme = useTheme()
  const { isSSO } = theme.ux
  isSSO && points.splice(2)

  return (
    <MethodDetails>
      <MethodInfo>
        <MethodTitle>
          {name}
          <MethodTitleFee> {fee} fee</MethodTitleFee>
        </MethodTitle>
        {points.map((point, index) => (
          <MethodListItem key={index}>{point}</MethodListItem>
        ))}
      </MethodInfo>
      <MethodDetailsText>
        {text}
        {link && <a href={link}>Learn More</a>}
      </MethodDetailsText>
    </MethodDetails>
  )
}

const CardMethodDetails = details => {
  const { type, account, expiration, fee, accountHolder } = details
  const theme = useTheme()
  return (
    <MethodDetails>
      <MethodInfo>
        <MethodTitle>
          {type} {account}
        </MethodTitle>
        <MethodLineText>Expires: {expiration}</MethodLineText>
        <MethodLineText>Card Holder: {accountHolder}</MethodLineText>
        <MethodLineText>Fee: {fee || 'free'}</MethodLineText>
      </MethodInfo>
      <div>
        <PaymentMethodIcon
          {...details}
          existing
          height={theme.ux.isSSO ? 75 : 112}
        />
      </div>
    </MethodDetails>
  )
}

const BankAccountMethodDetails = details => {
  const { type, account, fee, accountHolder } = details
  const theme = useTheme()
  const { isSSO } = theme.ux
  return (
    <MethodDetails>
      <MethodInfo>
        <MethodTitle>
          {type} {account}
        </MethodTitle>
        <MethodLineText>Account Holder: {accountHolder}</MethodLineText>
        <MethodLineText>Fee: {fee || 'free'}</MethodLineText>
      </MethodInfo>
      <div>
        <Cheque src={cheque} alt="Check Icon" height={isSSO ? 75 : null} />
      </div>
    </MethodDetails>
  )
}

export const renderMethodDetails = (method: PaymentMethodType) => {
  switch (method?.typeName) {
    case 'CreditCard':
    case 'DebitCard':
      return <CardMethodDetails {...method} />

    case 'BankAccount':
      return <BankAccountMethodDetails {...method} />
    default:
      return null
  }
}

export const renderOptionDetails = (option: PaymentOption) => {
  const { typeName } = option ?? {}

  return typeName ? (
    <PaymentOptionDetails {...option} {...optionsData[typeName]} />
  ) : null
}

const optionsData: { [string]: Object } = {
  DebitCard: {
    points: [
      'Save Money with lower transaction fees',
      'Save time with easy payment setup',
      'Save hassle with faster processing'
    ],
    text: 'Debit cards have a much lower fee than credit cards with the same ease of use and setup. Additionally payments made with a debit card will come out of your account the same day, so you don’t have to worry about late fees, whereas with a credit card it may take a few days to process.'
  },
  CreditCard: {
    points: [
      'Save time with quick payment setup',
      'Added fraud protection from your card company'
    ],
    text: 'Credit cards are easy to use and set up as a payment method. Many card companies also offer benefits, rewards or points for using your card. Plus, using a credit card may help you build your credit score and offer greater protection against fraud.'
  },
  BankAccount: {
    points: [
      'Easily pay online through your bank account',
      'Connecting your bank account is quick & simple'
    ],
    text: 'Skip the check, don’t waste your time mailing or delivering your payment. Easily connect your bank account to make your payments here with Zego online.'
  },
  PayPal: {
    points: [
      'Enjoy the convenience of paying with PayPal',
      'Just sign in and pay',
      'Get added PayPal benefits, such as fraud protection'
    ],
    text: 'Have an account with PayPal? Simply choose this option and it will guide you through securely logging and paying through PayPal.'
  }
}
