// @flow
import { connect } from 'react-redux'
import get from 'lodash/get'
import { getExpressPayFee } from 'zego-shared/store/payments/methods/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { saveDetails } from 'zego-shared/store/payments/autopay/actions'
import {
  getAutoPayDetails,
  getAutoPayState,
  getPaymentAmountType,
  getIsAutopayDisabled
} from 'zego-shared/store/payments/autopay/selectors'
import {
  FAP,
  VAP,
  VAP_AND_FAP
} from 'zego-shared/store/payments/autopay/constants'
import { getReauthItemByZegoAccountId } from 'zego-shared/store/payments/plaid/selectors'

import { VariableAndFixedAmount } from '../Amount'
import AddAutoPay from './AddAutoPay'
import {
  paymentMethodEdit,
  autoPaySchedule,
  expressPay,
  payment,
  getFAPObject,
  getVAPObject
} from './config'
import type { BrowserRouter } from 'react-router-dom'
import type { AutoPayState } from 'zego-shared/store/payments/autopay/constants'


const fetchingSelector = createIsFetchingSelector(['autopaydata'])

export const getPaymentObject = (
  state?: AutoPayState,
  paymentFields: Object[],
  paymentAmountType?: AutoPayState
) => {
  if (state === VAP_AND_FAP) {
    return {
      ...getPaymentObject(paymentAmountType, paymentFields),
      Component: VariableAndFixedAmount
    }
  }
  switch (state) {
    case VAP:
      return getVAPObject()
    case FAP:
      return getFAPObject(paymentFields)
    default:
      return payment
  }
}

const addSteps = (expressFee?: string, flows: Object[]): Object[] => {
  const steps = expressFee ? [1, 2, 3, null] : [1, 2, null]
  return steps.map((step, index): Object => {
    return { ...flows[index], nextFlow: step }
  })
}

export const getFlows = (
  flowType: AutoPayState,
  autoPay: Object,
  paymentAmountType: AutoPayState,
  expressFee: string
) => {
  const paymentFields = get(autoPay, 'amount.fields', [])
  const balance = getPaymentObject(flowType, paymentFields, paymentAmountType)
  let flows = expressFee
    ? [balance, paymentMethodEdit, expressPay, autoPaySchedule]
    : [balance, paymentMethodEdit, autoPaySchedule]
  return addSteps(expressFee, flows)
}

export const mapStateToProps = (
  state: Object,
  { match: { url } }: BrowserRouter
) => {
  const autoPay = getAutoPayDetails(state)
  const autoPayState = getAutoPayState(state)
  const paymentAmountType = getPaymentAmountType(state)
  const paymentMethodId = get(autoPay, 'lastPaymentMethod.id')
  const typeName = get(autoPay, 'lastPaymentMethod.typeName')
  const expressFee = getExpressPayFee(state, typeName)

  return {
    autoPay,
    flows: getFlows(autoPayState, autoPay, paymentAmountType, expressFee),
    isFetching: fetchingSelector(state),
    startFlow: 0,
    expressFee,
    isAutopayDisabled: getIsAutopayDisabled(state),
    itemForReauth: getReauthItemByZegoAccountId(state, paymentMethodId)
  }
}

export const mapDispatchToProps = (dispatch: Object => void) => {
  return {
    saveDetails: (details: Object) => {
      dispatch(saveDetails(details))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAutoPay)
