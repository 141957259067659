import { connect } from 'react-redux'
import queryString from 'query-string'
import {
  loginEmail,
  loginSocial,
  loginToken,
  requestResetPass,
  resetPass,
  setGoogleLoginError,
  fetchResourcesAndRedirectAfterLogin
} from 'zego-shared/store/authorization/actions'
import {
  getIsAuth,
  hasLoginFailed
} from 'zego-shared/store/authorization/selectors'
import Login from './Login'

const mapStateToProps = (state, { location: { search } }) => {
  const { redirect_uri: redirectURI, sso: token, messageAfterLogin } = queryString.parse(search)
  return {
    isAuth: getIsAuth(state),
    error: state.snackbar.message,
    hasRedirect: redirectURI != null,
    loginFailed: hasLoginFailed(state),
    token,
    messageAfterLogin
  }
}

const mapDispatchToProps = {
  loginEmail,
  loginSocial,
  loginToken,
  requestResetPass,
  resetPass,
  fetchResourcesAndRedirectAfterLogin,
  handleSocialFailure: () => setGoogleLoginError()
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
