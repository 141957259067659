import { createFetchActions } from '../../../helpers/actions/creators'

export const PROPERTIES_FETCH_SECTION = 'PROPERTIES'
export const SELECT_PROPERTY = 'SELECT_PROPERTY'
export const UPDATE_SELECTED_PROPERTY = 'UPDATE_SELECTED_PROPERTY'
export const UPDATE_PROPERTY_RENDER_READY = 'UPDATE_PROPERTY_RENDER_READY'
export const CHANGE_PROPERTY = 'CHANGE_PROPERTY'
export const PROPERTY_INFO_SECTION = 'PROPERTY_INFO'
export const FETCH_PROPERTY_STAFF = 'FETCH_PROPERTY_STAFF'
export const PROPERTY_ADDRESS_SECTION = 'PROPERTY_ADDRESS_SECTION'

export const {
  fetchSuccessAction: fetchPropertiesSuccess
} = createFetchActions(PROPERTIES_FETCH_SECTION)

export const changeProperty = id => ({
  type: CHANGE_PROPERTY,
  id
})

export const selectProperty = id => ({
  type: SELECT_PROPERTY,
  id
})

export const updateSelectedProperty = data => ({
  type: UPDATE_SELECTED_PROPERTY,
  data
})

export const updatePropertyRenderReady = isReady => ({
  type: UPDATE_PROPERTY_RENDER_READY,
  isReady
})

export const {
  fetchAction: fetchPropertyInfoAction,
  fetchStartAction: fetchPropertyInfoStart,
  fetchSuccessAction: fetchPropertyInfoSuccess,
  fetchErrorAction: fetchPropertyInfoError
} = createFetchActions(PROPERTY_INFO_SECTION)

export const {
  fetchAction: fetchPropertyAddressAction,
  fetchStartAction: fetchPropertyAddressStart,
  fetchSuccessAction: fetchPropertyAddressSuccess,
  fetchErrorAction: fetchPropertyAddressError
} = createFetchActions(PROPERTY_ADDRESS_SECTION)

export const fetchPropertyInfo = propertyId =>
  fetchPropertyInfoAction({
    id: propertyId
  })

export const fetchPropertyStaffSuccess = staff => ({
  type: FETCH_PROPERTY_STAFF,
  staff
})
