// @flow

import React from 'react'
import TextField from '@Common/Form/TextInput'
import IconButton from '@material-ui/core/IconButton'
import Flex from '@Common/Flex'
import { CommonIcon } from '@icons'
import { createCase } from './api'
import { getSubject } from './utils'

import {
  Wrapper,
  Header,
  Content,
  EmbeddedServiceHelpButton,
  HelpButton,
  EmbeddedServiceIcon,
  ChatButton
} from './styles'

import type { User } from './types'

type Props = {
  offlineMinimizedText: string,
  orgId: string,
  recordTypeId: string,
  user: User,
  apiUrl: string
}

type State = {
  description: ?string,
  caseCreateView: boolean,
  caseSubmitted: boolean,
  caseSubmissionError: boolean,
  email: string,
  fullName: string
}

export default class OfflineChat extends React.Component<Props, State> {
  state = {
    description: null,
    caseCreateView: false,
    caseSubmitted: false,
    caseSubmissionError: false,
    email: this.props.user.email || '',
    fullName: this.props.user.fullName || ''
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.user !== this.props.user) {
      this.setState({
        email: this.props.user.email || '',
        fullName: this.props.user.fullName || ''
      })
    }
  }

  openCreateView = () => {
    this.setState({
      caseCreateView: true,
      description: null,
      caseSubmitted: false,
      caseSubmissionError: false
    })
  }

  closeCreateView = () => {
    this.setState({
      caseCreateView: false,
      description: null,
      caseSubmitted: false,
      caseSubmissionError: false
    })
  }

  createCase = () => {
    const { description, email, fullName } = this.state
    const { apiUrl, user, orgId, recordTypeId } = this.props
    const userForSubject = {
      fullName,
      email,
      ...user
    }
    const data = {
      name: userForSubject.fullName,
      email: userForSubject.email,
      subject: getSubject(userForSubject, false),
      orgid: orgId,
      recordType: recordTypeId,
      origin: 'Resident Web - Offline',
      description,
      [window._env_.REACT_APP_SF_ZCOMPANY_NAME || '']: user.companyName || '',
      [window._env_.REACT_APP_SF_ZCOMPANY_ID || '']: user.companyId || '',
      [window._env_.REACT_APP_SF_ZPROPERTY_NAME || '']: user.propertyName || '',
      [window._env_.REACT_APP_SF_ZPROPERTY_ID || '']: user.propertyId || '',
      [window._env_.REACT_APP_SF_ZUSER_ID || '']: user.userId || '',
      [window._env_.REACT_APP_SF_ZPM_ID || '']: user.companyPmsId || '',
      [window._env_.REACT_APP_SF_ZUNIT_NAME || '']: user.unitName || '',
      [window._env_.REACT_APP_SF_ZUNIT_ID || '']: user.unitId || ''
    }
    createCase(apiUrl, data)
      .then(() => {
        this.setState({ caseSubmitted: true })
      })
      .catch(() => {
        this.setState({
          caseSubmitted: true,
          caseSubmissionError: true
        })
      })
  }

  handleChange = async ({
    currentTarget: { id, value }
  }: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ [id]: value })
  }

  render() {
    const { offlineMinimizedText, user } = this.props
    const { caseCreateView, caseSubmitted, caseSubmissionError } = this.state

    // the code for !caseCreateView below is based on salesforce UI
    if (!caseCreateView) {
      return (
        <EmbeddedServiceHelpButton className="zegoEmbeddedServiceHelpButton">
          <HelpButton className="zegoHelpButton">
            <button
              id="zegoHelpButtonEnabled"
              className="zegoHelpButtonEnabled uiButton"
              onClick={this.openCreateView}>
              <EmbeddedServiceIcon
                className="embeddedServiceIcon"
                aria-hidden="true"
                data-icon=""
              />
              <span
                className="zegoHelpButtonLabel"
                id="zegoHelpButtonSpan"
                aria-live="polite"
                aria-atomic="true">
                <span className="assistiveText">Live chat:</span>
                <span className="message">{offlineMinimizedText}</span>
              </span>
            </button>
          </HelpButton>
        </EmbeddedServiceHelpButton>
      )
    }

    return (
      <Wrapper>
        <Header justifySpaceBetween alignCenter>
          <IconButton onClick={this.closeCreateView}>
            <CommonIcon name="resize" height="15px" width="15px" fill="#fff" />
          </IconButton>
          <div>Contact Us</div>
          <IconButton onClick={this.closeCreateView}>
            <CommonIcon
              name="close"
              height="12px"
              width="12px"
              fill="#fff"
              strokeWidth={3}
              stroke="#fff"
            />
          </IconButton>
        </Header>
        <Content>
          {caseSubmitted ? (
            <div>
              {caseSubmissionError
                ? 'Error submitting case'
                : 'Case submitted. We will respond soon.'}
              <div>
                <ChatButton primary onClick={this.closeCreateView}>
                  Close
                </ChatButton>
              </div>
            </div>
          ) : (
            <div>
              <div>
                <font color="#707070" size="12px">
                  All our agents are offline at the moment, please leave a
                  message describing the issue you are facing and we will get
                  back to you. Thanks.
                </font>
              </div>
              {!user.fullName && (
                <React.Fragment>
                  <Flex paddingTop="10px">Name</Flex>
                  <div>
                    <TextField
                      fullWidth
                      id="fullName"
                      onChange={this.handleChange}
                    />
                  </div>
                </React.Fragment>
              )}
              {!user.email && (
                <React.Fragment>
                  <Flex paddingTop="10px">Email</Flex>
                  <div>
                    <TextField
                      fullWidth
                      id="email"
                      onChange={this.handleChange}
                    />
                  </div>
                </React.Fragment>
              )}
              <Flex paddingTop="15px">Description</Flex>
              <div>
                <TextField
                  multiline
                  fullWidth
                  rows={user.userId ? '3' : '2'}
                  id="description"
                  onChange={this.handleChange}
                />
              </div>
              <ChatButton primary onClick={this.createCase}>
                Submit
              </ChatButton>
            </div>
          )}
        </Content>
      </Wrapper>
    )
  }
}
