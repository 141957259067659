import styled from '@emotion/styled'
import Card from '@Common/Card'
import Button from '@Common/Button'
import { Box } from '@material-ui/core'

export const StyledCard = styled(Card)`
  &.MuiPaper-elevation1 {
    box-shadow: ${props =>
      `${props.theme.custom.shadow}, 32px 25px 0 0 rgba(185,201,220,0.25)`};
    margin-right: 32px;
    border-radius: 25px;
`

export const StyledButton = styled(Button)`
  height: 35px;
  text-transform: unset;
`
export const HeaderStyled = styled(Box)`
  padding-bottom: ${({ theme }) => theme.space.s45};

  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    padding-bottom: 5%;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    padding-bottom: ${({ theme }) => theme.space.s20};
  }
`

export const BorderBox = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.light3};
`
export const PaymentDetailsStyled = styled(Box)`
  padding-left: ${({ theme }) => theme.space.s45};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    padding-left: 0;
  }
`
export const PageTitle = styled('div')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H4 : theme.custom.font.size.H3};
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    margin-bottom: 10px;
  }
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
  }
`

export const PStyled = styled.p`
  color: ${({ theme, light }) =>
    light ? theme.palette.common.grey : theme.palette.common.midnight};
  font-size: ${({ theme, H6 }) =>
    H6 ? theme.custom.font.size.H6 : theme.custom.font.size.H5};
  font-weight: ${({ theme, bolder }) =>
    bolder ? theme.custom.font.weight.bolder : theme.custom.font.weight.bold};
  line-height: 19px;
  margin: 0;

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    font-size: ${theme.custom.font.size.md};
    line-height: ${theme.space.s16};
  `}
`
