import {
  createFetchActions
} from '../../../helpers/actions/creators'
export const STATEMENTS_SECTION = 'STATEMENTSSECTION'

export const {
  fetchAction: fetchStatementsAction,
  fetchStartAction: fetchStatementsStart,
  fetchSuccessAction: fetchStatementsSuccess,
  fetchErrorAction: fetchStatementsError
} = createFetchActions(STATEMENTS_SECTION)

export const fetchStatements = residentId =>
  fetchStatementsAction({ residentId })
