// @flow
import React from 'react'
import Flex from '@Common/Flex'
import { useSelector } from 'react-redux'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import {
  Button,
  Ad,
  ContentWrap,
  Title,
  SubTitle,
  TitleUnderline
} from '../Common'

const StartReporting = () => {
  const residentId = useSelector(getUserId)
  const OptInUrl = `/payments/${residentId}/credit-reporting/signup`

  return (
    <Ad to={OptInUrl}>
      <ContentWrap>
        <Flex direction="column" id="startReportingTxt">
          <Title>Start <TitleUnderline /></Title>
          <SubTitle>Reporting Now!</SubTitle>
        </Flex>
        <Button id="signUpBtn">Sign Up</Button>
      </ContentWrap>
    </Ad>
  )
}

export default StartReporting
