// @flow
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, match, BrowserHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { formatDateMDYY } from 'zego-shared/utils/date'
import { makePlaidInsufficientFundsError } from 'zego-shared/store/payments/oneTimePayment/actions'
import Hidden from '@material-ui/core/Hidden'
import PaymentInfo from '../PaymentInfo'
import Button from '@Common/Button'
import { Instructions, SSOInstructions } from '../../Instructions'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import InsufficientBalanceModal from '../InsufficientBalanceModal'
import {
  MakePaymentStyled,
  ButtonWrapperStyled,
  PaymentReviewMemoStyled
} from './styles'
import { StyledMobileButtons } from '../../styles'
import { getPaymentSuccessRedirect } from 'zego-shared/store/overrides/selectors'

type Props = {
  fullName: string,
  makePayment: Function,
  summary: Object,
  match: match,
  history: BrowserHistory,
  isProcessing: boolean,
  isPayPal: boolean,
  isPlaidBankAccount: boolean,
  isDebitCard: boolean,
  paymentReviewMemo: string,
  paymentDisclaimerMemo: string,
  residentNsfFee: string | null,
  plaidInsufficientFundsError: boolean,
  hasBalanceChecksEnabled: boolean,
  isCreditCard: boolean,
  paymentNotes: String,
  paymentMethod: Object
}

const Summary = ({
  makePayment,
  match: { url },
  history: { push },
  summary,
  fullName = '[user name]',
  isProcessing,
  isPayPal,
  isPlaidBankAccount,
  isDebitCard,
  paymentReviewMemo,
  paymentDisclaimerMemo,
  residentNsfFee,
  plaidInsufficientFundsError,
  hasBalanceChecksEnabled,
  isCreditCard,
  paymentNotes,
  paymentMethod
}: Props) => {
  const baseUrl = url.split('/summary')[0]
  const theme = useTheme()
  const [displayErrorModal, setDisplayErrorModal] = useState(false)
  const dispatch = useDispatch()

  const payment_success_redirect = useSelector(getPaymentSuccessRedirect)
  const onPaymentSuccessful = () => {
    if (payment_success_redirect) {
      window.location.replace(payment_success_redirect)
    } else {
      push(`${baseUrl}/success`)
    }
  }

  useEffect(() => {
    setDisplayErrorModal(plaidInsufficientFundsError)
  }, [plaidInsufficientFundsError])

  if (!summary) {
    return <Redirect to={baseUrl} from={url} />
  }

  const ExitBtn = withAlertModal(({ showAlertModal }) => (
    <Button
      id="exitBtn"
      disabled={isProcessing}
      outline
      primary
      onClick={showAlertModal}>
      Go Back &amp; Edit
    </Button>
  ))

  const handleMakePaymentClick = () => {
    makePayment(onPaymentSuccessful)
  }

  const handleExitAndSaveClick = () => {
    dispatch(makePlaidInsufficientFundsError(false))
  }

  const goBack = () => push(baseUrl)
  const today = formatDateMDYY(new Date())

  const Btns = (
    <React.Fragment>
      {isPayPal ? (
        <ExitBtn
          alertTitle="Are you sure you wish to edit this payment?"
          onAlertClickYes={goBack}
          alertMessage="By making changes to this payment you will have to go through the steps for setting up PayPal again."
        />
      ) : (
        <Button
          onClick={goBack}
          disabled={isProcessing}
          outline
          primary
          id="editBtn">
          Go Back &amp; Edit
        </Button>
      )}

      <Button
        primary
        disabled={isProcessing}
        animate={isProcessing}
        onClick={handleMakePaymentClick}
        id="makePaymentBtn">
        {isProcessing ? 'Processing' : 'Make Payment'}
      </Button>
    </React.Fragment>
  )

  return (
    <MakePaymentStyled direction="column">
      <PaymentInfo paymentInfo={summary} paymentNotes={paymentNotes} />
      {paymentReviewMemo && (
        <PaymentReviewMemoStyled>{paymentReviewMemo}</PaymentReviewMemoStyled>
      )}
      <p id="otpDisclaimerTxt">
        {`I, ${fullName}, confirm that the payment information above is correct
        and authorize PayLease on ${today} to set up the payment with the
        details above.`}
      </p>
      <Hidden smDown>
        <ButtonWrapperStyled>{Btns}</ButtonWrapperStyled>
      </Hidden>
      {theme.ux.isSSO ? (
        <SSOInstructions
          paymentDisclaimerMemo={paymentDisclaimerMemo}
          isDebitCard={isDebitCard}
          nsfFee={residentNsfFee}
          shouldShowBalanceChecksDisclaimer={
            isPlaidBankAccount && hasBalanceChecksEnabled
          }
        />
      ) : (
        <Instructions
          paymentDisclaimerMemo={paymentDisclaimerMemo}
          isCreditCard={isCreditCard}
          isDebitCard={isDebitCard}
          nsfFee={residentNsfFee}
          shouldShowBalanceChecksDisclaimer={
            isPlaidBankAccount && hasBalanceChecksEnabled
          }
        />
      )}
      <MobileBtnsPortal>
        <StyledMobileButtons>{Btns}</StyledMobileButtons>
      </MobileBtnsPortal>

      {displayErrorModal && (
        <InsufficientBalanceModal
          handleExitAndSaveClick={handleExitAndSaveClick}
          totalAmount={summary.payment.total}
          lastFour={paymentMethod.lastFour}
        />
      )}
    </MakePaymentStyled>
  )
}

export default Summary
