export const getPropertyName = (propertyName, unitName) =>
  `${propertyName}${unitName ? ` | ${unitName}` : ''}`

export const getSelectedItem = (items = [], id) =>
  items.find(item => item.id === id)

export const getDropDownItems = (linkedAccounts = []) =>
  linkedAccounts.map(
    ({
      user_id: userId,
      unit_name: unitName,
      property_name: propertyName
    }) => ({
      id: userId,
      name: getPropertyName(propertyName, unitName)
    })
  )
