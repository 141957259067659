// @flow
import React from 'react'
import type { Node } from 'react'
import {useSelector} from 'react-redux'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { ThemeProvider } from 'emotion-theming'
import { getIsSsoPayments } from 'zego-shared/store/authorization/selectors'
import ZegoTheme from './ZegoTheme'
import StyleProvider from './StyleProvider'
import { addSsoPaymentsOverrides } from './SsoPaymentsTheme'

type Props = {
  children: Node
}

const Provider = ({ children }: Props) => {
  const isSsoPayments = useSelector(getIsSsoPayments)
  let theme = ZegoTheme;
  
  if (isSsoPayments) {
    theme = addSsoPaymentsOverrides(ZegoTheme)
  }

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <StyleProvider>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyleProvider>
    </MuiThemeProvider>
  )
}

export default Provider
