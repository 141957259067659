import flowRight from 'lodash/flowRight'
import withNormalizedFetch from '../../../helpers/reducers/withNormalizedFetch'
import {
  PROPERTIES_FETCH_SECTION,
  fetchPropertyInfoSuccess,
  fetchPropertyAddressSuccess,
  FETCH_PROPERTY_STAFF
} from '../actions'


const initialState = {
  propertyInfo: {},
  propertyStaff: [],
  propertyAddress: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchPropertyInfoSuccess().type:
      return {
        ...state,
        propertyInfo: action.payload
      }
    case fetchPropertyAddressSuccess().type:
      return {
        ...state,
        propertyAddress: action.payload
      }
    case FETCH_PROPERTY_STAFF:
      return {
        ...state,
        propertyStaff: action.staff
      }
    default:
      return state
  }
}

const withFetchAndRefresh = flowRight(
  withNormalizedFetch(PROPERTIES_FETCH_SECTION)
)

export default withFetchAndRefresh(reducer)
