// @flow
import * as React from 'react'
import Clear from '@material-ui/icons/Clear'
import Flex from '@Common/Flex'
import PaymentMethod from '../../PaymentMethod'
import { MoreModalCardStyled, CloseIconButton } from '../styles'
import { MethodsStyled, NameStyled, StyledListItem } from './styles'

type Props = {
  paymentTotal: string,
  onCloseBtnClick: Function,
  onMethodClick: Function,
  paymentMethods: Object[],
  paymentMethodProps: Object
}

const MorePaymentMethods = ({
  onMethodClick,
  onCloseBtnClick,
  paymentMethods,
  paymentMethodProps
}: Props) => {
  return (
    <MoreModalCardStyled
      CardContentProps={{
        classes: {
          root: 'card-content-root'
        }
      }}>
      <CloseIconButton id="closeIcon" onClick={onCloseBtnClick}>
        <Clear />
      </CloseIconButton>
      <p>Select one of your saved payment methods you would like to use.</p>
      <Flex
        alignCenter
        fullWidth
        justifyCenter
        style={{ overflow: 'auto', flex: 1 }}>
        <MethodsStyled id="paymentMethods" direction="column">
          {paymentMethods.map(({ name, id, account, ...method }, i) => {
            const props = paymentMethodProps[method.feeName]
            const addDivider = paymentMethods.length - 1 !== i
            return (
              <StyledListItem divider={addDivider} disableGutters key={id}>
                <PaymentMethod
                  {...method}
                  {...props}
                  onClick={() => onMethodClick(id)}
                  variant="list">
                  <NameStyled>
                    {name} {account}
                  </NameStyled>
                </PaymentMethod>
              </StyledListItem>
            )
          })}
        </MethodsStyled>
      </Flex>
    </MoreModalCardStyled>
  )
}

export default MorePaymentMethods
