// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import Flex from '@Common/Flex'
import { hasPurchasedLookback } from 'zego-shared/store/payments/creditReporting/selectors'
import { getDotOrCheckMark } from './MonthTracker'
import {
  GreyText,
  CheckMark,
  MonthLane,
  Month,
  Tracker,
  Year,
  Icon,
  DialogStyled,
  MonthsContainer,
  FootNote,
  CounterWrapper,
  Counter,
  CounterText
} from './styles'

type Props = {
  data: {
    transactions: Object,
    years: Array<number>,
    monthsReported: number,
    lookbackMonthsReported: number
  },
  show: boolean,
  onClose: Function
}

const MonthTrackerModal = ({ data, show, onClose }: Props) => {
  const hasLookback: boolean = true ?? useSelector(hasPurchasedLookback)
  const { transactions, years, monthsReported, lookbackMonthsReported } = data

  const content = (
    <>
      <CounterWrapper>
        <Counter>{monthsReported}</Counter>
        <CounterText>Payments Reported</CounterText>
        <Counter blue>{lookbackMonthsReported}</Counter>
        <CounterText>Lookback Payments</CounterText>
      </CounterWrapper>
      <MonthLane>
        <Month>Jan</Month>
        <Month>Feb</Month>
        <Month>Mar</Month>
        <Month>Apr</Month>
        <Month>May</Month>
        <Month>Jun</Month>
        <Month>Jul</Month>
        <Month>Aug</Month>
        <Month>Sep</Month>
        <Month>Oct</Month>
        <Month>Nov</Month>
        <Month>Dec</Month>
        <Year />
      </MonthLane>
      {years.map(year => {
        return (
          <Tracker>
            <MonthsContainer>
              {getDotOrCheckMark(transactions[year])}
            </MonthsContainer>
            <Year className="year">{year}</Year>
          </Tracker>
        )
      })}
      <FootNote>
        {hasLookback && (
          <Flex justifyRight>
            <GreyText marginRight="7px">lookback reported</GreyText>
            <CheckMark blue />
          </Flex>
        )}
        <Flex justifyRight>
          <GreyText marginRight="7px">month reported</GreyText>
          <CheckMark />
        </Flex>
      </FootNote>
    </>
  )

  return (
    <DialogStyled
      classes={{ paper: 'dialog-paper' }}
      open={show}
      onClose={onClose}>
      <Icon aria-label="close" onClick={onClose}>
        <CloseIcon fontSize="large" />
      </Icon>
      {content}
    </DialogStyled>
  )
}

export default MonthTrackerModal
