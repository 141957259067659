import { connect } from 'react-redux'
import Methods from './Methods'
import { withFetchData } from 'zego-shared/views'
import {
  fetchLastUsedPaymentMethod,
  fetchPaymentMethods
} from 'zego-shared/store/payments/methods/actions'
import { fetchPaymentOptions } from 'zego-shared/store/payments/options/actions'

const MethodsWithFetchData = withFetchData(Methods)

export const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: { residentId }
    }
  }
) => ({
  fetchData: () => {
    dispatch(fetchPaymentMethods(residentId, false))
    dispatch(fetchLastUsedPaymentMethod())
    dispatch(fetchPaymentOptions(false))
  }
})

export default connect(null, mapDispatchToProps)(MethodsWithFetchData)
