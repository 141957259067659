// @flow
import { find, get } from 'lodash'

export const COMPANY_MEMOS_TYPES = {
    REVIEW_AGREE: 'review_agree',
    REVIEW_TERMS: 'review_terms',
    HOME_DISCLAIMER: 'home_disclaimer'
}

export const getCompanyMemos = (state: Object) => {
    return get(state, 'company.memos', [])
}

export const getPaymentReviewMemo = (state: Object) => {
    const memos = getCompanyMemos(state)
    const paymentReviewMemo = find(memos, ['type', COMPANY_MEMOS_TYPES.REVIEW_AGREE]);
    return get(paymentReviewMemo, 'message', '')
}

export const getPaymentDisclaimerMemo = (state: Object) => {
    const memos = getCompanyMemos(state)
    const paymentDisclaimerMemo = find(memos, ['type', COMPANY_MEMOS_TYPES.REVIEW_TERMS]);
    return get(paymentDisclaimerMemo, 'message', '')
}

export const getPaymentSummaryMemo = (state: Object) => {
    const memos = getCompanyMemos(state)
    const paymentSummaryMemo = find(memos, ['type', COMPANY_MEMOS_TYPES.HOME_DISCLAIMER]);
    return get(paymentSummaryMemo, 'message', '')
}
