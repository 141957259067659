import jwtDecode from 'jwt-decode'
import get from 'lodash/get'

export const isValidToken = token => {
  if (token == null) {
    return false
  }
  const decodedToken = jwtDecode(token)
  if (typeof decodedToken.exp !== 'number') {
    return false
  }
  const currentTs = Math.floor(new Date() / 1000)
  if (!decodedToken.jti || decodedToken.exp < currentTs) {
    return false
  }
  return true
}

export const isAdminToken = token => {
  const decodedToken = jwtDecode(token)
  const isAdmin = get(decodedToken, ['act', 'sub'], '')

  return isAdmin ? true : false
}
