// @flow

import React, { forwardRef } from 'react'
import type { Node } from 'react'
import { Button } from './styles'

export type Props = {
  name?: string,
  children?: Node,
  primary?: boolean,
  primaryLight?: boolean,
  secondary?: boolean,
  left?: boolean,
  elong?: boolean,
  long?: boolean,
  thin?: boolean,
  outline?: boolean
}

const Btn = forwardRef<Props, Object>(({ name, children, ...props }, ref) => (
  <Button
    ref={ref}
    {...props}
    classes={{
      disabled: 'disabled'
    }}>
    {name}
    {children}
  </Button>
))

export default Btn
