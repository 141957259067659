// @flow
import React from 'react'
import List from '@material-ui/icons/List'

import { IconButtonStyled } from './styles'

type Props = {
  onClick?: Function,
  color: ?String
}

const ListIconButton = ({
  onClick,
  color
}: Props) => {
  return (
    <IconButtonStyled
      onClick={onClick}
      color={color}>
      <List />
    </IconButtonStyled>
  )
}

export default ListIconButton
