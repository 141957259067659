// @flow
import styled from '@emotion/styled'
import { CardStyled } from '../styles'
import Flex from '@Common/Flex'
import { convertHexToRGB } from '@utils/view'

export const H4Styled = styled.h4`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme, oneColumn }) =>
    theme.ux.isSSO
      ? oneColumn
        ? theme.custom.font.size.H5
        : theme.custom.font.size.H6
      : theme.custom.font.size.H5};
  line-height ${({ theme, oneColumn }) =>
    theme.ux.isSSO ? (oneColumn ? '22px' : '20px') : '22px'};
  margin: 0;
  margin-bottom: ${({ theme }) => (theme.ux.isSSO ? '3px' : '12px')};

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme, oneColumn }) =>
      oneColumn && theme.custom.font.size.sm};
    font-weight: ${({ theme, oneColumn }) =>
      oneColumn && theme.custom.font.weight.regular};
  }
`

export const PStyled = styled.p`
  color: ${({ theme }) => theme.palette.neutral.deep};
  font-size: ${({ theme, oneColumn }) =>
    theme.ux.isSSO
      ? oneColumn
        ? theme.custom.font.size.H6
        : theme.custom.font.size.md
      : theme.custom.font.size.H6};
  line-height ${({ theme, oneColumn }) =>
    theme.ux.isSSO ? (oneColumn ? '19px' : '17px') : '19px'};
  margin: ${({ theme }) => (theme.ux.isSSO ? '3px 0 0' : '0')};

  ${({ capitalize }) => capitalize && 'text-transform: capitalize'};

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme, oneColumn }) =>
      oneColumn && theme.custom.font.size.sm};
    font-weight: ${({ theme, oneColumn }) =>
      oneColumn && theme.custom.font.weight.regular};
  }
`

export const FlexStyled = styled(Flex)`
  ${({ bottomMargin, theme }) =>
    bottomMargin &&
    `
    margin-bottom: ${theme.ux.isSSO ? 15 : 30}px;
  `}
  ${({ alignTextRight }) => alignTextRight && 'text-align: right;'}
`

export const PaymentInfoCardStyled = styled(CardStyled)`
  padding-bottom: ${({ theme }) => `calc(${theme.space.s15} - 5px)`};

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    .MuiCardContent-root {
      padding: ${theme.space.s20} ${theme.space.s30}
    }
  `}

  ${({ innerPadding }) =>
    innerPadding &&
    `
    .MuiCardContent-root {
      padding: ${innerPadding}
    }
  `}
`

export const TotalStyled = styled(Flex)`
  border-top: 1px solid ${convertHexToRGB('979797', 0.25)};
  margin-top: ${({ theme, oneColumn }) =>
    theme.ux.isSSO ? (oneColumn ? '35px' : '30px') : '35px'};
  padding-top: ${({ theme }) => theme.space.s15};
  & p {
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
  }

  & p:last-of-type {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
  }
`

export const Line = styled('div')`
  border-top: 1px solid ${convertHexToRGB('979797', 0.25)};
  margin-top: ${({ theme }) => theme.space.s15};
  margin-bottom: ${({ theme }) => theme.space.s20};
`
