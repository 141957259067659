import { call, select, takeLatest, put } from 'redux-saga/effects'
import {
  fetchWorkOrderHistoryAction,
  fetchWorkOrderHistoryError,
  fetchWorkOrderHistoryStart,
  fetchWorkOrderHistorySuccess
} from '../actions'
import { getToken, getUnitId } from '../../../authorization/selectors'
import { workOrderApiUrl } from '../../../settings/selectors'
import { showSnackbar } from '../../../snackbar/actions'
import { failedToFetchWorkOrderHistory } from '../../../../utils/messages'
import api from '../../../../utils/api'

export function* fetchHistory() {
  try {
    const authToken = yield select(getToken)
    const unitID = yield select(getUnitId)
    const apiUrl = yield select(workOrderApiUrl)

    yield put(fetchWorkOrderHistoryStart())
    const response = yield call(api.getWorkOrderHistory, apiUrl, authToken, unitID.id)
    yield put(fetchWorkOrderHistorySuccess(response))
  } catch (error) {
    yield put(fetchWorkOrderHistoryError())
    yield put(showSnackbar(failedToFetchWorkOrderHistory(error), 'error'))
  }
}

export function* watchFetchHistory() {
  yield takeLatest(fetchWorkOrderHistoryAction().type, fetchHistory)
}

export default [watchFetchHistory()]
