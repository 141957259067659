// @flow

import get from 'lodash/get'

export const hasPayment = (state: Object) => {
  return get(state, ['settings', 'payment']) === true
}

export const nativePayerWithoutPayment = (state: Object) => {
  return (
    get(state, ['settings', 'native_payment']) === true && !hasPayment(state)
  )
}

export const nativePayer = (state: Object) =>
  get(state, ['settings', 'native_payment']) === true

export const hasNativePayment = (state: Object) => {
  return (
    hasPayment(state) && get(state, ['settings', 'native_payment']) === true
  )
}

export const hasWorkOrder = (state: Object) => {
  return get(state, ['settings', 'work_order']) === true
}

export const hasStandaloneWorkOrder = (state: Object) => {
  return get(state, ['settings', 'standalone_work_orders']) === true
}

export const workOrderApiUrl = (state: Object) => {
  return get(state, ['settings', 'work_order_api_url'])
}

export const isPaymentOnly = (state: Object) => {
  return get(state, ['settings', 'experience']) === 'payment_only'
}

export const isFullResident = (state: Object) => {
  return get(state, ['settings', 'experience']) === 'full_resident'
}

export const marketplaceEnabled = (state: Object) => {
  return get(state, ['settings', 'marketplace']) === true
}
