// @flow
import { amountToNumber } from 'zego-shared/utils/number'

export const disableBalanceEditMode = (
  paymentFields: Object[],
  optionalFields: Object[],
  balanceConfig: Object
) => {
  const editable = [...paymentFields, ...optionalFields].some(({ locked }) => !locked)

  return { ...balanceConfig, infoItem: { ...balanceConfig.infoItem, editable } }
}

export const buildPaymentFields = (paymentFields: Object[]): Object[] => {
  return paymentFields
    .filter(({ amount }) => amountToNumber(amount))
    .map(({ amount, currency, key }) => {
      return { key, money: { amount: amountToNumber(amount), currency } }
    })
}
