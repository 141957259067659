import React from 'react'
import { Route } from 'react-router-dom'
import History from '../../views/WorkOrders/History'
import Summary from '../../views/WorkOrders/Summary'
import WorkOrdersNew from '../../views/WorkOrders/WorkOrdersNew'

const WorkOrdersNavRoutes = () => (
  <React.Fragment>
    <Route
      exact
      path="/units/:unitId/work-orders/summary"
      component={Summary}
    />
    <Route exact path="/units/:unitId/work-orders/new" component={WorkOrdersNew} />
    <Route
      exact
      path="/units/:unitId/work-orders/history"
      component={History}
    />
  </React.Fragment>
)

export default WorkOrdersNavRoutes
