// @flow
import styled from '@emotion/styled'
import IconButton from '@material-ui/core/IconButton'
import MuiCardActions from '@material-ui/core/CardActions'
import CommonCard, { CardContent as CommonCardContent } from '@Common/Card'

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 32px;
  width: 32px;
  cursor: pointer;

  & svg {
    height: 20px;
    width: 20px;
  }
`

export const Card = styled(CommonCard)`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: 'space-between';
  padding-top: 3em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-bottom: 2em;

  ${({ theme }) => [theme.breakpoints.up('sm')]} {
    width: 360px;
    height: 610px;
  }

  & h1.MuiTypography-root {
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: bold;
  }

  & .MuiCardMedia-img {
    object-fit: none;
  }
`

export const CardContent = styled(CommonCardContent)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;

  &.MuiCardContent-root {
    padding: 0 1px;
    margin-bottom: 1em;

    &:last-child {
      padding: 0 1px;
    }
  }
`

export const CardActions = styled(MuiCardActions)`
  display: flex;
  justify-content: flex-end;

  &.MuiCardActions-root {
    padding: 0;
  }
`
