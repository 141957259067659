// @flow
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { Formik } from 'formik'
import { object, string, boolean } from 'yup'
import { succeedToLoginLink } from 'zego-shared/utils/messages'
import LoginLinkForm from './LoginLinkForm'
import { linkedAccountsHooks } from '../../../../../hooks'
import { TitleText } from './styles'
import { StyledCard } from '../styles'

const { useTokenToLinkProperty } = linkedAccountsHooks

const initialValues = {
  email: '',
  password: '',
  agreement: false
}

const validationSchema = object().shape({
  email: string()
    .trim()
    .email()
    .required('email is required'),
  password: string()
    .trim()
    .required('password is required'),
  agreement: boolean()
    .oneOf([true], 'Must accept terms and conditions')
    .required()
})

type Props = {
  account: {
    firstName: string,
    lastName: string,
    activationToken: string,
    propertyName: string,
    address1: string,
    email: string,
    zip: string,
    city: string
  }
}

const getLoginParams = (token, propertyName) => {
  return new URLSearchParams({
    sso: token,
    messageAfterLogin: succeedToLoginLink(propertyName)
  }).toString()
}

const LoginLinkCard = ({ account }: Props) => {
  const { propertyName, activationToken } = account
  const [{ error, token }, handleSubmit] = useTokenToLinkProperty(activationToken)

  if (token) {
    return <Redirect to={`/login?${getLoginParams(token, propertyName)}`} />
  }

  return (
    <StyledCard outline error={error}>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="stretch">
        <Grid item>
          <TitleText>
            Log into your existing account to link this new property.
          </TitleText>
        </Grid>
        <Grid item container direction="column">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {props => <LoginLinkForm {...props} />}
          </Formik>
        </Grid>
      </Grid>
    </StyledCard>
  )
}

export default LoginLinkCard
