// @flow
import React from 'react'
import { isLessThanOrEqualsToday, isSame } from 'zego-shared/utils/date'
import 'react-day-picker/lib/style.css'

import { DayPickerStyled } from './styles'

export const getDisabledModifier = (
  disableAll: boolean,
  allowedDays: [number, number]
) => (date: Date) => {
  const day = date.getDate()
  if (disableAll) {
    return disableAll
  }
  if (isLessThanOrEqualsToday(date)) {
    return true
  }
  const [startDay, endDay] = allowedDays

  if (startDay <= endDay) {
    return !(day >= startDay && day <= endDay)
  } else {
    if (day >= startDay || day <= endDay) {
      return false
    }
    return true
  }
}

export const getIsSelectedModifier = (selectedDay: Date) => (day: Date) => {
  if (selectedDay) {
    return isSame(selectedDay, day)
  }
  return false
}

export const getHandleDayClick = (onChange?: (Date, Object) => mixed) => (
  day: Date,
  modifiers: Object,
  event: Object
) => {
  if (modifiers.disabled) {
    return
  }
  onChange && onChange(day, event)
}

const WeekDay = ({ locale, weekday, className, localeUtils }) => {
  const weekDay = localeUtils.formatWeekdayLong(weekday)
  return (
    <div className={className} role="columnheader">
      <abbr title={weekDay}>{weekDay[0]}</abbr>
    </div>
  )
}

const Day = (day, { outside = false }) => {
  return <div>{outside ? '\u2022' : day.getDate()}</div>
}

type Props = {
  allowedDays: [number, number],
  selectedDay: Date,
  onChange?: (day: Date, event: Object) => mixed,
  disableAll: boolean,
  selectLastDay: boolean
}

const DayPicker = ({
  allowedDays = [0, 0],
  selectedDay,
  onChange,
  disableAll = true,
  selectLastDay,
  ...props
}: Props) => {
  const disabled = getDisabledModifier(disableAll, allowedDays)
  const isSelected = getIsSelectedModifier(selectedDay)
  const handleDayClick = getHandleDayClick(onChange)

  return (
    <DayPickerStyled
      {...props}
      weekdayElement={WeekDay}
      renderDay={Day}
      showOutsideDays
      enableOutsideDaysClick={false}
      modifiers={{ disabled, isSelected }}
      onDayClick={handleDayClick}
      disableNavBtns={disableAll}
      month={selectedDay ? selectedDay : undefined}
    />
  )
}
export default DayPicker
