import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import Button from '@Common/Button'
import { Fab, Box } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import yellowOverlay from '@images/autopay_summary.png'
import Card from '@Common/Card'

export const Pagination = styled(Box)`
  margin-top: 10px;
  & .scroll-menu-arrow--disabled svg {
    color: ${({ theme }) => theme.palette.common.grey};
  }
  & .menu-item-wrapper {
    outline: 0;
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    max-width: 170px;
    margin-top: 0;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    max-width: 150px;
  }
`

export const LeftIcon = styled(KeyboardArrowLeftIcon)`
  margin-right: 5px;
  color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
`

export const RightIcon = styled(KeyboardArrowRightIcon)`
  margin-left: 5px;
  color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
`

export const StyledFlex = styled(Flex)`
  height: 400px;
`

export const AddButton = styled(Fab)`
  display: block;
  margin-left: auto;
  margin-top: ${({ theme }) => (theme.ux.isSSO ? '-415px' : theme.space.s30)};
  margin-bottom: ${({ theme }) => theme.space.s60};
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 60px;
  width: 60px;
  ${({ theme }) => [theme.breakpoints.up('lg')]} {
    margin-top: ${({ theme }) => (theme.ux.isSSO ? '-415px' : theme.space.s80)};
    height: ${({ theme }) => (theme.ux.isSSO ? '60px' : '75px')};
    width: ${({ theme }) => (theme.ux.isSSO ? '60px' : '75px')};
  }
  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const PageTitle = styled('div')`
  line-height: 32px;
  height: 44px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H3};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  margin-bottom: ${({ theme }) => theme.space.s50};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    margin-bottom: ${({ theme }) => theme.space.s25};
    font-size: ${({ theme }) => theme.custom.font.size.H4};
  }

  ${({ theme }) => theme.ux.isSSO && `margin-bottom: ${theme.space.s30};`}
`

export const AutoPayInfo = styled(Flex)`
  height: ${({ isLevelCreditEnabled }) =>
    isLevelCreditEnabled ? '207px' : '100%'};
  width: 100%;
  border-radius: 5px;
  background-color: rgba(237, 237, 237, 0.75);
  color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) =>
    `${theme.space.s33} ${theme.space.s27} ${theme.space.s35}`};
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 67px;
    flex-direction: row;
    padding: ${({ theme }) =>
      `${theme.space.s12} ${theme.space.s29} ${theme.space.s13} ${theme.space.s21}`};
  }

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    padding: ${({ theme }) =>
      `${theme.space.s12} ${theme.space.s18} ${theme.space.s13}`};
  }
`

export const NextRunDate = styled.div`
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    flex-direction: row;
  }
`

export const AutoPayBox = styled(Flex)`
  box-sizing: border-box;
  height: 80px;
  width: 340px;
  border: ${({ dashed }) =>
    `2px ${dashed ? 'dashed' : 'solid'} rgba(185, 201, 220, 0.75)`};
  border-radius: 5px;
`

export const AutoPayCount = styled(Flex)`
  width: 100%;
  margin-top: ${({ theme }) => theme.space.s30};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: 0;
    text-align: right;
  }
`

export const NextPayment = styled.div`
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  font-size: ${({ theme }) => theme.custom.font.size.H3};
  color: ${({ theme }) => theme.palette.common.midnight};
  line-height: 36px;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    height: 24px;
    line-height: 24px;
  }
`

export const NextAutoPayText = styled.div`
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  color: ${({ theme }) => theme.palette.common.grey};
  line-height: 22px;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    height: 19px;
    line-height: 19px;
  }
`

export const PaymentItemLane = styled(Flex)`
  padding: 0px 6px;
`

export const TotalItemLane = styled(PaymentItemLane)`
  margin-top: ${({ theme }) => theme.space.s20};
  ${({ theme }) => [theme.breakpoints.up('xl')]} {
    margin-top: ${({ theme }) =>
      theme.ux.isSSO ? theme.space.s20 : theme.space.s45};
  }
`

export const Item = styled('span')`
  color: ${({ theme, color }) =>
    color ? theme.palette.common[color] : theme.palette.common.white};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  font-weight: ${({ theme, light, bold }) =>
    light
      ? theme.custom.font.weight.light
      : bold
      ? theme.custom.font.weight.bolder
      : theme.custom.font.weight.regular};
  line-height: ${({ lineHeight }) => lineHeight && `${lineHeight}px`};
  text-decoration: ${({ lineThrough }) => lineThrough && 'line-through'};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    ${({ theme, autoPayCount }) =>
      autoPayCount &&
      `width: 100%;
      text-align: right;
      font-size: ${theme.custom.font.size.sm};
      `};
  }
`

export const PaymentDetailsCardTitle = styled('div')`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  line-height: 29px;

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    margin-top: ${({ theme }) => theme.space.s5};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
  }
`

export const LinkItem = styled('span')`
  color: ${({ theme }) => theme.palette.palette.primary.main}
  text-decoration: underline;
`

export const StyledButton = styled(Button)`
  height: 35px;
  margin: 0;
  width: ${({ width }) => width};
`

export const AutoPayCircle = styled(Flex)`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${({ theme, active }) =>
    active ? theme.palette.secondary.main : 'transparent'};
  border: ${({ theme }) => `2px solid ${theme.palette.secondary.main}`};
  margin-right: ${({ addMargin }) => addMargin && '12px'};
  cursor: pointer;

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    height: 25px;
    width: 25px;
  }
`

export const Count = styled.div`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  height: 100%;

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }
`

export const NoAutoPayWrap = styled(Flex)`
  height: ${({ theme }) => (theme.ux.isSSO ? 'auto' : '409px')};
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    height: 250px;
  }
`

export const NoAutoPayImage = styled('div')`
  width: 390px;
  height: 390px;
  border-radius: 50%;
  margin-right: 55px;
  background-color: ${({ theme }) => theme.palette.neutral.light};
  position: relative;

  & img {
    width: 410px;
    height: 350px;
  }
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    width: 250px;
    height: 250px;
    margin-right: 0;
    & img {
      width: 260px;
      height: 210px;
    }
  }
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s30};
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    width: 243px;
    height: 243px;
    margin-right: 0;
    & img {
      width: 105%;
      height: 100%;
    }
  `}
`

export const AddIconWrap = styled('div')`
  height: 60px;
  width: 60px;
  position: absolute;
  margin-top: 97px;
  margin-left: 308px;
  margin-top: 30px;
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    margin-left: 200px;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    height: 40px;
    width: 40px;
    margin: 0;
    right: 15px;
    top: 18px;
  `}
`

export const MakePaymentsText = styled('div')`
  height: 64px;
  width: 512px;
  margin-right: ${({ theme }) => theme.space.s25};
  margin-bottom: 166px;
  text-align: center;
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    font-size: ${theme.custom.font.size.H6};
    margin: auto 150px;
  `}
`

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
`

export const StyledCard = styled(Card)`
  padding: ${({ theme }) =>
    `${theme.space.s47} ${theme.space.s35} ${theme.space.s50}`};

  &:last-child {
    padding: 0;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    height: 330px;    
    & .MuiCardContent-root {
      padding: ${theme.space.s20} ${theme.space.s30}
    }
  `};
`

export const ShowForMdUp = styled(Flex)`
  display: block;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: none;
  }
`

export const ShowForMdDown = styled(Flex)`
  display: none;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: block;
    margin-top: ${({ theme }) => theme.space.s20};
  }
`

export const StyledCardGrid = styled(Grid)`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: -33px;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `    
    padding-bottom: ${theme.space.s12}; 

    ${[theme.breakpoints.down('sm')]} {
      margin-top: 0px;
    }
  `};
`

export const SummaryOverlay = styled('div')`
  background-image: ${({ theme }) =>
    !theme.ux.isSSO && `url(${yellowOverlay})`};
  width: 181px;
  height: 21px;
  position: absolute;
  margin-top: ${({ theme }) => theme.space.s7};
  right: 0;
  z-index: 0;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s73};
    margin-right: -2px;
  }
`

export const StyledBox = styled(Box)`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    max-width: 445px;
  }
`

export const TotalAmount = styled(Item)`
  font-size: ${({ theme }) => theme.custom.font.size.H2};
  color: ${({ theme }) => theme.palette.common.midnight};
  line-height: 25px;
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H3};
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    @media (max-width: 799px) {
      font-size: ${theme.custom.font.size.H3};
    }
  `};
`

export const TotalText = styled(Item)`
  font-size: ${({ theme }) => theme.custom.font.size.H3};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  color: ${({ theme }) => theme.palette.common.midnight};

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
  }
`

export const WillRunOn = styled(Item)`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  color: ${({ theme }) => theme.palette.common.grey};
  padding-bottom: ${({ theme }) => theme.ux.isSSO && '15px'};

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
  }
`
