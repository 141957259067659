// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import { convertHexToRGB } from '@utils/view'

export const MethodStyled = styled(Flex)`
  height: 84px;
  border-radius: 15px;
  background-color: ${({ isBox, theme }) =>
    isBox ? theme.palette.neutral.main + '26' : 'transparent'};
  cursor: pointer;

  ${({ empty }) => empty && `background-color: theme.palette.neutral.main;`}
  ${({ isBox }) =>
    isBox &&
    `
    max-width: 143px;
    margin-right: 20px;
    flex: auto;
   `}

  ${({ theme }) => theme.ux.isSSO && `margin: 0 10px;`}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    ${({ isBox, theme }) =>
      isBox &&
      `
      max-width: none;
      height: 50px;
      margin: 0 0 ${theme.space.s15};
      padding: 0 ${theme.space.s20};
      border-radius: 5px;
      & i {
        margin: 0 ${theme.space.s5};
      }
    `}

    ${({ empty, theme }) =>
      empty &&
      `
     justify-content:center;
     background-color: transparent;
     border: 2px solid  rgba(185,201,220,0.25)
    `}
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    ${({ isBox, theme }) =>
      isBox &&
      `
      padding: 0 ${theme.space.s15}
    `}
  }

  & span {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme }) => theme.custom.font.size.xs};
    line-height: 12px;
    text-align: center;
    text-transform: capitalize;
  }

  & span:last-child {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme, isBox }) =>
      isBox ? theme.custom.font.size.sm : theme.custom.font.size.H6};
    line-height: 14px;
    text-align: center;
  }

  & svg {
    margin: 4px 0;
    ${({ empty }) => empty && `color: rgba(185,201,220,0.75);`}
  }
`

export const PaymentOptionsStyled = styled(Flex)`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    flex: 1;
    flex-wrap: wrap;
    overflow: auto;
  }
  ${({ theme }) => theme.ux.isSSO && `justify-content: space-around;`}
`

export const HeaderStyled = styled(Flex)`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-bottom: ${({ theme }) => theme.space.s30};
  }

  & h5 {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    line-height: 22px;
    text-transform: capitalize;
    margin: 0;
  }
`

export const ModalBackDropStyled = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) =>
    convertHexToRGB(theme.palette.common.white, 0.85)};
  position: absolute;
`
