import { call, select, takeLatest, put } from 'redux-saga/effects'

import {
  fetchUserResourceAction,
  fetchUserResourceError,
  fetchUserResourceStart,
  fetchUserResourceSuccess
} from '../actions'
import api from '../../../utils/api'
import { getToken } from '../../authorization/selectors'
import { showSnackbar } from '../../snackbar/actions'
import { genericErrorMsg } from '../../../utils/messages'

export function* fetchUserResource({ residentId }) {
  try {
    const authToken = yield select(getToken)
    yield put(fetchUserResourceStart())
    const response = yield call(api.getUserResource, authToken, residentId)
    yield put(fetchUserResourceSuccess(response))
  } catch (error) {
    yield put(fetchUserResourceError(error))
    yield put(showSnackbar(genericErrorMsg, 'error'))
  }
}

export function* watchFetchUserResource() {
  yield takeLatest(fetchUserResourceAction().type, fetchUserResource)
}

export default [watchFetchUserResource()]
