// @flow
import React, { useState, useEffect } from 'react'
import { Redirect, match, BrowserHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import { getAutoPaySummary } from 'zego-shared/store/payments/autopay/selectors'
import { createAP } from 'zego-shared/store/payments/autopay/actions'
import { formatDateMMDDYY } from 'zego-shared/utils/date'
import { withRouter } from 'react-router'
import Button from '@Common/Button'
import Flex from '@Common/Flex'
import { Grid, Hidden } from '@material-ui/core'

import { Instructions, SSOInstructions } from '../../Instructions'
import {
  ButtonWrapperStyled,
  ConfirmPaymentStyled,
  PaymentInfoCardStyled,
  FlexXSRow
} from './styles'
import { PStyled, H4Styled, FlexStyled, TotalStyled } from '../../styles'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import { getPaymentSuccessRedirect } from 'zego-shared/store/overrides/selectors'
import { getUserId } from 'zego-shared/store/authorization/selectors'

type ResidentInfo = {
  recipient: {
    propertyName: string,
    address: string,
    companyName: string
  },
  accountInfo: {
    fullName: string,
    creditCardName: string
  }
}

type PaymentInfo = {
  nextPayDate: string,
  autoPayAmt: string,
  methodFee: {
    value: string,
    label: string
  },
  total: string
}

type ScheduleInfo = {
  startDate: string,
  endDate: string,
  frequency: string,
  maxLimit: ?string
}

type AutoPaySummary = {
  residentInfo: ResidentInfo,
  paymentInfo: PaymentInfo,
  scheduleInfo: ScheduleInfo
}

type Props = {
  match: match,
  history: BrowserHistory,
  onBackClick: Function,
  isError: boolean,
  isDebitCard: boolean,
  residentNsfFee: null | string,
  isPlaidBankAccount: boolean,
  isCreditCard: boolean,
  hasBalanceChecksEnabled: boolean
}

const TotalPaymentItem = ({ label, value, elementId }) => {
  return (
    <FlexStyled justifySpaceBetween>
      <PStyled>{label}</PStyled>
      <PStyled id={elementId} dark>
        {value}
      </PStyled>
    </FlexStyled>
  )
}

const ScheduleItem = ({ label, value, elementId }) => {
  return (
    <FlexXSRow marginBottom>
      <H4Styled style={{ margin: '0' }}>{label}</H4Styled>
      <PStyled id={elementId}>{value}</PStyled>
    </FlexXSRow>
  )
}

const ScheduleCard = (props: ScheduleInfo) => {
  const { startDate, endDate, frequency, maxLimit } = props
  return (
    <PaymentInfoCardStyled>
      <ScheduleItem
        elementId="apStartDate"
        label="Autopay Start"
        value={startDate}
      />
      <ScheduleItem elementId="apEndDate" label="Autopay End" value={endDate} />
      <ScheduleItem
        elementId="apFrequency"
        label="Frequency"
        value={frequency}
      />
      <MaxAmountItem maxLimit={maxLimit} />
    </PaymentInfoCardStyled>
  )
}

const ResidentInfoCard = (props: ResidentInfo) => {
  const { recipient, accountInfo } = props
  return (
    <PaymentInfoCardStyled>
      <FlexXSRow bottomMargin>
        <H4Styled>Recipient</H4Styled>
        <div>
          <PStyled id="propName">{recipient.propertyName}</PStyled>
          <PStyled id="propAddress">{recipient.address}</PStyled>
          <PStyled id="companyName">{recipient.companyName}</PStyled>
        </div>
      </FlexXSRow>
      <FlexXSRow>
        <H4Styled>Account Info</H4Styled>
        <div>
          <PStyled id="residentName">{accountInfo.fullName}</PStyled>
          <PStyled id="cardName">{accountInfo.creditCardName}</PStyled>
        </div>
      </FlexXSRow>
    </PaymentInfoCardStyled>
  )
}

const MaxAmountItem = ({ maxLimit }) => {
  if (undefined !== maxLimit && maxLimit > 0.0) {
    return (
      <ScheduleItem
        label="Max Amount"
        value={`$${maxLimit}`}
        elementId="apMaxAmount"
      />
    )
  }

  return <></>
}

const TotalInfoCard = (props: PaymentInfo) => {
  const { nextPayDate, total, autoPayAmt, methodFee } = props
  return (
    <PaymentInfoCardStyled>
      <FlexStyled justifySpaceBetween>
        <H4Styled>Next Payment</H4Styled>
        <PStyled id="nextPayDate">{nextPayDate}</PStyled>
      </FlexStyled>
      <div>
        <TotalPaymentItem
          label="Auto Pay"
          value={autoPayAmt}
          elementId="apAmount"
        />
        <TotalPaymentItem
          label={methodFee.label}
          value={methodFee.value}
          elementId="methodFee"
        />
      </div>
      <TotalStyled justifySpaceBetween>
        <PStyled dark>Total</PStyled>
        <PStyled id="apTotal" dark>
          {total}
        </PStyled>
      </TotalStyled>
    </PaymentInfoCardStyled>
  )
}

const Summary = (props: Props) => {
  const {
    match: { url },
    history,
    onBackClick,
    isError,
    isDebitCard,
    residentNsfFee,
    isCreditCard,
    isPlaidBankAccount,
    hasBalanceChecksEnabled
  } = props

  const isSsoPayments = useTheme().ux.isSSO
  const summary = useSelector(getAutoPaySummary)
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(false)

  const baseUrl = url.split('/summary')[0]
  const clickBackFn = () => {
    onBackClick()
    history.push(baseUrl)
  }

  const paymentSuccessRedirect = useSelector(getPaymentSuccessRedirect)
  const residentId = useSelector(getUserId)
  const onSuccess = () => {
    if (paymentSuccessRedirect) {
      window.location.replace(paymentSuccessRedirect);
    } else {
      history.push(`/payments/${residentId}/auto-pay`)
    }
  }

  const createAutoPay = () => {
    setIsSaving(true)
    dispatch(createAP(onSuccess))
  }

  useEffect(() => {
    isError && setIsSaving(false)
  }, [isError, setIsSaving])

  const NavBtns = ({ idSuffix = '' }) => (
    <>
      <Button
        id={`editBtn${idSuffix}`}
        outline
        primary
        disabled={isSaving}
        onClick={clickBackFn}>
        Back &amp; Edit
      </Button>
      <Button
        id={`saveApBtn${idSuffix}`}
        primary
        disabled={isSaving}
        animate={isSaving}
        onClick={createAutoPay}>
        {isSaving ? 'Saving' : 'Save Auto Pay'}
      </Button>
    </>
  )

  if (!summary || Object.keys(summary).length < 1) {
    return <Redirect to={baseUrl} from={url} />
  }

  const { residentInfo, paymentInfo, scheduleInfo }: AutoPaySummary = summary
  const { accountInfo } = residentInfo
  return (
    <Flex direction="column">
      <Grid container spacing={isSsoPayments ? 2 : 6}>
        <Grid item xs={12} sm={5} lg={isSsoPayments ? 3 : 5} xl={3}>
          <ScheduleCard {...scheduleInfo} />
        </Grid>
        <Grid item xs={12} sm={7} lg={isSsoPayments ? 3 : 7} xl={4}>
          <ResidentInfoCard {...residentInfo} />
        </Grid>
        <Grid item xs={12} sm={12} lg={isSsoPayments ? 6 : 12} xl={5}>
          <TotalInfoCard {...paymentInfo} />
        </Grid>
      </Grid>
      <ConfirmPaymentStyled id="paymentConfMessage">
        I, {accountInfo.fullName}, confirm that the payment information below is
        correct and authorize PayLease on {formatDateMMDDYY(new Date())} to set
        up the autopay (automatic recurring payment) with the details below. I
        understand I am responsible for canceling my autopay when I am moving
        from my property.
      </ConfirmPaymentStyled>
      <Hidden smDown>
        <ButtonWrapperStyled justifyRight alignCenter>
          <NavBtns />
        </ButtonWrapperStyled>
      </Hidden>
      {isSsoPayments ? (
        <SSOInstructions
          isDebitCard={isDebitCard}
          nsfFee={residentNsfFee}
          shouldShowBalanceChecksDisclaimer={
            isPlaidBankAccount && hasBalanceChecksEnabled
          }
        />
      ) : (
        <Instructions
          isDebitCard={isDebitCard}
          isCreditCard={isCreditCard}
          nsfFee={residentNsfFee}
          shouldShowBalanceChecksDisclaimer={
            isPlaidBankAccount && hasBalanceChecksEnabled
          }
        />
      )}
      <MobileBtnsPortal>
        <NavBtns idSuffix="Mobile" />
      </MobileBtnsPortal>
    </Flex>
  )
}

export default withRouter(Summary)
