// @flow
import React from 'react'
import { NewUserNoBalanceStyled, StyledButton } from './styles'
import Button from '@Common/Button'
import { CommonIcon } from '@icons'
import { useTheme } from '@material-ui/core'
type Props = {
  unlockedZero?: boolean,
  disableMakePaymentMenuTab: boolean,
  onClickPayNow?: Function,
  onClickSetupAutoPay?: Function,
  autopayCount: Number
}

const NewUserNoBalance = ({ unlockedZero, disableMakePaymentMenuTab, onClickPayNow, onClickSetupAutoPay, autopayCount }: Props) => {
  const isSSO = useTheme().ux.isSSO

  const getHeader = () => {
    return unlockedZero
      ? 'Let’s make a payment!'
      : 'You’re currently up to date with all your payments!'
  }

  const getButton = () => {
    if (unlockedZero) {
      const btnText = !disableMakePaymentMenuTab
        ? 'Pay Now'
        : `${autopayCount ? 'View' : 'Setup'} Auto Pay Now`

      const btnMethod = !disableMakePaymentMenuTab
        ? onClickPayNow
        : onClickSetupAutoPay

      return (
        <StyledButton>
          <Button className="payBtn" fullWidth primary onClick={btnMethod}>
            {btnText}
          </Button>
        </StyledButton>
      )
    }
  }

  return (
    <NewUserNoBalanceStyled
      noSm
      CardContentProps={{
        classes: {
          root: 'card-content-root'
        }
      }}>
      <h4>{getHeader()}</h4>
      <CommonIcon name="hands" width={isSSO ? 200 : 289} height="258px" />
      {!unlockedZero && (
        <p>
          Check back closer to your next payment due date or contact your
          property for more information!
        </p>
      )}
      {unlockedZero && !autopayCount && (
        <p>Set up a recurring Auto Pay to make your payments easier.</p>
      )}
      {getButton()}
    </NewUserNoBalanceStyled>
  )
}

export default NewUserNoBalance
