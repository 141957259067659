import React from 'react'
import Button from '@Common/Button'
import { DisclaimerStyled } from './styles'

export const DisclaimerText = ({ onClick }) => {
  return (
    <DisclaimerStyled>
      By clicking ADD, you grant ZEGO permission to store your card credentials.{' '}
      <Button id="readMoreLink" onClick={onClick}>
        Read More +
      </Button>
    </DisclaimerStyled>
  )
}

export const DisclaimerTextFull = ({ termsLink, children = null }) => {
  return (
    <DisclaimerStyled>
      By clicking ADD, you grant ZEGO permission to store your card credentials.
      By adding this payment account, you grant ZEGO permission to store your
      card credentials. The stored card credentials will only be used when you
      initiate a payment or have a recurring payment scheduled. You may delete
      this payment account at any time by accessing your ZEGO account and
      navigating to the 'Payment Methods' tab. Any changes to these terms will
      be reflected in ZEGO's general{' '}
      <a href={termsLink} target="_blank" rel="noopener noreferrer">
        terms and conditions.
      </a>
      {children}
    </DisclaimerStyled>
  )
}
