// @flow

import React from 'react'
import { StyledLink } from './styles'

type Props = {
  color: ?string
}

const Link = ({ color, ...props }: Props) => (
  <StyledLink {...props} color={color} />
)

export default Link
