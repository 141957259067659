import { createFetchActions, createUpdateActions } from '../../../helpers/actions/creators'

export const PLAID_LINK_TOKEN = 'PLAID_LINK_TOKEN'
const CREATE_PLAID_BANK_ACCOUNT_ACTION = 'CREATE_PLAID_BANK_ACCOUNT_ACTION'
export const PLAID_ITEM = 'PLAID_ITEM'
export const CLEAR_TOKEN = 'CLEAR_TOKEN'
export const CLEAN_ERROR = 'CLEAN_ERROR'
export const PLAID_ITEMS = 'PLAID_ITEMS'

export const {
  fetchAction: fetchPlaidLinkTokenAction,
  fetchStartAction: fetchPlaidLinkTokenStart,
  fetchSuccessAction: fetchPlaidLinkTokenSuccess,
  fetchErrorAction: fetchPlaidLinkTokenError
} = createFetchActions(PLAID_LINK_TOKEN)

export const createPlaidBankAccount = payload => ({
  type: CREATE_PLAID_BANK_ACCOUNT_ACTION,
  ...payload
})

export const {
  updateAction: updatePlaidItemAction,
  updateStartAction: updatePlaidItemStart,
  updateSuccessAction: updatePlaidItemSuccess,
  updateErrorAction: updatePlaidItemError
} = createUpdateActions(PLAID_ITEM)

export const cleanError = () => ({
  type: CLEAN_ERROR
})

export const clearToken = () => ({
  type: CLEAR_TOKEN,
})

export const {
  fetchAction: fetchPlaidItemsAction,
  fetchStartAction: fetchPlaidItemsStart,
  fetchSuccessAction: fetchPlaidItemsSuccess,
  fetchErrorAction: fetchPlaidItemsError
} = createFetchActions(PLAID_ITEMS)
