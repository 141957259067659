// @flow
import React from 'react'
import Typography from '@material-ui/core/Typography'
import PlaidCard from '@Common/PlaidCard'
import Button from '@Common/Button'
import SVG from '../../../../SVG'
import { useStyles } from './styles'
import '@images/svg/warning_icon.svg'
import {upperFirst} from 'lodash'

export type Props = {
  updatedPlaidItem: {
    originalPlaidItem: Object,
    id: string,
    bankName: string,
    subtype: string,
    mask: string
  },
  onClose: Function,
  onContinue: Function
}

const getAccountName = ({ bankName, type, lastFour }) => {
  return `${bankName} ${upperFirst(String(type).toLowerCase())} ${lastFour}`
}

const ReauthZegoConfirmationCard = ({ updatedPlaidItem, onClose, onContinue }: Props) => {
  const classes = useStyles()
  return (updatedPlaidItem) ? (
    <PlaidCard
      classes={{ root: classes.root, content: classes.content }}
      title={
        <React.Fragment>
          <SVG className={classes.warningIcon} name="warning_icon" />
        </React.Fragment>
      }
      onClose={() => onClose()}
      actions={
        <Button primary onClick={() => onContinue()} className={classes.button}>
          Continue
        </Button>
      }>
      <React.Fragment>
        <Typography style={{fontWeight: 700}}>
            You have chosen to add a new account, rather than relink your existing account.
        </Typography>
        <Typography>
          Because of this, all future debits set to use {getAccountName(updatedPlaidItem.originalPlaidItem)} for the purpose of one-time or auto payments
          will instead be debited from {getAccountName({bankName: updatedPlaidItem.bankName, type: updatedPlaidItem.subtype, lastFour: updatedPlaidItem.mask})}.
        </Typography>
      </React.Fragment>
    </PlaidCard>
  ) : null
}


export default ReauthZegoConfirmationCard