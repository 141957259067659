// @flow
import React from 'react'
import { CheckBox } from '@Common/Form'

import { TermsAndConditionsStyled, TermsAndConditionsCTAStyled } from './styles'

type Props = {
  accepted: boolean,
  onAcceptedChange: Function,
  termsAndConditionsUrl: string
}

const TermsAndConditions = ({
  accepted = false,
  onAcceptedChange,
  termsAndConditionsUrl
}: Props) => {
  return (
    <TermsAndConditionsStyled>
      <CheckBox checked={accepted} onChange={onAcceptedChange} />
      <span>
        I have confirmed the name on the account is correct and I agree with the{' '}
        <TermsAndConditionsCTAStyled
          href={termsAndConditionsUrl}
          target="_blank"
          rel="noopener noreferrer">
          terms and conditions{' '}
        </TermsAndConditionsCTAStyled>
        of use.*
      </span>
    </TermsAndConditionsStyled>
  )
}
export default TermsAndConditions
