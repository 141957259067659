// @flow
import get from 'lodash/get'
import Dinero from 'dinero.js'

export const getResidentNsfFee = (state: Object) => {
  const nsfFee = get(state, 'pmSettings.residentNsfFee')
  if (nsfFee == null) {
    return null
  }

  const { amount, currency } = nsfFee

  return new Dinero({ amount, currency }).toFormat()
}

export const getPaymentNotesEnabled = (state: Object) =>
  get(state, 'pmSettings.paymentNotesEnabled')

export const getShowSSOEmailCollectionForm = (state: Object) => 
  get(state, 'pmSettings.showSSOEmailCollectionForm')

export const getDisableMyProfileMenuTab = (state: Object) => 
  get(state, 'pmSettings.disableMyProfileMenuTab')

export const getDisableAutopayMenuTab = (state: Object) =>
  get(state, 'pmSettings.disableAutopayMenuTab')

export const getDisableMakePaymentMenuTab = (state: Object) =>
  get(state, 'pmSettings.disableMakePaymentMenuTab')

export const getDisableHelpMenuTab = (state: Object) =>
  get(state, 'pmSettings.disableHelpMenuTab')

export const getDisableSummaryMenuTab = (state: Object) =>
  get(state, 'pmSettings.disableSummaryMenuTab')

export const getDisableLogOutMenuTab = (state: Object) =>
  get(state, 'pmSettings.disableLogOutMenuTab')

export const getUIPaymentsDisabled = (state: Object) =>
  get(state, 'pmSettings.disableUIPayments')
