// @flow

import styled from '@emotion/styled'
import Flex from '@Common/Flex'

export const WithSignStyled = styled(Flex)`
  & span {
    width: 9.62px;
    color: ${({ theme }) => theme.palette.black};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    margin-right: 7px;
    display: flex;
    align-items: center;
  }
`
