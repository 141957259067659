// @flow
import React from 'react'
import type { Node } from 'react'
import Raven from 'zego-shared/utils/sentry'

type Props = {
  ErrorScreen: Function,
  children: Node
}

class ErrorBoundary extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Object, errorInfo: Object) {
    Raven.captureException(error, { extra: errorInfo })
  }

  render() {
    const { children, ErrorScreen } = this.props
    if (this.state.hasError) {
      return <ErrorScreen />
    }
    return children
  }
}

export default ErrorBoundary
