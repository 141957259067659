// @flow
import React from 'react'
import { Formik } from 'formik'
import { withRouter, Link } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import { Box } from '@material-ui/core'
import { Arrow } from '@icons'
import { FabButton } from '@Common/Button'
import Loader from '@Common/Loader'
import { RadioButton } from '@Common/Form'
import { methodTypes } from '@utils/view'
import Hidden from '@material-ui/core/Hidden'
import type { Theme } from '../../../../theme'

import AddPaymentMethodForm from '../../AddPaymentMethodForm'
import schemas, { getCardSchema } from '../../AddPaymentMethodForm/schemas'
import initialValues from '../../AddPaymentMethodForm/initialValues'
import CardDisclaimer from '../../CardDisclaimer'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import { StyledButton } from '../styles'
import { BoxStyled, HeaderStyled, PageTitle } from './styles'

type Props = {
  match: { params: { methodType: string }, url: string },
  saveActions: Object,
  isSaving: boolean,
  paymentOptions: Object,
  redirectOnMethodCreateSuccess: string => void,
  isFetching: boolean,
  theme: Theme,
  history: () => {}
}

const methodTypeToHeader = {
  [methodTypes.bankAccount]: 'Bank Account',
  [methodTypes.card]: 'Card'
}

export const getSaveCard = (args: {
  redirectOnMethodCreateSuccess: Function,
  baseUrl: string,
  saveActions: Object,
  methodType: string,
  isCredit: boolean
}) => (data: Object) => {
  const {
    redirectOnMethodCreateSuccess,
    baseUrl,
    saveActions,
    methodType,
    isCredit
  } = args
  redirectOnMethodCreateSuccess(baseUrl)
  const saveAction = saveActions[methodType]
  saveAction && saveAction(data, !isCredit)
}

export const getSetCardType = (setIsCredit: Function) => (
  event: SyntheticInputEvent<>
) => {
  const { value } = event.target
  setIsCredit(value === 'credit')
}

export const AddPaymentMethod = ({
  match: {
    params: { methodType },
    url
  },
  history,
  saveActions,
  isSaving,
  paymentOptions,
  redirectOnMethodCreateSuccess,
  isFetching,
  theme
}: Props) => {
  const { debit, cc = false } = paymentOptions
  const baseUrl = url.split(`/${methodType}`)[0]
  const isCard = methodType === methodTypes.card
  const [isCredit, setIsCredit] = React.useState(cc)
  const [isInternational, setIsInternational] = React.useState(false)
  const formPadding = theme.ux.isSSO ? `38px 23px` : undefined

  React.useEffect(() => {
    setIsCredit(cc)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOptions])

  const saveCard = getSaveCard({
    redirectOnMethodCreateSuccess,
    baseUrl,
    saveActions,
    isCredit,
    methodType
  })

  const setCardType = getSetCardType(setIsCredit)

  const getBtn = handleSubmit => (
    <StyledButton id="addPayMethodBtn" primary onClick={handleSubmit}>
      Add
    </StyledButton>
  )

  return (
    <Box>
      <HeaderStyled justifySpaceBetween alignCenter>
        <Link to={baseUrl}>
          <Box display="flex" alignItems="center" mr={6}>
            <FabButton
              background={theme.palette.tertiary.main}
              component={() => (
                <Arrow
                  id="backArrow"
                  name="arrow"
                  width="16px"
                  height="12px"
                  fill={theme.palette.primary.main}
                />
              )}
            />
          </Box>
        </Link>

        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          flex={1}>
          <PageTitle>{methodTypeToHeader[methodType]} Add</PageTitle>
          {isCard && debit && cc && !isFetching && (
            <Box display="flex">
              <RadioButton
                id="creditRadioBtn"
                label="Credit"
                value="credit"
                selected={isCredit}
                onChange={setCardType}
              />
              <RadioButton
                id="debitRadioBtn"
                label="Debit"
                value="debit"
                selected={!isCredit}
                onChange={setCardType}
              />
            </Box>
          )}
        </Box>
      </HeaderStyled>
      {!isSaving && !isFetching && (
        <Formik
          initialValues={initialValues[methodType]}
          validationSchema={
            isCard ? getCardSchema(isInternational) : schemas[methodType]
          }
          onSubmit={saveCard}>
          {({ handleSubmit, ...props }) => {
            if (isSaving) return null
            return (
              <React.Fragment>
                <AddPaymentMethodForm
                  methodType={methodType}
                  setInternational={setIsInternational}
                  customPadding={formPadding}
                  {...props}
                />
                <BoxStyled justifyRight>
                  {isCard && <CardDisclaimer />}
                  <Hidden smDown>{getBtn(handleSubmit)}</Hidden>
                </BoxStyled>
                <MobileBtnsPortal>{getBtn(handleSubmit)}</MobileBtnsPortal>
              </React.Fragment>
            )
          }}
        </Formik>
      )}
      {(isSaving || isFetching) && <Loader />}
    </Box>
  )
}

export default withRouter(withTheme(AddPaymentMethod))
