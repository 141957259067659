// @flow
import get from 'lodash/get'
import Dinero from 'dinero.js'

export const getPaymentOptionFees = (state: Object) =>
  get(state, 'payments.fees')

export const feeTypeNamesToFeeKeys = {
  CreditCard: 'creditCardFee',
  BankAccount: 'achFee',
  DebitCard: 'debitCardFee',
  PayPal: 'payPalFee'
}

export const getFee = (
  fees: Object,
  typename: string,
  paymentType?: 'regular' | 'express' = 'regular'
): Object => {
  if (fees) {
    const key = feeTypeNamesToFeeKeys[typename]
    const { currency, amount } = get(fees, [key, paymentType]) || {}
    if (currency && amount) {
      return { fee: Dinero({ currency, amount }).toFormat(), currency, amount }
    }
    return { fee: '', currency, amount }
  }
}

export const getExpressPayFee = (
  paymentOptionFees: Object[],
  typeName: string,
  useExpressPay: boolean
) => {
  const { amount = 0 } = useExpressPay
    ? getFee(paymentOptionFees, typeName, 'express')
    : {}
  return amount
}
