// @flow
import styled from '@emotion/styled'
import Card from '@Common/Card'
import { FormGroup } from '@Common/Form'

export const FixAmountStyled = styled(Card)`
  min-height: 171px;

  ${({ theme, type }) =>
    theme.ux.isSSO &&
    `    
    & .MuiCardContent-root {
      padding: 30px;
    }
  `}
`

export const FormHeader = styled.h5`
  color: ${({ theme }) => theme.palette.black};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  line-height: 22px;
  width: 100%;
  margin: ${({ theme }) => `${theme.space.s15} 0 ${theme.space.s20}`};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  &:first-of-type {
    margin-top: 0;
  }

  & span {
    color: ${({ theme }) => theme.palette.primary.main};
    text-align: right;
    float: right;
  }
`
export const FormStyled = styled.form`
  display: grid;
`

export const FormWrapper = styled.div`
  border-left: ${({ theme }) => `1px solid ${theme.palette.neutral.main}`};
  padding-left: 12%;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    margin-top: 2em;
    padding-left: 0;
    border-left: 0;
    border-top: ${({ theme }) => `1px solid ${theme.palette.neutral.main}`};
  }
`

export const FormGroupStyled = styled(FormGroup)`
  margin-bottom: ${({ theme }) => theme.space.s20};

  &:last-of-type {
    margin-bottom: 19px;
  }
`
