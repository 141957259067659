// @flow
import React from 'react'
import Flex from '@Common/Flex'
import { useSelector } from 'react-redux'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import { getLevelCreditActive } from 'zego-shared/store/payments/creditReporting/selectors'
import {
  Ad,
  ContentWrap,
  Title,
  SubTitle,
  SignUpBtn,
  AmountDecimal
} from './styles'

const LookBackAd = () => {
  const residentId = useSelector(getUserId)
  const levelCreditActive = useSelector(getLevelCreditActive)
  // technically we're missing a 'reactivate page' here but if the opt-in is deactivated we shouldn't be rendering
  // a lookback ad ... so this will probably be fine
  // people with 'inactive' opt-ins should not be redirected to the sign-up page
  const url = (levelCreditActive)
    ? `/payments/${residentId}/credit-reporting/lookback/purchase`
    : `/payments/${residentId}/credit-reporting/signup?lookback=true`

  return (
    <Ad to={url}>
      <ContentWrap>
        <Flex direction="column" id="lbAddPaymentsTxt">
          <Title>
            $49<AmountDecimal>.95</AmountDecimal>
          </Title>
          <SubTitle>one-time payment</SubTitle>
        </Flex>
        <SignUpBtn id="lbPurchaseBtn">Purchase</SignUpBtn>
      </ContentWrap>
    </Ad>
  )
}

export default LookBackAd
