// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import LevelCredit from './LevelCredit'
import LegacyCreditReporting from './LegacyCreditReporting'
import {
  getCreditReportingDetails,
  hasCreditReportingDetails,
  isLevelCreditReportingEnabled,
  getLevelCreditActive,
  getLevelCreditNonExistent,
  getIsOptIn
} from 'zego-shared/store/payments/creditReporting/selectors'
import { CreditReportingDetails } from './types'

type Props = {
  match: { url: string },
  history: Object
}

type ViewProps = {
  hasDetails: boolean,
  details: CreditReportingDetails,
  isOptedInLegacy: boolean,
  isLevelCreditEnabled: boolean,
  levelCreditActive: boolean,
  levelCreditNonExistent: boolean,
  hasTransactions: boolean
} & Props

export const CRVData = () => {
  const hasDetails: boolean = useSelector(hasCreditReportingDetails)
  const details: CreditReportingDetails = useSelector(getCreditReportingDetails)
  const isOptedInLegacy = useSelector(getIsOptIn)
  const isLevelCreditEnabled = useSelector(isLevelCreditReportingEnabled)
  const levelCreditActive = useSelector(getLevelCreditActive)
  const levelCreditNonExistent = useSelector(getLevelCreditNonExistent)

  return {
    details,
    hasDetails,
    isOptedInLegacy,
    isLevelCreditEnabled,
    levelCreditActive,
    levelCreditNonExistent
  }
}

export const CreditReportingView = ({
  match,
  history,
  hasDetails,
  details,
  isOptedInLegacy,
  isLevelCreditEnabled,
  levelCreditActive,
  levelCreditNonExistent
}: ViewProps) => {
  if (isLevelCreditEnabled && !isOptedInLegacy) {
    return <LevelCredit isOptedIn={!levelCreditNonExistent} isActive={levelCreditActive} details={details}/>
  }

  if (isLevelCreditEnabled && isOptedInLegacy) {
    return (
      <LegacyCreditReporting
        match={match}
        history={history}
        details={details}
        isOptedInLegacy={isOptedInLegacy}
      />
    )
  }

  return (
    <LegacyCreditReporting
      match={match}
      history={history}
      details={details}
      isOptedInLegacy={isOptedInLegacy}
    />
  )
}

export default (props: Props) => {
  return <CreditReportingView {...props} {...CRVData()} />
}
