// @flow
import * as React from 'react'
import Clear from '@material-ui/icons/Clear'

import Flex from '@Common/Flex'
import PaymentMethod from '../../PaymentMethod'
import { MorePaymentMethodCardStyled, CloseIconButton } from './styles'

type Props = {
  paymentTotal: string,
  onCloseBtnClick: Function,
  onMethodClick: Function,
  paymentOptions: Object[],
  paymentOptionsProps: Object
}

const MoreOptions = ({
  onMethodClick,
  onCloseBtnClick,
  paymentOptions,
  paymentOptionsProps
}: Props) => {
  return (
    <MorePaymentMethodCardStyled
      CardContentProps={{
        classes: {
          root: 'card-content-root'
        }
      }}>
      <CloseIconButton onClick={onCloseBtnClick}>
        <Clear />
      </CloseIconButton>
      <p>Select the payment method type you would prefer to use.</p>
      <Flex alignCenter wrap>
        {paymentOptions.map(method => {
          const props = paymentOptionsProps[method.name]
          return (
            <PaymentMethod
              {...method}
              onClick={() => onMethodClick(method)}
              {...props}
            />
          )
        })}
      </Flex>
    </MorePaymentMethodCardStyled>
  )
}

export default MoreOptions
