import { createFetchActions } from '../../../helpers/actions/creators'

export const WORK_ORDER_OPTIONS = 'WORKORDEROPTIONS'
export const SUBMIT_WORK_ORDER = 'SUBMIT_WORK_ORDER'
export const SUBMIT_WORK_ORDER_START = 'SUBMIT_WORK_ORDER_START'
export const SUBMIT_WORK_ORDER_END = 'SUBMIT_WORK_ORDER_END'

export const {
  fetchAction: fetchWorkOrderOptions,
  fetchStartAction: fetchWorkOrderOptionsStart,
  fetchSuccessAction: fetchWorkOrderOptionsSuccess,
  fetchErrorAction: fetchWorkOrderOptionsError
} = createFetchActions(WORK_ORDER_OPTIONS)

export const submitWorkOrder = (message, options, file, history) => ({
  type: SUBMIT_WORK_ORDER,
  message,
  options,
  file,
  history
})

export const submitWorkOrderStart = {
  type: SUBMIT_WORK_ORDER_START
}

export const submitWorkOrderEnd = {
  type: SUBMIT_WORK_ORDER_END
}
