// @flow
import React from 'react'
import MoreHoriz from '@material-ui/icons/MoreHoriz'
import AddIcon from '@material-ui/icons/Add'
import Flex from '@Common/Flex'
import Modal from '@material-ui/core/Modal'
import Loader from '@Common/Loader'
import MorePaymentMethods from '../MorePaymentMethods'
import {
  PaymentMethodEditStyled,
  MethodStyled,
  AddIconButtonStyled,
  MoreIconButtonStyled
} from './styles'
import { ModalBackDropStyled } from '../../styles'
import { MoreMethodsStyled } from '../styles'
import { withPaymentNotes } from '../../../PaymentNotes'

type Props = {
  value: { fee: string, name: string },
  fee: string,
  onAddClick: Function,
  paymentMethods: Object[],
  paymentMethodProps: Object[],
  onShowMoreClick: string => mixed,
  isFetching: boolean
}

type WrapperProps = {
  ...Props,
  showNotes?: boolean
}

const PaymentMethodDetails = ({
  value = {},
  onAddClick,
  paymentMethods,
  paymentMethodProps,
  onShowMoreClick,
  isFetching
}: Props) => {
  const { fee = '', name } = value
  const [showMore, setShowMore] = React.useState(false)
  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  const handleMethodClick = (id: string) => {
    toggleShowMore()
    onShowMoreClick(id)
  }

  return (
    <PaymentMethodEditStyled
      CardContentProps={{
        classes: {
          root: 'card-content-root'
        }
      }}>
      {isFetching ? (
        <Flex justifyCenter>
          <Loader fullScreen={false} />
        </Flex>
      ) : (
        <Flex direction="column" fullHeight height="fit-content">
          <h5>Payment method</h5>
          <Flex justifySpaceBetween wrap>
            <MethodStyled direction="column" justifySpaceBetween>
              <p>Using this payment method</p>
              <div id="paymentMethod">
                {name}{' '}
                {fee && (
                  <span id="methodFee">
                    fee <b>{fee}</b>
                  </span>
                )}
                {!fee && (
                  <span>
                    <b>free</b>
                  </span>
                )}
              </div>
            </MethodStyled>
            <Flex alignCenter>
              <MoreIconButtonStyled id="moreMethods" onClick={toggleShowMore}>
                <MoreHoriz />
              </MoreIconButtonStyled>

              <AddIconButtonStyled id="addMethod" onClick={onAddClick}>
                <AddIcon />
              </AddIconButtonStyled>
            </Flex>
          </Flex>
        </Flex>
      )}
      <Modal
        open={showMore}
        BackdropComponent={ModalBackDropStyled}
        onBackdropClick={toggleShowMore}>
        <MoreMethodsStyled justifyCenter alignCenter>
          <MorePaymentMethods
            onCloseBtnClick={toggleShowMore}
            onMethodClick={handleMethodClick}
            paymentMethods={paymentMethods}
            paymentMethodProps={paymentMethodProps}
          />
        </MoreMethodsStyled>
      </Modal>
    </PaymentMethodEditStyled>
  )
}

const PaymentMethodDetailsWithNotes = ({ showNotes, ...props }: WrapperProps) =>
  showNotes
    ? withPaymentNotes(PaymentMethodDetails)({ ...props })
    : PaymentMethodDetails({ ...props })

export default PaymentMethodDetailsWithNotes
