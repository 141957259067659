import withFetch from '../../../helpers/reducers/withFetch'
import { WORK_ORDER_OPTIONS, SUBMIT_WORK_ORDER_START, SUBMIT_WORK_ORDER_END } from '../actions'

const form = (state = null, action) => {
  switch (action.type) {
    case SUBMIT_WORK_ORDER_START:
      return {...state, isSubmitting: true}
    case SUBMIT_WORK_ORDER_END:
      return {...state, isSubmitting: false}
    default:
      return state
  }
}

export default withFetch(WORK_ORDER_OPTIONS)(form)
