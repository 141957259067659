export const VALIDATE_EMAIL_TOKEN = 'VALIDATE_EMAIL_TOKEN'
export const GET_RES_BILLING_INFO = 'GET_RES_BILLING_INFO'
export const UPDATE_RES_BILLING_INFO = 'UPDATE_RES_BILLING_INFO'
export const VERIFY_RESIDENT_PIN = 'VERIFY_RESIDENT_PIN'
export const RESIDENT_PIN_LOGIN = 'RESIDENT_PIN_LOGIN'

export const validateEmailToken = (token, includeStreet) => ({
  type: VALIDATE_EMAIL_TOKEN,
  token,
  includeStreet
})

export const getResidentBillingPref = data => ({
  type: GET_RES_BILLING_INFO,
  data
})

export const updateResidentBillingPref = (token, residentPrefs) => ({
  type: UPDATE_RES_BILLING_INFO,
  token,
  residentPrefs
})

export const verifyResidentPin = (pin) => ({
  type: VERIFY_RESIDENT_PIN,
  pin
})

export const residentPinLogin = data => ({
  type: RESIDENT_PIN_LOGIN,
  data
})
