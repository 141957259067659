/* eslint-disable react-hooks/exhaustive-deps */
// @flow
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { match, BrowserHistory } from 'react-router-dom'
import { withTheme } from 'emotion-theming'
import { Route } from 'react-router-dom'
import Button from '@Common/Button'
import Layout from '../../Layout'
import Summary from '../Summary'
import PaymentFlow, { usePaymentFlow } from '../../PaymentFlow'
import PaymentsBlocked from '../../PaymentsBlocked'
import { progress as progressObj } from './config'
import { ButtonWrapperStyled } from './styles'
import type { AutoPay } from './types'
import type { Flow } from '../../PaymentFlow'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import { Hidden } from '@material-ui/core'
import { useSelectedMethod } from '../../../../hooks'
import ReauthFlowModal from '../../Plaid/Reauthorization/ReauthFlowModal'
import { getCancelOptionBehaviorOverride } from 'zego-shared/store/overrides/selectors'

type Props = {
  match: match,
  history: BrowserHistory,
  autoPay: AutoPay,
  flows: Flow[],
  startFlow: number,
  saveDetails: Object => void,
  expressFee: string,
  isFetching: boolean,
  isAutopayDisabled: boolean,
  itemForReauth: Object | null
}

export const getToggleHideBtns = (
  setHideBtns: boolean => void,
  hideBtns: boolean
) => () => {
  setHideBtns(!hideBtns)
}

export const getSetIsSummary = (setIsSummary: boolean => void) => () =>
  setIsSummary(false)

export const getSummaryComponent = (setIsSummary: Function) => (
  props: Object
) => <Summary onBackClick={setIsSummary} {...props} />

export const getHandleChange = (
  saveDetails: Object => void,
  autoPay: AutoPay
) => (name: string) => (value: any) => {
  saveDetails({ ...autoPay, [name]: value })
}

export const getHasValue = (
  autoPay: AutoPay,
  validator: Function,
  name: string
) => () => {
  const value = autoPay[name]
  if (validator) return validator(value)

  return Boolean(autoPay[name])
}

const getNavBtns = (
  onCancelClicked,
  onContinueClicked,
  hasValue,
  required
) => ({ idSuffix = '' }) => (
  <React.Fragment>
    <Button
      id={`exitBtn${idSuffix}`}
      outline
      primary
      onClick={onCancelClicked}
    >
      Exit &amp; Cancel
    </Button>
    <Button
      id={`continueBtn${idSuffix}`}
      primary
      onClick={onContinueClicked}
      disabled={required && !hasValue()}>
      Continue
    </Button>
  </React.Fragment>
)

const AddAutoPay = ({
  match: { url, path },
  history: { push },
  autoPay,
  flows,
  startFlow,
  saveDetails,
  expressFee,
  isFetching,
  isAutopayDisabled,
  itemForReauth
}: Props) => {
  const [hideBtns, setHideBtns] = useState(false)
  const [isSummary, setIsSummary] = useState(false)
  const [showReauth, setShowReauth] = useState(false)
  const [flow, { gotoNextFlow }] = usePaymentFlow()
  const [{ method }, setSelectedPaymentMethod] = useSelectedMethod(setHideBtns, gotoNextFlow)
  const { nextFlow, progress, required, name, validator } = flows[flow]
  const handleChange = getHandleChange(saveDetails, autoPay)
  const toggleHideBtns = getToggleHideBtns(setHideBtns, hideBtns)
  const hasValue = getHasValue(autoPay, validator, name)
  const isSummaryNext = !Boolean(nextFlow)
  const paymentSummaryRoute = '/payments/summary'

  const gotoPaymentSummary = () => {
    push(`${url}/summary`)
    setIsSummary(true)
  }

  const reauthIsRequired = () => {
    // name refers to the current flow name.
    return itemForReauth && name === 'lastPaymentMethod'
  }

  const onContinueClicked = () => {
    if (reauthIsRequired()) {
      setShowReauth(true)
      return
    }

    if (isSummaryNext) {
      gotoPaymentSummary()
    } else {
      gotoNextFlow(nextFlow)
    }
  }

  const cancelOption = useSelector(getCancelOptionBehaviorOverride)
  const onCancelClicked = () => {
    if (cancelOption) {
      window.location.replace(cancelOption)
    } else {
      push(paymentSummaryRoute)
    }
  }

  const NavBtns = getNavBtns(
    onCancelClicked,
    onContinueClicked,
    hasValue,
    required
  )

  useEffect(() => {
    hideBtns && setHideBtns(false)
  }, [flow, autoPay])

  useEffect(() => {
    gotoNextFlow(startFlow)
  }, [isFetching])

  if (!isFetching && isAutopayDisabled) {
    return (
      <Layout progress={progressObj.noAutoPay} isFetching={isFetching}>
        <PaymentsBlocked isAutoPay={true} />
      </Layout>
    )
  }

  return (
    <Layout
      progress={isSummary ? progressObj.summary : progress}
      isFetching={isFetching}>
      <Route
        exact
        path={path}
        render={() => (
          <React.Fragment>
            <PaymentFlow
              flows={flows}
              flow={flow}
              details={autoPay}
              handleDetailsChange={handleChange}
              onIconClick={gotoNextFlow}
              onAddPaymentMethodChange={toggleHideBtns}
              onContinueClicked={onContinueClicked}
              expressFee={expressFee}
              setHideBtns={setHideBtns}
              selectedPaymentMethod={method}
              setMethod={setSelectedPaymentMethod}
            />
            {!hideBtns && (
              <Hidden smDown>
                <ButtonWrapperStyled>
                  <NavBtns />
                </ButtonWrapperStyled>
              </Hidden>
            )}
            {!hideBtns && (
              <MobileBtnsPortal>
                <NavBtns idSuffix="Mobile" />
              </MobileBtnsPortal>
            )}
          </React.Fragment>
        )}
      />
      <Route
        path={`${path}/summary`}
        render={getSummaryComponent(getSetIsSummary(setIsSummary))}
      />
      {showReauth && (
        <ReauthFlowModal
          plaidItem={itemForReauth}
          onClose={() => setShowReauth(false)}
        />
      )}
    </Layout>
  )
}

export default withTheme(AddAutoPay)
