// @flow
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { paymentMethodTypes } from '@utils/view'
import AlertModal from '@Common/AlertModal'
import NoPaymentMethods from './NoPaymentMethods'
import ExistingCard from './ExistingCard'
import ExistingBankAccount from './ExistingBankAccount'
import MorePaymentMethods from '../MorePaymentMethods'
import { useHistory } from 'react-router'
import { getCancelOptionBehaviorOverride } from 'zego-shared/store/overrides/selectors'

type Props = {
  paymentMethods: Object[],
  value: { id: string, fee: string, name: string, typeName: string },
  paymentMethodProps: Object,
  selectPaymentMethod: string => mixed,
  paymentOptions: Object[],
  setHideBtns: ?Function,
  showNotes: boolean,
  onContinueClicked: Function,
  onPayPalApprove: Function,
  createPayPalOrder: Function,
  onAddPaymentMethod: Function
}

/*
 * When loaded for the first time, show an existing debit or credit card if present
 * When a user picks a payment menthod, show the picked method
 */
const PaymentMethodDetails = ({
  paymentMethods,
  value = {},
  paymentMethodProps,
  selectPaymentMethod,
  paymentOptions,
  setHideBtns,
  onAddPaymentMethod,
  showNotes,
  onContinueClicked,
  createPayPalOrder,
  onPayPalApprove
}: Props) => {
  const [showMore, setShowMore] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(false)
  const history = useHistory()

  const addPaymentMethod = typeName => {
    const option = paymentOptions.find(option => option.typeName === typeName)
    onAddPaymentMethod(paymentMethodProps[option?.name])
  }

  // Set current method to user picked method or default to debit/credit
  const currentMethod = paymentMethods.find(m => m.id === value.id)

  const debitEnabled = !!paymentOptions.find(
    option => option.typeName === paymentMethodTypes.DEBIT
  )
  const achEnabled = !!paymentOptions.find(
    option => option.typeName === paymentMethodTypes.BANK
  )

  const hasDebit = !!paymentMethods.find(
    option => option.typeName === paymentMethodTypes.DEBIT
  )

  useEffect(() => {
    setHideBtns && setHideBtns(true)
    return () => {
      setHideBtns && setHideBtns(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  const toggleAlert = () => setShowAlert(!showAlert)

  const morePaymentsMethodClick = (id: string) => {
    toggleShowMore()
    selectPaymentMethod(id)
  }

  const newProps = {
    hasDebit,
    debitEnabled,
    achEnabled,
    paymentOptions,
    paymentMethods,
    currentMethod,
    showNotes,
    onContinueClicked,
    onCancel: toggleAlert,
    onShowMore: toggleShowMore,
    addPaymentMethod,
    selectPaymentMethod
  }

  const renderScenario = () => {
    switch (value.typeName) {
      case paymentMethodTypes.DEBIT:
      case paymentMethodTypes.CREDIT:
        return <ExistingCard {...newProps} />
      case paymentMethodTypes.BANK:
        return <ExistingBankAccount {...newProps} />
      default:
        return <NoPaymentMethods {...newProps} />
    }
  }

  const cancelOption = useSelector(getCancelOptionBehaviorOverride)
  const onAlertCancel = () => {
    if (cancelOption) {
      window.location.replace(cancelOption)
    } else {
      history.push('/payments/summary')
    }
  }

  const alertBtns = [
    {
      onClick: onAlertCancel,
      primary: true,
      outline: true,
      long: true,
      text: 'Yes'
    },
    {
      onClick: toggleAlert,
      primary: true,
      long: true,
      text: 'No'
    }
  ]

  return (
    <>
      <AlertModal
        show={showAlert}
        title="Are you sure you wish to exit and cancel this payment?"
        buttons={alertBtns}
      />
      {showMore ? (
        <MorePaymentMethods
          onCancel={toggleAlert}
          onCloseBtnClick={toggleShowMore}
          onMethodClick={morePaymentsMethodClick}
          paymentMethods={paymentMethods}
          paymentMethodProps={paymentMethodProps}
          paymentOptions={paymentOptions}
          onAddPaymentMethod={onAddPaymentMethod}
          currentMethod={currentMethod}
          onPayPalApprove={onPayPalApprove}
          createPayPalOrder={createPayPalOrder}
        />
      ) : (
        renderScenario()
      )}
    </>
  )
}

export default PaymentMethodDetails
