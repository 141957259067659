// @flow
import React from 'react'
import { useSelector } from 'react-redux'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import {
  LookbackAd,
  LookbackAdText,
  GoButton,
  HeadText,
  SubText,
  BodyText
} from './styles'

type Props = {
  isOptedIn: boolean
}

const IncludePaymentAd = ({ isOptedIn = false }: Props) => {
  const residentId = useSelector(getUserId)
  const creditReportingUrl = `/payments/${residentId}/credit-reporting/lookback`

  return (
    <LookbackAd id="lookbackAd" to={creditReportingUrl}>
      <LookbackAdText>
        <HeadText id="lookBackHeadTxt"> Include Past Payments</HeadText>
        <SubText id="lookBackSubTxt"> In Your Credit Score</SubText>
        <BodyText id="lookBackTxt">
          Get credit for payments you’ve already made with Zego!
        </BodyText>
      </LookbackAdText>
      <GoButton id="goNowLink">Learn More</GoButton>
    </LookbackAd>
  )
}

export default IncludePaymentAd
