import { isUndefined, mapValues } from 'lodash'
import { convertLockAmount, formatPaymentAmountsFromParams } from './utils'

/**
 * A utility function to extract query paraeters from a query string, decode them, and
 * convert as needed
 * @param {*} param0 QueryParameters from the parsed url
 * @returns Any parameters that were extracted
 */
export function extractParamsFromQueryString( {query, url} ) {
  const decoded = mapValues(query, decodeURIComponent)
  const {
    redirect_uri: redirectURI,
    include_token: includeToken,
    payment_amounts: paymentAmounts,
    lock_amount: lockAmountRaw,
    hide_autopay_type: hideAutopayType,
    cancel_option: cancelOption,
    redir_url: paymentSuccessRedirect,
    ipn_url: ipnUrl, 
    ipn_custom_value: ipnCustomValue, 
    secondary_resident_id: secondaryResidentID
  } = decoded
  let formattedPaymentFields
  const lockAmount = isUndefined(lockAmountRaw) ? false: convertLockAmount(lockAmountRaw)

  if (paymentAmounts) {
    formattedPaymentFields = (
      formatPaymentAmountsFromParams(
        paymentAmounts,
        lockAmount
      )
    )
  }

  return {
    redirectURI,
    includeToken,
    lockAmount,
    formattedPaymentFields,
    hideAutopayType,
    cancelOption,
    paymentSuccessRedirect,
    ipnUrl,
    ipnCustomValue,
    secondaryResidentID
  }
}
