import styled from '@emotion/styled'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'

export const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: 'transparent',
    overflow: 'hidden'
  },
  paperAnchorDockedLeft: {
    backgroundColor: theme.palette.common.white,
    borderRight: 'none',
    boxShadow: '0 2px 20px 0 rgba(0,0,0,0.25)',
    height: '100vh',
    width: '100vw'
  }
}))

export const HeaderIconWrap = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const DrawerIconWrap = styled('div')`
  cursor: pointer;
  position: absolute;
  right: 28px;
  top: 28px;
`

export const StyledList = styled('div')`
  margin: auto;
`

export const StyledLink = styled(NavLink)`
  display: block;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  margin-bottom: 18px;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }

  & svg {
    margin-left: -20px;
    margin-right: 10px;
  }
`

export const HeaderText = styled('div')`
  color: ${({ theme }) => theme.palette.primary.deep};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  margin: 8px 10px;
  text-transform: capitalize;
`