// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import { Dialog } from '@material-ui/core'

export const CardDisclaimerStyled = styled(Flex)`
  flex: 1;
`

export const DisclaimerStyled = styled.span`
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  line-height: 20px;
  height: fit-content;

  & button {
    padding: 0 10px 6px 0;
    color: ${({ theme }) => theme.palette.secondary.dark};
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    ${({ theme }) =>
      theme.ux.isSSO &&
      `
      margin: 0;
      border: 0;
      line-height: 1;
    `}
  }

  & a {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

// For SSO only
export const StyledDialog = styled(Dialog)`
  & .MuiDialogTitle-root {
    padding: 0;
    display: flex;
    justify-content: flex-end;
  }

  & .MuiDialogContent-root {
    padding: 0 20vw 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ theme }) => [theme.breakpoints.down('sm')]} {
      padding: 0 10vw 10vh;
    }

    & span {
      font-size: 16px;
    }
  }
`
