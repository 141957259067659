// @flow
import React from 'react'
import { Box } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import { generate as shortid } from 'shortid'
import ListFull from '@Common/ListFull'
import { GridItem } from '@Common/Grid'
import Loader from '@Common/Loader'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Hidden from '@material-ui/core/Hidden'

import RowAction from '../RowAction'
import NoMethod from '../NoMethod'
import { HeaderStyled, StyledTableContainer, StyledButton } from './styles'
import MethodListIMobile from './MethodListIMobile'
import { MobileBtnsPortal } from '@Common/MobileButtons'
import type { Account } from './types'
import PaymentsBlocked from '../../PaymentsBlocked'
import { paymentStatuses } from 'zego-shared/store/payments/summary/constants'
import Plaid from '../../Plaid'

const columns = [
  { label: 'Account', dataKey: 'account' },
  { label: 'Card/Bank Name', dataKey: 'name' },
  { label: 'Type', dataKey: 'type' }
]

type Props = {
  accounts: [Account],
  deleteMethod: (id: string) => void,
  match: { url: string },
  isFetching: boolean,
  optionsConfig: { hasBank?: boolean, hasCard?: boolean },
  paymentState: boolean
}

const TableContainer = ({ isSSO, children }) => {
  if (!isSSO) {
    return <React.Fragment>{children}</React.Fragment>
  }

  return <StyledTableContainer>{children}</StyledTableContainer>
}

const MethodsList = ({
  accounts,
  deleteMethod,
  isFetching,
  match: { url },
  optionsConfig,
  paymentState
}: Props) => {
  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const PathAddBankAccount = `${url}/bank-account/add`
  const PathAddCard = `${url}/card/add`
  const { hasBank, hasCard } = optionsConfig
  const hasAccounts = accounts.length > 0
  const history = useHistory()
  const isSsoMobile =
    useMediaQuery(theme.breakpoints.down('sm')) && theme.ux.isSSO
  const isSSO = theme.ux.isSSO
  const tableProps = {}

  const List = ({ isSmDown }) => {
    if (isSmDown) {
      return (
        <MethodListIMobile deleteMethod={deleteMethod} accounts={accounts} />
      )
    }

    if (isSSO) {
      tableProps.stickyHeader = true
      tableProps['aria-label'] = 'Payment Method List Table'
    }

    return (
      <TableContainer isSSO={isSSO}>
        <ListFull
          key={shortid()}
          elementId="paymentMethods"
          columns={columns}
          rows={accounts}
          idKey="account"
          rowAction={props => (
            <RowAction {...props} onConfirmDelete={deleteMethod} />
          )}
          tableProps={tableProps}
        />
      </TableContainer>
    )
  }

  const Btns = (
    <React.Fragment>
      {hasCard && (
        <Link to={PathAddCard}>
          <StyledButton id="addCardBtn" outline primary>
            Add a Payment Card
          </StyledButton>
        </Link>
      )}
      {hasBank && (
        <Plaid
          switchToManual={() => {
            history.push(PathAddBankAccount)
          }}
          render={({ enabled, open }) => {
            const usePlaid = enabled
            const onClickHandler = event => {
              event.preventDefault()
              open()
            }
            return (
              <Link
                to={PathAddBankAccount}
                onClick={usePlaid ? onClickHandler : undefined}>
                <StyledButton id="addBankBtn" outline primary>
                  Add a Bank Account
                </StyledButton>
              </Link>
            )
          }}
        />
      )}
    </React.Fragment>
  )

  if (isFetching) return <Loader />

  return paymentState === paymentStatuses.BLOCKED ? (
    <PaymentsBlocked />
  ) : (
    <GridItem
      style={isSsoMobile ? { padding: `0 ${theme.space.s15}` } : undefined}
      columnStart={1}
      columnSpan={12}>
      <HeaderStyled variant="h3">Payment Methods</HeaderStyled>
      {!isFetching && !hasAccounts && (
        <NoMethod
          addBankAccountPath={PathAddBankAccount}
          addCardPath={PathAddCard}
          optionsConfig={optionsConfig}
        />
      )}
      {!isFetching && hasAccounts && (
        <React.Fragment>
          <List isSmDown={isSmDown} />
          <Hidden smDown>
            <Box
              display="flex"
              justifyContent="flex-end"
              fontWeight="fontWeightBold"
              fontSize="h6.fontSize"
              mt={isSSO ? 4 : 15}
              mb={isSSO ? 1 : 15}>
              {Btns}
            </Box>
          </Hidden>
          <MobileBtnsPortal>{Btns}</MobileBtnsPortal>
        </React.Fragment>
      )}
    </GridItem>
  )
}

export default MethodsList
