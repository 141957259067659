export const dateTypes = {
  lastDay: 'Last Day of Month',
  firstDay: 'First Day of Month',
  indefinite: 'Indefinite',
  pickDate: 'Pick a Date'
}

export const isPickADate = (dateType: string) => {
  return dateTypes.pickDate === dateType
}

export const getInputValue = (
  value: any,
  dateType: string,
  formatter: Function
) => {
  if (isPickADate(dateType)) {
    return formatter(value)
  }
  return dateType
}

export const getSelectedDate = (date: Date) => {
  if (date instanceof Date) {
    return date.getDate()
  }
}
