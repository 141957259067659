// @flow
import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import PlaidCard from '@Common/PlaidCard'
import Button from '@Common/Button'
import SVG from '../../../../SVG'
import { StyledFormControlLabel, AcknowledgementCheckbox, useStyles } from './styles'
import '@images/svg/warning_icon.svg'
import PlaidAcknowledgement from '../../../../Common/PlaidAcknowledgement'
import {upperFirst} from 'lodash'

export type Props = {
  plaidItem: {
    itemId: string,
    bankName: string,
    type: string,
    lastFour: string
  },
  onClose: Function,
  onContinue: Function
}

const getAccountName = ({ bankName, type, lastFour }) => {
  return `${bankName} ${upperFirst(String(type).toLowerCase())} ${lastFour}`
}

const ReauthFlowCard = ({ plaidItem, onClose, onContinue }: Props) => {
  const classes = useStyles()

  const [acknowledgement, setAcknowledgement] = useState(false)

  return plaidItem ? (
    <PlaidCard
      classes={{ root: classes.root, content: classes.content }}
      title={
        <React.Fragment>
          <SVG className={classes.warningIcon} name="warning_icon" />
          <Typography variant="h4" component="h1" className={classes.title}>
            Reauthorization Needed
          </Typography>
        </React.Fragment>
      }
      onClose={onClose}
      actions={
        <Button
          disabled={!acknowledgement}
          primary
          onClick={onContinue}
          className={classes.button}>
          Continue
        </Button>
      }>
      <React.Fragment>
        <Typography>
          The account you are attempting to use (
          <strong>{getAccountName(plaidItem)}</strong>) was linked with Plaid
          Account Connect and will remain unavailable for payments until you
          have reauthorized.
        </Typography>
        <Typography>Continue to log in and relink this account.</Typography>
      </React.Fragment>
      <React.Fragment>
        <PlaidAcknowledgement>
          <StyledFormControlLabel
            value="end"
            onChange={() => setAcknowledgement(!acknowledgement)}
            control={<AcknowledgementCheckbox type="checkbox" />}
            label={`By selecting an account other than ${getAccountName(
              plaidItem
            )} in this process, I acknowledge that ${getAccountName(
              plaidItem
            )} will be replaced by the newly selected account.`}
            labelPlacement="end"
          />
        </PlaidAcknowledgement>
      </React.Fragment>
    </PlaidCard>
  ) : null
}

export default ReauthFlowCard
