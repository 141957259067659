// @flow
import React, { useCallback, useState } from 'react'
import { EnvelopeIcon } from '@icons'
import Flex from '@Common/Flex'
import AlertModal from '@Common/AlertModal'
import { StyledIconButton, Title } from './styles'
import { FormGroup, CheckBox, TextInput } from '../../../Common/Form/'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { getUserEmail } from 'zego-shared/store/authorization/selectors'
import {
  requestPaymentReceipt,
  REQUEST_PAYMENT_RECEIPT
} from 'zego-shared/store/payments/history/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { requestPaymentEmailSchema } from '../../CreditReporting/Lookback/schema'

type Props = {
  transactionId: number
}

const isSubmittingSelector = createIsFetchingSelector([
  REQUEST_PAYMENT_RECEIPT.toLowerCase()
])

const SendPaymentReceiptButton = ({ transactionId }: Props) => {
  const dispatch = useDispatch()
  const isSubmitting = useSelector(isSubmittingSelector)
  const [isFormVisible, setIsFormVisible] = useState(false)
  const initialEmail = useSelector(getUserEmail)
  const toggleForm = () => setIsFormVisible(!isFormVisible)

  const sendReceipt = () => {
    dispatch(requestPaymentReceipt(values))
  }

  const { values, touched, handleChange, submitForm, errors } = useFormik({
    initialValues: { email: initialEmail, transactionId },
    validationSchema: requestPaymentEmailSchema,
    onSubmit: sendReceipt
  })

  const checkDisableSubmit = useCallback(
    () => errors.email || !values.email || isSubmitting,
    [errors.email, values.email, isSubmitting]
  )

  const formButtons = [
    {
      text: 'Cancel',
      onClick: toggleForm,
      primary: true,
      outline: true,
      long: true,
      className: 'cancel-button'
    },
    {
      text: 'Send Receipt',
      onClick: submitForm,
      primary: true,
      long: true,
      disabled: checkDisableSubmit(),
      animate: isSubmitting,
      className: 'submit-button'
    }
  ]

  return (
    <>
      <StyledIconButton onClick={toggleForm}>
        <EnvelopeIcon name="envelope" width="24px" height="24px" />
      </StyledIconButton>

      <AlertModal show={isFormVisible} buttons={formButtons}>
        <Title className="modal-title">Resend Receipt</Title>

        <FormGroup label="Email" touched={touched.email} error={errors.email}>
          <TextInput
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
        </FormGroup>
        <Flex marginTop="15px">
          <CheckBox
            name="saveEmail"
            value={values.saveEmail}
            onChange={handleChange}
            label="Save this as my notification email address"
          />
        </Flex>
      </AlertModal>
    </>
  )
}

export default SendPaymentReceiptButton
