// @flow
import styled from '@emotion/styled'
import { Typography, TableContainer } from '@material-ui/core'
import { PaymentIcons } from '@icons'
import Button from '@Common/Button'

import Flex from '../../../Common/Flex'

export const HeaderStyled = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.space.s45};

  ${({ theme }) => [theme.breakpoints.down('lg')]} {
    padding-bottom: ${({ theme }) =>
      theme.ux.isSSO ? theme.space.s15 : theme.space.s30};
    font-weight: ${({ theme }) =>
      theme.ux.isSSO
        ? theme.custom.font.weight.regular
        : theme.custom.font.weight.bolder};
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding-bottom: 5%;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    padding-bottom: ${({ theme }) => theme.space.s20};
  }
`

export const AccountNameStyled = styled(Flex)`
  & > span {
    height: 16px;
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    line-height: 19px;
  }
  & > a {
    text-decoration: underline;
  }
`

export const MethodNameStyled = styled(Flex)`
  & > span {
    color: ${({ theme }) => theme.palette.common.grey};
    font-size: ${({ theme }) => theme.custom.font.size.md};
    height: 14px;
    line-height: 17px;
    min-width: 80px;
  }

  & > button {
    margin: 0;
    background-color: transparent;
  }
`

export const PaymentIconsStyled = styled(PaymentIcons)`
  min-width: 70px;
`

export const StyledButton = styled(Button)`
  height: 35px;
  width: 225px;

  background-color: ${({ theme }) =>
    theme.ux.isSSO ? theme.palette.common.white : undefined};

  &:last-of-type {
    margin-left: ${({ theme }) =>
      theme.ux.isSSO ? theme.space.s20 : `${theme.spacing(9)}px`};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.ux.isSSO ? theme.palette.common.white : undefined};
  }
`

/* only for SSO */
export const StyledTableContainer = styled(TableContainer)`
  max-height: 295px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.neutral.light};
  padding-left: 10px;
  padding-right: 15px;
  background-color: ${({ theme }) => theme.palette.background.default};
  box-shadow: ${({ theme }) => theme.custom.shadow};

  & .MuiTableHead-root {
    height: 55px;

    & .MuiTableCell-root {
      padding: 15px 0 0;
    }
  }
`
