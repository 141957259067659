export const GET_CONFIG = 'getAutopayConfig'
export const GET_AUTO_PAYS = 'getAutopays'
export const GET_CASH_PAY_CONFIG = 'getCashPayConfig'

export const getAutopaysQuery = () => {
  return `query($residentId: ID!) {
    ${GET_AUTO_PAYS}(id: $residentId) {
      variableAutopays {
        id
        type
        paymentMethod
        paymentMethodId
        paymentType
        feeAmount {
          amount
          currency
        }
        createdOn
        startDate
        endDate
        debitDay
        frequency
        maxLimit {
          amount
          currency
        }
        skipped
        nextDebitDate
      }
      fixedAutopays {
        id
        type
        paymentMethod
        paymentMethodId
        paymentType
        unitAmount {
          amount
          currency
        }
        feeAmount {
          amount
          currency
        }
        totalAmount {
          amount
          currency
        }
        createdOn
        startDate
        endDate
        debitDay
        frequency
        skipped
        nextDebitDate
      }
    }
  }`
}

export const getAutoPayConfigQuery = () => {
  return `{
        ${GET_CONFIG} {
          allowSkip
          allowCancel
          allowEdit
          allowIndefinite
          variableSettings {
            enabled
            startDay
            endDay
            allowMaxLimit
          }
          fixedSettings {
            enabled
            startDay
            endDay
          }
        }
      }`
}

export const getCashPayConfigQuery = () => {
  return `{
        ${GET_CASH_PAY_CONFIG} {
          enabled
          allowCashPay
          accountNumber
          allowOverPay
        }
      }`
}