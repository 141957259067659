// @flow
import React from 'react'
import { StyledTextInput, StyledTooltip, HelpIcon } from './styles'
import { InputAdornment, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'

type Props = {
  multiline?: boolean,
  className?: string,
  maskInput?: () => {},
  rowsMax?: number,
  InputProps?: Object,
  helperText?: string,
  helperTextPlacement?: string
}

const TextInput = ({
  multiline,
  className = '',
  maskInput,
  rowsMax,
  InputProps,
  helperText,
  helperTextPlacement = 'right-start',
  ...props
}: Props) => {
  const textAreaAttrs = {}
  if (multiline) {
    textAreaAttrs.rowsMax = rowsMax || 5
  }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <StyledTextInput
      {...props}
      {...textAreaAttrs}
      variant="outlined"
      multiline={multiline}
      className={`${multiline ? 'multiline' : ''} ${className}`}
      InputProps={{
        inputComponent: maskInput && maskInput,
        endAdornment: !isMobile && helperText && (
          <InputAdornment placement="end">
            <StyledTooltip
              classes={{ tooltip: `placement-${helperTextPlacement}` }}
              placement={helperTextPlacement}
              title={
                <React.Fragment>
                  {helperText}
                  <div className="arrow" x-placement={helperTextPlacement} />
                </React.Fragment>
              }>
              <HelpIcon />
            </StyledTooltip>
          </InputAdornment>
        ),
        ...InputProps
      }}
    />
  )
}

export default TextInput
