// @flow

import React from 'react'
import chunk from 'lodash/chunk'
import { withTheme } from 'emotion-theming'
import Card from '@Common/Card'
import Select from '@Common/Form/Select'
import TextInput from '@Common/Form/TextInput'
import { CommonIcon } from '@icons'
import Button, { FabButton } from '@Common/Button'
import Loader from '@Common/Loader'
import Flex from '@Common/Flex'
import { GridItem } from '@Common/Grid'
import { RightSelect, InputLabel } from './styles'
import { isValidImageFile, workOrderFileSizeLimit } from '@utils/validationUtils'
import CustomSwitch from '@Common/Switch/CustomSwitch'
import AlertModal from '@Common/AlertModal'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { formatDateMDYY } from 'zego-shared/utils/date'

import type { Theme } from '../../../theme'

type WOSettings = {
  images: boolean,
  options: {}
}

type Props = {
  hasData: boolean,
  isFetching: boolean,
  theme: Theme,
  woSettings: WOSettings,
  submitWorkOrder: (string, Object, Object, Object, Object) => mixed,
  isSubmitting: boolean,
  unitName: string
}

type State = {
  message: ?string,
  photos: ?Array<string>,
  photoDataUrl: ?Array<string>,
  photoUploadError: ?string
}

const getOptions = (kv, parentValue) => kv
  .filter(({ dependent_value }) => !dependent_value || parentValue === dependent_value)
  .map(({ id, name }) => ({ label: name, value: id }))

class WorkOrdersNew extends React.Component<Props, State> {
  state = {
    message: null,
    photos: [],
    photoDataUrl: [],
    photoUploadError: null
  }

  fileUploadRef = React.createRef()

  updateState = updates => this.setState(oldState => {
    return {
      ...oldState,
      ...updates
    }
  })

  handleSelect = ({ target: { name, value } }) => {
    this.updateState({ [name]: value })
  }

  handleCategory = (event) => {
    this.updateState({ subCategory: null })
    this.handleSelect(event)
  }

  handleChange = (
    { currentTarget: { id, value } }: SyntheticEvent<HTMLInputElement>
  ) => {
    this.updateState({ [id]: value })
  }

  handleToggleChange = (
    { currentTarget: { id } }: SyntheticEvent<HTMLInputElement>,
    value: boolean
  ) => {
    this.updateState({ [id]: value })
  }

  addPhoto = ({ target: { files } }) => {
    const image = files[0]

    if (!image) {
      return
    }

    if (this.state.photos.length === 4) {
      this.updateState({ photoUploadError: 'Oops! It looks like you are attempting to upload too many photos.' })
      return
    }

    if (isValidImageFile(image)) {
      if (!this.props.woSettings.multi_images) {
        this.updateState({ photos: [image], photoDataUrl: [URL.createObjectURL(image)] })
      } else {
        this.updateState({ photos: this.state.photos.concat(image), photoDataUrl: this.state.photoDataUrl.concat(URL.createObjectURL(image)) })
      }
    } else if (!image.name.match(/\.(jpg|jpeg|png|gif)$/i)) {
      this.updateState({ photoUploadError: 'Oops! It looks like you are attempting to upload a file that is not in a compatible format.  Please try uploading your file again in jpeg or png format.' })
    } else if (image.size > workOrderFileSizeLimit) {
      this.updateState({ photoUploadError: 'Oops! It looks like you are attempting to upload a file that is too large. Please try again uploading a smaller image.' })
    }
  }

  hidePhotoUploadErrorModal = () => {
    this.updateState({ photoUploadError: null })
  }

  deletePhoto = (index) => {
    this.state.photos.splice(index, 1)
    this.state.photoDataUrl.splice(index, 1)
    this.updateState({ photos: this.state.photos, photoDataUrl: this.state.photoDataUrl })
  }

  fileToBase64 = (file) => {
    return new Promise(resolve => {
      var reader = new FileReader()
      reader.onload = function (event) {
        // $FlowFixMe
        resolve(event.target.result)
      }

      reader.readAsDataURL(file)
    })
  }

  getOptionDependency = (option: Object, options: Array<Object>) => {
    const {
      option_name: name,
      next_level: nextLevel,
      dependent_on: dependentOn,
      dependent_value: dependentValue,
      option_required: required,
      option_display_name: displayName,
      option_type: controlType = 'select',
      option_default: defaultValue
    } = option

    const parentOption = dependentOn && options.find(o => o.next_level_name === dependentOn)
    const parentValue = parentOption && this.state[parentOption.option_name]
    const selectOptions = nextLevel && getOptions(nextLevel, parentValue).map(s => s.value)
    const controlVisible = !dependentOn
      || (dependentValue === String(parentValue))
      || (parentValue && !dependentValue && selectOptions.length)
    const value = name in this.state ? this.state[name] : defaultValue

    return {
      name,
      nextLevel,
      controlVisible,
      selectOptions,
      required,
      displayName,
      controlType,
      defaultValue,
      parentValue,
      value
    }
  }

  isValid = () => {
    const { woSettings: { options }, isSubmitting } = this.props
    const { message } = this.state

    if (isSubmitting) {
      return false
    }

    if (!message) {
      return false
    }

    for (const option of options) {
      const { value, controlVisible, selectOptions, required } = this.getOptionDependency(option, options)

      if (required && controlVisible && !value) {
        return false
      }

      if (required && controlVisible && selectOptions && !selectOptions.includes(value)) {
        return false
      }
    }

    return true
  }

  submitWorkOrder = async () => {
    const { submitWorkOrder, woSettings: { options: optionsDefinition } } = this.props
    const { message, photos, photoDataUrl, photoUploadError, ...options } = this.state

    const defaults = optionsDefinition
      .filter(item => item.option_default)
      .reduce((acc, item) => ({...acc, [item.option_name]: item.option_default}), {})

    submitWorkOrder(message, {...defaults, ...options}, photos)
  }

  render() {
    const { hasData, isFetching, woSettings, theme, unitName } = this.props
    if (!hasData) return null
    if (isFetching) {
      return <Loader />
    }
    const { photos, photoDataUrl, photoUploadError } = this.state
    const { images, multi_images: multiImages, options } = woSettings
    const woOptions = chunk(options, 2)

    return (
      <GridItem columnStart={5} columnSpan={8} rowStart={1} rowSpan={4}>
        <Flex direction='column'>
          <AlertModal
            showCloseButton
            show={photoUploadError || false}
            onClose={this.hidePhotoUploadErrorModal}>
            <div>{photoUploadError}</div>
          </AlertModal>
          <Card paddingBottom={theme.space.s45} marginBottom={theme.space.s75}>
            {
              woOptions.map((chunk, index) => (
                <Flex paddingBottom={theme.space.s45} paddingTop={theme.space.s30} key={index}>
                  {
                    chunk.map((option, index) => {
                      const {
                        name,
                        displayName,
                        value,
                        controlType,
                        controlVisible,
                        nextLevel,
                        parentValue
                      } = this.getOptionDependency(option, options)

                      switch (controlType) {
                        case 'select':
                          const Component = (index % 2 === 0) ? Select : RightSelect
                          const extraProps = (value == null ? {} : { initialValue: value })
                          return (
                            <Component
                              id={name}
                              name={name}
                              onChange={this.handleSelect}
                              key={name}
                              label={displayName}
                              placeholder='select'
                              options={getOptions(nextLevel, parentValue)}
                              style={{visibility: controlVisible ? 'visible' : 'hidden'}}
                              {...extraProps} />
                          )
                        case 'toggle':
                          return (
                            <div style={{
                              width: '20em',
                              visibility: controlVisible ? 'visible' : 'hidden'
                            }}>
                              <InputLabel>{displayName}</InputLabel>
                              <CustomSwitch
                                elementId={name}
                                key={name}
                                isOptedIn={value}
                                handleChange={this.handleToggleChange}
                                switchedOnText='Yes'
                                switchedOffText='No'
                              />
                            </div>
                          )
                        case 'text':
                          return (
                            <TextInput
                              key={name}
                              id={name}
                              onChange={this.handleChange}
                              label={displayName}
                              placeholder=''
                              style={{
                                marginTop: '7px',
                                visibility: controlVisible ? 'visible' : 'hidden'
                              }}
                              value={value}
                              multiline />
                          )
                        case 'unit':
                          return (<InputLabel paddingBottom>{displayName}: {unitName}</InputLabel>)
                        case 'date':
                          return (<InputLabel paddingBottom>{displayName}: {formatDateMDYY(new Date())}</InputLabel>)
                        default:
                          return null
                      }
                    })
                  }
                </Flex>
              ))
            }
            <TextInput
              id='message'
              onChange={this.handleChange}
              label='Description'
              placeholder=''
              multiline />
          </Card>
          {
            images && (
              <Card marginBottom={theme.space.s20}>
                <Flex justifySpaceBetween>
                  {
                    (photos.length === 0 ?
                      (<InputLabel style={{ paddingTop: '3%' }} >{multiImages ? 'Add Up to Four Photos (optional)' : 'Add a Photo (optional)'}</InputLabel>)
                      :
                      (<Flex>
                        {photoDataUrl.map((photoUrl, index) => (
                          <div style={{ position: 'relative' }} key={index}>
                            <IconButton style={{ position: 'absolute', bottom: '70px', left: '68px' }} onClick={() => { this.deletePhoto(index) }}>
                              <CloseIcon style={{ color: theme.palette.common.white }} />
                            </IconButton>
                            <img style={{ marginRight: theme.space.s45 }} width='100px' height='100px' src={photoUrl} alt='work order' />
                          </div>
                        ))}
                      </Flex>))
                  }
                  <label htmlFor='wo-file-upload'>
                    <FabButton
                      id='addWoDocBtn'
                      onClick={() => this.fileUploadRef.current.click()}
                      component={() => (
                        <CommonIcon
                          name='add'
                          width='2em'
                          height='2em'
                          fill={theme.palette.primary.main}
                          strokeWidth={4}
                          stroke={theme.palette.common.white}
                        />)}
                    />
                  </label>
                  <input id='addWoInput' onChange={this.addPhoto} ref={this.fileUploadRef} type='file' style={{ display: 'none' }} />
                </Flex>
              </Card>
            )
          }
          <Flex justifyRight>
            <Button long primary disabled={!this.isValid()} onClick={this.submitWorkOrder} id='woSubmitBtn'>Submit</Button>
          </Flex>
        </Flex >
      </GridItem >
    )
  }
}

export default withTheme(WorkOrdersNew)
