import gql from 'graphql-tag'

export const GET_COMPANY_MEMOS = 'getCompanyMemos'

export const getCompanyMemosQuery = gql`
  query($companyUuid: ID!) {
    ${GET_COMPANY_MEMOS}(id: $companyUuid) {
      type
      message
    }
  }
`