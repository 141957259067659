import gql from 'graphql-tag'

export default {
  PAYMENT_FIELD: gql`
    fragment PaymentField on PaymentField {
      key
      label
      money {
        currency
        amount
      }
      locked
      updatedDate
    }
  `,

  LINE_ITEM: gql`
    fragment LineItem on LineItem {
      label
      money {
        currency
        amount
      }
    }
  `
}
