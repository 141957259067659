// @flow

import React from 'react'
import { Route } from 'react-router-dom'
import OneTimePaymentRoutes from './OneTimePaymentRoutes'
import Methods from '../../views/Payments/Methods'
import Statements from '../../views/Payments/Statements'
import CreditReporting from '../../views/Payments/CreditReporting'
import History, { Transaction } from '../../views/Payments/History'
import AutoPay from '../../views/Payments/AutoPay'
import Summary from '../../views/Payments/Summary'

const getPaymentRoutes = (isCreditReportingEnabled: boolean) => [
  <Route
    key="methods"
    path="/payments/:residentId/methods"
    component={Methods}
  />,
  <Route
    key="transaction"
    path="/payments/:residentId/history/:transId"
    component={Transaction}
    exact
  />,
  <Route
    key="history"
    path="/payments/:residentId/history"
    component={History}
    exact
  />,
  <Route
    key="statements"
    path="/payments/:residentId/statements"
    component={Statements}
  />,
  isCreditReportingEnabled && (
    <Route
      key="credit-reporting"
      path="/payments/:residentId/credit-reporting"
      component={CreditReporting}
    />
  ),
  <Route exact key="paymentsRootSummary" path="/" component={Summary} />,
  <Route
    exact
    key="paymentsSummary"
    path="/payments/summary"
    component={Summary}
  />,
  <Route
    key="oneTimePayment"
    path="/payments/:residentId/one-time"
    component={OneTimePaymentRoutes}
  />,
  <Route
    key="autoPay"
    path="/payments/:residentId/auto-pay"
    component={AutoPay}
  />
]

export default getPaymentRoutes
