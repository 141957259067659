// @flow

import { connect } from 'react-redux'
import get from 'lodash/get'
import querystring from 'query-string'
import {
  getPaymentDetails,
  isBalanceZero,
  balanceToInteger,
  getRecipient,
  getResidentFullName,
  getPayload,
  getFraudToolkitError
} from 'zego-shared/store/payments/oneTimePayment/selectors'
import {
  getExpressPayFee,
  isPayPalSelected,
  checkHasPaymentMethod
} from 'zego-shared/store/payments/methods/selectors'
import {
  saveDetails,
  getPayPalPaymentAction,
  fetchOnetimePaymentData
} from 'zego-shared/store/payments/oneTimePayment/actions'
import { hasData } from 'zego-shared/store/payments/autopay/selectors'
import { fetchPaymentHistory } from 'zego-shared/store/payments/history/actions'
import { getPaymentState } from 'zego-shared/store/payments/summary/selectors'
import { fetchUserResource } from 'zego-shared/store/userResource/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { withFetchData } from 'zego-shared/views'
import { disableBalanceEditMode } from '@utils/view'
import OneTimePayment from './OneTimePayment'
import { balance, expressPayUpSell, paymentMethod } from './config'
import { showSnackbar } from 'zego-shared/store/snackbar/actions'
import { createHTTPHeader } from 'zego-shared/utils/api/utils'
import { getPaymentToken } from 'zego-shared/store/authorization/selectors'
import { payPalErrorMessage } from 'zego-shared/utils/messages'
import type { BrowserRouter } from 'react-router-dom'
import { savePaymentNotes } from 'zego-shared/store/payments/paymentNotes/actions'
import { saveSelectedMethodType } from 'zego-shared/store/payments/methods/actions'
import { getReauthItemByZegoAccountId } from 'zego-shared/store/payments/plaid/selectors'

const OneTimePaymentWithFetchData = withFetchData(OneTimePayment)

const fetchingSelector = createIsFetchingSelector([
  'onetimepayment',
  'property'
])
const getPayPalPaymentSelector = createIsFetchingSelector(['getpaypalpayment'])

export const getStartFlows = ({
  expressFee,
  lastPaymentMethod,
  isBalZero,
  editBal
}: {
  expressFee: string,
  lastPaymentMethod: Object,
  isBalZero: boolean,
  editBal?: string
}) => {
  if (isBalZero || editBal) {
    return 0
  }
  if (!expressFee) {
    return 1
  }
  if (!lastPaymentMethod) {
    return 1
  }
  return 2
}

export const setPaymentMethodsNextFlow = (
  expressFee: string,
  paymentMethod: Object
) => {
  if (!expressFee) {
    return { ...paymentMethod, nextFlow: undefined }
  }
  return paymentMethod
}

export const getEditBal = (location: Object) => {
  const { search } = location
  if (search) {
    const { edit_bal: editBal = '' } = querystring.parse(search)
    return editBal
  }
}

export const mapStateToProps = (state: Object, props: Object) => {
  const details = getPaymentDetails(state)
  const isBalZero = isBalanceZero(state)
  const editBal = getEditBal(props.location)
  const paymentMethodId = get(details, 'lastPaymentMethod.id')
  const expressFee = getExpressPayFee(
    state,
    get(details, 'lastPaymentMethod.typeName')
  )
  const flows = [
    disableBalanceEditMode(
      get(details, 'amount.fields', []),
      get(details, 'amount.optionalFields', []),
      balance
    ),
    setPaymentMethodsNextFlow(expressFee, paymentMethod),
    expressPayUpSell
  ]

  return {
    details,
    flows,
    expressFee,
    recipient: getRecipient(state),
    fullName: getResidentFullName(state),
    isFetching: fetchingSelector(state),
    balance: balanceToInteger(get(details, 'amount.total')),
    hasPaymentMethod: checkHasPaymentMethod(state),
    startFlow: getStartFlows({
      expressFee,
      lastPaymentMethod: get(details, 'lastPaymentMethod'),
      isBalZero,
      editBal
    }),
    paymentState: getPaymentState(state),
    isPayPal: isPayPalSelected(state),
    isFetchingPayPalPaymentMethod: getPayPalPaymentSelector(state),
    payPalPayLoad: {
      variables: getPayload(state),
      headers: createHTTPHeader(getPaymentToken(state))
    },
    hasAutopays: hasData(state),
    fraudToolkitError: getFraudToolkitError(state),
    itemForReauth: getReauthItemByZegoAccountId(state, paymentMethodId)
  }
}

export const mapDispatchToProps = (
  dispatch: Function,
  {
    match: {
      params: { residentId }
    },
    details
  }: BrowserRouter
) => {
  return {
    fetchData: () => {
      dispatch(fetchOnetimePaymentData(residentId))
      dispatch(fetchUserResource(residentId))
      dispatch(fetchPaymentHistory(residentId))
    },
    componentWillUnmount: () => {
      dispatch(saveDetails(null))
      dispatch(savePaymentNotes(''))
      dispatch(saveSelectedMethodType({}))
    },
    saveDetails: (details: Object) => dispatch(saveDetails(details)),
    createOrderErrorMsg: () =>
      dispatch(showSnackbar(payPalErrorMessage, 'error')),
    getPayPalPaymentMethod: (orderId: string, onSuccess: Function) =>
      dispatch(getPayPalPaymentAction({ orderId, onSuccess })),
    showMessageWithTooltip: (messagesWithTooltip: Object[]) =>
      dispatch(showSnackbar('', 'warning', messagesWithTooltip))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OneTimePaymentWithFetchData)
