// @flow

import { connect } from 'react-redux'
import withFetchData from 'zego-shared/views/withFetchData'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  fetchWorkOrderOptions,
  submitWorkOrder
} from 'zego-shared/store/workOrders/new/actions'
import {
  hasWorkOrderOptions,
  getWorkOrderOptions,
  isSubmittingWorkOrder
} from 'zego-shared/store/workOrders/new/selectors'
import { getUnitName } from 'zego-shared/store/select/selectors'
import type { BrowserRouter } from 'react-router-dom'
import WorkOrdersNew from './WorkOrdersNew'
const fetchingSelector = createIsFetchingSelector(['workorderoptions'])

export const mapStateToProps = (state: Object) => {
  return {
    isFetching: fetchingSelector(state),
    hasData: hasWorkOrderOptions(state),
    woSettings: getWorkOrderOptions(state),
    isSubmitting: isSubmittingWorkOrder(state),
    unitName: getUnitName(state)
  }
}

export const mapDispatchToProps = (
  dispatch: Function,
  { history, match: { url, params } }: BrowserRouter
) => {
  return {
    fetchData: () => {
      dispatch(fetchWorkOrderOptions())
    },
    submitWorkOrder: (message: string,
        options: string,
        file: Object) => {
      dispatch(submitWorkOrder(message, options, file, history))
    }
  }
}

const WorkOrdersNewWithFetchData = withFetchData(WorkOrdersNew)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkOrdersNewWithFetchData)
