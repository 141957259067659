// @flow
import styled from '@emotion/styled'
import theme from '@utils/../theme'

const paymentMethodBg = {
  BankAccount: theme.palette.common.iceberg,
  DebitCard: theme.palette.secondary.dark,
  CreditCard: theme.palette.primary.light,
  PayPal: theme.palette.common.iceberg
}

export const Root = styled('div')(
  ({ existing, scale = 1 }) => `
    height: ${60 * scale}px;
    width:${scale * (existing ? 96 : 86)}px;
    
    & + div {
      margin-left: ${theme.space.s40};

      ${theme.breakpoints.down('md')} {
        margin-left: ${theme.space.s20};
      }
    }
  `
)

export const Wrapper = styled('div')(
  ({ theme, existing, type, reverse, scale, selected, onClick }) => `
    display: flex;
    flex-direction: ${reverse ? 'column-reverse' : 'column'};
    justify-content: space-between;
    padding: ${theme.space.s4} ${theme.space.s4};
    background-color: ${existing ? paymentMethodBg[type] : 'white'};
    border-radius: 4px;
    height: 60px;
    ${onClick ? 'cursor: pointer' : ''};
    width: ${existing ? 96 : 86}px;
    
    transform-origin: top left;
    transform: scale(${scale});
    border: ${selected ? 1 : 0}px solid ${theme.palette.tertiary.main};

    box-shadow: 0px 0.638592px 0.638592px rgba(186, 186, 186, 0.1), 0px 0.638592px 2.55437px rgba(14, 20, 39, 0.25);
  `
)

export const Name = styled('h4')(
  ({ theme, white }) => `
    margin: 0;
    line-height: 1;
    white-space: nowrap;
    font-size:  ${theme.custom.font.size.md};
    font-weight: ${theme.custom.font.weight.regular};
    color: ${white ? 'white' : theme.palette.primary.deep};
  `
)

export const LightText = styled('span')(
  ({ theme }) => `
        font-weight: ${theme.custom.font.weight.light};
    `
)

export const Nickname = styled('div')(
  ({ theme, white, reverse, scale = 1 }) => `
    margin: ${reverse ? '0 0 auto' : 'auto 0 0'};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size:  ${theme.custom.font.size.xs};
    font-weight: ${theme.custom.font.weight.light};
    color: ${white ? 'white' : theme.palette.primary.deep};
    max-width: 60%;
    font-size: ${10 / scale}px;
  `
)
