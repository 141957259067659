import styled from '@emotion/styled'
import MaterialFormControl from '@material-ui/core/FormControl'
import MaterialInputLabel from '@material-ui/core/InputLabel'
import MaterialMenuItem from '@material-ui/core/MenuItem'

export const FormControl = styled(MaterialFormControl)`
  min-width: 100px;
  ${({ width }) =>
    width &&
    `
  width: ${width};
  `};

  *:focus {
    background: none;
  }
`

export const InputLabel = styled(MaterialInputLabel)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const MenuItem = styled(MaterialMenuItem)`
  font-size: ${({ theme }) => theme.custom.font.size.sm};

  &:hover {
    background-color: ${({ theme }) => theme.palette.neutral.light};
  }

  &.Mui-selected {
    background-color: ${({ theme }) => theme.palette.common.iceberg};

    &:hover {
      background-color: ${({ theme }) => theme.palette.neutral.light};
    }
  }
`
