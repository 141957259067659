// @flow
import React from 'react'
import Flex from '@Common/Flex'
import { formatDateMMDDYY } from 'zego-shared/utils/date'
import {
  DialogStyled,
  DialogTitleStyled,
  StyledButton,
  ModalText,
  Bold,
  ModalInner,
  ReviewItemTitle,
  ReviewItemValue,
  ReviewItem
} from './styles'
type Props = {
  show: boolean,
  backToEdit: Function,
  values: Object,
  submitLCForm: Function
}

const optinModal = ({
  show = false,
  backToEdit,
  values,
  submitLCForm
}: Props) => (
  <DialogStyled
    classes={{
      paper: 'dialog-paper'
    }}
    open={show}
    maxWidth={false}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <Flex justifyCenter>
      <ModalInner>
        <Flex direction="column">
          <DialogTitleStyled>Confirm Your Submission</DialogTitleStyled>
          <ModalText>
            Please double check your entries to ensure the information you wish
            to submit is correct.{' '}
            <Bold>
              You will not be able to go back and edit this information after
              this point.
            </Bold>
          </ModalText>
        </Flex>
        <Flex justifyCenter direction="column">
          <ReviewItem>
            <ReviewItemTitle>Name</ReviewItemTitle>
            <ReviewItemValue>
              {values.firstName} {values.lastName}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemTitle>Birthdate</ReviewItemTitle>
            <ReviewItemValue>
              {formatDateMMDDYY(values.birthDate)}
            </ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemTitle>Email</ReviewItemTitle>
            <ReviewItemValue>{values.email}</ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemTitle>SSN</ReviewItemTitle>
            <ReviewItemValue>{values.ssn}</ReviewItemValue>
          </ReviewItem>
          <ReviewItem>
            <ReviewItemTitle>Address</ReviewItemTitle>
            <ReviewItemValue>
              {values.address}, {values.address2}, {values.postalCode},{' '}
              {values.city}, {values.state}
            </ReviewItemValue>
          </ReviewItem>
        </Flex>
        <Flex justifySpaceBetween direction="column">
          <Flex justifyCenter>
            <StyledButton
              id="optin-modal-edit-button"
              outline
              primary
              rightMargin
              onClick={backToEdit}>
              Edit
            </StyledButton>
            <StyledButton
              id="optin-modal-save-button"
              primary
              onClick={submitLCForm}>
              Save
            </StyledButton>
          </Flex>
        </Flex>
      </ModalInner>
    </Flex>
  </DialogStyled>
)

export default optinModal
