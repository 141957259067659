import { connect } from 'react-redux'
import { withFetchData } from 'zego-shared/views'
import { fetchStatements } from 'zego-shared/store/payments/statements/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { getUserId } from 'zego-shared/store/authorization/selectors'
import { hasStatements } from 'zego-shared/store/payments/statements/selectors'
import { nativePayerWithoutPayment } from 'zego-shared/store/settings/selectors'
import {
  isLevelCreditReportingEnabled,
  getIsOptIn
} from 'zego-shared/store/payments/creditReporting/selectors'
import {
  getDisableAutopayMenuTab,
  getDisableMakePaymentMenuTab,
  getDisableSummaryMenuTab
} from 'zego-shared/store/pmSettings/selectors'
import { getAutoPays } from 'zego-shared/store/payments/autopay/selectors'
import { fetchCreditReportingConfig } from 'zego-shared/store/payments/creditReporting/actions'
import { fetchAutoPaysAction, fetchCashPayConfigAction } from 'zego-shared/store/payments/autopay/actions'
import { fetchPaymentOptions } from 'zego-shared/store/payments/options/actions'
import { fetchPaymentMethods } from 'zego-shared/store/payments/methods/actions'
import Payments from './Payments'
import { fetchPmSettingsAction } from 'zego-shared/store/pmSettings/actions'

const fetchingSelector = createIsFetchingSelector([
  'statementssection',
  'creditreporting'
])

const PaymentsWithFetchData = withFetchData(Payments)

export const mapStateToProps = state => ({
  residentId: getUserId(state),
  hasStatements: hasStatements(state),
  isFetching: fetchingSelector(state),
  nativePayerWithoutPayment: nativePayerWithoutPayment(state),
  isLevelCreditEnabled: isLevelCreditReportingEnabled(state),
  isLegacyOptedIn: getIsOptIn(state),
  disableAutopayMenuTab: getDisableAutopayMenuTab(state),
  disableMakePaymentMenuTab: getDisableMakePaymentMenuTab(state),
  disableSummaryMenuTab: getDisableSummaryMenuTab(state),
  autopays: getAutoPays(state)
})

export const mapDispatchToProps = dispatch => ({
  fetchData: ({ residentId }) => {
    dispatch(fetchAutoPaysAction())
    dispatch(fetchCashPayConfigAction())
    dispatch(fetchPaymentMethods(residentId))
    dispatch(fetchPaymentOptions())
    dispatch(fetchStatements(residentId))
    dispatch(fetchCreditReportingConfig())
    dispatch(fetchPmSettingsAction())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsWithFetchData)
