// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import { Link } from 'react-router-dom'
import EnrollDesktop from '@images/credit_reporting/enroll_desktop.png'
import ReactivateDesktop from '@images/credit_reporting/reactivate_bg.png'
import ReactivateBtnBG from '@images/credit_reporting/reactivate_btn_bg.png'
import SignUpBG from '@images/credit_reporting/sign_up_bg.png'
import SignUpBGTab from '@images/credit_reporting/sign_up_bg_tab.png'
import SignUpBGMob from '@images/credit_reporting/sign_up_bg_mob.png'
import EnrollTab from '@images/credit_reporting/enroll _tablet.png'
import ReactivateTab from '@images/credit_reporting/reactivate_bg_tab.png'
import EnrollMobile from '@images/credit_reporting/enroll_mobile.png'
import ReactivateMobile from '@images/credit_reporting/reactivate_bg_mob.png'

export const Button = styled.div`
  width: ${({ reactivate }) => (reactivate ? '175px' : '150px')};
  height: 50px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  padding: ${({ reactivate }) => (reactivate ? '6px 10px 6px 0' : '6px 0')};
  margin-top: ${({ theme }) => theme.space.s37};
  z-index: 0;

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    transform: scale(0.607);
    transform-origin: top left; `}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    ${({ theme, reactivate }) =>
      !reactivate
        ? `
      margin-right: ${theme.space.s30};
      margin-top: ${theme.space.s17};
      font-size: ${theme.custom.font.size.H5};
      width: 113px;
      height: 40px;
    `
        : `
      margin-top: ${theme.space.s25};
    `}
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    ${({ theme, reactivate }) =>
      !reactivate &&
      `
      margin-right: ${theme.space.s27};
      margin-top: ${theme.space.s15};
      width: 91px;
      height: 39px;
    `}
  }

  &:before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    top: 0;
    left: 0;
    height: 48px;
    width: 0;
    transition: 0.25s width;
    z-index: -1;

    ${({ theme }) => [theme.breakpoints.down('md')]} {
      ${({ reactivate }) =>
        !reactivate &&
        `
        height: 40px;
      `}
    }
    ${({ theme }) => [theme.breakpoints.down('xs')]} {
      ${({ reactivate }) =>
        !reactivate &&
        `
        height: 39px;
      `}
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background-image: ${({ reactivate }) =>
      reactivate ? `url('${ReactivateBtnBG}')` : `url('${SignUpBG}')`};
    background-size: 100% 100%;
    z-index: 1;

    ${({ theme }) => [theme.breakpoints.down('md')]} {
      ${({ reactivate }) =>
        !reactivate &&
        `
        background-image: url('${SignUpBGTab}');
        height: 40px;
      `}
    }
    ${({ theme }) => [theme.breakpoints.down('xs')]} {
      ${({ reactivate }) =>
        !reactivate &&
        `
        background-image: url('${SignUpBGMob}');
        height: 39px;
      `}
    }
  }
`

export const Ad = styled(Link)`
  text-decoration: none;
  display: block;
  background-image: ${({ reactivate }) =>
    reactivate ? `url(${ReactivateDesktop})` : `url(${EnrollDesktop})`};
  background-size: 100% 100%;
  width: ${({ theme }) => (theme.ux.isSSO ? '170px' : '282px')};
  height: ${({ theme }) => (theme.ux.isSSO ? '320px' : '547px')};
  z-index: 1;
  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    margin-top: 20px;  
    margin-bottom: 0px;  `}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    background-image: ${({ reactivate }) =>
      reactivate ? `url(${ReactivateTab})` : `url(${EnrollTab})`};
    width: 100%;
    height: 210px;
    max-width: 451px;
    max-height: 218px;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    background-image: ${({ reactivate }) =>
      reactivate ? `url(${ReactivateMobile})` : `url(${EnrollMobile})`};
    max-width: 373px;
  }
`

export const ContentWrap = styled(Flex)`
  padding-top: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s120 : theme.space.s223};
  margin-left: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s20 : theme.space.s37};
  display: flex;
  flex-direction: column;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    padding-top: ${({ theme, reactivate }) =>
      reactivate ? theme.space.s10 : theme.space.s57};
    margin-left: ${({ theme }) => theme.space.s28};
    display: flex;
    flex-direction: ${({ reactivate }) => (reactivate ? 'column' : 'row')};
    justify-content: space-between;
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    padding-top: ${({ theme, reactivate }) =>
      reactivate ? theme.space.s16 : theme.space.s55};
    margin-left: ${({ theme }) => theme.space.s25};
  }

  &:hover ${Button}:before, &:focus ${Button}:before {
    width: 100%;
  }
`

export const Title = styled('div')`
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? '21px' : theme.custom.font.size.H3};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: ${({ reactivate }) => (reactivate ? '32px' : '24px')};
  z-index: 1;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    ${({ theme, reactivate }) =>
      reactivate &&
      `
      font-size: 32px;
      margin-top: ${theme.space.s10};
    `}
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme, reactivate }) =>
      reactivate ? '32px' : theme.custom.font.size.H28};
  }
`

export const SubTitle = styled('div')`
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.H6 : theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.normal};
  ${({ reactivate, theme }) =>
    reactivate &&
    `
    line-height: 22px;
    margin-top: ${theme.space.s16};
  `}

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-top: ${({ theme }) => theme.space.s5};
    ${({ reactivate }) =>
      reactivate &&
      `
      font-size: 16px;
    `}
  }

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
  }
`

export const TitleUnderline = styled.div`
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    height: 7px;
    width: 82px;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    position: relative;
    left: 10px;
    z-index: -1;
  }

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    height: 6px;
    width: 64px;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    position: relative;
    left: 8px;
    z-index: -1;
    bottom: 3px;
  }
`

export const ShowForMdUp = styled('div')`
  display: block;
  ${({ zIndex }) => zIndex && 'z-index: 1'};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: none;
  }
`

export const ShowForMdDown = styled('div')`
  display: none;
  ${({ zIndex }) => zIndex && 'z-index: 1'};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    display: block;
  }
`
