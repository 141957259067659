// @flow
import styled from '@emotion/styled'
import lease_renewal_desktop from '@images/lease_renewal_desktop.png'

export const LoginStyledDesktop = styled('div')`
  min-height: 100vh;
  ${({ theme }) => [theme.breakpoints.up('md')]} {
    background: ${({ theme }) => theme.palette.common.white}
      url(${lease_renewal_desktop}) center bottom no-repeat fixed;
    background-size: 100%;
  }
`
export const Heading = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: ${({ theme }) => theme.space.s26};
  margin-top: ${({ theme }) => theme.space.s40};
  @media only screen and (max-width: 540px)  {
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    line-height: ${({ theme }) => theme.space.s20};
  }
`
export const SubHeading = styled('div')`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  line-height: ${({ theme }) => theme.space.s26};
  color: ${({ theme }) => theme.palette.neutral.deep};
  @media only screen and (max-width: 540px)  {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    line-height: ${({ theme }) => theme.space.s14};
  }
`
export const PayLeaseLogoStyled = styled.img`
  width: 245px;
  margin-left: ${({ theme }) => theme.space.s57};
  object-fit: contain;
  align-self: center;
  margin: auto;
  display: block;
`
export const LeaseRenewalMobile = styled.img`
  width: 100%;
  margin-left: ${({ theme }) => theme.space.s57};
  object-fit: contain;
  align-self: center;
  display: block;
`
export const LeaseRenewalBox = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: ${({ theme }) => theme.space.s100} auto;
  font-family: acumin-pro, sans-serif;
  font-style: normal;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
  @media only screen and (max-width: 540px)  {
    font-size: ${({ theme }) => theme.custom.font.size.sm};
    margin: ${({ theme }) => theme.space.s35} auto;
  }
`
export const MobileContainer = styled('div')`
  margin: ${({ theme }) => theme.space.s75} auto;
  font-family: acumin-pro, sans-serif;
  font-style: normal;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
`

export const FooterHeading = styled('div')`
@media only screen and (max-width: 540px)  {
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  line-height: ${({ theme }) => theme.space.s22};
  letter-spacing: 0px;
  text-align: right;
  margin-top: ${({ theme }) => theme.space.s25};
  margin-right: ${({ theme }) => theme.space.s30};
}
`
export const FooterSubHeading = styled('div')`
@media only screen and (max-width: 540px)  {
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  line-height: ${({ theme }) => theme.space.s14};
  text-align: right;
  margin-right: ${({ theme }) => theme.space.s30};
  margin-left: ${({ theme }) => theme.space.s75};
  color: ${({ theme }) => theme.palette.neutral.deep};
}
`

export const ActionBox = styled('li')`
@media only screen and (max-width: 540px)  {
  width: 303px;
  height: ${({ theme }) => theme.space.s60};
  margin-top: ${({ theme }) => theme.space.s10};
  float: right;
  margin-right: ${({ theme }) => theme.space.s30};
  background: ${({ theme }) => theme.palette.common.iceberg};
  border-radius: ${({ theme }) => theme.space.s4};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  line-height: ${({ theme }) => theme.space.s19};
  padding: ${({ theme }) => theme.space.s20};
}
`
