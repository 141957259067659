import withFetch from '../../../helpers/reducers/withFetch'
import {
  STATEMENTS_SECTION
} from '../actions'

const statements = (state = null, { type, payload }) => {
  switch (type) {
    default:
      return state
  }
}

export default withFetch(STATEMENTS_SECTION)(statements)
