import React, { useState } from 'react'
import { withTheme } from 'emotion-theming'
import StarRatingComponent from 'react-star-rating-component';
import Flex from '@Common/Flex'
import CloseIcon from '@material-ui/icons/Close'
import { Dialog } from '@material-ui/core';

import { 
  StyledDismiss,
  StyledSubmit, 
  StyledContent,
  StyledTextInput,
  StyledStarIcon,
  StyledSurveyMsg,
  SurveyMessageStyles,
  SurveyInput,
  SurveyInputStyles,
  RatingLabel, 
  modalStyles
} from './styles'

const SurveyModal = (props) => {
  const { 
    submitSurveyResponse, 
    dismissNotification,
    surveyId, 
    surveyMessage, 
    setShowModal, 
    theme 
  } = props

  const surveySubtitle = "Thanks for your feedback!";

  const [feedback, setFeedback] = useState('')
  const [hoverRating, setHoverRating] = useState(0)
  const [rating, setRating] = useState(0)
  const [step, setStep] = useState(1)

  const handleClose = () => {
    setStep(1)
    setShowModal(false)
  }

  const handleStarChoice = (nextValue, prevValue, name) => {
    setRating(nextValue)
  }

  const handleStarHover = (nextValue, prevValue, name) => {
    setHoverRating(nextValue)
  }

  const handleStarHoverOut = (nextValue, prevValue, name) => {
    setHoverRating(rating)
  }

  const handleSubmit = () => {
    if (step === 2) {
      submitSurveyResponse(surveyId, rating, feedback)
    }
    else if (step === 1) {
      setStep(2)
    }
  }

  const modalContent = () => {
    if (step === 1) {
      return(
        <> 
        <StyledSurveyMsg>
          {surveyMessage} 
        </StyledSurveyMsg>
        <div style={SurveyMessageStyles.subtitleMsg}>
          {surveySubtitle} 
        </div>

        <SurveyInput style={SurveyInputStyles.step1}>
          <StarRatingComponent 
            starCount={10} 
            value={hoverRating} 
            onStarClick={handleStarChoice} 
            onStarHover={handleStarHover}
            onStarHoverOut={handleStarHoverOut}
            name={"rating"}
            starColor={theme.palette.alternative.purple}
            emptyStarColor={"transparent"}
            renderStarIcon={() =>  ( <StyledStarIcon name="star-rating" />  )} 
          />
          
          <RatingLabel>
            <Flex>Unhappy</Flex>
            <Flex>Happy</Flex>
          </RatingLabel>

        </SurveyInput>
        </>
      )
    } 
    else if (step === 2) {
      return(
      <>
        <div style={SurveyMessageStyles.thankYouMsg}>
          Thank You! 
        </div>
        <div style={SurveyMessageStyles.center}>
          Please let us know anything that may have affected your score
        </div>

        <SurveyInput style={SurveyInputStyles.step2}>
          <StyledTextInput
              id="survey-feedback"
              onChange={event => {setFeedback(event.target.value)}}
              rows={8}
              placeholder="We value your feedback! Write your comments here."
              multiline
              variant={'outlined'}
            /> 
          <br />
        </SurveyInput>
      </>
      )
    }

  }

  const btnLabel = step === 1 ? 'Submit' : 'Done'

  const handleDismiss = () => {
    setShowModal(false)
    dismissNotification(surveyId)
  }

  return(
    <Dialog 
      onClose={handleClose}
      open={true}
      fullWidth
      PaperProps={{style: modalStyles.root}}
      maxWidth="md">

      <StyledDismiss 
        disableRipple 
        name="Dismiss" 
        onClick={handleDismiss}
        endIcon={<CloseIcon /> }>
      </StyledDismiss>

      <StyledContent>
        {modalContent()}
        <StyledSubmit name={btnLabel} onClick={handleSubmit} />
      </StyledContent>
    
    </Dialog>
  )

}

export default withTheme(SurveyModal)
