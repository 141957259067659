import {
  createFetchActions,
  createUpdateActions
} from '../../helpers/actions/creators'

export const TERMS_AND_CONDITIONS = 'TERMSANDCONDITIONS'
export const DO_NOT_SHOW_TERMS_AND_CONDITIONS =
  'DO_NOT_SHOW_TERMS_AND_CONDITIONS'

export const {
  fetchAction: fetchTermsAndConditionsAction,
  fetchStartAction: fetchTermsAndConditionsStart,
  fetchSuccessAction: fetchTermsAndConditionsSuccess,
  fetchErrorAction: fetchTermsAndConditionsError
} = createFetchActions(TERMS_AND_CONDITIONS)

export const {
  updateAction: acceptTermsAndConditionsAction,
  updateSuccessAction: acceptTermsAndConditionsSuccess,
  updateErrorAction: acceptTermsAndConditionsError
} = createUpdateActions(TERMS_AND_CONDITIONS)

export const hideTandCfromAdmin = () => ({
  type: DO_NOT_SHOW_TERMS_AND_CONDITIONS
})
