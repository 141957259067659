// @flow
import React from 'react'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { withTheme } from 'emotion-theming'
import { useDispatch } from 'react-redux'
import {
  loaderMounted,
  loaderUnmounted
} from 'zego-shared/store/loader/actions'

import { Loading } from './styles'

type Props = {
  color?: string,
  fullScreen?: boolean,
  theme: Object
}

const Loader = (props: Props) => {
  const { theme, color = theme.palette.primary.dark, fullScreen = true } = props
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(loaderMounted())
    return () => dispatch(loaderUnmounted())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (fullScreen) {
    return (
      <Loading>
        <PropagateLoader color={color} />
      </Loading>
    )
  }
  return <PropagateLoader color={color} />
}

export default withTheme(Loader)
