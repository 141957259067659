import { string, object, date } from 'yup'

export const paymentRemindersSchema = object().shape({
  email: string()
    .trim()
    .email('Invalid Email Address'),
  altEmail: string()
    .trim()
    .email('Invalid Email Address'),
  mobile: string().min(14, 'Invalid Phone Number'),
  altMobile: string().min(14, 'Invalid Phone Number'),
  dateToSend: date()
    .required('Delivery Start Date Is Required')
    .typeError('Delivery Start Date Is Invalid')

})
