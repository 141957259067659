import { call, select, takeLatest, put, delay } from 'redux-saga/effects'
import { getToken, getUnitUuId, getPaymentToken} from '../../../authorization/selectors'
import { getPropertyId } from '../../../select/selectors'
import {
  fetchUnits as fetchUnitsAction,
  fetchUnitsStart,
  fetchUnitsError,
  fetchUnitsSuccess,
  fetchUnit as fetchUnitAction,
  fetchUnitStart,
  fetchUnitError,
  fetchUnitSuccess,
  refreshUnit as refreshUnitAction,
  refreshUnitStart,
  refreshUnitError,
  refreshUnitSuccess,
  fetchUnitInfoStart,
  fetchUnitInfoSuccess,
  fetchUnitInfoError,
  fetchActionUnitInfo
} from '../actions'
import { getUnitId } from '../selectors'
import { fetchPropertyAddressAction } from '../../actions'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import { print } from 'graphql'
import { getGraphQlData } from '../../../../utils/graphql'
import { noUnitSelected } from '../../../../utils/messages'
import { getUnitUuIdQuery, GET_UNIT } from '../graphql/queries'

export const getActions = isRefreshing => ({
  start: isRefreshing ? refreshUnitStart : fetchUnitStart,
  success: isRefreshing ? refreshUnitSuccess : fetchUnitSuccess,
  error: isRefreshing ? refreshUnitError : fetchUnitError
})

export function* getDelay(time) {
  yield delay(time)
}

export function* fetchUnits({ name }) {
  if (name === '') {
    log('name is empty')
    return
  }

  // go ahead and notify fetch start
  // so UI can show loading
  yield put(fetchUnitsStart())

  // debounce by 400ms
  yield call(getDelay, 400)

  try {
    const authToken = yield select(getToken)
    // TODO get property id from somewhere else after implementing property switching
    const propertyId = yield select(getPropertyId)
    const params = { propertyId, name }
    const response = yield call(api.getUnits, authToken, params)

    yield put(fetchUnitsSuccess(response))
  } catch (error) {
    yield put(fetchUnitsError(error))
    log(`Failed to fetch units. Error: ${error}`)
  }
}

export function* fetchUnitInfo() {
  yield put(fetchUnitInfoStart())
  
  try {
    const authToken = yield select(getToken)
    const unitUuid = yield select(getUnitUuId)
    const paymentToken = yield select(getPaymentToken)
    const tokens = { authToken, paymentToken }
    const response = yield call(
      api.graphqlQuery,
      tokens,
      print(getUnitUuIdQuery),
      { unitUuid }
    )
    const payload = yield call(getGraphQlData, response, GET_UNIT)
    const propertyUuId = payload.property.id
  
    yield put(fetchUnitInfoSuccess(propertyUuId))
    yield put(fetchPropertyAddressAction())
  } catch (error) {
    yield put(fetchUnitInfoError(error))
    log(`Failed to fetch unit info. Error: ${error}`)
  }
}

export function* getUnit({ id, isRefreshing }) {
  const actions = getActions(isRefreshing)
  try {
    const unitId = id ? id : yield select(getUnitId)
    const authToken = yield select(getToken)

    if (unitId === -1) throw new Error(noUnitSelected)

    yield put(actions.start())
    const response = yield call(api.getUnit, unitId, authToken)
    yield put(actions.success(response))
  } catch (error) {
    log(`Failed to refresh unit. Error: ${error}`)
    yield put(actions.error(error))
  }
}

export function* watchFetchUnits() {
  yield takeLatest(fetchUnitsAction().type, fetchUnits)
}

export function* watchFetchUnitInfo() {
  yield takeLatest(fetchActionUnitInfo().type, fetchUnitInfo)
}

export function* watchFetchUnit() {
  yield takeLatest(fetchUnitAction().type, getUnit)
}

export function* watchRefreshUnit() {
  yield takeLatest(refreshUnitAction().type, getUnit)
}

export default [watchFetchUnitInfo(), watchFetchUnits(), watchFetchUnit(), watchRefreshUnit()]
