// @flow
import React from 'react'
import { Grid } from '@material-ui/core'
import { useLocation } from "react-router-dom";
import Flex from '@Common/Flex'
import { PaymentInfo, StyledButton, InfoLane } from '../styles'
import {
  LastPaymentStyled,
  ButtonsStyled,
  BolderText,
  BoldText,
  InfoContainerStyled
} from './styles'

type Props = {
  goToPaymentDetails: Function,
  lastPayment: Object,
  goToOneTime: Function,
  disableMakePaymentMenuTab: boolean
}

const LastPayment = ({ goToPaymentDetails, lastPayment, goToOneTime, disableMakePaymentMenuTab }: Props) => {
  const location = useLocation()

  const { account, amount, date, type, status, transId } = lastPayment

  const onClickDetails = () => {
    const state = { from: location.pathname }
    goToPaymentDetails(transId, state)
  }

  const renderHeader = () => {
    return (
      <InfoLane fullWidth>
        <PaymentInfo primary isText lineHeight="40px">
          <span>Last Payment</span>
        </PaymentInfo>
      </InfoLane>
    )
  }

  const renderInfo = () => {
    return (
      <InfoContainerStyled
        justify="space-between"
        container>
        <Grid item>
          <BolderText><span>{amount}</span></BolderText>
          <BoldText><span>{account}</span></BoldText>
          <div><span>{type} Payment</span></div>
        </Grid>
        <Grid item>
          <Flex alignEnd direction="column">
            <BolderText><span>{date}</span></BolderText>
            <BoldText><span>{status}</span></BoldText>
          </Flex>
        </Grid>
      </InfoContainerStyled>
    )
  }

  const renderButtons = () => {
    return (
      <Grid component={ButtonsStyled}>
        <Grid container justify="space-between">
          <Grid xs={12} sm={6} md={12} lg={3} xl={4} item>
            <StyledButton
              id="detailsBtn"
              fullWidth
              outline
              primary
              onClick={onClickDetails}>
              Details
            </StyledButton>
          </Grid>
          <Grid xs={12} sm={5} md={12} lg={8} xl={7} item>
            {!disableMakePaymentMenuTab && (
              <StyledButton
                id="makePaymentBtn"
                fullWidth
                primary
                onClick={goToOneTime}
              >
                Make Another Payment
              </StyledButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <LastPaymentStyled justifySpaceBetween>
      <div>
        {renderHeader()}
        {renderInfo()}
      </div>
      {renderButtons()}
    </LastPaymentStyled>
  )
}

export default LastPayment
