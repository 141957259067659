// @flow
import styled from '@emotion/styled'
import Card from '@Common/Card'
import Flex from '@Common/Flex'
import { IconButtonStyled as IconButton } from '../../styles'

export const AmountStyled = styled(Card)`
  min-height: 171px;
`

export const DescriptionStyled = styled(Flex)`
  padding-right: ${({ theme }) => (theme.ux.isSSO ? '2em' : '1em')};

  & h5 {
    height: 18px;
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H5};
    line-height: 22px;
    margin: 0 0 22px;
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
    text-transform: capitalize;
  }

  & p {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
    line-height: 18px;
    margin: 0 0 1em;
    flex: 1;
  }

  & button {
    padding: 0;
    max-width: fit-content;
    text-transform: capitalize;
  }
`
export const AmountValueStyled = styled(Flex)`
  height: 100%;
  min-height: 96px;
  border-left: ${({ theme }) => `1px solid ${theme.palette.neutral.main}`};
  padding-left: 1em;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    margin-top: 2em;
    border-left: 0;
    border-top: ${({ theme }) => `1px solid ${theme.palette.neutral.main}`};
  }

  & > span {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    line-height: 29px;
    text-align: right;
    margin-right: 24px;
    text-transform: capitalize;
  }

  & > div {
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: ${({ theme, fullBal }) =>
      fullBal ? theme.custom.font.size.H4 : theme.custom.font.size.H3};
    font-weight: ${({ theme }) =>
      theme.ux.isSSO
        ? theme.custom.font.regular
        : theme.custom.font.weight.bolder};
    line-height: 1;
    text-align: center;
    flex: 1;
  }

  & input.MuiInputBase-input {
    height: 34.29px;
    width: 149px;
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    & > div {
      font-size: ${({ theme, fullBal }) =>
        fullBal ? theme.custom.font.size.H5 : theme.custom.font.size.H4};
    }
  }
`

export const IconButtonStyled = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CancelBtnStyled = styled(Flex)`
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    position: relative;
    bottom: -10px;
  }

  & button {
    padding: 0;
    margin-top: 0;
    min-width: 0;
  }
`
