import { combineReducers } from 'redux'
import { PAYMENTS_SUMMARY, NEXT_AUTOPAY } from '../actions'
import withFetch from '../../../helpers/reducers/withFetch'

const paymentsSummary = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default combineReducers({
  paymentState: withFetch(PAYMENTS_SUMMARY)(paymentsSummary),
  nextAutopay: withFetch(NEXT_AUTOPAY)(paymentsSummary)
})
