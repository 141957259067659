// @flow
import styled from '@emotion/styled'

import Card from '@Common/Card'

export const PaymentCardStyled = styled(Card)`
  height: 54px;
  padding: ${({ theme }) => `0 ${theme.space.s15} 0 ${theme.space.s20}`};
  margin-bottom: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s12 : theme.space.s20};

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin-bottom: ${({ theme }) => theme.space.s15};
  }

  & .MuiCardContent-root {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  & .MuiCardContent-root:last-child {
    padding: 0;
  }

  & p {
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
    line-height: 19px;
    text-transform: capitalize;
    margin: 0;

    & b {
      font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
      line-height: 19px;
      margin: 0 5px;
    }
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    & p {
      font-size: ${({ theme }) => theme.custom.font.size.sm};
    }
  }
`
