// @flow
import React from 'react'
import { withTheme } from 'emotion-theming'
import { CommonIcon } from '@icons'
import { StyledDelete } from './styles'

type Props = {
  onClick?: () => void,
  theme: Object,
}

const Delete = ({ onClick, theme }: Props) => {
  return (
    <StyledDelete>
      <CommonIcon
        name="close"
        height="20px"
        width="20px"
        fill={theme.palette.error.main}
        onClick={onClick ? onClick : null}
      />
    </StyledDelete>
  )
}

export default withTheme(Delete)
