import { select, put, call, takeEvery } from 'redux-saga/effects'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'
import { getToken } from '../../../authorization/selectors'
import {
  fetchSSOPaymentStart,
  fetchSSOPaymentSuccess,
  fetchSSOPaymentError,
  fetchSSOPayment as fetchSSOPaymentAction
} from '../actions'

export function* fetchSSOPayment(action) {
  try {
    const authToken = yield select(getToken)
    yield put(fetchSSOPaymentStart())
    const response = yield call(api.getPaymentLink, authToken)
    yield put(fetchSSOPaymentSuccess(response))
  } catch (error) {
    yield put(fetchSSOPaymentError(error))
    log(`Failed to fetch sso payment link. Error: ${error}`)
  }
}

function* watchFetchSSOPayment() {
  yield takeEvery(fetchSSOPaymentAction().type, fetchSSOPayment)
}

export default [watchFetchSSOPayment()]
