// @flow

import React, { useState } from 'react'
import { generate as shortid } from 'shortid'
import { Drawer } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { CommonIcon } from '@icons'
import { useSelector } from 'react-redux'
import { getLatestTAndC } from 'zego-shared/store/termsAndConditions/selectors'
import {
  useStyles,
  StyledList,
  IconWrap,
  StyledListItem,
  StyledListItemText,
  MenuIconWrap,
  ClickableArea,
  NavGroup,
  NavGroupButton,
  StyledButton
} from './styles'

type Props = {
  name: string,
  open: boolean,
  width: string,
  termsAndConditions: Object,
  myDetailsButtonRef: Object,
  hideMyDetailsButton: (open: boolean) => {},
  disableHelpMenuTab: boolean,
  disableLogOutMenuTab: boolean
}

type MenuItem = {
  text: string,
  native?: boolean,
  link: string,
  mobileOnly?: boolean,
  onClick?: Function,
  show?: boolean
}

export const getMenuItems = (tAndcUrl: string, disableHelpMenuTab: boolean, disableLogOutMenuTab: boolean): MenuItem[] => [
  {
    text: 'Support Center',
    native: true,
    show: !disableHelpMenuTab,
    link: 'https://gozego.force.com/residents/'
  },
  {
    text: 'Terms and Conditions',
    native: true,
    show: true,
    link: tAndcUrl
  },
  {
    text: 'Logout',
    show: !disableLogOutMenuTab,
    link: '/logout'
  },
  {
    text: 'Download App',
    native: true,
    show: true,
    link: 'https://pmz6t.app.link/kqG7Nx5z10',
    mobileOnly: true
  }
]

function SideNav(props: Props) {
  const classes = useStyles()
  const [open, setState] = useState(props.open || false)

  const { name, width, myDetailsButtonRef, hideMyDetailsButton, disableHelpMenuTab, disableLogOutMenuTab } = props
  const {url} = useSelector(getLatestTAndC)
  let menuItems = getMenuItems(url, disableHelpMenuTab, disableLogOutMenuTab)
  
  menuItems = menuItems.filter(item => item.show !== false)

  const toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    hideMyDetailsButton(open)
    setState(open)
  }

  const onClickDetailsButton = () => {
    hideMyDetailsButton(false)
    setState(false)
    myDetailsButtonRef.current.click()
  }

  const sideList = side => (
    <div className={classes.list} role="presentation">
      <IconWrap>
        <CommonIcon
          id="topNavDisableBtn"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          name="menu_icon"
          width="19px"
          height="14px"
        />
      </IconWrap>
      <StyledList>
        {menuItems.map(({ text, link, native, mobileOnly, onClick }, index) => (
          <StyledListItem
            id={text.replace(/\s/g, '')}
            key={shortid()}
            button
            disableRipple={true}
            mobileOnly={mobileOnly}
            {...(onClick
              ? {
                  onClick: () => {
                    toggleDrawer(false)({})
                    onClick()
                  }
                }
              : {})}>
            {!native && (
              <Link to={link}>
                <StyledListItemText primary={text} />
              </Link>
            )}
            {native && (
              <a href={link} target="_blank" rel="noopener noreferrer">
                <StyledListItemText primary={text} />
              </a>
            )}
          </StyledListItem>
        ))}
      </StyledList>
    </div>
  )

  return (
    <div>
      <MenuIconWrap>
        <CommonIcon
          onClick={toggleDrawer(true)}
          id="topNavEnableBtn"
          name="menu_icon"
          width="19px"
          height="14px"
        />
      </MenuIconWrap>
      <Drawer
        anchor="right"
        variant="persistent"
        open={open}
        onClose={toggleDrawer(false)}
        classes={{
          paper: classes.paper,
          paperAnchorDockedRight: classes.paperAnchorDockedRight
        }}>
        <NavGroup>
          <NavGroupButton>
            {open && (
              <StyledButton
                id="appAccountVisibleBtn"
                name={name}
                primary={true}
                width={width}
                onClick={onClickDetailsButton}
              />
            )}
            <ClickableArea
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            />
          </NavGroupButton>
          {sideList('right')}
        </NavGroup>
      </Drawer>
    </div>
  )
}

export default SideNav
