// @flow
import styled from '@emotion/styled'
import { TextInput } from '@Common/Form'
import { StyledCardContent } from '../styles'
import CardActions from '@material-ui/core/CardActions'

export const FinalStepsContent = styled(StyledCardContent)`
    align-items: start;
    flex-grow: unset;

    & > h3 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    & > p {
        font-size: 16px;
        padding: 0;
        margin-bottom: 0;
    }
`
export const NameInput = styled(TextInput)`
    width: 100%;
    text-align: left;
    margin-top: 10px;

    & .MuiInputBase-input {
        height: 60px;
        font-size: 20px;
    }

    & fieldset {
        border: 1px solid #949494;
        border-radius: 2px;
        box-sizing: border-box;
    }
`

export const FinalStepsAction = styled(CardActions)`
    padding: 5px 0;
`