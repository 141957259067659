import gql from 'graphql-tag'

export const FEES_FIELDS = gql`
  fragment FeesFields on Fee {
    regular {
      currency
      amount
    }

    express {
      currency
      amount
    }
  }
`
