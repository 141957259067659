// @flow

import React from 'react'
import { Hidden, useMediaQuery, useTheme } from '@material-ui/core'
import Button from '@Common/Button'
import Flex from '@Common/Flex'
import {
  CheckMark,
  CheckTextContainer,
  Title,
  SubTitle,
  CheckText,
  QuickPaymentsCard
} from './styles'
import PaymentMethodIcon from '../PaymentMethodIcon'
import { isDebitCard } from '@utils/view/paymentMethods'

type Props = {
  selectPaymentMethod: Function,
  paymentMethods: Object[]
}

const DebitAd = ({ selectPaymentMethod, paymentMethods }: Props) => {
  const theme = useTheme()
  const { isSSO } = theme.ux
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))

  const debitCardMethod = paymentMethods.find(isDebitCard)

  const renderCheckTexts = texts => {
    return texts.filter(v=>!!v).map(text => (
      <Flex direction="row" alignStart>
        <CheckMark />
        <CheckTextContainer alignCenter justifyCenter>
          <CheckText>{text}</CheckText>
        </CheckTextContainer>
      </Flex>
    ))
  }

  const paymentCard = (
    <PaymentMethodIcon
      {...debitCardMethod}
      existing={true}
      height={isMobile || isSSO ? 60 : 145}
    />
  )

  return (
    <QuickPaymentsCard noImage>
      <Title>Use Your Debit Instead</Title>
      <Flex justifySpaceBetween>
        <div>
          <SubTitle>Why use Debit?</SubTitle>
          <Flex direction="column" marginBottom="10px">
            {renderCheckTexts([
              'Realize faster payment processing',
              'Reduce late and returned payment fees',
              !isSSO && 'Get peace of mind with consumer protections'
            ])}
          </Flex>
          <Hidden smUp>
            <Flex marginBottom="32px">{paymentCard}</Flex>
          </Hidden>

          <Button
            primary
            elong
            onClick={() => selectPaymentMethod(debitCardMethod?.id)}
          >
            Use Debit Card
          </Button>
        </div>

        <Flex justifyEnd alignCenter>
          <Hidden only="xs">{paymentCard}</Hidden>
        </Flex>
      </Flex>
    </QuickPaymentsCard>
  )
}

export default DebitAd
