// @flow
import get from 'lodash/get'
import { createSelector } from 'reselect'
import {
  getPaymentOptions,
  getEnabledPaymentOptions
} from '../../options/selectors'
import { getPaymentOptionFees, getFee } from '../../fees/selectors'
import {
  pascalToSentenceCase,
  capitalizeFirstLetter
} from '../../../../utils/string'
import { getHistory } from '../../history/selectors'

export const paypalDetails = {
  feeName: 'PayPal',
  fee: '',
  name: 'PayPal',
  id: 'paypal',
  __typename: 'PayPal'
}

export const typeNameToFeeNames = {
  CreditCard: 'Credit card',
  BankAccount: 'Bank acct',
  DebitCard: 'Debit card'
}

export const typeNameToPaymentType = {
  CreditCard: 'CC',
  BankAccount: 'ACH',
  DebitCard: 'DB'
}

export const paymentTypeToTypeName = {
  CC: 'CreditCard',
  ACH: 'BankAccount',
  Debit: 'DB'
}

const isBankAccount = (type: string) => {
  return type === 'BankAccount'
}

const isPayPal = (type: string) => {
  return type === 'PayPal'
}

export const isPayPalSelected = (state: Object) =>
  get(state, 'payments.method.name') === 'PayPal'

export const isDebitCardSelected = (state: Object) =>
  get(state, 'payments.method.__typename') === 'DebitCard'

export const isCreditCardSelected = (state: Object) =>
  get(state, 'payments.method.__typename') === 'CreditCard'

export const isPlaidAccountSelected = (state: Object) =>
  get(state, 'payments.method.isPlaidBankAccount', false)

export const getAccountId = (state: Object) =>
  get(state, 'payments.method.id', -1)

export const getPaymentMethods = (state: Object) =>
  get(state, 'payments.methods')

export const getEnabledPaymentMethods = (state: Object) => {
  const options = getEnabledPaymentOptions(state)
  const paymentMethods = getPaymentsMethodsData(state)

  return paymentMethods.filter(method => options.includes(method.typeName))
}

export const getPaymentMethod = (state: Object) => {
  return get(state, 'payments.method')
}

export const getPaymentsMethodsData = createSelector(
  [getPaymentMethods, getPaymentOptionFees],
  (paymentMethods = {}, fees) => {
    const { byId = {}, allIds = [] } = paymentMethods

    return allIds.map((id: string) => {
      const method = byId[id]

      const {
        expirationMonth,
        expirationYear,
        __typename,
        lastFour,
        bankName,
        network,
        cardHolderName,
        accountHolderName
      } = method

      return {
        id,
        account: lastFour,
        name: capitalizeFirstLetter(
          isBankAccount(__typename) ? bankName : network
        ),
        type: pascalToSentenceCase(method.__typename),
        typeName: __typename,
        feeName: typeNameToFeeNames[__typename],
        fee: getFee(fees, __typename).fee,
        network,
        accountHolder: cardHolderName || accountHolderName,
        expiration: expirationMonth && `${expirationMonth}/${expirationYear}`
      }
    })
  }
)

const buildPaymentMethodData = (method: Object, fees: Object): Object => {
  const {
    __typename: typeName = '',
    bankName = '',
    lastFour = '',
    id = ''
  } = method
  const methodName: string = isBankAccount(typeName)
    ? bankName
    : isPayPal(typeName)
      ? typeName
      : pascalToSentenceCase(typeName)
  return {
    id,
    typeName,
    name: `${methodName} ${lastFour}`,
    ...getFee(fees, typeName, 'regular')
  }
}

export const paymentsMethodsData = getPaymentsMethodsData

export const getPaymentMethodData = createSelector(
  [getPaymentMethod, getPaymentOptionFees],
  (method = {}, fees) => {
    if (Object.keys(method).length < 1) {
      return ''
    }
    return buildPaymentMethodData(method, fees)
  }
)

export const getExpressPayFee = createSelector(
  [getPaymentOptionFees, (state, typeName) => typeName],
  (fees, typeName) => {
    const feeObject = getFee(fees, typeName, 'express')
    return get(feeObject, 'fee', '')
  }
)

export const bankAccountToBankAccountPayload = (bankAccount: Object) => {
  const { confirmAccountNumber, nickname, ...rest } = bankAccount
  return { ...rest, currencyCode: 'USD' }
}

export const paymentCardToPaymentCardPayload = (
  formData: Object,
  isDebitCard: boolean = false
) => {
  const {
    address,
    cardNumber,
    city,
    country,
    cvv,
    expiration,
    name = '',
    state,
    zipCode
  } = formData
  const names = name.trim().split(' ')
  const expirationMonth = String(expiration.month() + 1)
  const expirationYear = String(expiration.year())

  return {
    firstName: names[0],
    lastName: names.slice(1).join(' '),
    address,
    city,
    state,
    country,
    cardNumber,
    expirationMonth,
    expirationYear,
    verificationCode: cvv,
    postalCode: zipCode,
    isDebitCard
  }
}

export const deleteMethodToDeleteMethodPayload = (
  id: string,
  typeName: string
) => {
  return {
    paymentMethodId: Number(id),
    paymentType: typeNameToPaymentType[typeName]
  }
}

export const getPaymentMethodById = createSelector(
  getPaymentMethods,
  (state, id) => id,
  ({ byId = {} }: { byId: Object } = {}, id: string) => {
    const method = byId[id]
    if (method) {
      const { __typename: typeName, ...rest } = method
      return {
        account: { ...rest, typeName },
        type: pascalToSentenceCase(typeName)
      }
    }
    return {}
  }
)

export const getPaymentOptionsConfig = createSelector(
  getPaymentOptions,
  (options: Object) => {
    const { ach, cc, debit } = options || {}
    return {
      hasBank: ach,
      hasCard: cc || debit
    }
  }
)

export const checkHasPaymentMethod = (state: Object) => {
  const paymentMethod = getPaymentMethod(state) || {}

  return paymentMethod.id ? true : false
}

export const getSavedMethodType = (state: Object) =>
  get(state, 'payments.methods.selectedMethodType')

export const getLastUsedPaymentMethod = createSelector(
  [getHistory, getPaymentMethods],
  ([lastTransaction], methodsData) => {
    if (methodsData) {
      const methods: Object[] = Object.values(methodsData.byId)
      if (!lastTransaction) return methods[0]

      const { account, paymentType } = lastTransaction
      const typename = paymentTypeToTypeName[paymentType]
      const lastFour = account.split('#').pop()

      return (
        methods.find(m => typename === m.__typename && lastFour === m.lastFour) ||
        methods[0]
      )
    }
    return null
  }
)
