import gql from 'graphql-tag'

export const GET_RESIDENT = 'getResident'

export const getResidentUserIdQuery = gql`
  query($residentUuId: ID!) {
    ${GET_RESIDENT}(id: $residentUuId) {
        userId
    }
  }
`