import { call, put, takeLatest, select, take } from 'redux-saga/effects'
import { print } from 'graphql'
import {
  fetchPaymentMethodsAction,
  fetchPaymentMethodsSuccess,
  fetchPaymentMethodsError,
  fetchPaymentMethodsStart,
  updateBankAccountAction,
  updateBankAccountError,
  updateBankAccountStart,
  updateBankAccountSuccess,
  SELECT_PAYMENT_METHOD,
  setPaymentMethod,
  updateCardError,
  updateCardAction,
  updateCardSuccess,
  updateCardStart,
  DELETE_PAYMENT_METHOD,
  PAYMENT_METHOD_CREATE_SUCCESS_REDIRECT,
  fetchLastUsedPaymentMethod,
  fetchLastUsedPaymentMethodSuccess,
  fetchLastUsedPaymentMethodError
} from '../actions'
import api from '../../../../utils/api'
import { getGraphQlData } from '../../../../utils/graphql'
import log from '../../../../utils/logger'
import { getPaymentToken, getUserId } from '../../../authorization/selectors'
import {
  getPaymentMethodsQuery,
  getPaymentMethodsVariables
} from '../graphql/queries'
import {
  createBankAccount,
  createPaymentCard,
  deletePaymentMethod
} from '../graphql/mutations'
import {
  bankAccountToBankAccountPayload,
  paymentCardToPaymentCardPayload,
  deleteMethodToDeleteMethodPayload,
  getLastUsedPaymentMethod,
  checkHasPaymentMethod,
  getPaymentMethods
} from '../selectors'
import { showSnackbar } from '../../../snackbar/actions'
import {
  failedToFetchPaymentMethods,
  paymentMethodAdded
} from '../../../../utils/messages'
import { getPaymentState } from 'zego-shared/store/payments/summary/selectors'
import { paymentStatuses } from 'zego-shared/store/payments/summary/constants'
import { hasHistory } from '../../history/selectors'
import { fetchPaymentHistory } from '../../history/actions'
import { transformPaymentOptions } from '../../options/selectors'
import { paymentOptions } from '../../options/graphql/queries'

export function* fetchPaymentMethods({ residentId, isOnetimePayment, checkAllowed }) {
  try {
    const paymentState = yield select(getPaymentState)
    if (paymentState !== paymentStatuses.BLOCKED) {
      yield put(fetchPaymentMethodsStart())
      const paymentToken = yield select(getPaymentToken)
      const tokens = { paymentToken }
      const query = getPaymentMethodsQuery()
      const variables = getPaymentMethodsVariables(residentId, checkAllowed)

      const methodsResponse = yield call(api.graphqlQuery, tokens, query, variables)
      const methodsPayload = yield call(getGraphQlData, methodsResponse, 'getPaymentMethods')

      const optionsResponse = yield call(
        api.graphqlQuery,
        tokens,
        print(paymentOptions),
        { oneTimePayment: isOnetimePayment }
      )
      const optionsPayload = yield call(
        getGraphQlData,
        optionsResponse,
        'getPaymentOptions'
      )

      const methods = getEnabledPaymentMethods(methodsPayload, optionsPayload)
      yield put(fetchPaymentMethodsSuccess(methods))
    }
  } catch (error) {
    yield put(showSnackbar(failedToFetchPaymentMethods, 'error'))
    yield put(fetchPaymentMethodsError(error))
  }
}

export function* saveBankAccount({ payload, residentId }) {
  try {
    yield put(updateBankAccountStart())
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = print(createBankAccount)
    const input = yield call(bankAccountToBankAccountPayload, payload)
    const response = yield call(api.graphqlQuery, tokens, query, {
      residentId,
      input
    })
    const rPayload = yield call(getGraphQlData, response, 'createBankAccount')
    yield put(updateBankAccountSuccess(rPayload))
    yield put(showSnackbar(paymentMethodAdded, 'success'))
  } catch (error) {
    log(`Failed to save bank account details. Error: ${error}`)
    yield put(updateBankAccountError(error))
    yield put(showSnackbar(error.message, 'error'))
  }
}

export function* saveCard({ payload, isDebitCard }) {
  try {
    yield put(updateCardStart())
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = print(createPaymentCard)
    const input = yield call(
      paymentCardToPaymentCardPayload,
      payload,
      isDebitCard
    )

    const response = yield call(api.graphqlQuery, tokens, query, {
      input
    })
    const rPayload = yield call(getGraphQlData, response, 'createPaymentCard')
    yield put(updateCardSuccess(rPayload))
    yield put(showSnackbar(paymentMethodAdded, 'success'))
  } catch (error) {
    log(`Failed to save card details. Error: ${error}`)
    yield put(updateCardError(error))
    yield put(showSnackbar(error.message, 'error'))
  }
}

export function* deleteMethod({ id, typeName, residentId }) {
  try {
    const paymentToken = yield select(getPaymentToken)
    const tokens = { paymentToken }
    const query = print(deletePaymentMethod)
    const post = yield call(deleteMethodToDeleteMethodPayload, id, typeName)

    const response = yield call(api.graphqlQuery, tokens, query, { post })
    const { message } = yield call(
      getGraphQlData,
      response,
      'deletePaymentMethod'
    )
    yield put(showSnackbar(message, 'success'))
    yield put(fetchPaymentMethodsAction({ residentId }))
  } catch (error) {
    log(`Error: ${error}`)
    yield put(showSnackbar(error.message, 'error'))
  }
}

export function* selectPaymentMethod({ id }) {
  try {
    const { byId } = yield select(getPaymentMethods)
    yield put(setPaymentMethod(byId[id]))
  } catch (error) {
    log(`Error: ${error}`)
  }
}

export function* redirectOnMethodCreateSuccess({
  redirectSuccess,
  redirectFailure
}) {
  try {
    const action = yield take([
      updateCardSuccess().type,
      updateBankAccountSuccess().type,
      updateBankAccountError().type
    ])
    if (action.type === updateBankAccountError().type) {
      redirectFailure && redirectFailure()
    } else {
      redirectSuccess()
    }
  } catch (error) {
    log(`Error: ${error}`)
  }
}

export function* fetchLastUsedMethod() {
  try {
    const residentId = yield select(getUserId)
    const hasHistoryData = yield select(hasHistory)
    const hasPaymentMethods = yield select(checkHasPaymentMethod)

    if (!hasHistoryData) yield put(fetchPaymentHistory(residentId))
    if (!hasPaymentMethods) yield put(fetchPaymentMethodsAction({ residentId }))

    const lastMethod = yield select(getLastUsedPaymentMethod)
    yield put(fetchLastUsedPaymentMethodSuccess(lastMethod))
  } catch (error) {
    yield put(fetchLastUsedPaymentMethodError(error))
    log(`Error: ${error}`)
  }
}

function* watchRedirectOnMethodCreateSuccess() {
  yield takeLatest(
    PAYMENT_METHOD_CREATE_SUCCESS_REDIRECT,
    redirectOnMethodCreateSuccess
  )
}

function* watchDeletePaymentMethod() {
  yield takeLatest(DELETE_PAYMENT_METHOD, deleteMethod)
}

function* watchSelectPaymentMethod() {
  yield takeLatest(SELECT_PAYMENT_METHOD, selectPaymentMethod)
}

function* watchFetchPaymentMethods() {
  yield takeLatest(fetchPaymentMethodsAction().type, fetchPaymentMethods)
}

function* watchSaveBankAccount() {
  yield takeLatest(updateBankAccountAction().type, saveBankAccount)
}

function* watchSaveCard() {
  yield takeLatest(updateCardAction().type, saveCard)
}

function* watchFetchLastPaymentMethod() {
  yield takeLatest(fetchLastUsedPaymentMethod().type, fetchLastUsedMethod)
}

function getEnabledPaymentMethods(paymentMethods, options) {
  const convertedPaymentOptions = Object.keys(options).map(
    key => options[key] === true && transformPaymentOptions[key]
  )
  return paymentMethods.filter(method => convertedPaymentOptions.includes(method.__typename))
}

export default [
  watchSaveCard(),
  watchSaveBankAccount(),
  watchFetchPaymentMethods(),
  watchSelectPaymentMethod(),
  watchDeletePaymentMethod(),
  watchRedirectOnMethodCreateSuccess(),
  watchFetchLastPaymentMethod()
]
