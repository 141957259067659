import { connect } from 'react-redux'
import { createIsUpdatingSelector } from 'zego-shared/store/isUpdating/selectors'

import AddPaymentMethod from './AddPaymentMethod'

const updatingSelector = createIsUpdatingSelector(['bankaccount', 'card'])

const mapStateToProps = state => {
  return {
    isSaving: updatingSelector(state)
  }
}

export default connect(mapStateToProps)(AddPaymentMethod)
