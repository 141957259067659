// @flow

import React from 'react'
import withAlertModal from '@Common/AlertModal/withAlertModal'
import { withTheme } from 'emotion-theming'
import { Box } from '@material-ui/core'
import Delete from '../../Common/Delete'
import ViewLink from './ViewLink'
import type { Theme } from '../../../theme'

type RowData = { id: string, typeName: string }

type Props = {
  row: RowData,
  theme: Theme,
  onConfirmDelete: (id: string, typeName: string) => void
}

const DeleteMethod = withAlertModal(({ showAlertModal }) => (<Delete onClick={showAlertModal}/>))

const RowAction = ({
  row: { id, typeName },
  onConfirmDelete = () => {}
}: Props) => {
  return (
    <Box display="flex" justifyContent="flex-end">
      <ViewLink id={id} />
      <DeleteMethod
        alertTitle="Are you sure you wish to delete?"
        onAlertClickYes={() => onConfirmDelete(id, typeName)}
      />
    </Box>
  )
}

export default withTheme(RowAction)
