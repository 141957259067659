import { connect } from 'react-redux'
import AutoPay from './AutoPay'
import { withFetchData } from 'zego-shared/views'
import {
  cancelAutopay,
  skipAutopay,
  cancelSkipAutopay
} from 'zego-shared/store/payments/autopay/actions'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  getAutoPays,
  hasData
} from 'zego-shared/store/payments/autopay/selectors'
import {
  getAllowCancel,
  getAllowEdit,
  getAllowSkip
} from 'zego-shared/store/payments/autopay/selectors'
import {
  isLevelCreditReportingEnabled,
  getLevelCreditActive,
  getLevelCreditInactive
} from 'zego-shared/store/payments/creditReporting/selectors'
import { getDisableAutopayMenuTab } from 'zego-shared/store/pmSettings/selectors'
import { withRouter } from 'react-router'

const fetchingSelector = createIsFetchingSelector(['autopaydata'])
const autoPayFetchData = withFetchData(AutoPay)

export const mapStateToProps = state => ({
  autopays: getAutoPays(state),
  isFetching: fetchingSelector(state),
  hasData: hasData(state),
  allowEdit: getAllowEdit(state),
  allowSkip: getAllowSkip(state),
  allowCancel: getAllowCancel(state),
  isLevelCreditEnabled: isLevelCreditReportingEnabled(state),
  levelCreditActive: getLevelCreditActive(state),
  levelCreditInActive: getLevelCreditInactive(state),
  disableAutopayMenuTab: getDisableAutopayMenuTab(state)
})

export const mapDispatchToProps = dispatch => ({
  cancelAutopay: (id, autopayType, redirectArgs) => {
    dispatch(cancelAutopay(id, autopayType, redirectArgs))
  },
  skipAutopay: (id, autopayType) => {
    dispatch(skipAutopay(id, autopayType))
  },
  cancelSkipAutopay: (id, autopayType) => {
    dispatch(cancelSkipAutopay(id, autopayType))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(autoPayFetchData))
