import React from 'react'
import Grid from '@material-ui/core/Grid'
import { EXPIRATION_FORMAT } from './schema'
import ExpDateMask from '@Common/ExpDateMask'
import { buildRegionsOptions, UNITED_STATES } from '@utils/view'
import { formatDateMMYY } from 'zego-shared/utils/date'
import type { FieldsProps } from '../types'
import {
  StyledInputText,
  StyledInputDate,
  StyledSelect,
  StyledFormGroup
} from '../styles'

const US_STATES = buildRegionsOptions(UNITED_STATES, true)

const handleDateChange = (cbFn: OnChange, values: Object) => (
  date: Object,
  name: string
) => {
  cbFn({ ...values, [name]: date })
}
const LookbackFields = ({
  onChange,
  values,
  errors,
  setValues,
  getErrorDetails,
  getNamedProps,
  setFieldValue,
  handleBlur: onBlur,
  handleFocus: onFocus,
  handleLeaseMonthToMonthChange,
  latestTnC
}: FieldsProps & {
  handleLeaseMonthToMonthChange: Function
}) => {
  const inputProps = { onFocus, onBlur }

  const dateChange = handleDateChange(setValues, values)
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledFormGroup
            label="Name on Card"
            {...getErrorDetails('nameOnCard')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('nameOnCard')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>

        <Grid item xs={12} md={6}>
          <StyledFormGroup
            label="Card Number"
            {...getErrorDetails('cardNumber')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('cardNumber')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <StyledFormGroup
            label="Expiration"
            {...getErrorDetails('expiration')}>
            <StyledInputDate
              name="expiration"
              width="232px"
              showSelectedDate={false}
              formatter={formatDateMMYY}
              readOnly={false}
              format={EXPIRATION_FORMAT}
              CalendarProps={{
                minPanel: 'month'
              }}
              InputProps={{
                name: 'expiration',
                inputComponent: ExpDateMask,
                placeholder: EXPIRATION_FORMAT
              }}
              onChange={dateChange}
              initialDate={values['expiration']}
            />
          </StyledFormGroup>
        </Grid>

        <Grid item xs={12} md={3} lg={4}>
          <StyledFormGroup label="CVV" {...getErrorDetails('cvv')}>
            <StyledInputText
              name="cvv"
              onChange={onChange}
              {...getNamedProps('cvv')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <StyledFormGroup
            label="Billing Address"
            {...getErrorDetails('addressLine1')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('addressLine1')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <StyledFormGroup label="Apartment, suite, unit, etc">
            <StyledInputText name="addressLine2" onChange={onChange} />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <StyledFormGroup label="zip" {...getErrorDetails('zip')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('zip')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <StyledFormGroup label="city" {...getErrorDetails('city')}>
            <StyledInputText
              onChange={onChange}
              {...getNamedProps('city')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <StyledFormGroup label="state" {...getErrorDetails('state')}>
            <StyledSelect
              id="stateSelect"
              options={US_STATES}
              onChange={onChange}
              {...getNamedProps('state')}
              {...inputProps}
            />
          </StyledFormGroup>
        </Grid>
      </Grid>
    </>
  )
}

export default LookbackFields
