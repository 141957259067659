export const formatAutoPay = ({
  itemId,
  total,
  nextRun,
  bankName,
  type,
  lastFour,
  isVariable
}) => ({
  itemId,
  total,
  nextRun,
  account: String(`${bankName} ${type} ${lastFour}`).toLowerCase(),
  isVariable
})
