import withFetch from '../../helpers/reducers/withFetch'
import { USER_RESOURCE } from '../actions'

const userResource = (state = {}, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default withFetch(USER_RESOURCE)(userResource)
