import {
  createFetchActions,
  createUpdateActions
} from '../../../helpers/actions/creators'
export const PAYMENT_HISTORY_SECTION = 'PAYMENTHISTORYSECTION'
export const VOID_PAYMENT = 'VOID_PAYMENT'
export const VOID_PAYMENT_SUCCESS = 'VOID_PAYMENT_SUCCESS'
export const VOID_PAYMENT_ERROR = 'VOID_PAYMENT_ERROR'
export const REQUEST_PAYMENT_RECEIPT = 'REQUESTPAYMENTRECEIPT'

export const {
  fetchAction: fetchPaymentHistoryAction,
  fetchStartAction: fetchPaymentHistoryStart,
  fetchSuccessAction: fetchPaymentHistorySuccess,
  fetchErrorAction: fetchPaymentHistoryError
} = createFetchActions(PAYMENT_HISTORY_SECTION)

export const {
  updateAction: requestPaymentReceipt,
  updateStartAction: requestPaymentReceiptStart,
  updateSuccessAction: requestPaymentReceiptSuccess,
  updateErrorAction: requestPaymentReceiptError
} = createUpdateActions(REQUEST_PAYMENT_RECEIPT)

export const fetchPaymentHistory = (residentId, cursor) =>
  fetchPaymentHistoryAction({
    residentId,
    cursor
  })

export const voidPayment = id => ({
  id,
  type: VOID_PAYMENT
})

export const voidPaymentSuccess = id => ({
  type: VOID_PAYMENT_SUCCESS,
  id
})

export const voidPaymentError = id => ({
  type: VOID_PAYMENT_ERROR,
  id
})
