// @flow
import { connect } from 'react-redux'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import {
  isLookbackEnabled,
  getLevelCreditTransactions
} from 'zego-shared/store/payments/creditReporting/selectors'
import withFetchData from 'zego-shared/views/withFetchData'
import {
  getReportedLevelCreditTrans,
  getReportedLookbackTrans
} from 'zego-shared/store/payments/creditReporting/actions'
import LevelCredit from './LevelCredit'

const fetchingSelector = createIsFetchingSelector(['levelcredit'])

const mapStateToProps = state => ({
  isFetching: fetchingSelector(state),
  transactionsData: getLevelCreditTransactions(state),
  lookbackEnabled: isLookbackEnabled(state)
})

const mapDispatchToProps = dispatch => ({
  fetchData: () => {
    dispatch(getReportedLevelCreditTrans())
    dispatch(getReportedLookbackTrans())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withFetchData(LevelCredit))
