// @flow
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getHasMax } from 'zego-shared/store/payments/autopay/selectors'
import { updateDetails } from 'zego-shared/store/payments/autopay/actions'
import { MY_FULL_BAL } from 'zego-shared/store/payments/autopay/constants'

import MaxAmountEdit from './MaxAmountEdit'
import AmountBase from '../AmountBase'

type Props = {
  value: Object,
  onChange: (string, ?boolean) => mixed,
  onCancel?: Function,
  noMax: boolean,
  isBoth: boolean,
  payFixAmt: Function,
  name: string
}

export const getUpdateDetailsWithInitialVal = (
  value: Object,
  initialVal: Object,
  name: string,
  dispatch: Function
) => (editMode: boolean) => {
  const hasChange = value !== initialVal
  const isCancel = !editMode
  isCancel && hasChange && dispatch(updateDetails({ [name]: initialVal }))
}

export const getToggleEditMode = (
  setEditMode: Function,
  updateDetailsWithInitialVal: Function
) => (editMode: boolean) => () => {
  setEditMode(editMode)
  updateDetailsWithInitialVal(editMode)
}

const MaxAmount = ({ value, onChange, isBoth, payFixAmt, name }: Props) => {
  const hasMax = useSelector(getHasMax)
  const dispatch = useDispatch()
  const { total } = value
  const [editMode, setEditMode] = React.useState(total !== MY_FULL_BAL)
  const [initialVal] = React.useState(value)
  const updateDetailsWithInitialVal = getUpdateDetailsWithInitialVal(
    value,
    initialVal,
    name,
    dispatch
  )
  const toggleEditMode = getToggleEditMode(
    setEditMode,
    updateDetailsWithInitialVal
  )

  if (editMode) {
    return (
      <MaxAmountEdit
        value={value}
        onCancel={toggleEditMode(false)}
        onChange={onChange}
      />
    )
  }

  return (
    <AmountBase
      title="Payment"
      description="Your amount to be paid with each auto payment."
      value={MY_FULL_BAL}
      linkBtnText={isBoth && 'Pay a Fixed Amount'}
      fullBal
      onIconClicked={toggleEditMode(true)}
      disableEdit={!hasMax}
      onLinkBtnClick={payFixAmt}
    />
  )
}

export default MaxAmount
