// @flow
import React from 'react'
import type { Node } from 'react'
import PaymentNotes from './PaymentNotesContainer'
import type { DataProps as PaymentNotesProps } from './PaymentNotesContainer'

const withPaymentNotes =
  (Component: Node, notesProps: PaymentNotesProps) => (props: Object) =>
    (
      <PaymentNotes {...notesProps}>
        <Component {...props} />
      </PaymentNotes>
    )

export default withPaymentNotes
