// @flow
import React from 'react'
import NoReminderSet from './NoReminderSet'
import ViewReminder from './ViewReminder'
import EditPaymentReminders from './EditPaymentReminders'
import { SectionTitle } from '../styles'
import { useSelector } from 'react-redux'
import { hasPaymentReminders } from 'zego-shared/store/payments/paymentReminders/selectors'
import { Underline } from './styles'

type Props = {
  isEdit: string,
  changeMode: () => null,
  isSSO: boolean
}

const PaymentReminders = ({ isEdit, changeMode, isSSO }: Props) => {
  const hasReminders = useSelector(hasPaymentReminders)
  return (
    <>
      {isEdit ? (
        <EditPaymentReminders changeMode={changeMode}/>
      ) : (
        <>
          {!isSSO && 
            <>
            <Underline />
            <SectionTitle>Payment Reminders</SectionTitle>
            </>
          }
          {hasReminders ? (
            <ViewReminder changeMode={changeMode} />
          ) : (
            <NoReminderSet changeMode={changeMode}/>
          )}
        </>
      )}
    </>
  )
}

export default PaymentReminders
