// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'
import { Link } from 'react-router-dom'
import LClogo from '@images/credit_reporting/level_credit_logo.png'
import SuccessAd from '@images/credit_reporting/success_ad.png'
import SuccessAdMob from '@images/credit_reporting/success_ad_mob.png'
import SuccessGo from '@images/credit_reporting/success_go.png'
import LClogoMob from '@images/credit_reporting/level_credit_logo_mob.png'
import Button from '@Common/Button'

export const LevelCreditLogo = styled('div')`
  width: 151px;
  height: 40px;
  background-image: url(${LClogo});
  background-size: cover;
  text-align: right;

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    background-image: url(${LClogoMob});
    width: 101px;
    height: 27px;
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-bottom: ${({ theme }) => theme.space.s20};
  }
`

export const OptOutText = styled.div`
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  color: ${({ theme }) => theme.palette.common.grey};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
  line-height: 14px;
`

export const LCStyledButton = styled(Button)`
  margin-left: ${({ theme }) => theme.space.s10};
  width: 175px;
  height: 35px;
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    width: 236px;
  }
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    width: 314px;
  }
`
export const LCButtonWrap = styled(Flex)`
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.space.s30};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    justify-content: space-between;
  }
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    flex-direction: column-reverse;
    min-height: 100px;
    margin-top: 0;
  }
`

export const LevelCreditFormContainer = styled(Flex)`
  box-shadow: 0px 2px 4px rgba(14, 20, 39, 0.5);
  border-radius: 5px;
  padding: ${({ theme }) => `${theme.space.s40} ${theme.space.s28}`};
  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    margin-bottom: ${({ theme }) => theme.space.s48};
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
    max-height: calc(100vh - 205px);
    overflow: scroll;
    margin-top: -25px;
    background-color: ${theme.palette.common.white};
    
    & ~ div {
      display: none;
    }

    @media (max-width: 666px) {
      max-height: calc(100vh - 223px);
    }
  `}
`

export const LevelCreditLogoDiv = styled('div')`
  width: 120px;
  height: 30px;
  background-image: url('${LClogo}');
  background-size: cover;
  text-align: right;
  background-repeat: no-repeat;

  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    display: none;
  }

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-bottom: ${({ theme }) => theme.space.s20};
    width: 105px;
    height: 28px;
  }
`

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.neutral.cool};
  margin-top: ${({ theme }) => theme.space.s24};
  margin-bottom: ${({ theme }) => theme.space.s37};
`

export const LookBackHeader = styled.div`
  padding: ${({ theme }) =>
    `${theme.space.s30} ${theme.space.s16} ${theme.space.s29} ${theme.space.s20}`};
  min-height: 138px;
  background: ${({ theme }) => theme.palette.common.lightBlue};
  display: flex;
  justify-content: space-between;
  box-shadow: ${({ theme }) =>
    theme.ux.isSSO ? 'none' : '0px 2px 4px rgba(14, 20, 39, 0.25)'};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    padding: ${({ theme }) =>
      `${theme.space.s45} ${theme.space.s11} ${theme.space.s10} ${theme.space.s12}`};
  }
`

export const LookBackTitleInfo = styled.div`
  margin-right: ${({ theme }) => theme.space.s24};
`

export const LookBackMessageMob = styled.div`
  display: none;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    display: block;
  }
`

export const LookBackMessage = styled.div`
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    display: none;
  }
`

export const LookBackHeaderTitle = styled.div`
  height: 29px;
  line-height: 29px;
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  font-size: ${({ theme }) => theme.custom.font.size.H4};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    margin-bottom: ${({ theme }) => theme.space.s11};
  }
`

export const LookBackHeaderInfo = styled.div`
  min-height: 50px;
  line-height: 20px;
  max-width: 600px;
  color: ${({ theme }) => theme.palette.common.grey};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  font-size: ${({ theme }) => theme.custom.font.size.sm};
`

export const LookBackPaymentInfo = styled.div`
  height: 24px;
  line-height: 24px;
  width: 111px;
  text-align: center;
  color: ${({ theme }) => theme.palette.common.grey};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  font-size: ${({ theme }) => theme.custom.font.size.sm};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    text-align: left;
    margin-left: ${({ theme }) => theme.space.s5};
  }
`

export const LookBackPaymentAmount = styled.div`
  height: 48px;
  line-height: 32px;
  width: 111px;
  color: ${({ theme }) => theme.palette.primary.light};
  font-weight: ${({ theme }) => theme.custom.font.weight.bold};
  font-size: ${({ theme }) => theme.custom.font.size.H2};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H3};
  }
`

export const LookBackPaymentAmountDecimal = styled.span`
  height: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.primary.light};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  font-size: ${({ theme }) => theme.custom.font.size.H5};
  position: absolute;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-weight: ${({ theme }) => theme.custom.font.weight.bold};
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`

export const FooterInfo = styled.div`
  height: 14px;
  line-height: 12px;
  margin-top: ${({ theme }) => theme.space.s10};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  font-size: ${({ theme }) => theme.custom.font.size.xs};
`

export const LookBackPaymentContainer = styled.div`
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    position: absolute;
    right: 28px;
    margin-top: -31px;
  }
`

/* Success Page */

export const SuccessTitle = styled.div`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  font-size: ${({ theme }) => theme.custom.font.size.H4};
  position: relative;
  line-height: 29px;

  & span {
    position: relative;
  }
  &&::before {
    height: 7px;
    left: -3px;
    right: 0;
    bottom: 3px;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    transform: translateX(10px);
    content: '';
    ${({ theme }) => theme.ux.isSSO && 'display: none;'}
  }
`

export const LCLogoSuccess = styled('div')`
  width: 103px;
  height: 26px;
  background-image: url(${LClogo});
  background-size: cover;
  margin-top: ${({ theme }) => theme.space.s5};
`

export const SuccessMessageContainer = styled.div`
  width: 100%;
  min-height: 157px;
  padding: 22px 17.8% 86px 17.8%;
  position: absolute;
  left: 0;
  right: 0;
  margin-top: ${({ theme }) => theme.space.s25};
  background-color: ${({ theme }) => theme.palette.common.iceberg};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    padding-left: 101px;
    padding-right: 101px;
  }

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    min-height: 253px;
    padding: 22px 34px 0;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
     padding-top: ${theme.space.s20};
     padding-left: ${theme.space.s20};
     padding-right: ${theme.space.s20};
     margin-top: 15px;
    `};
`

export const SuccessMessage = styled(Flex)`
  color: ${({ theme }) => theme.palette.common.midnight};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  margin-bottom: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s15 : theme.space.s20};
  flex-direction: column;
  line-height: 18px;
  max-width: 853px;
`

export const Bold = styled.div`
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
`

export const AdLink = styled.div`
  width: ${({ theme }) => (theme.ux.isSSO ? '350px' : '563px')};
  background-image: url(${SuccessAd});
  background-size: contain;
  background-repeat: no-repeat;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    width: 315px;
    background-image: url(${SuccessAdMob});
  }
`

export const AdText = styled.div`
  padding-top: 108px;
  height: 154px;
  margin-left: ${({ theme }) => theme.space.s44};
  margin-bottom: ${({ theme }) => theme.space.s27};

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    padding-top: 54px;
    height: 100px;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
      padding-top: ${theme.space.s60};
      height: initial;
      margin-left: ${theme.space.s26};
    `}
`

export const MainText = styled.div`
  font-size: 18px;
  line-height: 18px;
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  margin-bottom: ${({ theme }) => theme.space.s2};

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H4};
  }
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: 18px;
  }
`

export const SubText = styled.div`
  line-height: 16px;
  height: 20px;
  line-height: 20px;
  font-size: ${({ theme }) =>
    theme.ux.isSSO ? theme.custom.font.size.md : theme.custom.font.size.H6};
  ${({ theme }) => [theme.breakpoints.down('md')]} {
    font-size: 18px;
  }
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    font-size: ${({ theme }) => theme.custom.font.size.H6};
  }
`

export const GoButton = styled(Link)`
  height: 35px;
  width: 111px;
  position: relative;
  color: ${({ theme }) => theme.palette.primary.deep};
  text-align: center;
  line-height: 16px;
  margin-left: ${({ theme }) =>
    theme.ux.isSSO ? theme.space.s26 : theme.space.s44};
  background-repeat: no-repeat;
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  padding: ${({ theme }) =>
    `${theme.space.s3} ${theme.space.s26} 0 ${theme.space.s21}`};
  z-index: 0;
  &:before {
    content: '';
    position: absolute;
    background-color: ${({ theme }) => theme.palette.tertiary.main};
    top: 0;
    left: 0;
    height: 30px;
    width: 0;
    transition: 0.25s width;
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 117px;
    background-image: url(${SuccessGo});
    background-size: 100% 100%;
    z-index: 1;
  }
  &:hover :before,
  &:focus :before {
    width: 100%;
  }
`

export const SuccessLogoContainer = styled(Flex)`
  margin: ${({ theme }) => theme.space.s10} 9.4% 0;

  ${({ theme }) => [theme.breakpoints.down('md')]} {
    margin-left: 71px;
    margin-right: 71px;
  }

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    margin-left: 0;
    margin-right: 0;
  }

  ${({ theme }) =>
    theme.ux.isSSO &&
    `
      margin-left: 7px;
      margin-right: 7px;
    `}
`

export const AdContainer = styled(Flex)`
  width: 100%;
  height: ${({ theme }) => (theme.ux.isSSO ? '230px' : '387px')};
  position: absolute;
  left: 0;
  margin-top: 5px;
`

export const LogoWrap = styled(Flex)`
  height: 30px;

  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    display: none;
  }
`
