// @flow

import { useEffect } from 'react';

type Visitor = {
  id: String,
  lastPayMethod: string
}
type Account = {}

type Props = {
  visitor: Visitor,
  account: Account
}

type PendoInstance = {
  isReady?: Function,
  get_visitor_id?: Function,
  initialize?: Function,
  updateOptions: Function
}

const Pendo = (props: Props) => {

  const { visitor, account } = props
  const { lastPayMethod: paymentsDataLoaded } = visitor

  useEffect(() => {
    initPendo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {

    if (paymentsDataLoaded) {
      updatePendo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentsDataLoaded])

  const initPendo = () => {
    const pendo: PendoInstance = window.pendo

    if (pendo && visitor.id) {
      const { isReady, get_visitor_id: getVisitorId, initialize } = pendo
      if (
        (isReady && !isReady()) ||
        (getVisitorId && getVisitorId() !== visitor.id)
      ) {
        initialize &&
          initialize({
            visitor,
            account
          })
      }
    }
  }

  const updatePendo = () => {
    const pendo: PendoInstance = window.pendo

    const { updateOptions } = pendo
    updateOptions({
      visitor,
      account
    })
  }

  return null
}

export default Pendo