// @flow
import React from 'react'
import { useTheme } from '@material-ui/core'
import CardDisclaimer from './CardDisclaimer'
import SSOCardDisclaimer from './SSOCardDisclaimer.js'

export default () => {
  const theme = useTheme()

  return theme.ux.isSSO ? <SSOCardDisclaimer /> : <CardDisclaimer />
}
