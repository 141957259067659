// @flow
import styled from '@emotion/styled'

import Flex from '@Common/Flex'
import Card from '@Common/Card'

import { buttonWrapperStyledSsoStyles } from '../../styles'

export const ButtonWrapperStyled = styled(Flex)`
  justify-content: flex-end;
  margin-top: 75px;

  & button {
    height: 35px;
    width: 225px;
    text-transform: unset;
  }

  & button {
    margin-left: 22px;
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  }
  & a button {
    font-weight: ${({ theme }) => theme.custom.font.weight.regular};
  }

  ${({ theme }) => theme.ux.isSSO && buttonWrapperStyledSsoStyles}
`

export const CardStyled = styled(Card)`
  height: 320px;
  width: 540px;
  border-radius: 0 45px 0 45px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);

  & .MuiCardContent-root {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & p {
    height: 64px;
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme }) => theme.custom.font.size.H4};
    line-height: 32px;
    text-align: center;
    margin: 0 0 50px;
  }

  & button:last-of-type {
    margin-left: ${({ theme }) => theme.space.s20};
    font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  }
`
