// @flow
import React, { useState } from 'react'
import type { Node, SyntheticEvent } from 'react'
import { CommonIcon } from '@icons'
import AlertModal from '@Common/AlertModal/AlertModal'
import { TextInput, FormGroup } from '@Common/Form'
import Button from '@Common/Button'
import {
  MainWrapper,
  ModalContent,
  ActionLink,
  ContentWrapper,
  RemoveNote,
  Buttons,
  ActionButton
} from './styles'

type Props = {
  children: Node,
  paymentNote: string,
  notesEnabled: boolean,
  onSave: Function,
  variant: 'button' | 'wrapper'
}

export const PaymentNotes = ({
  children = null,
  notesEnabled,
  onSave,
  paymentNote,
  variant = 'wrapper'
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [noteValue, setNoteValue] = useState(paymentNote)

  if (!notesEnabled) return children

  const toggleModal = () => setModalVisible(!modalVisible)
  const onTextChange = (e: SyntheticEvent) => setNoteValue(e.target.value)

  const handleSave = value => () => {
    toggleModal()
    onSave(value)
    setNoteValue(value)
  }

  const actionContent = (
    <>
      <span>{paymentNote ? 'Edit payment note' : 'Add a payment note'}</span>
      <CommonIcon name="notepad" height="25px" width="25px" />
    </>
  )

  const modalContent = (
    <ModalContent>
      <FormGroup label="Note Content" fullWidth>
        <TextInput
          multiline={true}
          rows={3}
          value={noteValue}
          onChange={onTextChange}
        />
      </FormGroup>
      {paymentNote && (
        <RemoveNote id="paymentNoteRemove" onClick={handleSave('')}>
          Remove Note
        </RemoveNote>
      )}
      <Buttons>
        <Button
          id="paymentNoteCancel"
          onClick={toggleModal}
          outline={true}
          primary={true}
        >
          Cancel
        </Button>
        <Button
          id="paymentNoteSave"
          onClick={handleSave(noteValue)}
          primary={true}
        >
          Save
        </Button>
      </Buttons>
    </ModalContent>
  )

  const mainContent = {
    button: (
      <ActionButton id="paymentNoteActionBtn" onClick={toggleModal}>
        {actionContent}
      </ActionButton>
    ),
    wrapper: (
      <MainWrapper>
        <ActionLink id="paymentNoteActionLink" onClick={toggleModal}>
          {actionContent}
        </ActionLink>
        <ContentWrapper>{children}</ContentWrapper>
      </MainWrapper>
    )
  }

  return (
    <>
      {mainContent[variant]}
      <AlertModal
        show={modalVisible}
        onClose={toggleModal}
        title="Payment Note"
        message={modalContent}
        showCloseButton={false}
      />
    </>
  )
}

export default PaymentNotes
