import styled from '@emotion/styled'
import FlexCommon from '@Common/Flex'
import Card from '@Common/Card'
import Popover from '@material-ui/core/Popover'
import Calendar from '@Common/Calendar'

export const Underline = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.warm};
  height: 1px;
  margin: ${({ theme }) => `${theme.space.s70} 0`};
`

export const NoRemindersText = styled.div`
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  color: ${({ theme }) => theme.palette.neutral.deep};
  line-height: ${({ theme }) => theme.space.s24};
  margin-bottom: ${({ theme }) => theme.space.s70};
`
export const StyledInputLane = styled(FlexCommon)`
  margin-bottom: ${({ theme }) => theme.space.s24};
  max-width: 416px;
  width: 100%;

  @media only screen and (max-width: 540px) {
    width: 316px;
  }
`

export const IconWrap = styled.div`
  margin-left: ${({ theme }) => theme.space.s20};
  width: 24px;
  height: 24px;
`

export const SelecFieldsFlex = styled(FlexCommon)`
  @media only screen and (max-width: 768px) {
    flex-direction: ${({ theme }) => (theme.ux.isSSO ? 'inherit' : 'column')};
  }
`

export const InputWrap = styled(FlexCommon)`
  width: ${({ theme }) => (theme.ux.isSSO ? 200 : 270)}px;
  flex-direction: column;

  & label.MuiInputLabel-formControl {
    font-size: ${({ theme }) =>
      theme.ux.isSSO ? theme.custom.font.size.sm : 'inherit'};
  }

  & button {
    width: ${({ theme }) => (theme.ux.isSSO ? '98%' : 'inherit')};
    margin: ${({ theme }) => (theme.ux.isSSO ? 'unset' : 'inherit')};
    position: ${({ theme }) => (theme.ux.isSSO ? 'relative' : 'inherit')};
    left: ${({ theme }) => (theme.ux.isSSO ? '-24px' : 'inherit')};
    top: ${({ theme }) => (theme.ux.isSSO ? '-10px' : 'inherit')};
  }

  & button#cancelBtn {
    left: ${({ theme }) => (theme.ux.isSSO ? '0px' : 'inherit')};
  }

  & button#editBtn {
    top: ${({ theme }) => (theme.ux.isSSO ? '-30px' : 'inherit')};
  }

  @media only screen and (max-width: 768px) {
    width: ${({ mobWidth }) => (mobWidth ? mobWidth : '170px')};
    margin-bottom: ${({ mobMarginBotom }) =>
      mobMarginBotom ? mobMarginBotom : ''};
  }
`

export const BottomWrap = styled(FlexCommon)`
  width: ${({ theme }) => (theme.ux.isSSO ? 'inherit' : '556px')};
  margin-top: ${({ theme }) => (theme.ux.isSSO ? '20px' : '90px')};

  @media only screen and (max-width: 768px) {
    flex-direction: ${({ theme }) => (theme.ux.isSSO ? 'inherit' : 'column')};
    width: ${({ theme }) => (theme.ux.isSSO ? 'inherit' : '100%')};
    margin-bottom: ${({ mobMarginBotom }) => mobMarginBotom && mobMarginBotom};
  }
`

export const PopoverStyled = styled(Popover)`
  & .MuiPopover-paper {
    overflow: visible;
  }
`

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
`

export const CalendarStyled = styled(Calendar)`
  width: 100%;
`

export const ScheduleInputStyled = styled.div`
  position: relative;
  & svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const CalenderWrapperStyled = styled(FlexCommon)`
  position: relative;
  background-color: transparent;
  width: fit-content;
  max-width: 100%;
  min-height: 150px;

  &::before {
    content: '';
    position: absolute;
    right: 14px;
    top: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.palette.neutral.cool};
    ${({ theme }) => [theme.breakpoints.down('sm')]} {
      display: none;
    }
  }
  & .calendar-nav {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: -12px -12px;
      height: calc(100% + 20px);
      background-color: ${({ theme }) => theme.palette.neutral.cool};
      pointer-events: none;
    }
  }

  & .datetime-picker {
    & button,
    & .current-date {
      z-index: 1;
      pointer-events: initial;
    }

    & button::before {
      font-size: ${({ theme }) => theme.custom.font.size.sm};
      background: ${({ theme }) => theme.palette.tertiary.cool};
      color: ${({ theme }) => theme.palette.primary.main};
      line-height: 16px;
    }

    & tbody tr td {
      color: ${({ theme }) => theme.palette.primary.main};
      font-size: ${({ theme }) => theme.custom.font.size.xs};
      line-height: 12px;
      padding: ${({ theme }) => theme.space.s10};

      &.selected:not(.next):not(.prev) {
        background-color: ${({ theme, disableAll }) =>
          !disableAll && theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.common.white} !important;
      }
      &.disabled {
        cursor: not-allowed;
        color: ${({ theme }) => theme.palette.neutral.main};
      }
    }
  }
`

export const ActionBtnsStyled = styled(FlexCommon)`
  padding: ${({ theme }) => theme.space.s15};

  & button {
    width: fit-content;
    color: ${({ theme }) => theme.palette.primary.main};
    font-size: 10px;
    background-color: transparent;
    min-width: unset;
    padding: 0;
    margin: 0;
    text-transform: capitalize;
    & :hover {
      background-color: transparent;
    }
    &:last-of-type {
      font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
      margin-left: 20px;
    }
  }
`

export const StyledCard = styled(Card)`
  margin-top: -40px;
  overflow: scroll;
  height: 300px;
  overflow-x: hidden;
  & div.MuiCardContent-root {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 10px;
  }
`
