// @flow
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { IconButtonStyled, Card, CardContent, CardActions } from './styles'

type Props = {
  title: string | React$Node,
  onClose: Function,
  actions?: React$Node,
  classes: Object,
  children: React$Node
}

const styles = {
  root: {},
  title: {},
  content: {},
  actions: {}
}

const CardTitle = ({ children, className }) => {
  if ('string' !== typeof children) {
    return children
  }

  return (
    <Typography variant="h4" component="h1" className={className}>
      {children}
    </Typography>
  )
}

const PlaidCard = ({ title, onClose, actions, classes, children }: Props) => (
  <Card unwrapContent className={classes.root}>
    <IconButtonStyled onClick={onClose}>
      <CloseIcon />
    </IconButtonStyled>
    <CardTitle className={classes.title}>{title}</CardTitle>
    <CardContent className={classes.content}>{children}</CardContent>
    {actions ? (
      <CardActions className={classes.actions}>{actions}</CardActions>
    ) : null}
  </Card>
)

export default withStyles(styles)(PlaidCard)
