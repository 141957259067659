// @flow

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Flex from '@Common/Flex'
import { MenuWrap, DownlinkWrap, SubNavLink, ActiveBar, TabNav } from './styles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { WORK_ORDER_FEATURE_FLAG } from 'zego-shared/store/workOrders/constants'
import { MARKETPLACE_FEATURE_FLAG } from 'zego-shared/store/marketplace/constants'

type Props = {
  unitId: number,
  activePage: string,
  hasWorkOrder: boolean,
  hasStandaloneWorkOrder: boolean,
  marketplaceEnabled: boolean,
  featureFlag: Object,
  ldInitRequest: Function,
  nativePayer: boolean,
  hasPayment: boolean,
  paymentLink: string,
  top: number
}

const SubNav = (props: Props) => {
  const {
    unitId,
    activePage,
    hasWorkOrder,
    hasStandaloneWorkOrder,
    marketplaceEnabled,
    nativePayer,
    hasPayment,
    paymentLink,
    top,
    ldInitRequest,
    featureFlag
  } = props
  const [isActivePayment, setActivePayment] = useState(false)
  const [isActiveWorkOrders, setActiveWorkOrders] = useState(false)
  const [showWorkOrderFF, setShowWorkOrderFF] = useState(false)
  const [showMarketplaceFF, setShowMarketplaceFF] = useState(false)
  const [isActiveDownloadApp, setActiveDownloadApp] = useState(false)
  const [isActiveMarketplace, setActiveMarketplace] = useState(false)
  function setActiveLink() {
    if (activePage === 'payments') return setActivePayment(true)
    if (activePage === 'workOrders') return setActiveWorkOrders(true)
    if (activePage === 'marketplace') return setActiveMarketplace(true)
    if (activePage === 'downloadApp') return setActiveDownloadApp(true)
  }

  function resetActiveLink() {
    setActivePayment(false)
    setActiveWorkOrders(false)
    setActiveMarketplace(false)
    setActiveDownloadApp(false)
  }

  useEffect(() => {
    resetActiveLink()
    setActiveLink()
  })

  useEffect(() => {
    ldInitRequest()
  }, [ldInitRequest])

  useEffect(() => {
    setShowWorkOrderFF(
      featureFlag &&
      featureFlag.ld &&
      featureFlag.ld.variation(WORK_ORDER_FEATURE_FLAG, false)
    )

    setShowMarketplaceFF(
      featureFlag &&
      featureFlag.ld &&
      featureFlag.ld.variation(MARKETPLACE_FEATURE_FLAG, false)
    )
  }, [featureFlag])

  const theme = useTheme()
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const showWorkOrder = hasWorkOrder || (hasStandaloneWorkOrder && showWorkOrderFF)
  const showMarketplace = marketplaceEnabled && showMarketplaceFF

  return (
    <TabNav
      top={top}
      scrollButtons={isSmDown ? 'on' : 'off'}
      variant="scrollable"
      indicatorColor="primary">
      <MenuWrap>
        <DownlinkWrap>
          <SubNavLink
            direction="column"
            active={isActiveDownloadApp}
            primaryColor
            justifySpaceBetween>
            <Link to={'/download-app'} id="downloadAppLink"> Download the Mobile App</Link>
            {isActiveDownloadApp && <ActiveBar />}
          </SubNavLink>
        </DownlinkWrap>
        <Flex flex={1} justifyRight>
          {hasPayment && (
            <SubNavLink
              active={isActivePayment}
              direction="column"
              justifySpaceBetween>
              {nativePayer ? (
                <Link to={paymentLink} id="paymentsLink">Payments</Link>
              ) : (
                <a href={paymentLink} target="_blank" rel="noopener noreferrer">
                  Payments
                </a>
              )}
              {isActivePayment && <ActiveBar />}
            </SubNavLink>
          )}
          {showWorkOrder && (
            <SubNavLink
              active={isActiveWorkOrders}
              direction="column"
              justifySpaceBetween>
              <Link to={`/units/${unitId}/work-orders/summary`} id="woLink">
                Work Orders
              </Link>
              {isActiveWorkOrders && <ActiveBar />}
            </SubNavLink>
          )}
          {showMarketplace && (
            <SubNavLink
              active={isActiveMarketplace}
              direction="column"
              justifySpaceBetween>
              <Link to={`/units/${unitId}/marketplace/list`} id="marketLink">
                Marketplace
              </Link>
              {isActiveMarketplace && <ActiveBar />}
            </SubNavLink>
          )}
        </Flex>
      </MenuWrap>
    </TabNav>
  )
}

export default SubNav
