import { injectGlobal } from 'emotion'
import PayLeaseTheme from './theme'

export default injectGlobal`
  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: ${PayLeaseTheme.typography.fontFamily}
  }
  a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
  }
  a.help-link {
    text-decoration: underline;
    cursor: pointer;
    color: -webkit-link;
  }
  a:hover {
    cursor: pointer;
  }
  li {
    list-style-type: none;
  }
  #root {
    height: 100%;
  }

  .embeddedServiceHelpButton .helpButton .uiButton {
    background: #0D628A !important;
    font-family: "Arial", sans-serif;
  }
  .embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: none;
  }

  .zegoEmbeddedServiceHelpButton {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
    box-shadow: none;
    overflow: visible;
    z-index: 999;
    font-family: sans-serif;
  }

  .zegoEmbeddedServiceHelpButton .assistiveText {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px,1px,1px,1px);
  }

  @media only screen and (min-width: 48em) {
      .zegoEmbeddedServiceHelpButton {
          background-color:transparent;
      }
  }

  .zegoEmbeddedServiceHelpButton .zegoHelpButton {
      position: fixed;
      bottom: 12px;
      right: 12px;
      height: 46px;
      -webkit-font-smoothing: subpixel-antialiased;
  }

  .zegoEmbeddedServiceHelpButton .zegoHelpButton .uiButton {
    background: #0D628A !important;
    font-family: "Arial", sans-serif;
      box-sizing: border-box;
      margin: 0;
      padding: 0 12px;
      height: 46px;
      box-shadow: 0 0 12px 0 rgba(0,0,0,0.5);
      border-radius: 23px;
      line-height: 1;
      background: #000;
      font-size: .875em;
      color: #fff;
      font-weight: normal;
      text-shadow: none;
      font-family: inherit;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
  }

  .zegoEmbeddedServiceHelpButton .zegoHelpButton .uiButton .label {
      color: #fff;
  }

  .embeddedServicePreventScrolling {
      overflow: hidden;
      position: fixed;
      width: 100%;
      height: 100%;
  }

  .zegoEmbeddedServiceHelpButton .zegoHelpButton .uiButton:focus {
      outline: none;
  }

  .helpButton {
    display: none;
  }

  .zegoHelpButton {
    display: none;
  }

  .zegoEmbeddedServiceHelpButton .zegoHelpButton .zegoHelpButtonEnabled:hover::before,.zegoEmbeddedServiceHelpButton .zegoHelpButton .zegoHelpButtonEnabled:focus::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 23px;
      background-color: #000;
      opacity: .2;
      pointer-events: none;
  }

  .zegoEmbeddedServiceHelpButton .zegoHelpButton .zegoHelpButtonEnabled.no-hover:hover::before,.zegoEmbeddedServiceHelpButton .zegoHelpButton .zegoHelpButtonEnabled.no-hover:focus::before {
      display: none;
  }

  @media only screen and (min-width: 48em) {
    .zegoEmbeddedServiceHelpButton .zegoHelpButton {
      position:fixed;
      bottom: 0;
    }

    .zegoEmbeddedServiceHelpButton .zegoHelpButton .uiButton {
      border-radius: 8px 8px 0 0;
    }

    .zegoEmbeddedServiceHelpButton .zegoHelpButton .uiButton:hover::before,.zegoEmbeddedServiceHelpButton .zegoHelpButton .uiButton:focus::before {
      border-radius: 8px 8px 0 0;
    }
  }

  .zegoEmbeddedServiceHelpButton .uiButton {
    font-weight: bold;
    font-size: 16px;
    margin: 2px 3px;
    padding: 4px 6px;
    min-width: 12em;
    max-width: 14em;
    text-decoration: none;
    text-align: center;
    border: 0;
    background: #dddfe1;
  }

  .zegoEmbeddedServiceHelpButton .zegoHelpButtonDisabled {
    cursor: default;
    box-shadow: none;
    text-shadow: none;
  }

  .zegoEmbeddedServiceHelpButton .zegoHelpButtonEnabled {
    cursor: pointer;
    position: relative;
  }

  .zegoEmbeddedServiceHelpButton .uiButton .zegoHelpButtonLabel {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    max-width: 100%;
    line-height: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .zegoEmbeddedServiceHelpButton .uiButton .zegoHelpButtonLabel .message {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .zegoHelpButtonEnabled:focus .zegoHelpButtonLabel {
    text-decoration: underline;
  }

  .zegoEmbeddedServiceHelpButton .embeddedServiceIcon {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-right: 10px;
    line-height: 1;
    vertical-align: top;
  }

  .zegoEmbeddedServiceHelpButton .embeddedServiceIcon::before {
    font-family: "embeddedserviceiconfont";
    font-size: 1.25em;
    content: attr(data-icon);
    speak: none;
    text-rendering: auto;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .embeddedServiceSidebarFormField .uiInput label[for='Subject'] {
    display: none;
  }
  .embeddedServiceSidebarFormField .uiInput .Subject {
    display: none;
  }
  .embeddedServiceSidebarFormField .uiInput label[for='SuppliedName'] {
    display: none;
  }
  .embeddedServiceSidebarFormField .uiInput .SuppliedName {
    display: none;
  }

  .datetime-picker .calendar-nav button {
    background-color: transparent;
  }
`
