import React, { useState } from 'react'
import { methodTypes } from '@utils/view'
import AddPaymentMethod from './AddPaymentMethod'
import TestVAriantA from './PaymentMethods'
import TestVariantC from './TestVariantC'

const VariantSwitcher = ({ testVariant, ...props }) => {
  const { saveActions, selectedPaymentMethod: selectedMethod } = props

  const [showAddPayment, setShowAddPayment] = useState()

  const onAddPaymentMethod = method => {
    if (method.methodType === methodTypes.paypal) {
      props.setPaymentMethodToPayPal()
      props.onSelectPayPal()
      setTimeout(() => props.setHideBtns(false))
    } else {
      setShowAddPayment(true)
      props.setMethod(method)
      setTimeout(() => props.setHideBtns(true)) // Delay slightly before run
    }
  }

  const hideAddPaymentMethod = () => {
    props.setMethod({})
    setShowAddPayment(false)
  }

  const savePaymentMethod = (details: Object) => {
    const { methodType, isDebitCard } = selectedMethod
    saveActions[methodType](details, isDebitCard)
  }

  if (showAddPayment) {
    return (
      <AddPaymentMethod
        cancel={hideAddPaymentMethod}
        methodType={selectedMethod?.methodType}
        savePayment={savePaymentMethod}
      />
    )
  }

  const PaymentMethods = testVariant === 'C' ? TestVariantC : TestVAriantA

  return <PaymentMethods {...props} onAddPaymentMethod={onAddPaymentMethod} />
}

export default VariantSwitcher
