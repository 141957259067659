// @flow
import React from 'react'
import { Ad, GoButton, SubText, MainText } from './styles'
import { useSelector } from 'react-redux'
import { getUserId } from 'zego-shared/store/authorization/selectors'

type Props = {
  isOptedIn: boolean
}

const CreditReportingAd = ({ isOptedIn = false }: Props) => {
  const residentId = useSelector(getUserId)
  const creditReportingUrl = `/payments/${residentId}/credit-reporting`

  return (
    <Ad isOptedIn={isOptedIn} id="lcrAdCard" to={creditReportingUrl}>
      {isOptedIn ? (
        <>
          <SubText id="lcrOptedInSubTxt">Your payments reported</SubText>
          <MainText id="lcrOptedInMainTxt">Check them out!</MainText>
        </>
      ) : (
        <>
          <SubText id="lcrNotOptedInSubTxt"> Report Your Payments</SubText>
          <MainText id="lcrNotOptedInMainTxt"> Change Your Score</MainText>
        </>
      )}
      <GoButton>Go Now!</GoButton>
    </Ad>
  )
}

export default CreditReportingAd
