// @flow
import styled from '@emotion/styled'

export const ShowOnMediaStyled = styled('div')(
  ({ media }) => `
    display: none;
    
    @media ${media} {
        & {display: block;}
    }
  `
)
