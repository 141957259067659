// @flow
import get from 'lodash/get'
import { createSelector } from 'reselect'
import Dinero from 'dinero.js'

import { getPaymentOptionFees } from '../../fees/selectors'

const feeNames = {
  creditCardFee: 'Credit card',
  achFee: 'Bank acct',
  debitCardFee: 'Debit card',
  payPalFee: 'PayPal'
}

export const feeKeysToFeeNames = {
  creditCardFee: 'Credit card',
  achFee: 'Bank acct',
  debitCardFee: 'Debit card'
}

export const feeTypeNamesToFeeKeys = {
  CreditCard: 'creditCardFee',
  BankAccount: 'achFee',
  DebitCard: 'debitCardFee',
  PayPal: 'payPalFee'
}

const feeKeysToTypeNames = Object.fromEntries(
  Object.entries(feeTypeNamesToFeeKeys).map(([key, value]) => [value, key])
)

export const transformPaymentOptions = {
  cc: 'CreditCard',
  ach: 'BankAccount',
  debit: 'DebitCard'
}

export const getPaymentOptions = (state: Object) =>
  get(state, 'payments.options', '')

export const getEnabledPaymentOptions = (state: Object) => {
  const options = getPaymentOptions(state)

    // $FlowDisableNextLine
  const result = Object.keys(options).map(
    key => options[key] === true && transformPaymentOptions[key]
  )

  return result
}

export const getActiveOptions = createSelector(
  [getPaymentOptionFees],
  (fees = {}) => {
    return Object.keys(fees).map(key => {
      const {
        regular: { amount, currency }
      } = fees[key]
      return {
        name: feeNames[key],
        fee: Dinero({ amount, currency }).toFormat(),
        typeName: feeKeysToTypeNames[key]
      }
    })
  }
)

export const getShowCardNetworks = createSelector(
  getPaymentOptions,
  (options: Object = {}) => {
    const { cc, debit } = options
    return cc || debit
  }
)

export const getPlaidBalanceChecks = createSelector(
  getPaymentOptions,
  (options: Object = {}) => options.plaidBalanceChecks
)
