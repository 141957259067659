import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    backgroundColor: 'rgba(237, 237, 237, 0.5)'
  },
  content: {
    flexGrow: 1,
    padding: '15px',
    paddingRight: 0,
    '& .MuiTypography-root': {
      marginBottom: 0
    }
  },
  actions: {
    padding: '15px',
    paddingLeft: 0
  },
  titleText: {
    fontSize: '1.1rem',
    fontWeight: 400,
    color: theme.palette.common.midnight
  },
  dateText: {
    fontSize: '0.8rem',
    color: theme.palette.common.grey,
    visibility: props => !props.nextRun && 'hidden'
  },
  totalText: {
    fontSize: '1.2rem',
    fontWeight: 400,
    color: theme.palette.common.midnight,
    marginTop: '10px'
  },
  accountText: {
    fontSize: '0.8rem',
    color: theme.palette.common.grey,
    '& span': {
      textTransform: 'capitalize'
    }
  },
  button: {
    fontSize: '1rem',
    textDecoration: 'underline',
    textTransform: 'capitalize',
    color: theme.palette.secondary.dark,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))
