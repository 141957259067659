import { call, select, put, takeLatest } from 'redux-saga/effects'
import { getToken } from '../../../authorization/selectors'
import { selectCompany, FETCH_COMPANY_PROPERTIES_AND_SELECT } from '../actions'
import {
  selectProperty,
  updatePropertyRenderReady
} from '../../properties/actions'
import { fetchPropertiesSuccess } from '../../properties/actions'
import api from '../../../../utils/api'
import log from '../../../../utils/logger'

export function* fetchCompaniesProperties(companyId) {
  try {
    const authToken = yield select(getToken)
    const response = yield call(
      api.getCompaniesProperties,
      authToken,
      companyId
    )

    yield put(fetchPropertiesSuccess(response))
    return response
  } catch (error) {
    log(`Failed to fetch company properties. Error: ${error}`)
  }
}

export function* fetchCompaniesPropertiesAndSelect({ companyId, history }) {
  try {
    yield put(updatePropertyRenderReady(false))
    yield put(selectCompany(companyId))

    const properties = yield call(fetchCompaniesProperties, companyId)
    const firstPropertyId = properties[0].id

    yield put(selectProperty(firstPropertyId))

    history.push(`/properties/${firstPropertyId}/summary`)
  } catch (error) {
    log(`Failed to fetch property. Error: ${error}`)
  }
}

export function* watchFetchCompaniesPropertiesAndSelect() {
  yield takeLatest(
    FETCH_COMPANY_PROPERTIES_AND_SELECT,
    fetchCompaniesPropertiesAndSelect
  )
}

export default [watchFetchCompaniesPropertiesAndSelect()]
