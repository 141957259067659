export const setResidentCookie = () => {
  const cookieUser = 'registeredUser' // Cookie to indicate registered user
  const cookieType = 'currentUserType' // Cookie to indicate user type
  const cookieUserValue = "true" // Value indicates a registered user
  const cookieTypeValue = 0 // Value indicates that the user type is a resident
  const domain = window.location.hostname.replace(/(^payments\.)|(^engage\.)|(^resident\.)/i, "")
  
  let expirationTime = 5184000 // Two months in seconds
  expirationTime = expirationTime * 1000 // Converts expiration time to milliseconds
  let date = new Date()
  const dateTimeNow = date.getTime()
  date.setTime(dateTimeNow + expirationTime) // Sets expiration time (Time now + two months)
  const dateString = date.toUTCString(); // Converts milliseconds to UTC time string

  // Set the cookies
  document.cookie = `${cookieUser}=${cookieUserValue}; expires=${dateString}; path=/; domain=${domain}; sameSite=lax` // Sets cookie for all subdomains
  document.cookie = `${cookieType}=${cookieTypeValue}; expires=${dateString}; path=/; domain=${domain}; sameSite=lax`
}

export const isResidentCookieSet = () => {
  // Check to see if the resident cookie is set
  return isCookieSet('registeredUser')
}

const isCookieSet = (name) => {
  if (document.cookie.indexOf(`${name}=`) > -1) {
    return true
  }
  return false
}
