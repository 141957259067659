// @flow
import React from 'react'
import { Heading, SubHeading, PayLeaseLogoStyled } from '../styles'
import payLeaseLogo from '@images/paylease_logo.png'

const ResidentWeb = () => {
  return (
    <div>
      <PayLeaseLogoStyled src={payLeaseLogo} alt="paylease logo" />
      <Heading>Thank you for showing your interest in renewing your lease!</Heading>
      <SubHeading>A member of the property staff will reach out to you shortly.</SubHeading>
    </div>
  )
}
export default ResidentWeb
