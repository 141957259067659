// @flow

import { connect } from 'react-redux'
import { withRouter, BrowserRouter } from 'react-router-dom'
import { getPaymentMethodById } from 'zego-shared/store/payments/methods/selectors'
import { createIsFetchingSelector } from 'zego-shared/store/isFetching/selectors'
import { deletePaymentMethod } from 'zego-shared/store/payments/methods/actions'

import MethodDetails from './MethodDetails'

const fetchingSelector = createIsFetchingSelector(['paymentmethodssection'])

export const mapStateToProps = (state: Object, props: Object) => {
  const {
    match: {
      params: { methodId }
    }
  } = props
  return {
    ...getPaymentMethodById(state, methodId),
    isFetching: fetchingSelector(state)
  }
}

export const mapDispatchToProps = (
  dispatch: Function,
  {
    match: {
      params: { residentId }
    }
  }: BrowserRouter
) => ({
  deleteMethod: (id: string, typeName: string) => {
    dispatch(deletePaymentMethod(residentId, id, typeName))
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MethodDetails)
)
