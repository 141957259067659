// @flow
import React from 'react'
import { ExpressPayAdStyled, CheckBoxStyled } from './styles'

type Props = {
  expressFee: string,
  value: boolean,
  onChange: boolean => mixed
}

const ExpressPay = ({ expressFee, onChange, value }: Props) => {
  const toggleExpressPay = (event, checked) => {
    onChange(checked)
  }
  return (
    <ExpressPayAdStyled>
      <h5>Avoid Late Fees</h5>
      <p>
        Your payment will be paid out in three business days. If you need your
        payment to arrive earlier use our express pay option to process your
        payment in one business day.
      </p>
      <CheckBoxStyled
        id="expPayRadio"
        label={`Use Express Pay - ${expressFee} Fee`}
        value={value}
        checked={value}
        onChange={toggleExpressPay}
      />
    </ExpressPayAdStyled>
  )
}

export default ExpressPay
