import React from 'react'
import Flex from '@Common/Flex'
import { Link } from 'react-router-dom'
import { CommonIcon } from '@icons'
import { FabButton } from '@Common/Button'
import createAutoPay from '@images/create_autopay.png'
import {
  PageTitle,
  NoAutoPayWrap,
  NoAutoPayImage,
  AddIconWrap,
  MakePaymentsText
} from './styles'

type Props = {
  theme: Object
}

const AutoPayZero = (props: Props) => {
  const { theme } = props

  return (
    <React.Fragment>
      <PageTitle>Auto Pay</PageTitle>
      <NoAutoPayWrap fullWidth justifyCenter>
        <NoAutoPayImage>
          <Link to="auto-pay/add">
            <AddIconWrap>
              <FabButton
                primaryLight
                id="addBtn"
                component={() => (
                  <CommonIcon
                    name="add"
                    width="2em"
                    height="2em"
                    fill={theme.palette.common.white}
                    strokeWidth={3}
                    stroke={theme.palette.common.white}
                  />
                )}
                size={theme.ux.isSSO ? '40px' : '60px'}
                background={theme.palette.primary.main}
              />
            </AddIconWrap>
          </Link>
          <img src={createAutoPay} alt="" />
        </NoAutoPayImage>
      </NoAutoPayWrap>
      <Flex justifyCenter marginTop="32px">
        <MakePaymentsText>
          Make payments easier! Setup a recurring Auto Pay to make your payments
          on time.
        </MakePaymentsText>
      </Flex>
    </React.Fragment>
  )
}

export default AutoPayZero
