// @flow
import React from 'react'
import Button from '@Common/Button'
import Flex from '@Common/Flex'
import {
  CheckMark,
  CheckTextContainer,
  Title,
  Thin,
  SubTitle,
  CheckText,
  SetupAccCard,
  BankAcctContent,
  SubTitleAndChecksContainer,
  UnderlinedButton,
  LinksContainer,
  ExistingBankDebitWrapper,
  NoPayment
} from './styles'
import DebitAd from './DebitAd'
import PaymentNotes from '@views/Payments/PaymentNotes'
import { paymentMethodTypes } from '@utils/view'
import { Hidden, useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getPaymentNotesEnabled } from 'zego-shared/store/pmSettings/selectors'
import { PaymentNotesBtn } from '../../PaymentNotes'

type Props = {
  showNotes: Boolean,
  debitEnabled: Boolean,
  achEnabled: Boolean,
  currentMethod: Object,
  paymentOptions: Object[],
  onContinueClicked: Function,
  onShowMore: Function,
  onCancel: Function,
  addPaymentMethod: Function
}

const NoPaymentMethods = ({
  onShowMore,
  onCancel,
  addPaymentMethod,
  currentMethod,
  paymentOptions,
  showNotes,
  debitEnabled,
  achEnabled
}: Props) => {
  const theme = useTheme()
  const { isSSO } = theme.ux
  const isPaymentNotesEnabled = useSelector(getPaymentNotesEnabled)
  const disablePaymentNotes = isSSO || !isPaymentNotesEnabled || !showNotes

  if (paymentOptions.length && !debitEnabled) onShowMore()

  const setupAccBtn = (
    <Button
      outline
      primary
      long={isSSO ? false : true}
      onClick={() => addPaymentMethod(paymentMethodTypes.BANK)}
    >
      Set Up Bank Acct
    </Button>
  )

  const renderCheckTexts = texts => {
    return texts.map(text => (
      <Flex direction="row" alignStart>
        <CheckMark />
        <CheckTextContainer alignCenter justifyCenter>
          <CheckText>{text}</CheckText>
        </CheckTextContainer>
      </Flex>
    ))
  }

  const mainContent = (
    <NoPayment>
      <ExistingBankDebitWrapper>
        <DebitAd addPaymentMethod={addPaymentMethod} />
      </ExistingBankDebitWrapper>

      {achEnabled && (
        <SetupAccCard customPadding="10px" unwrapContent>
          <BankAcctContent>
            <Title>
              Bank <Thin>Acct</Thin>
            </Title>
            <SubTitleAndChecksContainer>
              <Hidden only={['md', 'sm']}>
                <SubTitle small>When using bank acct</SubTitle>
              </Hidden>
              <Flex direction="row" justifySpaceBetween alignEnd>
                <div>
                  {renderCheckTexts([
                    'Enjoy low per transaction fees',
                    'Avoid finance charges'
                  ])}
                </div>
                <Hidden lgUp xsDown>
                  {setupAccBtn}
                </Hidden>
              </Flex>
            </SubTitleAndChecksContainer>
            <Hidden only={['md', 'sm']}>{setupAccBtn}</Hidden>
          </BankAcctContent>
        </SetupAccCard>
      )}
    </NoPayment>
  )

  return (
    <div>
      <PaymentNotes disabled={disablePaymentNotes}>{mainContent}</PaymentNotes>

      <LinksContainer direction="row" justifyRight>
        {isSSO && (
          <Flex flex={1} justifyLeft>
            <PaymentNotesBtn />
          </Flex>
        )}
        <UnderlinedButton light onClick={onCancel}>
          Exit &amp; Cancel Payment
        </UnderlinedButton>
        <UnderlinedButton onClick={onShowMore}>
          View all Payment Options
        </UnderlinedButton>
      </LinksContainer>
    </div>
  )
}

export default NoPaymentMethods
