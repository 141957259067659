import styled from '@emotion/styled'
import Select from '@Common/Form/Select'

export const RightSelect = styled(Select)`
  margin-left: ${({ theme}) => theme.space.s15}
`
export const InputLabel = styled('label')`
  margin: ${({ theme }) => `-${theme.space.s20} 0 0 0`};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  color: rgba(0, 0, 0, 0.54);
  display: block;
  font-weight: ${({ theme }) => theme.custom.font.weight.light};
  padding-top: ${({ paddingTop, theme }) => paddingTop && theme.space.s45};
  flex-grow: 1;
`
