// @flow
import styled from '@emotion/styled'
import Flex from '@Common/Flex'

export const SwitchText = styled('div')`
  width: 75px;
  height: 25px;
  margin-right: ${({ checked, theme }) => (checked ? theme.space.s30 : '0')};
  margin-left: ${({ checked, theme }) => (!checked ? theme.space.s30 : '0')};
  background: transparent;
  position: relative;
  bottom: 30px;
  font-size: ${({ theme }) => theme.custom.font.size.md};
  color: ${({ theme }) => theme.palette.common.white};
  text-align: right;
  padding-right: 5px;
  line-height: 24px;
`

export const SwitchTextWrap = styled('div')`
  height: 25px;
`

export const SwitchWrap = styled(Flex)`
  height: 60px;
`
