// @flow
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { getData } from '../../../helpers/selectors/data'
import {
  humanizeString,
  capitalizeFirstLetter,
  formatPhoneNumber
} from '../../../../utils/string'

const emptyObj = {}

export const getSelectedUnit = (state: Object) =>
  get(state, 'select.units.selected', emptyObj)

export const getSelectedUnitType = (state: Object) => {
  const { type } = getSelectedUnit(state)
  return type
}

export const getUnits = (state: Object) =>
  getData(state, ['select', 'units'], []) || []

export const isUnitSelected = (state: Object) =>
  !isEmpty(getSelectedUnit(state))

export const getUnitId = (state: Object) =>
  get(state, 'select.units.selected.id', -1)

export const getEmergencyPhone = (state: Object) =>
  formatPhoneNumber(
    get(getSelectedUnit(state), 'property.emergency_phone_number', '')
  )

export const getPropertyPhone = (state: Object) =>
  formatPhoneNumber(get(getSelectedUnit(state), 'property.phone_number', ''))

export const getUnitName = (state: Object) => {
  const name = get(state, 'select.units.selected.name', 'unnamed unit')
  return name == null ? name : name.toLowerCase()
}

export const getUnitStatus = (state: Object) =>
  humanizeString(get(state, 'select.units.selected.status', 'unknown status'))

export const getUnitType = (state: Object) =>
  humanizeString(get(state, 'select.units.selected.type', 'unknown type'))

export const getUnitActive = (state: Object) => {
  const isUnitActive = get(state, 'select.units.selected.active', false)
  return isUnitActive ? 'Activated' : 'Not Activated'
}

export const getUnitProperty = (state: Object) => {
  const name = get(
    state,
    'select.units.selected.property.name',
    'unknown property'
  )
  return capitalizeFirstLetter(name)
}

export const getUnitCompany = (state: Object) => {
  const name = get(
    state,
    'select.units.selected.property.company_name',
    'unknown company'
  )
  return capitalizeFirstLetter(name)
}

export const getUnitCompanyUuid = (state: Object) =>
  get(state, 'select.units.selected.property.company_uuid', null)

export const getUnitPropertyId = (state: Object) =>
  get(state, 'select.units.selected.property.id', -1)

export const getUnitAddress = (state: Object) =>
  get(state, 'select.units.selected.address', '')

export const getUnitPropertyUuId = (state: Object) =>
  get(state, 'select.units.unitPropertyUuid', '')
