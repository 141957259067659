// @flow
import React from 'react'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { StyledRegularParagraph } from './styles'

type Item = {
  label: string,
  value: string | number
}

type ListSimpleProps = {
  items: Array<Item>,
  divider?: boolean
}

const ListSimple = ({ items, divider = false }: ListSimpleProps) => {
  return (
    <List>
      {items.map(({ label, value }, i) => {
        const addDivider = items.length - 1 !== i && divider
        return (
          <ListItem key={label} divider={addDivider} disableGutters>
            <ListItemText
              primary={label}
              primaryTypographyProps={{ variant: 'h6' }}
            />
            <ListItemText
              primary={value}
              primaryTypographyProps={{
                component: StyledRegularParagraph,
                align: 'right',
                color: 'primary'
              }}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

export default ListSimple
