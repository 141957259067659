
import { connect } from 'react-redux'
import Notification from './Notification'
import { getSurvey } from 'zego-shared/store/surveys/selectors'
import { dismissNotification } from 'zego-shared/store/surveys/actions'

const mapStateToProps = (state) => {
  const survey = getSurvey(state)
  return {
    surveyId: survey && survey.surveyId,
    surveyMessage: survey && survey.surveyMessage
}}

const mapDispatchToProps = { dismissNotification }

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
