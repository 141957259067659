// @flow
import get from 'lodash/get'

export const getDeliveryPreferenceFromState = (state: Object) => {
  const result = get(state, 'profile.deliveryPreferenceResult')
  if (!result) return null

  return result.billingType;
}

export const getReasonCodeFromState = (state: Object) => {
  const result = get(state, 'profile.deliveryPreferenceResult')
  if (!result) return ""
  
  return result.paperStatementReason;
}