import styled from '@emotion/styled'
import { StyledCard } from '../styles'
import Flex from '@Common/Flex'

export const CardStyled = styled(StyledCard)`
  & .MuiCardContent-root {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  & h4 {
    height: ${({ width }) =>
      width === 'sm' || width === 'xs' ? '18px' : '24px'};
    color: ${({ theme }) => theme.palette.common.midnight};
    font-size: ${({ theme, width }) =>
      width === 'sm' || width === 'xs'
        ? theme.custom.font.size.H5
        : theme.custom.font.size.H4};
    font-weight: ${({ theme }) => theme.custom.font.weight.light};
    line-height: 29px;
    margin: 1px 0 0;
  }
`

export const CardHeader = styled(Flex)`
  padding: 0;
  text-indent: 6px;
`

export const PaymentInfoContainerStyled = styled(Flex)`
  justify-content: space-between;
`

export const EditIconButtonContainerStyled = styled('div')`
  margin-top: 15px;
`
