import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveSelectedMethodType } from 'zego-shared/store/payments/methods/actions'
import { getSavedMethodType } from "zego-shared/store/payments/methods/selectors";
import { methodTypes } from '../../utils/view'

export function useSelectedMethod(setHideBtns, gotoNextFlow) {
    const [method, setMethod] = useState({})
    const selectedPaymentMethod = useSelector(getSavedMethodType)
    const dispatch = useDispatch()

    useEffect(() => {
        /**
         * Check to see if there is a selectedPaymentMethod.methodType set
         * by the InsufficientBalanceModal. If this is true and the
         * selectedPaymentMethod.methodType and method.methodType are not
         * equal, set method to selectedPaymentMethod
         */
        const selectedMethodType = selectedPaymentMethod?.methodType
        const methodType = method?.methodType

        if (selectedMethodType !== methodType) {
            setMethod(selectedPaymentMethod || {})
            if (selectedMethodType !== methodTypes.paypal) {
                setHideBtns(true)
            }
            gotoNextFlow(1)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPaymentMethod?.methodType])

    const setSelectedPaymentMethod = (method: Object) => {
        setMethod(method)
        dispatch(saveSelectedMethodType(method))
    }

    return [{ method, selectedPaymentMethod }, setSelectedPaymentMethod]
}

export default useSelectedMethod
