import styled from '@emotion/styled'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { AppBar, ButtonBase, Toolbar } from '@material-ui/core'
import Button from '@Common/Button'
import InputBase from '@material-ui/core/InputBase';

export const SSOToolbar = styled(Toolbar)`
  min-height: 55px;
`

export const CustomInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.neutral.light}`,
    fontSize: theme.custom.font.size.H6,
    fontWeight: theme.custom.font.weight.bolder,
    padding: '5px 26px 5px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}))(InputBase);

export const Logo = styled('img')`
  height: 34px;
  position: relative;
  margin-right: 0.5rem;
  top: -3px;
`

export const LogoMobile = styled(Logo)`
  height: 28px;
`

export const StyledAppBar = styled(AppBar)`
  box-shadow: none;
  background-color: transparent;
  height: ${({ height }) => `${height}px`};
  overflow: hidden;
  transform: none;
  z-index: 1200;
  overflow: visible;
  display:  ${({ hide }) =>  hide ? 'none' : 'block'};
  top: 0px;
  position: sticky;
  ${({ theme }) => [theme.breakpoints.down('xs')]} {
    position: static;
  }
`

export const SSOAppBar = styled(StyledAppBar)`
  box-shadow: none !important;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral.warm};
`

export const Typography = styled('div')`
  flex: 1;
  padding-top: ${({ theme }) => theme.space.s10};
  ${({ theme }) => [theme.breakpoints.only('xs')]} {
    padding-top: ${({ theme }) => theme.space.s20};
  }
`

export const Name = styled('span')`
  color: ${({ theme }) => theme.palette.primary.deep};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  text-transform: capitalize;
  position: absolute;
`

export const MyDetailsNav = styled('div')`
  padding: ${({ theme }) => theme.space.s20};
  width: ${({ width }) => `${width}px`};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => theme.custom.shadow};
`

export const MyAvatar = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.space.s25};
`

export const MyDetailsLink = styled('div')`
  padding: ${({ theme }) => theme.space.s5};
  color: ${({ theme }) => theme.palette.common.midnight};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  line-height: 19px;

  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => [theme.breakpoints.down('sm')]} {
    padding: ${({ theme }) => theme.space.s10} 0;
  }
`

export const StyledButton = styled(Button)`
  line-height: 19px;
  height: 30px;
  min-width: 217px;
  margin-top: 15px;
  margin-right: ${({ theme }) => theme.space.s20};
  background-color: ${({ isSideNavOpen }) => isSideNavOpen && 'transparent'};
  color: ${({ isSideNavOpen }) => isSideNavOpen && 'transparent'};
`

export const AvatarButton = styled(ButtonBase)`
  margin-top: 10px;
  margin-right: 18px;
`

export const useStyles = makeStyles({
  root: {
    marginTop: '10px'
  },
  rounded: {
    borderRadius: '12px'
  }
})

export const SSOHeaderText = styled('div')`
  color: ${({ theme }) => theme.palette.primary.deep};
  font-size: ${({ theme }) => theme.custom.font.size.H6};
  font-weight: ${({ theme }) => theme.custom.font.weight.bolder};
  margin: 8px 10px;
  text-transform: capitalize;
`
